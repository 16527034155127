import { UserStreakEntity } from "api/src/users/entities/user-streaks.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useLastStreak() {
  return useQuery<UserStreakEntity>(
    ["users", "streak"],
    async () => {
      const response = await api.get<UserStreakEntity>("users/streak", {});

      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Couldn't fetch last streak");
    },
    {
      keepPreviousData: true,
    }
  );
}
