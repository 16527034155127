import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function HomeIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 30 34" {...props}>
      <Svg width={32} height={32} fill="none" stroke={color}>
        <Path
          d="M19 26v-6a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H6a1.001 1.001 0 0 1-1-1V14.442a1 1 0 0 1 .327-.74l10-9.091a1 1 0 0 1 1.345 0l10 9.092a1 1 0 0 1 .328.74V26a1.001 1.001 0 0 1-1 1h-6a1 1 0 0 1-1-1v0Z"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
