import { Text, useColorModeValue, useToken, VStack } from "native-base";
import React, { useMemo } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";

import { IconButton } from "./elements/IconButton";
import { UploadIcon } from "./icons/Icons";
import { InfoIcon } from "./icons/InfoIcon";

interface IDropBoxProps {
  hasFiles: boolean;
  supportedFormats: string;
  openInfoModal?: (open: boolean) => void;
  hasFileComponent: () => JSX.Element;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  isFocused: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
}

export function DropBox({
  hasFiles,
  supportedFormats,
  openInfoModal,
  hasFileComponent,
  getInputProps,
  getRootProps,
  isFocused,
  isDragAccept,
  isDragReject,
}: IDropBoxProps) {
  const [primary50, primary100, dark300, dark500] = useToken("colors", [
    "primary.50",
    "primary.100",
    "dark.300",
    "dark.500",
  ]);
  const backgroundColor = useColorModeValue(primary50, dark300);
  const borderColor = useColorModeValue(primary100, dark500);
  const style = useMemo(
    () => ({
      backgroundColor,
      borderColor,
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  return (
    <section>
      {/* @ts-ignore */}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {hasFiles ? (
          <VStack space={20}>
            {openInfoModal ? (
              <IconButton
                style={{ position: "absolute", right: 0, zIndex: 999 }}
                onPress={() => openInfoModal(true)}
              >
                <InfoIcon color="primary.200" />
              </IconButton>
            ) : null}
            <VStack
              alignItems="center"
              justifyContent="center"
              space="2"
              pb="10"
            >
              <UploadIcon size={50} />
              <Text fontSize="md">
                Drag & Drop file or click to{" "}
                <Text underline={true} color="primary.400" fontSize="md">
                  Browse
                </Text>
              </Text>
              <Text>Supported formats: {supportedFormats}</Text>
            </VStack>
          </VStack>
        ) : (
          hasFileComponent()
        )}
      </div>
    </section>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  borderWidth: 2,
  borderStyle: "dashed",
  outline: "none",
  transition: "all .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#DF3D8D",
  backgroundColor: "#DF3D8D15",
};

const acceptStyle = {
  borderColor: "#DF3D8D",
  backgroundColor: "#FBCFE8",
};

const rejectStyle = {
  borderColor: "#ff1744",
  backgroundColor: "#ff174420",
};
