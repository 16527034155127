import { VStack, Heading, Text } from "native-base";
import React from "react";

interface IPageHeaderProps {
  label: string;
  subtitle: string;
}
export function PageHeader({ label, subtitle }: IPageHeaderProps) {
  return (
    <VStack
      space={2}
      justifyContent="center"
      mb={4}
      alignItems="center"
      maxW="70%"
      mx="auto"
    >
      <Heading textAlign="center" fontSize={20} fontWeight={600}>
        {label}
      </Heading>
      <Text fontSize={16}>{subtitle}</Text>
    </VStack>
  );
}
