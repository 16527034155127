import { Center, Image } from "native-base";
import React, { ReactNode } from "react";
import { KeyboardAvoidingView, Platform } from "react-native";

import { WebHeader } from "../layout/WebHeader";
import { isWeb } from "../util/platform";
import { useBackgroundColor } from "../util/theme-helper";

import { ScrollView } from "./elements/ScrollView";

interface IAuthContentProps {
  children: ReactNode;
}

export function AuthContent({ children }: IAuthContentProps) {
  const backgroundColor = useBackgroundColor();

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={Platform.OS === "ios" ? 50 : 0}
      style={{
        flex: 1,
        justifyContent: "center",
        backgroundColor,
      }}
    >
      {isWeb() && <WebHeader isLogin={false} />}
      <ScrollView
        paddingX={[0, 0, "10%", "15%", "20%"]}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          paddingBottom: 35,
        }}
        _dark={{
          bg: "dark.100",
        }}
      >
        <Center>
          <Image
            height={150}
            width={150 * (1000 / 600)}
            source={require("../assets/images/logo.png")}
            alt="Logo"
          />
        </Center>
        {children}
      </ScrollView>
    </KeyboardAvoidingView>
  );
}
