import { IScrollViewProps, ScrollView as NbScrollView } from "native-base";
import React from "react";
import { useWindowDimensions } from "react-native";

export function ScrollView({ ...props }: IScrollViewProps) {
  const { height } = useWindowDimensions();

  return (
    <NbScrollView
      bounces={false}
      showsVerticalScrollIndicator={false}
      paddingX={[6, 6, "10%", "15%", "20%"]}
      _web={{ background: "secondary.200", height }}
      _dark={{
        bg: "dark.100",
      }}
      {...props}
    />
  );
}
