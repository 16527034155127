import { useNavigation } from "@react-navigation/core";
import {
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Text,
  useToken,
  VStack,
} from "native-base";
import React, { useState } from "react";

import { Content } from "../components/content/Content";
import { IconButton } from "../components/elements/IconButton";
import { ArrowBackIcon } from "../components/icons/ArrowBackIcon";
import { ArrowForwardIcon } from "../components/icons/ArrowForwardIcon";
import { COPING_STRATEGIES } from "../constants/coping-strategies";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";

export function CopingStrategiesScreen() {
  const primaryColor = useToken("colors", "primary.400");
  const [copingIndex, setCopingIndex] = useState<number>(0);
  const { navigate } = useNavigation();

  const { icon: Icon } = COPING_STRATEGIES[copingIndex];

  return (
    <>
      <Header />
      <Body
        bg="secondary.200"
        paddingX={[6, 6, "10%", "15%", "20%"]}
        _dark={{ bg: "dark.500" }}
      >
        <VStack
          alignItems="center"
          space="3"
          pt="10"
          bg="white"
          paddingX="5"
          paddingY="10"
          marginTop="10"
          _dark={{ bg: "dark.100" }}
        >
          <VStack>
            <Badge
              bg="primary.400"
              rounded="full"
              mb={-4}
              mr={-4}
              zIndex={1}
              variant="solid"
              alignSelf="flex-end"
              _text={{
                fontSize: 12,
              }}
            >
              {copingIndex + 1}
            </Badge>
            <Icon size="6xl" />
          </VStack>
          <HStack alignItems="center" w="100%" justifyContent="center">
            <Heading fontSize="20" fontWeight={600}>
              {COPING_STRATEGIES[copingIndex].label}
            </Heading>
            {copingIndex !== COPING_STRATEGIES.length - 1 ? (
              <Box position="absolute" right="5%">
                <IconButton onPress={() => setCopingIndex(copingIndex + 1)}>
                  <ArrowForwardIcon color={primaryColor} size="sm" />
                </IconButton>
              </Box>
            ) : null}
            {copingIndex !== 0 ? (
              <Box position="absolute" left="5%">
                <IconButton onPress={() => setCopingIndex(copingIndex - 1)}>
                  <ArrowBackIcon color={primaryColor} size="lg" />
                </IconButton>
              </Box>
            ) : null}
          </HStack>

          <Text textAlign="center" fontSize="15">
            {COPING_STRATEGIES[copingIndex].description}
          </Text>
          <Divider my="4" />
          {COPING_STRATEGIES[copingIndex].sections.map(
            ({ label, description, content, cta }, index) => (
              <Box w="100%" key={index}>
                <VStack space="2" alignItems="center" w="100%">
                  <Heading fontSize="16" fontWeight={600} color="primary.400">
                    {label}
                  </Heading>
                  <Text textAlign="justify" mb="2">
                    {description}
                  </Text>

                  {content.map((item) => (
                    <Box w="100%" key={item} style={{ overflow: "hidden" }}>
                      <Content variant="horizontal" contentId={item} />
                    </Box>
                  ))}

                  {cta && (
                    <Button
                      borderRadius={5}
                      p={2}
                      px={3}
                      my={3}
                      onPress={() => {
                        if (cta) {
                          navigate(cta.link);
                        }
                      }}
                    >
                      <Text color="white" fontSize={12}>
                        {cta.text}
                      </Text>
                    </Button>
                  )}
                </VStack>
                <Divider my="4" />
              </Box>
            )
          )}
        </VStack>
      </Body>
    </>
  );
}
