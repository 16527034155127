export enum Issue {
  lgbtq = "lgbtq",
  family = "family",
  neurodiversity = "neurodiversity",
  diversity = "diversity",
  finances = "finances",
  deadlines = "deadlines",
  bodyImage = "body-image",
  jobSecurity = "job-security",
  mentalHealth = "mental-health",
  relationships = "relationships",
  physicalHealth = "physical-health",
  longTermIllness = "long-term-illness",
  issuesWithCoworkers = "issues-with-coworkers",
  issuesWithManagement = "issues-with-management",
}
