import { RouteProp, useRoute } from "@react-navigation/core";
import { isEmpty } from "lodash";
import {
  HStack,
  Text,
  Image,
  VStack,
  Box,
  Divider,
  Center,
  useColorMode,
} from "native-base";
import React, { useEffect, useMemo, useState } from "react";

import { ContentButtons } from "../components/content/ContentButtons";
import { ContentFeedback } from "../components/content/ContentFeedback";
import { ContentTags } from "../components/content/ContentTags";
import { LoadingAnimation } from "../components/elements/LoadingAnimation";
import { Markdown } from "../components/elements/Markdown";
import { ScrollView } from "../components/elements/ScrollView";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import { ARTICLE_READ_TIME } from "../constants/article-read-time";
import { useAddViewedContent } from "../hooks/useAddViewedContent";
import { useContent } from "../hooks/useContent";
import { Header } from "../layout/Header";
import { RootStackParamList } from "../navigation/RootStackParamList";
import { getChannelsLabel } from "../util/channel";
import { isCloseToBottom, isHtml } from "../util/content";
import { getImage } from "../util/image";
import { isWeb } from "../util/platform";
import { getContentTags } from "../util/tags";

export function ContentScreen() {
  const { colorMode } = useColorMode();
  const {
    params: { contentId },
  } = useRoute<RouteProp<RootStackParamList, "ContentDetails">>();
  const { data: content } = useContent(contentId);
  const { mutate: addViewedContent } = useAddViewedContent();
  const [hasPassedTime, setHasPassedTime] = useState<boolean>(false);
  const [hasReachedEnd, setHasReachedEnd] = useState<boolean>(false);

  const isContentHtml = isHtml(content?.content);

  useEffect(() => {
    const readTimeout = setTimeout(
      () => setHasPassedTime(true),
      ARTICLE_READ_TIME
    );
    return () => clearTimeout(readTimeout);
  }, []);

  const hasViewed = useMemo(
    () => hasPassedTime && hasReachedEnd,
    [hasPassedTime, hasReachedEnd]
  );

  useEffect(() => {
    if (hasViewed) {
      addViewedContent({
        contentId,
      });
    }
  }, [hasViewed]);

  if (!content) {
    return (
      <>
        <Header hasBack={true} />
        <Center h={isWeb() ? "80vh" : "80%"}>
          <LoadingAnimation isLoading={true} size={150} />
        </Center>
      </>
    );
  }

  return (
    <Box key={contentId} w="100%" bg="secondary.200">
      <Header hasBack={true} />
      <ScrollView
        px={0}
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent)) {
            setHasReachedEnd(true);
          }
        }}
        w="100%"
        contentContainerStyle={{ paddingBottom: 200 }}
        scrollEventThrottle={100}
        position="relative"
        _dark={{ bg: "dark.500" }}
      >
        <HStack top={0}>
          <Image
            source={{ uri: getImage(content?.image) }}
            w="100%"
            h="400px"
            alt={content?.title}
            key={contentId}
          />
        </HStack>
        <WhiteBoxContainer>
          <VStack space={0} position="relative">
            <VStack>
              <Box>
                <VStack space={2}>
                  <Text color="primary.400" fontWeight={600} fontSize={32}>
                    {content?.title}
                  </Text>
                  <HStack alignItems="center" justifyContent="flex-start">
                    <Text>
                      <Text fontSize={18} fontWeight={600} color="primary.400">
                        {getChannelsLabel(content?.channels)}
                      </Text>
                    </Text>
                  </HStack>
                  <ContentButtons
                    contentId={content?.id}
                    isEditVisible={true}
                  />
                </VStack>
              </Box>

              <Divider my={4} mt={4} alignSelf="center" />

              <Box px={4} pb={4}>
                {isContentHtml ? (
                  <div
                    className={`article ${
                      colorMode === "dark" ? "article-dark-theme" : ""
                    }`}
                    dangerouslySetInnerHTML={{
                      __html:
                        content?.content.replaceAll("<noscript>", "") ?? "",
                    }}
                  />
                ) : (
                  <Markdown>{content?.content ?? ""}</Markdown>
                )}
              </Box>
              {!isEmpty(content?.tags) ? (
                <>
                  <Divider my={4} mt={2} />
                  <ContentTags tags={getContentTags(content?.tags)} />
                </>
              ) : null}
              <Divider my={4} mt={2} />

              <Center w="100%" justifyContent="center">
                <ContentFeedback contentId={content?.id} />
              </Center>
            </VStack>
          </VStack>
        </WhiteBoxContainer>
      </ScrollView>
    </Box>
  );
}
