import { ICriticalIndicatorChart } from "api/src/organisations/interfaces/ICriticalIndicatorChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

import { useLineChartOptions } from "../../../../hooks/useLineChartOptions";

import { CriticalIndicatorsLegend } from "./CriticalIndicatorsLegend";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ICriticalIndicatorChartProps {
  ICriticalIndicatorChart: ICriticalIndicatorChart | undefined;
}
export interface ILineChartData {
  data: { labels?: string[]; datasets: ICriticalIndicatorDataset[] };
  activeSelection: string[];
}
export interface ICriticalIndicatorDataset {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
}

export function CriticalIndicatorsChart({
  ICriticalIndicatorChart,
}: ICriticalIndicatorChartProps) {
  const chartOptions = useLineChartOptions({});

  const datasets: ICriticalIndicatorDataset[] = [
    {
      label: "All",
      data: [],
      borderColor: "#DF3D8D",
      backgroundColor: "#C4C4C4",
    },
    {
      label: "Stress",
      data: ICriticalIndicatorChart?.stress || [],
      borderColor: "#35CD1C",
      backgroundColor: "#35CD1C",
    },
    {
      label: "Depression",
      data: ICriticalIndicatorChart?.depression || [],
      borderColor: "#1BA2CD",
      backgroundColor: "#1BA2CD",
    },
    {
      label: "Sleep",
      data: [],
      borderColor: "#DF408F",
      backgroundColor: "#DF408F",
    },
    {
      label: "Resiliance",
      data: ICriticalIndicatorChart?.resilience || [],
      borderColor: "#7B61FF",
      backgroundColor: "#7B61FF",
    },
    {
      label: "Anxiety",
      data: ICriticalIndicatorChart?.anxiety || [],
      borderColor: "#D14C4C",
      backgroundColor: "#D14C4C",
    },
    {
      label: "Sickness and Absense",
      data: [],
      borderColor: "#21F9EC",
      backgroundColor: "#21F9EC",
    },
    {
      label: "Exercise",
      data: [],
      borderColor: "#D14DCC",
      backgroundColor: "#D14DCC",
    },
  ];
  const [lineChartData, setLineChartData] = useState<ILineChartData>({
    data: { labels: ICriticalIndicatorChart?.labels, datasets },
    activeSelection: ["All"],
  });

  useEffect(() => {
    setLineChartData({
      data: { labels: ICriticalIndicatorChart?.labels, datasets },
      activeSelection: ["All"],
    });
  }, [ICriticalIndicatorChart]);

  const handleActiveSelection = (selectedItem: ICriticalIndicatorDataset) => {
    if (selectedItem.label === "All") {
      setLineChartData({
        data: { labels: ICriticalIndicatorChart?.labels, datasets },
        activeSelection: ["All"],
      });
    } else if (lineChartData.activeSelection.includes("All")) {
      setLineChartData({
        data: {
          labels: ICriticalIndicatorChart?.labels,
          datasets: [selectedItem],
        },
        activeSelection: [selectedItem.label],
      });
    } else if (lineChartData.activeSelection.includes(selectedItem.label)) {
      setLineChartData({
        data: {
          labels: ICriticalIndicatorChart?.labels,
          datasets: lineChartData.data.datasets.filter(
            (item) => item.label !== selectedItem.label
          ),
        },
        activeSelection: lineChartData.activeSelection.filter(
          (item) => item !== selectedItem.label
        ),
      });
    } else {
      setLineChartData({
        data: {
          labels: ICriticalIndicatorChart?.labels,
          datasets: [...lineChartData.data.datasets, selectedItem],
        },
        activeSelection: [...lineChartData.activeSelection, selectedItem.label],
      });
    }
  };

  return (
    <>
      <HStack h="100%">
        {lineChartData.data ? (
          <>
            <CriticalIndicatorsLegend
              activeSelection={lineChartData.activeSelection}
              handleActiveSelection={handleActiveSelection}
              data={datasets}
            />
            <Box w="85%">
              <Line
                options={chartOptions}
                data={lineChartData.data}
                height="100%"
              />
            </Box>
          </>
        ) : (
          <Box alignItems="center" justifyContent="center" w="100%">
            <Text color="secondary.650">
              There's no data to be shown currently.
            </Text>
          </Box>
        )}
      </HStack>
    </>
  );
}
