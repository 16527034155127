import { SubmitHabitDto } from "api/src/habit/dto/submit-habit.dto";
import { useMutation, useQueryClient } from "react-query";

import { EVENT_HABIT_ADD } from "../constants/analytics";
import { eventHit } from "../util/analytics";
import { api } from "../util/api";

import { useHabits } from "./useHabits";

export function useHabitAdd() {
  const queryClient = useQueryClient();
  const { data: habits } = useHabits();
  return useMutation<null, null, SubmitHabitDto>(
    "habit-add",
    async (data: SubmitHabitDto) => {
      const response = await api.post("habit", data);

      if (response.status === 201) {
        return response.data;
      }

      throw response.data;
    },
    {
      onSuccess: (newHabit) => {
        eventHit("submit", EVENT_HABIT_ADD);
        queryClient.setQueryData("habits", [...(habits ?? ""), newHabit]);
        queryClient.invalidateQueries("habits");
      },
    }
  );
}
