import { FiveMinuteQuestion } from "../enum/FiveMinuteQuestion";

export const FIVE_MINUTE_ENTRIES = [
  {
    label: "I am grateful for...",
    question: FiveMinuteQuestion.gratefulFor,
  },
  {
    label: "What will I do to make it great?",
    question: FiveMinuteQuestion.plansToMakeItGreat,
  },
  {
    label: "Daily affirmation. I am...",
    question: FiveMinuteQuestion.dailyAffirmation,
  },
  {
    label: "Here are 3 amazing things that happened today.",
    question: FiveMinuteQuestion.amazingThingsToday,
  },
  {
    label: "Today I am proud of myself because",
    question: FiveMinuteQuestion.proudOfMyself,
  },
  {
    label: "What could I have done to make the day even better?",
    question: FiveMinuteQuestion.couldHaveDone,
  },
];
