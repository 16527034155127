import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";

import { StripeRedirect } from "../components/StripeRedirect";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ??
    "pk_test_51LHmfDJ8EuGsVuj5C7teW0qYJuah5y13McLsT6OQy71tWL3PkqhnGNo7vXQYUDAxFfc6FcIOqSLnqFOvhkGamYXt00F850tCkc"
);

export function StripeAppScreen() {
  return (
    <>
      <Elements stripe={stripePromise}>
        <StripeRedirect />
      </Elements>
    </>
  );
}
