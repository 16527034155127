import { WellbeingQuestionAnswer } from "api/src/wellbeing/enum/WellbeingQuestionAnswer";
import { IQuestionResponse } from "api/src/wellbeing/interface/IQuestionResponse";
import { Text, VStack, Pressable } from "native-base";
import React, { useEffect, useState } from "react";
import { View as AnimatedView } from "react-native-animatable";

import { getWellbeingAnswerLabel } from "../../util/wellbeing";

import { Question } from "./Question";

interface IQuestionsProps {
  isOnboarding: boolean;
  question?: IQuestionResponse;
  questionNumber: number;
  onComplete: (answer: WellbeingQuestionAnswer) => void;
  handleSkip?: (isCancel?: boolean) => void;
  handleBack?: () => void;
}

export function QuestionsPage({
  isOnboarding = false,
  question,
  questionNumber,
  onComplete,
  handleSkip,
  handleBack,
}: IQuestionsProps) {
  const [isEntering, setIsEntering] = useState<boolean>(true);
  const [isTouchDisabled, setIsTouchDisabled] = useState<boolean>(true);

  const [selectedAnswer, setSelectedAnswer] = useState<WellbeingQuestionAnswer>(
    WellbeingQuestionAnswer.never
  );
  const currentAnimation = isEntering ? "fadeIn" : "fadeOut";
  const isFirstQuestion = questionNumber === 0;

  useEffect(() => {
    setIsEntering(true);
  }, [question]);

  return (
    <VStack space={12} px={6}>
      <VStack space={2}>
        <AnimatedView
          style={{ width: "100%" }}
          animation={currentAnimation}
          delay={0}
          onAnimationEnd={
            !isEntering ? () => onComplete(selectedAnswer) : undefined
          }
          duration={350}
        >
          <Text textAlign="center" fontSize={16} fontWeight="600">
            Question {questionNumber + 1}.
          </Text>
        </AnimatedView>
        <AnimatedView
          style={{ width: "100%" }}
          animation={currentAnimation}
          delay={200}
          duration={350}
        >
          <Text textAlign="center" fontSize={16} fontWeight="400">
            {question?.question}
          </Text>
        </AnimatedView>
      </VStack>

      <VStack space={2}>
        {question?.answers.map((answer, index) => (
          <AnimatedView
            style={{ width: "100%", alignItems: "center" }}
            key={answer}
            animation={isEntering ? "fadeInUp" : "fadeOutDown"}
            delay={100 + 200 * index}
            duration={300}
            onAnimationEnd={() => setIsTouchDisabled(false)}
          >
            <Question
              isDisabled={isTouchDisabled}
              label={getWellbeingAnswerLabel(answer)}
              onPress={() => {
                setIsTouchDisabled(true);
                if (!isEntering) {
                  return;
                }
                setSelectedAnswer(answer);
                return setIsEntering(false);
              }}
            />
          </AnimatedView>
        ))}
        <AnimatedView
          animation={isEntering ? "fadeInUp" : "fadeOutDown"}
          delay={1100}
          duration={300}
        >
          {!isOnboarding ? (
            <>
              <Pressable
                onPress={
                  isFirstQuestion
                    ? () => handleSkip?.(true)
                    : () => handleSkip?.()
                }
                _pressed={{ opacity: 0.7 }}
                pt="2"
              >
                <Text textAlign="center" color="secondary.650">
                  {isFirstQuestion
                    ? "I've changed my mind"
                    : "Skip wellbeing checker"}
                </Text>
              </Pressable>
            </>
          ) : null}
          {!isOnboarding && !isFirstQuestion ? (
            <>
              <Pressable
                onPress={handleBack}
                _pressed={{ opacity: 0.7 }}
                pt="2"
              >
                <Text textAlign="center" color="secondary.650">
                  Back
                </Text>
              </Pressable>
            </>
          ) : null}
        </AnimatedView>
      </VStack>
    </VStack>
  );
}
