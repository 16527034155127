import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function WindIcon({ color = "#c4c4c4", ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 60 60" {...props}>
      <Svg width={60} height={60} fill="none" stroke={color}>
        <Path
          d="M30.348 45.077a5.625 5.625 0 1 0 5.277-7.577H9.375M22.848 14.924a5.626 5.626 0 1 1 5.277 7.577h-22.5M43.473 22.424A5.626 5.626 0 1 1 48.75 30H7.5"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
