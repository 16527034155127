import { useNavigation } from "@react-navigation/core";
import { HStack, Box, VStack } from "native-base";
import React from "react";

import { DefaultHabitCard } from "../components/DefaultHabitCard";
import { HabitHeader } from "../components/HabitHeader";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import { DEFAULT_HABITS } from "../constants/default-habits";
import { Header } from "../layout/Header";

export function DefaultHabitScreen() {
  const { navigate } = useNavigation();

  return (
    <>
      <Box bg="secondary.200" minH="100vh" _dark={{ bg: "dark.500" }}>
        <Header hasBack={true} />
        <WhiteBoxContainer>
          <VStack space={5}>
            <HabitHeader
              title="Add a habit"
              subtitle="Select a default habit to start with or make your own!"
            />

            <HStack
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              alignSelf="center"
            >
              {DEFAULT_HABITS.map((item) => (
                <Box>
                  <DefaultHabitCard
                    item={item}
                    onPress={() => {
                      if (item.id === "0") {
                        navigate("AddHabit", { habitId: undefined });
                      } else {
                        navigate("AddHabit", { ...item, habitId: undefined });
                      }
                    }}
                  />
                </Box>
              ))}
            </HStack>
          </VStack>
        </WhiteBoxContainer>
      </Box>
    </>
  );
}
