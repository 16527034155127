import { ChartOptions } from "chart.js";
import { useColorModeValue, useToken } from "native-base";

interface IChartOptions {
  yTitle?: string;
}

export function useLineChartOptions({
  yTitle,
}: IChartOptions): ChartOptions<"line"> {
  const [secondary700, secondary400, dark200] = useToken("colors", [
    "secondary.700",
    "secondary.400",
    "dark.200",
  ]);
  const tickColor = useColorModeValue(secondary700, secondary400);
  const gridColor = useColorModeValue(secondary400, dark200);

  return {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.3,
      },
    },
    scales: {
      y: {
        offset: false,
        title: yTitle
          ? {
              display: true,
              text: yTitle,
              color: tickColor,
            }
          : undefined,
        ticks: {
          color: tickColor,
          padding: 5,
        },
        grid: {
          color: gridColor,
          drawTicks: false,
        },
      },
      x: {
        ticks: {
          color: tickColor,
          padding: 20,
        },
        grid: {
          color: gridColor,
          drawTicks: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
}
