import { Icon, IIconProps } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

export function InsightsIcon(props: IIconProps) {
  return (
    <Icon viewBox="-1 -1 29 29" {...props}>
      <Svg width={24} height={24} fill="none">
        <Path
          d="M13.5 13.5V3.375M22.268 8.438 4.732 18.562M3.544 15.351a10.14 10.14 0 0 1 6.58-11.4v7.6l-6.58 3.8Z"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M13.5 3.375A10.125 10.125 0 1 1 4.769 18.63"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
