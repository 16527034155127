import { AddIcon, Fab as NbFab, IFabProps } from "native-base";
import React from "react";

export function Fab({ ...props }: IFabProps) {
  return (
    <NbFab
      _ios={{ bottom: "5%" }}
      _web={{ left: "49%" }}
      placement="bottom-left"
      left="45%"
      size="12"
      icon={<AddIcon size="xs" />}
      renderInPortal={false}
      {...props}
    />
  );
}
