import { useStripe } from "@stripe/react-stripe-js";
import React, { forwardRef, useImperativeHandle } from "react";

import { useCheckout } from "../hooks/useCheckout";

export const CheckoutStripe = forwardRef<{ checkout: () => void }>((_, ref) => {
  const stripe = useStripe();

  const { mutate: checkout } = useCheckout();

  useImperativeHandle(ref, () => ({
    checkout: () => {
      checkout(
        {},
        {
          onSuccess: (data) => {
            console.log("checked art");
            stripe?.redirectToCheckout({
              sessionId: data.session,
            });
          },
          onError: () => {
            console.log("Could not checkout");
          },
        }
      );
    },
  }));

  return <></>;
});
