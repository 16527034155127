import { useQuery } from "react-query";

import { api } from "../util/api";

export function useUsersUnReadNotificationQuantity() {
  return useQuery<number>(
    ["unread-notifications-quantity"],
    async () => {
      const response = await api.get<number>(
        "notification/unread-notifications-quantity"
      );
      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject(response.status);
    },
    {
      keepPreviousData: true,
      retry: false,
      staleTime: 60000,
    }
  );
}
