import { IResilienceScoreAverage } from "api/src/organisations/interfaces/IResilienceScoresAverage";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationAgeResilienceScore() {
  return useQuery<IResilienceScoreAverage>(
    ["age-resilience-scores"],
    async () => {
      const response = await api.get<IResilienceScoreAverage>(
        "organisations/age-resilience-scores",
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject(
        "Could not get organisations age resilience scores"
      );
    },
    {
      keepPreviousData: true,
    }
  );
}
