import { useNavigation } from "@react-navigation/native";
import { UserEntity } from "api/src/users/entities/user.entity";
import { AlertDialog as NbAlertDialog, HStack, Input } from "native-base";
import React, { useRef, useState } from "react";

import { useDeleteAccount } from "../../hooks/useDeleteAccount";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { Button } from "../elements/Button";

interface IAlertDialogProps {
  isOpen: boolean;
  onClose: () => void;
  user: UserEntity | undefined;
}

export function CancelMembershipModal({
  isOpen,
  onClose,
  user,
}: IAlertDialogProps) {
  const cancelRef = useRef<typeof Button>(null);
  const { mutate: deleteAccount, isLoading } = useDeleteAccount();
  const [code, setCode] = useState("");
  const toast = useToast();
  const { navigate } = useNavigation();

  const codeText = `We have sent a confirmation code to your email ${user?.email}, please enter it below.`;

  return (
    <NbAlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <NbAlertDialog.Content>
        <NbAlertDialog.Header alignItems="center" textAlign="center">
          Are you sure?
        </NbAlertDialog.Header>
        <NbAlertDialog.Body textAlign="center" alignContent="center" mb={2}>
          {codeText}
          <Input
            autoFocus={true}
            placeholder="Verification Code"
            keyboardType="default"
            onChangeText={(text) => setCode(text)}
            type="password"
            mb={2}
            mt={2}
          />
          This will delete your account, you will loose access to all of
          Miindset. We hope you've enjoyed your time with us. This action is
          irreversible.
        </NbAlertDialog.Body>
        <NbAlertDialog.Footer>
          <HStack flexBasis="100%" justifyContent="space-between">
            <Button variant="outline" ref={cancelRef} onPress={onClose}>
              Cancel
            </Button>
            <HStack space={[2, 0]}>
              <Button
                onPress={() => {
                  if (user) {
                    deleteAccount(
                      { email: user.email, code },
                      {
                        onSuccess: (data) => {
                          toast({
                            title:
                              data.message || "Account successfully deleted",
                            status: ToastStatus.success,
                          });
                          onClose();
                          navigate("Login");
                        },
                        onError: (data) => {
                          toast({
                            title:
                              data.message || "Failed to delete your account",
                            status: ToastStatus.error,
                          });
                        },
                      }
                    );
                  }
                }}
                bg="red.600"
                isLoading={isLoading}
                px={5}
              >
                Confirm
              </Button>
            </HStack>
          </HStack>
        </NbAlertDialog.Footer>
      </NbAlertDialog.Content>
    </NbAlertDialog>
  );
}
