import { ContentType } from "api/src/content/enum/ContentType";
import { Circle, ICircleProps } from "native-base";
import React from "react";

import { getContentTypeIcon } from "../../util/content";

interface IContentIconProps extends ICircleProps {
  type: ContentType;
  size?: number;
}

export function ContentIcon({
  type,
  size = 4,
  zIndex,
  ...props
}: IContentIconProps) {
  const Icon = getContentTypeIcon(type);
  if (!Icon) {
    return null;
  }

  return (
    <Circle size={7} bg="white" shadow="4" {...props}>
      <Icon
        color="primary.400"
        size={size}
        position="absolute"
        zIndex={zIndex}
      />
    </Circle>
  );
}
