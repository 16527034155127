import React from "react";
import { Path, Svg } from "react-native-svg";

export function CircleProgressIcon() {
  return (
    <Svg width="50" height="54.5" fill="none" viewBox="0 3 50 50">
      <Path
        d="M13.2188 30.0249H14.9209V23.2231H15.8027L14.5859 22.6011L19.877 30.0249H21.4014V20.1606H19.7061V26.9556H18.8242L20.041 27.5776L14.75 20.1606H13.2188V30.0249Z"
        fill="#2F333C"
      />
      <Path
        d="M27.1504 19.6616H25.5645L22.625 32.5063H24.2109L27.1504 19.6616Z"
        fill="#2F333C"
      />
      <Path
        d="M27.2119 30.0249H29.0576L31.6484 21.9927H32.1748V20.1606H30.7119L27.2119 30.0249ZM28.9756 27.4751H34.417L33.959 26.1147H29.4336L28.9756 27.4751ZM34.3555 30.0249H36.2012L32.6943 20.1606H31.7646V21.9927L34.3555 30.0249Z"
        fill="#2F333C"
      />
      <Path
        opacity="0.4"
        d="M45 25.0249C45 36.6229 35.598 46.0249 24 46.0249C12.402 46.0249 3 36.6229 3 25.0249C3 13.4269 12.402 4.0249 24 4.0249C35.598 4.0249 45 13.4269 45 25.0249Z"
        stroke="#CBCCC4"
        strokeOpacity="0.4"
        strokeWidth="6"
      />
      <Path
        d="M43.4347 7.8723V7.84453C43.4347 6.17786 42.1347 4.95564 40.1681 4.95564C39.566 4.94569 38.9692 5.06846 38.42 5.31522C37.8708 5.56199 37.3826 5.92669 36.9903 6.38342C36.9358 6.43393 36.8919 6.49487 36.8614 6.56262C36.8309 6.63037 36.8142 6.70356 36.8125 6.77786C36.8125 6.84641 36.8262 6.91426 36.8529 6.97738C36.8797 7.0405 36.9188 7.09761 36.968 7.14529C37.0173 7.19298 37.0756 7.23027 37.1396 7.25496C37.2035 7.27964 37.2718 7.29121 37.3403 7.28897C37.487 7.28533 37.6274 7.22801 37.7347 7.12786C38.0316 6.7799 38.4004 6.50041 38.8157 6.3086C39.231 6.11679 39.6829 6.01722 40.1403 6.01675C41.4514 6.01675 42.2681 6.86119 42.2681 7.91119V7.93897C42.2681 9.16675 41.2903 9.85008 39.7458 9.96675C39.6833 9.96916 39.622 9.98476 39.5659 10.0125C39.5098 10.0403 39.4602 10.0796 39.4203 10.1278C39.3805 10.1761 39.3512 10.2322 39.3346 10.2925C39.3179 10.3528 39.3142 10.416 39.3236 10.4779C39.3236 10.4779 39.3236 11.7667 39.3236 12.0056C39.3374 12.1432 39.402 12.2706 39.5047 12.363C39.6075 12.4555 39.741 12.5063 39.8792 12.5056H39.9403C40.0778 12.4918 40.2052 12.4272 40.2977 12.3245C40.3901 12.2218 40.441 12.0883 40.4403 11.9501V10.8056C42.0847 10.5556 43.4347 9.69453 43.4347 7.8723Z"
        fill="#DF3D8D"
      />
      <Path
        d="M39.878 15.2502C40.2616 15.2502 40.5725 14.9393 40.5725 14.5558C40.5725 14.1722 40.2616 13.8613 39.878 13.8613C39.4945 13.8613 39.1836 14.1722 39.1836 14.5558C39.1836 14.9393 39.4945 15.2502 39.878 15.2502Z"
        fill="#DF3D8D"
      />
      <Path
        d="M48.4015 7.11688C48.0639 7.27152 47.7069 7.37991 47.3404 7.4391C47.9266 9.10821 47.9285 10.927 47.3457 12.5973C46.7629 14.2676 45.63 15.6904 44.1326 16.6325C42.6352 17.5746 40.8622 17.9801 39.1042 17.7825C37.3462 17.585 35.7074 16.7961 34.4565 15.5452C33.2056 14.2942 32.4167 12.6555 32.2191 10.8975C32.0216 9.13945 32.4271 7.36647 33.3692 5.8691C34.3113 4.37173 35.7341 3.2388 37.4044 2.65599C39.0747 2.07318 40.8935 2.07506 42.5626 2.66132C42.6218 2.29475 42.7302 1.93783 42.8848 1.60021C40.9772 0.940604 38.9023 0.94731 36.999 1.61923C35.0957 2.29115 33.4764 3.58859 32.4057 5.29963C31.335 7.01066 30.8761 9.0342 31.1039 11.0397C31.3318 13.0453 32.2329 14.9143 33.6601 16.3415C35.0874 17.7688 36.9564 18.6699 38.962 18.8978C40.9675 19.1256 42.991 18.6667 44.7021 17.596C46.4131 16.5253 47.7105 14.906 48.3825 13.0027C49.0544 11.0994 49.0611 9.02449 48.4015 7.11688Z"
        fill="#DF3D8D"
      />
      <Path
        d="M46.6684 6.11122C48.2025 6.11122 49.4462 4.86757 49.4462 3.33344C49.4462 1.79932 48.2025 0.555664 46.6684 0.555664C45.1343 0.555664 43.8906 1.79932 43.8906 3.33344C43.8906 4.86757 45.1343 6.11122 46.6684 6.11122Z"
        fill="#DF3D8D"
      />
    </Svg>
  );
}
