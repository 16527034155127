import { debounce, isEmpty } from "lodash";
import { Box, FlatList, Input, Text, useColorModeValue } from "native-base";
import React, { useState, useCallback, useMemo } from "react";

import { useFlagUserBySearch } from "../../hooks/useFlagUserBySearch";
import { useRecommendedContent } from "../../hooks/useRecommendedContent";
import { useSearchContent } from "../../hooks/useSearchContent";
import { ContentHorizontal } from "../content/ContentHorizontal.web";
import { LoadingAnimation } from "../elements/LoadingAnimation";
import { SearchIcon } from "../icons/SearchIcon";

export default function AutoCompleteInput() {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { data: content, isFetching } = useSearchContent(debouncedSearchTerm);
  const { data: recommendedContent } = useRecommendedContent();

  const handleChangeSearchTerm = debounce((term: string) => {
    setDebouncedSearchTerm(term);
  }, 1000);

  useFlagUserBySearch(searchTerm);

  const handleInputChanged = useCallback((searchValue: string) => {
    setSearchTerm(searchValue);
    handleChangeSearchTerm(searchValue);
  }, []);
  const [textInputFocus, setTextInputFocus] = useState<boolean>();

  const shouldLoad = useMemo(
    () => debouncedSearchTerm !== searchTerm || isFetching,
    [debouncedSearchTerm, searchTerm, isFetching]
  );

  const iconColor = useColorModeValue("secondary.800", "secondary.400");

  return (
    <Box flex={1} zIndex={1}>
      <Input
        placeholder="Search"
        variant="graySearch"
        borderRadius="full"
        width={250}
        onFocus={() => {
          setTextInputFocus(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setTextInputFocus(false);
          }, 200);
        }}
        value={searchTerm}
        onChangeText={handleInputChanged}
        maxHeight={9}
        paddingLeft={5}
        InputRightElement={
          <Box pr="2.5">
            <SearchIcon size="xs" color={iconColor} />
          </Box>
        }
      />
      {textInputFocus && (
        <FlatList
          backgroundColor="white"
          position="absolute"
          width={500}
          right={0}
          maxHeight={500}
          padding={2}
          height={500}
          zIndex={999}
          shadow={3}
          marginTop={10}
          showsVerticalScrollIndicator={!isEmpty(content)}
          data={
            shouldLoad
              ? []
              : searchTerm.length > 0
              ? content
              : recommendedContent?.content?.slice(5, 10)
          }
          ListEmptyComponent={() => (
            <Box p={5} textAlign="center" alignItems="center">
              {shouldLoad ? (
                <LoadingAnimation isLoading={true} size={80} />
              ) : (
                <Text fontSize={16}>No results found</Text>
              )}
            </Box>
          )}
          renderItem={({ item }) => (
            <Box>
              <ContentHorizontal item={item} />
            </Box>
          )}
          keyExtractor={({ id }) => id}
          _dark={{
            borderWidth: 1,
            shadow: 1,
            bg: "dark.400",
          }}
        />
      )}
    </Box>
  );
}
