import { IOrganisationDepartment } from "api/src/organisations/interfaces/IOrganisationDepartment";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationDepartments() {
  return useQuery<IOrganisationDepartment[]>(
    ["organisations", "departments"],
    async () => {
      const response = await api.get<IOrganisationDepartment[]>(
        "organisations/departments",
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get organisation departments");
    },
    { keepPreviousData: true }
  );
}
