import { createContext } from "react";

import { FirstLoginStepsType } from "../enum/first-login-steps";

export interface IFirstLoginContext {
  currentStep: FirstLoginStepsType | null;
  setCurrentStep: (step: FirstLoginStepsType | null) => void;
}

export const FirsLoginContext = createContext<IFirstLoginContext>({
  currentStep: null,
  setCurrentStep: () => {},
});
