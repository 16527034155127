import { useNavigation } from "@react-navigation/native";
import { Box, Divider, HStack, View } from "native-base";
import React, { useMemo } from "react";

import { IconButton } from "../components/elements/IconButton";
import { HealthAppIcon } from "../components/icons/Icons";
import { UserMediaConsumption } from "../components/insights/UserMediaConsumption";
import { UserMoodCalendar } from "../components/insights/UserMoodCalendar";
import { Loading } from "../components/Loading";
import { MoodIcon } from "../components/mood/icons/MoodIcon";
import { MoodsChart } from "../components/mood/MoodsChart";
import { UserInsight } from "../components/UserInsight";
import { UserLoginStreakTimeline } from "../components/UserLoginStreakTimeline";
import { UserNotification } from "../components/UserNotifications";
import { UserResilienceScore } from "../components/UserResilienceScore";
import { useWellbeingScore } from "../hooks/useWellbeingScore";
import { Header } from "../layout/Header";
import { getResilienceStateText } from "../util/wellbeing";

export function InsightsScreen() {
  const { navigate } = useNavigation();
  const { data: wellbeingScores, isLoading } = useWellbeingScore();

  const resilienceStateText = useMemo(
    () => getResilienceStateText(wellbeingScores?.resilience),
    [wellbeingScores]
  );

  if (isLoading) {
    return <Loading showHeader={true} />;
  }
  return (
    <>
      <Box bg="secondary.200" _dark={{ bg: "dark.500" }} flex={1}>
        <Header />
        <HStack
          flex={1}
          flexDirection="row"
          space={5}
          p={5}
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          <View
            flex={1}
            minWidth={400}
            bg="white"
            _dark={{ bg: "dark.100" }}
            p={10}
          >
            <HStack justifyContent="center">
              <UserResilienceScore
                text={resilienceStateText}
                percentage={wellbeingScores?.resilience}
                hasBottomText={true}
              />
              <Box position="absolute" left="5">
                <HealthAppIcon size="4xl" opacity={0} />
              </Box>
              <Box position="absolute" right="5">
                <IconButton
                  onPress={() => {
                    navigate("TrackMood");
                  }}
                >
                  <MoodIcon size="4xl" />
                </IconButton>
              </Box>
            </HStack>
            <UserLoginStreakTimeline />
            <UserMoodCalendar />
          </View>
          <View
            flex={1}
            minWidth={400}
            p={10}
            bg="white"
            _dark={{ bg: "dark.100" }}
          >
            <MoodsChart />
            <UserInsight />
            <Divider mb="5" />
            <UserNotification />
          </View>

          <View
            flex={1}
            minWidth={400}
            p={10}
            bg="white"
            _dark={{ bg: "dark.100" }}
          >
            <UserMediaConsumption />
          </View>
        </HStack>
      </Box>
    </>
  );
}
