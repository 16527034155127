import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function VideoCameraIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 27 27" {...props}>
      <Svg width={27} height={27} fill="none" stroke={color}>
        <Path
          d="M3.301 6.397h13.082c.868 0 1.7.346 2.313.964.613.617.958 1.454.958 2.327v9.874a.826.826 0 0 1-.24.581.815.815 0 0 1-.578.241H5.754a3.26 3.26 0 0 1-2.313-.963 3.302 3.302 0 0 1-.958-2.328V7.22c0-.219.087-.428.24-.582a.815.815 0 0 1 .578-.241v0Z"
          strokeWidth={1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="m19.637 11.75 5.723-3.291v9.873l-5.723-3.29"
          strokeWidth={1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
