import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function SpikeEmailIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="-3 -4 24 25" {...props}>
      <Svg width="31" height="31" fill="none" stroke={color}>
        <Path
          d="M13.4324 9.0001C13.4314 7.89087 13.0141 6.82242 12.2631 6.00614C11.512 5.18987 10.4819 4.68522 9.37664 4.59207C8.27133 4.49891 7.17132 4.82402 6.29424 5.50308C5.41716 6.18213 4.82692 7.16567 4.64028 8.25908C4.45365 9.35249 4.68423 10.4761 5.28639 11.4077C5.88856 12.3392 6.81845 13.0108 7.89208 13.2896C8.9657 13.5683 10.1049 13.434 11.0841 12.913C12.0634 12.3921 12.8115 11.5226 13.1804 10.4765C13.3477 10.0022 13.4327 9.50298 13.4324 9.0001Z"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M17.0625 8.99999C17.0646 7.74979 16.776 6.51622 16.2195 5.39674C15.6629 4.27727 14.8536 3.30256 13.8555 2.54965C12.8575 1.79673 11.698 1.28623 10.4687 1.05849C9.23942 0.830743 7.97402 0.891995 6.77248 1.2374C5.57094 1.58281 4.46619 2.20291 3.54552 3.0487C2.62485 3.89449 1.91348 4.9428 1.46763 6.1108C1.02178 7.2788 0.853655 8.53448 0.97655 9.77863C1.09944 11.0228 1.50999 12.2213 2.17574 13.2795L3.3 14.7L4.72049 15.8242C5.81679 16.5149 7.0637 16.9305 8.35513 17.0358C9.64657 17.141 12 17.0358 14.1 15.3"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M13.179 10.476C12.6375 11.7968 13.5191 12.5074 14.2778 12.6799C15.2029 12.8899 17.0621 11.9228 17.0621 9"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
