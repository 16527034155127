import Bugsnag from "@bugsnag/expo";

import { isWeb } from "./platform";
/**
 * Sets the user data in Bugsnag.
 *
 * @param id The ID of the user.
 * @param email The email of the user.
 * @param name The name of the user.
 */
export const setUserData = (id?: string, email?: string, name?: string) => {
  if (!isWeb()) {
    Bugsnag.setUser(id, email, name);
  }
};
