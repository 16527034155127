import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { useUsersUnReadNotificationQuantity } from "./useUsersUnReadNotificationQuantity";

interface IUseUsersReadNotification {
  notificationId: string;
}

export function useUserUnReadNotification() {
  const queryClient = useQueryClient();
  const { data: readNotification } = useUsersUnReadNotificationQuantity();

  return useMutation<null, null, IUseUsersReadNotification>(
    ["notification-read"],
    async ({ notificationId }: IUseUsersReadNotification) => {
      const response = await api.get(
        `notification/unread/${notificationId}`,
        {}
      );
      if (response.status !== 200) {
        throw response.data;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(
          "unReadNotifications",
          readNotification ? readNotification + 1 : 0
        );

        queryClient.invalidateQueries("notifications");
        queryClient.invalidateQueries("unread-notifications-quantity");
      },
    }
  );
}
