import AsyncStorage from "@react-native-async-storage/async-storage";
import { NotificationDto } from "api/src/users/dto/notification-token.dto";
import { useMutation } from "react-query";

import { EXPO_PUSH_NOTIFICATION_TOKEN } from "../constants/storage";
import { api } from "../util/api";

export function useExpoPushNotificationTokenDelete() {
  return useMutation<null, null, NotificationDto>(
    "expo-push-notification-token-delete",
    async (data: NotificationDto) => {
      const response = await api.put(
        "users/expo-notification-token/delete",
        data
      );

      if (response.status === 200) {
        AsyncStorage.removeItem(EXPO_PUSH_NOTIFICATION_TOKEN);
        return response.data;
      }

      return null;
    }
  );
}
