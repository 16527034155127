import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import { SubmitOrganisationContentDto } from "api/src/content/dto/submit-organisation-content.dto";
import { ContentStatus } from "api/src/content/enum/ContentStatus";
import { ContentType } from "api/src/content/enum/ContentType";
import { Region } from "api/src/content/enum/Region";
import * as ImagePicker from "expo-image-picker";
import {
  HStack,
  VStack,
  Box,
  Heading,
  Text,
  Image,
  Divider,
  Tooltip,
} from "native-base";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { GestureResponderEvent } from "react-native";

import { Icon, IconLibrary } from "../../components/elements/Icon";
import { IconButton } from "../../components/elements/IconButton";
import { IconButtonWithText } from "../../components/elements/IconButtonWithText";
import { ScrollView } from "../../components/elements/ScrollView";
import { DropdownInput } from "../../components/form/DropdownInput";
import { Input } from "../../components/form/Input";
import { RichEditor } from "../../components/form/RichEditor";
import { Loading } from "../../components/Loading";
import { UploadImage } from "../../components/organisations/UploadImage";
import { WhiteBoxContainer } from "../../components/WhiteBoxContainer";
import { PUBLISH_INFO } from "../../constants/organisation-content-edit-screen";
import { useAddOrganisationPost } from "../../hooks/useAddOrganisationPost";
import { useFormErrors } from "../../hooks/useFormErrors";
import { useOrganisationContent } from "../../hooks/useOrganisationContent";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { useUpdateOrganisationPost } from "../../hooks/useUpdateOrganisationPost";
import { useUploadImage } from "../../hooks/useUploadImage";
import { Header } from "../../layout/Header";
import { RootStackParamList } from "../../navigation/RootStackParamList";
import { getImage } from "../../util/image";

export function OrganisationContentEditScreen() {
  const [image, setImage] = useState<string>();
  const [type, setType] = useState<string>();
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const toast = useToast();
  const { navigate, goBack, canGoBack } = useNavigation();
  const { params } = useRoute<RouteProp<RootStackParamList, "ReviewContent">>();

  const { data, isLoading: isLoadingReview } = useOrganisationContent(
    params?.id
  );
  const { mutate: uploadImage, isLoading: isUploadLoadingImage } =
    useUploadImage();
  const { mutate: addPost, isLoading: isAddingOrganisationContent } =
    useAddOrganisationPost();
  const { mutate: updatePost, isLoading: isUpdatingOrganisationContent } =
    useUpdateOrganisationPost(params?.id);

  const defaultFormValues: FieldValues = useMemo(
    () => ({
      url: data?.url,
      type: data?.type,
      title: data?.title,
      headline: data?.headline,
      image: data?.image,
      content: data?.content,
      abstract: data?.abstract,
      region: data?.region,
      status: data?.status,
    }),
    [data]
  );

  const {
    control,
    setValue,
    getValues,
    setError,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: params?.id ? defaultFormValues : undefined,
  });

  const { setErrors } = useFormErrors(setError);

  const handleImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      uploadImage(
        { file: result?.uri },
        {
          onSuccess: (file) => {
            setImage(file);
            setValue("image", file);
          },
          onError: (error) => {
            if (image && error.message) {
              toast({ title: error.message, status: ToastStatus.error });
            }
            setErrors({
              image: error.message,
            });
          },
        }
      );
    }
  };

  const onSubmit = useCallback(
    (
      contentData: Record<string, string> & {
        type?: ContentType;
      } & {
        status?: ContentStatus;
      } & {
        isPublishAsNotification?: boolean;
      }
    ) => {
      if (
        contentData.type !== ContentType.article &&
        contentData.url.trim() === "" &&
        contentData.status !== ContentStatus.draft
      ) {
        setErrors({ url: "URL is required" });
        return;
      }
      const formData: SubmitOrganisationContentDto = {
        type: contentData.type,
        url: contentData.url,
        image: contentData?.image,
        title: contentData.title,
        headline: contentData.headline,
        content: contentData.content ?? "",
        abstract: contentData.abstract,
        status: contentData.status,
        isPublishAsNotification: contentData.isPublishAsNotification ?? false,
      };

      if (
        contentData.status === ContentStatus.live &&
        data?.status === ContentStatus.draft
      ) {
        addPost(formData, {
          onSuccess: () => {
            toast({
              title: "Content Publish Successfully from Draft!",
              status: ToastStatus.success,
            });
            navigate("ReviewContentsList");
          },
          onError: (data) => {
            toast({ title: "An error occurred!", status: ToastStatus.error });

            setErrors(data.errors ?? {});
          },
        });
        return;
      }

      if (params?.id) {
        updatePost(formData, {
          onSuccess: () => {
            toast({ title: "Content edited!", status: ToastStatus.success });

            navigate("ReviewContentsList");
          },
          onError: (data) => {
            toast({ title: "An error occurred!", status: ToastStatus.error });

            setErrors(data.errors ?? {});
          },
        });
      } else {
        addPost(formData, {
          onSuccess: () => {
            toast({ title: "Content added!", status: ToastStatus.success });
            navigate("ReviewContentsList");
          },
          onError: (data) => {
            toast({ title: "An error occurred!", status: ToastStatus.error });

            setErrors(data.errors ?? {});
          },
        });
      }
    },
    [data, updatePost]
  );

  useEffect(() => {
    if (params?.id) {
      setImage(data?.image ?? "");
      setType(data?.type);
      Object.entries(defaultFormValues).forEach(([key, value]) => {
        setValue(key, value, { shouldDirty: false, shouldTouch: false });
      });
    }
  }, [data, params]);

  function handleEdit(e: GestureResponderEvent) {
    setValue("status", data?.status);
    if (data?.isPublishAsNotification) {
      setValue("isPublishAsNotification", true);
    }
    handleSubmit(onSubmit)(e);
    clearErrors();
  }

  if (isLoadingReview) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <>
      <Header />
      {!isPreviewing ? (
        <WhiteBoxContainer width="75%">
          <Box>
            <HStack alignItems="center" space={4}>
              <IconButton
                onPress={() => {
                  if (canGoBack()) {
                    goBack();
                  } else {
                    navigate("ReviewContentsList");
                  }
                }}
              >
                <Icon
                  size={6}
                  icon="arrowleft"
                  iconLibrary={IconLibrary.antDesign}
                />
              </IconButton>
              <Heading
                fontWeight={500}
                fontSize={24}
                ref={null}
                textAlign="left"
              >
                {params?.id ? "Edit" : "Add"} content
              </Heading>
            </HStack>
          </Box>
          <VStack space={10}>
            <Box width="100%" alignItems="center">
              <UploadImage
                handleImage={handleImage}
                isLoading={isUploadLoadingImage}
                image={image}
              />

              {errors?.image && !image ? (
                <Text
                  color="#DC2626"
                  mt="2"
                  size={12}
                  width={300}
                  textAlign="center"
                >
                  {errors?.image?.message}
                </Text>
              ) : null}
            </Box>

            <Input
              name="title"
              label="Title"
              errors={errors}
              variant="contentInput"
              fontSize={16}
              control={control}
              placeholder="Title"
            />
            <Input
              name="headline"
              label="Headline"
              fontSize={16}
              variant="contentInput"
              errors={errors}
              control={control}
              placeholder="Headline"
            />
            <Input
              name="abstract"
              label="Abstract"
              fontSize={14}
              variant="contentInput"
              isTextarea={true}
              errors={errors}
              control={control}
              placeholder="Abstract"
            />

            <HStack justifyContent="space-between" mt={5}>
              <DropdownInput
                maxWidth="49%"
                name="region"
                placeholder="Select..."
                label="Region"
                variant="contentInput"
                defaultValue={data?.region}
                errors={errors}
                control={control}
                items={
                  Object.values(Region).map((region) => ({
                    label: region,
                    value: region,
                  })) || []
                }
              />

              <DropdownInput
                maxWidth="49%"
                name="type"
                placeholder="Select..."
                label="Type"
                variant="contentInput"
                defaultValue={data?.type}
                errors={errors}
                setValue={(type) => setType(type)}
                control={control}
                items={
                  Object.values(ContentType).map((type) => ({
                    label: type,
                    value: type,
                  })) || []
                }
              />
            </HStack>
            {type === "article" && (
              <Box>
                <RichEditor
                  name="content"
                  control={control}
                  variant="contentInput"
                  label="Content"
                  errors={errors}
                />
              </Box>
            )}
            <Input
              name="url"
              label="URL"
              fontSize={12}
              variant="contentInput"
              control={control}
              placeholder="URL"
              errors={errors}
            />
            {params?.id && data?.status !== ContentStatus.draft ? (
              <IconButtonWithText
                w={150}
                alignSelf="center"
                title="save"
                iconName="save"
                isLoading={
                  isUpdatingOrganisationContent || isAddingOrganisationContent
                }
                onPress={(e) => {
                  handleEdit(e);
                }}
              />
            ) : (
              <HStack width="100%" justifyContent="center" space="2">
                <IconButtonWithText
                  title="Save Draft"
                  iconName="file-plus"
                  isLoading={
                    getValues("status") === ContentStatus.draft &&
                    (isUpdatingOrganisationContent ||
                      isAddingOrganisationContent)
                  }
                  onPress={(e) => {
                    setValue("status", ContentStatus.draft);
                    clearErrors();
                    handleSubmit(onSubmit)(e);
                  }}
                />

                {type === "article" && (
                  <IconButtonWithText
                    title="Preview"
                    iconName="layout"
                    onPress={() => {
                      setIsPreviewing(!isPreviewing);
                    }}
                  />
                )}

                <IconButtonWithText
                  title="Publish"
                  iconName="upload"
                  isLoading={
                    getValues("isPublishAsNotification") !== true &&
                    getValues("status") === ContentStatus.live &&
                    isAddingOrganisationContent
                  }
                  onPress={(e) => {
                    setValue("status", ContentStatus.live);
                    clearErrors();
                    handleSubmit(onSubmit)(e);
                  }}
                />

                <IconButtonWithText
                  title="Publish As A Notification"
                  iconName="upload"
                  isLoading={
                    getValues("isPublishAsNotification") &&
                    getValues("status") === ContentStatus.live &&
                    isAddingOrganisationContent
                  }
                  onPress={(e) => {
                    setValue("status", ContentStatus.live);
                    setValue("isPublishAsNotification", true);
                    clearErrors();
                    handleSubmit(onSubmit)(e);
                  }}
                />
                <Tooltip label={PUBLISH_INFO} openDelay={300} w="380">
                  <Box h="fit-content" cursor="pointer">
                    <Icon
                      size={4}
                      color="primary.400"
                      icon="questioncircleo"
                      iconLibrary={IconLibrary.antDesign}
                    />
                  </Box>
                </Tooltip>
              </HStack>
            )}
          </VStack>
        </WhiteBoxContainer>
      ) : (
        <ScrollView
          px={0}
          bg="white"
          contentContainerStyle={{ paddingBottom: 200 }}
          scrollEventThrottle={100}
        >
          <VStack space={0}>
            <HStack>
              <Image
                source={{ uri: getImage(getValues("image")) }}
                w="100%"
                h="200px"
              />
            </HStack>
            <VStack
              width={{
                lg: "90%",
                xl: "90%",
                md: "90%",
                sm: "95%",
                base: "95%",
              }}
              alignSelf="center"
            >
              <Box py={4} bg="white">
                <VStack space={2}>
                  <Text color="primary.400" fontWeight={600} fontSize={22}>
                    {getValues("title")}
                  </Text>
                </VStack>
              </Box>

              <Divider my={4} mt={2} alignSelf="center" />

              <Box px={4} bg="white" pb={4}>
                <div
                  className="article"
                  dangerouslySetInnerHTML={{
                    __html: getValues("content"),
                  }}
                />
              </Box>
            </VStack>
          </VStack>

          <HStack width="100%" justifyContent="center" space="2">
            <IconButtonWithText
              title="Close Preview"
              iconName="minimize"
              onPress={() => {
                setIsPreviewing(!isPreviewing);
              }}
            />
          </HStack>
        </ScrollView>
      )}
    </>
  );
}
