import { Issue } from "api/src/wellbeing/enum/Issue";
import { WellbeingQuestionAnswer } from "api/src/wellbeing/enum/WellbeingQuestionAnswer";

import { ISSUE_IMAGES } from "../constants/issues";
import { RESILIENCY_TEXT } from "../constants/resiliency-state-text";
import { RequiredImageType } from "../type/RequiredImageType";

/**
 * Get resiliency state text
 *
 * @returns Resiliency state tex
 */
export const getResilienceStateText = (score: number = 0) =>
  RESILIENCY_TEXT.find(({ min }) => score >= min)?.text ?? "";

/**
 * Get label for wellbeing answer
 * @param answer - Wellbeing answer
 * @returns Wellbeing answer label
 */
export const getWellbeingAnswerLabel = (answer: WellbeingQuestionAnswer) => {
  switch (answer) {
    case WellbeingQuestionAnswer.never:
      return "Never";
    case WellbeingQuestionAnswer.rarely:
      return "Rarely";
    case WellbeingQuestionAnswer.sometimes:
      return "Sometimes";
    case WellbeingQuestionAnswer.often:
      return "Often";
    case WellbeingQuestionAnswer.always:
    default:
      return "Always";
  }
};

/**
 * Get issue image
 *
 * @param channel - The issue to get the image for
 *
 * @returns 'require'd issue image
 */
export function getIssueImage(issue: Issue): RequiredImageType {
  return ISSUE_IMAGES[issue];
}
