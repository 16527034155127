import { AlertLevel } from "api/src/signposting/enum/AlertLevel";
import { useEffect } from "react";

import { TRIGGER_WORDS } from "../constants/settings/trigger-words";

import { useFlagUser } from "./useFlagUser";

export function useFlagUserBySearch(searchTerm: string) {
  const { mutate: flagUser } = useFlagUser();

  useEffect(() => {
    if (
      TRIGGER_WORDS.some((x) => x.toLowerCase() === searchTerm.toLowerCase())
    ) {
      flagUser({
        alertLevel: AlertLevel.high,
        triggerWord: searchTerm.toLowerCase(),
      });
    }
  }, [searchTerm]);
}
