import Bugsnag from "@bugsnag/expo";

import App from "./src/App";
import { isWeb } from "./src/util/platform";

/** bugsnag creates a mouse click issue on web  */
if (!isWeb()) {
  Bugsnag.start({ autoDetectErrors: process.env.NODE_ENV !== "development" });
}

export default App;
