import { useNavigation } from "@react-navigation/native";
import { Role } from "api/src/users/enum/role";
import { HStack, Text, Tooltip, useColorModeValue } from "native-base";
import React, { useCallback } from "react";
import { StyleSheet } from "react-native";

import { useHiddenContentIds } from "../../hooks/useHiddenContentIds";
import { useHideContent } from "../../hooks/useHideContent";
import { useMe } from "../../hooks/useMe";
import { useSaveContent } from "../../hooks/useSaveContent";
import { useSavedContentIds } from "../../hooks/useSavedContentIds";
import { isWeb } from "../../util/platform";
import { Button } from "../elements/Button";
import { HideIcon } from "../icons/HideIcon";
import { SaveFileIcon } from "../icons/SaveFileIcon";

interface IContentButtonsProps {
  contentId: string;
  isContentCard?: boolean;
  direction?: "row" | "column" | undefined;
  isEditVisible?: boolean;
}

const style = StyleSheet.create({
  iconStyle: {
    shadowColor: "#F5F5F5",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.4,
    shadowRadius: 100,
    elevation: 16,
  },
});

export function ContentButtons({
  contentId,
  isContentCard = false,
  isEditVisible = false,
  direction = "row",
}: IContentButtonsProps) {
  const { mutate: saveContent, isLoading: saveLoading } = useSaveContent();
  const { data: savedContentIds } = useSavedContentIds();
  const { mutate: hideContent, isLoading: hideLoading } = useHideContent();
  const { data: hiddenContentIds } = useHiddenContentIds();
  const { navigate } = useNavigation();
  const { data: user } = useMe();

  const handleSave = useCallback(() => {
    saveContent({ contentId });
  }, [contentId]);

  const handleHide = useCallback(() => {
    hideContent({ contentId });
  }, [contentId]);

  const handleEdit = () => {
    navigate("ReviewContents", {
      screen: "ReviewContent",
      params: { id: contentId },
    });
  };

  const isSaved = (savedContentIds || [])?.includes(contentId);
  const isHidden = (hiddenContentIds || [])?.includes(contentId);

  const iconColor = useColorModeValue("secondary.850", "secondary.200");
  const iconSize = direction === "column" ? "lg" : "sm";

  const getIconColor = (flag: boolean) => {
    if (isContentCard) {
      return flag ? "primary.600" : "secondary.200";
    }
    return flag ? "white" : iconColor;
  };
  const getButtonBG = (flag: boolean) => {
    if (flag && !isContentCard) {
      return "primary.300";
    }
  };

  return (
    <HStack
      justifyContent={isContentCard ? "flex-end" : "flex-start"}
      space="2"
      mt={direction === "row" ? "0" : "2"}
    >
      {!isHidden ? (
        <Tooltip
          label={isSaved ? "Unsave the content" : "Save the content"}
          openDelay={500}
        >
          <Button
            variant={isContentCard ? "unstyled" : "moodInverted"}
            onPress={handleSave}
            disabled={hideLoading}
            bg={getButtonBG(isSaved)}
            py="1"
            px={isContentCard ? (isSaved ? "2" : "0") : "4"}
          >
            <HStack alignItems="center" justifyContent="center" space="1">
              <SaveFileIcon
                color={getIconColor(isSaved)}
                size={iconSize}
                style={isContentCard && style.iconStyle}
                _dark={{ color: "white" }}
              />
              {!isContentCard && (
                <Text color={isSaved ? "white" : undefined} fontSize="12">
                  {isSaved ? "Unsave" : "Save"}
                </Text>
              )}
            </HStack>
          </Button>
        </Tooltip>
      ) : null}
      {!isSaved ? (
        <Tooltip
          label={isHidden ? "Unhide the content" : "Hide the content"}
          openDelay={500}
        >
          <Button
            variant={isContentCard ? "unstyled" : "moodInverted"}
            onPress={handleHide}
            disabled={saveLoading}
            bg={getButtonBG(isHidden)}
            py="1"
            px={isContentCard ? "0" : "4"}
          >
            <HStack alignItems="center" justifyContent="center" space="1">
              <HideIcon
                color={getIconColor(isHidden)}
                size={iconSize}
                style={isContentCard && style.iconStyle}
                _dark={{ color: "white" }}
              />
              {!isContentCard && (
                <Text fontSize="12" color={isHidden ? "white" : undefined}>
                  {isHidden ? "Unhide" : "Hide"}
                </Text>
              )}
            </HStack>
          </Button>
        </Tooltip>
      ) : null}
      {isEditVisible && isWeb() && user?.role === Role.admin ? (
        <Button
          variant="moodInverted"
          onPress={handleEdit}
          bg="primary.300"
          py="1.8"
          px="8"
        >
          <HStack alignItems="center" space="2">
            <Text fontSize="12" color="white">
              Edit
            </Text>
          </HStack>
        </Button>
      ) : null}
    </HStack>
  );
}
