import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function PencilIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Svg width={24} height={24} fill="none">
        <Path
          d="m18 2 4 4M7.5 20.5 19 9l-4-4L3.5 16.5 2 22l5.5-1.5Z"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
