import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function BrainIcon({ color = "#DF3D8D", ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 33 33" {...props}>
      <Svg width={33} height={33} fill="none" stroke={color}>
        <Path
          d="M16.79 22.908v-12M11.79 18.908a5 5 0 1 1-5 5v-.842"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M21.79 18.908a5 5 0 1 0 5 5v-.841"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M9.79 23.408h-1a6.001 6.001 0 0 1-2-11.659V9.908a5 5 0 1 1 10 0v14M23.79 23.408h1a6.001 6.001 0 0 0 2-11.659V9.908a5 5 0 1 0-10 0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M11.79 11.408v1a3.5 3.5 0 0 1-3.5 3.5M21.79 11.408v1a3.5 3.5 0 0 0 3.5 3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
