import { ContentType } from "api/src/content/enum/ContentType";
import { AlertLevel } from "api/src/signposting/enum/AlertLevel";
import { HStack, VStack, View, Divider, Text } from "native-base";
import React, { useMemo, useState } from "react";

import { ButtonDirectionToggle } from "../components/ButtonDirectionToggle";
import { Container } from "../components/Container";
import { ContentContainer } from "../components/content/ContentContainer.web";
import { ContentSliderWeb } from "../components/ContentSliderWeb";
import { HighAlertBanner } from "../components/HighAlertBanner";
import { Loading } from "../components/Loading";
import { UserInsightsArea } from "../components/UserInsightsArea";
import { UserResilienceScore } from "../components/UserResilienceScore.web";
import { useFlaggedUser } from "../hooks/useFlaggedUser";
import { useSavedContent } from "../hooks/useSavedContent";
import { useWellbeingScore } from "../hooks/useWellbeingScore";
import { Header } from "../layout/Header";
import { getResilienceStateText } from "../util/wellbeing";

export function MyMediaLibraryScreen() {
  const [direction, setDirection] = useState<"column" | "row">("column");
  const { data: flaggedUser } = useFlaggedUser();
  const { data: allSavedContent, isLoading } = useSavedContent({
    type: "all",
    channel: "all",
  });
  const { data: wellbeingScores, isLoading: isLoadingWellbeingScore } =
    useWellbeingScore();
  const resilienceStateText = useMemo(
    () => getResilienceStateText(wellbeingScores?.resilience),
    [wellbeingScores]
  );

  if (isLoading || isLoadingWellbeingScore) {
    return <Loading showHeader={true} />;
  }

  const carousel = allSavedContent?.slice(0, 1) || [];
  const content = allSavedContent?.slice(1) || [];

  return (
    <>
      <Header />
      <Container>
        <View p={10} width="100%" bg="white" _dark={{ bg: "dark.100" }}>
          <HStack>
            <VStack maxW="65%" space={2}>
              <ContentSliderWeb
                items={carousel}
                title="My Page"
                imageHeight={350}
              />
            </VStack>
            <VStack>
              <UserResilienceScore
                text={resilienceStateText}
                percentage={wellbeingScores?.resilience}
                hasBottomText={true}
              />
              <UserInsightsArea />
            </VStack>
          </HStack>
          <HStack space={5} alignItems="center">
            <Text fontSize="lg" textAlign="center" fontWeight={600}>
              My Miindset Library
            </Text>
            <HStack flex="auto" justifyContent="flex-end">
              <ButtonDirectionToggle
                direction={direction}
                onPress={(direction) => setDirection(direction)}
              />
            </HStack>
          </HStack>

          {flaggedUser?.alertLevel === AlertLevel.high ||
          flaggedUser?.alertLevel === AlertLevel.medium ? (
            <HighAlertBanner alertLevel={flaggedUser?.alertLevel} />
          ) : null}
          <Divider orientation="horizontal" mt={2} />

          <ContentContainer
            data={content.filter((c) => c.type === ContentType.article)}
            direction={direction}
            type={ContentType.article}
          />
          <ContentContainer
            data={content.filter((c) => c.type === ContentType.podcast)}
            direction={direction}
            type={ContentType.podcast}
          />
          <ContentContainer
            data={content.filter((c) => c.type === ContentType.video)}
            direction={direction}
            type={ContentType.video}
          />
        </View>
      </Container>
    </>
  );
}
