import React, { ReactNode, useCallback, useMemo, useState } from "react";

import {
  IOnboardingContext,
  OnboardingContext,
} from "../../context/OnboardingContext";
import { OnboardingStep } from "../../enum/signup-step";

interface ITransitionContextProps {
  children: ReactNode;
}

export function OnboardingContextProvider({
  children,
}: ITransitionContextProps) {
  const [currentOnboardingStep, setCurrentOnboardingStep] =
    useState<OnboardingStep>(OnboardingStep.digestMedia);

  const setCurrentStep = useCallback(
    (step: OnboardingStep) => {
      setCurrentOnboardingStep(step);
    },
    [currentOnboardingStep, setCurrentOnboardingStep]
  );

  const onboardingContextValue = useMemo(
    () => ({
      currentStep: currentOnboardingStep,
      setCurrentStep,
    }),
    [currentOnboardingStep, setCurrentOnboardingStep]
  );

  return (
    <OnboardingContext.Provider
      value={onboardingContextValue as IOnboardingContext}
    >
      <>{children}</>
    </OnboardingContext.Provider>
  );
}
