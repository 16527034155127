import { Icon, IIconProps, useColorMode, View } from "native-base";
import React from "react";
import {
  Path,
  Circle,
  Defs,
  LinearGradient,
  Stop,
  Svg,
  ForeignObject,
} from "react-native-svg";

import { isWeb } from "../../util/platform";

export function FlowerLotusIcon(props: IIconProps) {
  const { colorMode } = useColorMode();
  return (
    <Icon {...props}>
      {isWeb() ? (
        <Svg fill="none" viewBox="0 0 45 45">
          <Circle cx={22.5} cy={22.5} r={22.5} fill="url(#a)" />
          <Circle
            cx={22.5}
            cy={22.5}
            r={22.5}
            fill="#EF50A6"
            fillOpacity={0.43}
          />
          <Path
            d="M22.5 32.765c2.081 0 5.523-.023 9.481-2.309 3.96-2.285 5.24-5.148 5.65-6.614a1.036 1.036 0 0 0-.739-1.28c-.96-.246-2.618-.468-4.751.038M12.858 22.6c-2.133-.506-3.791-.284-4.752-.038a1.034 1.034 0 0 0-.738 1.28c.41 1.466 1.69 4.329 5.65 6.614 3.958 2.286 7.4 2.31 9.48 2.31"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M22.5 32.766s5.203-2.838 5.203-10.407c0-5.93-3.194-8.955-4.577-9.988a1.045 1.045 0 0 0-1.252 0c-1.383 1.033-4.577 4.059-4.577 9.988 0 7.568 5.203 10.407 5.203 10.407Z"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M22.5 32.766c1.56-.332 5.766-2.704 8.288-7.07 2.52-4.368 1.9-7.855 1.407-9.371a1.04 1.04 0 0 0-1.206-.697 10.073 10.073 0 0 0-4.079 1.97M18.089 17.597a10.072 10.072 0 0 0-4.079-1.969 1.04 1.04 0 0 0-1.206.697c-.492 1.516-1.114 5.003 1.408 9.37 2.521 4.367 6.728 6.739 8.287 7.07"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Defs>
            <LinearGradient
              id="a"
              x1={41.4}
              y1={9.225}
              x2={6.975}
              y2={38.025}
              gradientUnits="userSpaceOnUse"
            >
              <Stop stopColor="#AB4CD1" />
              <Stop offset={1} stopColor="#E74FAB" />
            </LinearGradient>
          </Defs>
        </Svg>
      ) : (
        <ForeignObject>
          <View>
            <Svg fill="none" viewBox="0 0 45 45">
              <Circle cx={22.5} cy={22.5} r={22.5} fill="url(#a)" />
              <Circle
                cx={22.5}
                cy={22.5}
                r={21.5}
                fill={colorMode === "light" ? "#EF50A6" : "#161F32"}
                strokeWidth={colorMode === "light" ? undefined : 2}
                stroke={colorMode === "light" ? undefined : "#EF50A6"}
                fillOpacity={0.43}
              />
              <Path
                d="M22.5 32.765c2.081 0 5.523-.023 9.481-2.309 3.96-2.285 5.24-5.148 5.65-6.614a1.036 1.036 0 0 0-.739-1.28c-.96-.246-2.618-.468-4.751.038M12.858 22.6c-2.133-.506-3.791-.284-4.752-.038a1.034 1.034 0 0 0-.738 1.28c.41 1.466 1.69 4.329 5.65 6.614 3.958 2.286 7.4 2.31 9.48 2.31"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <Path
                d="M22.5 32.766s5.203-2.838 5.203-10.407c0-5.93-3.194-8.955-4.577-9.988a1.045 1.045 0 0 0-1.252 0c-1.383 1.033-4.577 4.059-4.577 9.988 0 7.568 5.203 10.407 5.203 10.407Z"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <Path
                d="M22.5 32.766c1.56-.332 5.766-2.704 8.288-7.07 2.52-4.368 1.9-7.855 1.407-9.371a1.04 1.04 0 0 0-1.206-.697 10.073 10.073 0 0 0-4.079 1.97M18.089 17.597a10.072 10.072 0 0 0-4.079-1.969 1.04 1.04 0 0 0-1.206.697c-.492 1.516-1.114 5.003 1.408 9.37 2.521 4.367 6.728 6.739 8.287 7.07"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <Defs>
                <LinearGradient
                  id="a"
                  x1={41.4}
                  y1={9.225}
                  x2={6.975}
                  y2={38.025}
                  gradientUnits="userSpaceOnUse"
                >
                  <Stop
                    stopColor={colorMode === "light" ? "#AB4CD1" : "#161F32"}
                  />
                  <Stop
                    offset={1}
                    stopColor={colorMode === "light" ? "#E74FAB" : "#161F32"}
                  />
                </LinearGradient>
              </Defs>
            </Svg>
          </View>
        </ForeignObject>
      )}
    </Icon>
  );
}
