import { ChangeUserDepartmentDto } from "api/src/organisations/dto/change-user-department.dto";
import { useQueryClient, useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

type IOrganisationUserChangeDepartment = Record<string, string>;

export function useOrganisationUserChangeDepartment() {
  const queryClient = useQueryClient();

  return useMutation<
    IApiResponse<IOrganisationUserChangeDepartment>,
    IApiResponse<IOrganisationUserChangeDepartment>,
    ChangeUserDepartmentDto
  >(
    ["organisations-change-user-department"],
    async (data: ChangeUserDepartmentDto) => {
      const response = await api.put<
        IApiResponse<IOrganisationUserChangeDepartment>
      >("organisations/change-user-department", {
        userId: data.userId,
        departmentId: data.departmentId,
        id: data.id,
      });

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["organisation", "all-users"]);
      },
    }
  );
}
