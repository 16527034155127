import { Icon, IIconProps } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

export function LogOutIcon(props: IIconProps) {
  return (
    <Icon viewBox="-5 0 35 30" {...props}>
      <Svg width={30} height={30} fill="none">
        <Path
          d="M19.712 10.242 24.468 15l-4.756 4.758M11.781 15h12.684M11.781 24.969H5.437a.906.906 0 0 1-.906-.907V5.938a.906.906 0 0 1 .907-.906h6.343"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
