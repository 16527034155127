import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import { CommonActions } from "@react-navigation/native";
import { VStack, Text, View, Pressable } from "native-base";
import React from "react";
import { useForm } from "react-hook-form";

import { AuthButton } from "../../components/auth/AuthButton";
import { AuthCard } from "../../components/auth/AuthCard";
import { AuthFooterText } from "../../components/auth/AuthFooterText";
import { AuthInput } from "../../components/auth/AuthInput";
import { AuthContent } from "../../components/AuthContent";
import { useFormErrors } from "../../hooks/useFormErrors";
import { useLogin } from "../../hooks/useLogin";
import { isWeb } from "../../util/platform";

export function LoginScreen() {
  const { dispatch, navigate } = useNavigation();
  const { mutate, isLoading } = useLogin();
  const {
    reset,
    control,
    setError,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const { setErrors } = useFormErrors(setError);

  function onSubmit(data: Record<string, string>) {
    mutate(data, {
      onSuccess: () => {
        dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: !isWeb() ? "Main" : "Auth" }],
          })
        );
      },
      onError: (errorData) => {
        if (errorData.errors?.accountNotActive) {
          AsyncStorage.setItem("email", `${data.email}`.toLowerCase());
          AsyncStorage.setItem("password", data.password);
          dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "ConfirmEmail" }],
            })
          );
          return;
        }

        if (errorData.errors) {
          setErrors(errorData.errors);
        }
      },
    });
  }

  return (
    <AuthContent>
      <AuthCard
        label="Welcome back!"
        subheading="Your personal wellbeing companion"
      >
        <VStack space={2}>
          <AuthInput
            name="email"
            placeholder="Email"
            control={control}
            errors={errors}
            label="Email"
            keyboardType="email-address"
            rules={{ required: "Email is required" }}
          />
          <AuthInput
            px={5}
            name="password"
            borderWidth={0}
            type="password"
            errors={errors}
            control={control}
            placeholder="Password"
            onSubmitEditing={handleSubmit(onSubmit)}
            rules={{ required: "Password is required" }}
          />
          <Pressable
            width="100%"
            maxW={500}
            pr="2"
            mb="5"
            alignSelf="center"
            alignItems="flex-end"
            onPress={() => {
              navigate("ForgotPassword", { email: getValues("email") });
            }}
          >
            <Text fontSize={13}>Forgot your password?</Text>
          </Pressable>

          {isWeb() ? (
            <View alignItems="center">
              <AuthButton
                w="50%"
                onPress={handleSubmit(onSubmit)}
                isLoading={isLoading}
              >
                Sign in
              </AuthButton>
            </View>
          ) : (
            <AuthButton onPress={handleSubmit(onSubmit)} isLoading={isLoading}>
              Sign in
            </AuthButton>
          )}
        </VStack>
      </AuthCard>
      <AuthFooterText
        onPress={() => {
          reset();
          return navigate("Register", { invitationCode: "" });
        }}
      >
        Don't have an account?{" "}
        <Text fontWeight={600} variant="strongHighlight">
          Sign up
        </Text>
      </AuthFooterText>
    </AuthContent>
  );
}
