import { useNavigation } from "@react-navigation/core";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { Dimensions } from "react-native";

import { TransitionContext } from "../../context/TransitionContext";
import { RootStackParamList } from "../../navigation/RootStackParamList";
import { CircleTransition } from "../elements/CircleTransition";

interface ITransitionContextProps {
  children: ReactNode;
}

const { height } = Dimensions.get("window");

export function TransitionContextProvider({
  children,
}: ITransitionContextProps) {
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [nextScreen, setNextScreen] = useState<keyof RootStackParamList | null>(
    null
  );

  const [callback, setCallback] = useState<() => void | null>();
  const { navigate } = useNavigation();

  const startTransition = useCallback(
    ({
      screen,
      callback,
    }: {
      callback?: () => void;
      screen?: keyof RootStackParamList;
    }) => {
      if (screen) {
        setNextScreen(screen);
      }

      if (callback) {
        setCallback(callback);
      }

      setIsTransitioning(true);
    },
    [setNextScreen, setIsTransitioning]
  );

  const authContextValue = useMemo(
    () => ({ isTransitioning, startTransition }),
    [isTransitioning, setIsTransitioning, startTransition]
  );

  return (
    <TransitionContext.Provider value={authContextValue}>
      <>
        {children}
        {isTransitioning && (
          <CircleTransition
            topOffset={height + 50}
            onComplete={() => {
              if (!isTransitioning) {
                return;
              }

              if (nextScreen) {
                navigate(nextScreen);
              }

              if (callback) {
                callback();
              }
              setTimeout(() => setIsTransitioning(false), 50);
            }}
            isOpen={!!isTransitioning}
            startOpen={false}
          />
        )}
      </>
    </TransitionContext.Provider>
  );
}
