import {
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useBreakpointValue } from "native-base";
import React, { memo, useRef } from "react";

import { WEB_NAVIGATION_CONFIG } from "../constants/web-navigation";
import { UnidaysWebRedirectScreen } from "../screens/auth/UnidaysWebRedirectScreen";
import { DownloadAppScreen } from "../screens/DownloadAppScreen";
import { StripeAppScreen } from "../screens/StripeAppScreen";
import { SubscriptionScreen } from "../screens/Subscription";
import { pageHit } from "../util/analytics";
import { isWeb } from "../util/platform";

import { AuthNavigator } from "./AuthNavigator";
import { MainNavigator } from "./MainNavigator";
import { RootStackParamList } from "./RootStackParamList";

const config = WEB_NAVIGATION_CONFIG;

const linking = {
  prefixes: "",
  config,
};

const App = createStackNavigator<RootStackParamList>();

export const RootNavigator = memo(() => {
  const AppScreenComponent = useBreakpointValue({
    base: isWeb() ? SubscriptionScreen : MainNavigator,
    lg: MainNavigator,
  });

  const routeNameRef = useRef<string>();
  const navigationRef = useNavigationContainerRef();

  return (
    <NavigationContainer
      // @ts-ignore
      linking={linking}
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name || "";
      }}
      onStateChange={async () => {
        const currentRouteName = navigationRef.getCurrentRoute()?.name || "";

        if (routeNameRef.current !== currentRouteName) {
          pageHit(currentRouteName);
        }

        routeNameRef.current = currentRouteName;
      }}
    >
      <App.Navigator
        initialRouteName="Auth"
        screenOptions={{
          headerShown: false,
        }}
      >
        <App.Screen name="StripeApp" component={StripeAppScreen} />

        <App.Screen name="Auth" component={AuthNavigator} />
        <App.Screen
          name="UnidaysWebRedirect"
          component={UnidaysWebRedirectScreen}
        />
        <App.Screen name="Main" component={AppScreenComponent} />
        <App.Screen name="DownloadApp" component={DownloadAppScreen} />
      </App.Navigator>
    </NavigationContainer>
  );
});
