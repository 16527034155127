import { useStyledSystemPropsResolver } from "native-base";
import { IViewProps } from "native-base/lib/typescript/components/basic/View/types";
import React, { forwardRef } from "react";
import { View as RNView } from "react-native";

export const View = forwardRef<RNView, React.PropsWithChildren<IViewProps>>(
  ({ children, ...props }: React.PropsWithChildren<IViewProps>, ref) => {
    const [style, otherProps] = useStyledSystemPropsResolver(props);

    return (
      <RNView {...otherProps} style={[style]} ref={ref}>
        {children}
      </RNView>
    );
  }
);
