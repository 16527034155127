import { EditContentDto } from "api/src/content/dto/edit-content.dto";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

export function useUpdatePost(contentId?: string) {
  const queryClient = useQueryClient();
  return useMutation<
    IApiResponse<ContentEntity>,
    IApiResponse<ContentEntity>,
    EditContentDto
  >(
    "auth",
    async ({ ...data }: EditContentDto) => {
      const response = await api.put<IApiResponse<ContentEntity>>(
        `content/review/${contentId}`,
        data
      );

      if (response.status !== 200) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("content");
      },
    }
  );
}
