import { Icon, View } from "native-base";
import React from "react";
import {
  Circle,
  Defs,
  ForeignObject,
  LinearGradient,
  Path,
  Stop,
  Svg,
} from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function ChannelsBadgeIcon(props: IIconProps) {
  return (
    <Icon {...props}>
      <ForeignObject>
        <View>
          <Svg viewBox="0 0 59 59" fill="none">
            <Circle cx={29.5} cy={29.5} r={29.5} fill="url(#a)" />
            <Circle
              cx={29.5}
              cy={29.5}
              r={29.5}
              fill="#EF50A6"
              fillOpacity={0.43}
            />
            <Path
              d="m12.39 35.99 17.996 7.67 17.995-7.67"
              stroke="#fff"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <Path
              d="m12.39 29.5 17.996 7.67L48.38 29.5"
              stroke="#fff"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <Path
              d="m12.39 23.01 17.996 7.67 17.995-7.67-17.995-7.67-17.995 7.67Z"
              stroke="#fff"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <Defs>
              <LinearGradient
                id="a"
                x1={54.28}
                y1={12.095}
                x2={9.145}
                y2={49.855}
                gradientUnits="userSpaceOnUse"
              >
                <Stop stopColor="#AB4CD1" />
                <Stop offset={1} stopColor="#E74FAB" />
              </LinearGradient>
            </Defs>
          </Svg>
        </View>
      </ForeignObject>
    </Icon>
  );
}
