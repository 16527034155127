import React from "react";
import { G, Path } from "react-native-svg";

export function SadMoodSvg({
  x,
  y,
  scale = 1,
}: {
  x: number;
  y: number;
  scale?: number;
}) {
  return (
    <G x={x} y={y} scale={scale}>
      <Path
        d="M130.649 258.2c70.848 0 128.28-57.465 128.28-128.35 0-70.885-57.432-128.35-128.28-128.35S2.369 58.965 2.369 129.85c0 70.885 57.432 128.35 128.28 128.35Z"
        fill="#fff"
        stroke="#C4C4C4"
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M107.585 198.985c12.606-17.904 37.819-17.904 50.425 0 2.611 3.708 7.55 4.46 11.031 1.678 3.481-2.781 4.187-8.041 1.576-11.749-18.91-26.855-56.729-26.855-75.638 0-2.611 3.708-1.906 8.968 1.575 11.749 3.481 2.782 8.42 2.03 11.031-1.678ZM210.16 100.065c.937 6.191-3.005 12.129-8.387 15.8-5.624 3.837-13.641 5.934-23.084 4.449-9.817-1.543-16.793-7.345-20.906-13.976-3.974-6.408-5.61-14.208-3.785-19.989.43-1.358 1.479-2.389 2.785-2.735 1.305-.345 2.687.043 3.662 1.03 14.15 14.307 30.128 17.821 44.454 12.156a3.726 3.726 0 0 1 3.355.309c1.019.631 1.717 1.717 1.906 2.956ZM58.845 100.065c-.937 6.191 3.005 12.129 8.388 15.8 5.623 3.837 13.64 5.934 23.083 4.449 9.817-1.543 16.794-7.345 20.906-13.976 3.975-6.408 5.61-14.208 3.785-19.989-.43-1.358-1.479-2.389-2.785-2.735-1.305-.345-2.687.043-3.662 1.03C94.41 98.951 78.432 102.465 64.106 96.8a3.726 3.726 0 0 0-3.355.309c-1.018.631-1.717 1.717-1.906 2.956Z"
        fill="#C4C4C4"
      />
    </G>
  );
}
