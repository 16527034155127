import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { I_AM_GREAT_ENTRIES } from "api/src/journal/constants/i-am-great-entries";
import { JournalTitleEntry } from "api/src/journal/constants/journal-title-entries";
import { JournalEntryType } from "api/src/journal/enum/JournalEntryType";
import { Text, HStack, VStack } from "native-base";
import React, { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { Button } from "../../components/elements/Button";
import { SaveFileIcon } from "../../components/icons/Icons";
import { JournalCurrentDay } from "../../components/journal/JournalCurrentDay";
import { JournalEntryContainer } from "../../components/journal/JournalEntryContainer";
import { JournalEntryInput } from "../../components/journal/JournalEntryInput";
import { Loading } from "../../components/Loading";
import { useJournalEntry } from "../../hooks/useJournalEntry";
import { useJournalSubmit } from "../../hooks/useJournalSubmit";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { RootStackParamList } from "../../navigation/RootStackParamList";

export function AddIAmGreatEntryScreen() {
  const toast = useToast();
  const { navigate } = useNavigation();
  const {
    params: { journalId },
  } = useRoute<RouteProp<RootStackParamList, "AddIAmGreatEntry">>();

  const { mutate: submitIAmGreatEntry, isLoading: isJournalSubmitLoading } =
    useJournalSubmit(journalId);
  const { data: journal, isLoading } = useJournalEntry(journalId);

  const defaultValues: FieldValues = {
    admireMyselfFor: journal?.iAmGreatData.admireMyselfFor,
    feelGoodAbout: journal?.iAmGreatData.feelGoodAbout,
    feelGoodWhen: journal?.iAmGreatData.feelGoodWhen,
    fellHappyIn: journal?.iAmGreatData.fellHappyIn,
    futureGoals: journal?.iAmGreatData.futureGoals,
    greatAt: journal?.iAmGreatData.greatAt,
    greatBecause: journal?.iAmGreatData.greatBecause,
    greatestTalentIs: journal?.iAmGreatData.greatestTalentIs,
    makesMeLaugh: journal?.iAmGreatData.makesMeLaugh,
    meanALotTo: journal?.iAmGreatData.meanALotTo,
    myFriendsThink: journal?.iAmGreatData.myFriendsThink,
    naturalGiftedAt: journal?.iAmGreatData.naturalGiftedAt,
    othersPraiseMy: journal?.iAmGreatData.othersPraiseMy,
    othersThinkIAmGoodAt: journal?.iAmGreatData.othersThinkIAmGoodAt,
    peaceWhen: journal?.iAmGreatData.peaceWhen,
    reallyEnjoy: journal?.iAmGreatData.reallyEnjoy,
    reasonsToAchieveGoals: journal?.iAmGreatData.reasonsToAchieveGoals,
    succeededBeforeAt: journal?.iAmGreatData.succeededBeforeAt,
    thinkIamGoodAt: journal?.iAmGreatData.thinkIamGoodAt,
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: journalId ? defaultValues : undefined,
  });

  function onSubmit(data: Record<string, string>) {
    const {
      admireMyselfFor,
      feelGoodAbout,
      feelGoodWhen,
      fellHappyIn,
      futureGoals,
      greatAt,
      greatBecause,
      greatestTalentIs,
      makesMeLaugh,
      meanALotTo,
      myFriendsThink,
      naturalGiftedAt,
      othersPraiseMy,
      othersThinkIAmGoodAt,
      peaceWhen,
      reallyEnjoy,
      reasonsToAchieveGoals,
      succeededBeforeAt,
      thinkIamGoodAt,
    } = data;

    return submitIAmGreatEntry(
      {
        type: JournalEntryType.iAmGreat,
        title: JournalTitleEntry.iAmGreat,
        iAmGreat: {
          admireMyselfFor,
          feelGoodAbout,
          feelGoodWhen,
          fellHappyIn,
          futureGoals,
          greatAt,
          greatBecause,
          greatestTalentIs,
          makesMeLaugh,
          meanALotTo,
          myFriendsThink,
          naturalGiftedAt,
          othersPraiseMy,
          othersThinkIAmGoodAt,
          peaceWhen,
          reallyEnjoy,
          reasonsToAchieveGoals,
          succeededBeforeAt,
          thinkIamGoodAt,
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${
              !journalId
                ? "New I am Great Journal created"
                : "I am Great Journal edited"
            }`,
            status: ToastStatus.success,
          });
          navigate("MyJournal");
        },
        onError: () => {
          toast({
            title: `Couldn't ${
              !journalId ? "created" : "edit"
            } I am Great Journal`,
            status: ToastStatus.warning,
          });
        },
      }
    );
  }

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [journal]);

  if (isLoading) {
    return <Loading showHeader={true} />;
  }

  return (
    <JournalEntryContainer>
      <HStack justifyContent="space-between" alignItems="center">
        <Text fontSize={18} fontWeight="500">
          {JournalTitleEntry.iAmGreat}
        </Text>
        <JournalCurrentDay />
      </HStack>
      <VStack space="2" mt="2">
        {I_AM_GREAT_ENTRIES.map(({ label, question }, index) => (
          <JournalEntryInput
            key={index}
            control={control}
            name={question}
            placeholder={label}
          />
        ))}
      </VStack>

      <HStack pt="10" justifyContent="center" space="10">
        <Button
          onPress={handleSubmit(onSubmit)}
          isLoading={isJournalSubmitLoading}
        >
          <HStack alignItems="center" space="1">
            <SaveFileIcon color="white" size={["sm", "md"]} />
            <Text color="white" fontSize={[15, 18]} fontWeight="300">
              {!journalId ? "Save" : "Edit"}
            </Text>
          </HStack>
        </Button>
      </HStack>
    </JournalEntryContainer>
  );
}
