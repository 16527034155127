export const GET_GROUNDED_SCREEN = [
  {
    title: "Get Grounded",
    details:
      "A quick and simple way of distracting your thoughts and bringing you back to now",
    buttons: "Begin",
  },
  {
    title: "Name 3 things around you that you can see",
    details: "Tap once each time you have named a thing",
  },
  {
    title: "Name 3 things around you that you can hear",
    details: "Tap once each time you have named a thing",
  },
  {
    title: "Name 3 things around you that you can touch",
    details: "Tap once each time you have named a thing",
  },
];
