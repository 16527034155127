import { useNavigation } from "@react-navigation/core";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { debounce, isEmpty } from "lodash";
import { Box, Divider, Button, HStack, Text, VStack } from "native-base";
import React, { useCallback, useState } from "react";

import { FilteredSearch } from "../../components/admin/FilteredSearch.web";
import { ReviewableContent } from "../../components/admin/ReviewableContent.web";
import { Loading } from "../../components/Loading";
import { ReviewListScreenHeader } from "../../components/ReviewListScreenHeader";
import { WhiteBoxContainer } from "../../components/WhiteBoxContainer";
import { useReviewContents } from "../../hooks/useReviewContents";
import { Header } from "../../layout/Header";

export function ReviewListScreen() {
  const { navigate } = useNavigation();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [status, setStatus] = useState<string>("All");

  const [channel, setChannel] = useState<string>("All");

  const handleChangeSearchTerm = debounce((term: string) => {
    setDebouncedSearchTerm(term);
  }, 1500);

  const [dateRange, setDateRange] = useState<{
    startingDate: string;
    endingDate: string;
  }>({
    startingDate: "",
    endingDate: "",
  });

  const {
    data: reviewContent,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useReviewContents(
    debouncedSearchTerm,
    status,
    channel,
    dateRange.startingDate,
    dateRange.endingDate
  );
  const onDateRangeSelected = (startDate: string, endDate: string) => {
    setDateRange({
      startingDate: startDate,
      endingDate: endDate,
    });
  };
  const handleStatusChange = (status: string) => {
    setStatus(status);
  };
  const handleChannelChange = (channel: string) => {
    setChannel(channel);
  };
  const handleSearchChange = useCallback((searchValue: string) => {
    setSearchTerm(searchValue);
    handleChangeSearchTerm(searchValue);
  }, []);
  function handleView(content: ContentEntity) {
    navigate("ReviewContent", { id: content.id });
  }
  function handleAdd() {
    navigate("ReviewContent");
  }
  return (
    <>
      <Header />
      <WhiteBoxContainer width="100%">
        <VStack space={5}>
          <VStack alignItems="center" justifyContent="center" space={4}>
            <ReviewListScreenHeader
              title="Content to review"
              subtitle="All of the content can be reviewed here"
            />

            <Button onPress={handleAdd} borderRadius={5} w={100}>
              <HStack alignItems="center" space="2">
                <Text fontSize="13" color="white">
                  Add content
                </Text>
              </HStack>
            </Button>
          </VStack>
          <FilteredSearch
            searchTerm={searchTerm}
            handleChannelChange={handleChannelChange}
            handleSearchChange={handleSearchChange}
            handleStatusChange={handleStatusChange}
            onDateRangeSelected={onDateRangeSelected}
          />
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <VStack space={2}>
                {isEmpty(reviewContent?.pages[0].items) ? (
                  <Text marginTop={10} color="secondary.500" textAlign="center">
                    No content available
                  </Text>
                ) : (
                  reviewContent?.pages.map((page) =>
                    page.items.map((content) => (
                      <Box mx={4} key={content.id}>
                        <ReviewableContent
                          item={content}
                          onView={() => handleView(content)}
                        />

                        <Divider w="50%" alignSelf="center" mb={3} />
                      </Box>
                    ))
                  )
                )}

                {hasNextPage && (
                  <Button
                    variant="outline"
                    width={120}
                    alignSelf="center"
                    my="5"
                    fontSize="sm"
                    isLoading={isFetchingNextPage}
                    onPress={() => fetchNextPage()}
                  >
                    Load More
                  </Button>
                )}
              </VStack>
            </>
          )}
        </VStack>
      </WhiteBoxContainer>
    </>
  );
}
