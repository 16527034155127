import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function CircleTickIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="0 -7 41 40" {...props}>
      <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
        <Path
          d="M21.5 13L14.1625 20L10.5 16.5"
          stroke="#DF3D8D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
          stroke="#DF3D8D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
