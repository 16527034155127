import { IOrganisationLocation } from "api/src/organisations/interfaces/IOrganisationLocations";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationLocations() {
  return useQuery<IOrganisationLocation[]>(
    ["locations"],
    async () => {
      const response = await api.get<IOrganisationLocation[]>(
        "organisations/locations",
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get locations of organisation");
    },
    { keepPreviousData: true }
  );
}
