import { useNavigation } from "@react-navigation/core";
import { ContentStatus } from "api/src/content/enum/ContentStatus";
import { ContentType } from "api/src/content/enum/ContentType";
import dayjs from "dayjs";
import { HStack, Text, Divider, Box, Button, useColorMode } from "native-base";
import React, { useState } from "react";
import { Pressable } from "react-native";

import { useAddViewedContent } from "../../hooks/useAddViewedContent";
import { useDeleteOrganisationContent } from "../../hooks/useDeleteOrganisationContent";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { getContentTypeIcon } from "../../util/content";
import { openUrl } from "../../util/linking";
import { IContentVariantProps } from "../content/Content";
import { AlertDialog } from "../elements/AlertDialog";
import { Icon as CIcon, IconLibrary } from "../elements/Icon";
import { DeleteIcon } from "../icons/DeleteIcon";

export function ReviewableContent({ item }: IContentVariantProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const Icon = item.type ? getContentTypeIcon(item.type) : null;
  const { navigate } = useNavigation();
  const { mutate: viewContent } = useAddViewedContent();
  const { mutate: deleteContent, isLoading } = useDeleteOrganisationContent(
    item.id
  );
  const toast = useToast();
  const { id, url, type, isPublishAsNotification } = item;
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";

  function handleView() {
    if (!id) {
      return;
    }
    if ((url && type === ContentType.podcast) || type === ContentType.video) {
      if (!url) {
        return;
      }
      viewContent({ contentId: id });
      openUrl(url);
      return;
    }
    navigate("ContentDetails", { contentId: id });
  }

  const handleEdit = () => {
    if (!id) {
      return;
    }
    navigate("EditOrganisationContent", { id, type: "organisationAdmin" });
  };
  function handleDelete() {
    deleteContent(
      { contentId: item.id },
      {
        onSuccess: () => {
          toast({ title: "Content Deleted!", status: ToastStatus.success });
        },
        onError: () => {
          toast({
            title: "Could not delete content",
            status: ToastStatus.error,
          });
        },
      }
    );
  }

  return (
    <HStack
      key={id}
      justifyContent="flex-start"
      space="5"
      p="2"
      px="4"
      alignItems="center"
      borderRadius={10}
      bg={
        item.status === ContentStatus.draft ? "secondary.300" : "secondary.100"
      }
      _dark={{
        bg: item.status === ContentStatus.draft ? "dark.300" : "dark.100",
      }}
    >
      <HStack space="5" py="2">
        <Box w="8">
          {isPublishAsNotification ? (
            <CIcon
              icon="bell"
              size={4}
              color="primary.400"
              iconLibrary={IconLibrary.feather}
            />
          ) : Icon ? (
            <Icon color="primary.400" size={5} />
          ) : null}
        </Box>
        <Text width={200} isTruncated={true}>
          {item.title}
        </Text>

        <Pressable onPress={handleEdit}>
          <Box width="100" alignItems="center">
            <Text color="primary.400">Edit</Text>
          </Box>
        </Pressable>
        <Pressable onPress={handleView}>
          <Box width="100" alignItems="center">
            <Text color="primary.400">View</Text>
          </Box>
        </Pressable>
      </HStack>
      <Divider orientation="vertical" bg="secondary.400" />
      <HStack space="5" flex="1" py="2">
        <Text width={200}>{`Created On ${dayjs(item.createdAt).format(
          "DD/MM/YYYY"
        )}`}</Text>
        <Text>{`Author: ${item.author.firstName} ${item.author.lastName}`}</Text>
      </HStack>
      <Button
        justifyContent="center"
        alignItems="center"
        onPress={() => setIsOpen(true)}
        isLoading={isLoading}
        variant="unstyled"
        _hover={{ bg: "transparent" }}
      >
        <DeleteIcon size={6} color={isDarkMode ? undefined : "secondary.800"} />
      </Button>
      <AlertDialog
        headerText="Delete Content"
        bodyText="This will remove content permanently. Are you sure?"
        buttonColor="red.400"
        buttonText="Confirm"
        isOpen={isOpen}
        buttonAction={() => {
          setIsOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </HStack>
  );
}
