import { useNavigation } from "@react-navigation/native";
import { Channel } from "api/src/channels/enum/Channel";
import { ISubChannel } from "api/src/channels/interface/IChannel";
import { OrganisationRole } from "api/src/organisations/enum/organisation-role";
import {
  Box,
  HStack,
  Text,
  Image,
  VStack,
  Menu,
  Pressable,
  View,
} from "native-base";
import React, { useEffect, useState } from "react";
import { Animated } from "react-native";

import AutoCompleteInput from "../components/autocomplete-input/AutoCompleteInput";
import { ChannelsMenu } from "../components/ChannelsMenu";
import { Container } from "../components/Container";
import { DrawerNavigationMenu } from "../components/DrawerNavigationMenu";
import { Icon, IconLibrary } from "../components/elements/Icon";
import { IconButton } from "../components/elements/IconButton";
import {
  ArrowBackIcon,
  MenuIcon,
  MenuWithBellIcon,
} from "../components/icons/Icons";
import { NavigationButton } from "../components/NavigationButton.web";
import { OrganisationMenu } from "../components/organisations/OrganisationMenu";
import { useAnimatedValue } from "../hooks/useAnimatedValue";
import { useMe } from "../hooks/useMe";
import { useUsersUnReadNotificationQuantity } from "../hooks/useUsersUnReadNotificationQuantity";
import { ChannelType } from "../types/channel-type";
import { isWeb } from "../util/platform";

import { WebToaster } from "./WebToaster";

interface IHeaderProps {
  hasBack?: boolean;
  onBackPress?: () => void;
  isLogin?: boolean;
}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

export function WebHeader({
  hasBack = false,
  onBackPress,
  isLogin: isLoggedIn = true,
}: IHeaderProps) {
  const { data: user } = useMe();
  const { goBack, navigate, canGoBack } = useNavigation();
  const drawerMenuAnimation = useAnimatedValue();
  const { data: unReadNotification } = useUsersUnReadNotificationQuantity();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const logoWidth = 120;

  const handleChannelsMenu = (
    slug: Channel | string,
    label: string,
    type?: ChannelType,
    subChannel?: ISubChannel,
    hasSubChannels?: boolean
  ) => {
    if (hasSubChannels) {
      return navigate("SubChannels", {
        slug,
        label,
        type,
      });
    }
    navigate("ChannelDetails", {
      slug,
      label,
      type,
      subChannel,
    });
  };

  const handleOrganisationMenu = (screen: string) => {
    navigate("Organisation", {
      screen,
    });
  };

  useEffect(() => {
    Animated.timing(drawerMenuAnimation, {
      duration: 300,
      useNativeDriver: false,
      toValue: isDrawerOpen ? 1 : 0,
    }).start();

    if (isDrawerOpen && isWeb()) {
      window.document.body.style.position = "fixed";
      window.document.body.style.overflowY = "scroll";
    } else {
      window.document.body.style.position = "relative";
      window.document.body.style.overflow = "auto";
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    if (isWeb()) {
      window.document.body.style.overflowY = "scroll";
    }
  }, [navigate]);

  const back = () => {
    if (canGoBack()) {
      goBack();
    } else {
      navigate("Home");
    }
  };

  return (
    <>
      <View
        bg="primary.400"
        zIndex={20}
        _dark={{
          bg: "dark.100",
          shadow: 4,
        }}
      >
        <Container
          bg="primary.400"
          _dark={{
            bg: "dark.100",
          }}
        >
          <HStack space={5} width="100%" alignItems="center">
            {hasBack ? (
              <IconButton
                onPress={() => {
                  onBackPress ? onBackPress() : back();
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            ) : null}
            {!hasBack && isLoggedIn ? (
              <IconButton
                onPress={() => {
                  setIsDrawerOpen((state) => !state);
                }}
              >
                {unReadNotification && unReadNotification > 0 ? (
                  <MenuWithBellIcon size={8} />
                ) : (
                  <MenuIcon size={8} />
                )}
              </IconButton>
            ) : null}
            <Pressable onPress={() => navigate("Home")}>
              <Box>
                <Image
                  src={require("../assets/images/logo-nav.png")}
                  width={logoWidth}
                  height={logoWidth * 0.202}
                  alt="Miindset-logo-white"
                />
              </Box>
            </Pressable>
            {isLoggedIn ? (
              <HStack w="85%" alignItems="center">
                <NavigationButton title="Home" to="Home" />
                <VStack space={6} alignSelf="flex-start" position="relative">
                  <Menu
                    py={0}
                    closeOnSelect={true}
                    placement="bottom left"
                    shouldOverlapWithTrigger={false}
                    trigger={(triggerProps) => (
                      <Pressable p={1} px={3} py={4} {...triggerProps}>
                        <HStack space={2} alignItems="center">
                          <Text color="white" fontSize={13} fontWeight={500}>
                            Channels
                          </Text>
                          <Icon
                            icon="down"
                            size={3}
                            color="white"
                            iconLibrary={IconLibrary.antDesign}
                          />
                        </HStack>
                      </Pressable>
                    )}
                  >
                    <Menu.Item
                      backgroundColor="transparent"
                      _dark={{
                        backgroundColor: "dark.100",
                      }}
                    >
                      <ChannelsMenu handleChannelsMenu={handleChannelsMenu} />
                    </Menu.Item>
                  </Menu>
                </VStack>
                <Box>
                  {unReadNotification && unReadNotification > 0 ? (
                    <Box
                      position="absolute"
                      top="2"
                      right="0"
                      width="2"
                      height="2"
                      rounded="full"
                      background="white"
                    />
                  ) : null}
                  <NavigationButton title="Insights" to="Insights" />
                </Box>
                {user?.role === "Admin" && (
                  <NavigationButton title="Review" to="ReviewContents" />
                )}
                {user?.organisationUser?.organisationRole ===
                  OrganisationRole.owner && (
                  <VStack space={6} alignSelf="flex-start" position="relative">
                    <Menu
                      py={2}
                      closeOnSelect={true}
                      placement="bottom left"
                      shouldOverlapWithTrigger={false}
                      trigger={(triggerProps) => (
                        <Pressable p={1} px={3} py={4} {...triggerProps}>
                          <HStack space={2} alignItems="center">
                            <Text color="white" fontSize={13} fontWeight={500}>
                              Organisation
                            </Text>
                            <Icon
                              icon="down"
                              size={3}
                              color="white"
                              iconLibrary={IconLibrary.antDesign}
                            />
                          </HStack>
                        </Pressable>
                      )}
                      _dark={{ bg: "dark.400" }}
                    >
                      <OrganisationMenu
                        handleOrganisationMenu={handleOrganisationMenu}
                      />
                    </Menu>
                  </VStack>
                )}
                {isLoggedIn && (
                  <View position="relative" ml="auto">
                    <AutoCompleteInput />
                  </View>
                )}
              </HStack>
            ) : (
              <Box flexDirection="row">
                <NavigationButton title="Home" to="Welcome" />
                <NavigationButton title="Login" to="Login" />
              </Box>
            )}
          </HStack>
        </Container>
      </View>
      <AnimatedPressable
        onPress={() => setIsDrawerOpen(false)}
        pointerEvents={isDrawerOpen ? "auto" : "none"}
        style={[
          {
            top: 0,
            left: 0,
            zIndex: 100,
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "#000",
            opacity: drawerMenuAnimation.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 0.5],
            }),
          },
          { cursor: "initial" },
        ]}
      />
      <Animated.View
        style={{
          top: 0,
          left: 0,
          width: 400,
          zIndex: 100,
          height: "100%",
          position: "absolute",
          transform: [
            {
              translateX: drawerMenuAnimation.interpolate({
                inputRange: [0, 1],
                outputRange: ["-100%", "0%"],
              }),
            },
          ],
        }}
      >
        <DrawerNavigationMenu onPress={() => setIsDrawerOpen(false)} />
      </Animated.View>
      <WebToaster />
    </>
  );
}
