import { useNavigation } from "@react-navigation/core";
import { VStack, Text } from "native-base";
import React from "react";

import { AuthButton } from "../../components/auth/AuthButton";
import { AuthCard } from "../../components/auth/AuthCard";
import { AuthFooterText } from "../../components/auth/AuthFooterText";
import { AuthContent } from "../../components/AuthContent";

export function WelcomeScreen() {
  const { navigate } = useNavigation();

  return (
    <AuthContent>
      <AuthCard label="Welcome to Miindset">
        <VStack space={6}>
          <Text mt={-6} textAlign="center" mb={4}>
            Your personal wellbeing companion
          </Text>

          <VStack space={2} px={5} mt={-4} mb={4} alignSelf="center" maxW={800}>
            <Text fontSize={13} fontWeight={500} textAlign="center">
              Available whenever you need us, with a personally tailored daily
              digest of recent content (Articles Videos & Podcasts) selected for
              how you are feeling in that moment.
            </Text>
            <Text fontSize={12} fontWeight={500} textAlign="center">
              SMS Counselling 24/7 | Habit Tracker | Grounding Exercises |
              Guided Breathing | Journalling | Coping Strategies | Sleep &
              Activity Tracking | Daily Insights
            </Text>
          </VStack>
          <VStack space={2}>
            <AuthButton
              onPress={() => navigate("Register", { invitationCode: "" })}
              variant="invertedAuth"
            >
              Sign up
            </AuthButton>
            <AuthButton
              onPress={() => navigate("Unidays")}
              variant="invertedAuth"
              _pressed={{ opacity: 0.8 }}
            >
              UNIDAYS Sign up
            </AuthButton>
          </VStack>
          <AuthFooterText onPress={() => navigate("Login")}>
            Already have an account?{" "}
            <Text fontWeight={600} color="primary.400">
              Login
            </Text>
          </AuthFooterText>
        </VStack>
      </AuthCard>
    </AuthContent>
  );
}
