import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { SecondLoginContextProvider } from "../components/context/SecondLoginContextProvider";
import { TransitionContextProvider } from "../components/context/TransitionContextProvider";
import { AffirmationScreen } from "../screens/AffirmationScreen";
import { GetGroundedExercise } from "../screens/second-login/GetGroundedExercise";
import { HabitTracker } from "../screens/second-login/HabitTracker";
import { Help } from "../screens/second-login/Help";

import { DigestingScreen } from "./DigestingScreen";

const Auth = createStackNavigator();

export function SecondLoginNavigator() {
  return (
    <>
      <TransitionContextProvider>
        <SecondLoginContextProvider>
          <Auth.Navigator
            initialRouteName="GetGrounded"
            screenOptions={{
              headerShown: false,
            }}
          >
            <Auth.Screen name="GetGrounded" component={GetGroundedExercise} />
            <Auth.Screen name="HabitTracker" component={HabitTracker} />
            <Auth.Screen name="Help" component={Help} />
            <Auth.Screen name="Digesting" component={DigestingScreen} />
            <Auth.Screen name="Affirmation" component={AffirmationScreen} />
          </Auth.Navigator>
        </SecondLoginContextProvider>
      </TransitionContextProvider>
    </>
  );
}
