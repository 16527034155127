import { useNavigation, useRoute } from "@react-navigation/core";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { ContentType } from "api/src/content/enum/ContentType";
import { NotificationEntity } from "api/src/notification/entities/notification.entity";
import { OrganisationRole } from "api/src/organisations/enum/organisation-role";
import { Role } from "api/src/users/enum/role";
import { LinearGradient } from "expo-linear-gradient";
import { first } from "lodash";
import { Text, Image, Pressable, Stack, View, HStack } from "native-base";
import React from "react";

import { CONTENT_LABELS } from "../../constants/content-labels";
import { DEFAULT_CHANNEL_IMAGE } from "../../constants/default-channel-image";
import { useAddViewedContent } from "../../hooks/useAddViewedContent";
import { useMe } from "../../hooks/useMe";
import { ChannelDetailsRouteProp } from "../../navigation/ChannelStackParamList";
import { ChannelType } from "../../types/channel-type";
import { getImage } from "../../util/image";
import { openUrl } from "../../util/linking";

import { ContentButtons } from "./ContentButtons";

interface IContentBannerProps {
  contentData: ContentEntity;
  direction?: "column" | "row";
  channelType?: ChannelType;
  setNotification?: (notification: NotificationEntity) => void;
  notification?: NotificationEntity;
}

export function ContentCard({
  direction,
  contentData,
  channelType,
  setNotification,
  notification,
}: IContentBannerProps) {
  const { data: user } = useMe();
  const { navigate } = useNavigation();
  const { params } = useRoute<ChannelDetailsRouteProp>();
  const { mutate: viewContent } = useAddViewedContent();
  const firstChannel = first(contentData.channels);

  const handleEdit = () => {
    navigate("ReviewContents", {
      screen: "ReviewContent",
      params: { id: contentData?.id },
    });
  };

  const handleEditOrganisation = () => {
    navigate("Organisation", {
      screen: "OrganisationContent",
      // react navigation type error https://reactnavigation.org/docs/nesting-navigators/
      // @ts-ignore
      params: {
        screen: "EditOrganisationContent",
        params: { id: contentData?.id },
      },
    });
  };

  function handlePress() {
    if (!contentData?.id) {
      return;
    }
    if (setNotification && notification) {
      return setNotification(notification);
    }
    if (
      contentData?.url &&
      (contentData?.type === ContentType.podcast ||
        contentData?.type === ContentType.video)
    ) {
      viewContent({ contentId: contentData?.id });
      openUrl(contentData?.url);
      return;
    }
    navigate("ContentDetails", { contentId: contentData?.id });
  }

  const handleChannelPress = () => {
    if (firstChannel !== undefined) {
      navigate("ChannelDetails", firstChannel);
    }
  };

  return (
    <Stack space={4} flexShrink={1} direction={direction} key={contentData.id}>
      <Pressable onPress={handlePress}>
        {({ isHovered }) => (
          <View>
            <Image
              alt="article image"
              height="100%"
              width={direction === "column" ? "100%" : 200}
              source={{
                uri: `https://res.cloudinary.com/tlero/image/upload/${contentData?.image}`,
              }}
              borderRadius={5}
              opacity={isHovered ? 0.9 : 1}
              style={{
                aspectRatio: 16 / 9,
              }}
              fallbackSource={{
                uri: getImage(DEFAULT_CHANNEL_IMAGE),
              }}
            />
            <LinearGradient
              colors={["#00000080", "transparent", "transparent"]}
              style={{ position: "absolute", height: "100%", width: "100%" }}
            />
            <View
              style={{
                top: 5,
                right: 5,
                position: "absolute",
              }}
            >
              {!notification ? (
                <ContentButtons
                  contentId={contentData?.id}
                  isContentCard={true}
                  direction={direction}
                />
              ) : null}
            </View>
          </View>
        )}
      </Pressable>
      <Stack space={2} flexShrink={1}>
        <Text fontWeight="600" fontSize={16} minH={6} numberOfLines={1}>
          {contentData.title}
        </Text>
        <Pressable onPress={handleChannelPress}>
          <Text
            fontSize={[10, 10, 12, 14, 15]}
            cursor={params?.slug ? "auto" : "pointer"}
            fontWeight="600"
            variant="strongHighlight"
          >
            {firstChannel?.label}
          </Text>
        </Pressable>
        <View>
          <Text numberOfLines={2}>{contentData.abstract}</Text>
        </View>
        <HStack space={5}>
          <Pressable onPress={handlePress}>
            <Text variant="strongHighlight">
              {CONTENT_LABELS[contentData?.type]}
            </Text>
          </Pressable>
          {!notification && channelType === "organisation" ? (
            user?.organisationUser?.organisationRole ===
              OrganisationRole.owner ||
            user?.organisationUser?.organisationRole ===
              OrganisationRole.editor ? (
              <Pressable onPress={handleEditOrganisation}>
                <Text variant="strongHighlight">Edit</Text>
              </Pressable>
            ) : null
          ) : !notification && user?.role === Role.admin ? (
            <Pressable onPress={handleEdit}>
              <Text variant="strongHighlight">Edit</Text>
            </Pressable>
          ) : null}
        </HStack>
      </Stack>
    </Stack>
  );
}
