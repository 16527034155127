import DateTimePickerModal from "@dietime/react-native-date-picker";
import dayjs from "dayjs";
import {
  FormControl,
  Text,
  Box,
  HStack,
  Pressable,
  Modal,
  View,
  useColorModeValue,
  useToken,
  useColorMode,
} from "native-base";
import React, { useState } from "react";
import { useController } from "react-hook-form";
import { TimePicker, ValueMap } from "react-native-simple-time-picker";

import { isWeb } from "../../util/platform";
import { useTextColor } from "../../util/theme-helper";
import { Button } from "../elements/Button";
import { Icon, IconLibrary } from "../elements/Icon";

import { IInputProps } from "./Input";

export function DatePicker({
  name,
  errors = {},
  placeholder,
  control,
  startYear = parseInt(dayjs().format("YYYY")) - 100,
  endYear = parseInt(dayjs().format("YYYY")),
  mode = "date",
  ...props
}: IInputProps) {
  const [secondary150, dark200, dark100] = useToken("colors", [
    "secondary.150",
    "dark.200",
    "dark.100",
  ]);

  const { defaultTextColor } = useTextColor();
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";

  const iconColor = useColorModeValue("secondary.700", "secondary.650");
  const datePickerMarkColor = useColorModeValue(secondary150, dark200);
  const datePickerFadeColor = useColorModeValue("white", dark100);
  const [isDatePickerVisible, setIsDatePickerVisible] =
    useState<boolean>(false);

  const { field } = useController({ control, defaultValue: "", name });

  const [timeValue, setTimeValue] = useState<ValueMap>(
    field?.value && mode !== "date"
      ? {
          hours: parseInt(field?.value.split(":")[0]),
          minutes: parseInt(field?.value.split(":")[1].split(" ")[0]),
          seconds: 0,
          ampm: field?.value.split(":")[1].split(" ")[1],
        }
      : {
          hours: 1,
          minutes: 0,
          seconds: 0,
        }
  );

  const errorMessage = errors[name]?.message;

  const handleChange = (newValue: ValueMap) => {
    const hours = newValue.hours < 10 ? `0${newValue.hours}` : newValue.hours;
    const minutes =
      newValue.minutes < 10 ? `0${newValue.minutes}` : newValue.minutes;
    const ampm = newValue.ampm === undefined ? "am" : newValue.ampm;
    field.onChange(`${hours}:${minutes} ${ampm}`);
    setTimeValue(newValue);
  };

  return (
    <>
      <Modal
        paddingX={5}
        isOpen={isDatePickerVisible}
        onClose={() => setIsDatePickerVisible(false)}
      >
        <View
          bg="secondary.50"
          padding={5}
          borderRadius={20}
          _dark={{ bg: "dark.100" }}
        >
          {mode === "time" ? (
            <View
              width={isWeb() ? 300 : 250}
              height={200}
              justifyContent="center"
            >
              <TimePicker
                value={timeValue}
                hoursUnit="h"
                minutesUnit="m"
                onChange={handleChange}
                zeroPadding={true}
                isAmpm={true}
                textColor={defaultTextColor}
              />
            </View>
          ) : (
            <View width={!isWeb() ? "80%" : 250}>
              <DateTimePickerModal
                showMonthName={true}
                startYear={startYear}
                endYear={endYear}
                value={field?.value ? field.value : new Date()}
                fontSize={13}
                onChange={(date) => {
                  field.onChange(date);
                }}
                textColor={defaultTextColor}
                markColor={datePickerMarkColor}
                fadeColor={isDarkMode ? datePickerFadeColor : undefined}
              />
            </View>
          )}
          <Button
            padding={4}
            my={2}
            onPress={() => setIsDatePickerVisible(false)}
          >
            <Text color="white" fontSize={13} fontWeight={600}>
              Continue
            </Text>
          </Button>
        </View>
      </Modal>
      <FormControl isInvalid={!!errorMessage} {...props}>
        <Pressable
          _pressed={{ opacity: 0.8 }}
          onPress={() => {
            setIsDatePickerVisible(true);
          }}
        >
          <Box
            px={5}
            h={50}
            borderWidth={0}
            color="secondary.850"
            borderRadius={10}
            value={field.value}
            pointerEvents="none"
            background="secondary.150"
            placeholderTextColor="white"
            onChangeText={field.onChange}
            {...props}
            alignItems="flex-start"
            justifyContent="center"
            _dark={{
              bg: "dark.200",
            }}
          >
            <HStack justifyContent="space-between" width="100%">
              <Text
                color={field.value ? "secondary.850" : "secondary.700"}
                fontSize={12}
                _dark={{
                  color: field.value ? "secondary.400" : "secondary.650",
                }}
              >
                {field.value
                  ? mode === "date"
                    ? dayjs(field.value).format("DD/MMM/YYYY")
                    : field.value
                  : placeholder}
              </Text>
              <Icon
                iconLibrary={IconLibrary.antDesign}
                icon="calendar"
                size={18}
                color={iconColor}
              />
            </HStack>
          </Box>
        </Pressable>
        {errorMessage ? (
          <FormControl.ErrorMessage justifyContent="center" alignItems="center">
            <Text
              mb={1}
              fontSize={12}
              color="red.500"
              fontWeight={600}
              textAlign="center"
            >
              {errorMessage}
            </Text>
          </FormControl.ErrorMessage>
        ) : null}
      </FormControl>
    </>
  );
}
