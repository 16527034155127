import React from "react";
import Helmet from "react-helmet";

interface IAwinCheckoutProps {
  subtotal: string;
  currencyCode: string;
  orderRef: string;
  saleAmount: string;
  voucherCode?: string;
}
export function AwinCheckout({
  subtotal,
  currencyCode,
  orderRef,
  saleAmount,
  voucherCode,
}: IAwinCheckoutProps) {
  if (!subtotal || !currencyCode) {
    return null;
  }

  return (
    <>
      <img
        src={`https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=31754&amount=${subtotal}&cr=${currencyCode?.toUpperCase()}&ref=${orderRef}&parts=${saleAmount}&vc=${voucherCode}&ch=aw`}
        style={{ border: "0" }}
        width="0"
        height="0"
        alt=""
      />

      <Helmet>
        <script type="text/javascript">
          {`
if (typeof AWIN != "undefined" && typeof AWIN.Tracking != "undefined") {
    AWIN.Tracking.Sale = {};
    AWIN.Tracking.Sale.amount = ${Number(subtotal).toFixed(2)};
    AWIN.Tracking.Sale.channel = "aw";
    AWIN.Tracking.Sale.orderRef = "${orderRef}";
    AWIN.Tracking.Sale.parts = "${saleAmount}";
    AWIN.Tracking.Sale.currency = "${currencyCode?.toUpperCase()}";
    AWIN.Tracking.Sale.voucher = "${voucherCode}";
    AWIN.Tracking.Sale.test = "0";
    AWIN.Tracking.run();
}

`}
        </script>

        <script
          src="https://www.dwin1.com/31754.js"
          type="text/javascript"
          // @ts-ignore
          defer="defer"
        />
      </Helmet>
    </>
  );
}
