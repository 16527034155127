import { useNavigation } from "@react-navigation/core";
import {
  Box,
  Circle,
  HStack,
  StatusBar,
  useColorModeValue,
  useToken,
  VStack,
} from "native-base";
import React from "react";
import { Dimensions, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Pressable } from "../components/core";
import {
  ArrowBackIcon,
  MenuIcon,
  WhiteLogoIcon,
  MenuWithBellIcon,
} from "../components/icons/Icons";
import { useUsersUnReadNotificationQuantity } from "../hooks/useUsersUnReadNotificationQuantity";

interface IHeaderProps {
  hasBack?: boolean;
  onBackPress?: () => void;
  style?: ViewStyle;
}

export function BaseHeader({
  hasBack = false,
  onBackPress,
  style,
}: IHeaderProps) {
  const { goBack, navigate } = useNavigation();
  const insets = useSafeAreaInsets();
  const { data: unReadNotifications } = useUsersUnReadNotificationQuantity();
  const [primary400, dark100] = useToken("colors", ["primary.400", "dark.100"]);
  const backgroundColor = useColorModeValue(primary400, dark100);
  const borderColor = useColorModeValue("white", "dark.500");
  const window = Dimensions.get("window");
  return (
    <>
      <StatusBar backgroundColor={backgroundColor} />
      <Box position="relative" zIndex={10} style={style}>
        <VStack>
          <Box safeAreaTop={true} bg={backgroundColor}>
            <HStack
              px="4"
              py="3"
              justifyContent={!hasBack ? "flex-end" : "space-between"}
              alignItems="center"
              minH={55}
              borderBottomColor={borderColor}
              borderBottomWidth="1"
            >
              {hasBack ? (
                <Pressable
                  _pressed={{ opacity: 0.5 }}
                  onPress={() => {
                    onBackPress ? onBackPress() : goBack();
                  }}
                >
                  <ArrowBackIcon />
                </Pressable>
              ) : null}
              {!hasBack && (
                <Pressable
                  _pressed={{ opacity: 0.5 }}
                  onPress={() => {
                    navigate("MyPage");
                  }}
                >
                  {unReadNotifications && unReadNotifications > 0 ? (
                    <MenuWithBellIcon />
                  ) : (
                    <MenuIcon />
                  )}
                </Pressable>
              )}
            </HStack>
            <Box
              position="absolute"
              zIndex={20}
              alignSelf="center"
              top={30}
              _ios={{ top: window.height < 700 ? insets.top : insets.top + 30 }}
            >
              <Circle
                size="sm"
                bg={backgroundColor}
                borderBottomColor={borderColor}
                borderTopColor={backgroundColor}
                borderLeftColor={backgroundColor}
                _dark={{
                  borderWidth: "1",
                  style: {
                    transform: [{ rotate: "45deg" }],
                  },
                }}
              >
                <WhiteLogoIcon
                  size="lg"
                  viewBox="0 0 48 48"
                  width={48}
                  height={48}
                  _dark={{
                    style: {
                      transform: [{ rotate: "315deg" }],
                    },
                  }}
                />
              </Circle>
            </Box>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
