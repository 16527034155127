import { Box, Text, HStack, useToken } from "native-base";
import React from "react";
import { Controller } from "react-hook-form";
import { Slider } from "react-native-range-slider-expo";

import { IInputProps } from "./Input";

interface IThresholdInputProps extends IInputProps {
  label?: string;
  name: string;
  min?: number;
  max?: number;
  step?: number;
}
export function SliderInput({
  min = 0,
  max = 100,
  step = 4,
  name,
  control,
  label,
}: IThresholdInputProps) {
  const [sliderColor, sliderPositiveColor, knobColor, bubbleColor] = useToken(
    "colors",
    ["primary.400", "primary.400", "primary.200", "white"]
  );

  return (
    <Box>
      <Controller
        control={control}
        name={name}
        defaultValue={0}
        render={({ field: { value, onChange } }) => (
          <>
            <HStack justifyContent="space-between">
              <Text
                px={1}
                mb={-4}
                fontSize={16}
                fontWeight="600"
                color="primary.400"
              >
                {label}
              </Text>
              <Text fontWeight={600} color="primary.400">
                {value}
              </Text>
            </HStack>
            <HStack space={5}>
              <Slider
                min={min}
                max={max}
                step={step}
                styleSize="small"
                initialValue={value}
                knobColor={knobColor}
                outOfRangeBarColor={sliderColor}
                valueLabelsTextColor={bubbleColor}
                rangeLabelsTextColor={sliderColor}
                inRangeBarColor={sliderPositiveColor}
                valueLabelsBackgroundColor={sliderColor}
                valueOnChange={(value: number) => onChange(value)}
              />
            </HStack>
          </>
        )}
      />
    </Box>
  );
}
