import { Editor, EditorState, convertFromHTML, ContentState } from "draft-js";
import "draft-js/dist/Draft.css";
import { stateToHTML } from "draft-js-export-html";
import { Box, FormControl, ScrollView } from "native-base";
import React, { useEffect, useCallback, useState } from "react";
import "../styles.css";
import { useController } from "react-hook-form";

import { InputLabel } from "./InputLabel";
import { IRichEditorProps } from "./RichEditor";

export function RichEditor({
  variant,
  label,
  errors,
  control,
  name,
}: IRichEditorProps) {
  const [updatedValue, setUpdatedValue] = useState<boolean>(false);
  const { field } = useController({ control, defaultValue: "", name });
  const errorMessage = errors[name]?.message;

  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (updatedValue || field.value === "") {
      return;
    }
    const blocksFromHtml = convertFromHTML(field.value ?? "");
    const state = ContentState.createFromBlockArray(
      blocksFromHtml.contentBlocks,
      blocksFromHtml.entityMap
    );

    setEditorState(EditorState.createWithContent(state));
    setUpdatedValue(true);
  }, [field.value, updatedValue]);

  const handleChange = useCallback(
    (newState: EditorState) => {
      setEditorState(newState);
      field.onChange(stateToHTML(newState.getCurrentContent()));
    },
    [setEditorState, field.value]
  );

  return (
    <>
      {label ? <InputLabel label={label} variant={variant} /> : null}

      <ScrollView maxH={400}>
        <Box bg="white" p={4}>
          <Editor
            placeholder="Enter text..."
            editorState={editorState}
            onChange={handleChange}
          />
        </Box>
      </ScrollView>
      {errorMessage ? (
        <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
      ) : null}
    </>
  );
}
