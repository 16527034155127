import { FlagUserDto } from "api/src/signposting/dto/flag-user.dto";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

export function useFlagUser() {
  const queryClient = useQueryClient();

  return useMutation<null, null, FlagUserDto>(
    ["flag-user"],
    async (data: FlagUserDto) => {
      const response = await api.post("signposting/flag-user", data);

      if (response.status === 201) {
        return response.data;
      }

      throw response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["flagged-user"]);
      },
    }
  );
}
