import { CsvEmployeeDataDto } from "api/src/organisations/dto/csv-employee-data.dto";
import { isEmpty } from "lodash";
import { Box, Text, VStack, Image, Modal, Spinner } from "native-base";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDropzone } from "react-dropzone";

import { UploadFileTypes } from "../../enum/upload-file-types";
import { DropBox } from "../DropBox";
import { Button } from "../elements/Button";
import { UploadCompletedIcon } from "../icons/UploadCompletedIcon";

interface IUploadFileProps {
  fileType?: UploadFileTypes;
  error: string | null;
  setError: (error: string | null) => void;
  onFileUpload: (acceptedFiles: File[]) => void;
  downloadFile?:
    | string
    | CsvEmployeeDataDto[]
    | (() => string | CsvEmployeeDataDto[]);
  fileToDownload?: CsvEmployeeDataDto[] | null;
  isBadgeUpdated?: boolean;
  isLoading?: boolean;
}

export function UploadFile({
  fileType = UploadFileTypes.csv,
  error,
  setError,
  onFileUpload,
  downloadFile,
  fileToDownload,
  isBadgeUpdated = false,
  isLoading = false,
}: IUploadFileProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isFileDialogActive,
    acceptedFiles,
  } = useDropzone({
    maxFiles: 1,
    useFsAccessApi: false,
    accept:
      fileType === UploadFileTypes.csv
        ? {
            "text/csv": [".csv"],
          }
        : { "image/*": [".jpg", ".jpeg", ".png"] },
  });
  useEffect(() => {
    if (isFileDialogActive) {
      setError(null);
    }
  }, [isFileDialogActive]);

  const files = acceptedFiles.map((file) => (
    <Text fontWeight={500}>
      {file.name} - {file.size} bytes
    </Text>
  ));

  const hasFileComponent = () => (
    <VStack alignItems="center" justifyContent="center" space="2">
      <UploadCompletedIcon size={50} />
      <Text fontWeight={600}>{files}</Text>
      <Text>Press upload to complete the process.</Text>
    </VStack>
  );
  return (
    <>
      <Box shadow={1}>
        <VStack m={5} space="4">
          <Text textAlign="center" fontWeight={600} fontSize="lg">
            Upload
          </Text>
          <DropBox
            hasFiles={isEmpty(files)}
            supportedFormats={
              fileType === UploadFileTypes.csv ? "CSV" : "Images"
            }
            hasFileComponent={hasFileComponent}
            openInfoModal={setIsModalOpen}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            isFocused={isFocused}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
          <Text alignSelf="center" fontWeight={600} color="red.400">
            {error}
          </Text>
          {downloadFile && isEmpty(files) ? (
            <CSVLink
              data={downloadFile}
              filename="Miindset_Employee_Template.csv"
              style={{ textDecoration: "none", textAlign: "center" }}
            >
              <Text color="primary.400">Download Employee CSV Template</Text>
            </CSVLink>
          ) : null}

          <Button
            onPress={() => onFileUpload(acceptedFiles)}
            isDisabled={!!isEmpty(files)}
          >
            {isLoading ? <Spinner color="white" /> : "UPLOAD FILES"}
          </Button>

          {fileToDownload ? (
            <CSVLink
              data={fileToDownload}
              filename="Miindset_Employee.csv"
              style={{ textDecoration: "none", textAlign: "center" }}
            >
              <Text color="primary.400">Download Employee CSV</Text>
            </CSVLink>
          ) : null}
          {isBadgeUpdated && (
            <Text color="green.700" textAlign="center">
              Company badge is successfully updated
            </Text>
          )}
        </VStack>
      </Box>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Modal.Content maxWidth="800">
          <Modal.CloseButton />
          <Modal.Body>
            {fileType === UploadFileTypes.csv ? (
              <VStack
                width="100%"
                alignItems="center"
                justifyContent="center"
                p="10"
                space={10}
              >
                <Text fontSize="lg" fontWeight={600}>
                  Uploading Help
                </Text>
                <Text>
                  For a smooth upload, ensure that your spreadsheet is laid out
                  like the image below, with at least the First Name, Last Name
                  and Email Address filled up for each employee. Feel free to
                  add any extra columns you find useful.
                </Text>
                <Image
                  h="150"
                  w="600"
                  source={require("../../assets/images/upload-table-example.png")}
                  alt="Upload Table Example"
                />
                <Text>
                  You should download the template shown bellow and edit with
                  your employee data.
                </Text>
                <CSVLink
                  data={downloadFile || ""}
                  filename="Miindset_Employee_Template.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Text textAlign="center" color="primary.400">
                    Download Employee CSV template
                  </Text>
                </CSVLink>
              </VStack>
            ) : (
              <VStack
                width="100%"
                alignItems="center"
                justifyContent="center"
                p="10"
                space={10}
              >
                <Text fontSize="lg" fontWeight={600}>
                  Uploading Help
                </Text>
                <Text>
                  For a smooth upload, you can upload jpg, jpeg and png file
                  types.
                </Text>
              </VStack>
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
}
