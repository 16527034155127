import { Box, Text, VStack, HStack, Image } from "native-base";
import React from "react";

import { useMe } from "../../../hooks/useMe";
import { Header } from "../../../layout/Header";
import { getImage } from "../../../util/image";
import { Container } from "../../Container";
import { OrganisationTabs } from "../OrganisationTabs";

import { UploadBadge } from "./UploadBadge.web";

export function OrganisationSettings() {
  const { data: user, isRefetching } = useMe();

  return (
    <>
      <Header hasBack={false} />
      <Container size="container.xl" h="auto" minH="94vh" pt={10}>
        <Box h="100%" w="100%">
          <OrganisationTabs activeTab="Settings" />

          <HStack justifyItems="center" justifyContent="center" mt="6">
            <VStack padding={10} space={5} w="50%">
              <VStack space={4}>
                {user && !isRefetching ? (
                  <Image
                    source={{
                      uri: getImage(user?.organisationUser.organisation.badge),
                    }}
                    size={100}
                    alignSelf="center"
                    alt={user?.organisationUser.organisation.name}
                  />
                ) : (
                  <Box size={100} />
                )}
                <Text fontSize="md" textAlign="center">
                  Upload the{" "}
                  <Text fontWeight={600} fontSize="lg">
                    Company Badge
                  </Text>{" "}
                  here. {"\n"} The badge will be displayed on the company
                  channel.
                </Text>
              </VStack>
              <UploadBadge />
            </VStack>
          </HStack>
        </Box>
      </Container>
    </>
  );
}
