import { HStack, useColorMode, VStack, Text } from "native-base";
import React from "react";

import { useTextColor } from "../util/theme-helper";

import { Icon, IconLibrary } from "./elements/Icon";
import { Switch } from "./elements/Switch";

export function DarkModeSwitch() {
  const { toggleColorMode, colorMode } = useColorMode();
  const { defaultTextColor } = useTextColor();
  const isDarkMode = colorMode === "dark";

  return (
    <HStack space="4" py="3" px="1" alignItems="center">
      <Icon
        icon="theme-light-dark"
        iconLibrary={IconLibrary.materialCommunity}
        color="secondary.400"
        size={7}
      />
      <VStack flexGrow="1" flexShrink="1" flexBasis="100%">
        <HStack justifyContent="space-between">
          <Text
            fontSize={[15, 16]}
            textAlign="center"
            alignSelf="center"
            color={defaultTextColor}
          >
            Enable Dark Mode
          </Text>
          <Switch size="sm" isChecked={isDarkMode} onToggle={toggleColorMode} />
        </HStack>
      </VStack>
    </HStack>
  );
}
