import AnimatedLottieView from "lottie-react-native";
import {
  Box,
  Center,
  Heading,
  useBreakpointValue,
  useColorModeValue,
  useToken,
  View,
  VStack,
  Text,
} from "native-base";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Animated, TouchableOpacity } from "react-native";
import { View as AnimatedView } from "react-native-animatable";

import { Container } from "../../components/Container";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { FirsLoginContext } from "../../context/FirstLoginContext";
import { TransitionContext } from "../../context/TransitionContext";
import { FirstLoginStepsType } from "../../enum/first-login-steps";

import { FirstLoginSteps } from "./FirstLoginSteps";

const inAnimation = "fadeInDown";

export function WelcomeScreen() {
  const colourAnimation = useRef(new Animated.Value(0)).current;
  const [, setButtonTopOffset] = useState<number>(0);
  const [, setIsTransitioning] = useState<boolean>(false);
  const { setCurrentStep } = useContext(FirsLoginContext);

  useEffect(() => {
    handleAnimation();
    setCurrentStep(FirstLoginStepsType.welcomeScreen);
  }, []);

  function handleAnimation() {
    Animated.timing(colourAnimation, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
    }).start(() => {
      setIsTransitioning(false);
    });
  }

  const [primary400, gray100, dark500] = useToken("colors", [
    "primary.400",
    "secondary.200",
    "dark.500",
  ]);
  const backgroundColor = useColorModeValue(gray100, dark500);

  const backgroundColourValue = colourAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [primary400, backgroundColor],
  });

  const backgroundColorBreakpoint = useBreakpointValue({
    base: "white",
    lg: backgroundColourValue,
  });

  const { startTransition } = useContext(TransitionContext);
  const [animationState] = useState<"In" | "OutDown">("In");

  return (
    <>
      <Animated.View
        style={{
          backgroundColor: backgroundColorBreakpoint,
        }}
      >
        <Container minH="100vh" justifyContent="center">
          <Box
            alignSelf="center"
            p={10}
            width={{ base: "unset", lg: "70%" }}
            bg="white"
            _dark={{ bg: "dark.100" }}
            borderRadius={5}
          >
            <AnimatedView animation="fadeIn" delay={750} duration={750}>
              <FirstLoginSteps />
            </AnimatedView>
            <VStack px={{ base: 0, lg: 4 }} pt={5} space={8} marginTop={-30}>
              <AnimatedView animation={inAnimation} delay={25} duration={750}>
                <View
                  flex={1}
                  onLayout={(event) => {
                    const { layout } = event.nativeEvent;
                    setButtonTopOffset(layout.height / 2 + layout.y);
                  }}
                >
                  <Center>
                    <AnimatedLottieView
                      source={require("../../assets/animations/character.json")}
                      speed={0.75}
                      style={{ width: 400, height: 450 }}
                      autoPlay={true}
                      loop={true}
                    />
                  </Center>
                </View>
              </AnimatedView>
              <View
                pb={10}
                flex="1"
                justifyContent="center"
                px={{ base: 0, lg: 10 }}
                marginTop={-70}
              >
                <VStack space={8}>
                  <AnimatedView
                    style={{ width: "100%" }}
                    animation={`fadeInDown${
                      animationState === "In" ? "" : "Out"
                    }`}
                    delay={1000 + 300 + 400}
                    duration={1000}
                  >
                    <Heading fontSize={28} fontWeight="600" textAlign="center">
                      Welcome To Miindset!
                    </Heading>
                  </AnimatedView>
                  <VStack space={2}>
                    <AnimatedView
                      style={{ width: "100%" }}
                      animation={`fadeInLeft${
                        animationState === "In" ? "" : "Out"
                      }`}
                      delay={1000 + 300 + 500}
                      duration={1500}
                    >
                      <Text textAlign="center" lineHeight={24} fontSize={16}>
                        Well done, you have just completed your first Well Being
                        Check. Each time you complete a Well Being Check, our AI
                        will curate content for you to consume based on the
                        results of how you are feeling.
                      </Text>
                    </AnimatedView>
                  </VStack>
                  <AnimatedView
                    style={{ width: "100%" }}
                    animation={`fade${
                      animationState === "In" ? "InUp" : "OutDown"
                    }`}
                    delay={1000 + 300 + 600}
                    duration={2000}
                  >
                    <Center mt={6}>
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={() => {
                          startTransition({
                            screen: "FirstLoginDigestMedia",
                            callback: () => {
                              setCurrentStep(FirstLoginStepsType.digestMedia);
                            },
                          });
                        }}
                      >
                        <ArrowIcon size="4xl" />
                      </TouchableOpacity>
                    </Center>
                  </AnimatedView>
                </VStack>
              </View>
            </VStack>
          </Box>
        </Container>
      </Animated.View>
    </>
  );
}
