import { Circle, Text } from "native-base";
import React from "react";
import { DateData } from "react-native-calendars";
import { MarkingProps } from "react-native-calendars/src/calendar/day/marking";
import { DayState } from "react-native-calendars/src/types";

interface ICalendarDayProps {
  date: DateData;
  marking: MarkingProps;
  state: DayState;
}

export function CalendarDay({ date, marking, state }: ICalendarDayProps) {
  return (
    <Circle
      alignItems="center"
      borderColor="secondary.400"
      borderWidth="0.5"
      size="10"
      bg={marking?.marked ? "primary.400" : "white"}
    >
      {state === "disabled" ? (
        <Text color="secondary.400" textAlign="center">
          {date?.day}
        </Text>
      ) : (
        <Text
          textAlign="center"
          color={marking?.marked ? "white" : "secondary.800"}
        >
          {date?.day}
        </Text>
      )}
    </Circle>
  );
}
