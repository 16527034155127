/**
 * Convert a string into title case
 * @param string
 * @returns {string} The title case version of a string
 */
export function toTitleCase(string: string): string {
  return string.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}
