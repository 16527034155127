import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function CloseCircularIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 41 41" {...props}>
      <Svg width={41} height={41} fill="none">
        <Path
          d="M27.443 14.206a.321.321 0 0 0-.32-.32l-2.643.012-3.98 4.744-3.976-4.74-2.646-.012c-.177 0-.32.14-.32.32 0 .076.027.148.075.208l5.21 6.206-5.21 6.202a.321.321 0 0 0 .244.529l2.647-.012 3.976-4.745 3.976 4.74 2.643.013c.176 0 .32-.14.32-.32a.333.333 0 0 0-.076-.209l-5.201-6.202 5.209-6.206a.32.32 0 0 0 .072-.208Z"
          fill="#DF3D8D"
        />
        <Path
          d="M20.5 2.603c-9.906 0-17.938 8.031-17.938 17.937S10.595 38.478 20.5 38.478c9.906 0 17.938-8.032 17.938-17.938 0-9.906-8.032-17.937-17.938-17.937Zm0 32.832c-8.224 0-14.895-6.67-14.895-14.895 0-8.224 6.671-14.894 14.895-14.894s14.895 6.67 14.895 14.894S28.724 35.435 20.5 35.435Z"
          fill="#DF3D8D"
        />
      </Svg>
    </Icon>
  );
}
