import { MAX_CHANNELS } from "api/src/wellbeing/constants/max-channels";
import { Issue } from "api/src/wellbeing/enum/Issue";
import {
  Box,
  Center,
  Flex,
  Text,
  View,
  VStack,
  useBreakpointValue,
} from "native-base";
import React, { useCallback, useMemo, useState } from "react";
import { TouchableOpacity } from "react-native";
import { View as AnimatedView } from "react-native-animatable";

import { ISSUES } from "../../constants/issues";
import { IssueSelection } from "../ChannelSelection";
import { ArrowIcon } from "../icons/ArrowIcon";

interface IChannelSelectionProps {
  onComplete: (issues: Issue[]) => void;
}

export function ChannelSelectionPage({ onComplete }: IChannelSelectionProps) {
  const [isEntering] = useState<boolean>(true);
  const [activeIssues, setActiveIssues] = useState<
    Partial<Record<Issue, boolean>>
  >({});

  const enabledIssues = useMemo(
    () => Object.entries(activeIssues).filter(([, isActive]) => isActive),
    [activeIssues]
  );

  const handleComplete = useCallback(() => {
    if (enabledIssues.length < 3) {
      return;
    }

    onComplete(enabledIssues.map(([issue]) => issue) as Issue[]);
  }, [enabledIssues]);

  const handleIssuePressed = useCallback(
    (question: Issue) => {
      if (!activeIssues[question] && enabledIssues.length >= 3) {
        return;
      }

      setActiveIssues({
        ...activeIssues,
        [question]: !activeIssues[question],
      });
    },
    [activeIssues]
  );

  const currentAnimation = isEntering ? "fadeIn" : "fadeOut";

  const rowNumber = useBreakpointValue({
    base: "50%",
    lg: "30%",
    xl: "30%",
  });

  return (
    <VStack space={8} pb={5}>
      <VStack space={2}>
        <AnimatedView
          animation={currentAnimation}
          delay={0}
          onAnimationEnd={!isEntering ? onComplete : undefined}
          duration={750}
        >
          <Text textAlign="center" fontSize={16} fontWeight="600">
            Question 11.
          </Text>
        </AnimatedView>
        <AnimatedView animation={currentAnimation} delay={200} duration={750}>
          <VStack alignItems="center" justifyContent="center" space={2}>
            <Text fontSize={16} fontWeight="400">
              What's on your mind?
            </Text>
            <Text fontSize={14} color="secondary.650">
              Select {MAX_CHANNELS} answers
            </Text>
          </VStack>
        </AnimatedView>
      </VStack>

      <AnimatedView animation={currentAnimation} delay={300} duration={750}>
        <VStack space={8}>
          <Flex
            flex={1}
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
          >
            {ISSUES.map((issue) => (
              <Box flexBasis={rowNumber} key={issue.label}>
                <View m="3%" alignItems="center">
                  <IssueSelection
                    onPress={() => handleIssuePressed(issue.slug)}
                    issue={issue}
                    isActive={activeIssues[issue.slug]}
                  />
                </View>
              </Box>
            ))}
          </Flex>
          {enabledIssues.length >= 3 ? (
            <Center>
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={() => handleComplete()}
              >
                <ArrowIcon size="4xl" />
              </TouchableOpacity>
            </Center>
          ) : null}
        </VStack>
      </AnimatedView>
    </VStack>
  );
}
