import { Icon, IIconProps } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

export function AddIcon(props: IIconProps) {
  return (
    <Icon viewBox="0 0 34 34" {...props}>
      <Svg width={34} height={34} fill="none">
        <Path
          d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12ZM11 16h10M16 11v10"
          stroke="#DF3D8D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
