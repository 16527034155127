import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

interface IFlowerLotusLineIconProps extends IIconProps {
  strokeOpacities: number[];
}

export function FlowerLotusLineIcon({
  strokeOpacities,
  ...props
}: IFlowerLotusLineIconProps) {
  return (
    <Icon viewBox="0 0 208 208" {...props}>
      <Svg width={208} height={208} fill="none">
        <Path
          d="M104 162.5C117 162.5 138.495 162.353 163.223 148.077C187.95 133.8 195.952 115.916 198.513 106.76C198.743 105.932 198.805 105.067 198.697 104.215C198.589 103.363 198.312 102.541 197.882 101.797C197.453 101.053 196.879 100.402 196.195 99.8822C195.512 99.3624 194.731 98.984 193.899 98.7692C187.902 97.2319 177.544 95.8422 164.219 99.0032"
          stroke="#EF50A6"
          strokeOpacity={strokeOpacities[2]} // THIRD
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M43.7755 99.0027C30.4515 95.8424 20.0947 97.2319 14.0973 98.7692C13.2656 98.984 12.485 99.3624 11.8011 99.8822C11.1173 100.402 10.5439 101.053 10.1144 101.797C9.68487 102.541 9.4079 103.363 9.29962 104.215C9.19135 105.067 9.25394 105.932 9.48375 106.76C12.0443 115.916 20.0461 133.8 44.7736 148.077C69.5011 162.353 90.9965 162.5 103.996 162.5"
          stroke="#EF50A6"
          strokeOpacity={strokeOpacities[1]} // SECOND
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M104 162.5C104 162.5 136.5 144.773 136.5 97.5C136.5 60.4627 116.55 41.5616 107.911 35.1131C106.782 34.2682 105.41 33.8115 104 33.8115C102.59 33.8115 101.218 34.2682 100.089 35.1131C91.4499 41.5616 71.5 60.4627 71.5 97.5C71.5 144.773 104 162.5 104 162.5Z"
          stroke="#EF50A6"
          strokeOpacity={strokeOpacities[0]} // FIRST
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M104 162.5C113.742 160.428 140.018 145.614 155.767 118.337C171.515 91.06 167.636 69.279 164.56 59.806C164.065 58.2775 163.02 56.9868 161.629 56.1834C160.238 55.3801 158.598 55.1211 157.026 55.4567C147.711 57.5686 138.998 61.774 131.549 67.7538"
          stroke="#EF50A6"
          strokeOpacity={strokeOpacities[2]} // THIRD
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M76.4501 67.7536C69.0014 61.7738 60.2889 57.5686 50.9733 55.4567C49.402 55.1211 47.7621 55.3801 46.3707 56.1834C44.9792 56.9868 43.935 58.2775 43.4399 59.806C40.3633 69.279 36.4844 91.06 52.233 118.337C67.9815 145.614 94.2576 160.428 104 162.5"
          stroke="#EF50A6"
          strokeOpacity={strokeOpacities[1]} // SECOND
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
