import { Platform } from "react-native";

/**
 * Check if the current platform is `ios`
 *
 * @returns {boolean}
 */
export const isiOS = (): boolean => Platform.OS === "ios";

/**
 * Check if the current platform is `web`
 *
 * @returns {boolean}
 */
export const isWeb = (): boolean => Platform.OS === "web";

/**
 * Check if the current platform is not `web`
 *
 * @returns {boolean}
 */
export const isNotWeb = (): boolean => !isWeb();

/**
 * Check if the current platform is `android`
 *
 * @returns {boolean}
 */
export const isAndroid = (): boolean => Platform.OS === "android";
