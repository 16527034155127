import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { MyPageScreen } from "../screens/MyPageScreen";
import { NotificationsAlertScreen } from "../screens/NotificationAlertScreen";

const MyPage = createStackNavigator();
export function MyPageNavigator() {
  return (
    <MyPage.Navigator
      initialRouteName="MyPageMain"
      screenOptions={{
        headerShown: false,
      }}
    >
      <MyPage.Screen name="MyPageMain" component={MyPageScreen} />
      <MyPage.Screen
        name="NotificationAlert"
        component={NotificationsAlertScreen}
      />
    </MyPage.Navigator>
  );
}
