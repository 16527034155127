import { ContentEntity } from "api/src/content/entity/content.entity";
import { ContentStatus } from "api/src/content/enum/ContentStatus";
import { ContentType } from "api/src/content/enum/ContentType";
import { useInfiniteQuery } from "react-query";

import { IPaginatedResponse } from "../interfaces/IPaginatedResponse";
import { api } from "../util/api";

export interface IUseOrganisationContents {
  limit?: number;
  status?: ContentStatus;
  title?: string;
  filter?: ContentType;
}

export function useOrganisationContents({
  limit = 10,
  status = ContentStatus.live,
  title = "",
  filter,
}: IUseOrganisationContents) {
  return useInfiniteQuery<IPaginatedResponse<ContentEntity>>(
    ["content", "all-contents", status],
    async ({ pageParam = 1 }) => {
      const response = await api.get<IPaginatedResponse<ContentEntity>>(
        "content/organisation-contents",
        { params: { page: pageParam, limit, status, title, filter } }
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get organisation contents");
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.currentPage < lastPage.meta.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
      },
    }
  );
}
