import { useNavigation } from "@react-navigation/core";
import { ContentType } from "api/src/content/enum/ContentType";
import {
  AspectRatio,
  Box,
  Center,
  Image,
  Pressable,
  Text,
  VStack,
} from "native-base";
import React from "react";

import { getChannelsLabel } from "../../util/channel";
import { getImage } from "../../util/image";

import { IContentVariantProps } from "./Content";
import { ContentIcon } from "./ContentIcon";

export function ContentLarge({
  item: { image, channels, title, type = ContentType.article },
  onPress,
  onLongPress,
}: IContentVariantProps) {
  const { navigate } = useNavigation();
  return (
    <Box flex="1">
      <Pressable
        onLongPress={onLongPress}
        onPress={onPress}
        _pressed={{ opacity: 0.8 }}
        borderRadius={5}
        py={4}
        px={2}
      >
        <VStack space="1">
          <AspectRatio ratio={16 / 9}>
            <Image
              source={{ uri: getImage(image) }}
              alt={title}
              borderRadius={14}
              w="100%"
              h="auto"
              maxH={200}
            />
          </AspectRatio>
          {ContentIcon ? (
            <Box
              position="absolute"
              zIndex="10"
              alignSelf="flex-end"
              top={2}
              right={2}
            >
              <ContentIcon type={type} />
            </Box>
          ) : null}
          <Center>
            <Text
              fontSize={16}
              textAlign="center"
              fontWeight="400"
              numberOfLines={2}
            >
              {title}
            </Text>
          </Center>
          <Center>
            <Pressable
              onPress={() => {
                const channel = channels && channels.length > 0 && channels[0];
                if (channel) {
                  const { slug, label } = channel;
                  navigate("ChannelDetails", { slug, label });
                }
              }}
            >
              <Text
                fontSize="14"
                textAlign="center"
                color="primary.400"
                fontWeight="600"
              >
                {getChannelsLabel(channels)}
              </Text>
            </Pressable>
          </Center>
        </VStack>
      </Pressable>
    </Box>
  );
}
