import { VStack, Box, Text, Select, Heading } from "native-base";
import React from "react";

import { SemiCircleProgress } from "../../../elements/SemiCircularProgress";

interface IOrganisationResilienceScore {
  percentage: number;
  filterOptions: {
    label: string;
    value: string;
  }[];
  onFilterChange: () => void;
}

export function OrganisationResilienceScore({
  percentage,
  filterOptions,
  onFilterChange,
}: IOrganisationResilienceScore) {
  return (
    <VStack alignItems="center" space={3}>
      <Heading fontSize={18} fontWeight={600} textAlign="center">
        Average Organisation Resilience
      </Heading>
      <Box pb="2">
        <SemiCircleProgress percentage={percentage * 100} progressWidth={5} />
        <Box position="absolute" alignSelf="center" bottom="-10">
          <Text fontSize="50" fontWeight="600">
            {(percentage * 100).toFixed(0)}
          </Text>
        </Box>
      </Box>
      {filterOptions.length ? (
        <Select
          placeholder="Filter"
          variant="rounded"
          width="80%"
          onValueChange={onFilterChange}
          borderColor="secondary.400"
          placeholderTextColor="secondary.650"
          pl={4}
        >
          {filterOptions.map(({ label, value }) => (
            <Select.Item label={label} value={value} />
          ))}
        </Select>
      ) : null}
    </VStack>
  );
}
