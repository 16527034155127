/** The expo push notification token */
export const EXPO_PUSH_NOTIFICATION_TOKEN = "expo-push-notification-token";

/**
 * The expo push notification token that we keep in memory, even if it's been deleted
 * As there is no need to talk to expos servers again if we know the token ahead of time.
 * This also allows us to restore the token on login.
 */
export const EXPO_PUSH_NOTIFICATION_TOKEN_CACHED =
  "expo-push-notification-token-cached";

/* Last time the user viewed the notification enable screen */
export const LAST_VIEW_NOTIFICATION_SCREEN =
  "last-viewed-notification-enable-screen";

/* Well being well done screen key */
export const WELLBEING_WELL_DONE_SCREEN_KEY = "wellbeing-welldone-screen";

/* Payment reminder for subscription before 3 days of trial ends */
export const PAYMENT_REMINDER_SCREEN_KEY = "payment-reminder-screen";

/* Verify payment reminder value that it is viewed or not */
export const VERIFY_PAYMENT_REMINDER = "verify-payment-reminder";
