import { DigestType } from "api/src/wellbeing/enum/DigestType";
import { VStack, Text, HStack, Pressable, View, Box } from "native-base";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AuthCard } from "../../components/auth/AuthCard";
import { Container } from "../../components/Container";
import { DigestItem } from "../../components/digest-media/DigestItem";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { HeadphonesIcon } from "../../components/icons/HeadphonesIcon";
import { OpenBookIcon } from "../../components/icons/OpenBookIcon";
import { VideoCameraIcon } from "../../components/icons/VideoCameraIcon";
import { OnboardingSteps } from "../../components/OnboardingSteps";
import { OnboardingContext } from "../../context/OnboardingContext";
import { TransitionContext } from "../../context/TransitionContext";
import { OnboardingStep } from "../../enum/signup-step";
import { useBackButton } from "../../hooks/useBackButton";
import { useDigestOrderSubmit } from "../../hooks/useDigestOrderSubmit";
import { IIconProps } from "../../interfaces/IIconprops";

interface IDigestType {
  label: string;
  type: DigestType;
  icon: (icon: IIconProps) => JSX.Element;
}

const DIGEST_TYPES: IDigestType[] = [
  {
    label: "Read",
    type: DigestType.read,
    icon: OpenBookIcon,
  },
  {
    label: "Watch",
    type: DigestType.watch,
    icon: VideoCameraIcon,
  },

  {
    label: "Listen",
    type: DigestType.listen,
    icon: HeadphonesIcon,
  },
];

export function DigestMediaScreen() {
  const [activeItems, setActiveItems] = useState<DigestType[]>([]);
  const { setCurrentStep } = useContext(OnboardingContext);
  const { mutate: submitDigest, isLoading } = useDigestOrderSubmit();
  useBackButton(() => true);

  const { startTransition } = useContext(TransitionContext);

  useEffect(() => {
    setCurrentStep(OnboardingStep.digestMedia);
  }, []);

  const handlePress = useCallback(
    (value: DigestType) => {
      const existingItem = activeItems.findIndex((item) => item === value);

      if (existingItem !== -1) {
        setActiveItems(activeItems.filter((item) => item !== value));
        return;
      }

      setActiveItems([...activeItems, value]);
    },
    [activeItems]
  );

  const handleSubmit = useCallback(() => {
    submitDigest(
      { digestOrder: activeItems },
      {
        onSuccess: () => {
          startTransition({
            screen: "WellbeingCheckerOnboarding",
            callback: () => {
              setCurrentStep(OnboardingStep.wellbeing);
            },
          });
        },
      }
    );
  }, [activeItems, submitDigest, startTransition, setCurrentStep]);

  const isButtonActive = useMemo(() => activeItems.length >= 3, [activeItems]);

  return (
    <View
      bg={{ base: "white", lg: "secondary.200" }}
      minH="100vh"
      justifyContent="center"
    >
      <Container minH="unset">
        <Box
          alignSelf="center"
          p={10}
          width={{ base: "unset", lg: "70%" }}
          bg="white"
          borderRadius={5}
        >
          <OnboardingSteps />

          <VStack justifyContent="center" alignItems="center" pt={10}>
            <AuthCard label="How do you like to digest your media?">
              <VStack space={8} alignItems="center">
                <VStack space={2} px={2} mb={4} mt={-4}>
                  <Text fontWeight={400} textAlign="center">
                    Select all 3 in order of preference
                  </Text>
                </VStack>
                <VStack space={16} alignItems="center">
                  <HStack space={4} justifyContent="space-between" px={10}>
                    {DIGEST_TYPES.map(({ label, icon, type }) => (
                      <DigestItem
                        label={label}
                        icon={icon}
                        key={label}
                        onPress={() => handlePress(type)}
                        activeIndex={
                          activeItems.findIndex((value) => value === type) + 1
                        }
                      />
                    ))}
                  </HStack>
                  <>
                    <Pressable
                      _pressed={isButtonActive ? { opacity: 0.7 } : {}}
                      opacity={isButtonActive ? 1 : 0}
                      disabled={!isButtonActive || isLoading}
                      onPress={handleSubmit}
                    >
                      <ArrowIcon size="4xl" />
                    </Pressable>
                  </>
                </VStack>
              </VStack>
            </AuthCard>
          </VStack>
        </Box>
      </Container>
    </View>
  );
}
