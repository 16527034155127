export enum Channel {
  womenHealth = "women-health",
  work = "work",
  food = "food",
  lgbtq = "lgbtq",
  money = "money",
  sleep = "sleep",
  sport = "sport",
  ageing = "ageing",
  neurodiversity = "neurodiversity",
  diversity = "diversity",
  travel = "travel",
  opinion = "opinion",
  education = "education",
  mentalHealth = "mental-health",
  entertainment = "entertainment",
  physicalHealth = "physical-health",
  scienceAndTechnology = "science-and-technology",
  familyAndRelationships = "family-and-relationships",
}

export enum WomenHealthSubChannel {
  fertitlitPregnancyAndPostnatalSupport = "fertility-pregnancy-and-postnatal-support",
  sexualHealth = "sexual-health",
  familyAndRelationships = "family-and-relationships",
  empowerment = "empowerment",
  nonBinaryAndTrans = "non-binary-and-trans",
  nutritionDietAndEating = "nutrion-diet-and-eating",
  healthyAgeingAndLongTermCondition = "healthy-ageing-and-long-term-condition",
  premenopauseMenopauseAndPerimenopause = "premenopause-menopause-and-perimenopause",
  diseaseHealthAndWellness = "disease-health-and-wellness",
  healthAndBeauty = "health-and-beauty",
}
