import { useNavigation, useRoute } from "@react-navigation/core";
import { RouteProp } from "@react-navigation/native";
import { VStack, View } from "native-base";
import React from "react";
import { FieldValues, useForm } from "react-hook-form";

import { AuthButton } from "../../components/auth/AuthButton";
import { AuthCard } from "../../components/auth/AuthCard";
import { AuthInput } from "../../components/auth/AuthInput";
import { AuthContent } from "../../components/AuthContent";
import { useForgotPasswordValidateEmail } from "../../hooks/useForgotPasswordValidateEmail";
import { useFormErrors } from "../../hooks/useFormErrors";
import { RootStackParamList } from "../../navigation/RootStackParamList";
import { isWeb } from "../../util/platform";

export function ForgotPasswordScreen() {
  const { navigate } = useNavigation();

  const { params } =
    useRoute<RouteProp<RootStackParamList, "ForgotPassword">>();

  const defaultValues: FieldValues = {
    email: params?.email,
  };
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: params?.email ? defaultValues : undefined,
  });
  const { setErrors } = useFormErrors(setError);

  const { mutate: validateEmail, isLoading } = useForgotPasswordValidateEmail();

  function onSubmit(data: Record<string, string>) {
    validateEmail(
      {
        ...data,
      },
      {
        onSuccess: () => {
          navigate("ResetPassword", { email: data?.email });
        },
        onError: (data) => {
          // @ts-ignore
          setErrors(data.errors ?? data ?? {});
        },
      }
    );
  }

  return (
    <AuthContent>
      <AuthCard
        label="Forgot your password?"
        subheading="We can help you recover your account"
      >
        <VStack space={2}>
          <AuthInput
            name="email"
            placeholder="Email"
            control={control}
            errors={errors}
            keyboardType="email-address"
          />

          {isWeb() ? (
            <View alignItems="center">
              <AuthButton
                w="50%"
                onPress={handleSubmit(onSubmit)}
                isLoading={isLoading}
              >
                Verify
              </AuthButton>
            </View>
          ) : (
            <AuthButton onPress={handleSubmit(onSubmit)} isLoading={isLoading}>
              Verify
            </AuthButton>
          )}
        </VStack>
      </AuthCard>
    </AuthContent>
  );
}
