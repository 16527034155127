import { Icon, IIconProps } from "native-base";
import React from "react";
import { Path, Svg, Circle } from "react-native-svg";

export function MenuBarIcon(props: IIconProps) {
  return (
    <Icon viewBox="0 0 85 85" {...props}>
      <Svg width="85" height="85" viewBox="0 0 85 85" fill="none">
        <Circle cx="42.5" cy="42.5" r="42.5" fill="#DF3D8D" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.1338 39.9956H56.1338H34.1338Z"
          fill="white"
        />
        <Path
          d="M34.1338 39.9956H56.1338"
          stroke="white"
          strokeWidth="2.44444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.4004 28.2627H56.1337H44.4004Z"
          fill="white"
        />
        <Path
          d="M44.4004 28.2627H56.1337"
          stroke="white"
          strokeWidth="2.44444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5 52.4624H56.5H23.5Z"
          fill="white"
        />
        <Path
          d="M23.5 52.4624H56.5"
          stroke="white"
          strokeWidth="2.44444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
