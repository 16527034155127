import { Icon } from "native-base";
import React, { useEffect, useState } from "react";
import { G, Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function ShoutIcon({ ...props }: IIconProps) {
  const [isShout, setIsShout] = useState<boolean>(true);

  useEffect(() => {
    const shoutInterval = setInterval(() => {
      setIsShout((value) => !value);
    }, 2000);

    return () => {
      clearInterval(shoutInterval);
    };
  }, []);

  return (
    <Icon viewBox="0 0 108 51" {...props}>
      {isShout ? (
        <Svg width={108} height={51} fill="none">
          <G fill="#7B61FF">
            <Path
              d="M4 17.3873C4 7.78457 12.3947 0 22.75 0H85.25C95.6053 0 104 7.78457 104 17.3873C104 26.9901 95.6053 34.7746 85.25 34.7746H22.75C12.3947 34.7746 4 26.9901 4 17.3873Z"
              fill="#7B61FF"
            />
            <Path
              d="M21.3077 41.908C26.3077 39.768 26.5961 35.9636 26.1154 34.3289C28.6795 31.9511 34.0961 28.6223 35.25 34.3289C36.6923 41.4622 15.0577 44.583 21.3077 41.908Z"
              fill="#7B61FF"
            />
          </G>
          <Path
            d="M15.216 14.719v-.754h5.967v.754h-2.522v8.333h-.91v-8.333h-2.535Zm10.133 1.91c-1.287 0-2.418.833-2.522 2.484h4.992c.039-1.651-1.157-2.483-2.47-2.483Zm3.289 4.174c-.325 1.339-1.469 2.353-3.25 2.353-2.015 0-3.484-1.404-3.484-3.653 0-2.262 1.443-3.653 3.484-3.653 2.08 0 3.354 1.482 3.354 3.289 0 .286 0 .468-.026.715h-5.889c.078 1.664 1.235 2.535 2.561 2.535 1.209 0 2.028-.637 2.288-1.586h.962Zm5.187 2.249-1.833-2.86-1.768 2.86h-.962l2.288-3.55-2.262-3.548h1.027l1.807 2.82 1.755-2.82h.949l-2.262 3.51 2.288 3.588h-1.027Zm2.221-1.924V16.72h-.988v-.767h.988v-1.781h.923v1.78h1.95v.768h-1.95v4.407c0 .87.3 1.144 1.118 1.144h.832v.78h-.975c-1.235 0-1.898-.507-1.898-1.924Zm8.109-7.15h1.248l-.741 3.276H42.75l1.404-3.276Zm8.714 6.448c0 1.43-1.144 2.717-3.211 2.717-1.885 0-3.341-.988-3.367-2.704h1.95c.052.728.533 1.209 1.378 1.209.858 0 1.365-.455 1.365-1.105 0-1.963-4.68-.78-4.667-4.07 0-1.637 1.326-2.625 3.198-2.625 1.859 0 3.133.949 3.25 2.587h-2.002c-.04-.598-.52-1.066-1.3-1.08-.715-.025-1.248.326-1.248 1.067 0 1.82 4.654.806 4.654 4.004Zm6.984 2.626V19.19h-3.886v3.86h-1.82v-9.073h1.82v3.73h3.886v-3.73h1.82v9.074h-1.82Zm12.165-4.563c0 2.73-2.067 4.654-4.641 4.654-2.561 0-4.654-1.924-4.654-4.654 0-2.717 2.093-4.641 4.654-4.641 2.587 0 4.64 1.924 4.64 4.64Zm-7.423 0c0 1.846 1.13 3.029 2.782 3.029 1.638 0 2.769-1.183 2.769-3.03 0-1.845-1.131-3.002-2.77-3.002-1.65 0-2.781 1.157-2.781 3.003Zm8.45 1.092v-5.603h1.82v5.616c0 1.248.677 1.898 1.834 1.898 1.17 0 1.846-.65 1.846-1.898v-5.616h1.833v5.603c0 2.392-1.716 3.562-3.705 3.562-1.99 0-3.627-1.17-3.627-3.562Zm8.31-4.134v-1.47h6.656v1.47h-2.418v7.605h-1.82v-7.605h-2.418Zm8.555 1.807h-1.248l.741-3.276h1.911l-1.404 3.276Z"
            fill="#fff"
          />
        </Svg>
      ) : (
        <Svg width={108} height={51} fill="none">
          <G fill="#7B61FF">
            <Path
              d="M104 18.244C104 8.642 95.605.857 85.25.857h-62.5C12.395.857 4 8.642 4 18.244c0 9.603 8.395 17.388 18.75 17.388h62.5c10.355 0 18.75-7.785 18.75-17.388Z"
              fill="#7B61FF"
            />
            <Path
              d="M86.692 42.765c-5-2.14-5.288-5.944-4.807-7.579-2.565-2.378-7.981-5.707-9.135 0-1.442 7.133 20.192 10.254 13.942 7.58Z"
              fill="#7B61FF"
            />
          </G>
          <Path
            d="M23.979 22.9218H30.505V21.1148H26.995C28.503 19.9578 30.362 18.2938 30.362 16.2138C30.362 14.6148 29.4 13.3538 27.242 13.3538C25.513 13.3538 24.018 14.3418 23.953 16.6428H26.098C26.098 15.7458 26.488 15.2258 27.151 15.2258C27.827 15.2258 28.139 15.6678 28.139 16.3568C28.139 17.9558 25.994 19.6068 23.979 21.2708V22.9218ZM35.6038 23.0518H37.8268V21.3618H38.8928V19.4378H37.8268V13.6918H35.3438L31.2358 19.5678V21.3618H35.6038V23.0518ZM33.5758 19.4378L35.7598 16.1358V19.4378H33.5758ZM39.2266 25.4828H41.3716L44.8556 10.7018H42.7106L39.2266 25.4828ZM45.0513 13.5748V15.4728H49.0943L45.7923 23.0518H48.0673L51.3303 15.2258V13.5748H45.0513ZM60.2862 23.0518H62.5092V13.9258H60.2862V17.5008H56.8282V13.9258H54.6052V23.0518H56.8282V19.2948H60.2862V23.0518ZM67.012 17.4748C67.753 17.4748 68.377 17.9298 68.377 18.7228H65.595C65.725 17.9168 66.284 17.4748 67.012 17.4748ZM70.483 20.6468H68.117C67.935 21.0368 67.584 21.3488 66.96 21.3488C66.245 21.3488 65.66 20.9068 65.582 19.9578H70.613C70.639 19.7368 70.652 19.5158 70.652 19.3078C70.652 17.0978 69.157 15.6938 67.038 15.6938C64.88 15.6938 63.372 17.1238 63.372 19.4248C63.372 21.7258 64.906 23.1558 67.038 23.1558C68.845 23.1558 70.132 22.0638 70.483 20.6468ZM71.5247 23.0518H73.7477V13.4318H71.5247V23.0518ZM77.2817 16.8248V15.7978H75.0587V26.5098H77.2817V22.0378C77.6847 22.6488 78.4517 23.1558 79.5177 23.1558C81.2987 23.1558 82.7157 21.6998 82.7157 19.4118C82.7157 17.1238 81.2987 15.6938 79.5177 15.6938C78.4517 15.6938 77.6847 16.1878 77.2817 16.8248ZM80.4537 19.4118C80.4537 20.5688 79.6997 21.2188 78.8547 21.2188C78.0227 21.2188 77.2687 20.5818 77.2687 19.4248C77.2687 18.2678 78.0227 17.6308 78.8547 17.6308C79.6997 17.6308 80.4537 18.2548 80.4537 19.4118Z"
            fill="white"
          />
        </Svg>
      )}
    </Icon>
  );
}
