import { createStackNavigator } from "@react-navigation/stack";

import { UserScreen } from "../screens/UserScreen";
import { useBackgroundColor } from "../util/theme-helper";

const User = createStackNavigator();

export function UserNavigator() {
  const { backgroundColor } = useBackgroundColor();
  return (
    <User.Navigator
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor },
      }}
      initialRouteName="UserScreen"
    >
      <User.Screen
        name="UserScreen"
        component={UserScreen}
        options={{ title: "My Profile - Miindset" }}
      />
    </User.Navigator>
  );
}
