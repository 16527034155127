import { UploadImageDto } from "api/src/content/dto/upload-image.dto";
import { useMutation } from "react-query";

import { MAX_FILE_UPLOAD_SIZE } from "../constants/upload-file-max-size";
import { api } from "../util/api";

interface IErrorType {
  message: string;
}

export function useUploadImage() {
  return useMutation<string, IErrorType, UploadImageDto>(
    "upload-image",
    async ({ ...data }: UploadImageDto) => {
      const response = await api.post("content/upload-image", data);

      if (response.status === 201) {
        return response.data;
      }

      if (response.status === 413) {
        throw new Error(
          `Image size must be smaller than ${MAX_FILE_UPLOAD_SIZE} MB!`
        );
      }

      throw new Error("Something went wrong");
    }
  );
}
