import { Insight } from "api/src/insights/enum/insight";

import { INSIGHTS } from "../constants/insights";

/**
 * Get insight data
 *
 * @param channel - The issue to get the image for
 *
 * @returns 'require'd issue image
 */
export function getInsightData(insightKey: Insight) {
  return INSIGHTS.find(({ insight }) => insight === insightKey);
}
