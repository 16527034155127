import { ContentEntity } from "api/src/content/entity/content.entity";
import React from "react";

import { getMarkDownContent } from "../../util/notifications";

export function RenderHtmlContent({ content }: { content: ContentEntity }) {
  const getHtmlContentParagraphs = (contentHtml: string) => {
    const formattedHtmlContent = contentHtml.replace(/\\n/g, "\n");
    const markedDownContent = getMarkDownContent(formattedHtmlContent ?? "");
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(markedDownContent, "text/html");
    return Array.from(parsedHtml.body.children);
  };
  return (
    <>
      {getHtmlContentParagraphs(content?.content).map((paragraph, index) => (
        <div
          key={index}
          className="article"
          dangerouslySetInnerHTML={{ __html: paragraph.outerHTML }}
        />
      ))}
    </>
  );
}
