import { RouteProp, useRoute } from "@react-navigation/core";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { HStack, Text, Image, Box, Divider, Pressable } from "native-base";
import React, { useState } from "react";

import { LoadingAnimation } from "../components/elements/LoadingAnimation";
import { Markdown } from "../components/elements/Markdown";
import { ScrollView } from "../components/elements/ScrollView";
import { HtmlView } from "../components/HtmlView";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import { useContent } from "../hooks/useContent";
import { Header } from "../layout/Header";
import { RootStackParamList } from "../navigation/RootStackParamList";
import { isHtml } from "../util/content";
import { getImage } from "../util/image";
import { isWeb } from "../util/platform";

export function NotificationScreen() {
  const {
    params: { contentId, createAt },
  } = useRoute<RouteProp<RootStackParamList, "Notification">>();
  const { data: content } = useContent(contentId);
  const [isHtmlContentLoading, setIsHtmlContentLoading] =
    useState<boolean>(true);
  const { navigate } = useNavigation();

  const isContentHtml = isHtml(content?.content);

  if (!content) {
    return (
      <>
        <Header hasBack={true} />
        <Box
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingAnimation autoPlay={true} size={100} isLoading={true} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Header hasBack={true} />
      <ScrollView
        px={5}
        bg="white"
        contentContainerStyle={{ paddingBottom: 200 }}
        scrollEventThrottle={100}
      >
        <WhiteBoxContainer width={{ sm: "90%", md: "80%" }}>
          <Text fontWeight={600} fontSize={20} textAlign="center">
            Notification
          </Text>
          <Pressable onPress={() => navigate("Notifications")}>
            <Text color="primary.400" fontSize={16} textAlign="center" my="2">
              View All Notifications
            </Text>
          </Pressable>
          <Divider />
          <HStack
            justifyContent="space-between"
            alignItems="center"
            space="2"
            my="5"
          >
            <Text width="50%" isTruncated={true}>
              {content?.title}
            </Text>
            <Text>{dayjs(createAt).format("DD/MM/YY")}</Text>
          </HStack>
          <Divider />
          <Image
            my="10"
            source={{ uri: getImage(content?.image) }}
            w="100%"
            h="200px"
            alt={content?.title}
            borderRadius="5"
          />

          {isWeb() ? (
            <Box pb={4}>
              {isContentHtml ? (
                <div
                  className="article"
                  dangerouslySetInnerHTML={{
                    __html: content?.content.replaceAll("<noscript>", "") ?? "",
                  }}
                />
              ) : (
                <Markdown>{content?.content ?? ""}</Markdown>
              )}
            </Box>
          ) : isContentHtml ? (
            <>
              {isHtmlContentLoading ? (
                <Box justifyContent="center" alignItems="center">
                  <LoadingAnimation
                    autoPlay={true}
                    size={100}
                    isLoading={true}
                  />
                </Box>
              ) : null}

              <HtmlView
                html={content?.content?.replace(/<noscript>/g, "")}
                onInitialized={() => {
                  setIsHtmlContentLoading(false);
                }}
              />
            </>
          ) : (
            <Markdown>{content?.content ?? ""}</Markdown>
          )}
        </WhiteBoxContainer>
      </ScrollView>
    </>
  );
}
