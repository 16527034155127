import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function TrashIcon({ color = "#C4C4C4", ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 41 41" {...props}>
      <Svg width={41} height={41} fill="none" stroke={color}>
        <Path
          d="M28.938 12.688H11.063M17.563 17.563v6.5M22.438 17.563v6.5M27.313 12.688v15.437a.812.812 0 0 1-.813.813h-13a.812.812 0 0 1-.813-.813V12.687"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M24.063 12.688v-1.626a1.625 1.625 0 0 0-1.625-1.624h-4.875a1.625 1.625 0 0 0-1.625 1.624v1.626"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
