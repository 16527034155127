import React, { ReactNode, useCallback, useMemo, useState } from "react";

import {
  FirsLoginContext,
  IFirstLoginContext,
} from "../../context/FirstLoginContext";
import { FirstLoginStepsType } from "../../enum/first-login-steps";

interface ITransitionContextProps {
  children: ReactNode;
}

export function FirstLoginContextProvider({
  children,
}: ITransitionContextProps) {
  const [currentLoginStep, setCurrentLoginStep] = useState<FirstLoginStepsType>(
    FirstLoginStepsType.digestMedia
  );

  const setCurrentStep = useCallback(
    (step: FirstLoginStepsType) => {
      setCurrentLoginStep(step);
    },
    [currentLoginStep, setCurrentLoginStep]
  );

  const loginContextValue = useMemo(
    () => ({
      currentStep: currentLoginStep,
      setCurrentStep,
    }),
    [currentLoginStep, setCurrentLoginStep]
  );

  return (
    <FirsLoginContext.Provider value={loginContextValue as IFirstLoginContext}>
      <>{children}</>
    </FirsLoginContext.Provider>
  );
}
