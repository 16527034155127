import { ContentEntity } from "api/src/content/entity/content.entity";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

export function useSetContentState(contentId?: string, isRejected?: boolean) {
  const queryClient = useQueryClient();
  return useMutation<IApiResponse<ContentEntity>, IApiResponse<ContentEntity>>(
    "auth",
    async () => {
      const path = isRejected ? "reject" : "approve";
      const response = await api.put<IApiResponse<ContentEntity>>(
        `content/review/${contentId}/${path}`
      );

      if (response.status !== 200) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        // TODO: Dynamically replace the new value
        queryClient.invalidateQueries(["content", "review"]);
      },
    }
  );
}
