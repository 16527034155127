import { IOrganisationStats } from "api/src/organisations/interfaces/IOrganisationStats";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationStats(location?: string) {
  return useQuery<IOrganisationStats[]>(
    ["organisation-stats", location],
    async () => {
      const response = await api.get<IOrganisationStats[]>(
        `organisations/stats/${location ?? ""}`,
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get organisations stats");
    },
    {
      keepPreviousData: true,
    }
  );
}
