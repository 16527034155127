import {
  View,
  Text,
  Heading,
  Divider,
  HStack,
  VStack,
  Pressable,
  Link,
} from "native-base";
import React from "react";
import { Linking } from "react-native";

import { ToastStatus, useToast } from "../../hooks/useToast";
import { isWeb } from "../../util/platform";
import { CallIcon } from "../icons/CallIcon";
import { FertilitySupportIcon } from "../icons/FertilitySupportIcon";
import { NetworkIcon } from "../icons/NetworkIcon";

export function FertilitySupport() {
  const toast = useToast();

  const handleContactClick = (phoneNumber: string) => {
    const url = `tel:${phoneNumber}`;
    if (isWeb()) {
      return;
    }
    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          toast({
            title: "Can't handle this contact",
            status: ToastStatus.warning,
          });
        } else {
          return Linking.openURL(url);
        }
      })
      .catch((err) =>
        toast({
          title: `An error occurred: ${err}`,
          status: ToastStatus.error,
        })
      );
  };

  return (
    <View mb={10} mt={50} alignItems="center">
      {!isWeb() ? <FertilitySupportIcon /> : null}
      <Heading mt={5} mb={5} fontSize={28} fontWeight={600} textAlign="center">
        Fertility Support
      </Heading>
      {isWeb() ? <FertilitySupportIcon /> : null}
      <Text
        mt={5}
        w={isWeb() ? 500 : 300}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Fertility Network UK provides free and impartial support, advice,
        information and understanding for anyone affected by fertility issues.
      </Text>
      {isWeb() ? null : <Divider w="80%" mt={5} alignSelf="center" />}
      <Heading
        mt={5}
        fontSize={20}
        color="primary.400"
        fontWeight={600}
        textAlign="center"
      >
        Fertility Network UK
      </Heading>
      <Text
        _dark={{ color: "secondary.200" }}
        mt={8}
        w={500}
        fontSize={14}
        textAlign="center"
        color="black"
      >
        What can I call about?
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Fertility Network UK is not for diagnosis, but however simple or
        complicated question, Diane and Janet will try to answer you and support
        you through your queries and worries. Remember, no question too trivial
        to ask - if it is bothering you then ask away - this could be just the
        service you are looking for. And there's no charge for this support!
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Even if you just want to talk, why not give Diane or Janet a call on our
        Support line or email. If it matters to you, it matters to us.
      </Text>
      <VStack
        mt={10}
        flex={1}
        w={isWeb() ? 860 : 250}
        space={5}
        justifyContent="space-between"
        flexDirection={isWeb() ? "row" : "column"}
      >
        <Pressable onPress={() => handleContactClick("0121 323 5025")}>
          <HStack space={2}>
            <CallIcon />
            <VStack>
              <Text>Telephone - 0121 323 5025</Text>
              <Text fontSize="10">(Mon/Wed/Fri) Diane - 10am to 4pm</Text>
            </VStack>
          </HStack>
        </Pressable>
        <Pressable onPress={() => handleContactClick("07816 086694")}>
          <HStack space={2}>
            <CallIcon />
            <VStack>
              <Text>Telephone - 07816 086694</Text>
              <Text fontSize="10">(Tue/Thu) Janet - 10am to 4pm</Text>
            </VStack>
          </HStack>
        </Pressable>
        <Link href="https://fertilitynetworkuk.org/" isExternal={true}>
          <HStack space={2}>
            <NetworkIcon />
            <VStack>
              <Text underline={true}>fertilitynetworkuk.org</Text>
            </VStack>
          </HStack>
        </Link>
      </VStack>
    </View>
  );
}
