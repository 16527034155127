import { Heading, VStack, Text, Box } from "native-base";
import React from "react";

import { Container } from "../../components/Container";
import { ScrollView } from "../../components/elements/ScrollView";
import { CriticalIndicatorsChart } from "../../components/organisations/dashboard/indicators/CriticalIndicatorsChart.web";
import { TotalOrganisationPercentageChart } from "../../components/organisations/dashboard/indicators/TotalOrganisationPercentageChart.web";
import { OrganisationTabs } from "../../components/organisations/OrganisationTabs";
import { Header } from "../../layout/Header";

export function OrganisationIndicatorsScreen() {
  return (
    <>
      <Header hasBack={false} />

      <ScrollView
        bg="secondary.200"
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
        _dark={{
          bg: "dark.500",
        }}
      >
        <Container size="container.xl" h="100vh">
          <VStack pt={10} h="100%" w="100%" space={10}>
            <OrganisationTabs activeTab="Indicators" />
            <VStack space={5} h="85%">
              <VStack h="50%" space={3} ml={6}>
                <VStack h="20%">
                  <Heading fontSize={24} color="primary.400" fontWeight="600">
                    Critical Indicators
                  </Heading>
                  <Text fontSize={16}>
                    Changes within the organisation for stress, anxiety &
                    depression
                  </Text>
                </VStack>
                <Box h="75%">
                  <CriticalIndicatorsChart />
                </Box>
              </VStack>
              <VStack h="50%" space={3} ml={6}>
                <VStack h="20%">
                  <Heading fontSize={24} color="primary.400" fontWeight="600">
                    Total organisation Percentage
                  </Heading>
                  <Text fontSize={16}>
                    Proportional breakdown of the stress, anxiety and depression
                    levels within your organisation in the last 30 days
                  </Text>
                </VStack>
                <Box h="75%" w="50%">
                  <TotalOrganisationPercentageChart />
                </Box>
              </VStack>
            </VStack>
          </VStack>
        </Container>
      </ScrollView>
    </>
  );
}
