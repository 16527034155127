export const RESILIENCY_TEXT: { min: number; text: string }[] = [
  {
    min: 1,
    text: "You have hit maximum resilience today - keep it up! Keep making time for self care and keep your focus on maintaining a healthy, wholefood diet, keeping in touch with loved ones and friends, making time for activities you love, staying hydrated and practicing good sleep hygiene. Remember good habits are built over weeks and months, not days, so consistency is key.",
  },
  {
    min: 0.9,
    text: "Congratulations, your hard work has definitely been paying off - this is an excellent score. Give yourself a big well done, and keep doing what you’re doing! If you have enough energy today, try to fit in some exercise or a jog in nature.",
  },
  {
    min: 0.8,
    text: "You are doing something right, your resiliency is great today! Reflect on what activities you’ve been doing this week that may have contributed to such a high score, and make sure you make time for these each week. Well done, you!",
  },
  {
    min: 0.7,
    text: "Your resiliency is good today, great job! Today might be a good day to take part in some more high energy activities such as cycling, going for a run, hitting the gym or playing with your kids or pets - or even planning an outing! Get organizing some fun activities while you feel good, as a treat for future you to look forward to.",
  },
  {
    min: 0.6,
    text: "Your resiliency is pretty good today, but there is room for improvement. Have you been putting yourself first this week? Maybe try some journaling, alternate nostril breathing, or a quick meditation to boost your reserves for today",
  },
  {
    min: 0.5,
    text: "It looks like something has affected your resilience score today. Can you notice any patterns of what that might be? Identifying triggers for stress and anxiety can help you prepare and plan for them, and be better equipped for handling them in the future.",
  },
  {
    min: 0.4,
    text: "Take this as your cue to relax today. Try to do some restorative activities to better help your resiliency tomorrow. Why not call a friend, do something creative or take a walk out in nature?",
  },
  {
    min: 0.3,
    text: "Your score is pretty low, did you have a busy or stressful day yesterday? Try creating a routine that uplifts you and that you can stick to easily - for example having a glass of water with every meal to keep your hydration levels up.",
  },
  {
    min: 0.2,
    text: "Try to take it easy today and prioritize rest. Make sure to stay hydrated and aim to get a good night's sleep tonight. Avoiding screen time 1 hour before bed or using blue-light blocking devices can help this, as well as aiming to go to bed before 11pm.",
  },
  {
    min: 0.1,
    text: "Your score today suggests you need to take it easy and be kind to yourself. Why not try a loving-kindness meditation, or some breathwork to increase your energy?",
  },
  { min: 0, text: "" },
];
