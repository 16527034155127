import { ContentEntity } from "api/src/content/entity/content.entity";
import { useInfiniteQuery } from "react-query";

import { ContentFilterOption } from "../components/ContentFilter";
import { api } from "../util/api";

import { IContentPaginatedResponse } from "src/interfaces/IContentPaginatedResponse";

interface IOrganisationChannelContentRequest {
  contentType: ContentFilterOption;
  isEnabled?: boolean;
}

export function useOrganisationChannelContents({
  contentType,
  isEnabled = false,
}: IOrganisationChannelContentRequest) {
  return useInfiniteQuery<IContentPaginatedResponse<ContentEntity>>(
    ["content", "organisation", contentType],
    async ({ pageParam }) => {
      const response = await api.get<IContentPaginatedResponse<ContentEntity>>(
        "/content/organisation-channel-contents",
        {
          params: {
            contentType: contentType === "all" ? undefined : contentType,
            page: pageParam,
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get content for channel");
    },
    {
      staleTime: 60000,
      enabled: isEnabled,
      getNextPageParam: (lastPage) =>
        lastPage?.content
          ? lastPage.content.meta.totalItems /
              lastPage.content.meta.itemsPerPage >
            lastPage.content.meta.currentPage
          : false,
    }
  );
}
