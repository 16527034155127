import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React, { memo } from "react";

import { TransitionContextProvider } from "../components/context/TransitionContextProvider";
import { BottomTabs } from "../layout/BottomTabs";
import { Header } from "../layout/Header";
import { EditContentScreen } from "../screens/admin/EditContentScreen";
import { HomeScreen } from "../screens/HomeScreen";
import { SearchScreen } from "../screens/SearchScreen";
import { isWeb } from "../util/platform";

import { ChannelNavigator } from "./ChannelNavigator";
import { HomeNavigator } from "./HomeNavigator";
import { UserNavigator } from "./UserNavigator";

const App = createBottomTabNavigator();

export const AppNavigator = memo(() => (
  <>
    <Header />
    <TransitionContextProvider>
      <App.Navigator
        screenOptions={{
          unmountOnBlur: true,
          headerShown: false,
        }}
        initialRouteName="Home"
        tabBar={(props) => <BottomTabs {...props} />}
      >
        <App.Screen
          name="Home"
          component={isWeb() ? HomeScreen : HomeNavigator}
          options={{ title: "Home - Miindset" }}
        />
        <App.Screen
          name="ReviewContent"
          component={EditContentScreen}
          options={{ title: "Edit Content - Miindset" }}
        />
        <App.Screen
          name="Channels"
          component={ChannelNavigator}
          options={{ title: "Channels - Miindset" }}
        />
        <App.Screen
          name="Search"
          component={SearchScreen}
          options={{ title: "Search - Miindset" }}
        />

        <App.Screen name="User" component={UserNavigator} />
      </App.Navigator>
    </TransitionContextProvider>
  </>
));
