import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

type IChangeEmail = Record<string, string>;

export function useSendVerificationCode() {
  return useMutation<
    IApiResponse<IChangeEmail>,
    IApiResponse<IChangeEmail>,
    IChangeEmail
  >(["email-verification"], async (data: IChangeEmail) => {
    const response = await api.post<IApiResponse<IChangeEmail>>(
      "/users/verify-email",
      data
    );

    if (response.status !== 201) {
      throw response.data;
    }

    return response.data;
  });
}
