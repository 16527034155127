import { ContentType } from "api/src/content/enum/ContentType";
import { ReactElement } from "react";
import { NativeScrollEvent } from "react-native";

import { CONTENT_TYPE_ICONS } from "../constants/content-icons";
import { IIconProps } from "../interfaces/IIconprops";

/**
 * Get content type icon
 *
 * @param type - Content type
 * @returns the icon component
 */
export const getContentTypeIcon = (
  type: ContentType
): ((props: IIconProps) => ReactElement) | undefined =>
  CONTENT_TYPE_ICONS[type] ?? undefined;

/**
 * Is close to bottom
 *
 * @remarks
 * Checks if user has scrolled to near the bottom of the article
 *
 * @param event - Scroll event
 *
 * @returns if they have or not
 */
export const isCloseToBottom = ({
  layoutMeasurement,
  contentOffset,
  contentSize,
}: NativeScrollEvent): boolean => {
  const paddingToBottom = 100;

  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

/**
 * is HTML
 *
 * @remarks
 * Checks if content is HTML or not
 *
 * @param content - Content
 *
 * @returns if it is
 */
export const isHtml = (content?: string): boolean =>
  !!content?.match(/(<a>|<p>|<span>|<li>|<p>)/g);
