import { useBreakpointValue } from "native-base";
import React from "react";
import { ViewStyle } from "react-native";

import { BaseHeader } from "./BaseHeader";
import { WebHeader } from "./WebHeader";

interface IHeaderProps {
  hasBack?: boolean;
  onBackPress?: () => void;
  style?: ViewStyle;
}

export function Header(props: IHeaderProps) {
  const AppHeader = useBreakpointValue({
    base: <BaseHeader {...props} />,
    lg: <WebHeader {...props} />,
  });

  return AppHeader;
}
