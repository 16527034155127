import { ContentEntity } from "api/src/content/entity/content.entity";
import { ContentType } from "api/src/content/enum/ContentType";
import { capitalize, isEmpty } from "lodash";
import { Divider, Text, HStack, VStack } from "native-base";
import React from "react";

import { ChannelType } from "../../types/channel-type";
import { Grid } from "../Grid";
import { HeadphonesIcon, OpenBookIcon, VideoCameraIcon } from "../icons/Icons";

import { ContentCard } from "./ContentCard.web";

interface IContentContainerProps {
  type?: ContentType;
  data: ContentEntity[];
  hideIcon?: boolean;
  direction: "row" | "column";
  label?: string;
  channelType?: ChannelType;
}

export function ContentContainer({
  data,
  label,
  hideIcon,
  type = ContentType.article,
  direction = "column",
  channelType,
}: IContentContainerProps) {
  function getIcon(type: ContentType) {
    switch (type) {
      case ContentType.article:
        return OpenBookIcon;
      case ContentType.podcast:
        return HeadphonesIcon;
      case ContentType.video:
        return VideoCameraIcon;
      default:
        return OpenBookIcon;
    }
  }

  const Icon = getIcon(type);
  const content = data.filter((content) => content.type === type).slice(0, 19);
  if (isEmpty(content)) {
    return null;
  }

  return (
    <VStack position="relative" w="100%">
      <HStack alignItems="center" my={7}>
        {!hideIcon ? (
          <>
            <Icon color="primary.400" />
            <Divider orientation="vertical" mx={5} />
          </>
        ) : null}

        <Text variant="strongHighlight" fontWeight="600">
          {label ?? capitalize(type)}
        </Text>
      </HStack>
      <Grid direction={direction}>
        {content?.map((item, index) => (
          <ContentCard
            contentData={item}
            direction={direction}
            channelType={channelType}
            key={index}
          />
        ))}
      </Grid>
      <Divider mt={5} />
    </VStack>
  );
}
