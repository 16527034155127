import { useNavigation } from "@react-navigation/core";
import { RouteProp, useRoute } from "@react-navigation/native";
import { Mood } from "api/src/emotion/enum/mood";
import { AlertLevel } from "api/src/signposting/enum/AlertLevel";
import dayjs from "dayjs";
import { first } from "lodash";
import { Box, HStack, VStack, Pressable, Text, View } from "native-base";
import React, { useCallback, useContext, useMemo, useState } from "react";

import { AuthButton } from "../components/auth/AuthButton";
import { Container } from "../components/Container";
import { AlertDialog } from "../components/elements/AlertDialog";
import { Button } from "../components/elements/Button";
import { OnboardingSteps } from "../components/OnboardingSteps";
import { PageHeader } from "../components/PageHeader";
import { IMoodItem, MOODS } from "../constants/moods";
import { OnboardingContext } from "../context/OnboardingContext";
import { OnboardingStep } from "../enum/signup-step";
import { useBackButton } from "../hooks/useBackButton";
import { useMoodSubmit } from "../hooks/useMoodSubmit";
import { ToastStatus, useToast } from "../hooks/useToast";
import { Header } from "../layout/Header";
import { RootStackParamList } from "../navigation/RootStackParamList";

export function TrackMoodScreen() {
  const { goBack, navigate } = useNavigation();
  const { mutate: submitMood, isLoading } = useMoodSubmit();
  const [activeMood, setActiveMood] = useState<IMoodItem | null>(null);
  const toast = useToast();
  const { currentStep } = useContext(OnboardingContext);
  const { params: allParams } =
    useRoute<RouteProp<RootStackParamList, "TrackMood">>();
  const [isSkipAlert, setIsSkipAlert] = useState<boolean>(false);

  useBackButton(() => {
    if (allParams?.type === "wellbeing" && allParams?.status === "skipped") {
      return false;
    }
    if (allParams?.type !== "wellbeing" && !currentStep) {
      return false;
    }
    return true;
  });

  const handleSubmit = useCallback(() => {
    submitMood(
      { mood: activeMood?.value as Mood },
      {
        onSuccess: () => {
          if (currentStep === OnboardingStep.trackMood) {
            navigate("Affirmation");
            return;
          }

          if (
            allParams?.type === "wellbeing" &&
            allParams?.flaggedUser?.alertLevel === AlertLevel.high &&
            allParams?.flaggedUser?.triggerWord === null
          ) {
            navigate("Digesting");
            return;
          }
          if (allParams?.type === "wellbeing") {
            navigate("Affirmation", { type: "wellbeing", status: "submitted" });
            return;
          }

          navigate("Home");
          toast({ title: "Mood Recorded", status: ToastStatus.success });
        },
        onError: ({ message }) => {
          goBack();
          toast({
            title: message || "Could not record your mood",
            status: ToastStatus.error,
          });
        },
      }
    );
  }, [activeMood, submitMood, goBack]);

  const { icon: Icon } = activeMood ?? (first(MOODS) as IMoodItem);

  const currentDate = useMemo(() => dayjs().format("MMMM Do YYYY - HH:mm"), []);

  return (
    <>
      {allParams?.type === "wellbeing"
        ? allParams?.status === "skipped" && <Header hasBack={true} />
        : !currentStep && <Header />}
      <View
        bg={{ base: "white", lg: "secondary.200" }}
        _dark={{ bg: "dark.500" }}
        minH="100vh"
        mt={!currentStep ? "10" : 0}
        justifyContent={!currentStep ? "start" : "center"}
      >
        <Container minH="unset">
          <Box
            alignSelf="center"
            p={10}
            width={{ base: "unset", lg: "70%" }}
            bg="white"
            _dark={{ bg: "dark.100" }}
            borderRadius={5}
          >
            <OnboardingSteps />
            <VStack pt="10" space={12} mt={6}>
              <VStack px="4" alignItems="center" minH="100%" space={8}>
                <VStack space={5}>
                  <PageHeader
                    label="Track your current mood"
                    subtitle={currentDate}
                  />
                  <VStack alignItems="center" space={10}>
                    <Icon size="200" />
                    <HStack
                      w="95%"
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {MOODS.slice(1).map((mood) => {
                        const { label } = mood;
                        const isActive = mood === activeMood;
                        return (
                          <Button
                            key={mood.value}
                            mb={2}
                            mx={2}
                            variant="moodInverted"
                            onPress={() => setActiveMood(mood)}
                            bg={isActive ? "primary.300" : undefined}
                            _text={{
                              color: isActive ? "white" : undefined,
                            }}
                          >
                            {label}
                          </Button>
                        );
                      })}
                    </HStack>
                  </VStack>
                </VStack>
                <Box w="90%">
                  {activeMood ? (
                    <AuthButton
                      variant="auth"
                      onPress={handleSubmit}
                      isLoading={isLoading}
                    >
                      Log my mood
                    </AuthButton>
                  ) : allParams?.type === "wellbeing" ? (
                    <Pressable
                      onPress={() => setIsSkipAlert(true)}
                      _pressed={{ opacity: 0.7 }}
                      pt="2"
                    >
                      <Text textAlign="center" color="secondary.650">
                        Skip mood tracker
                      </Text>
                    </Pressable>
                  ) : null}
                </Box>
              </VStack>
            </VStack>
          </Box>
        </Container>
      </View>
      <AlertDialog
        headerText="Skip Log Mood"
        bodyText="Are you sure?"
        buttonText="Yes, skip"
        showFooter={true}
        buttonAction={() => {
          setIsSkipAlert(false);
          if (allParams?.type === "wellbeing") {
            navigate("Affirmation", {
              type: "wellbeing",
              status: "skipped",
            });
            return;
          }
          navigate("Digesting");
        }}
        isOpen={isSkipAlert}
        onClose={() => setIsSkipAlert(false)}
      />
    </>
  );
}
