import { BreathingButton } from "../enum/breathing-button";
import { IBreathingScreen } from "../interfaces/IBreathingScreen";

export const BREATHING_FIRST_SCREEN: IBreathingScreen = {
  title:
    "Nourishing myself is a joyful experience, and I am worth the time spent on my healing.",
  details: "Would you like to try...",
  buttons: [
    BreathingButton.bellyBreathing,
    BreathingButton.fourSevenEightBreathing,
    BreathingButton.rollBreathing,
  ],
};
