import { ActivityLogType } from "api/src/activity-logs/enum/ActivityLogType";
import { Heading, HStack, Text, VStack } from "native-base";
import React, { useCallback, useState } from "react";
import { Dimensions } from "react-native";

import { BreathingAnimation } from "../components/animations/BreathingAnimation";
import { CountdownCircleButton } from "../components/CountdownCircleButton";
import { AlertDialog } from "../components/elements/AlertDialog";
import { Button } from "../components/elements/Button";
import { ScrollView } from "../components/elements/ScrollView";
import { EVENT_BREATHING_EXERCISE } from "../constants/analytics";
import { BELLY_BREATHING } from "../constants/breathing-belly";
import { BREATHING_BUTTON_LABELS } from "../constants/breathing-buttons";
import { FOURSEVENEIGHT_BREATHING } from "../constants/breathing-fourseveneight";
import { ROLL_BREATHING } from "../constants/breathing-roll";
import { BreathingButton } from "../enum/breathing-button";
import { BreathingStepType } from "../enum/breathing-step-type";
import { useActivityCompleted } from "../hooks/useActivityCompleted";
import { IBreathingScreen } from "../interfaces/IBreathingScreen";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";
import { eventHit } from "../util/analytics";

const { height } = Dimensions.get("window");

export function BreathingExercisesScreen() {
  const [breathingStep, setBreathingStep] = useState<number>(0);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [bellyExercise, setBellyExercise] =
    useState<IBreathingScreen[]>(BELLY_BREATHING);
  const info: string[] = [
    "Belly breathing is easy to do and very relaxing. Try this basic exercise anytime you need to relax or relieve stress.",
    "This breathing exercise uses belly breathing to help you relax, do this exercise either lying or sitting down.",
    "The object of roll breathing is to develop full use of your lungs and get in touch with the rhythm of your breathing. You can practise this in any position, but it is best to learn it lying on your back, with your knees bent. Be cautious the first few times you try this exercise, you may become dizzy or lightheaded - slow your breathing to your normal breathing pattern before getting up slowly.",
  ];

  const { mutate: submitActivityLog } = useActivityCompleted(
    ActivityLogType.breathingExercise
  );

  const handleNextStep = useCallback(
    (buttonType: BreathingButton) => {
      if (buttonType === BreathingButton.bellyBreathing) {
        setBellyExercise(BELLY_BREATHING);
        setBreathingStep(breathingStep + 2);
      } else if (buttonType === BreathingButton.fourSevenEightBreathing) {
        setBellyExercise(FOURSEVENEIGHT_BREATHING);
        setBreathingStep(breathingStep + 2);
      } else if (buttonType === BreathingButton.rollBreathing) {
        setBellyExercise(ROLL_BREATHING);
        setBreathingStep(breathingStep + 2);
      } else if (buttonType === BreathingButton.anotherExercise) {
        submitActivityLog(null, {
          onSuccess: () => {
            setBreathingStep(0);
          },
        });
      }
    },
    [breathingStep, bellyExercise]
  );

  const isInStep = bellyExercise[breathingStep].type === BreathingStepType.step;

  return (
    <>
      <AlertDialog
        headerText="Quit Exercise"
        bodyText="Are you sure you want to quit?"
        buttonText="Okay"
        buttonAction={() => {
          setBreathingStep(0);
          setShowAlert(false);
        }}
        isOpen={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
      />
      <Header
        hasBack={isInStep}
        onBackPress={() => {
          setShowAlert(true);
        }}
      />
      <Body>
        <ScrollView _dark={{ bg: "dark.500" }}>
          <VStack
            my="10"
            p="10"
            minH={height / 2}
            justifyContent="space-between"
            alignItems="center"
            background={isInStep ? "white" : "secondary.200"}
            _dark={{ bg: "dark.100" }}
          >
            <VStack space="6">
              <VStack alignItems="center" space={1}>
                <BreathingAnimation size={75} shouldStart={isInStep} />
                <Text fontSize={18}>
                  {breathingStep === 0
                    ? "Let’s  take a breath"
                    : bellyExercise[breathingStep].step}
                </Text>
              </VStack>
              {breathingStep !== 0 && (
                <>
                  <Text fontSize={22} fontWeight={600} textAlign="center">
                    {bellyExercise[breathingStep].title}
                  </Text>
                  <Text
                    fontSize={breathingStep === 0 ? 18 : 16}
                    textAlign="center"
                  >
                    {bellyExercise[breathingStep].details}
                  </Text>{" "}
                </>
              )}
            </VStack>
            {isInStep ? (
              <CountdownCircleButton
                auto={bellyExercise[breathingStep].auto}
                key={bellyExercise[breathingStep].step}
                onPress={() => {
                  if (bellyExercise[breathingStep + 1].step === "Complete") {
                    eventHit("submit", EVENT_BREATHING_EXERCISE);
                  }
                  setBreathingStep(breathingStep + 1);
                }}
              />
            ) : (
              <HStack space="4" mt="20" flex={1} width="100%">
                {bellyExercise[breathingStep].buttons?.map(
                  (buttonType, index) => (
                    <VStack
                      flex={1}
                      justifyContent="space-between"
                      alignItems="center"
                      space={6}
                      background="white"
                      paddingY="20"
                      paddingX="5"
                      _dark={{ bg: "dark.300" }}
                      key={index}
                    >
                      <Heading
                        fontSize={22}
                        fontWeight={600}
                        textAlign="center"
                      >
                        {BREATHING_BUTTON_LABELS[buttonType]}
                      </Heading>
                      <Text fontSize={16} fontWeight={200} textAlign="center">
                        {info[index]}
                      </Text>
                      <Button
                        key={index}
                        px="4"
                        maxW={200}
                        width="100%"
                        variant="outline"
                        onPress={() => handleNextStep(buttonType)}
                      >
                        Begin
                      </Button>
                    </VStack>
                  )
                )}
              </HStack>
            )}
          </VStack>
        </ScrollView>
      </Body>
    </>
  );
}
