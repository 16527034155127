import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationStressAnxietyDepressionTotalPercentage() {
  return useQuery<number[]>(
    ["organisations", "total-percentage"],
    async () => {
      const response = await api.get<number[]>(
        "organisations/stress-anxiety-depression-total-percentage"
      );

      if (response.status === 200) {
        return response.data;
      }
      return Promise.reject(
        "Could not get organisation total percentage of stress, anxiety and depression"
      );
    },
    {
      keepPreviousData: true,
    }
  );
}
