import { SubmitHabitDto } from "api/src/habit/dto/submit-habit.dto";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

export function useHabitEdit(habitId: string | undefined) {
  const queryClient = useQueryClient();

  return useMutation<null, null, SubmitHabitDto>(
    "habit-edit",
    async (data: SubmitHabitDto) => {
      const response = await api.put(`habit/${habitId}`, data);

      if (response.status !== 200) {
        throw response.data;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("habit");
        queryClient.invalidateQueries("habits");
      },
    }
  );
}
