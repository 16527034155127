import { HStack, Text, VStack, Pressable, useToken } from "native-base";
import React, { ReactElement, ReactNode } from "react";
import { PressableProps } from "react-native";

import { IIconProps } from "../interfaces/IIconprops";

interface IListItemProps extends PressableProps {
  icon?: (props: IIconProps) => ReactElement;
  secondaryIcon?: ReactNode;
  title: string;
  subtitle?: string;
  textColor?: string;
  action?: ReactNode;
  bgColor?: string;
}

export function MyPageListItem({
  icon: Icon,
  secondaryIcon,
  title,
  subtitle,
  action,
  textColor,
  bgColor,
  onPress,
}: IListItemProps) {
  const iconColor = useToken("colors", "secondary.400");
  return (
    <>
      <Pressable
        onPress={onPress}
        _pressed={{ bg: "secondary.200" }}
        _web={{
          _hover: {
            bg: "secondary.200",
          },
        }}
        _dark={{
          _pressed: { bg: "dark.300" },
          _hover: { bg: "dark.300" },
        }}
      >
        <HStack
          space="4"
          py="3"
          px="1"
          backgroundColor={bgColor}
          alignItems={subtitle ? "flex-start" : "center"}
        >
          {Icon && <Icon size="2xl" color={iconColor} />}
          {secondaryIcon}
          <VStack flexGrow="1" flexShrink="1" flexBasis="100%">
            <HStack justifyContent="space-between">
              <Text
                fontSize={[15, 16]}
                color={textColor}
                _dark={{ color: textColor || "secondary.400" }}
              >
                {title}
              </Text>
              {action}
            </HStack>
            {subtitle ? (
              <Text
                fontSize={[13, 14]}
                color="secondary.500"
                _dark={{ color: "secondary.650" }}
              >
                {subtitle}
              </Text>
            ) : null}
          </VStack>
        </HStack>
      </Pressable>
    </>
  );
}
