import { Box } from "native-base";
import React from "react";

import { Container } from "./Container";
import { IWhiteBoxContainerProps } from "./WhiteBoxContainer";

export function WhiteBoxContainer({
  children,
  ...props
}: IWhiteBoxContainerProps) {
  return (
    <Container minH="unset">
      <Box
        alignSelf="center"
        p={10}
        mt={10}
        width={{ sm: "90%", md: "70%" }}
        bg="white"
        borderRadius={5}
        _dark={{ bg: "dark.100" }}
        {...props}
      >
        {children}
      </Box>
    </Container>
  );
}
