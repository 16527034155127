import { EmergencyContactsDto } from "api/src/users/dto/emergency-contacts.dto";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

export function useEmergencyContacts() {
  const queryClient = useQueryClient();

  return useMutation<null, null, EmergencyContactsDto>(
    "emergency-contacts",
    async (data: EmergencyContactsDto) => {
      const response = await api.put("users/emergency-contacts", data);

      if (response.status !== 200) {
        throw response.data.errors;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["account"]);
      },
    }
  );
}
