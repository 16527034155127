import { VStack, Center, Text } from "native-base";
import React, { useState } from "react";
import { View as AnimatedView } from "react-native-animatable";
import { TouchableOpacity } from "react-native-gesture-handler";

import { isWeb } from "../../util/platform";
import { ArrowIcon } from "../icons/ArrowIcon";

interface IWelcomeWellbeing {
  onComplete: () => void;
}

export function WelcomeOnboardingThird({ onComplete }: IWelcomeWellbeing) {
  const [animationState, setAnimationState] = useState<"In" | "OutDown">("In");
  return (
    <VStack space={8}>
      <VStack space={2} mt={6}>
        <AnimatedView
          style={{ width: "100%" }}
          animation={`fade${animationState}`}
          delay={0}
          duration={750}
        >
          <Text
            textAlign="center"
            fontSize={!isWeb() ? 14 : 18}
            fontWeight="600"
          >
            Completing any of the built in exercises also teaches Miindset about
            you and what you need to improve your wellbeing, as well as what is
            and isn’t working for you as an individual.
          </Text>
        </AnimatedView>
        <AnimatedView
          style={{ width: "100%" }}
          animation={`fade${animationState}`}
          delay={300}
          duration={750}
        >
          <Text
            fontWeight={500}
            mb={2}
            fontSize={!isWeb() ? undefined : 18}
            textAlign="center"
          >
            You can see how you are doing each day by checking your ‘Insights’
            as well as receive tips and advice on what you can do to improve
            your overall wellbeing further.
          </Text>
        </AnimatedView>
      </VStack>
      <AnimatedView
        style={{ width: "100%" }}
        animation={`fade${animationState === "In" ? "InUp" : "OutDown"}`}
        delay={300 + 300}
        onAnimationEnd={() => {
          if (animationState === "OutDown") {
            onComplete();
          }
        }}
        duration={1000}
      >
        <Center mt={6}>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => setAnimationState("OutDown")}
          >
            <ArrowIcon size="4xl" />
          </TouchableOpacity>
        </Center>
      </AnimatedView>
    </VStack>
  );
}
