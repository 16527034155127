import { useNavigation } from "@react-navigation/core";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { ContentType } from "api/src/content/enum/ContentType";
import { IPressableProps } from "native-base";
import React, { useState, memo } from "react";

import { useAddViewedContent } from "../../hooks/useAddViewedContent";
import { useContent } from "../../hooks/useContent";
import { useContentFeedback } from "../../hooks/useContentFeedback";
import { useSaveContent } from "../../hooks/useSaveContent";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { openUrl } from "../../util/linking";
import { AlertDialog } from "../elements/AlertDialog";

import { ContentHorizontal } from "./ContentHorizontal";
import { ContentLarge } from "./ContentLarge";

type ExtendedPressableType = Omit<IPressableProps, "onPress">;

export interface IContentProps extends ExtendedPressableType {
  item?: Partial<ContentEntity>;
}

export interface IContentVariantProps extends ExtendedPressableType {
  item: Partial<ContentEntity>;
  hasSave?: boolean;
  onLong?: () => void;
  onPress?: () => void;
  onOpenFeedback?: () => void;
}

export const Content = memo(
  ({
    item,
    contentId,
    variant = "large",
    ...props
  }: IContentProps & {
    contentId?: string;
    variant?: "large" | "horizontal";
  }) => {
    const [isOpenFeedbackAlert, setIsOpenFeedbackAlert] =
      useState<boolean>(false);

    const { navigate } = useNavigation();
    const toast = useToast();
    const { mutate: saveContent } = useSaveContent();
    const { mutate: viewContent } = useAddViewedContent();
    const { mutate: contentFeedback } = useContentFeedback();

    // TODO: Remove this once all content is fetched from the server
    if (item && !item.image) {
      item.image = "vclb84zudq5zc48cxhl8";
    }

    let contentData = item;
    const { data: content } = useContent(contentId);
    if (content) {
      contentData = content;
    }

    if (!contentData) {
      return null;
    }

    function handlePress() {
      if (!contentData?.id) {
        return;
      }

      if (
        contentData?.url &&
        (contentData?.type === ContentType.podcast ||
          contentData?.type === ContentType.video)
      ) {
        viewContent({ contentId: contentData?.id });
        openUrl(contentData?.url);
        setIsOpenFeedbackAlert(true);
        return;
      }

      navigate("ContentDetails", { contentId: contentData?.id });
    }

    function handleLongPress() {
      if (!contentData?.id) {
        return;
      }

      saveContent({ contentId: contentData?.id });
      toast({ title: "Saved content!", status: ToastStatus.success });
    }

    function onSubmit(helpful: boolean, contentId: string | undefined) {
      if (!contentId) {
        toast({
          title: "Something went wrong",
          status: ToastStatus.error,
        });
        return;
      }
      setIsOpenFeedbackAlert(false);

      contentFeedback(
        {
          contentId,
          helpful,
        },
        {
          onSuccess: () => {
            toast({
              title: "Thank you for your feedback",
              status: ToastStatus.success,
            });
          },
          onError: () => {
            toast({
              title: "Something went wrong",
              status: ToastStatus.error,
            });
          },
        }
      );
    }

    return (
      <>
        {variant === "large" ? (
          <ContentLarge
            onLong={handleLongPress}
            onPress={handlePress}
            hasSave={true}
            item={contentData}
            {...props}
          />
        ) : (
          <ContentHorizontal
            hasSave={true}
            onLong={handleLongPress}
            onPress={handlePress}
            item={contentData}
            {...props}
          />
        )}
        <AlertDialog
          headerText="Feedback"
          bodyText="Did this content help you?"
          buttonText="Yes"
          secondaryButtonText="No"
          buttonAction={() => {
            onSubmit(true, contentData?.id);
          }}
          secondaryButtonAction={() => {
            onSubmit(false, contentData?.id);
          }}
          isOpen={isOpenFeedbackAlert}
          onClose={() => {
            setIsOpenFeedbackAlert(false);
          }}
        />
      </>
    );
  }
);
