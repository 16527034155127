import { Alert } from "react-native";

/**
 * Helper for confirmation dialogs
 *
 * @param {string} title
 * @param {string} description
 * @param {string} buttonText
 * @param {() => void} onConfirm
 * @returns {void}
 */
export const confirmation = (
  title: string,
  description: string,
  buttonText: string,
  onConfirm: () => void
) =>
  Alert.alert(
    title,
    description,
    [
      {
        text: "Cancel",
        style: "cancel",
        onPress: () => {},
      },
      {
        text: buttonText,
        onPress: () => {
          onConfirm();
        },
      },
    ],
    { cancelable: true }
  );
