import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function NutritionIcon({ ...props }: IIconProps) {
  return (
    <Icon size={81} {...props}>
      <Svg width="81" height="81" viewBox="0 0 81 81" fill="none">
        <Path
          d="M55.6875 20.2188C50.5839 19.7616 45.5625 22.75 40.5 22.75C35.4375 22.75 30.4146 19.7442 25.3125 20.2188C15.1875 21.168 10.125 30.3438 10.125 45.5313C10.125 58.1875 20.25 75.9062 27.7172 75.9062C35.1844 75.9062 35.9343 72.1094 40.5 72.1094C45.0657 72.1094 45.814 75.9062 53.2828 75.9062C60.7516 75.9062 70.875 58.1875 70.875 45.5313C70.875 30.3438 66.2871 21.168 55.6875 20.2188Z"
          stroke="#EF3F95"
          stroke-width="5.0625"
          stroke-miterlimit="10"
        />
        <Path
          d="M51.2449 13.1434C47.9226 16.4656 44.0213 17.4148 41.9393 17.698C41.749 17.7255 41.5549 17.7092 41.3718 17.6504C41.1887 17.5916 41.0214 17.4918 40.8826 17.3587C40.7438 17.2255 40.6372 17.0625 40.5709 16.882C40.5045 16.7015 40.4802 16.5082 40.4997 16.3169C40.8752 12.7582 42.4751 9.44045 45.0259 6.93073C48.5064 3.45026 52.3032 2.67506 54.3156 2.52477C54.5034 2.50763 54.6928 2.5327 54.8697 2.59814C55.0466 2.66359 55.2067 2.76776 55.3382 2.90303C55.4696 3.03831 55.5692 3.20126 55.6296 3.37997C55.69 3.55869 55.7097 3.74864 55.6872 3.93594C55.29 7.41906 53.724 10.6647 51.2449 13.1434Z"
          fill="#EF3F95"
        />
        <Path
          d="M34.1719 55.6875C36.2688 55.6875 37.9688 52.2877 37.9688 48.0938C37.9688 43.8998 36.2688 40.5 34.1719 40.5C32.0749 40.5 30.375 43.8998 30.375 48.0938C30.375 52.2877 32.0749 55.6875 34.1719 55.6875Z"
          fill="#EF3F95"
        />
        <Path
          d="M46.8281 55.6875C48.9251 55.6875 50.625 52.2877 50.625 48.0938C50.625 43.8998 48.9251 40.5 46.8281 40.5C44.7312 40.5 43.0312 43.8998 43.0312 48.0938C43.0312 52.2877 44.7312 55.6875 46.8281 55.6875Z"
          fill="#EF3F95"
        />
      </Svg>
    </Icon>
  );
}
