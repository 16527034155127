import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function ArrowForwardIcon({ color = "#C4C4C4", ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 11 20" {...props}>
      <Svg width={11} height={20} fill="none" stroke={color}>
        <Path
          d="M1.75 1.875 9.875 10 1.75 18.125"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
