import { Text, Box, VStack, HStack, Center } from "native-base";
import React from "react";

interface IContentTagsProps {
  tags: string[];
}

export function ContentTags({ tags }: IContentTagsProps) {
  return (
    <>
      <Center w="100%" justifyContent="center">
        <Box my={4} mt={4} mb={10} position="relative">
          <VStack
            justifyContent="center"
            alignItems="center"
            space={2}
            height="60px"
          >
            <Text>Tags</Text>
            <HStack flexWrap="wrap" space={2}>
              {tags?.map((value: string) => (
                <HStack
                  px={3}
                  py={1}
                  mt={2}
                  borderRadius={100}
                  bg="primary.200"
                  justifyContent="center"
                  alignItems="center"
                  space={2}
                >
                  <Text color="white">{value}</Text>
                </HStack>
              ))}
            </HStack>
          </VStack>
        </Box>
      </Center>
    </>
  );
}
