import { isArray } from "lodash";
import { useStyledSystemPropsResolver } from "native-base";
import { IVStackProps as NBVStackProps } from "native-base/lib/typescript/components/primitives/Stack/VStack";
import React, { forwardRef } from "react";
import { View } from "react-native";

import { Box } from "./Box";

interface IVStackProps extends NBVStackProps {
  space?: number;
}
export const VStack = forwardRef<View, React.PropsWithChildren<IVStackProps>>(
  (
    { children, space, ...props }: React.PropsWithChildren<IVStackProps>,
    ref
  ) => {
    const [style, otherProps] = useStyledSystemPropsResolver(props);

    return (
      <Box
        {...otherProps}
        style={style ? { ...style, flexDirection: "column" } : undefined}
        ref={ref}
      >
        {isArray(children) ? (
          children?.map((child, index) =>
            index !== children.length - 1 && space ? (
              <Box marginBottom={space}>{child}</Box>
            ) : (
              child
            )
          )
        ) : space ? (
          <Box marginBottom={space}>{children}</Box>
        ) : (
          children
        )}
      </Box>
    );
  }
);
