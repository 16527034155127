import { createContext } from "react";

import { RootStackParamList } from "../navigation/RootStackParamList";

export interface ITransitionContext {
  isTransitioning: boolean;
  startTransition: ({
    screen,
  }: {
    callback?: () => void;
    screen?: keyof RootStackParamList;
  }) => void;
}

export const TransitionContext = createContext<ITransitionContext>({
  isTransitioning: false,
  startTransition: async () => {},
});
