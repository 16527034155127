import { OrganisationUpdateDto } from "api/src/organisations/dto/organisation-update.dto";
import { UploadBadgeDto } from "api/src/organisations/dto/upload-badge.dto";
import { OrganisationEntity } from "api/src/organisations/entities/organisation.entity";
import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

export function useUpdateOrganisation() {
  return useMutation<
    IApiResponse<OrganisationEntity>,
    IApiResponse<OrganisationEntity>,
    Partial<OrganisationUpdateDto & UploadBadgeDto>
  >(
    "organisations",
    async ({ ...data }: Partial<OrganisationUpdateDto & UploadBadgeDto>) => {
      const response = await api.put<IApiResponse<OrganisationEntity>>(
        "organisations",
        data
      );

      if (response.status !== 200) {
        throw response.data;
      }

      return response.data;
    }
  );
}
