import { ICriticalIndicatorChart } from "api/src/organisations/interfaces/ICriticalIndicatorChart";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationCriticalIndicatorChart(
  location?: string,
  filter?: string,
  startingDate?: string,
  endingDate?: string
) {
  return useQuery<ICriticalIndicatorChart>(
    [
      "organisations",
      "critical-indicator-chart",
      location,
      filter,
      startingDate,
      endingDate,
    ],
    async () => {
      const response = await api.get<ICriticalIndicatorChart>(
        `organisations/critical-indicator-chart/${location ?? ""}`,
        { params: { filter, startingDate, endingDate } }
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject(
        "Could not get organisations critical indicator chart data"
      );
    },
    {
      keepPreviousData: true,
    }
  );
}
