import { Insight } from "api/src/insights/enum/insight";

import { RootStackParamList } from "../navigation/RootStackParamList";

export interface IInsightItem {
  label: string;
  insight: Insight;
  cta: {
    text: string;
    link: keyof RootStackParamList;
  };
}

export const INSIGHTS: IInsightItem[] = [
  {
    label:
      "Your mood has been steadily improving. Would you like to track your mood now?",
    insight: Insight.moodUp,
    cta: {
      text: "Track Mood",
      link: "TrackMood",
    },
  },
  {
    label:
      "Your mood has been steadily decreasing. Would you like to track your mood now?",
    insight: Insight.moodDown,
    cta: {
      text: "Track Mood",
      link: "TrackMood",
    },
  },
  {
    label:
      "Your feelings have been more positive lately. Would you like to track your feelings now?",
    insight: Insight.feelingsPositive,
    cta: {
      text: "Track Feelings",
      link: "TrackFeelings",
    },
  },
  {
    label:
      "You have had more negative than positive feelings recently. Would you like to track your feelings now?",
    insight: Insight.feelingsNegative,
    cta: {
      text: "Track Feelings",
      link: "TrackFeelings",
    },
  },
];
