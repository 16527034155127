import { Tooltip, View } from "native-base";
import React from "react";
// @ts-ignore
import { CircleProgress } from "react-gradient-progress";

import { AGE_RESILIENCE_INFO_TEXT } from "../constants/age-resilience-info-text";
import { PositionPlacementType } from "../types/PositionPlacementType";
import { useTextColor } from "../util/theme-helper";

import { CircleProgressIcon } from "./icons/CircleProgressIcon";

interface ICircleProgressBar {
  primaryColor?: string[];
  secondaryColor?: string;
  percentage: number;
  fontSize?: string;
  size?: number;
  isAgeResilience?: boolean;
  infoTextPlacement?: PositionPlacementType;
}

export function CircleProgressBar({
  primaryColor = ["#614AD3", "#EF5DA8"],
  secondaryColor = "#CBCCC466",
  percentage,
  size = 60,
  fontSize = "12px",
  isAgeResilience = false,
  infoTextPlacement = "top right",
}: ICircleProgressBar) {
  const { defaultTextColor } = useTextColor();

  return (
    <>
      {isAgeResilience && percentage === 0 ? (
        <Tooltip
          label={AGE_RESILIENCE_INFO_TEXT}
          placement={infoTextPlacement}
          w={280}
        >
          <View mx="1.5" mt="1.5">
            <CircleProgressIcon />
          </View>
        </Tooltip>
      ) : (
        <CircleProgress
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          percentage={percentage}
          width={size}
          fontFamily="Poppins_500Medium"
          fontWeight={600}
          fontSize={fontSize}
          fontColor={defaultTextColor}
          stroke="#fff"
        />
      )}
    </>
  );
}
