import { Heading, useColorModeValue, useToken, VStack } from "native-base";
import React, { useCallback } from "react";
import { Calendar } from "react-native-calendars";

import { useDayMoods } from "../../hooks/useDayMoods";
import { useTextColor } from "../../util/theme-helper";
import { ArrowBackIcon, ArrowForwardIcon } from "../icons/Icons";

import { CalendarDay } from "./CalendarDay";

export function UserMoodCalendar() {
  const [secondary500] = useToken("colors", ["secondary.500"]);
  const calendarBackground = useColorModeValue("white", "#161F32");

  const { defaultTextColor } = useTextColor();
  const { data: completedDays } = useDayMoods();
  const DayComponent = useCallback(
    (date, marking, state) => (
      <CalendarDay date={date} marking={marking} state={state} />
    ),
    []
  );

  return (
    <VStack>
      <Heading textAlign="center" fontWeight="600" fontSize="14">
        Days of completed mood tracker
      </Heading>
      <Calendar
        theme={{
          textDayHeaderFontFamily: "Poppins_400Regular",
          textMonthFontFamily: "Poppins_400Regular",
          textMonthFontSize: 18,
          calendarBackground,
          monthTextColor: defaultTextColor,
        }}
        renderArrow={(direction) =>
          direction === "left" ? (
            <ArrowBackIcon size="sm" color={secondary500} />
          ) : (
            <ArrowForwardIcon size="xs" />
          )
        }
        markedDates={completedDays}
        dayComponent={({ date, marking, state }) =>
          DayComponent(date, marking, state)
        }
      />
    </VStack>
  );
}
