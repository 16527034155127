import dayjs from "dayjs";
import { Text } from "native-base";
import React, { useMemo } from "react";

export function JournalCurrentDay() {
  const currentDay = useMemo(() => dayjs().format("MMM Do YYYY"), []);

  return (
    <Text variant="faded" fontSize={16} fontWeight="300">
      {currentDay}
    </Text>
  );
}
