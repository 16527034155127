import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { isEmpty } from "lodash";
import { HStack, Text, Box } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";

import { useLineChartOptions } from "../../../../hooks/useLineChartOptions";
import { useOrganisationMonthWiseWellbeingScores } from "../../../../hooks/useOrganisationMonthWiseWellbeingScores";

dayjs.extend(localeData);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: dayjs.monthsShort(),
  datasets: [
    {
      data: [] as number[],
      borderColor: "#6C60FF",
      backgroundColor: "#6C60FF",
    },
  ],
};

export function CriticalIndicatorsChart() {
  const chartRef = useRef<ChartJS<"line">>(null);
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(true);
  const { data: avgWellbeingScores, isLoading } =
    useOrganisationMonthWiseWellbeingScores();
  const chartOptions = useLineChartOptions({ yTitle: "Avg. Wellbeing Score" });

  useEffect(() => {
    const chart = chartRef.current;
    if (avgWellbeingScores !== undefined && !isEmpty(avgWellbeingScores)) {
      setIsDataEmpty(false);
      avgWellbeingScores.forEach((value: number) =>
        data.datasets[0].data.push(value)
      );
      chart?.update();
    }
  }, [avgWellbeingScores]);

  return (
    <HStack h="100%" w="100%">
      {isLoading || isDataEmpty ? (
        <Box alignItems="center" justifyContent="center" w="100%">
          <Text color="secondary.650">
            There's no data to be shown currently.
          </Text>
        </Box>
      ) : (
        <Chart
          type="line"
          ref={chartRef}
          options={chartOptions}
          data={data}
          height="100%"
        />
      )}
    </HStack>
  );
}
