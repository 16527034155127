import { Icon, IIconProps } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

export function UsersIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 32 33" {...props}>
      <Svg width={32} height={33} fill="none" stroke={color}>
        <Path
          d="M11 20.25a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13ZM19.427 7.492A6.5 6.5 0 1 1 21.19 20.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M2 24.925a11.002 11.002 0 0 1 18-.001M21.19 20.25a10.987 10.987 0 0 1 9 4.674"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
