import { useRoute, useNavigation } from "@react-navigation/native";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AccountType } from "api/src/users/enum/account-type";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import {
  HStack,
  VStack,
  Container,
  Image,
  Heading,
  Text,
  useBreakpointValue,
} from "native-base";
import React, { useEffect, useMemo, useState } from "react";

import { AwinCheckout } from "../components/AwinCheckout";
import { CheckoutButton } from "../components/CheckoutButton";
import { ScrollView } from "../components/elements/ScrollView";
import { ACCOUNT_TYPES } from "../constants/account-type-images";
import { useActiveSubscription } from "../hooks/useActiveSubscription";
import { useMe } from "../hooks/useMe";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";
import { getSubscriptionText } from "../util/subscription";

import { useOrder } from "./useOrder";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ??
    "pk_test_51LHmfDJ8EuGsVuj5C7teW0qYJuah5y13McLsT6OQy71tWL3PkqhnGNo7vXQYUDAxFfc6FcIOqSLnqFOvhkGamYXt00F850tCkc"
);

export function SubscriptionScreen() {
  const route = useRoute();
  const { data: me } = useMe();
  const { navigate } = useNavigation();
  const { data: activeSubscription } = useActiveSubscription();
  const { data: order } = useOrder();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (route.path?.includes("success=1")) {
      setIsSuccess(true);
    }
  }, [route]);

  const isSmallScreen = useBreakpointValue({
    base: true,
    lg: false,
  });

  const accountType = useMemo(() => {
    if (activeSubscription?.isSpecialPrice) {
      return ACCOUNT_TYPES[AccountType.standard];
    }
    return ACCOUNT_TYPES[me?.accountType ?? AccountType.standard];
  }, [me, me?.accountType, activeSubscription?.isSpecialPrice]);

  const memberShip = activeSubscription?.isSpecialPrice
    ? "Your Free Trial is ending soon"
    : `${capitalize(me?.accountType)} Membership`;

  useEffect(() => {
    if (isSmallScreen && !activeSubscription?.isFree) {
      navigate("DownloadApp");
    }
  }, [activeSubscription, isSmallScreen]);

  return (
    <>
      {isSuccess &&
      order &&
      me &&
      activeSubscription &&
      !activeSubscription?.isFree ? (
        <AwinCheckout
          subtotal={order.price}
          currencyCode="gbp"
          orderRef={activeSubscription?.id}
          saleAmount={order.saleAmount}
          voucherCode={me?.accountType === AccountType.unidays ? "unidays" : ""}
        />
      ) : null}
      <Elements stripe={stripePromise}>
        {!activeSubscription?.isFree ? <Header /> : null}
        <Body>
          <ScrollView pt="10" w="100%" px="0" _dark={{ bg: "dark.500" }}>
            <HStack w="100%" alignItems="center" justifyContent="center">
              <Container
                size="container.sm"
                alignItems="center"
                justifyContent="center"
              >
                <VStack
                  bg="secondary.200"
                  padding={5}
                  space={5}
                  w="100%"
                  justifyContent="center"
                  alignItems="center"
                  _web={{
                    bg: "white",
                    p: "10",
                    mt: "10",
                    _dark: { bg: "dark.100" },
                  }}
                >
                  <Image
                    alt="logo"
                    width={90}
                    height={90}
                    resizeMode="contain"
                    key={accountType.label}
                    source={accountType.image}
                  />
                  <VStack space={2}>
                    <Heading fontSize={16} fontWeight={600} textAlign="center">
                      {memberShip}
                    </Heading>
                    <Text textAlign="center" fontSize={12}>
                      Registered Since{" "}
                      {dayjs(me?.createdAt).format("YYYY-MM-DD")}
                    </Text>
                    <Text textAlign="center" fontSize={12}>
                      {getSubscriptionText(activeSubscription, me)}
                    </Text>
                  </VStack>
                  <CheckoutButton />
                </VStack>
              </Container>
            </HStack>
          </ScrollView>
        </Body>
      </Elements>
    </>
  );
}
