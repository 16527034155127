import { OrganisationUserEntity } from "api/src/organisations/entities/organisation-user.entity";
import { useInfiniteQuery } from "react-query";

import { IPaginatedResponse } from "../interfaces/IPaginatedResponse";
import { api } from "../util/api";

interface IUseOrganisationUsers {
  limit?: number;
  filter?: string;
}

export function useOrganisationUsers({
  limit = 10,
  filter,
}: IUseOrganisationUsers) {
  return useInfiniteQuery<IPaginatedResponse<OrganisationUserEntity>>(
    ["organisation", "all-users"],
    async ({ pageParam = 1 }) => {
      const response = await api.get<
        IPaginatedResponse<OrganisationUserEntity>
      >("organisations/users", { params: { page: pageParam, limit, filter } });

      if (response.status === 200) {
        return response?.data;
      }

      return Promise.reject("Could not get organisation Users");
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.currentPage < lastPage.meta.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
      },
    }
  );
}
