export const CONTENT_TAGS: string[] = [
  "women's health",
  "sleep",
  "mental health",
  "work",
  "food",
  "education",
  "neurodiversity",
  "diversity",
  "physical health",
  "science & technology",
  "family & relationships",
  "lgbtq+",
  "entertainment",
  "travel",
  "opinion",
  "money",
  "sports",
];
