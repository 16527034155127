import { JournalTitleEntry } from "api/src/journal/constants/journal-title-entries";

import { JournalEntryScreen } from "../enum/journal-entry-screen";

export const JOURNAL_ENTRY_SCREENS: Record<
  JournalTitleEntry,
  JournalEntryScreen
> = {
  [JournalTitleEntry.fiveMinute]: JournalEntryScreen.addFiveMinuteEntry,
  [JournalTitleEntry.gratitude]: JournalEntryScreen.addGratitudeEntry,
  [JournalTitleEntry.iAmGreat]: JournalEntryScreen.addIAmGreatEntry,
  [JournalTitleEntry.diary]: JournalEntryScreen.addDiaryEntry,
};

export const JOURNAL_ENTRY_POINT: Record<string, JournalEntryScreen> = {
  fiveMinute: JournalEntryScreen.addFiveMinuteEntry,
  gratitude: JournalEntryScreen.addGratitudeEntry,
  iAmGreat: JournalEntryScreen.addIAmGreatEntry,
  diary: JournalEntryScreen.addDiaryEntry,
  tag: JournalEntryScreen.addTagsEntry,
};
