import {
  CommonActions,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { useStripe } from "@stripe/react-stripe-js";
import React, { useEffect } from "react";

import { RootStackParamList } from "../navigation/RootStackParamList";

export function StripeRedirect() {
  const {
    params: { stripeToken },
  } = useRoute<RouteProp<RootStackParamList, "StripeApp">>();
  const stripe = useStripe();
  const { dispatch } = useNavigation();

  useEffect(() => {
    if (!stripeToken) {
      dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: "Main" }],
        })
      );

      return;
    }

    stripe?.redirectToCheckout({
      sessionId: stripeToken,
    });
  });

  return <></>;
}
