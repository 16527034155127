import LottieView from "lottie-react-native";
import React from "react";
import { Dimensions } from "react-native";

const { height } = Dimensions.get("window");
export function ConfettiAnimation() {
  return (
    <LottieView
      source={require("../../assets/animations/confetti.json")}
      style={{
        height,
        position: "absolute",
      }}
      autoPlay={true}
      loop={false}
    />
  );
}
