import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function OpenBookIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 24 21" {...props}>
      <Svg width={24} height={21} fill="none" stroke={color}>
        <Path
          d="M11.621 4.46c0-.873.345-1.71.958-2.328a3.26 3.26 0 0 1 2.313-.964h6.54c.218 0 .426.087.579.241.153.155.24.364.24.582v13.165a.826.826 0 0 1-.24.582.815.815 0 0 1-.578.241h-6.541c-.868 0-1.7.347-2.313.964a3.302 3.302 0 0 0-.958 2.327"
          strokeWidth={1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M1 15.156c0 .218.086.428.24.582a.815.815 0 0 0 .578.24h6.54c.868 0 1.7.348 2.313.965.614.617.958 1.454.958 2.327V4.46c0-.873-.344-1.71-.958-2.328a3.26 3.26 0 0 0-2.312-.964H1.818a.815.815 0 0 0-.579.241.825.825 0 0 0-.239.582v13.165Z"
          strokeWidth={1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M3.434 5.281h5.723M3.434 7.75h5.723m-5.723 2.468h5.723m4.906-4.937h5.723M14.063 7.75h5.723m-5.723 2.468h5.723M3.434 5.281h5.723M3.434 7.75h5.723m-5.723 2.468h5.723m4.906-4.937h5.723M14.063 7.75h5.723m-5.723 2.468h5.723"
          strokeLinecap="square"
        />
      </Svg>
    </Icon>
  );
}
