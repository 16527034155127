import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import { Feeling } from "api/src/emotion/enum/feeling";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Box, HStack, VStack, Pressable, Text, View } from "native-base";
import React, { useCallback, useContext, useMemo, useState } from "react";

import { Container } from "../components/Container";
import { AlertDialog } from "../components/elements/AlertDialog";
import { Button } from "../components/elements/Button";
import { OnboardingSteps } from "../components/OnboardingSteps";
import { PageHeader } from "../components/PageHeader";
import { FEELINGS } from "../constants/feelings";
import { OnboardingContext } from "../context/OnboardingContext";
import { OnboardingStep } from "../enum/signup-step";
import { useBackButton } from "../hooks/useBackButton";
import { useFeelingsSubmit } from "../hooks/useFeelingsSubmit";
import { ToastStatus, useToast } from "../hooks/useToast";
import { Header } from "../layout/Header";
import { RootStackParamList } from "../navigation/RootStackParamList";

export function TrackFeelingsScreen() {
  const { params: allParams } =
    useRoute<RouteProp<RootStackParamList, "TrackFeelings">>();
  const [selectedFeelings, setSelectedFeelings] = useState<Feeling[]>([]);
  const { goBack, navigate } = useNavigation();
  const { mutate: submitFeelings, isLoading } = useFeelingsSubmit();
  const { setCurrentStep, currentStep } = useContext(OnboardingContext);
  const toast = useToast();
  const [isSkipAlert, setIsSkipAlert] = useState<boolean>(false);
  useBackButton(() => !(!currentStep && allParams?.type !== "wellbeing"));
  const handleSubmit = useCallback(() => {
    submitFeelings(
      { feelings: selectedFeelings },
      {
        onSuccess: () => {
          if (currentStep === OnboardingStep.trackFeelings) {
            setCurrentStep(OnboardingStep.trackMood);
            navigate("TrackMoodOnboarding");
            return;
          }
          if (allParams?.type === "wellbeing") {
            navigate("TrackMood", { type: "wellbeing", status: "submitted" });
            return;
          }

          toast({ title: "Feelings recorded", status: ToastStatus.success });
          navigate("Home");
        },
        onError: () => {
          toast({
            title: "Could not record feelings",
            status: ToastStatus.error,
          });
        },
      }
    );
  }, [selectedFeelings, submitFeelings, goBack]);

  const currentDate = useMemo(() => dayjs().format("MMMM Do YYYY - HH:mm"), []);

  return (
    <>
      {!currentStep && allParams?.type !== "wellbeing" ? <Header /> : null}

      <View
        bg={{ base: "white", lg: "secondary.200" }}
        _dark={{ bg: "dark.500" }}
        minH="100vh"
        justifyContent={!currentStep ? "start" : "center"}
        mt={!currentStep ? "10" : 0}
      >
        <Container minH="unset">
          <Box
            alignSelf="center"
            p={10}
            width={{ base: "unset", lg: "70%" }}
            bg="white"
            _dark={{ bg: "dark.100" }}
            borderRadius={5}
          >
            <OnboardingSteps />
            <Box pt="10">
              <PageHeader
                label="Which emotions are you feeling right now?"
                subtitle={currentDate}
              />
            </Box>

            <VStack
              alignItems="center"
              px={{ base: 0, lg: 6 }}
              minH="100%"
              space={6}
              mt={6}
            >
              {FEELINGS.map((feeling) => (
                <Box key={feeling.title}>
                  <Box justifyContent="center" alignItems="center" pb={5}>
                    <Text
                      color="secondary.850"
                      fontWeight={400}
                      fontSize={16}
                      _dark={{ color: "secondary.200" }}
                    >
                      {`${feeling.title} feelings`}
                    </Text>
                  </Box>
                  <HStack flexWrap="wrap" justifyContent="center" space="3">
                    {feeling.data.map(({ label, value }) => {
                      const isActive = selectedFeelings.includes(value);
                      return (
                        <VStack alignItems="center" mb="1.5" key={label}>
                          <Button
                            _pressed={{ opacity: 1 }}
                            variant="moodInverted"
                            py="2"
                            px="3"
                            borderColor={isActive ? "transparent" : undefined}
                            bg={isActive ? "primary.400" : undefined}
                            _text={{
                              color: isActive ? "white" : undefined,
                            }}
                            onPress={() =>
                              setSelectedFeelings(
                                isActive
                                  ? selectedFeelings.filter((i) => i !== value)
                                  : [...selectedFeelings, value]
                              )
                            }
                          >
                            {label}
                          </Button>
                        </VStack>
                      );
                    })}
                  </HStack>
                </Box>
              ))}
              {!isEmpty(selectedFeelings) ? (
                <Box w="90%" maxW={400} mt="10">
                  <Button
                    variant="auth"
                    onPress={handleSubmit}
                    isLoading={isLoading}
                  >
                    Log my feelings
                  </Button>
                </Box>
              ) : allParams?.type === "wellbeing" ? (
                <Pressable
                  onPress={() => setIsSkipAlert(true)}
                  _pressed={{ opacity: 0.7 }}
                  pt="2"
                >
                  <Text textAlign="center" color="secondary.650">
                    Skip record feeling
                  </Text>
                </Pressable>
              ) : null}
            </VStack>
          </Box>
        </Container>
      </View>
      <AlertDialog
        headerText="Skip Record Feeling"
        bodyText="Are you sure?"
        buttonText="Yes, skip"
        showFooter={true}
        buttonAction={() => {
          setIsSkipAlert(false);
          if (allParams?.type === "wellbeing") {
            navigate("TrackMood", {
              type: "wellbeing",
              status: "skipped",
            });
            return;
          }
          setCurrentStep(OnboardingStep.trackMood);
          navigate("Digesting");
        }}
        isOpen={isSkipAlert}
        onClose={() => setIsSkipAlert(false)}
      />
    </>
  );
}
