import { isEmpty } from "lodash";
import { Box, Text } from "native-base";
import React, { useMemo, useState } from "react";

import { ChannelFilterOption } from "../components/ChannelFilter";
import { Content } from "../components/content/Content";
import { ContentFilterOption } from "../components/ContentFilter";
import { FlatList } from "../components/elements/FlatList";
import { LoadingAnimation } from "../components/elements/LoadingAnimation";
import { UserScreenHeader } from "../components/UserScreenHeader";
import { useSavedContent } from "../hooks/useSavedContent";
import { useWellbeingScore } from "../hooks/useWellbeingScore";
import { Body } from "../layout/Body";
import { getResilienceStateText } from "../util/wellbeing";

interface IProps {
  isShowNotifications?: boolean;
}

export function UserScreen({ isShowNotifications }: IProps) {
  const [typeFilter, setTypeFilter] = useState<ContentFilterOption>("all");
  const [channelFilter, setChannelFilter] =
    useState<ChannelFilterOption>("all");
  const {
    data: savedContent,
    isFetching,
    isLoading: isLoadingContent,
  } = useSavedContent({
    type: typeFilter,
    channel: channelFilter,
  });
  const { data: wellbeingScores, isLoading: isLoadingWellbeingScore } =
    useWellbeingScore();
  const resilienceStateText = useMemo(
    () => getResilienceStateText(wellbeingScores?.resilience),
    [wellbeingScores]
  );

  const hasNoContentAndFilter =
    isEmpty(savedContent) && typeFilter === "all" && channelFilter === "all";

  const isLoading = useMemo(
    () => isLoadingContent || isLoadingWellbeingScore,
    [isLoadingContent, isLoadingWellbeingScore]
  );

  if (isLoading) {
    return (
      <Box h="200" alignItems="center" justifyContent="center">
        <LoadingAnimation size={100} isLoading={true} />
      </Box>
    );
  }

  return (
    <>
      <Body>
        <FlatList
          px="0"
          data={savedContent}
          ListHeaderComponent={
            <UserScreenHeader
              typeFilter={typeFilter}
              setTypeFilter={setTypeFilter}
              channelFilter={channelFilter}
              setChannelFilter={setChannelFilter}
              resilienceStateText={resilienceStateText}
              wellbeingScores={wellbeingScores}
              hasNoContentAndFilter={hasNoContentAndFilter}
              isShowNotifications={isShowNotifications}
            />
          }
          ListEmptyComponent={
            <Box alignSelf="center" top="1%">
              {isFetching ? (
                <LoadingAnimation size={100} isLoading={isFetching} />
              ) : (
                <Text color="secondary.500" textAlign="center" mx="20" mb="20">
                  {hasNoContentAndFilter
                    ? "You haven't saved any content yet, save some content and it will appear here!"
                    : "No content found"}
                </Text>
              )}
            </Box>
          }
          renderItem={({ item }) => (
            <Box mx={4}>
              <Content item={item} variant="horizontal" />
            </Box>
          )}
          keyExtractor={({ id }) => id}
        />
      </Body>
    </>
  );
}
