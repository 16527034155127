import { MOODS_LIMIT_TO_SHOW_GRAPH } from "api/src/emotion/constants/graph-limits";
import dayjs from "dayjs";
import {
  Box,
  Text,
  View,
  VStack,
  useBreakpointValue,
  Heading,
} from "native-base";
import React, { useMemo } from "react";

import { useMoods } from "../../hooks/useMoods";
import { isWeb } from "../../util/platform";
import { Chart } from "../elements/Chart";
import { LoadingAnimation } from "../elements/LoadingAnimation";

import { EcstaticMoodSvg } from "./icons/EcstaticMoodSvg";
import { HappyMoodSvg } from "./icons/HappyMoodSvg";
import { OkayMoodSvg } from "./icons/OkayMoodSvg";
import { SadMoodSvg } from "./icons/SadMoodSvg";
import { VerySadMoodSvg } from "./icons/VerySadMoodSvg";

export function MoodsChart() {
  const { data: moods, isLoading } = useMoods();
  const moodsData = useMemo(() => {
    const data = [];
    if (moods) {
      for (let i = moods.length - 1; i >= 0; i -= 1) {
        if (
          typeof data.find(
            (item) =>
              dayjs(item.createdAt).format("ddd") ===
              dayjs(moods[i].createdAt).format("ddd")
          ) === "undefined"
        ) {
          data.push(moods[i]);
        }
      }
    }
    return data?.map(({ mood, createdAt }) => ({
      mood,
      weekDay: dayjs(createdAt).format("ddd"),
      hour: dayjs(createdAt).format("hh:mmA"),
    }));
  }, [moods]);

  const showOverlay = useMemo(
    () => moodsData && moodsData.length < MOODS_LIMIT_TO_SHOW_GRAPH,
    [moodsData]
  );

  return (
    <VStack alignItems="center">
      {showOverlay ? (
        <View
          px={10}
          zIndex={1}
          position="absolute"
          opacity={0.7}
          top={0}
          left={0}
          right={0}
          bottom={0}
          justifyContent="center"
          alignItems="center"
        >
          <Text textAlign="center" fontWeight="600">
            You will be able to see how your mood changes over the week once you
            have recorded your mood every day for 1 week.
          </Text>
        </View>
      ) : null}
      <Heading
        textAlign="center"
        fontWeight="600"
        fontSize={{ base: "14", lg: 20 }}
        color={{ base: "secondary.850", lg: "primary.200" }}
        _dark={{ color: "secondary.200" }}
      >
        Mood tracker over the last week
      </Heading>
      <Box
        pl="5"
        opacity={showOverlay ? "0.5" : "1"}
        _dark={{ opacity: showOverlay ? "0.1" : "1" }}
      >
        {!isLoading ? (
          <Chart
            height={isWeb() ? 800 : 300}
            data={moodsData || []}
            CustomTick={CustomTick}
            categories={{
              x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
              y: ["very-sad", "sad", "okay", "happy", "ecstatic"],
            }}
            interpolation="basis"
          />
        ) : (
          <Box h="200" alignItems="center" justifyContent="center">
            <LoadingAnimation size={100} isLoading={isLoading} />
          </Box>
        )}
      </Box>
    </VStack>
  );
}

export function CustomTick({
  x,
  y,
  text,
}: {
  x: number;
  y: number;
  text: string;
}) {
  const values = useBreakpointValue({
    base: { x: x - 10, y: y - 5, scale: 0.04 },
    sm: { x: x - 10, y: y - 10, scale: 0.06 },
    md: { x: x - 30, y: y - 15, scale: 0.08 },
    lg: { x: x - 30, y: y - 20, scale: 0.1 },
    xl: { x: x - 40, y: y - 25, scale: 0.12 },
  });

  if (text === "very-sad") {
    return <VerySadMoodSvg x={values.x} y={values.y} scale={values.scale} />;
  }
  if (text === "sad") {
    return <SadMoodSvg x={values.x} y={values.y} scale={values.scale} />;
  }
  if (text === "okay") {
    return <OkayMoodSvg x={values.x} y={values.y} scale={values.scale} />;
  }
  if (text === "happy") {
    return <HappyMoodSvg x={values.x} y={values.y} scale={values.scale} />;
  }
  if (text === "ecstatic") {
    return <EcstaticMoodSvg x={values.x} y={values.y} scale={values.scale} />;
  }
  return <EcstaticMoodSvg x={values.x} y={values.y} scale={values.scale} />;
}
