import { useNavigation, RouteProp } from "@react-navigation/core";
import { useRoute } from "@react-navigation/native";
import { CountryCode } from "api/src/users/types/countries";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Box, Heading, HStack, Spinner, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { AuthButton } from "../../components/auth/AuthButton";
import { AuthCard } from "../../components/auth/AuthCard";
import { AuthFooterText } from "../../components/auth/AuthFooterText";
import { AuthInput } from "../../components/auth/AuthInput";
import { AuthContent } from "../../components/AuthContent";
import { CountryInput } from "../../components/form/CountryInput";
import { DatePicker } from "../../components/form/DatePicker";
import { DropdownInput } from "../../components/form/DropdownInput";
import { GENDERS } from "../../constants/genders";
import { useFormErrors } from "../../hooks/useFormErrors";
import { useOrganisationDepartmentsByInviteCode } from "../../hooks/useOrganisationDepartmentsByInviteCode";
import { useRegister } from "../../hooks/useRegister";
import { RootStackParamList } from "../../navigation/RootStackParamList";

export function RegisterScreen() {
  const [countryCode, setCountryCode] = useState<CountryCode>("GB");
  const [inviteCode, setInviteCode] = useState("");

  const { navigate, reset } = useNavigation();
  const {
    params: { invitationCode },
  } = useRoute<RouteProp<RootStackParamList, "Register">>();

  const { mutate: register, isLoading } = useRegister();

  const {
    control,
    reset: resetForm,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm();
  const { setErrors } = useFormErrors(setError);

  const {
    data: organisationDepartment,
    isLoading: isLoadingDepartments,
    isRefetching: isRefetchingDepartments,
  } = useOrganisationDepartmentsByInviteCode(inviteCode, !isEmpty(inviteCode));

  function handleConfirm() {
    reset({
      index: 0,
      routes: [{ name: "ConfirmEmail" }],
    });
  }

  function onSubmit(data: Record<string, string>) {
    register(
      {
        ...data,
        country: countryCode,
      },
      {
        onSuccess: () => {
          handleConfirm();
        },
        onError: (data) => {
          // @ts-ignore
          setErrors(data.errors ?? data ?? {});
        },
      }
    );
  }

  useEffect(() => {
    setValue("inviteCode", invitationCode);
  }, [invitationCode]);

  const handleChangeInviteCode = (text: string) => {
    setValue("inviteCode", text);
    if (text.length === 7) {
      setInviteCode(text);
    }
  };

  return (
    <AuthContent>
      <AuthCard label="Sign up" subheading="Welcome to Miindset!">
        <VStack space={2}>
          <AuthInput
            name="email"
            placeholder="Email"
            control={control}
            errors={errors}
            keyboardType="email-address"
          />
          <AuthInput
            name="password"
            type="password"
            placeholder="Password"
            control={control}
            errors={errors}
          />
          <AuthInput
            name="firstName"
            placeholder="First name"
            control={control}
            errors={errors}
          />
          <AuthInput
            name="lastName"
            placeholder="Last name"
            control={control}
            errors={errors}
          />
          <AuthInput
            name="inviteCode"
            placeholder="Invite code (optional)"
            control={control}
            errors={errors}
            onChangeText={handleChangeInviteCode}
            isDisabled={
              inviteCode.length > 6 &&
              organisationDepartment &&
              !isEmpty(organisationDepartment)
            }
          />
          {(isLoadingDepartments || isRefetchingDepartments) && (
            <HStack space={2} justifyContent="center" my="2">
              <Spinner accessibilityLabel="Loading posts" color="primary.400" />
              <Heading fontSize="sm" fontWeight="bold">
                Verifying Invite Code
              </Heading>
            </HStack>
          )}
          {organisationDepartment && !isEmpty(organisationDepartment) && (
            <DropdownInput
              name="department"
              placeholder="Department"
              borderRadius={10}
              variant="auth"
              maxWidth={500}
              alignSelf="center"
              control={control}
              errors={errors}
              items={organisationDepartment}
            />
          )}
          <DatePicker
            name="dob"
            placeholder="Date of birth"
            maxW={500}
            width="100%"
            alignSelf="center"
            mode="date"
            endYear={parseInt(dayjs().format("YYYY")) - 16}
            control={control}
            errors={errors}
          />
          <DropdownInput
            name="gender"
            borderRadius={10}
            placeholder="Gender"
            variant="auth"
            maxWidth={500}
            alignSelf="center"
            control={control}
            errors={errors}
            items={GENDERS}
          />
          <CountryInput
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
          <Box h={4} />
          <AuthButton onPress={handleSubmit(onSubmit)} isLoading={isLoading}>
            Register
          </AuthButton>
        </VStack>
      </AuthCard>
      <AuthFooterText
        onPress={() => {
          resetForm();
          return navigate("Login");
        }}
      >
        Already have an account?{" "}
        <Text fontWeight={600} variant="strongHighlight">
          Login
        </Text>
      </AuthFooterText>
    </AuthContent>
  );
}
