import { Icon } from "native-base";
import React from "react";
import { ClipPath, Defs, G, Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function RoundCheckIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 68 68" {...props}>
      <Svg width={68} height={68} fill="none" color={color}>
        <G
          clipPath="url(#a)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <Path
            d="M48.166 48.167c7.824-7.824 7.824-20.51 0-28.334-7.824-7.824-20.51-7.824-28.333 0-7.824 7.824-7.824 20.51 0 28.334 7.824 7.824 20.509 7.824 28.333 0Z"
            fill={color}
            stroke={color}
          />
          <Path
            d="m25.986 36.121 6.01 6.01M31.996 42.132l10.017-16.028"
            stroke="#fff"
          />
        </G>
        <Defs>
          <ClipPath id="a">
            <Path
              fill="#fff"
              transform="rotate(-45 41.042 17)"
              d="M0 0h48.083v48.083H0z"
            />
          </ClipPath>
        </Defs>
      </Svg>
    </Icon>
  );
}
