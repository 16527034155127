import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import { DEFAULT_CHANNELS as channels } from "api/src/channels/constants/default-channels";
import { Channel } from "api/src/channels/enum/Channel";
import { IChannel, ISubChannel } from "api/src/channels/interface/IChannel";
import { EditContentDto } from "api/src/content/dto/edit-content.dto";
import { Age } from "api/src/content/enum/Age";
import { ContentStatus } from "api/src/content/enum/ContentStatus";
import { ContentType } from "api/src/content/enum/ContentType";
import { Gender } from "api/src/content/enum/Gender";
import { Region } from "api/src/content/enum/Region";
import { AlertLevel } from "api/src/signposting/enum/AlertLevel";
import * as ImagePicker from "expo-image-picker";
import {
  HStack,
  VStack,
  Box,
  Heading,
  Text,
  Image,
  Checkbox,
} from "native-base";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { Button } from "../../components/elements/Button";
import { Icon, IconLibrary } from "../../components/elements/Icon";
import { IconButton } from "../../components/elements/IconButton";
import { ScrollView } from "../../components/elements/ScrollView";
import AutoCompleteInput from "../../components/form/AutoCompleteInput";
import { DropdownInput } from "../../components/form/DropdownInput";
import { Input } from "../../components/form/Input";
import { RichEditor } from "../../components/form/RichEditor";
import { SliderInput } from "../../components/form/SliderInput";
import { ThresholdInput } from "../../components/form/TreshholdInput";
import { Loading } from "../../components/Loading";
import { WhiteBoxContainer } from "../../components/WhiteBoxContainer";
import { CONTENT_TAGS } from "../../constants/content-tags";
import { useAddPost } from "../../hooks/useAddPost";
import { useFormErrors } from "../../hooks/useFormErrors";
import { useReviewContent } from "../../hooks/useReviewContent";
import { useSetContentState } from "../../hooks/useSetContentState";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { useUpdatePost } from "../../hooks/useUpdatePost";
import { useUploadImage } from "../../hooks/useUploadImage";
import { Header } from "../../layout/Header";
import { RootStackParamList } from "../../navigation/RootStackParamList";
import { getImage } from "../../util/image";
import { getContentTags } from "../../util/tags";

export function EditContentScreen() {
  const { params } = useRoute<RouteProp<RootStackParamList, "ReviewContent">>();
  const { navigate, goBack, canGoBack } = useNavigation();
  const { mutate: approvePost, isLoading: isApproveLoading } =
    useSetContentState(params?.id);
  const toast = useToast();

  const { mutate: rejectPost, isLoading: isRejectLoading } = useSetContentState(
    params?.id,
    true
  );

  const {
    data,
    isLoading: isLoadingReview,
    refetch,
  } = useReviewContent(params?.id);

  const [studentPost, setStudentPost] = useState<boolean>();

  const [gender, setGender] = useState<Gender[]>([Gender.all]);
  const [age, setAge] = useState<Age[]>([
    Age.under_18,
    Age.$19_24,
    Age.$25_39,
    Age.$40_55,
    Age.$56_and_over,
  ]);
  const { mutate: uploadImage, isLoading: isUploadLoading } = useUploadImage();

  const { mutate: addPost, isLoading: isLoadingAdd } = useAddPost();

  const { mutate: updatePost, isLoading } = useUpdatePost(params?.id);

  const [image, setImage] = useState<string>();
  const [type, setType] = useState<string>();

  const handleType = (type: string) => {
    setType(type);
  };

  const handleImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      uploadImage(
        { file: result?.uri },
        {
          onSuccess: (file) => {
            setImage(file);
            setValue("image", file);
          },
        }
      );
    }
  };

  const defaultFormValues: FieldValues = useMemo(
    () => ({
      url: data?.url,
      type: data?.type,
      title: data?.title,
      tags: data?.tags ? getContentTags(data.tags) : [],
      headline: data?.headline,
      image: data?.image,
      content: data?.content,
      abstract: data?.abstract,
      region: data?.region,
      status: data?.status,
      isStudentPost: data?.isStudentPost,
      alertLevel: data?.alertLevel,
      gender: data?.gender,
      age: data?.age,
      channels: data?.channels.map((channel) => channel.slug),
      subChannels: data?.channelsContent
        ?.map((channelContent) => channelContent.subChannel)
        .filter((item) => item),
      sentiment: data?.sentiment,
      stressUpper: `${data?.stressTargetUpper}`,
      stressLower: `${data?.stressTargetLower}`,
      anxietyUpper: `${data?.anxietyTargetUpper}`,
      anxietyLower: `${data?.anxietyTargetLower}`,
      depressionUpper: `${data?.depressionTargetUpper}`,
      depressionLower: `${data?.depressionTargetLower}`,
    }),
    [data]
  );

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: params?.id ? defaultFormValues : undefined,
  });

  const getSubChannels = useCallback((): ISubChannel[] => {
    const subChannelsList: ISubChannel[] = [];
    watch("channels")?.forEach((slug: string) => {
      const channel = channels?.find((ch: IChannel) => ch.slug === slug);
      if (channel?.subChannels?.length) {
        subChannelsList.push(...channel.subChannels);
      }
    });
    return subChannelsList;
  }, [watch("channels")]);

  const { setErrors } = useFormErrors(setError);

  const onSubmit = useCallback(
    (
      data: Record<string, string> & {
        channels?: Channel[];
      } & {
        subChannels?: string[];
      } & {
        type?: ContentType;
      } & {
        region?: Region;
      } & {
        gender?: Gender[];
      } & {
        age?: Age[];
      } & {
        alertLevel?: AlertLevel;
      } & {
        isStudentPost?: boolean;
      } & {
        tags?: string[];
      }
    ) => {
      if (data.type !== ContentType.article && data.url.trim() === "") {
        setErrors({ url: "URL is required" });
        return;
      }
      if (getSubChannels().length && !data.subChannels?.length) {
        setErrors({ subChannels: "Sub-Channel is required" });
        return;
      }
      const formData: EditContentDto = {
        type: data.type,
        url: data.url,
        title: data.title,
        headline: data.headline,
        content: data.content ?? "",
        region: data.region,
        gender: data.gender,
        age: data.age,
        alertLevel: data.alertLevel,
        image: data.image,
        isStudentPost: data.isStudentPost,
        abstract: data.abstract,
        channels: data.channels ?? [],
        subChannels: data?.subChannels ?? [],
        tags: data.tags ?? [],
        sentiment: +data.sentiment,
        depressionTarget: {
          lower: +data.depressionLower,
          upper: +data.depressionUpper,
        },
        stressTarget: {
          lower: +data.stressLower,
          upper: +data.stressUpper,
        },
        anxietyTarget: {
          lower: +data.anxietyLower,
          upper: +data.anxietyUpper,
        },
      };

      if (params?.id) {
        updatePost(formData, {
          onSuccess: () => {
            toast({ title: "Content edited!", status: ToastStatus.success });
            navigate("ReviewContentsList");
          },
          onError: (data) => {
            toast({ title: "An error occurred!", status: ToastStatus.error });
            setErrors(data.errors ?? {});
          },
        });
      } else {
        addPost(formData, {
          onSuccess: () => {
            toast({ title: "Content added!", status: ToastStatus.success });
            navigate("ReviewContentsList");
          },
          onError: (data) => {
            toast({ title: "An error occurred!", status: ToastStatus.error });
            setErrors(data.errors ?? {});
          },
        });
      }
    },
    [data, updatePost]
  );

  useEffect(() => {
    if (params?.id) {
      setStudentPost(data?.isStudentPost);
      setImage(data?.image ?? "");
      setType(data?.type);
      setAge(
        data?.age ?? [
          Age.under_18,
          Age.$19_24,
          Age.$25_39,
          Age.$40_55,
          Age.$56_and_over,
        ]
      );
      setGender(data?.gender ?? [Gender.all]);

      Object.entries(defaultFormValues).forEach(([key, value]) => {
        setValue(key, value, { shouldDirty: false, shouldTouch: false });
      });
      setValue("isStudentPost", data?.isStudentPost);
    } else {
      setValue("age", [
        Age.under_18,
        Age.$19_24,
        Age.$25_39,
        Age.$40_55,
        Age.$56_and_over,
      ]);
      setValue("gender", ["all"]);
    }
  }, [data, params]);

  if (isLoadingReview) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <>
      <Header />
      <ScrollView _dark={{ bg: "dark.500" }}>
        <WhiteBoxContainer width="100%">
          <Box>
            <HStack alignItems="center" space={4}>
              <IconButton
                onPress={() => {
                  if (canGoBack()) {
                    goBack();
                  } else {
                    navigate("ReviewContentsList");
                  }
                }}
              >
                <Icon
                  size={6}
                  icon="arrowleft"
                  iconLibrary={IconLibrary.antDesign}
                />
              </IconButton>
              <Heading
                fontWeight={500}
                fontSize={24}
                ref={null}
                textAlign="left"
              >
                {params?.id ? "Edit" : "Add"} content
              </Heading>
            </HStack>
          </Box>
          <VStack pb="16">
            <VStack space={10}>
              <Box flex={1} justifyContent="center" alignItems="center">
                {image && (
                  <Image
                    key={Math.random()}
                    w={150}
                    h={150}
                    source={{ uri: getImage(image) }}
                  />
                )}
                <Button
                  w={130}
                  h={50}
                  marginTop={5}
                  onPress={handleImage}
                  isLoading={isUploadLoading}
                >
                  Upload Image
                </Button>
              </Box>
              <Box>
                <Input
                  name="title"
                  label="Title"
                  errors={errors}
                  variant="contentInput"
                  fontSize={16}
                  control={control}
                  placeholder="Title"
                />
              </Box>
              <Box>
                <Input
                  name="headline"
                  label="Headline"
                  fontSize={16}
                  variant="contentInput"
                  errors={errors}
                  control={control}
                  placeholder="Headline"
                />
              </Box>
              <Box>
                <Input
                  name="abstract"
                  label="Abstract"
                  fontSize={14}
                  variant="contentInput"
                  isTextarea={true}
                  errors={errors}
                  control={control}
                  placeholder="Abstract"
                />
              </Box>

              <ThresholdInput
                upperProps={{ control, name: "stressUpper" }}
                label="Stress threshhold"
                lowerProps={{ control, name: "stressLower" }}
              />

              <ThresholdInput
                upperProps={{ control, name: "anxietyUpper" }}
                label="Anxiety threshhold"
                lowerProps={{ control, name: "anxietyLower" }}
              />

              <ThresholdInput
                upperProps={{ control, name: "depressionUpper" }}
                label="Depression threshhold"
                lowerProps={{ control, name: "depressionLower" }}
              />

              <SliderInput
                label="Sentiment"
                name="sentiment"
                min={-100}
                max={100}
                control={control}
              />

              {channels ? (
                <DropdownInput
                  name="channels"
                  supportsMultiple={true}
                  placeholder="Select..."
                  label="Channels"
                  variant="contentInput"
                  errors={errors}
                  control={control}
                  items={
                    channels?.map((channel) => ({
                      label: channel.label,
                      value: channel.slug,
                    })) || []
                  }
                />
              ) : null}

              {getSubChannels().length ? (
                <DropdownInput
                  name="subChannels"
                  supportsMultiple={true}
                  placeholder="Select..."
                  label="Sub Channels"
                  variant="contentInput"
                  errors={errors}
                  control={control}
                  items={
                    getSubChannels()?.map((subChannel) => ({
                      label: subChannel.label,
                      value: subChannel.slug,
                    })) || []
                  }
                />
              ) : null}

              <HStack justifyContent="space-between">
                <DropdownInput
                  maxWidth="49%"
                  name="region"
                  placeholder="Select..."
                  label="Region"
                  variant="contentInput"
                  defaultValue={data?.region}
                  errors={errors}
                  control={control}
                  items={
                    Object.values(Region).map((region) => ({
                      label: region,
                      value: region,
                    })) || []
                  }
                />

                <DropdownInput
                  maxWidth="49%"
                  name="type"
                  placeholder="Select..."
                  label="Type"
                  variant="contentInput"
                  defaultValue={data?.type}
                  errors={errors}
                  setValue={handleType}
                  control={control}
                  items={
                    Object.values(ContentType).map((type) => ({
                      label: type,
                      value: type,
                    })) || []
                  }
                />
              </HStack>
              {type === "article" && (
                <Box>
                  <RichEditor
                    name="content"
                    control={control}
                    variant="contentInput"
                    label="Content"
                    errors={errors}
                  />
                </Box>
              )}
              <Box>
                <Input
                  name="url"
                  label="URL"
                  fontSize={12}
                  variant="contentInput"
                  control={control}
                  placeholder="URL"
                  errors={errors}
                />
              </Box>

              <HStack justifyContent="space-between">
                <Text fontSize={14} fontWeight={600} color="primary.400">
                  Gender
                </Text>
                <Checkbox.Group
                  onChange={(value) => {
                    setGender(value.includes("all") ? [Gender.all] : value);
                    setValue("gender", value.includes("all") ? ["all"] : value);
                  }}
                  value={gender}
                  accessibilityLabel="choose options"
                >
                  <HStack space={5}>
                    <Checkbox
                      name="male"
                      isChecked={gender?.includes(Gender.male)}
                      isDisabled={gender?.includes(Gender.all)}
                      value="male"
                    >
                      male
                    </Checkbox>
                    <Checkbox
                      name="female"
                      isChecked={gender?.includes(Gender.female)}
                      isDisabled={gender?.includes(Gender.all)}
                      value="female"
                    >
                      female
                    </Checkbox>
                    <Checkbox
                      name="other"
                      isChecked={gender?.includes(Gender.other)}
                      isDisabled={gender?.includes(Gender.all)}
                      value="other"
                    >
                      other
                    </Checkbox>
                    <Checkbox
                      name="all"
                      isChecked={gender?.includes(Gender.all)}
                      value="all"
                    >
                      all
                    </Checkbox>
                  </HStack>
                </Checkbox.Group>
              </HStack>

              <HStack justifyContent="space-between">
                <Text fontSize={14} fontWeight={600} color="primary.400">
                  Age
                </Text>

                <Checkbox.Group
                  onChange={(value) => {
                    setAge(value);
                    setValue("age", value);
                  }}
                  value={age}
                  accessibilityLabel="choose options"
                >
                  <HStack space={5}>
                    <Checkbox
                      name="18 and under"
                      isChecked={age.includes(Age.under_18)}
                      value="18 and under"
                    >
                      18 and under
                    </Checkbox>
                    <Checkbox
                      name="19-24"
                      isChecked={age.includes(Age.$19_24)}
                      value="19-24"
                    >
                      19-24
                    </Checkbox>
                    <Checkbox
                      name="25-39"
                      isChecked={age.includes(Age.$25_39)}
                      value="25-39"
                    >
                      25-39
                    </Checkbox>
                    <Checkbox
                      name="40-55"
                      isChecked={age.includes(Age.$40_55)}
                      value="40-55"
                    >
                      40-55
                    </Checkbox>
                    <Checkbox
                      name="56 and over"
                      isChecked={age.includes(Age.$56_and_over)}
                      value="56 and over"
                    >
                      56 and over
                    </Checkbox>
                  </HStack>
                </Checkbox.Group>
              </HStack>

              <DropdownInput
                name="alertLevel"
                placeholder="Select..."
                label="Alert level"
                variant="contentInput"
                defaultValue={data?.alertLevel}
                errors={errors}
                control={control}
                items={
                  Object.values(AlertLevel).map((alert) => ({
                    label: alert,
                    value: alert,
                  })) || []
                }
              />

              <AutoCompleteInput
                name="tags"
                control={control}
                items={CONTENT_TAGS}
                variant="contentInput"
                placeholder="Tags (Add new tag by typing and separating with commas)"
              />

              <HStack justifyContent="space-between">
                <Text fontSize={14} fontWeight={600} color="primary.400">
                  Is student post?
                </Text>
                <Checkbox
                  name="isStudentPost"
                  isChecked={studentPost}
                  onChange={(isChecked) => {
                    setStudentPost(isChecked);
                    setValue("isStudentPost", Boolean(isChecked));
                  }}
                  value="true"
                />
              </HStack>

              <HStack space={2}>
                <Button
                  width={100}
                  isLoading={
                    isUploadLoading || params?.id ? isLoading : isLoadingAdd
                  }
                  onPress={(e) => {
                    clearErrors();
                    handleSubmit(onSubmit)(e);
                  }}
                >
                  Save
                </Button>

                {data && data?.status !== ContentStatus.live ? (
                  <Button
                    p={2}
                    px={3}
                    bg="green.500"
                    borderRadius={500}
                    onPress={() =>
                      approvePost(undefined, {
                        onSuccess: () => {
                          toast({
                            title: "Content is live!",
                            status: ToastStatus.success,
                          });
                          refetch();
                        },
                      })
                    }
                    isLoading={isApproveLoading}
                    _pressed={{ bg: "green.600" }}
                  >
                    <Text color="white" fontSize={12}>
                      Approve
                    </Text>
                  </Button>
                ) : null}
                {data && data?.status !== ContentStatus.rejected ? (
                  <Button
                    p={2}
                    px={3}
                    bg="red.500"
                    onPress={() =>
                      rejectPost(undefined, {
                        onSuccess: () => {
                          toast({
                            title: "Content rejected!",
                            status: ToastStatus.success,
                          });
                          refetch();
                        },
                      })
                    }
                    isLoading={isRejectLoading}
                    borderRadius={500}
                    _pressed={{ bg: "red.600" }}
                  >
                    <Text color="white" fontSize={12}>
                      Reject
                    </Text>
                  </Button>
                ) : null}
              </HStack>
            </VStack>
          </VStack>
        </WhiteBoxContainer>
      </ScrollView>
    </>
  );
}
