import { CountryCode, ICountry } from "api/src/users/types/countries";
import { HStack, useColorModeValue, useToken, View } from "native-base";
import React from "react";
import CountryPicker from "react-native-country-picker-modal";

import { isWeb } from "../../util/platform";
import { useTextColor } from "../../util/theme-helper";
import { Icon, IconLibrary } from "../elements/Icon";

interface ICountryInputProps {
  countryCode: CountryCode;
  setCountryCode: (countryCode: CountryCode) => void;
}

export function CountryInput({
  countryCode,
  setCountryCode,
}: ICountryInputProps) {
  const [secondary50, secondary150, dark100, dark200] = useToken("colors", [
    "secondary.50",
    "secondary.150",
    "dark.100",
    "dark.200",
  ]);

  const { defaultTextColor, fadedTextColor } = useTextColor();

  const primaryColorVariant = useColorModeValue(secondary150, dark200);
  const backgroundColor = useColorModeValue(secondary50, dark100);

  const onSelect = (country: ICountry) => {
    setCountryCode(country.cca2);
  };

  return (
    <HStack
      borderWidth="0"
      borderRadius={10}
      h={50}
      py="1"
      pl={isWeb() ? "6" : "5"}
      pr="5"
      bg="rgba(0,0,0,0.04)"
      justifyContent="space-between"
      maxW={500}
      alignItems="center"
      alignSelf="center"
      w="100%"
      _dark={{
        bg: "dark.200",
      }}
    >
      <View width="100%">
        <CountryPicker
          {...{
            countryCode,
            withFilter: true,
            withCountryNameButton: true,
            onSelect,
            preferredCountries: ["GB", "US"],
          }}
          visible={false}
          theme={{
            fontSize: 13,
            onBackgroundTextColor: defaultTextColor,
            filterPlaceholderTextColor: fadedTextColor,
            backgroundColor,
            primaryColorVariant,
          }}
        />
      </View>
      <Icon
        icon="chevron-down"
        size={4}
        color={fadedTextColor}
        style={{
          marginLeft: -15,
          zIndex: -1,
        }}
        iconLibrary={IconLibrary.entypo}
      />
    </HStack>
  );
}
