import { HStack, useToken } from "native-base";
import React from "react";
import { Pressable } from "react-native";

import { HorizontalContentIcon } from "./icons/HorizontalContentIcon";
import { VerticalContentIcon } from "./icons/VerticalContentIcon";

export interface IButtonLayoutToggleProps {
  direction: "column" | "row";
  onPress: (direction: "column" | "row") => void;
}

export function ButtonDirectionToggle({
  direction,
  onPress,
}: IButtonLayoutToggleProps) {
  const [primaryColor, secondaryColor] = useToken("colors", [
    "primary.400",
    "secondary.400",
  ]);

  return (
    <HStack>
      <Pressable
        onPress={() => {
          onPress("column");
        }}
      >
        <VerticalContentIcon
          size={10}
          color={direction === "column" ? primaryColor : secondaryColor}
        />
      </Pressable>
      <Pressable
        onPress={() => {
          onPress("row");
        }}
      >
        <HorizontalContentIcon
          size={10}
          color={direction === "row" ? primaryColor : secondaryColor}
        />
      </Pressable>
    </HStack>
  );
}
