import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function ArrowBackIcon({ color = "#fff", ...props }: IIconProps) {
  return (
    <Icon viewBox="-8 -5 32 31" {...props}>
      <Svg fill="none" width={32} height={31} stroke={color}>
        <Path
          d="M11 20.194 1 10.846 11 1.5"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
