import { Icon } from "native-base";
import React from "react";
import { Svg, Rect, Line } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function HorizontalContentIcon({
  color = "#DF3D8D",
  ...props
}: IIconProps) {
  return (
    <Icon viewBox="0 -5 30 20" {...props}>
      <Svg width="14" height="10" viewBox="0 0 14 10" fill="none">
        <Rect x="0.5" y="0.5" width="7" height="9" rx="1.5" stroke={color} />
        <Line
          x1="9.5"
          y1="2"
          x2="13.5"
          y2="2"
          stroke={color}
          strokeLinecap="round"
        />
        <Line
          x1="9.5"
          y1="4.5"
          x2="13.5"
          y2="4.5"
          stroke={color}
          strokeLinecap="round"
        />
        <Line
          x1="9.5"
          y1="7"
          x2="13.5"
          y2="7"
          stroke={color}
          strokeLinecap="round"
        />
      </Svg>
    </Icon>
  );
}
