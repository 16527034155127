import { Button, Text, IButtonProps, useColorModeValue } from "native-base";
import React from "react";

import { IconLibrary, Icon } from "./Icon";

export interface IIconButtonWithTextProps extends IButtonProps {
  iconName?: string;
  title?: string;
}

export function IconButtonWithText({
  title = "",
  iconName = "",
  ...props
}: IIconButtonWithTextProps) {
  const iconColor = useColorModeValue("secondary.800", "secondary.400");
  return (
    <Button
      variant="outline"
      borderWidth={2}
      h={10}
      leftIcon={
        <Icon
          icon={iconName}
          size={4}
          color={iconColor}
          iconLibrary={IconLibrary.feather}
        />
      }
      {...props}
    >
      <Text fontSize="sm">{title}</Text>
    </Button>
  );
}
