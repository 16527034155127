import { Text, Box, HStack, VStack, Center } from "native-base";
import React, { useState } from "react";

import { useAddViewedContent } from "../../hooks/useAddViewedContent";
import { useContentFeedback } from "../../hooks/useContentFeedback";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { Button } from "../elements/Button";

interface IContentFeedbackProps {
  contentId: string;
}

export function ContentFeedback({ contentId }: IContentFeedbackProps) {
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] =
    useState<boolean>(false);
  const { mutate: contentFeedback } = useContentFeedback();
  const { mutate: addViewedContent } = useAddViewedContent();
  const toast = useToast();

  function onSubmit(helpful: boolean) {
    setIsFeedbackSubmitted(true);

    contentFeedback(
      {
        contentId,
        helpful,
      },
      {
        onSuccess: () => {
          toast({
            title: "Thank you for your feedback",
            status: ToastStatus.success,
          });
        },
        onError: () => {
          toast({
            title: "Something went wrong",
            status: ToastStatus.error,
          });
        },
      }
    );
    addViewedContent({
      contentId,
    });
  }

  if (!contentId) {
    return null;
  }

  return (
    <>
      <Box my={4} mt={4} mb={10} position="relative">
        <VStack
          opacity={isFeedbackSubmitted ? 0 : 1}
          justifyContent="center"
          alignItems="center"
          pointerEvents={isFeedbackSubmitted ? "none" : undefined}
          space={2}
          height="60px"
        >
          <Text>Did this article help you?</Text>
          <HStack>
            <Button
              variant="moodInverted"
              py="1"
              px="3"
              minW={20}
              onPress={() => {
                onSubmit(true);
              }}
            >
              <Text fontSize="12">Yes</Text>
            </Button>
            <Button
              variant="moodInverted"
              py="1"
              px="3"
              minW={20}
              ml={2}
              onPress={() => {
                onSubmit(false);
              }}
            >
              <Text fontSize="12">No</Text>
            </Button>
          </HStack>
        </VStack>
        <Center
          position="absolute"
          pointerEvents="none"
          w="100%"
          h="100%"
          opacity={!isFeedbackSubmitted ? 0 : undefined}
          display={!isFeedbackSubmitted ? "none" : undefined}
        >
          <Text textAlign="center">Thanks for your feedback!</Text>
        </Center>
      </Box>
    </>
  );
}
