import { Box, HStack } from "native-base";
import React, { useContext, useMemo } from "react";
import { SafeAreaView } from "react-native-safe-area-context";

import { SecondLoginContext } from "../../context/SecondLoginContext";
import { SecondLoginStepsType } from "../../enum/second-login-steps";

const SECOND_LOGIN_ORDER = [
  SecondLoginStepsType.getGroundedExercise,
  SecondLoginStepsType.habitTracker,
  SecondLoginStepsType.help,
];

const SIGNUP_STEP_COUNT = SECOND_LOGIN_ORDER.length;

export function SecondLoginSteps() {
  const { currentStep } = useContext(SecondLoginContext);

  const currentStepIndex = useMemo(
    () => (currentStep ? SECOND_LOGIN_ORDER.indexOf(currentStep) : -1),
    [currentStep]
  );
  if (!currentStep) {
    return null;
  }

  return (
    <SafeAreaView>
      <HStack
        _dark={{ bg: "dark.100" }}
        space={2}
        position="relative"
        px={5}
        _android={{ pt: "4" }}
      >
        {[...Array(SIGNUP_STEP_COUNT)].map((_, index) => (
          <Box
            flex={1}
            key={index}
            height={1}
            bg={currentStepIndex >= index ? "primary.200" : "secondary.200"}
          />
        ))}
      </HStack>
    </SafeAreaView>
  );
}
