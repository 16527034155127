import React, { ReactNode } from "react";

import { SplashScreen } from "./SplashScreen";

interface IAnimatedAppLoaderProps {
  children: ReactNode;
}

export function AppLoader({ children }: IAnimatedAppLoaderProps) {
  return <SplashScreen>{children}</SplashScreen>;
}
