import { Box, Heading, HStack, Text, VStack } from "native-base";
import React from "react";

import { Container } from "../../components/Container";
import { ScrollView } from "../../components/elements/ScrollView";
import { EngagementChart } from "../../components/organisations/EngagementChart.web";
import { OrganisationTabs } from "../../components/organisations/OrganisationTabs";
import { useOrganisationEngagement } from "../../hooks/useOrganisationEngagement";
import { Header } from "../../layout/Header";

export function EngagementScreen() {
  const { data: organisationEngagement, isLoading } =
    useOrganisationEngagement();

  return (
    <>
      <Header hasBack={false} />
      <ScrollView
        bg="secondary.200"
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
        _dark={{ bg: "dark.500" }}
      >
        <Container size="container.xl" pt={10}>
          <Box h="100%" w="100%">
            <OrganisationTabs activeTab="Engagement" />
            <VStack space={5} mt={10}>
              <HStack
                space={4}
                ml={6}
                mb={6}
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
              >
                <VStack space={2}>
                  <Heading fontSize={24} color="primary.400" fontWeight="600">
                    Content Breakdown
                  </Heading>

                  <Text fontSize={16}>
                    % of content clicked on within each channel (including
                    articles, videos, podcasts)
                  </Text>
                </VStack>
              </HStack>
              <VStack space={5} alignItems="center">
                <EngagementChart
                  data={organisationEngagement ?? []}
                  isLoading={isLoading}
                />
              </VStack>
            </VStack>
          </Box>
        </Container>
      </ScrollView>
    </>
  );
}
