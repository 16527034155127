export enum AccountType {
  ambassador = "ambassador",
  founder = "founder",
  corporate = "corporate",
  corporateFamily = "corporateFamily",
  standard = "standard",
  student = "student",
  vip = "vip",
  unidays = "unidays",
}
