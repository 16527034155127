import React from "react";

import { Button } from "../elements/Button";
import { ImageBox } from "../ImageBox";

interface IUploadImageProps {
  handleImage: () => void;
  image?: string;
  isLoading?: boolean;
}

export function UploadImage({
  handleImage,
  image,
  isLoading = false,
}: IUploadImageProps) {
  return (
    <>
      <ImageBox
        handleImage={handleImage}
        image={image}
        supportedFormats="jpg, jpeg, png"
        isLoading={isLoading}
      />
      {image ? (
        <Button
          w={150}
          h={50}
          marginTop={5}
          onPress={handleImage}
          isDisabled={isLoading}
        >
          Change Image
        </Button>
      ) : null}
    </>
  );
}
