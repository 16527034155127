import { Channel, WomenHealthSubChannel } from "../enum/Channel";
import { IChannel } from "../interface/IChannel";

export const DEFAULT_CHANNELS: IChannel[] = [
  {
    label: "Women's Health",
    slug: Channel.womenHealth,
    subChannels: [
      {
        label: "Fertility, Pregnancy & Postnatal Support",
        slug: WomenHealthSubChannel.fertitlitPregnancyAndPostnatalSupport,
      },
      {
        label: "Sexual Health",
        slug: WomenHealthSubChannel.sexualHealth,
      },
      {
        label: "Family And Relationships",
        slug: WomenHealthSubChannel.familyAndRelationships,
      },
      {
        label: "Empowerment",
        slug: WomenHealthSubChannel.empowerment,
      },
      {
        label: "Non-Binary And Trans",
        slug: WomenHealthSubChannel.nonBinaryAndTrans,
      },
      {
        label: "Nutrition Diet And Eating",
        slug: WomenHealthSubChannel.nutritionDietAndEating,
      },
      {
        label: "Healthy Ageing And Long-Term Conditions",
        slug: WomenHealthSubChannel.healthyAgeingAndLongTermCondition,
      },
      {
        label: "Premenopause, Menopause And Perimenopause",
        slug: WomenHealthSubChannel.premenopauseMenopauseAndPerimenopause,
      },
      {
        label: "Disease Health And Wellness",
        slug: WomenHealthSubChannel.diseaseHealthAndWellness,
      },
      {
        label: "Health And Beauty",
        slug: WomenHealthSubChannel.healthAndBeauty,
      },
    ],
  },
  {
    label: "Mental Health",
    slug: Channel.mentalHealth,
  },
  {
    label: "Work",
    slug: Channel.work,
  },
  {
    label: "Food",
    slug: Channel.food,
  },
  {
    label: "Education",
    slug: Channel.education,
  },
  {
    label: "Neurodiversity",
    slug: Channel.neurodiversity,
  },
  {
    label: "Diversity",
    slug: Channel.diversity,
  },
  {
    label: "Physical Health",
    slug: Channel.physicalHealth,
  },
  {
    label: "Science & Technology",
    slug: Channel.scienceAndTechnology,
  },
  {
    label: "Family & Relationships",
    slug: Channel.familyAndRelationships,
  },
  {
    label: "LGBTQ+",
    slug: Channel.lgbtq,
  },
  {
    label: "Entertainment",
    slug: Channel.entertainment,
  },
  {
    label: "Opinion",
    slug: Channel.opinion,
  },

  {
    label: "Money",
    slug: Channel.money,
  },
  {
    label: "Travel",
    slug: Channel.travel,
  },

  {
    label: "Sleep",
    slug: Channel.sleep,
  },

  {
    label: "Sport",
    slug: Channel.sport,
  },
];
