import { IChannel } from "api/src/channels/interface/IChannel";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useChannels() {
  return useQuery<IChannel[]>(
    ["channels"],
    async () => {
      const response = await api.get<IChannel[]>("channels", {});

      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Could fetch channels");
    },
    {
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}
