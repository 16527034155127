import { JournalTitleEntry } from "api/src/journal/constants/journal-title-entries";
import { Divider, Heading, HStack, Text, VStack } from "native-base";
import React from "react";

import { ScrollView } from "../../components/elements/ScrollView";
import { JournalEntry } from "../../components/journal/JournalEntry";
import { WhiteBoxContainer } from "../../components/WhiteBoxContainer";
import { Body } from "../../layout/Body";
import { Header } from "../../layout/Header";

export function JournalTypeScreen() {
  return (
    <>
      <Header hasBack={true} />
      <Body>
        <ScrollView _web={{ _dark: { bg: "dark.500" } }}>
          <WhiteBoxContainer>
            <HStack justifyContent="center" mb="4">
              <Heading fontSize={[20, 24]} fontWeight="600">
                Choose Your Journal Entry
              </Heading>
            </HStack>
            <VStack pt={[5, 10]}>
              <Divider />
              {Object.entries(JournalTitleEntry).map(([, value], index) => (
                <JournalEntry key={index} title={value} />
              ))}
            </VStack>
            <Text
              pt="10"
              fontSize={[12, 16]}
              fontWeight="600"
              textAlign="justify"
            >
              Disclaimer: All the content written down in your journal by you is
              owned by you, and cannot be viewed or shared by Miindset in any
              form. These posts are yours to do with as you wish, in the hope
              that they improve your mental health journey.
            </Text>
          </WhiteBoxContainer>
        </ScrollView>
      </Body>
    </>
  );
}
