import { useToken, View } from "native-base";
import React, { useEffect, useRef } from "react";
import { Easing, Dimensions, Animated } from "react-native";

const { width, height } = Dimensions.get("window");

export enum CirclePosition {
  top,
  right,
  center,
  bottom,
  topRight,
  bottomLeft,
  bottomRight,
}

interface ICircleTransition {
  isOpen: boolean;
  onComplete?: () => void;
  startOpen: boolean;
  position?: CirclePosition;
  topOffset?: number;
}

const OPEN_SCALE = 2.5;
export function CircleTransition({
  isOpen,
  startOpen,
  topOffset,
  position = CirclePosition.center,
  onComplete,
}: ICircleTransition) {
  const [primaryColor] = useToken("colors", ["primary.400"]);
  const scale = useRef(new Animated.Value(startOpen ? OPEN_SCALE : 0)).current;
  const size = Math.max(width, height) + 20;

  useEffect(() => {
    Animated.timing(scale, {
      toValue: isOpen ? OPEN_SCALE : 0,
      easing: Easing.linear,
      duration: 750,
      useNativeDriver: true,
    }).start(() => {
      if (onComplete) {
        onComplete();
      }
    });
  }, [isOpen, scale]);

  function getLeftPosition(position: CirclePosition) {
    const halfSize = size / 2;
    const halfWidth = width / 2;
    const marginHorizontalTopLeft = -halfSize;

    switch (position) {
      case CirclePosition.center:
      case CirclePosition.top:
      case CirclePosition.bottom:
        return marginHorizontalTopLeft + halfWidth;
      case CirclePosition.topRight:
      case CirclePosition.bottomRight:
      case CirclePosition.right:
      default:
        marginHorizontalTopLeft;
    }
  }

  function getTopPosition(position: CirclePosition) {
    const halfSize = size / 2;
    const halfHeight = height / 2;
    const marginVerticalTopLeft = -halfSize;

    switch (position) {
      case CirclePosition.center:
      case CirclePosition.top:
      case CirclePosition.right:
        return marginVerticalTopLeft + halfHeight;
      case CirclePosition.bottomLeft:
      case CirclePosition.bottomRight:
      case CirclePosition.bottom:
      default:
        return marginVerticalTopLeft;
    }
  }

  const topPosition = topOffset ?? getTopPosition(position);
  const leftPosition = getLeftPosition(position);

  return (
    <View _web={{ position: "fixed" }}>
      <Animated.View
        pointerEvents="none"
        style={{
          top: topPosition - height / 2,
          zIndex: 2000,
          left: leftPosition,
          backgroundColor: primaryColor,
          width: size,
          height: size,
          borderRadius: size / 2,
          transform: [
            {
              scale,
            },
          ],
        }}
      />
    </View>
  );
}
