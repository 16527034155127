import { Channel } from "api/src/channels/enum/Channel";
import { ContentType } from "api/src/content/enum/ContentType";
import { capitalize, isEmpty } from "lodash";
import { Divider, Text, HStack, VStack, Button } from "native-base";
import React, { useState } from "react";

import { useChannelContent } from "../../hooks/useChannelContent";
import { useOrganisationChannelContents } from "../../hooks/useOrganisationChannelContents";
import { ChannelType } from "../../types/channel-type";
import { Grid } from "../Grid";
import { HeadphonesIcon, OpenBookIcon, VideoCameraIcon } from "../icons/Icons";

import { ContentCard } from "./ContentCard.web";

interface IChannelContentContainerProps {
  type?: ContentType;
  hideIcon?: boolean;
  direction: "row" | "column";
  label?: string;
  channelType: ChannelType;
  channel: Channel;
  isOrganisation: boolean;
  subChannel?: string;
}

export function ChannelContentContainer({
  label,
  hideIcon,
  type = ContentType.article,
  direction = "column",
  channelType,
  channel,
  isOrganisation = false,
  subChannel,
}: IChannelContentContainerProps) {
  const [page, setPage] = useState<number>(1);
  const {
    data: channelContent,
    fetchNextPage,
    hasNextPage,
  } = useChannelContent({
    channel,
    contentType: type,
    isEnabled: !isOrganisation,
    subChannel,
  });

  const {
    data: organisationContent,
    fetchNextPage: organisationFetchNextPage,
    hasNextPage: organisationHasNextPage,
  } = useOrganisationChannelContents({
    contentType: type,
    isEnabled: isOrganisation,
  });

  const content = isOrganisation
    ? organisationContent?.pages.map((item) => item.content.items).flat() || []
    : channelContent?.pages.map((item) => item.content.items).flat() || [];

  function getIcon(type: ContentType) {
    switch (type) {
      case ContentType.article:
        return OpenBookIcon;
      case ContentType.podcast:
        return HeadphonesIcon;
      case ContentType.video:
        return VideoCameraIcon;
      default:
        return OpenBookIcon;
    }
  }

  const Icon = getIcon(type);

  if (isEmpty(content)) {
    return null;
  }

  return (
    <VStack position="relative" w="100%">
      <HStack alignItems="center" my={7}>
        {!hideIcon ? (
          <>
            <Icon color="primary.400" />
            <Divider orientation="vertical" mx={5} />
          </>
        ) : null}

        <Text color="primary.400" fontWeight="600">
          {label ?? capitalize(type)}
        </Text>
      </HStack>
      <Grid direction={direction}>
        {content?.map((item, index) => (
          <ContentCard
            contentData={item}
            direction={direction}
            channelType={channelType}
            key={index}
          />
        ))}
      </Grid>
      <HStack marginTop={5} justifyContent="center">
        {hasNextPage || organisationHasNextPage ? (
          <Button
            rightIcon={<Icon size={5} />}
            onPress={() => {
              setPage(page + 1);
              if (isOrganisation) {
                organisationFetchNextPage({ pageParam: page + 1 });
                return;
              }
              fetchNextPage({ pageParam: page + 1 });
            }}
          >
            Load More
          </Button>
        ) : null}
      </HStack>
      <Divider mt={5} />
    </VStack>
  );
}
