import { Channel } from "api/src/channels/enum/Channel";
import { IChannel } from "api/src/channels/interface/IChannel";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { Box, HStack, Text } from "native-base";
import React, { useCallback, useLayoutEffect, useState } from "react";
import { Pressable } from "react-native";

import { useChannels } from "../hooks/useChannels";
import { useSavedContent } from "../hooks/useSavedContent";

import { ContentFilterOption } from "./ContentFilter";
import { FlatList } from "./elements/FlatList";
import { LoadingAnimation } from "./elements/LoadingAnimation";

export type ChannelFilterOption = "all" | Channel;
interface IChannelFilter {
  onCategoryChange: (filter: ChannelFilterOption) => void;
  activeCategory: ChannelFilterOption;
  activeFilter: ContentFilterOption;
}

export function ChannelFilter({
  onCategoryChange,
  activeCategory,
  activeFilter,
}: IChannelFilter) {
  const [filteredChannels, setFilteredChannels] = useState<IChannel[]>([]);
  const [isSwitchingType, setSwitchingType] = useState<boolean>(false);
  const { data } = useChannels();
  const { data: allSavedContent } = useSavedContent({
    type: activeFilter,
    channel: "all",
  });

  useLayoutEffect(() => {
    if (allSavedContent && data) {
      setSwitchingType(true);
      setFilteredChannels(
        data.filter((item) =>
          allSavedContent.find((content: ContentEntity) =>
            content.channels.find(
              (channel: IChannel) => channel.slug === item.slug
            )
          )
        )
      );
      setTimeout(() => {
        setSwitchingType(false);
      }, 1);
    }
  }, [allSavedContent, data]);

  const getLabelColor = useCallback(
    (name: string) =>
      activeCategory === name ? "primary.300" : "secondary.700",
    [activeCategory]
  );

  return (
    <>
      {!isSwitchingType ? (
        <FlatList
          px="0"
          data={filteredChannels}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item, index }) => (
            <Pressable
              onPress={() => {
                const newActiveCategory =
                  activeCategory === item.slug ? "all" : item.slug;

                onCategoryChange(newActiveCategory);
              }}
            >
              <HStack alignItems="center">
                <Text
                  fontSize={[13, 15]}
                  fontWeight="600"
                  color={getLabelColor(item.slug)}
                >
                  {item.label}
                </Text>
                {index + 1 !== filteredChannels?.length ? (
                  <Box w="2" h="0.5" bg="secondary.500" mx="2" />
                ) : null}
              </HStack>
            </Pressable>
          )}
          keyExtractor={(item, index) => index.toString()}
        />
      ) : (
        <LoadingAnimation size={100} isLoading={isSwitchingType} />
      )}
    </>
  );
}
