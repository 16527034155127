import { AccountType } from "api/src/users/enum/account-type";

/**
 * Get content type icon
 *
 * @param type - Content type
 * @returns the icon component
 */
export const getAccountExplanation = (type: AccountType) => {
  switch (type) {
    case AccountType.ambassador:
      return "You are one of a very limited number of members who joined us during our private beta on our journey to provide a safe place for everyone to improve their wellbeing and look after themselves.";
    case AccountType.founder:
      return "Upgraded B2C FREE subscription. Full access to all third party services. Full insights dashboard and access to EAP services.";
    case AccountType.corporate:
      return "Upgraded B2C paid monthly subscription. Full access to all third party services. Full insights dashboard (employer pays which may also give you access to EAP services).";
    case AccountType.standard:
      return "Upgraded B2C paid monthly subscription. Full access to all third party services. Full insights dashboard";
    case AccountType.student:
      return "Upgraded B2C paid monthly subscription. Full access to all third party services";
    case AccountType.unidays:
      return "Upgraded B2C paid monthly subscription. Full access to all third party services";
    case AccountType.vip:
      return "Discounted annual upfront payment and unique VIP badge Full access to all third party services. Full insights dashboard (employer pays which may also give you access to EAP services).";
    default:
      return null;
  }
};
