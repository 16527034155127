import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function PersonWalkIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 74 74" {...props}>
      <Svg width={74} height={74} fill="none">
        <Path
          d="M34.715 13.875a9.25 9.25 0 1 1 9.25 9.25 9.279 9.279 0 0 1-9.25-9.25Zm25.437 25.438c-8.238 0-12.054-4.076-16.072-8.412-1.011-1.099-2.081-2.226-3.18-3.238-10.753-9.944-27.894 6.967-28.646 7.69a2.343 2.343 0 0 0 1.6 4.148 2.341 2.341 0 0 0 1.696-.853 45.471 45.471 0 0 1 8.816-6.707c4.134-2.37 7.602-3.208 10.32-2.543l-5.117 11.765-10.84 24.975a2.284 2.284 0 0 0 1.186 3.035c.288.138.605.208.925.202a2.283 2.283 0 0 0 2.11-1.388l9.712-22.344 8.99 6.417v15.002a2.312 2.312 0 1 0 4.625 0V50.876a2.255 2.255 0 0 0-.983-1.879L34.57 41.307l4.076-9.395 2.052 2.14c4.105 4.393 9.192 9.886 19.454 9.886a2.312 2.312 0 1 0 0-4.626Z"
          fill="#DF3D8D"
        />
      </Svg>
    </Icon>
  );
}
