import { Heading, VStack, Pressable, Center, Text } from "native-base";
import React, { useContext, useState } from "react";
import { View as AnimatedView } from "react-native-animatable";
import { TouchableOpacity } from "react-native-gesture-handler";

import { OnboardingContext } from "../../context/OnboardingContext";
import { OnboardingStep } from "../../enum/signup-step";
import { isWeb } from "../../util/platform";
import { ArrowIcon } from "../icons/ArrowIcon";

interface IWelcomeWellbeing {
  isOnboarding?: boolean;
  onComplete: () => void;
  handleSkip?: () => void;
}

export function WelcomeWellbeing({
  isOnboarding = false,
  onComplete,
  handleSkip,
}: IWelcomeWellbeing) {
  const { currentStep } = useContext(OnboardingContext);

  const isFirstTime = currentStep === OnboardingStep.wellbeing;

  const [animationState, setAnimationState] = useState<"In" | "OutDown">("In");
  return (
    <VStack space={8}>
      <AnimatedView
        style={{ width: "100%" }}
        animation={`fade${animationState}`}
        delay={1000}
        duration={750}
      >
        <Heading fontSize={28} fontWeight="600" textAlign="center">
          Miindset Wellbeing Checker
        </Heading>
      </AnimatedView>
      <VStack space={2}>
        <AnimatedView
          style={{ width: "100%" }}
          animation={`fade${animationState}`}
          delay={1000 + 300 + 100}
          duration={750}
        >
          <Text
            textAlign="center"
            fontSize={!isWeb() ? 16 : 24}
            fontWeight="600"
          >
            {isFirstTime ? "Completely confidential!" : "Welcome back!"}
          </Text>
        </AnimatedView>
        <AnimatedView
          style={{ width: "100%" }}
          animation={`fade${animationState}`}
          delay={1000 + 300 + 100}
          duration={750}
        >
          <Text textAlign="center" lineHeight={24} fontSize={16}>
            Personalise your news feed with 11 quick questions
          </Text>
        </AnimatedView>
      </VStack>
      <AnimatedView
        style={{ width: "100%" }}
        animation={`fade${animationState === "In" ? "InUp" : "OutDown"}`}
        delay={1000 + 300 + 400}
        onAnimationEnd={() => {
          if (animationState === "OutDown") {
            onComplete();
          }
        }}
        duration={1000}
      >
        <Center mt={6}>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => setAnimationState("OutDown")}
          >
            <ArrowIcon size="4xl" />
          </TouchableOpacity>
        </Center>
      </AnimatedView>
      {!isOnboarding && (
        <AnimatedView
          animation={`fade${animationState === "In" ? "InUp" : "OutDown"}`}
          delay={2000}
          duration={750}
        >
          <Pressable onPress={handleSkip} _pressed={{ opacity: 0.7 }} pt="2">
            <Text textAlign="center" color="secondary.650">
              Skip wellbeing checker
            </Text>
          </Pressable>
        </AnimatedView>
      )}
    </VStack>
  );
}
