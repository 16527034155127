import { IOrganisationLocation } from "api/src/organisations/interfaces/IOrganisationLocations";
import { useToken, HStack, Heading } from "native-base";
import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";

import { Icon, IconLibrary } from "../../../elements/Icon";
import { IconButton } from "../../../elements/IconButton";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

interface IZoomable {
  center?: [number, number];
  zoom?: number;
  scaleFactor: number;
}

interface IMapChartProps {
  locations: IOrganisationLocation[] | undefined;
  setLocationFilter: React.Dispatch<React.SetStateAction<string>>;
}

const DEFAULT_ZOOMABLE: IZoomable = {
  center: [20, 0],
  zoom: 1.1,
  scaleFactor: 1,
};

export function OrganisationOverviewMap({
  locations,
  setLocationFilter,
}: IMapChartProps) {
  const [locationHeader, setLocationHeader] = useState<string>("");
  const [primaryColor, primaryDarkColor] = useToken("colors", [
    "primary.400",
    "primary.600",
  ]);
  const [zoomable, setZoomable] = useState<IZoomable>(DEFAULT_ZOOMABLE);

  return (
    <>
      <HStack
        justifyContent="space-between"
        bg="secondary.50"
        w="100%"
        alignItems="center"
        py={1}
        _dark={{ bg: "transparent" }}
      >
        <Heading fontSize="xl" fontWeight={500}>
          {locationHeader || "All Locations"}
        </Heading>
        <IconButton
          onPress={() => {
            setZoomable(DEFAULT_ZOOMABLE);
            setLocationFilter("");
            setLocationHeader("");
          }}
        >
          <Icon
            icon="zoom-out-map"
            iconLibrary={IconLibrary.material}
            size={5}
          />
        </IconButton>
      </HStack>

      <ComposableMap>
        <ZoomableGroup
          center={zoomable.center}
          zoom={zoomable.zoom}
          minZoom={zoomable.zoom}
          // @ts-ignore - Wrong interface values for the onMove in react-simple-maps
          onMove={({ zoom }) => {
            if (zoom !== 8) {
              setZoomable({ scaleFactor: zoom });
            }
          }}
        >
          <Geographies geography={geoUrl} fill={primaryColor}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    hover: {
                      fill: primaryDarkColor,
                      outline: "none",
                    },
                    pressed: {
                      fill: primaryDarkColor,
                      outline: "none",
                    },
                  }}
                />
              ))
            }
          </Geographies>
          {locations?.length &&
            locations.map((location) => (
              <>
                <Marker
                  key={location.coordinates.toString()}
                  coordinates={location.coordinates}
                  onMouseEnter={() => {
                    if (zoomable.zoom !== 8) {
                      setLocationHeader(`${location.countryName}`);
                    }
                  }}
                  onMouseLeave={() => {
                    if (zoomable.zoom !== 8) {
                      setLocationHeader("");
                    }
                  }}
                  onClick={() => {
                    setZoomable({
                      center: location.coordinates,
                      zoom: 8,
                      scaleFactor: 7,
                    });
                    setLocationFilter(location.countryCode);
                    setLocationHeader(`${location.countryName}`);
                  }}
                >
                  <circle
                    r={10 / zoomable.scaleFactor}
                    fill="#000"
                    stroke="#fff"
                    strokeWidth={2 / zoomable.scaleFactor}
                  />
                </Marker>
              </>
            ))}
        </ZoomableGroup>
      </ComposableMap>
    </>
  );
}
