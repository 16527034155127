import AsyncStorage from "@react-native-async-storage/async-storage";
import { UserEntity } from "api/src/users/entities/user.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";
import { setUserData } from "../util/bugsnag";

export function useMe() {
  return useQuery<UserEntity>(
    ["account"],
    async () => {
      const awc = await AsyncStorage.getItem("awc");
      const response = await api.get<UserEntity>("users/account", {
        params: { awc: awc ?? undefined },
      });
      if (response.status === 200) {
        const { id, email, firstName, lastName } = response.data;
        setUserData(id, email, `${firstName} ${lastName}`);
        return response.data;
      }

      return Promise.reject(response.status);
    },
    {
      keepPreviousData: true,
      retry: false,
      staleTime: 60000,
    }
  );
}
