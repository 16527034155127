import {
  Center,
  Container as NBContainer,
  IContainerProps as NBIContainerProps,
} from "native-base";
import React from "react";

export interface IContainerProps extends NBIContainerProps {
  children?: React.ReactNode;
}

export function Container({ children, ...props }: IContainerProps) {
  return (
    <Center>
      <NBContainer maxWidth="100%" width={1300} px="4" {...props}>
        {children}
      </NBContainer>
    </Center>
  );
}
