import { useNavigation } from "@react-navigation/core";
import { VStack, Text } from "native-base";
import React from "react";

import { AuthButton } from "../components/auth/AuthButton";
import { AuthCard } from "../components/auth/AuthCard";
import { AuthContent } from "../components/AuthContent";
import { APP_INFO } from "../constants/app-info";
import { useAppInfo } from "../hooks/useAppInfo";
import { isWeb } from "../util/platform";

export function AppInfoScreen() {
  const { navigate } = useNavigation();
  const { mutateAsync: readAppInfo } = useAppInfo();

  async function handleNextPress() {
    await readAppInfo();
    navigate(isWeb() ? "Home" : "App");
  }
  return (
    <AuthContent>
      <AuthCard label="Welcome to Miindset">
        <VStack space={6}>
          <VStack space={6} px={2} mt={-4} mb={4} alignItems="center">
            {APP_INFO.map((text, index) => (
              <Text
                key={index.toString()}
                fontSize={14}
                fontWeight={500}
                textAlign="center"
              >
                {text}
              </Text>
            ))}
          </VStack>
          <VStack space={2}>
            <AuthButton
              onPress={() => handleNextPress()}
              _text={{ fontSize: 18 }}
              variant="solid"
            >
              Next
            </AuthButton>
          </VStack>
        </VStack>
      </AuthCard>
    </AuthContent>
  );
}
