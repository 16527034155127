import { HabitEntity } from "api/src/habit/entities/habit.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useHabits() {
  return useQuery<HabitEntity[]>(
    "habits",
    async () => {
      const response = await api.get<HabitEntity[]>("habit", {});

      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Couldn't fetch habits");
    },
    {
      keepPreviousData: true,
    }
  );
}
