import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function PrimaryColorBellIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="-2 -3 23 23" {...props}>
      <Svg width="17" height="17" viewBox="0 0 14 14" fill="none">
        <Path
          d="M12.7525 9.66594C12.7006 9.60344 12.6497 9.54094 12.5997 9.48063C11.9122 8.64906 11.4963 8.14719 11.4963 5.79313C11.4963 4.57438 11.2047 3.57437 10.63 2.82437C10.2063 2.27031 9.63345 1.85 8.87845 1.53938C8.86873 1.53397 8.86006 1.52688 8.85282 1.51844C8.58126 0.609062 7.83814 0 7.00001 0C6.16189 0 5.41907 0.609062 5.14751 1.5175C5.14026 1.52563 5.13171 1.5325 5.1222 1.53781C3.36032 2.26312 2.50407 3.65469 2.50407 5.79219C2.50407 8.14719 2.08876 8.64906 1.40064 9.47969C1.35064 9.54 1.2997 9.60125 1.24782 9.665C1.11382 9.8266 1.02892 10.0232 1.00317 10.2316C0.97742 10.4399 1.01189 10.6513 1.10251 10.8406C1.29532 11.2469 1.70626 11.4991 2.17532 11.4991H11.8281C12.295 11.4991 12.7031 11.2472 12.8966 10.8428C12.9876 10.6534 13.0224 10.4419 12.9969 10.2333C12.9713 10.0247 12.8865 9.8278 12.7525 9.66594ZM7.00001 14C7.45159 13.9996 7.89464 13.8771 8.28219 13.6453C8.66973 13.4135 8.98732 13.0811 9.20126 12.6834C9.21134 12.6644 9.21632 12.643 9.21571 12.6215C9.21511 12.5999 9.20894 12.5789 9.1978 12.5604C9.18667 12.542 9.17095 12.5267 9.15217 12.5161C9.13339 12.5055 9.1122 12.5 9.09064 12.5H4.91001C4.88843 12.4999 4.86719 12.5054 4.84837 12.516C4.82955 12.5266 4.81378 12.5418 4.80261 12.5603C4.79144 12.5788 4.78524 12.5998 4.78462 12.6214C4.784 12.643 4.78898 12.6644 4.79907 12.6834C5.01299 13.0811 5.33053 13.4134 5.71801 13.6452C6.1055 13.877 6.54849 13.9996 7.00001 14Z"
          fill="#DF3D8D"
        />
      </Svg>
    </Icon>
  );
}
