import { Box, FormControl, HStack, Pressable, Text } from "native-base";
import React, { useState } from "react";
import { FieldArrayWithId } from "react-hook-form";

import { HabitReminderType } from "../type/HabitReminderTypes";

import { AlertDialog } from "./elements/AlertDialog";
import { IconButton } from "./elements/IconButton";
import { TrashIcon } from "./icons/TrashIcon";

interface IHabitReminderItem {
  field: FieldArrayWithId<HabitReminderType, "reminders", "id">;
  index: number;
  remove: (index?: number | number[] | undefined) => void;
  errors: { [x: string]: { message: string } };
  name: string;
  setIsAddNew: (isAdd: boolean) => void;
  setIndex: (index: number) => void;
  setIsDatePickerVisible: (isDatePicker: boolean) => void;
}

export function HabitReminderItem({
  field,
  index,
  remove,
  errors,
  name,
  setIsAddNew,
  setIndex,
  setIsDatePickerVisible,
}: IHabitReminderItem) {
  const [longPressed, setLongPressed] = useState<number>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const errorMessage = errors[name]?.message;

  return (
    <>
      <FormControl mb="2" key={field.id} isInvalid={!!errorMessage} {...field}>
        <Pressable
          _pressed={{ opacity: 0.8 }}
          onPress={() => {
            setIsAddNew(false);
            setIndex(index);
            setIsDatePickerVisible(true);
          }}
          onLongPress={() => setLongPressed(index)}
        >
          {longPressed === index ? (
            <Box
              bg="red.400"
              h={50}
              alignItems="center"
              key={field.id}
              justifyContent="center"
              rounded="xl"
            >
              <IconButton
                onPress={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
              >
                <TrashIcon size="12" color="white" />
              </IconButton>
            </Box>
          ) : (
            <Box
              px={3}
              h={50}
              borderWidth={0}
              color="gray.800"
              key={field.id}
              borderRadius={10}
              pointerEvents="none"
              background="rgba(0,0,0,.05)"
              backgroundColor="white"
              alignItems="flex-start"
              justifyContent="center"
            >
              <HStack justifyContent="space-between" width="100%">
                <Text fontSize={14} fontWeight="400" color="gray.700">
                  Set time of your reminder
                </Text>
                <Text fontSize={14} fontWeight="400" color="gray.700">
                  {field.time}
                </Text>
              </HStack>
            </Box>
          )}
        </Pressable>
        {errorMessage ? (
          <FormControl.ErrorMessage justifyContent="center" alignItems="center">
            <Text
              mb={1}
              fontSize={12}
              color="red.500"
              fontWeight={600}
              textAlign="center"
            >
              {errorMessage}
            </Text>
          </FormControl.ErrorMessage>
        ) : null}
        <AlertDialog
          key={field.id}
          headerText="Delete Reminder"
          bodyText="This will remove your reminder. Are you sure you want to
            delete it?"
          buttonText="Delete"
          buttonAction={() => {
            remove(index);
            setLongPressed(undefined);
            setIsDeleteModalOpen(false);
          }}
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
            setLongPressed(undefined);
          }}
        />
      </FormControl>
    </>
  );
}
