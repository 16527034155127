/**
 * Random point near rect
 *
 * @remarks
 * Get a random point outside of a rectangle in a certain boundary
 * Used by DigestingScreen
 * @param x - Rectangle x
 * @param y - Rectangle y
 * @param width - Rectangle width
 * @param height - Rectangle height
 * @param minDist - Minimum distance from the rect
 * @param maxDist - Maximum distance from the rect
 *
 * @return [x, y] position
 */
export const randomPointNearRect = (
  x: number,
  y: number,
  w: number,
  h: number,
  minDist: number,
  maxDist: number
) => {
  const dist = Math.random() * (maxDist - minDist) + minDist || 0;
  x += dist;
  y += dist;
  w -= dist * 2;
  h -= dist * 2;
  if (Math.random() < w / (w + h)) {
    x = Math.random() * w + x;
    y = Math.random() < 0.5 ? y : y + h - 1;
  } else {
    y = Math.random() * h + y;
    x = Math.random() < 0.5 ? x : x + w - 1;
  }

  return [x || 0, y || 0];
};
