import { useNavigation } from "@react-navigation/native";
import { Heading, HStack, Text, useToken, VStack } from "native-base";
import React from "react";

import { RootStackParamList } from "../../navigation/RootStackParamList";
import { IconButton } from "../elements/IconButton";
import { PencilIcon } from "../icons/PencilIcon";
import { TrashIcon } from "../icons/TrashIcon";

interface IJournalEntryHeaderProps {
  journalId: string | undefined;
  title: string | undefined;
  setIsOpen: () => void;
  editEntrypoint: keyof RootStackParamList;
  date: string;
}

export function JournalEntryHeader({
  journalId,
  title,
  setIsOpen,
  editEntrypoint,
  date,
}: IJournalEntryHeaderProps) {
  const { navigate } = useNavigation();
  const [secondaryColor] = useToken("colors", ["secondary.400"]);

  return (
    <VStack mb="6">
      <HStack alignItems="center">
        <Heading fontSize={[20, 24]} fontWeight="600">
          {title}
        </Heading>
        <HStack space="2" alignItems="center" position="absolute" right={0}>
          <IconButton onPress={setIsOpen}>
            <TrashIcon size="4xl" color={secondaryColor} />
          </IconButton>
          <IconButton
            onPress={() => {
              navigate(editEntrypoint, { journalId });
            }}
          >
            <PencilIcon size="lg" />
          </IconButton>
        </HStack>
      </HStack>
      <Text color="secondary.500">{date}</Text>
    </VStack>
  );
}
