import { Channel } from "api/src/channels/enum/Channel";
import { Issue } from "api/src/wellbeing/enum/Issue";

import { RequiredImageType } from "../type/RequiredImageType";

export interface IIssue {
  label: string;
  channel: Channel;
  slug: Issue;
}

export const ISSUES: {
  label: string;
  slug: Issue;
  channel: Channel;
}[] = [
  {
    label: "Mental Health",
    slug: Issue.mentalHealth,
    channel: Channel.mentalHealth,
  },
  {
    label: "My Family",
    slug: Issue.family,
    channel: Channel.familyAndRelationships,
  },
  {
    label: "My body image",
    slug: Issue.bodyImage,
    channel: Channel.mentalHealth,
  },
  {
    label: "Ageing",
    slug: Issue.neurodiversity,
    channel: Channel.ageing,
  },
  {
    label: "Physical Health",
    slug: Issue.physicalHealth,
    channel: Channel.physicalHealth,
  },

  {
    label: "My Relationships",
    slug: Issue.relationships,
    channel: Channel.familyAndRelationships,
  },

  {
    label: "Issues with Management",
    channel: Channel.work,
    slug: Issue.issuesWithManagement,
  },

  {
    label: "Issues with Coworkers",
    channel: Channel.work,
    slug: Issue.issuesWithCoworkers,
  },

  {
    label: "Job security",
    channel: Channel.work,
    slug: Issue.jobSecurity,
  },

  {
    label: "My finances",
    channel: Channel.money,
    slug: Issue.finances,
  },

  {
    label: "Deadlines",
    channel: Channel.mentalHealth,
    slug: Issue.deadlines,
  },

  {
    label: "LGBTQ+ Issues",
    channel: Channel.lgbtq,
    slug: Issue.lgbtq,
  },

  {
    label: "Long-term Illness",
    channel: Channel.physicalHealth,
    slug: Issue.longTermIllness,
  },
];

export const ISSUE_IMAGES: Record<Issue, RequiredImageType> = {
  [Issue.lgbtq]: require("../assets/images/issues/lgbtq.jpg"),
  [Issue.family]: require("../assets/images/issues/family.jpg"),
  [Issue.neurodiversity]: require("../assets/images/issues/ageing.jpg"),
  [Issue.diversity]: require("../assets/images/issues/ageing.jpg"),
  [Issue.finances]: require("../assets/images/issues/finances.jpg"),
  [Issue.deadlines]: require("../assets/images/issues/deadlines.jpg"),
  [Issue.bodyImage]: require("../assets/images/issues/body-image.jpg"),
  [Issue.jobSecurity]: require("../assets/images/issues/job-security.jpg"),
  [Issue.mentalHealth]: require("../assets/images/issues/mental-health.jpg"),
  [Issue.relationships]: require("../assets/images/issues/relationships.jpg"),
  [Issue.physicalHealth]: require("../assets/images/issues/physical-health.jpg"),
  [Issue.longTermIllness]: require("../assets/images/issues/long-term-illness.jpg"),
  [Issue.issuesWithCoworkers]: require("../assets/images/issues/issues-with-coworkers.jpg"),
  [Issue.issuesWithManagement]: require("../assets/images/issues/issues-with-management.jpg"),
};
