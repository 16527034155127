import { VStack, Heading, Text } from "native-base";
import React from "react";

interface IReviewListScreenHeaderProps {
  title: string;
  subtitle?: string;
}

export function ReviewListScreenHeader({
  title,
  subtitle,
}: IReviewListScreenHeaderProps) {
  return (
    <VStack justifyContent="center" space={2} alignItems="center">
      <Heading
        fontSize={24}
        textAlign="center"
        color="primary.400"
        fontWeight="600"
      >
        {title}
      </Heading>

      {subtitle ? (
        <Text textAlign="center" fontSize={16}>
          {subtitle}
        </Text>
      ) : null}
    </VStack>
  );
}
