import {
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  useFonts,
} from "@expo-google-fonts/poppins";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import * as ExpoSplashScreen from "expo-splash-screen";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Animated, View, StyleSheet, Easing } from "react-native";

import { isWeb } from "../../util/platform";

interface IAnimatedSplashScreenProps {
  children: ReactNode;
}

export function SplashScreen({ children }: IAnimatedSplashScreenProps) {
  const zoomAnimation = useRef(new Animated.Value(0)).current;
  const [colorMode, setColorMode] = useState<string | null>();

  const [isAppReady, setAppReady] = useState<boolean>(false);
  const [isSplashAnimationComplete, setAnimationComplete] =
    useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      const val = await AsyncStorage.getItem("@color-mode");
      setColorMode(val);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (isAppReady) {
      Animated.timing(zoomAnimation, {
        toValue: 1,
        duration: 2000,
        easing: Easing.exp,
        useNativeDriver: true,
      }).start(() => {
        setTimeout(() => setAnimationComplete(true), 500);
      });
    }
  }, [isAppReady]);

  const onImageLoaded = useCallback(async () => {
    try {
      await ExpoSplashScreen.hideAsync();
      await Promise.all([]);
    } catch (e) {
      // handle errors
    } finally {
      setAppReady(true);
    }
  }, []);

  const zoom = zoomAnimation.interpolate({
    inputRange: [0, 0.1, 1],
    outputRange: [1, 0.85, 25],
  });

  const [fontsLoaded] = useFonts({
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
  });

  const getBackground = () =>
    colorMode === "light"
      ? Constants?.manifest?.splash?.backgroundColor
      : Constants?.manifest?.splash?.dark.backgroundColor;

  return (
    <View
      style={{
        flex: 1,
        overflow: isWeb()
          ? isSplashAnimationComplete
            ? undefined
            : "hidden"
          : undefined,
      }}
    >
      {isAppReady && fontsLoaded && children}
      {!isSplashAnimationComplete && (
        <Animated.View
          pointerEvents="none"
          style={[
            StyleSheet.absoluteFill,
            {
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: getBackground(),
            },
          ]}
        >
          <Animated.Image
            style={{
              width: 60,
              height: 60,
              resizeMode: Constants?.manifest?.splash?.resizeMode || "contain",
              zIndex: 1000,
            }}
            source={require("../../assets/white_logo.png")}
            onLoadEnd={onImageLoaded}
            fadeDuration={0}
          />
          <Animated.View
            style={{
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#DF3D8D",
              width: 100,
              height: 100,
              borderRadius: 100,
              transform: [
                {
                  scale: zoom,
                },
              ],
            }}
          />
        </Animated.View>
      )}
    </View>
  );
}
