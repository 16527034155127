import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

interface IRemoveEmployee {
  employeeId: string | undefined;
}

export function useRemoveEmployee(id?: string) {
  const queryClient = useQueryClient();

  return useMutation<null, null, IRemoveEmployee>(
    ["remove-employee", id],
    async ({ employeeId }) => {
      const response = await api.delete(
        `organisations/remove-employee/${id || employeeId}`
      );

      if (response?.status === 200) {
        return response.data;
      }
      throw response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["organisation", "all-users"]);
      },
    }
  );
}
