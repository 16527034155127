import { Mood } from "api/src/emotion/enum/mood";
import { ReactElement } from "react";

import { EcstaticMooIcon } from "../components/mood/icons/EcstaticMooIcon";
import { HappyMoodIcon } from "../components/mood/icons/HappyMoodIcon";
import { NeutralMoodIcon } from "../components/mood/icons/NeutralMoodIcon";
import { OkayMoodIcon } from "../components/mood/icons/OkayMoodIcon";
import { SadMoodIcon } from "../components/mood/icons/SadMoodIcon";
import { VerySadMoodIcon } from "../components/mood/icons/VerySadMoodIcon";
import { IIconProps } from "../interfaces/IIconprops";

export interface IMoodItem {
  label: string;
  value: Mood;
  icon: (props: IIconProps) => ReactElement;
}

export const MOODS: IMoodItem[] = [
  {
    label: "Neutral",
    icon: NeutralMoodIcon,
    value: Mood.neutral,
  },
  {
    label: "Very Sad",
    icon: VerySadMoodIcon,
    value: Mood.verySad,
  },
  { label: "Sad", icon: SadMoodIcon, value: Mood.sad },
  { label: "Okay", icon: OkayMoodIcon, value: Mood.okay },
  { label: "Happy", icon: HappyMoodIcon, value: Mood.happy },
  {
    label: "Ecstatic",
    icon: EcstaticMooIcon,
    value: Mood.ecstatic,
  },
];
