import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function HeadphonesIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 22 20" {...props}>
      <Svg width={22} height={20} fill="none" stroke={color}>
        <Path
          d="M20.96 11.042h-3.271c-.434 0-.85.173-1.156.482a1.651 1.651 0 0 0-.48 1.164v4.114c0 .436.173.855.48 1.163a1.63 1.63 0 0 0 1.156.482h1.635c.434 0 .85-.173 1.157-.482.306-.308.478-.727.478-1.163v-5.76Zm0 0a9.927 9.927 0 0 0-.755-3.796 9.877 9.877 0 0 0-2.146-3.212 9.807 9.807 0 0 0-3.208-2.136 9.757 9.757 0 0 0-3.778-.73 9.758 9.758 0 0 0-3.777.73 9.807 9.807 0 0 0-3.208 2.136A9.878 9.878 0 0 0 1.94 7.246a9.928 9.928 0 0 0-.754 3.796v5.76c0 .436.173.855.48 1.163a1.63 1.63 0 0 0 1.156.482h1.635c.434 0 .85-.173 1.156-.482.307-.308.48-.727.48-1.163v-4.114c0-.437-.173-.855-.48-1.164a1.63 1.63 0 0 0-1.156-.482h-3.27"
          strokeWidth={1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
