import { useStyledSystemPropsResolver, IBoxProps } from "native-base";
import React, { forwardRef } from "react";
import { View } from "react-native";

export const Box = forwardRef<View, React.PropsWithChildren<IBoxProps>>(
  ({ children, ...props }: React.PropsWithChildren<IBoxProps>, ref) => {
    const [style, otherProps] = useStyledSystemPropsResolver(props);

    return (
      <View {...otherProps} style={[style]} ref={ref}>
        {children}
      </View>
    );
  }
);
