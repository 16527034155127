import { isEmpty } from "lodash";
import { VStack, HStack, Text, Heading } from "native-base";
import React, { useState } from "react";

import { useOrganisationLocationResilience } from "../../../../hooks/useOrganisationLocationResilience";
import { useOrganisationLocationsOrDepartments } from "../../../../hooks/useOrganisationLocationsOrDepartments";

import { LocationFilter } from "./locationFilter";

export function LocationResilienceScore() {
  const [firstValue, setFirstValue] = useState<string>("");
  const [secondValue, setSecondValue] = useState<string>("");
  const { data: filterData } = useOrganisationLocationsOrDepartments();

  const { data: firstFilterResilience, refetch: refetchFirstFilterResilience } =
    useOrganisationLocationResilience({
      location: filterData?.location,
      keyword: firstValue,
    });

  const {
    data: secondFilterResilience,
    refetch: refetchSecondFilterResilience,
  } = useOrganisationLocationResilience({
    location: filterData?.location,
    keyword: secondValue,
  });

  const firstOnChange = (selection: string) => {
    setFirstValue(selection);
    refetchFirstFilterResilience();
  };

  const secondOnChange = (selection: string) => {
    setSecondValue(selection);
    refetchSecondFilterResilience();
  };

  if (isEmpty(filterData?.data)) {
    return <></>;
  }

  return (
    <VStack alignItems="center" space={3}>
      <Heading fontSize={18} fontWeight={600} textAlign="center">
        {filterData?.location ? "Locations" : "Departments"} Resilience Score
      </Heading>

      <HStack space={2}>
        <LocationFilter
          filterData={filterData?.data}
          resilience={firstFilterResilience}
          value={firstValue}
          onChange={firstOnChange}
        />
        <Text mt="4" fontSize="xs">
          VS
        </Text>
        <LocationFilter
          filterData={filterData?.data}
          resilience={secondFilterResilience}
          value={secondValue}
          onChange={secondOnChange}
        />
      </HStack>
    </VStack>
  );
}
