import { SubmitFeelingsDto } from "api/src/emotion/dto/submit-feelings.dto";
import { useMutation } from "react-query";

import { EVENT_RECORD_FEELINGS } from "../constants/analytics";
import { eventHit } from "../util/analytics";
import { api } from "../util/api";

export function useFeelingsSubmit() {
  return useMutation<null, null, SubmitFeelingsDto>(
    "feelings/submit",
    async (data: SubmitFeelingsDto) => {
      const response = await api.post("emotion/feelings", data);

      if (response.status !== 201) {
        throw response.data;
      }

      eventHit("submit", EVENT_RECORD_FEELINGS);

      return null;
    }
  );
}
