import { useNavigation, CommonActions } from "@react-navigation/core";
import { Pressable, View, VStack, Text, Spinner } from "native-base";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { AuthButton as Button } from "../components/auth/AuthButton";
import { AuthCard } from "../components/auth/AuthCard";
import { AuthInput } from "../components/auth/AuthInput";
import { AlertDialog } from "../components/elements/AlertDialog";
import { ScrollView } from "../components/elements/ScrollView";
import { useDeleteAccount } from "../hooks/useDeleteAccount";
import { useFormErrors } from "../hooks/useFormErrors";
import { useLogout } from "../hooks/useLogout";
import { ToastStatus, useToast } from "../hooks/useToast";
import { useVerifyEmailAgain } from "../hooks/useVerifyEmailAgain";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";
import { isWeb } from "../util/platform";

interface IDeleteUserRequestDTO {
  email: string;
  code: string;
}

export function DeleteAccountScreen() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { navigate, dispatch } = useNavigation();
  const { mutate: join, isLoading } = useDeleteAccount();
  const [isVerificationCodeSent, setIsVerificationCodeSent] =
    useState<boolean>(false);
  const { mutate: verifyEmail, isLoading: isVerifyEmailLoading } =
    useVerifyEmailAgain(true);
  const {
    reset,
    control,
    formState: { errors },
    setError,
    getValues,
  } = useForm();
  const { setErrors } = useFormErrors(setError);
  const toast = useToast();
  const { mutate: logout } = useLogout();

  function handleLogout() {
    logout(undefined, {
      onSuccess: () => {
        dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: "Auth" }],
          })
        );
      },
    });
  }

  function onSubmit(data: IDeleteUserRequestDTO) {
    onClose();
    if (!data.code) {
      setErrors({ code: "Please enter the code to verify your email." });
    }
    join(
      { ...data },
      {
        onSuccess: (data) => {
          if (data.status === "ok") {
            toast({
              title: data.message || "Account successfully deleted",
              status: ToastStatus.success,
            });
            handleLogout();
          } else {
            toast({
              title: data.message || "Failed to delete your account",
              status: ToastStatus.error,
            });
          }
        },
        onError: (data) => {
          if (data?.errors?.subscription) {
            setErrors({ code: data.errors.subscription });
            return toast({
              title: data.errors.subscription,
              status: ToastStatus.error,
            });
          }
          setErrors(
            data.errors
              ? { ...data.errors, email: "Please enter a valid email" }
              : {}
          );
          toast({
            title: data.message || "Could not delete your account",
            status: ToastStatus.error,
          });
        },
      }
    );
  }

  const sendVerificationCode = () => {
    setErrors({ email: undefined });
    const values = getValues();
    verifyEmail(
      { email: values?.email },
      {
        onSuccess: () => {
          setIsVerificationCodeSent(true);
          toast({
            title: "Verification Code Sent",
            status: ToastStatus.success,
          });
        },
        onError: (data) => {
          setErrors(
            data.errors
              ? { ...data.errors, email: "Please enter a valid email" }
              : {}
          );
          toast({
            title: data.message || "Could not send verification code",
            status: ToastStatus.error,
          });
        },
      }
    );
  };

  function onClose(): void {
    setIsOpen(false);
  }

  return (
    <>
      <Header hasBack={!isWeb()} />
      <Body>
        <ScrollView
          pt="10"
          contentContainerStyle={{ justifyContent: "center" }}
          _web={{ _dark: { bg: "dark.500" } }}
        >
          <VStack
            pt="10"
            pb="16"
            _web={{
              background: "white",
              p: "10",
              mt: "10",
              _web: { _dark: { bg: "dark.100" } },
            }}
          >
            <AuthCard
              label="Delete Account"
              subheading="Please enter your email below to confirm the deletion of your account."
              message="This action cannot be undone! It may take up to a week for all of your data to be deleted from our system."
            >
              <VStack space={5} _web={{ mt: 5 }}>
                <AuthInput
                  name="email"
                  placeholder="Email"
                  control={control}
                  errors={errors}
                  label="email"
                  keyboardType="email-address"
                />
                {isVerificationCodeSent ? (
                  <>
                    <AuthInput
                      name="code"
                      autoFocus={true}
                      placeholder="Verification Code"
                      control={control}
                      errors={errors}
                      keyboardType="default"
                      type="password"
                    />
                    <Pressable
                      onPress={sendVerificationCode}
                      pb={2}
                      _pressed={{ opacity: 0.8 }}
                    >
                      {isVerifyEmailLoading ? (
                        <Spinner />
                      ) : (
                        <Text
                          alignSelf="center"
                          color="primary.400"
                          fontWeight={400}
                        >
                          Resend
                        </Text>
                      )}
                    </Pressable>
                  </>
                ) : null}

                <View
                  justifyContent="center"
                  alignItems="center"
                  _web={{ flexDirection: "row" }}
                >
                  <Button
                    py={4}
                    mb={2}
                    variant="outline"
                    onPress={() => {
                      reset();
                      navigate(isWeb() ? "Home" : "App");
                    }}
                    _web={{ maxW: 200, mx: 2 }}
                  >
                    Cancel
                  </Button>
                  {isVerificationCodeSent ? (
                    <Button
                      bg="red.400"
                      mb={2}
                      py={4}
                      variant="solid"
                      isLoading={isLoading}
                      onPress={() => setIsOpen(!isOpen)}
                      _web={{ maxW: 200, mx: 2 }}
                    >
                      Confirm Delete
                    </Button>
                  ) : (
                    <Button
                      bg="red.400"
                      mb={2}
                      py={4}
                      variant="solid"
                      isLoading={isVerifyEmailLoading}
                      onPress={sendVerificationCode}
                      _web={{ maxW: 200, mx: 2 }}
                    >
                      Verify Email
                    </Button>
                  )}
                </View>
              </VStack>
              <AlertDialog
                headerText="Delete Account"
                bodyText="This will remove your account permanently. Are you sure you?"
                buttonColor="red.400"
                buttonText="Confirm"
                buttonAction={() => {
                  const values = getValues();
                  onSubmit({ email: values.email, code: values.code });
                }}
                isOpen={isOpen}
                onClose={onClose}
              />
            </AuthCard>
          </VStack>
        </ScrollView>
      </Body>
    </>
  );
}
