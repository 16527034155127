import { Icon } from "native-base";
import React from "react";
import { Path, Rect, Svg } from "react-native-svg";

import { IIconProps } from "../../../interfaces/IIconprops";
import { useMoodIconColors } from "../../../util/theme-helper";

export function OkayMoodIcon({ ...props }: IIconProps) {
  const { faceFillColor, faceStrokeColor, mouthFillColor, eyesFillColor } =
    useMoodIconColors();

  return (
    <Icon viewBox="0 0 261 260" {...props}>
      <Svg width={261} height={260} fill="none">
        <Path
          d="M130.649 258.2c70.848 0 128.28-57.465 128.28-128.35 0-70.885-57.432-128.35-128.28-128.35S2.369 58.965 2.369 129.85c0 70.885 57.432 128.35 128.28 128.35Z"
          fill={faceFillColor}
          stroke={faceStrokeColor}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M102.714 106.2c0 16.624-8.659 30.1-19.34 30.1-10.68 0-19.339-13.476-19.339-30.1 0-16.624 8.659-30.1 19.34-30.1 10.68 0 19.339 13.476 19.339 30.1ZM197.261 106.2c0 16.624-8.658 30.1-19.339 30.1s-19.339-13.476-19.339-30.1c0-16.624 8.658-30.1 19.339-30.1s19.339 13.476 19.339 30.1Z"
          fill={eyesFillColor}
        />
        <Rect
          x={68.832}
          y={179.8}
          width={123.631}
          height={17.9}
          rx={8.95}
          fill={mouthFillColor}
        />
      </Svg>
    </Icon>
  );
}
