import { VStack, Center, Text } from "native-base";
import React, { useState } from "react";
import { View as AnimatedView } from "react-native-animatable";
import { TouchableOpacity } from "react-native-gesture-handler";

import { isWeb } from "../../util/platform";
import { ArrowIcon } from "../icons/ArrowIcon";

interface IWelcomeWellbeing {
  onComplete: () => void;
}

export function WelcomeOnboardingSecond({ onComplete }: IWelcomeWellbeing) {
  const [animationState, setAnimationState] = useState<"In" | "OutDown">("In");
  return (
    <VStack space={8}>
      <VStack space={2} mt={10}>
        <AnimatedView
          style={{ width: "100%" }}
          animation={`fade${animationState}`}
          delay={0}
          duration={750}
        >
          <Text
            textAlign="center"
            fontSize={!isWeb() ? 16 : 24}
            fontWeight="600"
          >
            Miindset will also customise your experience the more you use it.
          </Text>
        </AnimatedView>
        <AnimatedView
          style={{ width: "100%" }}
          animation={`fade${animationState}`}
          delay={100 + 100}
          duration={750}
        >
          <Text
            fontWeight={500}
            mb={2}
            fontSize={!isWeb() ? undefined : 18}
            textAlign="center"
          >
            Just reading an article, listening to a podcast or watching a video
            will teach Miindset what you like and how you like to consume your
            content.
          </Text>
        </AnimatedView>
      </VStack>
      <AnimatedView
        style={{ width: "100%" }}
        animation={`fade${animationState === "In" ? "InUp" : "OutDown"}`}
        delay={200 + 400}
        onAnimationEnd={() => {
          if (animationState === "OutDown") {
            onComplete();
          }
        }}
        duration={1000}
      >
        <Center mt={6}>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => setAnimationState("OutDown")}
          >
            <ArrowIcon size="4xl" />
          </TouchableOpacity>
        </Center>
      </AnimatedView>
    </VStack>
  );
}
