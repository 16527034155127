import { isString } from "lodash";
import React, { useState } from "react";
import { useQueryClient } from "react-query";

import { UploadFileTypes } from "../../../enum/upload-file-types";
import { useUpdateOrganisation } from "../../../hooks/useUpdateOrganisation";
import { convertBytesToMb } from "../../../util/convert-bytes-to-mb";
import { UploadFile } from "../UploadFile";

export function UploadBadge() {
  const queryClient = useQueryClient();
  const [isBadgeUpdated, setBadgeUpdated] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { mutate: updateOrganisation, isLoading: isUpdating } =
    useUpdateOrganisation();
  const onFileUpload = (acceptedFiles: File[]) => {
    try {
      if (convertBytesToMb(acceptedFiles[0].size) > 2) {
        setError("Image size must be smaller than 2 MB!");
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        const base64String = reader.result;
        if (base64String && isString(base64String)) {
          updateOrganisation(
            { file: base64String },
            {
              onSuccess: () => {
                setBadgeUpdated(true);
                queryClient.invalidateQueries(["account"]);
              },
              onError: ({ message }) => {
                setError(message || "Failed to update the badge");
              },
            }
          );
        } else {
          setError("Image failed to load.");
        }
      };
    } catch (err) {
      setError("Something went wrong.");
    }
  };

  return (
    <UploadFile
      onFileUpload={onFileUpload}
      error={error}
      setError={setError}
      fileType={UploadFileTypes.image}
      isBadgeUpdated={isBadgeUpdated}
      isLoading={isUpdating}
    />
  );
}
