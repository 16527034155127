import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { JournalTitleEntry } from "api/src/journal/constants/journal-title-entries";
import { GratitudeQuestion } from "api/src/journal/enum/GratitudeQuestion";
import { JournalEntryType } from "api/src/journal/enum/JournalEntryType";
import { Text, HStack, VStack, Heading } from "native-base";
import React, { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { Button } from "../../components/elements/Button";
import { SaveFileIcon } from "../../components/icons/Icons";
import { JournalCurrentDay } from "../../components/journal/JournalCurrentDay";
import { JournalEntryContainer } from "../../components/journal/JournalEntryContainer";
import { JournalEntryInput } from "../../components/journal/JournalEntryInput";
import { JournalEntryInputLabel } from "../../components/journal/JournalEntryInputLabel";
import { Loading } from "../../components/Loading";
import { useJournalEntry } from "../../hooks/useJournalEntry";
import { useJournalSubmit } from "../../hooks/useJournalSubmit";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { RootStackParamList } from "../../navigation/RootStackParamList";

export function AddGratitudeEntryScreen() {
  const toast = useToast();
  const { navigate } = useNavigation();

  const {
    params: { journalId },
  } = useRoute<RouteProp<RootStackParamList, "AddGratitudeEntry">>();

  const { mutate: submitGratitudeEntry, isLoading: isJournalSubmitLoading } =
    useJournalSubmit(journalId);
  const { data: journal, isLoading } = useJournalEntry(journalId);

  const defaultValues: FieldValues = {
    bestPartOfTheDay: journal?.gratitudeData.bestPartOfTheDay,
    thing1: journal?.gratitudeData.gratefulFor[0],
    thing2: journal?.gratitudeData.gratefulFor[1],
    thing3: journal?.gratitudeData.gratefulFor[2],
    thing4: journal?.gratitudeData.gratefulFor[3],
    thing5: journal?.gratitudeData.gratefulFor[4],
    thing6: journal?.gratitudeData.gratefulFor[5],
    thing7: journal?.gratitudeData.gratefulFor[6],
    thing8: journal?.gratitudeData.gratefulFor[7],
    thing9: journal?.gratitudeData.gratefulFor[8],
    thing10: journal?.gratitudeData.gratefulFor[9],
    learn1: journal?.gratitudeData.whatILearned[0],
    learn2: journal?.gratitudeData.whatILearned[1],
    learn3: journal?.gratitudeData.whatILearned[2],
    person1: journal?.gratitudeData.peopleThatMakeYouHappier[0],
    person2: journal?.gratitudeData.peopleThatMakeYouHappier[1],
    person3: journal?.gratitudeData.peopleThatMakeYouHappier[2],
    person4: journal?.gratitudeData.peopleThatMakeYouHappier[3],
    person5: journal?.gratitudeData.peopleThatMakeYouHappier[4],
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: journalId ? defaultValues : undefined,
  });

  function onSubmit(data: Record<string, string>) {
    const {
      bestPartOfTheDay,
      thing1,
      thing2,
      thing3,
      thing4,
      thing5,
      thing6,
      thing7,
      thing8,
      thing9,
      thing10,
      learn1,
      learn2,
      learn3,
      person1,
      person2,
      person3,
      person4,
      person5,
    } = data;

    return submitGratitudeEntry(
      {
        type: JournalEntryType.gratitude,
        title: JournalTitleEntry.gratitude,
        gratitude: {
          bestPartOfTheDay,
          gratefulFor: [
            thing1,
            thing2,
            thing3,
            thing4,
            thing5,
            thing6,
            thing7,
            thing8,
            thing9,
            thing10,
          ],
          peopleThatMakeYouHappier: [
            person1,
            person2,
            person3,
            person4,
            person5,
          ],
          whatILearned: [learn1, learn2, learn3],
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${
              !journalId
                ? "New Gratitude Journal created"
                : "Gratitude Journal edited"
            }`,
            status: ToastStatus.success,
          });
          navigate("MyJournal");
        },
        onError: () => {
          toast({
            title: `Couldn't ${
              !journalId ? "created" : "edit"
            } Gratitude Journal`,
            status: ToastStatus.warning,
          });
        },
      }
    );
  }

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [journal]);

  if (isLoading) {
    return <Loading showHeader={true} />;
  }

  return (
    <JournalEntryContainer>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading fontSize={18} fontWeight="500">
          {JournalTitleEntry.gratitude}
        </Heading>
        <JournalCurrentDay />
      </HStack>
      <VStack space={2} mt="2">
        <VStack space="2">
          <JournalEntryInputLabel title="10 things I'm grateful for:" />
          {[...Array(10)].map((_, index) => (
            <JournalEntryInput
              key={index}
              control={control}
              name={`thing${index + 1}`}
              placeholder={`${index + 1}.`}
            />
          ))}
        </VStack>
        <VStack>
          <JournalEntryInputLabel
            title="3 things I learned from my challenges:"
            subtitle="List three obstacles and what you’re learning from them."
          />
        </VStack>
        <VStack space="2">
          {[...Array(3)].map((_, index) => (
            <JournalEntryInput
              key={index}
              control={control}
              name={`learn${index + 1}`}
              placeholder={`${index + 1}.`}
            />
          ))}
        </VStack>
        <VStack>
          <JournalEntryInputLabel
            title="5 people who made my life happier:"
            subtitle="These could be friends, family or strangers!"
          />
        </VStack>
        <VStack space="2">
          {[...Array(5)].map((_, index) => (
            <JournalEntryInput
              key={index}
              control={control}
              name={`person${index + 1}`}
              placeholder={`${index + 1}.`}
            />
          ))}
        </VStack>
        <VStack>
          <JournalEntryInputLabel
            title="Best part of my day:"
            subtitle="Choose one moment of your day that made you happy and focus on it
            for 5 minutes before bed."
          />
          <VStack space="2">
            <JournalEntryInput
              isTextarea={true}
              control={control}
              name={GratitudeQuestion.bestPartOfTheDay}
              placeholder="The best part of my day has been..."
              style={{ marginTop: 6 }}
            />
          </VStack>
        </VStack>
      </VStack>
      <HStack pt="5" justifyContent="center" space="10">
        <Button
          onPress={handleSubmit(onSubmit)}
          isLoading={isJournalSubmitLoading}
        >
          <HStack alignItems="center" space="1">
            <SaveFileIcon color="white" size={["lg", "xl"]} />
            <Text color="white" fontSize={[15, 18]} fontWeight="300">
              {!journalId ? "Save" : "Edit"}
            </Text>
          </HStack>
        </Button>
      </HStack>
    </JournalEntryContainer>
  );
}
