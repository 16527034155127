import { SubmitOrganisationContentDto } from "api/src/content/dto/submit-organisation-content.dto";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

export function useAddOrganisationPost() {
  const queryClient = useQueryClient();
  return useMutation<
    IApiResponse<ContentEntity>,
    IApiResponse<ContentEntity>,
    SubmitOrganisationContentDto
  >(
    "organisation-content-add",
    async ({ ...data }: SubmitOrganisationContentDto) => {
      const response = await api.post<IApiResponse<ContentEntity>>(
        "content/add-organisation",
        data
      );

      if (response.status !== 201) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("content");
        queryClient.invalidateQueries("unread-notifications-quantity");
      },
    }
  );
}
