import {
  View,
  Text,
  Image,
  Heading,
  HStack,
  VStack,
  Link,
  Spacer,
} from "native-base";
import React from "react";

import { isWeb } from "../../util/platform";
import { NetworkIcon } from "../icons/NetworkIcon";
import { PerimenopauseIcon } from "../icons/PerimenopauseIcon";

export function PremenopauseSupport() {
  return (
    <View mb={10} mt={50} alignItems="center">
      {!isWeb() ? (
        <Image
          width={62}
          height={70}
          source={require("../../assets/images/badges/menopause.png")}
          alt="The Concept Of Sacred Spaces"
          borderRadius={10}
        />
      ) : null}
      <Heading mt={5} mb={5} fontSize={28} fontWeight={600} textAlign="center">
        Premenopause, Menopause And Perimenopause
      </Heading>
      {isWeb() ? <PerimenopauseIcon /> : null}
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Menopause and Me is dedicated to supporting women throughout the
        menopause journey. The site offers tailored information about all
        <Spacer size={1} />
        <Text _dark={{ color: "secondary.200" }} underline={true}>
          stages of menopause
        </Text>
        , understanding the
        <Spacer size={1} />
        <Text _dark={{ color: "secondary.200" }} underline={true}>
          changes to the body
        </Text>
        and
        <Spacer size={1} />
        <Text _dark={{ color: "secondary.200" }} underline={true}>
          treatment options.
        </Text>
        Menopause and Me provide the tools and support you need to help you
        through your menopause journey. Menopause and Me provide the tools and
        support you need to help you through your menopause journey. If you feel
        you are struggling with any aspect of your menopause journey, ask your
        healthcare professional for advice and help. You are not alone. Whatever
        the symptoms, <Text underline={true}>help is available for you.</Text>
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 500 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        underline={true}
        _dark={{ color: "secondary.200" }}
      >
        Join our community on Facebook: facebook.com/menopauseandme1
        https://www.menopauseandme.co.uk/en-gb
      </Text>

      <Link
        mt={10}
        href="https://www.menopauseandme.co.uk/en-gb"
        isExternal={true}
      >
        <HStack space={2}>
          <NetworkIcon />
          <VStack>
            <Text _dark={{ color: "secondary.200" }} underline={true}>
              www.menopauseandme.co.uk
            </Text>
          </VStack>
        </HStack>
      </Link>
    </View>
  );
}
