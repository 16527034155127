import { useNavigation } from "@react-navigation/core";
import { FlagUserDto } from "api/src/signposting/dto/flag-user.dto";
import { WellbeingQuestionDto } from "api/src/wellbeing/dto/wellbeing-question.dto";
import { WellbeingSubmitResponseDto } from "api/src/wellbeing/dto/wellbeing-submit-response.dto";
import { Issue } from "api/src/wellbeing/enum/Issue";
import { WellbeingQuestionAnswer } from "api/src/wellbeing/enum/WellbeingQuestionAnswer";
import {
  Box,
  Center,
  useBreakpointValue,
  useColorModeValue,
  useToken,
  View,
  VStack,
} from "native-base";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Animated } from "react-native";
import { View as AnimatedView } from "react-native-animatable";

import { Container } from "../components/Container";
import { AlertDialog } from "../components/elements/AlertDialog";
import { LoadingAnimation } from "../components/elements/LoadingAnimation";
import { FlowerLotusIcon } from "../components/icons/FlowerLotusIcon";
import { OnboardingSteps } from "../components/OnboardingSteps";
import { ChannelSelectionPage } from "../components/wellbeing-checker/ChannelSelectionPage";
import { QuestionsPage } from "../components/wellbeing-checker/QuestionsPage";
import { WelcomeOnboardingFirst } from "../components/wellbeing-checker/WelcomeOnboardingFirst";
import { WelcomeOnboardingSecond } from "../components/wellbeing-checker/WelcomeOnboardingSecond";
import { WelcomeOnboardingThird } from "../components/wellbeing-checker/WelcomeOnboardingThird";
import { WelcomeWellbeing } from "../components/wellbeing-checker/WelcomeWellbeingPage";
import { OnboardingContext } from "../context/OnboardingContext";
import { OnboardingStep } from "../enum/signup-step";
import { ToastStatus, useToast } from "../hooks/useToast";
import { useWellbeingQuestions } from "../hooks/useWellbeingQuestions";
import { useWellbeingSubmit } from "../hooks/useWellbeingSubmit";

const inAnimation = "fadeInDown";

enum WellbeingCheckerScreens {
  welcome = "welcome",
  questions = "questions",
  channelSelection = "channelSelection",
  analyzing = "analyzing",
  onboardingFirst = "onboardingFirst",
  onboardingSecond = "onboardingSecond",
  onboardingThird = "onboardingThird",
}

export function WellbeingCheckerScreen() {
  const { navigate } = useNavigation();
  const toast = useToast();
  const { mutate: submitWellbeing } = useWellbeingSubmit();
  const colourAnimation = useRef(new Animated.Value(0)).current;
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [, setButtonTopOffset] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const { data: wellbeingQuestions = [] } = useWellbeingQuestions();
  const [, setIsTransitioning] = useState<boolean>(false);
  const { currentStep, setCurrentStep } = useContext(OnboardingContext);
  const [flaggedUser, setFlaggedUser] = useState<FlagUserDto>();
  const [isSkipAlert, setIsSkipAlert] = useState<boolean>(false);
  const [questionAnswers, setQuestionAnswers] = useState<
    WellbeingQuestionDto[]
  >([]);

  useEffect(() => {
    handleAnimation();
  }, []);

  function handleAnimation() {
    Animated.timing(colourAnimation, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
    }).start(() => {
      setIsTransitioning(false);
    });
  }

  const [primary400, gray100, dark500] = useToken("colors", [
    "primary.400",
    "secondary.200",
    "dark.500",
  ]);

  const backgroundColor = useColorModeValue(gray100, dark500);

  const backgroundColourValue = colourAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [primary400, backgroundColor],
  });

  const backgroundColorBreakpoint = useBreakpointValue({
    base: "white",
    lg: backgroundColourValue,
  });

  const handleSkip = useCallback(
    (isCancel = false) => {
      if (isCancel) {
        navigate("Home");
      } else {
        return setIsSkipAlert(true);
      }
    },
    [currentStep]
  );

  const handleBack = useCallback(() => {
    setCurrentQuestion((prevQuestion) => prevQuestion - 1);
  }, [currentQuestion]);

  const isFirstTime = currentStep === OnboardingStep.wellbeing;

  const [currentScreen, setCurrentScreen] = useState<WellbeingCheckerScreens>(
    isFirstTime
      ? WellbeingCheckerScreens.onboardingFirst
      : WellbeingCheckerScreens.welcome
  );

  return (
    <>
      <Animated.View
        style={{
          backgroundColor: backgroundColorBreakpoint,
        }}
      >
        <Container minH="100vh" justifyContent="center">
          <Box
            alignSelf="center"
            p={10}
            width={{ base: "unset", lg: "70%" }}
            bg="white"
            _dark={{ bg: "dark.100" }}
            borderRadius={5}
          >
            <AnimatedView animation="fadeIn" delay={750} duration={750}>
              <OnboardingSteps />
            </AnimatedView>
            <VStack px={{ base: 0, lg: 4 }} pt={12} space={8}>
              {!isComplete ? (
                <AnimatedView animation={inAnimation} delay={25} duration={750}>
                  <View
                    flex={1}
                    onLayout={(event) => {
                      const { layout } = event.nativeEvent;
                      setButtonTopOffset(layout.height / 2 + layout.y);
                    }}
                  >
                    <Center>
                      <FlowerLotusIcon size={24} />
                    </Center>
                  </View>
                </AnimatedView>
              ) : null}
              <View
                pb={10}
                flex="1"
                justifyContent="center"
                px={{ base: 0, lg: 10 }}
              >
                {currentScreen === WellbeingCheckerScreens.onboardingFirst ? (
                  <WelcomeOnboardingFirst
                    onComplete={() =>
                      setCurrentScreen(WellbeingCheckerScreens.onboardingSecond)
                    }
                  />
                ) : null}
                {currentScreen === WellbeingCheckerScreens.onboardingSecond ? (
                  <WelcomeOnboardingSecond
                    onComplete={() =>
                      setCurrentScreen(WellbeingCheckerScreens.onboardingThird)
                    }
                  />
                ) : null}
                {currentScreen === WellbeingCheckerScreens.onboardingThird ? (
                  <WelcomeOnboardingThird
                    onComplete={() =>
                      setCurrentScreen(WellbeingCheckerScreens.questions)
                    }
                  />
                ) : null}
                {currentScreen === WellbeingCheckerScreens.welcome ? (
                  <WelcomeWellbeing
                    isOnboarding={currentStep === OnboardingStep.wellbeing}
                    onComplete={() =>
                      setCurrentScreen(WellbeingCheckerScreens.questions)
                    }
                    handleSkip={() => handleSkip(false)}
                  />
                ) : null}
                {currentScreen === WellbeingCheckerScreens.questions && (
                  <QuestionsPage
                    isOnboarding={currentStep === OnboardingStep.wellbeing}
                    questionNumber={currentQuestion}
                    question={wellbeingQuestions[currentQuestion]}
                    onComplete={(answer: WellbeingQuestionAnswer) => {
                      setQuestionAnswers([
                        ...questionAnswers.filter(
                          (qustionAnswer) =>
                            wellbeingQuestions[currentQuestion].slug !==
                            qustionAnswer.slug
                        ),
                        {
                          slug: wellbeingQuestions[currentQuestion].slug,
                          answer,
                        },
                      ]);
                      if (currentQuestion === wellbeingQuestions.length - 1) {
                        setCurrentScreen(
                          WellbeingCheckerScreens.channelSelection
                        );
                        return;
                      }
                      setCurrentQuestion(currentQuestion + 1);
                    }}
                    handleSkip={handleSkip}
                    handleBack={handleBack}
                  />
                )}
                {currentScreen === WellbeingCheckerScreens.channelSelection && (
                  <ChannelSelectionPage
                    onComplete={(issues: Issue[]) => {
                      setCurrentScreen(WellbeingCheckerScreens.analyzing);
                      setIsComplete(true);
                      submitWellbeing(
                        { questions: questionAnswers, issues },
                        {
                          onSuccess: (
                            response: WellbeingSubmitResponseDto | null
                          ) => {
                            setFlaggedUser(response?.flaggedUser);
                            if (currentStep === OnboardingStep.wellbeing) {
                              setCurrentStep(OnboardingStep.trackFeelings);
                              navigate("TrackFeelings");
                              return;
                            }

                            navigate("TrackFeelings", {
                              type: "wellbeing",
                              flaggedUser,
                            });
                          },
                          onError: () => {
                            toast({
                              title:
                                "An error occurred submitting your request",
                              status: ToastStatus.error,
                            });
                          },
                        }
                      );
                    }}
                  />
                )}
                {isComplete ? (
                  <Box justifyContent="center" alignItems="center">
                    <LoadingAnimation
                      size={100}
                      isLoading={true}
                      autoPlay={true}
                    />
                  </Box>
                ) : null}
              </View>
            </VStack>
          </Box>
        </Container>
      </Animated.View>
      <AlertDialog
        headerText="Skip Wellbeing Checker"
        bodyText="Are you sure?"
        buttonText="Yes, skip"
        showFooter={true}
        buttonAction={() => {
          setIsSkipAlert(false);
          setIsComplete(true);
          navigate("TrackFeelings", {
            type: "wellbeing",
            flaggedUser,
          });
        }}
        isOpen={isSkipAlert}
        onClose={() => setIsSkipAlert(false)}
      />
    </>
  );
}
