import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import {
  Box,
  Circle,
  HStack,
  useColorModeValue,
  View,
  VStack,
} from "native-base";
import React, { useCallback, useContext, useState } from "react";
import { Pressable } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { IconButton } from "../components/elements/IconButton";
import {
  ChannelsIcon,
  FlowerLotusIcon,
  HomeIcon,
  SearchIcon,
  UserIcon,
} from "../components/icons/Icons";
import { ShoutIcon } from "../components/icons/ShoutIcon";
import { WAVE_PHONE_NUMBER } from "../constants/settings/wave-phone-number";
import { TransitionContext } from "../context/TransitionContext";
import { useUsersUnReadNotificationQuantity } from "../hooks/useUsersUnReadNotificationQuantity";
import { openMessageApp } from "../util/linking";

export function BottomTabs({
  state,
  navigation: { navigate },
}: BottomTabBarProps) {
  const backgroundColor = useColorModeValue("white", "dark.100");

  const { startTransition } = useContext(TransitionContext);
  const [hideLayout, setHideLayout] = useState<boolean>(false);
  const { data: unReadNotification } = useUsersUnReadNotificationQuantity();
  function handleNavigation(route: string) {
    navigate(route);
  }

  const getIconColor = useCallback(
    (screen: string) =>
      state.routes[state.index].name === screen
        ? "primary.300"
        : "secondary.400",
    [state]
  );

  return (
    <>
      <SafeAreaView
        edges={["right", "bottom", "left"]}
        style={{ backgroundColor }}
      >
        {!hideLayout ? (
          <Box zIndex="5">
            <VStack>
              <Box>
                <HStack
                  bg={backgroundColor}
                  px="4"
                  py="1"
                  justifyContent="space-between"
                  alignItems="center"
                  borderTopColor="secondary.200"
                  space="20"
                  borderTopWidth="1"
                  _dark={{
                    borderTopColor: "dark.500",
                  }}
                >
                  <HStack
                    flex="1"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <IconButton
                      onPress={() => {
                        handleNavigation("Home");
                      }}
                    >
                      <HomeIcon color={getIconColor("Home")} />
                    </IconButton>
                    <IconButton
                      onPress={() => {
                        handleNavigation("Channels");
                      }}
                    >
                      <ChannelsIcon color={getIconColor("Channels")} />
                    </IconButton>

                    <View position="relative" bottom={28}>
                      <Pressable
                        onPress={() => {
                          setHideLayout(true);
                          startTransition({ screen: "WellbeingChecker" });
                          setTimeout(() => {
                            setHideLayout(false);
                          }, 1000);
                        }}
                      >
                        <Circle
                          size="sm"
                          bg={backgroundColor}
                          _dark={{
                            borderBottomColor: "dark.500",
                            borderWidth: "1",
                            borderTopColor: "dark.100",
                            borderLeftColor: "dark.100",
                            style: { transform: [{ rotate: "45deg" }] },
                          }}
                          shadow="1"
                        >
                          <FlowerLotusIcon
                            _dark={{
                              style: { transform: [{ rotate: "315deg" }] },
                            }}
                          />
                        </Circle>
                      </Pressable>
                    </View>

                    <IconButton
                      onPress={() => {
                        handleNavigation("Search");
                      }}
                    >
                      <SearchIcon color={getIconColor("Search")} />
                    </IconButton>
                    <IconButton
                      onPress={() => {
                        handleNavigation("User");
                      }}
                    >
                      {unReadNotification && unReadNotification > 0 ? (
                        <Box
                          position="absolute"
                          top="-8"
                          right="0"
                          width="2"
                          height="2"
                          rounded="full"
                          background="red.400"
                        />
                      ) : null}
                      <UserIcon color={getIconColor("User")} />
                    </IconButton>
                  </HStack>
                </HStack>
              </Box>
            </VStack>
          </Box>
        ) : null}
      </SafeAreaView>
      <VStack _ios={{ bottom: 24 }} position="absolute" bottom={12} right={2}>
        <IconButton
          style={{
            height: 50,
            justifyContent: "center",
          }}
          hitSlop={-15}
          onPress={() => openMessageApp(WAVE_PHONE_NUMBER, "SHOUT")}
        >
          <ShoutIcon size="100" />
        </IconButton>
      </VStack>
    </>
  );
}
