import { ContentEntity } from "api/src/content/entity/content.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useContent(contentId?: string) {
  return useQuery<ContentEntity | undefined>(
    ["content", "view", contentId],
    async () => {
      if (contentId) {
        const response = await api.get<ContentEntity>(
          `content/item/${contentId}`,
          {}
        );

        if (response.status === 200) {
          return response.data;
        }
      }
      return undefined;
    },
    {
      keepPreviousData: true,
    }
  );
}
