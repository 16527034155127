import { SubmitContentDto } from "api/src/content/dto/submit-content.dto";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

export function useAddPost() {
  const queryClient = useQueryClient();
  return useMutation<
    IApiResponse<ContentEntity>,
    IApiResponse<ContentEntity>,
    SubmitContentDto
  >(
    "content-add",
    async ({ ...data }: SubmitContentDto) => {
      const response = await api.post<IApiResponse<ContentEntity>>(
        "content/add",
        data
      );

      if (response.status !== 201) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("content");
      },
    }
  );
}
