import { useQuery } from "react-query";

import { api } from "../util/api";

export function useHiddenContentIds() {
  return useQuery<string[]>(
    "hidden-content-ids",
    async () => {
      const response = await api.get<string[]>("content/hidden-ids", {});
      return response.data ?? [];
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
    }
  );
}
