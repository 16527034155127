import { SignpostingEntity } from "api/src/signposting/entities/signposting.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useFlaggedUser() {
  return useQuery<SignpostingEntity>(
    ["flagged-user"],
    async () => {
      const response = await api.get<SignpostingEntity>(
        "signposting/flagged-user",
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Couldn't fetch flagged user");
    },
    {
      keepPreviousData: true,
    }
  );
}
