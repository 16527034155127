import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { EditContentScreen } from "../../screens/admin/EditContentScreen";
import { OrganisationContentEditScreen } from "../../screens/organisations/OrganisationContentEditScreen.web";
import { OrganisationContentListScreen } from "../../screens/organisations/OrganisationContentListScreen.web";
import { useBackgroundColor } from "../../util/theme-helper";

const OrganisationContent = createStackNavigator();

export function OrganisationContentNavigator() {
  const { backgroundColorWeb } = useBackgroundColor();
  return (
    <OrganisationContent.Navigator
      initialRouteName="ReviewContentsList"
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor: backgroundColorWeb },
      }}
    >
      <OrganisationContent.Screen
        name="ReviewContentsList"
        component={OrganisationContentListScreen}
        options={{ title: "Organisation Contents - Miindset" }}
      />
      <OrganisationContent.Screen
        name="EditOrganisationContent"
        component={OrganisationContentEditScreen}
        options={{ title: "Organisation Contents Edit - Miindset" }}
      />
      <OrganisationContent.Screen
        name="ReviewContent"
        component={EditContentScreen}
        options={{
          title: "Organisation Add/Edit Content - Miindset",
        }}
      />
    </OrganisationContent.Navigator>
  );
}
