import AsyncStorage from "@react-native-async-storage/async-storage";
import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

interface IVerifyEmail {
  email?: string;
}

export function useVerifyEmailAgain(isDeleteAccount?: boolean) {
  return useMutation<
    IApiResponse<string>,
    IApiResponse<string>,
    IVerifyEmail | null | undefined
  >(["verify-email-again"], async (data?: IVerifyEmail | null) => {
    const email = data?.email || (await AsyncStorage.getItem("email"));
    const response = await api.post<IApiResponse<string>>(
      `users/verify-email-again/${email}`,
      {},
      {
        params: { isDeleteAccount },
      }
    );

    if (response.status !== 201) {
      throw response.data;
    }

    return response.data;
  });
}
