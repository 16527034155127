import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { OnboardingContextProvider } from "../components/context/OnboardingContextProvider";
import { TransitionContextProvider } from "../components/context/TransitionContextProvider";
import { AffirmationScreen } from "../screens/AffirmationScreen";
import { DigestMediaScreen } from "../screens/auth/DigestMediaScreen";
import { EmergencyContactsScreen } from "../screens/EmergencyContactsScreen";
import { TrackFeelingsScreen } from "../screens/TrackFeelingsScreen";
import { TrackMoodScreen } from "../screens/TrackMoodScreen";
import { WellbeingCheckerScreen } from "../screens/WellbeingCheckerScreen";

import { DigestingScreen } from "./DigestingScreen";

const Auth = createStackNavigator();

export function OnboardingNavigator() {
  return (
    <>
      <TransitionContextProvider>
        <OnboardingContextProvider>
          <Auth.Navigator
            initialRouteName="EmergencyContacts"
            screenOptions={{
              headerShown: false,
            }}
          >
            <Auth.Screen
              name="EmergencyContacts"
              component={EmergencyContactsScreen}
            />
            <Auth.Screen name="DigestMedia" component={DigestMediaScreen} />
            <Auth.Screen name="TrackFeelings" component={TrackFeelingsScreen} />
            <Auth.Screen
              name="TrackMoodOnboarding"
              component={TrackMoodScreen}
            />
            <Auth.Screen name="Digesting" component={DigestingScreen} />
            <Auth.Screen
              name="WellbeingCheckerOnboarding"
              component={WellbeingCheckerScreen}
              options={{ animationEnabled: false }}
            />
            <Auth.Screen name="Affirmation" component={AffirmationScreen} />
          </Auth.Navigator>
        </OnboardingContextProvider>
      </TransitionContextProvider>
    </>
  );
}
