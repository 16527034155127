import { BreathingButton } from "../enum/breathing-button";
import { BreathingStepType } from "../enum/breathing-step-type";
import { IBreathingScreen } from "../interfaces/IBreathingScreen";

import { BREATHING_COMPLETE_SCREEN } from "./breathing-complete-screen";
import { BREATHING_FIRST_SCREEN } from "./breathing-first-screen";

export const ROLL_BREATHING: IBreathingScreen[] = [
  BREATHING_FIRST_SCREEN,
  {
    step: "Let's take breath",
    title: "Roll Breathing",
    details:
      "The object of roll breathing is to develop full use of your lungs and get in touch with the rhythm of your breathing. You can practise this in any position, but it is best to learn it lying on your back, with your knees bent. Be cautious the first few times you try this exercise, you may become dizzy or lightheaded - slow your breathing to your normal breathing pattern before getting up slowly.",
    buttons: [BreathingButton.begin],
  },
  {
    step: "Step 1",
    title: "Place your left hand on your stomach and your right on your chest.",
    auto: true,
    type: BreathingStepType.step,
  },
  {
    step: "Step 2",
    title:
      "Breathe in through your nose and out through your mouth and fill your lower lungs so that your left-hand raises when you inhale and your right-hand remains still. \n\nDo this 10 times.",
    type: BreathingStepType.step,
  },
  {
    step: "Step 3",
    title:
      "Now the second part. First, inhale your lower lungs like before then continue inhaling into your upper chest. Breathe regularly and slowly your right hand will rise and your left hand will fall as your stomach falls.",
    type: BreathingStepType.step,
  },
  {
    step: "Step 4",
    title:
      "Make a whooshing sound through your mouth as you exhale and feel the tension leave your body leaving you feeling more relaxed.",
    auto: true,
    type: BreathingStepType.step,
  },
  {
    step: "Step 5",
    title: "Try breathing this way for 5 minutes.",
    type: BreathingStepType.step,
  },
  BREATHING_COMPLETE_SCREEN,
];
