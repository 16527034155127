export const TERMS_AND_CONDITIONS: string[] = [
  "**Registration and acceptance",
  "These terms are important and apply if your organisation is a member of Miindset, and you yourself choose to register.",
  "Please read these terms carefully before registering for the service. By clicking to ‘Register’, you are consenting to these terms.",
  "We reserve the right to change or update these terms from time to time without further notice. It is therefore your responsibility to check these terms regularly for any changes.",
  "Your continued use of the Miindset service after any such changes constitutes your acceptance of the new terms. If you do not agree to abide by these terms or any future terms you should not continue to use Miindset.",

  "**Eligibility",
  "You must be 18 years or over to register with Miindset. If we have any reason to suspect that you are not over 18 years of age, we reserve the right to terminate your use of the Miindset service.",

  "**Your promises to us",
  "You confirm that:",
  "You are over 18 years of age",
  "All information and details provided by you are true, accurate and up to date in all respects and at all times",
  "By registering with Miindset, you are complying with the terms set out in this document",
  "You agree to compensate us from any claim or damages (including any legal fees in relation to such claim or damages) made by a third party in respect of any matter in relation to or arising from your use of the Miindset service, or arising from any breach or suspected breach of these terms by you or your violation of any law or the rights of any third party",

  "**Your data",
  "Upon registration with Miindset, you will select a password and username that only you can use to access your personalised app. You are responsible for maintaining the confidentiality of your password and username and are responsible for all activities that occur under them. We do not have the means to check the identity of people, nor do we wish to, so we will not be held liable if your password or username are used by someone else.",
  "You agree to notify us immediately, by emailing hello@miindset.com, of any unauthorised use of your password or username. You agree to be fully responsible for all use of your account and for any actions that take place using your account and to maintain and promptly update any information you provide, to keep it accurate, current and complete.",
  "In order to maintain your anonymity, we do not ask for your name or your address. We strongly advise and ask that you not to include your real name in your username.",

  "**Third-party websites",
  "Please be aware that Miindset may link to other websites that may be accessed by you through the app. Such third-party websites are not investigated, monitored or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any websites you may access via Miindset. If you decide to leave the app and access a third-party website or to use or install any third-party applications, software or content, you do so at your own risk. We are not responsible for the data policies, content or security of these linked websites.",

  "**Privacy",
  "Your security is very important to us. Please read our privacy policy page for important information regarding the use of data and your rights in relation to this.",

  "**Termination",
  "You can cancel your Miindset account at any time by notifying us at hello@miindset.com. We may terminate your account and stop you from using or accessing the app for any reason, or no reason, at any time at our sole discretion, with or without notice.",

  "**Our legal obligations and limits on liability",
  "While we have taken every care in compiling this personalised information app, you acknowledge that the information contained on this app does not constitute medical or professional advice. This site is provided for information purposes only and all content on the app is provided on an ‘as is’ and ‘as available’ basis. We do not make any promises to you in respect of the site or its content.",
  "Any decision made or action taken by you on the basis of information provided on or via the app is at your sole discretion and risk. You should seek appropriate professional advice before acting (or not acting) in reliance on any information contained in or accessed through this app. So far as is permissible by law, we do not accept any liability to any person relating to the use of this app.",
  "While we will use all reasonable endeavours to correct any errors or omissions in the content provided on the app as soon as practicable once they have been brought to our attention, we do not promise that the app will be available uninterrupted and in a fully operating condition nor that the information on and provided via the app will be free from errors or omissions. We shall not be liable for any failure or suspension of the app, or for the termination of access to it or access to its content.",

  "**General",
  "If any part of these terms is disallowed or found to be ineffective by any court or regulator, the other provisions shall continue to apply.",
  "In the event of any dispute between you and us concerning these terms, the relevant laws of the United Kingdom will apply. If you wish to take court proceedings against us, you must do so within the courts of the United Kingdom.",

  "**Complaints and feedback",
  "If you have any complaints about any aspect of the app or if you have any questions or would otherwise like to provide any other feedback, then you can contact us by emailing hello@miindset.com.",

  "**Duty of confidentiality",
  "All information that you provide to Miindset, including username and email, is kept confidential by Miindset and will never be shared with any third-party organisation.",

  "**Risk or crisis situations",
  "The Miindset wellbeing checker is a non-diagnostic tool used by Miindset to determine which content would be most suitable to each user.",
  "Users should not take the suggestions made by Miindset as professional advice, merely as suggestions of what information might be most beneficial for each user.",
];
