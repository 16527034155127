import { ContentType } from "api/src/content/enum/ContentType";
import dayjs from "dayjs";
import { Box, Divider, HStack, Text, VStack } from "native-base";
import React, { useMemo } from "react";

import { useViewedContentIds } from "../../hooks/useViewedContentIds";
import { HeadphonesIcon, OpenBookIcon, VideoCameraIcon } from "../icons/Icons";
import { Loading } from "../Loading";

export function UserMediaConsumption() {
  const { data: viewedContentIds, isLoading } = useViewedContentIds();

  const lastMonthViewed = useMemo(
    () =>
      viewedContentIds?.filter(({ createdAt }) => {
        const lastMonth = dayjs().subtract(4, "week");

        return dayjs(createdAt).isBetween(lastMonth, dayjs());
      }),
    [viewedContentIds]
  );

  if (isLoading) {
    return <Loading />;
  }
  return (
    <VStack alignItems="center" space="6">
      <VStack space={1}>
        <Text
          variant="strongHighlight"
          textAlign="center"
          fontWeight="600"
          fontSize="20"
        >
          Your media consumption this month
        </Text>
        <Text
          textAlign="center"
          fontSize="16"
          color="secondary.450"
          _dark={{ color: "secondary.400" }}
        >
          The last 4 weeks
        </Text>
      </VStack>
      {/* TODO: Refactor all this to render from an array to avoid duplicated code  */}
      <HStack space="8" pb="4" divider={<Divider h="80%" alignSelf="center" />}>
        <VStack>
          <HStack justifyContent="space-around" alignItems="center" space="2">
            <Text textAlign="center" fontWeight="600" fontSize="36">
              {
                lastMonthViewed?.filter(
                  ({ type }) => type === ContentType.article
                ).length
              }
            </Text>
            <Box ml={1}>
              <OpenBookIcon size="4xl" color="primary.200" alignSelf="center" />
            </Box>
          </HStack>
          <Text
            textAlign="center"
            fontSize="13"
            color="secondary.500"
            _dark={{ color: "secondary.400" }}
          >
            Articles
          </Text>
        </VStack>

        <VStack>
          <HStack justifyContent="space-around" alignItems="center" space="2">
            <Text textAlign="center" fontWeight="600" fontSize="36">
              {
                lastMonthViewed?.filter(
                  ({ type }) => type === ContentType.podcast
                ).length
              }
            </Text>
            <Box ml={1}>
              <HeadphonesIcon
                size="4xl"
                color="primary.200"
                alignSelf="center"
              />
            </Box>
          </HStack>
          <Text
            textAlign="center"
            fontSize="13"
            color="secondary.500"
            _dark={{ color: "secondary.400" }}
          >
            Podcasts
          </Text>
        </VStack>

        <VStack>
          <HStack justifyContent="space-around" alignItems="center" space="2">
            <Text textAlign="center" fontWeight="600" fontSize="36">
              {
                lastMonthViewed?.filter(
                  ({ type }) => type === ContentType.video
                ).length
              }
            </Text>
            <Box ml={1}>
              <VideoCameraIcon
                size="4xl"
                color="primary.200"
                alignSelf="center"
              />
            </Box>
          </HStack>
          <Text
            textAlign="center"
            fontSize="13"
            color="secondary.500"
            _dark={{ color: "secondary.400" }}
          >
            Videos
          </Text>
        </VStack>
      </HStack>
      <Text
        textAlign="center"
        fontSize="16"
        color="secondary.750"
        w="80%"
        _dark={{ color: "secondary.400" }}
      >
        Since the start of your journey you have consumed...
      </Text>
      <HStack>
        <HStack space="8" divider={<Divider h="80%" alignSelf="center" />}>
          <VStack space="2">
            <HStack justifyContent="center" alignItems="center" space="2.5">
              <Text textAlign="center" fontWeight="600" fontSize="26">
                {
                  viewedContentIds?.filter(
                    ({ type }) => type === ContentType.article
                  ).length
                }
              </Text>
              <OpenBookIcon size="2xl" color="primary.400" />
            </HStack>
            <Text
              textAlign="center"
              fontSize="13"
              color="secondary.500"
              _dark={{ color: "secondary.400" }}
            >
              Articles
            </Text>
          </VStack>
          <VStack space="2">
            <HStack justifyContent="center" alignItems="center" space="2.5">
              <Text textAlign="center" fontWeight="600" fontSize="26">
                {
                  viewedContentIds?.filter(
                    ({ type }) => type === ContentType.podcast
                  ).length
                }
              </Text>
              <HeadphonesIcon size="2xl" color="primary.400" />
            </HStack>
            <Text
              textAlign="center"
              fontSize="13"
              color="secondary.500"
              _dark={{ color: "secondary.400" }}
            >
              Podcasts
            </Text>
          </VStack>
          <VStack space="2">
            <HStack justifyContent="center" alignItems="center" space="2.5">
              <Text textAlign="center" fontWeight="600" fontSize="26">
                {
                  viewedContentIds?.filter(
                    ({ type }) => type === ContentType.video
                  ).length
                }
              </Text>
              <VideoCameraIcon size="2xl" color="primary.400" />
            </HStack>
            <Text
              textAlign="center"
              fontSize="13"
              color="secondary.500"
              _dark={{ color: "secondary.400" }}
            >
              Videos
            </Text>
          </VStack>
        </HStack>
      </HStack>

      <VStack
        alignItems="center"
        justifyContent="center"
        pt="2"
        space="2"
        px="6"
      >
        <Text
          variant="lightHighlight"
          textAlign="center"
          fontWeight="600"
          mt={5}
        >
          How watching, listening and reading benefits your mental health
        </Text>
        <Text textAlign="center">
          Reading has been shown to put our brains into a state similar to
          meditation, and it brings the same health benefits of deep relaxation
          and inner calm. Regular readers sleep better, have lower stress
          levels, higher self-esteem, and lower rates of depression than
          non-readers.
        </Text>
      </VStack>
    </VStack>
  );
}
