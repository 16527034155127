import AsyncStorage from "@react-native-async-storage/async-storage";
import { useMutation } from "react-query";

import { api } from "../util/api";

export function useAccountActive() {
  return useMutation<boolean, null, null>("account-active", async () => {
    const email = await AsyncStorage.getItem("email");
    const response = await api.get<{ isActive: boolean }>(
      `users/active/${email}`,
      { validateStatus: () => true }
    );

    await new Promise((res) => {
      setTimeout(res, 1000);
    });

    if (response?.data?.isActive) {
      return true;
    }
    throw new Error("Account not active");
  });
}
