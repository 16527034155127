import { Mood } from "api/src/emotion/enum/mood";
import { useToken } from "native-base";
import React, { ReactNode } from "react";
import Svg, { G, Line, Path, Rect, Text as SVGText } from "react-native-svg";
import { InterpolationPropType } from "victory";
import { CategoryPropType } from "victory-core";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory-native";
import { VictoryTooltipProps } from "victory-tooltip";

export type ChartData = {
  mood: Mood;
  createdAt?: string;
  weekDay: string;
  hour: string;
}[];

export type MentalHealthData = {
  x: string;
  y: number;
}[];

interface IChart {
  data: ChartData | MentalHealthData;
  CustomTick?: ReactNode;
  categories?: CategoryPropType;
  tickValues?: number[];
  height?: number;
  interpolation?: InterpolationPropType;
}

export function Chart({
  data,
  CustomTick,
  categories,
  height = 300,
  tickValues,
  interpolation = "natural",
}: IChart) {
  const [primaryColor] = useToken("colors", ["primary.400"]);

  return (
    <VictoryChart
      height={height}
      domainPadding={[0, 10]}
      containerComponent={
        <VictoryVoronoiContainer
          labels={() => `
                  `}
          labelComponent={
            <VictoryTooltip flyoutComponent={<CustomTooltip />} />
          }
        />
      }
    >
      <VictoryAxis
        // x
        style={{
          axis: { stroke: "#ECE9F1" },
          tickLabels: {
            fill: "#A2A3A5",
          },
        }}
      />
      <VictoryAxis
        // y
        width={400}
        height={400}
        offsetY={200}
        dependentAxis={true}
        style={{
          grid: { stroke: "#ECE9F1", padding: 20 },
          axis: { stroke: "transparent", padding: 20 },
          tickLabels: { fill: "red", padding: 10 },
        }}
        // added due to passing a react node by taking as props
        // @ts-ignore: Unreachable code error
        tickLabelComponent={<CustomTick />}
        tickValues={tickValues}
      />

      <VictoryArea
        y0={() => 1}
        x="weekDay"
        y="mood"
        categories={categories}
        interpolation={interpolation}
        style={{
          data: {
            strokeWidth: 3,
            stroke: primaryColor,
            fill: "#FFD2E9",
            opacity: 0.5,
          },
        }}
        animate={{
          duration: 2000,
          onLoad: { duration: 1000 },
        }}
        data={data}
      />
    </VictoryChart>
  );
}

function CustomTooltip({ x = 0, y = 0, datum }: VictoryTooltipProps) {
  // TODO: FIX TYPING - Property 'hour' does not exist on type '{} | undefined'.
  // @ts-ignore
  const { hour } = datum;
  return (
    <Svg width={167} height={100} fill="none">
      <G>
        <Line
          transform={`translate(${x}, 50)`}
          x1={0}
          y1={200}
          stroke="#422F8A"
          strokeOpacity={0.87}
          strokeDasharray="4.4 4.4"
        />
        <Path
          x={x - 42}
          y={y - 65}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.462 97.15c-1.912 2.467-5.012 2.467-6.924 0l-4.236-5.202c-.882-1.083-1.324-1.625-1.868-2.014a5 5 0 0 0-1.594-.758C71.194 89 70.496 89 69.099 89h-48.87c-6.686 0-9.11-.696-11.555-2.004a13.63 13.63 0 0 1-5.67-5.67C1.695 78.882 1 76.457 1 69.771V20.229c0-6.686.696-9.11 2.003-11.555a13.63 13.63 0 0 1 5.67-5.67C11.119 1.695 13.544 1 20.23 1h126.542c6.686 0 9.111.696 11.555 2.003a13.632 13.632 0 0 1 5.671 5.67c1.307 2.445 2.003 4.87 2.003 11.556v49.542c0 6.686-.696 9.11-2.003 11.555a13.632 13.632 0 0 1-5.671 5.67C155.882 88.305 153.457 89 146.771 89h-46.068c-2.794 0-4.19 0-5.482.352a10 10 0 0 0-3.189 1.516c-1.088.779-1.97 1.862-3.735 4.027l-1.835 2.254Z"
          fill="#fff"
          stroke="#DF3D8D"
          scale="0.5"
        />

        <SVGText
          x={x - 32}
          y={y - 36}
          fontSize="16"
          fontWeight="bold"
          fill="#11263C"
        >
          {hour || ""}
        </SVGText>
        <G x={x - 16.5} y={y - 10}>
          <Rect
            x={6}
            y={3}
            width={20}
            height={20}
            rx={10}
            fill="#422F8A"
            fillOpacity={0.87}
          />
          <Rect
            x={8}
            y={5}
            width={16}
            height={16}
            rx={8}
            stroke="#fff"
            strokeWidth={4}
          />
        </G>
      </G>
    </Svg>
  );
}
