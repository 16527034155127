import React, { ReactNode, useCallback, useMemo, useState } from "react";

import {
  SecondLoginContext,
  ISecondLoginContext,
} from "../../context/SecondLoginContext";
import { SecondLoginStepsType } from "../../enum/second-login-steps";

interface ITransitionContextProps {
  children: ReactNode;
}

export function SecondLoginContextProvider({
  children,
}: ITransitionContextProps) {
  const [currentLoginStep, setCurrentLoginStep] =
    useState<SecondLoginStepsType>(SecondLoginStepsType.getGroundedExercise);

  const setCurrentStep = useCallback(
    (step: SecondLoginStepsType) => {
      setCurrentLoginStep(step);
    },
    [currentLoginStep, setCurrentLoginStep]
  );

  const loginContextValue = useMemo(
    () => ({
      currentStep: currentLoginStep,
      setCurrentStep,
    }),
    [currentLoginStep, setCurrentLoginStep]
  );

  return (
    <SecondLoginContext.Provider
      value={loginContextValue as ISecondLoginContext}
    >
      <>{children}</>
    </SecondLoginContext.Provider>
  );
}
