import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function UploadCompletedIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 69 60" {...props}>
      <Svg width={69} height={60} fill="none">
        <Path
          d="m36.028 14.746.093.027.004-.004a.88.88 0 0 0 1-.617c1.171-3.937 4.862-6.687 8.975-6.687a.882.882 0 0 0 0-1.763c-5.054 0-9.302 3.365-10.665 7.947a.881.881 0 0 0 .593 1.097Z"
          fill="#DF3D8D"
          stroke="#F9FFF9"
          strokeWidth={0.3}
        />
        <Path
          d="M42 36 31 47l-5-5"
          stroke="#DF3D8D"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M56.344 42.438h-4.39a.732.732 0 0 1 0-1.463h4.39c6.052 0 10.976-4.924 10.976-10.976 0-6.052-4.924-10.976-10.976-10.976h-.106a.731.731 0 0 1-.724-.836c.065-.456.098-.913.098-1.36 0-5.244-4.268-9.512-9.513-9.512-2.04 0-3.986.638-5.627 1.845a.732.732 0 0 1-1.081-.25C34.743.06 22.602-1.129 16.308 6.57a13.863 13.863 0 0 0-2.858 11.576.732.732 0 0 1-.717.878h-.293C6.388 19.024 1.463 23.948 1.463 30c0 6.051 4.924 10.975 10.976 10.975h4.39a.732.732 0 0 1 0 1.464h-4.39C5.58 42.439 0 36.859 0 29.999c0-6.666 5.272-12.125 11.865-12.426-.62-4.266.565-8.57 3.31-11.929 6.739-8.244 19.653-7.32 25.112 1.873A10.881 10.881 0 0 1 46.1 5.852c6.355 0 11.39 5.409 10.95 11.728 6.532.366 11.734 5.796 11.734 12.419 0 6.86-5.58 12.44-12.44 12.44Z"
          fill="#DF3D8D"
        />
        <Path
          d="M15.85 41.294c0 10.17 8.274 18.443 18.444 18.443s18.443-8.274 18.443-18.444S44.463 22.85 34.293 22.85 15.85 31.124 15.85 41.294Zm1.764 0c0-9.197 7.482-16.68 16.68-16.68 9.196 0 16.68 7.482 16.68 16.68 0 9.196-7.484 16.68-16.68 16.68-9.197 0-16.68-7.483-16.68-16.68Z"
          fill="#DF3D8D"
          stroke="#F9FFF9"
          strokeWidth={0.3}
        />
      </Svg>
    </Icon>
  );
}
