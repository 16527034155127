import { SubmitDigestOrderDto } from "api/src/wellbeing/dto/submit-digest-order.dto";
import { useMutation } from "react-query";

import { api } from "../util/api";

export function useDigestOrderSubmit() {
  return useMutation<null, null, SubmitDigestOrderDto>(
    "digest/submit",
    async (data: SubmitDigestOrderDto) => {
      const response = await api.post("wellbeing/digest-order", data);

      if (response.status !== 201) {
        throw response.data;
      }

      return null;
    }
  );
}
