import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

interface IHabitId {
  habitId: string | undefined;
}

export function useDeleteHabitStreak(habitId: string | undefined) {
  const queryClient = useQueryClient();

  return useMutation<null, null, IHabitId>(
    ["habit-streak-delete", habitId],
    async ({ habitId }) => {
      const response = await api.delete(`habit/streak/${habitId}`, {});

      if (response.status !== 200) {
        throw response.data;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["habit-streak", habitId]);
        queryClient.invalidateQueries("habits");
      },
    }
  );
}
