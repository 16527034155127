import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

interface IJournalId {
  journalId: string | undefined;
}

export function useJournalEntryDelete(journalId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation<null, null, IJournalId>(
    ["journal-delete", journalId],
    async ({ journalId }) => {
      const response = await api.delete(`journal/${journalId}`, {});

      if (response.status !== 200) {
        throw response.data;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["journal-list"]);
      },
    }
  );
}
