export const BACKGROUND_COLORS = [
  "#8060ff",
  "#00fded",
  "#a47766",
  "#f3248f",
  "#9b26b0",
  "#009687",
  "#4caf51",
  "#ffea3b",
  "#fe5822",
  "#00bdd4",
  "#ffc109",
  "#cddb3a",
  "#4e6b2a",
  "#00ff00",
  "#f239f4",
  "#6c92b6",
];
