import {
  RouteProp,
  StackActions,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { JournalEntryType } from "api/src/journal/enum/JournalEntryType";
import { Text, HStack, VStack, Box } from "native-base";
import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
// import { TouchableOpacity } from "react-native";
// import Tags from "react-native-tags";

import { Button } from "../../components/elements/Button";
import { Input } from "../../components/form/Input";
// import { SaveFileIcon, TagIcon } from "../../components/icons/Icons";
import { SaveFileIcon } from "../../components/icons/Icons";
import { JournalCurrentDay } from "../../components/journal/JournalCurrentDay";
import { JournalEntryContainer } from "../../components/journal/JournalEntryContainer";
import { Loading } from "../../components/Loading";
import { useJournalEntry } from "../../hooks/useJournalEntry";
import { useJournalSubmit } from "../../hooks/useJournalSubmit";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { RootStackParamList } from "../../navigation/RootStackParamList";

export function AddDiaryEntryScreen() {
  const toast = useToast();
  const { dispatch, navigate } = useNavigation();

  const {
    params: { journalId },
  } = useRoute<RouteProp<RootStackParamList, "AddDiaryEntry">>();

  const { mutate: submitDiaryEntry, isLoading: isJournalSubmitLoading } =
    useJournalSubmit(journalId);
  const { data: journal, isLoading } = useJournalEntry(journalId);
  const [tags] = useState<string[]>([]);
  const defaultValues: FieldValues = {
    title: journal?.diaryData.title,
    post: journal?.diaryData.post,
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: journalId ? defaultValues : undefined,
  });

  function onSubmit(data: Record<string, string>) {
    const { title, post } = data;
    return submitDiaryEntry(
      {
        type: JournalEntryType.diary,
        title,
        diary: {
          title,
          post,
          tags,
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${
              !journalId ? "New Diary entry created" : "Diary entry edited"
            }`,
            status: ToastStatus.success,
          });
          !journalId ? dispatch(StackActions.pop(2)) : navigate("MyJournal");
        },
        onError: () => {
          toast({
            title: `Couldn't ${!journalId ? "create" : "edit"} diary`,
            status: ToastStatus.warning,
          });
        },
      }
    );
  }

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [journal]);

  if (isLoading) {
    return <Loading showHeader={true} />;
  }

  return (
    <JournalEntryContainer>
      <HStack justifyContent="flex-end" alignItems="center">
        <JournalCurrentDay />
      </HStack>
      <VStack space={1}>
        <Box>
          <Input
            name="title"
            fontSize={[20, 24]}
            variant="title"
            control={control}
            placeholder={!journalId ? "Title" : journal?.diaryData?.title}
          />
        </Box>
        <Box mt="2">
          <Input
            _web={{ bg: "secondary.100" }}
            isTextarea={true}
            control={control}
            name="post"
            textAlignVertical="top"
            placeholder={!journalId ? "Post Text" : journal?.diaryData?.post}
            size={["xl", "2xl"]}
            variant="title"
            fontWeight="300"
            h={300}
          />
        </Box>
      </VStack>

      {/*
              TODO: 
               - AddIcon: Rich text editor (add images, videos, audios to post text body);
               - MoodIcon: Add current mood while writing in the diary
               
              <HStack bg="white" mx="2" p="2" justifyContent="space-between">
                <AddIcon size={["md", "xl"]} />
                <MoodIcon size={["md", "xl"]} />
              </HStack> */}

      {/* <HStack
              pl="2"
              pt="2"
              bg="white"
              alignItems="center"
              space="2"
              borderTopColor="#C4C4C4"
              borderTopWidth="0.3"
            >
              <TagIcon />
              <Text color="#DF3D8D" fontSize={16}>
                Add Tags
              </Text>
              <Tags
                containerStyle={{
                  width: 250,
                }}
                maxNumberOfTags={4}
                inputStyle={{
                  paddingLeft: 0,
                  backgroundColor: "white",
                  color: "#DF3D8D",
                  fontSize: 14,
                }}
                initialTags={journalId ? journal?.diaryData?.tags : []}
                onChangeTags={(tags) => setTags(tags)}
                renderTag={({ tag, index, onPress }) => (
                  <TouchableOpacity
                    style={{
                      backgroundColor: "#EF5DA8",
                      opacity: 0.9,
                      borderRadius: 10,
                      paddingHorizontal: 10,
                      paddingVertical: 2,
                      marginRight: 6,
                      marginTop: 4,
                    }}
                    key={`${tag}-${index}`}
                    onPress={onPress}
                  >
                    <Text color="white">{tag}</Text>
                  </TouchableOpacity>
                )}
              />
            </HStack> */}

      <HStack pt="10" justifyContent="center" space="10">
        <Button
          onPress={handleSubmit(onSubmit)}
          isLoading={isJournalSubmitLoading}
        >
          <HStack alignItems="center" space="1">
            <SaveFileIcon color="white" size={["sm", "md"]} />
            <Text color="white" fontSize={[15, 18]} fontWeight="300">
              {!journalId ? "Save" : "Edit"}
            </Text>
          </HStack>
        </Button>
      </HStack>
    </JournalEntryContainer>
  );
}
