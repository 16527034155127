import { useRoute } from "@react-navigation/core";
import { Box, Heading, VStack, Image } from "native-base";
import React from "react";

import { ChannelBubble } from "../components/ChannelBubble";
import { FlatList } from "../components/elements/FlatList";
import { useChannels } from "../hooks/useChannels";
import { Header } from "../layout/Header";
import { ChannelDetailsRouteProp } from "../navigation/ChannelStackParamList";
import { isWeb } from "../util/platform";

export function SubChannelsScreen() {
  const { params } = useRoute<ChannelDetailsRouteProp>();
  const { data } = useChannels();

  const channel = data?.find((channel) => channel.slug === params.slug);

  return (
    <>
      <Header hasBack={true} />
      <FlatList
        ListHeaderComponent={
          <Box pt={isWeb() ? "20" : "10"} mb={6}>
            <VStack justifyContent="center" alignItems="center" space={1}>
              <Image
                source={require("../assets/images/badges/womens-health-channel-badge.png")}
                size={10}
                alt="Women's Health"
              />
              <Heading mt={1} fontSize={16} fontWeight={600} textAlign="center">
                {params.label}
              </Heading>
            </VStack>
          </Box>
        }
        data={channel?.subChannels}
        numColumns={isWeb() ? 5 : 3}
        renderItem={({ item }) => (
          <Box flexBasis={isWeb() ? "20%" : "33%"} key={item.label}>
            <ChannelBubble
              defaultChannel={channel}
              item={item}
              type="subchannel"
              badge={item.badge}
            />
          </Box>
        )}
        keyExtractor={(_, index) => index.toString()}
        _dark={{
          bg: "dark.100",
        }}
      />
    </>
  );
}
