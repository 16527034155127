import { AccountType } from "api/src/users/enum/account-type";
import { Box, HStack, Text, Image, VStack, View, Heading } from "native-base";
import React, { useMemo } from "react";

import { ACCOUNT_TYPES } from "../constants/account-type-images";
import { useMe } from "../hooks/useMe";
import { getAccountExplanation } from "../util/profile";

import { LoadingAnimation } from "./elements/LoadingAnimation";
import { InfoIcon } from "./icons/InfoIcon";
import { InfoPopover } from "./InfoPopover";

export function ProfileHeader() {
  const { data: me, isLoading } = useMe();
  const accountType = useMemo(
    () => ACCOUNT_TYPES[me?.accountType ?? AccountType.standard],
    [me, me?.accountType]
  );

  const accountExplanation = useMemo(
    () => getAccountExplanation(me?.accountType ?? AccountType.standard),
    [me, me?.accountType]
  );

  if (isLoading) {
    return (
      <Box h="200" alignItems="center" justifyContent="center">
        <LoadingAnimation isLoading={true} size={60} />
      </Box>
    );
  }

  return (
    <Box mt={[8, 0]} mb={[8, 0]} opacity={me ? 1 : 0}>
      <HStack
        p={[0, 5]}
        mb={[0, 5]}
        space={[4, 6]}
        alignItems="center"
        _web={{ borderBottomWidth: 2, borderColor: "secondary.200" }}
      >
        <Image
          alt="logo"
          width={90}
          height={90}
          resizeMode="contain"
          key={accountType.label}
          source={accountType.image}
        />
        <VStack>
          <Text fontSize={[15, 16]} fontWeight="400">
            {accountType.label}
          </Text>
          <Heading fontSize={[18, 22]} fontWeight="500">
            {me?.firstName} {me?.lastName}
          </Heading>
        </VStack>

        {accountExplanation && (
          <View flex="1" justifyContent="flex-end" alignItems="flex-end">
            <InfoPopover
              icon={InfoIcon}
              text={accountExplanation}
              placement="bottom right"
            />
          </View>
        )}
      </HStack>
    </Box>
  );
}
