import { Modal, VStack, Text, Image } from "native-base";
import React, { useEffect, useState } from "react";

import { APP_INFO } from "../constants/app-info";
import { useAppInfo } from "../hooks/useAppInfo";
import { useMe } from "../hooks/useMe";

interface IProps {
  isWellDone?: boolean;
}

export function AppInfoPopUp({ isWellDone = false }: IProps) {
  const { data: user } = useMe();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const { mutateAsync: readAppInfo } = useAppInfo();

  useEffect(() => {
    if (
      user !== undefined &&
      user?.hasReadAppInfo === false &&
      user?.hasOnboarded &&
      !isWellDone
    ) {
      setModalVisible(true);
    }
  }, [user, isWellDone]);

  async function handleClose() {
    setModalVisible(false);
    await readAppInfo();
  }

  return (
    <Modal isOpen={modalVisible} onClose={handleClose} size="xl">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header textAlign="center">Welcome to Miindset</Modal.Header>
        <Modal.Body alignItems="center" pb="20">
          <Image
            height={100}
            width={120}
            source={require("../assets/images/logo.png")}
            alt="Logo"
          />
          <VStack space={6} px={2} mx={10} alignItems="center">
            {APP_INFO.map((text, index) => (
              <Text
                key={index.toString()}
                fontSize={14}
                fontWeight={500}
                textAlign="center"
              >
                {text}
              </Text>
            ))}
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
