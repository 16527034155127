import { isEmpty } from "lodash";
import { Text } from "native-base";
import React from "react";

import { Icon, IconLibrary } from "../../components/elements/Icon";
import { useMe } from "../../hooks/useMe";
import { openPhoneApp } from "../../util/linking";

export function EmergencyContactText() {
  const { data: user } = useMe();
  return (
    <>
      {user
        ? user?.emergencyContacts
            ?.filter((contact) => contact.number)
            .map((contact, index) => (
              <Text key={index}>
                {` ${index > 0 && !isEmpty(contact.number) ? "or" : ""} `}
                <Text
                  onPress={() => openPhoneApp(contact.number)}
                  color="primary.200"
                >
                  {`${contact.contactName} `}
                  <Icon
                    icon="phone"
                    color="primary.400"
                    size={4}
                    iconLibrary={IconLibrary.antDesign}
                  />
                </Text>
              </Text>
            ))
        : " a friend or family member."}
    </>
  );
}
