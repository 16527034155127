import AsyncStorage from "@react-native-async-storage/async-storage";
import { Modal, VStack, Text, Image, Button } from "native-base";
import React from "react";

import { WELLBEING_WELL_DONE_SCREEN_KEY } from "../../constants/storage";
import { WELLBEING_WELL_DONE } from "../../constants/wellbeing-well-done-text";

interface IPropTypes {
  modalVisible: boolean;
  setModalVisible: (flag: boolean) => void;
}

export function WellDonePopup({ modalVisible, setModalVisible }: IPropTypes) {
  async function handleClose() {
    await AsyncStorage.removeItem(WELLBEING_WELL_DONE_SCREEN_KEY);
    setModalVisible(false);
  }

  return (
    <Modal
      isOpen={modalVisible}
      onClose={handleClose}
      size="xl"
      closeOnOverlayClick={false}
    >
      <Modal.Content>
        <Modal.Body alignItems="center" pb="20">
          <Image
            height={100}
            width={120}
            source={require("../../assets/images/logo.png")}
            alt="Logo"
          />
          <Text
            fontWeight={600}
            fontSize={16}
            color="black"
            textAlign="center"
            mb="8"
          >
            Well Done!
          </Text>
          <VStack space={6} px={2} mx={10} alignItems="center">
            {WELLBEING_WELL_DONE.map((text, index) => (
              <Text
                key={index.toString()}
                color="gray.700"
                fontSize={14}
                fontWeight={500}
                textAlign="center"
              >
                {text}
              </Text>
            ))}
          </VStack>
          <Button mt="5" onPress={handleClose}>
            View Results
          </Button>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
