import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import dayjs from "dayjs";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { Button } from "../components/elements/Button";
import { Input } from "../components/form/Input";
import { HabitHeader } from "../components/HabitHeader";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import { useHabit } from "../hooks/useHabit";
import { useHabitAdd } from "../hooks/useHabitAdd";
import { useHabitEdit } from "../hooks/useHabitEdit";
import { ToastStatus, useToast } from "../hooks/useToast";
import { Header } from "../layout/Header";
import { RootStackParamList } from "../navigation/RootStackParamList";

export function AddHabitScreen() {
  const toast = useToast();
  const { navigate } = useNavigation();
  const {
    params: { habitId, daysToStreak, name, reminder },
  } = useRoute<RouteProp<RootStackParamList, "AddHabit">>();
  const { mutate: addHabit, isLoading: isLoadingAdd } = useHabitAdd();
  const { mutate: editHabit, isLoading: isLoadingEdit } = useHabitEdit(habitId);
  const { data: habit } = useHabit(habitId);
  const defaultValues: FieldValues = {
    name: habit?.name,
    timer1: habit?.reminder,
  };
  const [daysToTrack, setDaysToTrack] = useState<number>(
    habit?.daysToStreak || 5
  );

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: habitId ? defaultValues : undefined,
  });

  const [hasReminder, setHasReminder] = useState<boolean>(
    !!(habitId && habit?.reminder)
  );

  useEffect(() => {
    if (!habitId) {
      setValue("name", name);
      setValue("timer1", reminder);
      setDaysToTrack(daysToStreak === undefined ? 5 : daysToStreak);
      setHasReminder(!!reminder);
    }
  }, [name, reminder, daysToStreak]);

  function onSubmit(data: Record<string, string>) {
    const { name, timer1 } = data;
    if (!habitId) {
      return addHabit(
        {
          name,
          daysToStreak: daysToTrack,
          reminder: hasReminder ? timer1 : "",
          startDate: dayjs().toISOString(),
          endDate: dayjs().add(daysToTrack, "day").toISOString(),
          timeZoneOffset: dayjs().format("Z"),
        },

        {
          onSuccess: () => {
            toast({ title: "New habit created", status: ToastStatus.success });
            navigate("TrackHabits");
          },
          onError: () => {
            toast({
              title: "Couldn't create habit",
              status: ToastStatus.warning,
            });
          },
        }
      );
    }
    return editHabit(
      {
        name,
        daysToStreak: daysToTrack,
        reminder: hasReminder ? timer1 : "",
        startDate: dayjs().toISOString(),
        endDate: dayjs().add(daysToTrack, "day").toISOString(),
        timeZoneOffset: dayjs().format("Z"),
      },

      {
        onSuccess: () => {
          toast({ title: "Habit edited", status: ToastStatus.success });
          navigate("TrackHabits");
        },
        onError: () => {
          toast({ title: "Couldn't edit habit", status: ToastStatus.warning });
        },
      }
    );
  }

  return (
    <>
      <Box bg="secondary.200" minH="100vh" _dark={{ bg: "dark.500" }}>
        <Header hasBack={true} />
        <WhiteBoxContainer>
          <VStack>
            <HabitHeader
              title={!habitId ? "Create a new habit" : "Edit your habit"}
              subtitle="Healthy habits are a great way to improve your miindset"
            />

            <VStack space="2" padding="2" mt="10" rounded="xl">
              <Box>
                <Input
                  maxLength={30}
                  py="4"
                  rounded="xl"
                  background="secondary.100"
                  fontSize={14}
                  variant="auth"
                  name="name"
                  control={control}
                  placeholderTextColor="secondary.450"
                  placeholder="Name of your habit"
                  _dark={{
                    bg: "dark.200",
                    color: "secondary.400",
                    placeholderTextColor: "secondary.650",
                    _disabled: {
                      bg: "dark.300",
                    },
                  }}
                />
              </Box>
              <HStack
                bg="secondary.100"
                py="4"
                rounded="xl"
                px="3"
                justifyContent="space-between"
                alignItems="center"
                _dark={{ bg: "dark.200" }}
              >
                <Text fontSize={14} fontWeight="400">
                  Days to track
                </Text>
                <HStack space="3">
                  {[5, 10, 30].map((value, index) => {
                    const isActive = value === daysToTrack;
                    return (
                      <Pressable
                        onPress={() => setDaysToTrack(value)}
                        key={index}
                      >
                        <VStack alignItems="center" justifyContent="center">
                          <Text fontSize="15">{value}</Text>
                          <Box
                            bg={isActive ? "primary.400" : "secondary.50"}
                            h="2"
                            w="12"
                            rounded="xl"
                          />
                        </VStack>
                      </Pressable>
                    );
                  })}
                </HStack>
              </HStack>
              <Button
                mt="16"
                w="40"
                alignSelf="center"
                onPress={handleSubmit(onSubmit)}
                isLoading={!habitId ? isLoadingAdd : isLoadingEdit}
              >
                {!habitId ? "Add" : "Edit"}
              </Button>
            </VStack>
          </VStack>
        </WhiteBoxContainer>
      </Box>
    </>
  );
}
