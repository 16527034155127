import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { useMe } from "./useMe";

export function useAppInfo() {
  const queryClient = useQueryClient();
  const { data: user } = useMe();
  return useMutation(
    "read-app-info",
    async () => {
      await api.post("users/read-app-info");
    },
    {
      onSuccess: () => {
        queryClient.setQueryData("account", { ...user, hasReadAppInfo: true });
      },
    }
  );
}
