import { useNavigation } from "@react-navigation/core";
import { AccountType } from "api/src/users/enum/account-type";
import { KeyboardAvoidingView, View, VStack } from "native-base";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { AuthButton as Button } from "../components/auth/AuthButton";
import { AuthCard } from "../components/auth/AuthCard";
import { AuthInput } from "../components/auth/AuthInput";
import { AlertDialog } from "../components/elements/AlertDialog";
import { ScrollView } from "../components/elements/ScrollView";
import { SecondaryEmail } from "../components/SecondaryEmail";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import { useChangeEmail } from "../hooks/useChangeEmail";
import { useFormErrors } from "../hooks/useFormErrors";
import { useMe } from "../hooks/useMe";
import { useSendVerificationCode } from "../hooks/useSendVerificationCode";
import { ToastStatus, useToast } from "../hooks/useToast";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";
import { isiOS, isWeb } from "../util/platform";

export function ChangeEmailScreen() {
  const toast = useToast();
  const { data: user } = useMe();
  const { navigate } = useNavigation();
  const [isConfirmationAlertOpen, setIsConfirmationAlertOpen] =
    useState<boolean>(false);

  const [isVerificationCodeSent, setIsVerificationCodeSent] =
    useState<boolean>(false);

  const { mutate: sendVerificationCode, isLoading: isSendingCodeLoading } =
    useSendVerificationCode();
  const { mutate: changeEmail, isLoading: isChangingEmailLoading } =
    useChangeEmail();

  const {
    reset,
    control,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
  } = useForm();
  const { setErrors } = useFormErrors(setError);

  function onSendVerificationCode(data: Record<string, string>) {
    if (data.newEmail === user?.email) {
      setError("newEmail", {
        message: "New email cannot be the same as old email",
      });
      return;
    }

    sendVerificationCode(
      {
        currentPassword: data.currentPassword,
        newEmail: data.newEmail,
        emailType: "primary",
      },
      {
        onSuccess: (data) => {
          if (data.status === "ok") {
            setIsVerificationCodeSent(true);
            clearErrors(["currentPassword", "newEmail"]);
            toast({
              title: data.message,
              status: ToastStatus.success,
            });
          } else {
            toast({
              title: data.message,
              status: ToastStatus.error,
            });
          }
        },
        onError: (data) => {
          setErrors(
            data.errors
              ? {
                  ...data.errors,
                  currentPassword: data.errors.currentPassword,
                  newEmail: data.errors.newEmail,
                }
              : {}
          );
        },
      }
    );
  }

  function onSubmit(data: Record<string, string>) {
    onClose();

    changeEmail(
      { code: data.code, emailType: "primary" },
      {
        onSuccess: (data) => {
          if (data.status === "ok") {
            reset();
            setIsVerificationCodeSent(false);
            navigate(isWeb() ? "Home" : "App");
            toast({
              title: data.message,
              status: ToastStatus.success,
            });
          } else {
            toast({
              title: data.message,
              status: ToastStatus.error,
            });
          }
        },
        onError: (data) => {
          setErrors(
            data.errors
              ? {
                  ...data.errors,
                  code: data.errors.code,
                }
              : {}
          );
        },
      }
    );
  }

  function onClose(): void {
    setIsConfirmationAlertOpen(false);
  }

  return (
    <>
      <Header hasBack={!isWeb()} />

      <Body>
        <KeyboardAvoidingView
          flex={1}
          behavior={isiOS() ? "padding" : "height"}
          keyboardVerticalOffset={50}
        >
          <ScrollView
            pt="10"
            contentContainerStyle={{ justifyContent: "center" }}
            _web={{ _dark: { bg: "dark.500" } }}
          >
            <WhiteBoxContainer>
              <AuthCard label="Registered Email">
                <VStack space={5} _web={{ mt: 5 }}>
                  <AuthInput
                    name="currentEmail"
                    placeholder={user?.email}
                    control={control}
                    errors={errors}
                    label="Current Email"
                    keyboardType="default"
                    isDisabled={true}
                  />

                  <AuthInput
                    name="newEmail"
                    autoFocus={true}
                    placeholder="New Email"
                    control={control}
                    errors={errors}
                    keyboardType="email-address"
                    isDisabled={isVerificationCodeSent}
                  />
                  <AuthInput
                    name="currentPassword"
                    placeholder="Current Password"
                    control={control}
                    errors={errors}
                    keyboardType="default"
                    type="password"
                    isDisabled={isVerificationCodeSent}
                  />
                  {isVerificationCodeSent ? (
                    <AuthInput
                      name="code"
                      autoFocus={true}
                      placeholder="Verification Code"
                      control={control}
                      errors={errors}
                      keyboardType="default"
                      type="password"
                    />
                  ) : null}

                  <View
                    justifyContent="center"
                    alignItems="center"
                    _web={{ flexDirection: "row" }}
                  >
                    <Button
                      bg="primary.400"
                      mb={2}
                      py={4}
                      variant="solid"
                      isLoading={
                        isVerificationCodeSent
                          ? isChangingEmailLoading
                          : isSendingCodeLoading
                      }
                      onPress={() => {
                        if (isVerificationCodeSent) {
                          setIsConfirmationAlertOpen(!isConfirmationAlertOpen);
                        } else {
                          onSendVerificationCode(getValues());
                        }
                      }}
                      _web={{ maxW: 200, mx: 2 }}
                    >
                      {isVerificationCodeSent ? "Update" : "Send Code"}
                    </Button>
                  </View>
                </VStack>
                <AlertDialog
                  headerText="Change Email"
                  bodyText="This will change your Email. Are you sure?"
                  buttonColor="danger.100"
                  buttonText="Confirm"
                  buttonAction={() => {
                    onSubmit(getValues());
                  }}
                  isOpen={isConfirmationAlertOpen}
                  onClose={onClose}
                />
              </AuthCard>
              {user?.accountType === AccountType.unidays ||
              user?.accountType === AccountType.corporate ? (
                <SecondaryEmail />
              ) : null}
            </WhiteBoxContainer>
          </ScrollView>
        </KeyboardAvoidingView>
      </Body>
    </>
  );
}
