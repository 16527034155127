import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationAverageResilience() {
  return useQuery<number>(
    ["average-resilience"],
    async () => {
      const response = await api.get<number>(
        "organisations/average-resilience",
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get organisations average-resilience");
    },
    {
      keepPreviousData: true,
    }
  );
}
