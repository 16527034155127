import { useNavigation } from "@react-navigation/native";
import {
  VStack,
  Text,
  HStack,
  Image,
  Heading,
  Pressable,
  Box,
  Divider,
} from "native-base";
import React from "react";

import { DigestItem } from "../../components/digest-media/DigestItem";
import { IconButton } from "../../components/elements/IconButton";
import { BrainIcon, CloseCircularIcon } from "../../components/icons/Icons";
import { ShoutIcon } from "../../components/icons/ShoutIcon";
import { DIGEST_TYPES } from "../../constants/digest-types";
import { SAMARITANS_PHONE_NUMBER } from "../../constants/settings/samaritans-phone-number";
import { WAVE_PHONE_NUMBER } from "../../constants/settings/wave-phone-number";
import { useFlaggedUserEdit } from "../../hooks/useFlaggedUserEdit";
import { openMessageApp, openPhoneApp } from "../../util/linking";
import { isWeb } from "../../util/platform";

import { EmergencyContactText } from "./EmergencyContactText";
import { ISignpostingScreenProps } from "./SignpostingScreen";

export function MediumAlertScreens({
  currentIndex,
  handleCloseButton,
  handleNavigationButton,
}: ISignpostingScreenProps) {
  const { navigate, reset } = useNavigation();
  const { mutate: editFlaggedUser } = useFlaggedUserEdit();

  const onPress = () => {
    editFlaggedUser({ hasAnswered: true });
    if (isWeb()) {
      navigate("Home");
      return;
    }
    reset({ index: 1, routes: [{ name: "App" }] });
  };
  switch (currentIndex) {
    case 0:
      return (
        <VStack space={4}>
          <HStack alignItems="center" w="100%" justifyContent="center">
            <Heading fontSize="20" fontWeight={600}>
              Please look after yourself.
            </Heading>
          </HStack>
          <Box pb={20} justifyContent="center">
            <VStack space={4} alignItems="center">
              <Text textAlign="center" color="primary.400">
                The app will now increase the frequency of check ins for you so
                you can keep track of your symptoms. Miindset will provide
                additional help and support as well as suggestions of where to
                get instant support over the next 2 weeks or until your
                wellbeing improves.
              </Text>
              <Text textAlign="center">
                You can always find additional help and support in your Miindset
                account. It may also be beneficial to read through our{" "}
                <Text
                  color="primary.400"
                  onPress={() => {
                    handleNavigationButton("CopingStrategies");
                  }}
                >
                  Coping Strategies
                </Text>{" "}
                Section.
              </Text>
              <BrainIcon />
              {/* TODO: Re add once implement button <Text>Look at Coping Strategies</Text> */}
              <Divider my="4" />
              <Text textAlign="center">
                If you are in imminent danger of harming yourself/acting on
                suicidal thoughts then please call 999 or speak with
                <EmergencyContactText />
              </Text>
              <Pressable
                onPress={() => openMessageApp(WAVE_PHONE_NUMBER)}
                _pressed={{ opacity: 0.8 }}
              >
                <Box height="50" justifyContent="center">
                  <ShoutIcon size="100" />
                </Box>
              </Pressable>
              <Text textAlign="center">
                You can also always get instant, confidential support via SMS
                with a trained professional by texting ‘SHOUT’ to 85258.
              </Text>
              <Pressable onPress={() => openMessageApp(WAVE_PHONE_NUMBER)}>
                <Text color="primary.200">Text ‘SHOUT’ Now</Text>
              </Pressable>
              <Divider my="4" />
              <Pressable onPress={() => openPhoneApp(SAMARITANS_PHONE_NUMBER)}>
                <Image
                  height={100}
                  width={300}
                  resizeMode="contain"
                  source={require("../../assets/images/samaritans.png")}
                  alt="Logo"
                />
              </Pressable>
              <Text textAlign="center">
                You can also speak to someone at the Samaritans by calling 116
                123.
              </Text>
              <Pressable onPress={() => openPhoneApp(SAMARITANS_PHONE_NUMBER)}>
                <Text color="primary.200">Call Samaritans Now</Text>
              </Pressable>
              <Divider my="4" />
              <HStack space={2}>
                {DIGEST_TYPES.map(({ label, icon }) => (
                  <DigestItem
                    label={label}
                    icon={icon}
                    key={label}
                    onPress={onPress}
                  />
                ))}
              </HStack>
              <Pressable onPress={onPress} _pressed={{ opacity: 0.5 }}>
                <Text color="primary.400" marginBottom="-1.5">
                  read articles,
                </Text>
              </Pressable>
              <Pressable onPress={onPress} _pressed={{ opacity: 0.5 }}>
                <Text color="primary.400" marginBottom="-1.5">
                  watch videos
                </Text>
              </Pressable>
              <Text color="primary.400" marginBottom="-1.5">
                and
              </Text>
              <Pressable onPress={onPress} _pressed={{ opacity: 0.5 }}>
                <Text color="primary.400" marginBottom="-1.5">
                  listen to podcasts
                </Text>
              </Pressable>
              <Text textAlign="center">
                Remember, how you are feeling is not wrong, you haven’t done
                anything wrong and you are entitled to feel what you are
                feeling. What you do about how you are feeling is what matters
                now. There are lots of people who care and who can support you
                to help you get through these times.
              </Text>
              <Text textAlign="center">
                Miindset has lots of information to help you work through this
                moment and we can also link you to in-person support via the
                phone and SMS as well as other services.
              </Text>
              <Text textAlign="center" pt="4">
                We will check in with you again later, we are here for you.
              </Text>
            </VStack>
            <HStack pt={4} justifyContent="center">
              <IconButton onPress={handleCloseButton}>
                <CloseCircularIcon size={12} />
              </IconButton>
            </HStack>
          </Box>
        </VStack>
      );
    case 1:
      return (
        <Box pb={20} justifyContent="center">
          <VStack space={4} alignItems="center">
            <Text textAlign="center">
              As you have been low now for 3 days please consider reaching out
              to your GP, talking to friends or family or connecting to someone
              via SMS by texting ‘WAVE’ to 85258 or by calling the Samaritans on
              116 123.
            </Text>
            <Pressable
              onPress={() => openMessageApp(WAVE_PHONE_NUMBER)}
              _pressed={{ opacity: 0.8 }}
            >
              <Box height="50" justifyContent="center">
                <ShoutIcon size="100" />
              </Box>
            </Pressable>
            <Pressable
              onPress={() => openPhoneApp(SAMARITANS_PHONE_NUMBER)}
              _pressed={{ opacity: 0.8 }}
            >
              <Image
                height={100}
                width={300}
                resizeMode="contain"
                source={require("../../assets/images/samaritans.png")}
                alt="Logo"
              />
            </Pressable>
            <HStack pt={4} justifyContent="center">
              <IconButton onPress={handleCloseButton}>
                <CloseCircularIcon size={12} />
              </IconButton>
            </HStack>
          </VStack>
        </Box>
      );
    default:
      navigate("Main");
      break;
  }
}
