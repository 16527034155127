import { useNavigation } from "@react-navigation/native";
import { Box, Button, Text } from "native-base";
import React, { useMemo } from "react";

import { useLatestInsight } from "../hooks/useLatestInsight";
import { getInsightData } from "../util/insight";

export function UserInsight() {
  const { navigate } = useNavigation();
  const { data: insight } = useLatestInsight();

  const insightData = useMemo(() => {
    if (!insight) {
      return null;
    }
    return getInsightData(insight);
  }, [insight]);

  if (!insightData) {
    return null;
  }

  return (
    <Box mb={1} alignItems="center">
      <Text fontSize="12" fontWeight="600" px={8} textAlign="center">
        {insightData.label}
      </Text>
      {insightData.cta ? (
        <Button
          borderRadius={5}
          p={2}
          px={3}
          my={3}
          onPress={() => {
            if (insightData.cta) {
              navigate(insightData.cta.link);
            }
          }}
        >
          <Text color="white" fontSize={12}>
            {insightData.cta.text}
          </Text>
        </Button>
      ) : null}
    </Box>
  );
}
