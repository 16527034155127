import {
  View,
  Text,
  Image,
  Heading,
  HStack,
  VStack,
  Pressable,
  Link,
} from "native-base";
import React from "react";
import { Linking } from "react-native";

import { ToastStatus, useToast } from "../../hooks/useToast";
import { isWeb } from "../../util/platform";
import { CallIcon } from "../icons/CallIcon";
import { NetworkIcon } from "../icons/NetworkIcon";
import { TransIcon } from "../icons/TransIcon";

export function NonBinaryAndTransSupport() {
  const toast = useToast();

  const handleContactClick = (phoneNumber: string) => {
    const url = `tel:${phoneNumber}`;
    if (isWeb()) {
      return;
    }
    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          toast({
            title: "Can't handle this contact",
            status: ToastStatus.warning,
          });
        } else {
          return Linking.openURL(url);
        }
      })
      .catch((err) =>
        toast({
          title: `An error occurred: ${err}`,
          status: ToastStatus.error,
        })
      );
  };

  return (
    <View mb={10} mt={50} alignItems="center">
      {!isWeb() ? (
        <Image
          width={49}
          height={94}
          source={require("../../assets/images/badges/trans-non-binary.png")}
          alt="The Concept Of Sacred Spaces"
          borderRadius={10}
        />
      ) : null}
      <Heading mt={5} mb={5} fontSize={28} fontWeight={600} textAlign="center">
        Non-Binary & Trans
      </Heading>
      {isWeb() ? <TransIcon /> : null}
      <Heading
        mt={5}
        fontSize={20}
        color="primary.400"
        fontWeight={600}
        textAlign="center"
      >
        LGBT Foundation
      </Heading>
      <Text
        mt={5}
        w={isWeb() ? 860 : 300}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Trans Programme has recently celebrated its 3-year anniversary! Over
        this time we have supported thousands of trans people through our
        services and events, and delivered training to hundreds of organisations
        on how to make services better. Our trans work has been developed
        through a comprehensive consultation with the trans community in Greater
        Manchester, and we are extremely proud to be a part of the local and
        national work that continues to highlight the needs of the trans
        community and fight transphobia in all walks of life. As we move into
        the third year of the programme and expand our services, we are keeping
        our ambitions high and hope to reach even more people.
      </Text>

      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        The aims of the programme are…
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        1. Improve the health and wellbeing of trans people
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        2. Increase the skills and confidence of trans people
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        3. Increase the knowledge, empowerment and resilience of trans people
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        4. Reduce feelings of loneliness and isolation of trans people
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        If you think you would like to get involved and could help us achieve
        these aims, please get in touch with us at trans@lgbt.foundation!
      </Text>
      <VStack
        mt={10}
        flex={1}
        w={isWeb() ? 460 : 250}
        space={5}
        justifyContent="space-around"
        flexDirection={isWeb() ? "row" : "column"}
      >
        <Pressable onPress={() => handleContactClick("0345 3 30 30 30")}>
          <HStack space={2}>
            <CallIcon />
            <VStack>
              <Text _dark={{ color: "secondary.200" }}>
                Telephone - 0345 3 30 30 30
              </Text>
            </VStack>
          </HStack>
        </Pressable>
        <Link
          href="https://lgbt.foundation/who-we-help/trans-people"
          isExternal={true}
        >
          <HStack space={2}>
            <NetworkIcon />
            <VStack>
              <Text _dark={{ color: "secondary.200" }} underline={true}>
                www.lgbt.foundation
              </Text>
            </VStack>
          </HStack>
        </Link>
      </VStack>
    </View>
  );
}
