import { useToken } from "native-base";
import React from "react";
import { TouchableOpacity } from "react-native";
import { CountdownCircleTimer } from "react-native-countdown-circle-timer";

import { ArrowIcon } from "./icons/ArrowIcon";

interface ICountdownCircleButtonProps {
  onPress: () => void;
  auto?: boolean;
}

export function CountdownCircleButton({
  onPress,
  auto,
}: ICountdownCircleButtonProps) {
  const [primary100, primary200, secondary200] = useToken("colors", [
    "primary.100",
    "primary.200",
    "secondary.200",
  ]);

  return (
    <CountdownCircleTimer
      size={50}
      strokeWidth={6}
      isPlaying={true}
      duration={10}
      trailColor={secondary200}
      colors={[primary100, primary200]}
      onComplete={auto ? () => onPress() : undefined}
      colorsTime={[10, 0]}
    >
      {({ remainingTime }) => (
        <TouchableOpacity
          disabled={remainingTime !== 0}
          activeOpacity={0.7}
          onPress={onPress}
        >
          <ArrowIcon size="4xl" opacity={remainingTime !== 0 ? 0.7 : 1} />
        </TouchableOpacity>
      )}
    </CountdownCircleTimer>
  );
}
