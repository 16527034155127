import { IChannel, ISubChannel } from "api/src/channels/interface/IChannel";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { first } from "lodash";
import React, { memo } from "react";

import { ContentBanner } from "./content/ContentBanner";

interface IContentSliderWeb {
  items: ContentEntity[];
  title?: string;
  channel?: IChannel;
  subChannel?: ISubChannel;
  imageHeight?: number;
  imageWidth?: number;
  handleSubChannel?: (subChannel?: ISubChannel) => void;
}

export const ContentSliderWeb = memo(
  ({
    items,
    title,
    channel,
    subChannel,
    handleSubChannel,
    imageHeight,
    imageWidth,
  }: IContentSliderWeb) => {
    const sliderContent = first(items);
    if (!sliderContent) {
      return null;
    }

    return (
      <>
        <ContentBanner
          contentData={sliderContent}
          title={title}
          channel={channel}
          subChannel={subChannel}
          handleSubChannel={handleSubChannel}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
        />
      </>
    );
  }
);
