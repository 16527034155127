import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function MyJournalIcon({ color = "#C4C4C4", ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 33 33" {...props}>
      <Svg width={33} height={33} fill="none" stroke={color}>
        <Path
          d="M12.5 16.5h8M12.5 20.5h8M7.5 5.5h18a1 1 0 0 1 1 1v19a3 3 0 0 1-3 3h-14a3 3 0 0 1-3-3v-19a1 1 0 0 1 1-1ZM10.5 3.5v4M16.5 3.5v4M22.5 3.5v4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
