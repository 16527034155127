import { useNavigation } from "@react-navigation/core";
import { isEmpty } from "lodash";
import { Center, Divider, Heading, HStack, VStack, Text } from "native-base";
import React from "react";
import { Pressable, Dimensions } from "react-native";

import { Fab } from "../../components/elements/Fab";
import { ScrollView } from "../../components/elements/ScrollView";
import { AddIcon } from "../../components/icons/AddIcon";
import { JournalEntry } from "../../components/journal/JournalEntry";
import { Loading } from "../../components/Loading";
import { WhiteBoxContainer } from "../../components/WhiteBoxContainer";
import { useJournalEntries } from "../../hooks/useJournalEntries";
import { Body } from "../../layout/Body";
import { Header } from "../../layout/Header";
import { isWeb } from "../../util/platform";

export function JournalScreen() {
  const { navigate } = useNavigation();
  const { data: journal, isLoading, isFetching } = useJournalEntries();
  const { height } = Dimensions.get("window");

  return (
    <>
      <Header hasBack={true} />
      <Body>
        <ScrollView
          contentContainerStyle={{
            flex: isEmpty(journal) || isLoading || isFetching ? 1 : 0,
          }}
          _web={{ _dark: { bg: "dark.500" } }}
        >
          <WhiteBoxContainer minHeight={isWeb() ? height - 200 : undefined}>
            {!isWeb() ? (
              <HStack justifyContent="center" alignItems="flex-end" mb="4">
                <Heading fontSize={[20, 24]} fontWeight="600">
                  My Miindset Journal
                </Heading>
              </HStack>
            ) : (
              <HStack justifyContent="space-between" alignItems="center" mb="4">
                <Heading fontSize={[20, 24]} fontWeight="600">
                  My Miindset Journal
                </Heading>
                <Pressable onPress={() => navigate("MyJournalType")}>
                  <AddIcon size="8" />
                </Pressable>
              </HStack>
            )}
            {isLoading ? (
              <Loading />
            ) : !isEmpty(journal) ? (
              <VStack pt={[5, 10]}>
                <Divider />
                {journal?.map(
                  ({ title, id, type, createdAt, updatedAt }, index) => (
                    <JournalEntry
                      key={index}
                      title={title}
                      id={id}
                      type={type}
                      createdAt={updatedAt || createdAt}
                    />
                  )
                )}
              </VStack>
            ) : (
              <Center h="full">
                <Text color="secondary.500" textAlign="center" mx="20">
                  You haven't add any Journal Entries yet, add some and it will
                  appear here!
                </Text>
              </Center>
            )}
          </WhiteBoxContainer>
        </ScrollView>

        {!isWeb() && (
          <Fab
            onPress={() => {
              navigate("MyJournalType");
            }}
          />
        )}
      </Body>
    </>
  );
}
