import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import { AccountType } from "api/src/users/enum/account-type";
import { VStack, Text } from "native-base";
import React, { useEffect, useState } from "react";

import { AuthButton } from "../components/auth/AuthButton";
import { AuthCard } from "../components/auth/AuthCard";
import { AuthContent } from "../components/AuthContent";
import { PAYMENT_REMINDER } from "../constants/payment-reminder";
import {
  PAYMENT_REMINDER_SCREEN_KEY,
  VERIFY_PAYMENT_REMINDER,
} from "../constants/storage";
import { useMe } from "../hooks/useMe";
import { isWeb } from "../util/platform";

export function PaymentReminderScreen() {
  const { navigate } = useNavigation();
  const { data: user } = useMe();
  const [paymentFee, setPaymentFee] = useState<number>(3.99);

  useEffect(() => {
    (async () => {
      if (user) {
        const paymentFee =
          [AccountType.student, AccountType.unidays].includes(
            user.accountType
          ) === true
            ? 2.99
            : 3.99;
        setPaymentFee(paymentFee);
      }
    })();
  }, [user]);

  async function handleNextPress() {
    await AsyncStorage.removeItem(PAYMENT_REMINDER_SCREEN_KEY);
    await AsyncStorage.setItem(VERIFY_PAYMENT_REMINDER, "viewed");
    navigate(isWeb() ? "Home" : "App");
  }
  return (
    <AuthContent>
      <AuthCard label="Payment Reminder">
        <VStack space={6}>
          <VStack space={6} px={2} mt={-4} mb={4} alignItems="center">
            {PAYMENT_REMINDER(paymentFee).map((text, index) => (
              <Text
                key={index.toString()}
                fontSize={14}
                fontWeight={500}
                textAlign="center"
              >
                {text}
              </Text>
            ))}
          </VStack>
          <VStack space={2}>
            <AuthButton
              onPress={() => handleNextPress()}
              _text={{ fontSize: 18 }}
              variant="solid"
            >
              Next
            </AuthButton>
          </VStack>
        </VStack>
      </AuthCard>
    </AuthContent>
  );
}
