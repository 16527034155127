import { useQueryClient, useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

type IOrganisationUserChangeRole = Record<string, string>;

export function useOrganisationUserChangeRole() {
  const queryClient = useQueryClient();

  return useMutation<
    IApiResponse<IOrganisationUserChangeRole>,
    IApiResponse<IOrganisationUserChangeRole>,
    IOrganisationUserChangeRole
  >(
    ["organisations-change-user-role"],
    async (data: IOrganisationUserChangeRole) => {
      const response = await api.put<IApiResponse<IOrganisationUserChangeRole>>(
        "organisations/change-user-role",
        {
          userId: data.userId,
          organisationRole: data.organisationRole,
        }
      );

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["organisation", "all-users"]);
      },
    }
  );
}
