import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { AlertLevel } from "api/src/signposting/enum/AlertLevel";
import { SubscriptionStatus } from "api/src/subscription/enum/SubscriptionStatus";
import { AccountType } from "api/src/users/enum/account-type";
import dayjs from "dayjs";
import * as Notifications from "expo-notifications";
import { useColorModeValue, useToken } from "native-base";
import React, { memo, useEffect } from "react";

import { WellDoneScreen } from "../components/wellbeing-checker/WellDoneScreen";
import {
  PAYMENT_REMINDER_SCREEN_KEY,
  VERIFY_PAYMENT_REMINDER,
} from "../constants/storage";
import { useActiveSubscription } from "../hooks/useActiveSubscription";
import { useFlaggedUser } from "../hooks/useFlaggedUser";
import { useMe } from "../hooks/useMe";
import { AddHabitScreen } from "../screens/AddHabitScreen";
import { AddSecondaryEmail } from "../screens/AddSecondaryEmail";
import { AffirmationScreen } from "../screens/AffirmationScreen";
import { AppInfoScreen } from "../screens/AppInfoScreen";
import { CompleteRegistrationScreen } from "../screens/auth/CompleteRegistrationScreen";
import { BreathingExercisesScreen } from "../screens/BreathingExercisesScreen";
import { ChangeEmailScreen } from "../screens/ChangeEmailScreen";
import { ChangePasswordScreen } from "../screens/ChangePasswordScreen";
import { ChannelsDetailsScreen } from "../screens/ChannelScreen";
import { ContentScreen } from "../screens/ContentScreen";
import { CopingStrategiesScreen } from "../screens/CopingStrategiesScreen";
import { DefaultHabitScreen } from "../screens/DefaultHabitScreen";
import { DeleteAccountScreen } from "../screens/DeleteAccountScreen";
import { EmergencyContactsScreen } from "../screens/EmergencyContactsScreen";
import { GetGroundedScreen } from "../screens/GetGroundedScreen";
import { GetGroundedSuccessScreen } from "../screens/GetGroundedSuccessScreen";
import { HabitDetailsScreen } from "../screens/HabitDetailsScreen";
import { HomeScreen } from "../screens/HomeScreen";
import { InsightsScreen } from "../screens/InsightsScreen";
import { AddDiaryEntryScreen } from "../screens/journal/AddDiaryEntryScreen";
import { AddFiveMinuteEntryScreen } from "../screens/journal/AddFiveMinuteEntryScreen";
import { AddGratitudeEntryScreen } from "../screens/journal/AddGratitudeEntryScreen";
import { AddIAmGreatEntryScreen } from "../screens/journal/AddIAmGreatEntryScreen";
import { DiaryDetailsScreen } from "../screens/journal/DiaryDetailsScreen";
import { FiveMinuteDetailsScreen } from "../screens/journal/FiveMinuteDetailsScreen";
import { GratitudeDetailsScreen } from "../screens/journal/GratitudeDetailsScreen";
import { IAmGreatDetailsScreen } from "../screens/journal/IAmGreatDetailsScreen";
import { JournalScreen } from "../screens/journal/JournalScreen";
import { JournalTypeScreen } from "../screens/journal/JournalTypeScreen";
import { MyMediaLibraryScreen } from "../screens/MyMediaLibraryScreen";
import { NotificationScreen } from "../screens/NotificationScreen";
import { NotificationsScreen } from "../screens/NotificationsScreen";
import { PaymentReminderScreen } from "../screens/PaymentReminderScreen";
import { PrivacyAndPolicyScreen } from "../screens/PrivacyAndPolicyScreen";
import { SignpostingScreen } from "../screens/signposting/SignpostingScreen";
import { SubChannelsScreen } from "../screens/SubChannelsScreen";
import { SubscriptionScreen } from "../screens/Subscription";
import { TermsAndConditonsScreen } from "../screens/TermsAndConditionsScreen";
import { TrackFeelingsScreen } from "../screens/TrackFeelingsScreen";
import { TrackHabitsScreen } from "../screens/TrackHabitsScreen";
import { TrackMoodScreen } from "../screens/TrackMoodScreen";
import { WellbeingCheckerScreen } from "../screens/WellbeingCheckerScreen";
import { isWeb } from "../util/platform";

import { AppNavigator } from "./AppNavigator";
import { DigestingScreen } from "./DigestingScreen";
import { MyPageNavigator } from "./MyPageNavigator";
import { OrganisationNavigator } from "./organisations/OrganisationNavigator";
import { ReviewContentNavigator } from "./ReviewContentNavigator";

const Main = createStackNavigator();

export const MainNavigator = memo(() => {
  const { navigate } = useNavigation();
  const { data: user, error, isLoading: isMeLoading } = useMe();
  const { data: flaggedUser } = useFlaggedUser();

  const { data: activeSubscription, isLoading: isActiveSubscriptionLoading } =
    useActiveSubscription();

  const lastNotificationResponse = Notifications.useLastNotificationResponse();

  useEffect(() => {
    if (
      lastNotificationResponse &&
      lastNotificationResponse.notification.request.content.data.habit_id &&
      lastNotificationResponse.actionIdentifier ===
        Notifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      const habitId =
        lastNotificationResponse.notification.request.content.data?.habit_id;
      return navigate("HabitDetails", { habitId: habitId as string });
    }
  }, [lastNotificationResponse]);

  // Checking if the user is logged in on Web then redirecting to the login page
  useEffect(() => {
    (async () => {
      if (isActiveSubscriptionLoading || isMeLoading) {
        return;
      }

      const isLoggedIn =
        error !== 401 && (await AsyncStorage.getItem("access_token"));
      if (isWeb() && !isLoggedIn) {
        navigate("Auth", { screen: "Login" });
        return;
      }

      if (
        isWeb() &&
        isLoggedIn &&
        !user?.hasOnboarded &&
        !window.location.pathname.includes("onboarding")
      ) {
        navigate("Auth", { screen: "Onboarding" });
        return;
      }

      if (
        isLoggedIn &&
        user?.hasOnboarded &&
        !user?.firstLogin &&
        user?.loginCount === 1 &&
        !window?.location?.pathname?.includes("first-login")
      ) {
        navigate("Auth", { screen: "FirstLogin" });
        return;
      }

      if (
        isLoggedIn &&
        user?.hasOnboarded &&
        user?.firstLogin &&
        !user?.secondLogin &&
        user?.loginCount === 2 &&
        !window?.location?.pathname?.includes("second-login")
      ) {
        navigate("Auth", { screen: "SecondLogin" });
        return;
      }

      const verifyPaymentReminder = await AsyncStorage.getItem(
        VERIFY_PAYMENT_REMINDER
      );
      if (
        verifyPaymentReminder !== "viewed" &&
        !activeSubscription?.isFree &&
        activeSubscription?.status === SubscriptionStatus.trialing &&
        dayjs(activeSubscription?.periodEnd).diff(dayjs(), "days") <= 3 &&
        dayjs(activeSubscription?.periodEnd).diff(dayjs(), "days") >= 0
      ) {
        await AsyncStorage.setItem(PAYMENT_REMINDER_SCREEN_KEY, "show");
      }

      if (
        !user?.secondaryEmail &&
        (user?.accountType === AccountType.unidays ||
          user?.accountType === AccountType.corporate)
      ) {
        navigate("AddSecondaryEmail");
        return;
      }

      if (activeSubscription?.isFree && isWeb()) {
        if (!window.location.pathname.includes("main/subscription")) {
          navigate("SubscriptionMain");
        }
      }

      if (flaggedUser && user?.hasOnboarded) {
        if (!flaggedUser.hasAnswered) {
          navigate("Signposting", { alertLevel: flaggedUser?.alertLevel });
          return;
        }

        if (
          flaggedUser.alertLevel === AlertLevel.medium &&
          dayjs().diff(flaggedUser.createdAt, "day") > 2
        ) {
          navigate("Signposting", {
            alertLevel: flaggedUser?.alertLevel,
            index: 1,
          });
        }
      }
    })();
  }, [
    user,
    activeSubscription,
    error,
    flaggedUser,
    isMeLoading,
    isActiveSubscriptionLoading,
  ]);

  const [dark100, dark500] = useToken("colors", ["dark.100", "dark.500"]);
  const backgroundColor = useColorModeValue(
    "white",
    isWeb() ? dark500 : dark100
  );

  return (
    <>
      <Main.Navigator
        initialRouteName={isWeb() ? "Home" : "App"}
        screenOptions={{
          headerShown: false,
          cardStyle: { backgroundColor },
        }}
      >
        <Main.Screen name="WellDone" component={WellDoneScreen} />
        <Main.Screen name="AppInfo" component={AppInfoScreen} />
        <Main.Screen name="PaymentReminder" component={PaymentReminderScreen} />
        <Main.Screen name="App" component={AppNavigator} />
        <Main.Screen
          name="Home"
          component={HomeScreen}
          options={{ title: "Home - Miindset" }}
        />
        <Main.Screen
          name="Notifications"
          component={NotificationsScreen}
          options={{ title: "Notifications - Miindset" }}
        />
        <Main.Screen
          name="Notification"
          component={NotificationScreen}
          options={{ title: "Notification - Miindset" }}
        />
        <Main.Screen
          name="WellbeingChecker"
          options={{ animationEnabled: false, title: "Wellbeing - Miindset" }}
          component={WellbeingCheckerScreen}
        />
        <Main.Screen
          name="SubscriptionMain"
          component={SubscriptionScreen}
          options={{ title: "Subscription - Miindset" }}
        />
        <Main.Screen
          name="TermsAndConditions"
          component={TermsAndConditonsScreen}
          options={{ title: "TermsAndConditions - Miindset" }}
        />
        <Main.Screen
          name="PrivacyAndPolicy"
          component={PrivacyAndPolicyScreen}
          options={{ title: "PrivacyAndPolicy - Miindset" }}
        />
        <Main.Screen
          name="AddSecondaryEmail"
          component={AddSecondaryEmail}
          options={{ title: "Add Secondary Email - Miindset" }}
        />
        <Main.Screen name="Organisation" component={OrganisationNavigator} />
        <Main.Screen
          name="Digesting"
          component={DigestingScreen}
          options={{ title: "Digesting - Miindset" }}
        />
        <Main.Screen name="MyPage" component={MyPageNavigator} />
        <Main.Screen
          name="ReviewContents"
          component={ReviewContentNavigator}
          options={{ title: "Review Content - Miindset" }}
        />
        <Main.Screen
          name="ContentDetails"
          component={ContentScreen}
          options={{ title: "Content Details - Miindset" }}
        />
        <Main.Screen
          name="ChannelDetails"
          component={ChannelsDetailsScreen}
          options={{ title: "Channel Details - Miindset" }}
        />
        <Main.Screen
          name="TrackMood"
          component={TrackMoodScreen}
          options={{ title: "Track Mood - Miindset" }}
        />
        <Main.Screen
          name="TrackFeelings"
          component={TrackFeelingsScreen}
          options={{ title: "Track Feelings - Miindset" }}
        />
        <Main.Screen
          name="TrackHabits"
          component={TrackHabitsScreen}
          options={{ title: "Track Habits - Miindset" }}
        />
        <Main.Screen
          name="AddHabit"
          component={AddHabitScreen}
          options={{ title: "Add Habit - Miindset" }}
        />
        <Main.Screen
          name="DefaultHabit"
          component={DefaultHabitScreen}
          options={{ title: "Habits - Miindset" }}
        />
        <Main.Screen
          name="HabitDetails"
          component={HabitDetailsScreen}
          options={{ title: "Habit Details - Miindset" }}
        />
        <Main.Screen
          name="Insights"
          component={InsightsScreen}
          options={{ title: "Insights - Miindset" }}
        />
        <Main.Screen
          name="MyJournal"
          component={JournalScreen}
          options={{ title: "My Journal - Miindset" }}
        />
        <Main.Screen
          name="MyJournalType"
          component={JournalTypeScreen}
          options={{ title: "My Journal Type - Miindset" }}
        />
        <Main.Screen
          name="AddDiaryEntry"
          component={AddDiaryEntryScreen}
          options={{ title: "Diary - Miindset" }}
        />
        <Main.Screen
          name="DiaryDetails"
          component={DiaryDetailsScreen}
          options={{ title: "Diary Details - Miindset" }}
        />
        <Main.Screen
          name="AddGratitudeEntry"
          component={AddGratitudeEntryScreen}
          options={{ title: "Add Gratitude - Miindset" }}
        />
        <Main.Screen
          name="GratitudeDetails"
          component={GratitudeDetailsScreen}
          options={{ title: "Gratitude Details - Miindset" }}
        />
        <Main.Screen
          name="AddIAmGreatEntry"
          options={{ title: "Add IAm Great - Miindset" }}
          component={AddIAmGreatEntryScreen}
        />
        <Main.Screen name="IAmGreatDetails" component={IAmGreatDetailsScreen} />
        <Main.Screen
          name="AddFiveMinuteEntry"
          component={AddFiveMinuteEntryScreen}
          options={{ title: "Add Five Minute Entry - Miindset" }}
        />
        <Main.Screen
          name="FiveMinuteDetails"
          component={FiveMinuteDetailsScreen}
          options={{ title: "Five Minute Details - Miindset" }}
        />
        <Main.Screen
          name="CopingStrategies"
          component={CopingStrategiesScreen}
          options={{ title: "Coping Strategies - Miindset" }}
        />
        <Main.Screen
          name="BreathingExercises"
          component={BreathingExercisesScreen}
          options={{ title: "Breathing Exercises - Miindset" }}
        />
        <Main.Screen
          name="GetGrounded"
          component={GetGroundedScreen}
          options={{ title: "Get Grounded - Miindset" }}
        />
        <Main.Screen
          name="GetGroundedSuccess"
          options={{ title: "Grounded Success - Miindset" }}
          component={GetGroundedSuccessScreen}
        />
        <Main.Screen
          name="DeleteAccount"
          component={DeleteAccountScreen}
          options={{ title: "Delete Account - Miindset" }}
        />
        <Main.Screen
          name="EmergencyContacts"
          component={EmergencyContactsScreen}
          options={{ title: "Emergency Contacts - Miindset" }}
        />
        <Main.Screen
          name="Affirmation"
          component={AffirmationScreen}
          options={{ title: "Affirmation - Miindset" }}
        />

        <Main.Screen
          name="ChangeEmail"
          component={ChangeEmailScreen}
          options={{ title: "Change Email - Miindset" }}
        />

        <Main.Screen
          name="ChangePassword"
          component={ChangePasswordScreen}
          options={{ title: "Change Password  - Miindset" }}
        />
        <Main.Screen
          name="CompleteRegistration"
          component={CompleteRegistrationScreen}
          options={{ title: "Complete Registration - Miindset" }}
        />
        <Main.Screen
          name="SubChannels"
          component={SubChannelsScreen}
          options={{ title: "Sub-Channels - Miindset" }}
        />
        <Main.Screen
          name="MyMediaLibrary"
          component={MyMediaLibraryScreen}
          options={{ title: "My Media Library - Miindset" }}
        />
        <Main.Screen name="Signposting" component={SignpostingScreen} />
      </Main.Navigator>
    </>
  );
});
