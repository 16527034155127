import { useNavigation, useRoute } from "@react-navigation/core";
import { IChannel, ISubChannel } from "api/src/channels/interface/IChannel";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { ContentType } from "api/src/content/enum/ContentType";
import { first } from "lodash";
import {
  Box,
  HStack,
  Image,
  Text,
  VStack,
  Divider,
  Pressable,
} from "native-base";
import React, { useEffect, useState } from "react";
import Dropdown, { Option } from "react-dropdown";
import "react-dropdown/style.css";

import { useAddViewedContent } from "../../hooks/useAddViewedContent";
import { ChannelDetailsRouteProp } from "../../navigation/ChannelStackParamList";
import { openUrl } from "../../util/linking";

interface IContentBannerProps {
  contentData: ContentEntity;
  title?: string;
  channel?: IChannel;
  subChannel?: ISubChannel;
  handleSubChannel?: (subChannel?: ISubChannel) => void;
  imageHeight?: number;
  imageWidth?: number;
}

export function ContentBanner({
  contentData,
  title = "Your Daily Digest",
  channel,
  subChannel,
  handleSubChannel,
  imageHeight = 250,
  imageWidth = 450,
}: IContentBannerProps) {
  const { navigate } = useNavigation();
  const { params } = useRoute<ChannelDetailsRouteProp>();
  const { mutate: viewContent } = useAddViewedContent();
  const firstChannel = first(contentData.channels);

  const [selectedSubChannel, setSelectedSubChannel] = useState<string>(
    subChannel?.slug ?? ""
  );

  useEffect(() => {
    handleSubChannel?.(subChannel);
    setSelectedSubChannel(subChannel?.slug ?? "");
  }, [subChannel, channel]);

  const handleChange = (ChannelDetails: Option) => {
    const getSelectedSubChannel = channel?.subChannels?.find(
      (channel) => ChannelDetails?.value === channel.slug
    );
    handleSubChannel?.(getSelectedSubChannel);

    setSelectedSubChannel(ChannelDetails?.value);
  };

  function handlePress() {
    if (!contentData?.id) {
      return;
    }
    if (
      contentData?.url &&
      (contentData?.type === ContentType.podcast ||
        contentData?.type === ContentType.video)
    ) {
      viewContent({ contentId: contentData?.id });
      openUrl(contentData?.url);
      return;
    }
    navigate("ContentDetails", { contentId: contentData?.id });
  }

  const handleChannelPress = () => {
    if (firstChannel !== undefined) {
      navigate("ChannelDetails", firstChannel);
    }
  };

  return (
    <Box
      flex="1"
      bg="white"
      borderRadius={5}
      key={contentData.id}
      _dark={{ bg: "dark.100" }}
    >
      <HStack space={[3, 3, 20]} alignItems="flex-start">
        <VStack alignItems="flex-start" flex={1} space={[1, 1, 2, 2, 2]}>
          {channel?.subChannels?.length ? (
            <Dropdown
              options={channel?.subChannels?.map((channel) => ({
                label: channel.label,
                value: channel.slug,
              }))}
              onChange={handleChange}
              value={selectedSubChannel}
            />
          ) : (
            <Text fontSize={28} textAlign="left" fontWeight="600">
              {title}
            </Text>
          )}

          <Pressable onPress={handlePress}>
            <Text
              mt={2}
              fontSize={24}
              textAlign="left"
              fontWeight={500}
              color="primary.400"
            >
              {contentData?.title}
            </Text>
          </Pressable>
          <Divider />
          <Text fontSize={[10, 12, 14, 16, 15]} textAlign="justify">
            {contentData?.abstract}
          </Text>

          <Pressable onPress={handleChannelPress}>
            <Text
              fontSize={[10, 10, 12, 14, 15]}
              textAlign="center"
              color="primary.400"
              fontWeight="600"
              cursor={params?.slug ? "auto" : "pointer"}
            >
              {firstChannel?.label}
            </Text>
          </Pressable>
        </VStack>
        {contentData && (
          <Pressable onPress={handlePress}>
            <Image
              width={imageWidth}
              height={imageHeight}
              source={{
                uri: `https://res.cloudinary.com/tlero/image/upload/${contentData?.image}`,
              }}
              alt="The Concept Of Sacred Spaces"
              borderRadius={5}
            />
          </Pressable>
        )}
      </HStack>
    </Box>
  );
}
