import LottieView from "lottie-react-native";
import React, { useEffect, useRef } from "react";

interface ILoadingAnimationProps {
  isLoading?: boolean;
  size?: number;
  autoPlay?: boolean;
}

const FRAME_POINTS: number[] = [3, 60];
export function LoadingAnimation({
  isLoading,
  size,
  autoPlay = false,
}: ILoadingAnimationProps) {
  const animationRef = useRef<LottieView>(null);
  let shouldReverse: boolean = false;

  const handleAnimationFinish = () => {
    const [startFrame, endFrame] = shouldReverse
      ? [...FRAME_POINTS].reverse()
      : FRAME_POINTS;
    animationRef?.current?.play(startFrame, endFrame);
    shouldReverse = !shouldReverse;
  };

  useEffect(() => {
    animationRef?.current?.play(0, 60);

    return () => {
      animationRef?.current?.reset();
    };
  }, []);

  if (!isLoading) {
    return null;
  }

  return (
    <LottieView
      source={require("../../assets/animations/loading.json")}
      speed={0.75}
      ref={animationRef}
      style={{ width: size, height: size }}
      autoPlay={autoPlay}
      loop={false}
      onAnimationFinish={() => {
        handleAnimationFinish();
      }}
    />
  );
}
