import { DigestType } from "api/src/wellbeing/enum/DigestType";

import { HeadphonesIcon } from "../components/icons/HeadphonesIcon";
import { OpenBookIcon } from "../components/icons/OpenBookIcon";
import { VideoCameraIcon } from "../components/icons/VideoCameraIcon";
import { IDigestType } from "../interfaces/IDigestType";

export const DIGEST_TYPES: IDigestType[] = [
  {
    label: "Read",
    type: DigestType.read,
    icon: OpenBookIcon,
  },
  {
    label: "Watch",
    type: DigestType.watch,
    icon: VideoCameraIcon,
  },

  {
    label: "Listen",
    type: DigestType.listen,
    icon: HeadphonesIcon,
  },
];
