import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationLocationResilience(filters: {
  location: boolean;
  keyword: string;
}) {
  return useQuery(
    ["organisations", "location-resilience", filters],
    async () => {
      const response = await api.post("organisations/location-resilience", {
        ...filters,
      });

      if (response.status === 201) {
        return response.data;
      }

      return Promise.reject("Could not get location resilience average");
    },
    { keepPreviousData: true }
  );
}
