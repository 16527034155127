import { ContentStatus } from "api/src/content/enum/ContentStatus";
import dayjs from "dayjs";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect } from "react";

import { useDeleteContent } from "../../hooks/useDeleteContent";
import { useSetContentState } from "../../hooks/useSetContentState";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { IContentVariantProps } from "../content/Content";
import { ContentHorizontal } from "../content/ContentHorizontal";
import { Button } from "../elements/Button";
import { Tag } from "../elements/Tag";

interface IReviewableContentProps extends IContentVariantProps {
  onView?: () => void;
}
export function ReviewableContent({ item, onView }: IReviewableContentProps) {
  const {
    mutate: approvePost,
    isLoading: isApproveLoading,
    isError,
    error,
  } = useSetContentState(item.id);
  const toast = useToast();
  const { mutate: rejectPost, isLoading: isRejectLoading } = useSetContentState(
    item.id,
    true
  );

  const { mutate: deleteContent, isLoading: isDeleteLoading } =
    useDeleteContent(item.id);

  function handleDelete() {
    deleteContent(
      { contentId: item.id },
      {
        onSuccess: () => {
          toast({ title: "Content Deleted!", status: ToastStatus.success });
        },
        onError: () => {
          toast({
            title: "Could not delete content",
            status: ToastStatus.error,
          });
        },
      }
    );
  }

  useEffect(() => {
    if (isError) {
      toast({ title: error.errors.message, status: ToastStatus.error });
    }
  }, [isError]);

  return (
    <Box mb={4} pb={2} mt={4} display="block" borderRadius={5}>
      <VStack space={2}>
        <ContentHorizontal item={item} hasSave={false} />
        <VStack space={2} px={2}>
          <VStack space={1}>
            <Box flexDirection="row" flexWrap="wrap">
              <Tag label="Sentiment" value={item.sentiment} />
              <Tag label="Characters" value={item.characterCount} />
              <Tag
                label="Stress"
                value={`${Math.round(
                  item.stressTargetLower ?? 0
                )} - ${Math.round(item.stressTargetUpper ?? 0)}`}
              />
              <Tag
                label="Anxiety"
                value={`${Math.round(
                  item.anxietyTargetLower ?? 0
                )} - ${Math.round(item.anxietyTargetUpper ?? 0)}`}
              />
              <Tag
                label="Depression"
                value={`${Math.round(
                  item.depressionTargetLower ?? 0
                )} - ${Math.round(item.depressionTargetUpper ?? 0)}`}
              />
            </Box>
          </VStack>
          <HStack space={1}>
            <Button borderRadius={5} p={2} px={3} onPress={onView}>
              <Text color="white" fontSize={12}>
                View / Edit
              </Text>
            </Button>
            {item.status !== ContentStatus.live ? (
              <Button
                p={2}
                px={3}
                bg="green.500"
                borderRadius={5}
                onPress={() => approvePost()}
                isLoading={isApproveLoading}
                _pressed={{ bg: "green.600" }}
              >
                <Text color="white" fontSize={12}>
                  Approve
                </Text>
              </Button>
            ) : null}
            {item.status !== ContentStatus.rejected ? (
              <Button
                p={2}
                px={3}
                bg="red.500"
                onPress={() => rejectPost()}
                isLoading={isRejectLoading}
                borderRadius={5}
                _pressed={{ bg: "red.600" }}
              >
                <Text color="white" fontSize={12}>
                  Reject
                </Text>
              </Button>
            ) : null}
            {item.status === ContentStatus.rejected ? (
              <Button
                p={2}
                px={3}
                bg="red.500"
                onPress={handleDelete}
                isLoading={isDeleteLoading}
                borderRadius={5}
                _pressed={{ bg: "red.800" }}
              >
                <Text color="white" fontSize={12}>
                  Delete
                </Text>
              </Button>
            ) : null}
            <Box p={2} px={3}>
              <Text fontSize={16}>
                <Text fontWeight="bold">Status:</Text> {item.status}
              </Text>
            </Box>
            <Box p={2} px={3}>
              <Text fontSize={16}>
                <Text fontWeight="bold">Date Added:</Text>{" "}
                {dayjs(item.createdAt).format("DD MMMM YYYY")}
              </Text>
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
}
