import { useNavigation } from "@react-navigation/core";
import { IChannel } from "api/src/channels/interface/IChannel";
import { Image, Pressable, Text, VStack } from "native-base";
import React from "react";

import { DEFAULT_CHANNEL_IMAGE } from "../constants/default-channel-image";
import { ChannelType } from "../types/channel-type";
import { getChannelImage, getSubChannelImage } from "../util/channel";
import { getImage } from "../util/image";

interface IChannelBubbleProps {
  item: IChannel;
  type?: ChannelType;
  badge?: string;
  defaultChannel?: IChannel;
}
export function ChannelBubble({
  item,
  type = "default",
  badge,
  defaultChannel,
}: IChannelBubbleProps) {
  const { navigate } = useNavigation();
  const isSubChannel = type === "subchannel";

  const handleNavigation = (item: IChannel) => {
    if (item.subChannels?.length) {
      return navigate("SubChannels", {
        slug: item.slug,
        label: item.label,
        type,
      });
    }
    return navigate("ChannelDetails", {
      slug: isSubChannel ? defaultChannel?.slug || "" : item.slug,
      label: isSubChannel ? defaultChannel?.label || "" : item.label,
      type,
      subChannelLabel: isSubChannel ? item.label : undefined,
      subChannelSlug: isSubChannel ? item.slug : undefined,
    });
  };

  const image =
    type === "subchannel" && defaultChannel
      ? getSubChannelImage(defaultChannel, item)
      : getChannelImage(item);

  return (
    <VStack>
      <Pressable
        p="2"
        mb="6"
        pb="1"
        borderRadius={5}
        _pressed={{ opacity: 0.7 }}
        onPress={() => handleNavigation(item)}
      >
        <VStack space={1} flex={1} alignItems="center">
          {badge ? (
            <Image
              source={{ uri: getImage(badge) }}
              size="sm"
              alt="logo"
              borderRadius={100}
              fallbackSource={{
                uri: getImage(DEFAULT_CHANNEL_IMAGE),
              }}
            />
          ) : (
            <Image source={image} size="sm" alt="logo" borderRadius={100} />
          )}

          <Text mt="2" fontSize="12" fontWeight={400} textAlign="center">
            {item?.label}
          </Text>
        </VStack>
      </Pressable>
    </VStack>
  );
}
