import { HabitEntity } from "api/src/habit/entities/habit.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useHabit(habitId: string | undefined) {
  return useQuery<HabitEntity>(
    ["habit", habitId],
    async () => {
      const response = await api.get<HabitEntity>(`habit/${habitId}`, {});

      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Couldn't fetch habit");
    },
    {
      keepPreviousData: true,
      enabled: !!habitId,
    }
  );
}
