import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { OrganisationSettings } from "../../components/organisations/settings/OrganisationSettings.web";
import { useBackgroundColor } from "../../util/theme-helper";

const Organisation = createStackNavigator();

export function OrganisationSettingsNavigator() {
  const { backgroundColorWeb } = useBackgroundColor();

  return (
    <Organisation.Navigator
      initialRouteName="Settings"
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor: backgroundColorWeb },
      }}
    >
      <Organisation.Screen
        name="Settings"
        component={OrganisationSettings}
        options={{
          title: "Organisation Settings - Miindset",
        }}
      />
    </Organisation.Navigator>
  );
}
