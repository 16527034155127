import {
  VStack,
  Box,
  HStack,
  Text,
  Pressable,
  useColorModeValue,
} from "native-base";
import React from "react";

import { ICriticalIndicatorDataset } from "./CriticalIndicatorsChart.web";

interface ICriticalIndicatorLegendProps {
  activeSelection: string[];
  handleActiveSelection: (item: ICriticalIndicatorDataset) => void;
  data: ICriticalIndicatorDataset[];
}

export function CriticalIndicatorsLegend({
  activeSelection,
  handleActiveSelection,
  data,
}: ICriticalIndicatorLegendProps) {
  const backgroundColor = useColorModeValue("secondary.50", "dark.200");
  return (
    <VStack>
      {data.map((item, index) => (
        <Pressable onPress={() => handleActiveSelection(item)} key={index}>
          <HStack
            padding={1.5}
            maxW={150}
            backgroundColor={
              activeSelection.find((selection) => selection === item.label)
                ? backgroundColor
                : undefined
            }
            marginTop={2}
            borderRadius={50}
            // @ts-ignore: NativeBase bug missing prop
            cursor="pointer"
          >
            <Box
              width={17}
              height={17}
              borderRadius={40}
              borderColor={item.borderColor}
              borderWidth={1}
              bgColor={item.backgroundColor}
            />
            <Text fontSize={14} fontWeight={400} marginLeft={2}>
              {item.label}
            </Text>
          </HStack>
        </Pressable>
      ))}
    </VStack>
  );
}
