import { Channel } from "api/src/channels/enum/Channel";
import { ISubChannel } from "api/src/channels/interface/IChannel";
import { Text, Image, HStack, Flex, Link } from "native-base";
import React from "react";

import { DEFAULT_CHANNEL_IMAGE } from "../constants/default-channel-image";
import { useChannels } from "../hooks/useChannels";
import { useMe } from "../hooks/useMe";
import { ChannelType } from "../types/channel-type";
import { getChannelImage } from "../util/channel";
import { getImage } from "../util/image";

export function ChannelsMenu({
  handleChannelsMenu,
}: {
  handleChannelsMenu: (
    slug: Channel | string,
    label: string,
    type?: ChannelType | undefined,
    subChannel?: ISubChannel | undefined,
    hasSubChannels?: boolean
  ) => void;
}) {
  const { data } = useChannels();
  const { data: user } = useMe();

  return (
    <Flex flex={1} flexDirection="row" flexWrap="wrap" width={800}>
      {user?.organisationUser ? (
        <Link
          p={2}
          flexBasis="33%"
          _hover={{ bg: "secondary.200" }}
          _dark={{
            _hover: {
              bg: "dark.200",
            },
          }}
          onPress={() => {
            handleChannelsMenu(
              user?.organisationUser.organisation.name,
              user?.organisationUser.organisation.name,
              "organisation"
            );
          }}
        >
          <HStack alignItems="center" space={4}>
            <Image
              source={{
                uri: getImage(
                  user?.organisationUser.organisation.badge ||
                    DEFAULT_CHANNEL_IMAGE
                ),
              }}
              size={10}
              alt={user?.organisationUser.organisation.name}
              fallbackSource={{
                uri: getImage(DEFAULT_CHANNEL_IMAGE),
              }}
            />

            <Text
              fontSize={14}
              fontWeight={500}
              color="secondary.750"
              _dark={{ color: "secondary.400" }}
            >
              {user?.organisationUser.organisation.name}
            </Text>
          </HStack>
        </Link>
      ) : null}

      {data?.map((channel, index) => {
        const image = getChannelImage(channel);

        return (
          <Link
            p={2}
            flexBasis="33%"
            _hover={{ bg: "secondary.200" }}
            _dark={{
              _hover: {
                bg: "dark.200",
              },
            }}
            onPress={() => {
              if (channel?.subChannels?.length) {
                return handleChannelsMenu(
                  channel.slug,
                  channel.label,
                  "default",
                  undefined,
                  true
                );
              }

              handleChannelsMenu(channel.slug, channel.label, "default");
            }}
            key={index}
          >
            <HStack alignItems="center" space={4}>
              <Image
                source={image}
                size={10}
                alt={channel.label}
                fallbackSource={{
                  uri: getImage(DEFAULT_CHANNEL_IMAGE),
                }}
              />

              <Text
                fontSize={14}
                fontWeight={500}
                color="secondary.750"
                maxWidth={180}
                isTruncated={true}
                _dark={{ color: "secondary.400" }}
              >
                {channel.label}
              </Text>
            </HStack>
          </Link>
        );
      })}
    </Flex>
  );
}
