import { Icon } from "native-base";
import React from "react";
import { Path, Rect, Svg } from "react-native-svg";

import { IIconProps } from "../../../interfaces/IIconprops";
import { useMoodIconColors } from "../../../util/theme-helper";

export function NeutralMoodIcon({ ...props }: IIconProps) {
  const { faceFillColor, faceStrokeColor, eyesFillColor, mouthFillColor } =
    useMoodIconColors();

  return (
    <Icon viewBox="0 0 261 260" {...props}>
      <Svg width={261} height={260} fill="none">
        <Path
          d="M130.649 258.2c70.848 0 128.28-57.465 128.28-128.35 0-70.885-57.432-128.35-128.28-128.35S2.369 58.965 2.369 129.85c0 70.885 57.432 128.35 128.28 128.35Z"
          fill={faceFillColor}
          stroke={faceStrokeColor}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M102.613 106c0 11.046-8.644 20-19.306 20C72.644 126 64 117.046 64 106s8.644-20 19.306-20c10.663 0 19.307 8.954 19.307 20ZM197 106c0 11.046-8.644 20-19.306 20-10.663 0-19.307-8.954-19.307-20s8.644-20 19.307-20C188.356 86 197 94.954 197 106Z"
          fill={eyesFillColor}
        />
        <Rect
          x={96.5}
          y={179.5}
          width={68}
          height={18}
          rx={9}
          fill={mouthFillColor}
        />
      </Svg>
    </Icon>
  );
}
