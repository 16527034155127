import { ContentTagEntity } from "api/src/content/entity/content-tag.entity";

/**
 * Returns an array of strings containing the tag names of a given array of ContentTagEntity objects.
 * @param tags - An array of ContentTagEntity objects.
 * @returns An array of strings containing the tag names of the input ContentTagEntity objects.
 */
export function getContentTags(tags: ContentTagEntity[]) {
  const allTags: string[] = [];

  tags.forEach((tag) => {
    allTags.push(tag.tag.tagName);
  });
  return allTags;
}
