import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { ChannelsScreen } from "../screens/ChannelsScreen";

import { ChannelStackParamList } from "./ChannelStackParamList";

const Channel = createStackNavigator<ChannelStackParamList>();

export function ChannelNavigator() {
  return (
    <Channel.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="ChannelsScreen"
    >
      <Channel.Screen name="ChannelsScreen" component={ChannelsScreen} />
    </Channel.Navigator>
  );
}
