import { Icon, IIconProps } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

export function MenuIcon(props: IIconProps) {
  return (
    <Icon viewBox="0 0 30 34" {...props}>
      <Svg fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 17h15-15Z"
          fill="#fff"
        />
        <Path
          d="M11 17h15"
          stroke="#fff"
          strokeWidth={1.667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9h8-8Z"
          fill="#fff"
        />
        <Path
          d="M18 9h8"
          stroke="#fff"
          strokeWidth={1.667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 25.5h22.5-22.5Z"
          fill="#fff"
        />
        <Path
          d="M3.75 25.5h22.5"
          stroke="#fff"
          strokeWidth={1.667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
