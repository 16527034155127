import {
  View,
  Text,
  Heading,
  HStack,
  VStack,
  Pressable,
  Link,
} from "native-base";
import React from "react";
import { Linking } from "react-native";

import { ToastStatus, useToast } from "../../hooks/useToast";
import { isWeb } from "../../util/platform";
import { CallIcon } from "../icons/CallIcon";
import { FamilyAndRelationshipIcon } from "../icons/FamilyIcon";
import { NetworkIcon } from "../icons/NetworkIcon";

export function FamilySupport() {
  const toast = useToast();

  const handleContactClick = (phoneNumber: string) => {
    const url = `tel:${phoneNumber}`;
    if (isWeb()) {
      return;
    }
    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          toast({
            title: "Can't handle this contact",
            status: ToastStatus.warning,
          });
        } else {
          return Linking.openURL(url);
        }
      })
      .catch((err) =>
        toast({
          title: `An error occurred: ${err}`,
          status: ToastStatus.error,
        })
      );
  };

  return (
    <View mb={10} mt={50} alignItems="center">
      {!isWeb() ? <FamilyAndRelationshipIcon /> : null}
      <Heading mt={5} mb={5} fontSize={28} fontWeight={600} textAlign="center">
        Family & Relationships
      </Heading>
      {isWeb() ? <FamilyAndRelationshipIcon /> : null}
      <Text
        mt={5}
        w={isWeb() ? 500 : 300}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Family pressures can sometimes be difficult to manage without emotional
        support and guidance to help. Many people feel confused by what
        information is available or struggle to access services close to home.
      </Text>
      <Heading
        mt={5}
        fontSize={20}
        color="primary.400"
        fontWeight={600}
        textAlign="center"
      >
        Familyline
      </Heading>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        FamilyLine service tackles these issues in a new and innovative way by
        using a network of <Text underline={true}>volunteers</Text> from across
        the country to support family members over the age of 18 through
        telephone calls, email and text messages.
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        The service aims to:
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Provide both immediate and long-term support
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Help with practical information and guidance
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Provide emotional and listening support
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Help with understanding and accessing relevant services and
        information
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Provide regular one-to-one befriending support to service
        users feeling isolated
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Access to short-term telephone counselling
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} A referral into our many projects across England and Wales,
        where relevant
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Family members aged 18 years old and over from anywhere in England,
        Wales and the Isle of Man can get in touch with the service for free via
        telephone, text message or email, using the details below.
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Do you need FamilyLine but it’s out of our operation hours? You can
        either call back in our opening hours, leave a message on our voice mail
        for a call back in our operation hours or if you’re in crisis you can
        contact our crisis line to get in touch with a trained professional.
      </Text>
      <Text mt={5} fontSize={14} color="primary.400" textAlign="center">
        Text FAMILYACTION to 85258
      </Text>
      <VStack
        mt={10}
        flex={1}
        w={isWeb() ? 860 : 250}
        space={5}
        justifyContent="space-between"
        flexDirection={isWeb() ? "row" : "column"}
      >
        <Pressable onPress={() => handleContactClick("0808 802 6666")}>
          <HStack space={2}>
            <CallIcon />
            <VStack>
              <Text>Telephone - 0808 802 6666</Text>
              <Text fontSize="10">(Mon/Fri) 9am to 9pm</Text>
            </VStack>
          </HStack>
        </Pressable>
        <Pressable onPress={() => handleContactClick("07537 404 282")}>
          <HStack space={2}>
            <CallIcon />
            <VStack>
              <Text>Telephone - 07537 404 282</Text>
              <Text fontSize="10">(Mon/Fri) 9am to 9pm</Text>
            </VStack>
          </HStack>
        </Pressable>
        <Link
          href="https://www.family-action.org.uk/what-we-do/children-families/familyline/"
          isExternal={true}
        >
          <HStack space={2}>
            <NetworkIcon />
            <VStack>
              <Text underline={true}>www.family-action.org.uk</Text>
            </VStack>
          </HStack>
        </Link>
      </VStack>
    </View>
  );
}
