import { useNavigation } from "@react-navigation/core";
import { Box, HStack } from "native-base";
import React from "react";

import { Button } from "./elements/Button";
import { IconButton } from "./elements/IconButton";
import { HealthAppIcon } from "./icons/Icons";
import { MoodIcon } from "./mood/icons/MoodIcon";

interface IUserInsightsArea {
  healthAppIconOpacity?: number;
}

export function UserInsightsArea({
  healthAppIconOpacity = 0,
}: IUserInsightsArea) {
  const { navigate } = useNavigation();
  return (
    <Box pb="9">
      <HStack space={[8, 2]} alignItems="center" justifyContent="center">
        <HealthAppIcon size="lg" opacity={healthAppIconOpacity} />
        <Button
          py={2}
          variant="inverted"
          onPress={() => {
            navigate("Insights");
          }}
          alignSelf="center"
        >
          All Insights
        </Button>
        <IconButton
          onPress={() => {
            navigate("TrackMood");
          }}
        >
          <MoodIcon size="lg" />
        </IconButton>
      </HStack>
    </Box>
  );
}
