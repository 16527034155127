import { WellbeingScoresEntity } from "api/src/wellbeing/entities/wellbeing-scores.entity";
import { Box, Divider, Heading, VStack, View } from "native-base";
import React from "react";

import { ChannelFilterOption, ChannelFilter } from "./ChannelFilter";
import { ContentFilterOption, ContentFilter } from "./ContentFilter";
import { UserInsightsArea } from "./UserInsightsArea";
import { UserLoginStreakTimeline } from "./UserLoginStreakTimeline";
import { UserNotification } from "./UserNotifications";
import { UserResilienceScore } from "./UserResilienceScore";

interface IUserScreenHeaderProps {
  typeFilter: ContentFilterOption;
  setTypeFilter: React.Dispatch<React.SetStateAction<ContentFilterOption>>;
  channelFilter: ChannelFilterOption;
  setChannelFilter: React.Dispatch<React.SetStateAction<ChannelFilterOption>>;
  resilienceStateText: string;
  wellbeingScores: WellbeingScoresEntity | undefined;
  hasNoContentAndFilter: boolean;
  isShowNotifications?: boolean;
}

export function UserScreenHeader({
  typeFilter,
  setTypeFilter,
  channelFilter,
  setChannelFilter,
  resilienceStateText,
  wellbeingScores,
  hasNoContentAndFilter,
  isShowNotifications = true,
}: IUserScreenHeaderProps) {
  return (
    <View flex={1}>
      <Box mb="2" _ios={{ mt: 2 }}>
        <UserResilienceScore
          text={resilienceStateText}
          percentage={wellbeingScores?.resilience}
          hasBottomText={true}
        />
        <UserLoginStreakTimeline />
        <UserInsightsArea />
        <Divider mb="8" w="70%" alignSelf="center" />
        {isShowNotifications ? <UserNotification /> : null}
        <Box mb={[4, 0]}>
          <VStack alignItems="center" space="4">
            <Heading fontWeight="600" fontSize="20">
              My Miindset Media Library
            </Heading>

            {!hasNoContentAndFilter ? (
              <>
                <ContentFilter
                  onFilterChange={(newFilter: ContentFilterOption) => {
                    setTypeFilter(newFilter);
                    setChannelFilter("all");
                  }}
                  activeFilter={typeFilter}
                />
                <Box h="4" />
                <Box mx={1}>
                  <ChannelFilter
                    onCategoryChange={(newFilter: ChannelFilterOption) => {
                      setChannelFilter(newFilter);
                    }}
                    activeCategory={channelFilter}
                    activeFilter={typeFilter}
                  />
                </Box>
              </>
            ) : null}
          </VStack>
        </Box>
      </Box>
    </View>
  );
}
