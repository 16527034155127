import { ViewedContentDto } from "api/src/content/dto/viewed-content.dto";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useViewedContentIds() {
  return useQuery<ViewedContentDto[]>(
    "viewed-content-ids",
    async () => {
      const response = await api.get<ViewedContentDto[]>(
        "content/viewed-ids",
        {}
      );

      return response.data ?? [];
    },
    {
      keepPreviousData: true,
    }
  );
}
