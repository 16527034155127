import { Icon, IIconProps } from "native-base";
import React from "react";
import { Path, Circle, Svg } from "react-native-svg";

export function GrayFlowerLotusIcon(props: IIconProps) {
  return (
    <Icon viewBox="-4 -4 29 29" {...props}>
      <Svg width={21} height={21} fill="none">
        <Circle cx={10.5} cy={10.5} r={10} stroke="#C4C4C4" />
        <Path
          d="M11 16c1.098 0 2.913-.013 5-1.323 2.088-1.31 2.764-2.95 2.98-3.79a.642.642 0 0 0-.053-.456.581.581 0 0 0-.143-.176.528.528 0 0 0-.193-.102c-.507-.14-1.381-.268-2.506.022M4.915 10.175c-1.125-.29-2-.163-2.506-.022a.529.529 0 0 0-.193.102.581.581 0 0 0-.143.176.63.63 0 0 0-.053.455c.216.84.892 2.481 2.98 3.79C7.087 15.987 8.902 16 10 16"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M10.5 16s2.5-1.515 2.5-5.556c0-3.166-1.535-4.782-2.2-5.333A.47.47 0 0 0 10.5 5a.47.47 0 0 0-.3.111C9.534 5.662 8 7.278 8 10.444 8 14.484 10.5 16 10.5 16Z"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M11 16c.769-.174 2.843-1.418 4.086-3.708 1.243-2.29.937-4.12.694-4.915a.54.54 0 0 0-.231-.304.486.486 0 0 0-.364-.061 4.848 4.848 0 0 0-2.01 1.033M8.39 8.045a6.306 6.306 0 0 0-2.412-1.033.684.684 0 0 0-.436.061.564.564 0 0 0-.278.304c-.291.796-.659 2.625.833 4.915S10.077 15.826 11 16"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
