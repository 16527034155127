import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

interface IDeleteDepartment {
  departmentId: string;
}

export function useDeleteDepartment(departmentId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    IApiResponse<IDeleteDepartment>,
    IApiResponse<IDeleteDepartment>,
    IDeleteDepartment
  >(
    ["organisations", "delete-department", departmentId],
    async () => {
      const response = await api.delete<IApiResponse<IDeleteDepartment>>(
        `organisations/delete-department/${departmentId}`
      );

      if (response?.status > 400) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["organisations", "departments"]);
      },
    }
  );
}
