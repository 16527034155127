import { UserEntity } from "api/src/users/entities/user.entity";
import React, { memo, ReactNode, useMemo, useState } from "react";

import { IMeContext, MeContext } from "../../context/MeContext";
import { api } from "../../util/api";

interface IMeContextProps {
  children: ReactNode;
}

export const MeContextProvider = memo(({ children }: IMeContextProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [me, setMe] = useState<UserEntity | undefined>();

  const meContextValue: IMeContext = useMemo(
    () => ({
      isAuthenticated,
      logout: () => {
        setIsAuthenticated(false);
      },
      me,
      authorize: async () => {
        await new Promise((res) => {
          setTimeout(res, 100);
        });

        const user = await api.get<UserEntity>("users/account", {
          validateStatus: () => true,
        });
        if (user.status !== 200) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
          setMe(user?.data);
        }
      },
    }),
    [isAuthenticated, me]
  );

  return (
    <MeContext.Provider value={meContextValue}>{children}</MeContext.Provider>
  );
});
