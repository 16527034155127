import { useNavigation } from "@react-navigation/native";
import { Pressable, Text } from "native-base";
import React from "react";

import { RootStackParamList } from "../navigation/RootStackParamList";

interface INavigationButtonProps {
  title: string;
  onPress?: () => void;
  to?: keyof RootStackParamList;
}

export function NavigationButton({
  onPress,
  title,
  to,
}: INavigationButtonProps) {
  const { navigate } = useNavigation();
  return (
    <Pressable
      p={1}
      px={3}
      py={4}
      onPress={
        onPress ||
        (() => {
          if (to) {
            navigate(to);
          }
        })
      }
    >
      <Text color="white" fontSize={13} fontWeight={500}>
        {title}
      </Text>
    </Pressable>
  );
}
