import { JournalTitleEntry } from "api/src/journal/constants/journal-title-entries";
import { JournalEntryType } from "api/src/journal/enum/JournalEntryType";
/**
 *
 * @param type - journal entry type
 * @returns - return success message for given journal type
 */
export function getJournalDeleteSuccessMsg(type: JournalEntryType | undefined) {
  return `${JournalTitleEntry[type as keyof typeof JournalTitleEntry]} Deleted`;
}
