export const I_AM_GREAT_LABELS = {
  admireMyselfFor: "I really admire myself for…",
  makesMeLaugh: "Something that makes me laugh is…",
  feelGoodAbout: "I feel good about my..",
  feelGoodWhen: "It makes me feel good when…",
  fellHappyIn: "Somewhere I feel happy is…",
  futureGoals: "My future goals are…",
  greatAt: "I am great because…",
  greatBecause: "I am great because…",
  greatestTalentIs: "My greatest talent is…",
  meanALotTo: "I mean a lot to...",
  myFriendsThink: "My friends think I have an awesome…",
  naturalGiftedAt: "I am naturally gifted at…",
  othersPraiseMy: "Others often praise my…",
  othersThinkIAmGoodAt: "Other people think I’m good at…",
  peaceWhen: "I’m at peace when…",
  reallyEnjoy: "Something I really enjoy is…",
  reasonsToAchieveGoals: "I know I can achieve them because…",
  succeededBeforeAt: "I have succeeded before at…",
  thinkIamGoodAt: "I think I’m a pretty good…",
};
