export interface IDefaultHabits {
  id: string;
  name: string;
  reminder: string;
  daysToStreak: number;
  otherReminders?: string[];
}

export const DEFAULT_HABITS: IDefaultHabits[] = [
  {
    id: "0",
    name: "Add Your Own",
    reminder: "",
    daysToStreak: 5,
  },
  {
    id: "1",
    name: "Go Outside for 20 Minutes",
    reminder: "06:00 pm",
    daysToStreak: 30,
  },
  {
    id: "2",
    name: "Drink A Pint Of Water",
    reminder: "12:00 pm",
    daysToStreak: 30,
  },
  {
    id: "3",
    name: "Yoga Before Breakfast",
    reminder: "09:00 am",
    daysToStreak: 30,
  },
  {
    id: "4",
    name: "Read A Chapter Before Bedtime",
    reminder: "11:00 pm",
    daysToStreak: 30,
  },
];
