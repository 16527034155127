import dayjs from "dayjs";
import {
  Box,
  Circle,
  Divider,
  Heading,
  HStack,
  ICircleProps,
  Text,
} from "native-base";
import React, { useMemo } from "react";

import { useLastStreak } from "../hooks/useLastStreak";

const ACTIVE_CIRCLE_PROPS: ICircleProps = {
  bg: "primary.400",
};

const INACTIVE_CIRCLE_PROPS: ICircleProps = {
  borderColor: "secondary.400",
  borderWidth: 1,
  bg: "white",
};

export function UserLoginStreakTimeline() {
  const { data: streakData } = useLastStreak();

  const streakCount = useMemo(
    () => dayjs(streakData?.lastActivity).diff(streakData?.startDate, "day"),
    [streakData]
  );
  const startingDay = streakCount < 7 ? 1 : Math.trunc(streakCount / 7) * 7 + 1;

  const endDay = streakCount < 7 ? 7 : (Math.trunc(streakCount / 7) + 1) * 7;

  return (
    <Box pb="9">
      <Heading fontSize="12" fontWeight="500" textAlign="center" mb="2">
        Your streak
      </Heading>
      <HStack alignItems="center" mx="10">
        <Text color="secondary.400" fontSize="11" fontWeight="600">
          {startingDay} Day
        </Text>
        <Box mx="3" flexShrink="1" flexGrow="1" justifyContent="center">
          <Divider position="absolute" />
          <HStack justifyContent="space-between">
            {[...Array(7)].map((_, index) => {
              const isActive = index < streakCount % 7;
              return (
                <Circle
                  key={index}
                  size="2"
                  {...(isActive ? ACTIVE_CIRCLE_PROPS : INACTIVE_CIRCLE_PROPS)}
                />
              );
            })}
          </HStack>
        </Box>
        <Text color="secondary.400" fontSize="11" fontWeight="600">
          {endDay} Days
        </Text>
      </HStack>
    </Box>
  );
}
