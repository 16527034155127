import React from "react";
import { Path, Svg } from "react-native-svg";

export function MoneyBadgeIcon() {
  return (
    <Svg width="70" height="45" viewBox="0 0 70 45" fill="none">
      <Path
        d="M50.7091 12.7577V11.3354C50.7091 4.96042 39.8809 0.160156 25.5005 0.160156C11.1202 0.160156 0.291992 4.96042 0.291992 11.3354V21.4947C0.291992 26.8029 7.79727 31.019 18.6255 32.2889V33.6858C18.6255 40.0608 29.4537 44.861 43.834 44.861C58.2144 44.861 69.0426 40.0608 69.0426 33.6858V23.5265C69.0426 18.2691 61.7665 14.053 50.7091 12.7577ZM18.6255 28.149V22.1296C20.9054 22.3894 23.2019 22.5167 25.5005 22.5106C27.7992 22.5167 30.0957 22.3894 32.3756 22.1296V28.149C30.1013 28.4632 27.8023 28.6161 25.5005 28.6062C23.1988 28.6161 20.8998 28.4632 18.6255 28.149ZM46.1257 17.9135V21.4947C46.1257 23.6281 42.5736 25.914 36.959 27.3109V21.3677C40.6543 20.5803 43.7768 19.3866 46.1257 17.9135ZM4.87537 21.4947V17.9135C7.22435 19.3866 10.3468 20.5803 14.0421 21.3677V27.3109C8.42748 25.914 4.87537 23.6281 4.87537 21.4947ZM23.2089 33.6858V32.6191L25.5005 32.6699C26.6273 32.6699 27.7158 32.6445 28.7662 32.5937C29.912 32.9493 31.1152 33.2541 32.3756 33.5334V39.502C26.761 38.1051 23.2089 35.8193 23.2089 33.6858ZM36.959 40.3402V34.2954C39.2386 34.5605 41.5349 34.6963 43.834 34.7018C46.1326 34.7078 48.4292 34.5805 50.7091 34.3208V40.3402C46.1515 40.9497 41.5166 40.9497 36.959 40.3402ZM55.2925 39.502V33.5588C58.9878 32.7715 62.1102 31.5778 64.4592 30.1047V33.6858C64.4592 35.8193 60.9071 38.1051 55.2925 39.502Z"
        fill="#DF3D8D"
      />
    </Svg>
  );
}
