import { Channel } from "api/src/channels/enum/Channel";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { useInfiniteQuery } from "react-query";

import { ContentFilterOption } from "../components/ContentFilter";
import { IContentPaginatedResponse } from "../interfaces/IContentPaginatedResponse";
import { api } from "../util/api";

interface IChannelContentRequest {
  channel: Channel;
  contentType: ContentFilterOption;
  isEnabled?: boolean;
  subChannel?: string;
}

export function useChannelContent({
  channel,
  contentType,
  isEnabled = true,
  subChannel = "",
}: IChannelContentRequest) {
  return useInfiniteQuery<IContentPaginatedResponse<ContentEntity>>(
    ["content", "channel", channel, contentType, subChannel],
    async ({ pageParam }) => {
      const response = await api.get<IContentPaginatedResponse<ContentEntity>>(
        `content/channel/${channel}`,
        {
          params: {
            contentType: contentType === "all" ? undefined : contentType,
            subChannel,
            page: pageParam,
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get content for channel");
    },
    {
      staleTime: 60000,
      enabled: isEnabled,

      getNextPageParam: (lastPage) =>
        lastPage?.content
          ? lastPage.content.meta.totalItems /
              lastPage.content.meta.itemsPerPage >
            lastPage.content.meta.currentPage
          : false,
    }
  );
}
