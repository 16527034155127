import React, { ReactNode } from "react";

import { Button, IButtonProps } from "../elements/Button";

interface IAuthButtonProps extends IButtonProps {
  children: ReactNode;
  onPress: () => void;
}

export function AuthButton({
  children,
  onPress,
  isLoading,
  ...props
}: IAuthButtonProps) {
  return (
    <Button
      py={4}
      variant="auth"
      onPress={onPress}
      maxW={300}
      width="100%"
      alignSelf="center"
      isLoading={isLoading}
      {...props}
    >
      {children}
    </Button>
  );
}
