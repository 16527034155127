import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import { VStack, Text } from "native-base";
import React from "react";

import { APP_VERSION } from "../../constants/app_version";
import { WELLBEING_WELL_DONE_SCREEN_KEY } from "../../constants/storage";
import { WELLBEING_WELL_DONE } from "../../constants/wellbeing-well-done-text";
import { isWeb } from "../../util/platform";
import { AuthButton } from "../auth/AuthButton";
import { AuthCard } from "../auth/AuthCard";
import { AuthContent } from "../AuthContent";

export function WellDoneScreen() {
  const { navigate } = useNavigation();

  async function handleNextPress() {
    await AsyncStorage.removeItem(WELLBEING_WELL_DONE_SCREEN_KEY);
    navigate(isWeb() ? "Home" : "App");
  }
  return (
    <AuthContent>
      <Text
        fontWeight={400}
        fontSize={12}
        color="gray.700"
        opacity={0.3}
        textAlign="center"
        mb="8"
        mt="-2"
      >
        Version {APP_VERSION}
      </Text>
      <AuthCard label="Well Done!">
        <VStack space={6}>
          <VStack space={6} px={2} mt={-2} mb={4} alignItems="center">
            {WELLBEING_WELL_DONE.map((text, index) => (
              <Text
                key={index.toString()}
                color="gray.700"
                fontSize={14}
                fontWeight={500}
                textAlign="center"
              >
                {text}
              </Text>
            ))}
          </VStack>
          <VStack space={2}>
            <AuthButton
              onPress={() => handleNextPress()}
              _text={{ fontSize: 18 }}
              variant="solid"
            >
              Next
            </AuthButton>
          </VStack>
        </VStack>
      </AuthCard>
    </AuthContent>
  );
}
