import { Text, VStack } from "native-base";
import { IVStackProps } from "native-base/lib/typescript/components/primitives/Stack/VStack";
import React from "react";

interface IJournalEntryInputLabelProps extends IVStackProps {
  title: string;
  subtitle?: string;
}

export function JournalEntryInputLabel({
  title,
  subtitle,
  ...props
}: IJournalEntryInputLabelProps) {
  return (
    <VStack {...props}>
      <Text fontSize="14" fontWeight={500}>
        {title}
      </Text>
      {subtitle ? <Text fontSize="12">{subtitle}</Text> : null}
    </VStack>
  );
}
