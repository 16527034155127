import { Icon } from "native-base";
import React from "react";
import { Ellipse, Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function HealthAppIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="-4 6 48 52" {...props}>
      <Svg width={48} height={52} fill="none">
        <Path
          d="M19 51c9.941 0 18-8.059 18-18s-8.059-18-18-18S1 23.059 1 33s8.059 18 18 18Z"
          stroke="#C4C4C4"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Ellipse cx={31} cy={16.5} rx={9} ry={9.5} fill="#fff" />
        <Path
          d="M9 33h20M19 23v20"
          stroke="#C4C4C4"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M26 15.5h3.75l1.5-2.5 3 5 1.5-2.5H38"
          stroke="#424241"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M23.01 12.409a6.873 6.873 0 0 1-.01-.386c0-1.161.396-2.286 1.12-3.184a4.927 4.927 0 0 1 2.85-1.741 4.87 4.87 0 0 1 3.29.49 4.994 4.994 0 0 1 2.24 2.5 4.994 4.994 0 0 1 2.24-2.5 4.87 4.87 0 0 1 3.29-.49 4.927 4.927 0 0 1 2.85 1.741A5.075 5.075 0 0 1 42 12.023C42 18.59 32.5 24 32.5 24s-3.795-2.16-6.587-5.409"
          stroke="#424241"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
