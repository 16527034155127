import { KeyboardAvoidingView } from "native-base";
import React, { ReactNode } from "react";

import { Body } from "../../layout/Body";
import { Header } from "../../layout/Header";
import { isiOS } from "../../util/platform";
import { ScrollView } from "../elements/ScrollView";
import { WhiteBoxContainer } from "../WhiteBoxContainer";

interface IJournalEntryContainerProps {
  children: ReactNode;
}

export function JournalEntryContainer({
  children,
}: IJournalEntryContainerProps) {
  return (
    <>
      <Header hasBack={true} />
      <Body>
        <KeyboardAvoidingView
          behavior={isiOS() ? "padding" : "height"}
          keyboardVerticalOffset={isiOS() ? 50 : 0}
          flex={1}
        >
          <ScrollView
            px={[2, 2, "10%", "15%", "20%"]}
            bg="secondary.50"
            _dark={{ bg: "dark.100" }}
          >
            <WhiteBoxContainer>{children}</WhiteBoxContainer>
          </ScrollView>
        </KeyboardAvoidingView>
      </Body>
    </>
  );
}
