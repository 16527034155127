import { SubmitOrganisationContentDto } from "api/src/content/dto/submit-organisation-content.dto";
import { ContentEntity } from "api/src/content/entity/content.entity";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

export function useUpdateOrganisationPost(contentId?: string) {
  const queryClient = useQueryClient();
  return useMutation<
    IApiResponse<ContentEntity>,
    IApiResponse<ContentEntity>,
    SubmitOrganisationContentDto
  >(
    "update-organisation-content",
    async ({ ...data }: SubmitOrganisationContentDto) => {
      const response = await api.put<IApiResponse<ContentEntity>>(
        `content/organisation-content/${contentId}`,
        data
      );

      if (response.status !== 200) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("content");
      },
    }
  );
}
