import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function SexualHealthIcon({ ...props }: IIconProps) {
  return (
    <Icon size={89} {...props}>
      <Svg width="89" height="89" viewBox="0 0 89 89" fill="none">
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.5333 28.7005C29.871 27.6608 26.94 27.529 24.1951 28.3255C21.4503 29.1219 19.0451 30.8021 17.3527 33.1053C15.6604 35.4085 14.7757 38.2058 14.8357 41.0633C14.8958 43.9207 15.8974 46.6784 17.685 48.9085C19.4726 51.1385 21.9463 52.7161 24.7222 53.3965C27.4981 54.0769 30.421 53.822 33.0372 52.6714C35.6534 51.5208 37.8167 49.5388 39.1914 47.033C40.566 44.5272 41.0751 41.6378 40.6397 38.8131C40.5649 38.327 40.6864 37.8311 40.9772 37.4345C41.2681 37.0379 41.7046 36.7732 42.1907 36.6984C42.6768 36.6237 43.1727 36.7451 43.5693 37.0359C43.9658 37.3268 44.2306 37.7633 44.3054 38.2494C44.8646 41.8811 44.2095 45.5958 42.4418 48.8172C40.674 52.0385 37.8925 54.5863 34.5287 56.0652C31.165 57.5442 27.4072 57.8715 23.8384 56.9965C20.2696 56.1215 17.0894 54.093 14.7913 51.2259C12.4932 48.3587 11.2056 44.8132 11.1284 41.1396C11.0513 37.4659 12.1888 33.8694 14.3645 30.9083C16.5401 27.9472 19.6323 25.7869 23.1612 24.7628C26.6901 23.7387 30.4584 23.9079 33.8812 25.2443C34.1112 25.3305 34.3217 25.4616 34.5005 25.6298C34.6794 25.7981 34.8231 26.0002 34.9232 26.2244C35.0233 26.4486 35.0779 26.6905 35.0838 26.936C35.0897 27.1815 35.0467 27.4257 34.9574 27.6544C34.8682 27.8832 34.7344 28.0919 34.5638 28.2686C34.3932 28.4452 34.1892 28.5861 33.9636 28.6833C33.7381 28.7804 33.4955 28.8318 33.25 28.8344C33.0044 28.8371 32.7608 28.7927 32.5333 28.7005Z"
          fill="#EF3F95"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.9585 72.3125V57.4792C25.9585 56.9874 26.1538 56.5158 26.5016 56.1681C26.8493 55.8203 27.3209 55.625 27.8127 55.625C28.3044 55.625 28.776 55.8203 29.1238 56.1681C29.4715 56.5158 29.6668 56.9874 29.6668 57.4792V72.3125C29.6668 72.8043 29.4715 73.2759 29.1238 73.6236C28.776 73.9713 28.3044 74.1667 27.8127 74.1667C27.3209 74.1667 26.8493 73.9713 26.5016 73.6236C26.1538 73.2759 25.9585 72.8043 25.9585 72.3125Z"
          fill="#EF3F95"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.396 63.0391C20.396 62.5473 20.5913 62.0757 20.9391 61.728C21.2868 61.3803 21.7584 61.1849 22.2502 61.1849H33.3752C33.8669 61.1849 34.3385 61.3803 34.6863 61.728C35.034 62.0757 35.2293 62.5473 35.2293 63.0391C35.2293 63.5308 35.034 64.0025 34.6863 64.3502C34.3385 64.6979 33.8669 64.8933 33.3752 64.8933H22.2502C21.7584 64.8933 21.2868 64.6979 20.9391 64.3502C20.5913 64.0025 20.396 63.5308 20.396 63.0391ZM53.5893 28.2976C51.5345 27.7175 49.3683 27.6533 47.2827 28.1106C45.1972 28.568 43.2566 29.5328 41.6332 30.9195C40.0097 32.3063 38.7534 34.0721 37.9756 36.0605C37.1978 38.049 36.9226 40.1985 37.1743 42.3188C37.2044 42.5612 37.1863 42.8072 37.121 43.0426C37.0557 43.2781 36.9446 43.4983 36.7939 43.6906C36.6433 43.8829 36.4561 44.0435 36.2431 44.1633C36.0302 44.283 35.7957 44.3595 35.5531 44.3883C35.3105 44.4172 35.0646 44.3978 34.8295 44.3313C34.5944 44.2648 34.3748 44.1525 34.1833 44.0008C33.9918 43.8491 33.8321 43.6611 33.7134 43.4476C33.5948 43.234 33.5195 42.9991 33.492 42.7564C33.0588 39.1074 33.8421 35.4175 35.7203 32.2593C37.5986 29.101 40.4668 26.651 43.8798 25.2894C47.2928 23.9279 51.0598 23.7309 54.5962 24.729C58.1326 25.7272 61.2406 27.8647 63.438 30.8099C65.6354 33.755 66.7993 37.3431 66.749 41.0173C66.6988 44.6915 65.4372 48.2464 63.16 51.1304C60.8829 54.0143 57.7175 56.066 54.1552 56.9671C50.5928 57.8681 46.8326 57.5682 43.4581 56.1138C43.2345 56.0175 43.032 55.8781 42.8622 55.7035C42.6924 55.529 42.5587 55.3227 42.4687 55.0964C42.2868 54.6395 42.2939 54.1291 42.4884 53.6774C42.6829 53.2257 43.0488 52.8698 43.5057 52.688C43.9627 52.5061 44.4731 52.5132 44.9248 52.7077C46.5569 53.415 48.3171 53.7794 50.0959 53.7781C51.8748 53.7769 53.6344 53.41 55.2656 52.7003C56.8967 51.9907 58.3646 50.9533 59.5781 49.6526C60.7917 48.352 61.7249 46.8158 62.3199 45.1394C62.915 43.463 63.1591 41.6822 63.0372 39.9075C62.9153 38.1329 62.43 36.4022 61.6113 34.8229C60.7926 33.2436 59.6581 31.8495 58.2781 30.727C56.8981 29.6044 55.3022 28.7775 53.5893 28.2976Z"
          fill="#EF3F95"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60.8039 31.9027C60.4563 31.555 60.261 31.0835 60.261 30.5918C60.261 30.1001 60.4563 29.6286 60.8039 29.2809L74.7101 15.3746C74.8812 15.1976 75.0858 15.0563 75.312 14.9591C75.5382 14.862 75.7815 14.8108 76.0277 14.8087C76.2739 14.8065 76.518 14.8534 76.7459 14.9467C76.9738 15.0399 77.1808 15.1776 77.3549 15.3517C77.529 15.5258 77.6667 15.7328 77.7599 15.9607C77.8531 16.1885 77.9 16.4327 77.8979 16.6789C77.8957 16.9251 77.8446 17.1684 77.7474 17.3946C77.6503 17.6208 77.509 17.8254 77.3319 17.9964L63.4257 31.9027C63.0779 32.2503 62.6064 32.4456 62.1148 32.4456C61.6231 32.4456 61.1516 32.2503 60.8039 31.9027Z"
          fill="#EF3F95"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.88 27.8119C75.6365 27.8087 75.396 27.7576 75.1722 27.6615C74.9485 27.5654 74.7459 27.4262 74.5759 27.2518C74.406 27.0774 74.272 26.8712 74.1818 26.645C74.0916 26.4188 74.0467 26.1771 74.0499 25.9336L74.1426 18.5651L66.7742 18.6578C66.2824 18.6642 65.8083 18.475 65.456 18.1318C65.1038 17.7886 64.9023 17.3195 64.8959 16.8278C64.8895 16.336 65.0787 15.8619 65.4219 15.5096C65.7651 15.1574 66.2342 14.9559 66.726 14.9495L77.8992 14.8086L77.7583 25.9799C77.7554 26.2236 77.7045 26.4643 77.6085 26.6883C77.5125 26.9122 77.3733 27.1151 77.1988 27.2852C77.0244 27.4554 76.8181 27.5894 76.5918 27.6798C76.3655 27.7702 76.1236 27.8151 75.88 27.8119Z"
          fill="#EF3F95"
        />
      </Svg>
    </Icon>
  );
}
