import { HabitEntity } from "api/src/habit/entities/habit.entity";
import { Text, HStack, Box, View } from "native-base";
import React from "react";
import { Pressable } from "react-native";

import { IconButton } from "./elements/IconButton";
import { AddIcon } from "./icons/AddIcon";

interface IHabitProps {
  item: Partial<HabitEntity>;
  onPress?: () => void;
}

export function DefaultHabitCard({ item, onPress }: IHabitProps) {
  return (
    <View width="100%" p={2}>
      <Pressable onPress={onPress}>
        <HStack
          bg="secondary.100"
          _dark={{ bg: "dark.300" }}
          justifyContent="flex-start"
          p={2}
          alignItems="center"
          rounded="xl"
        >
          <IconButton onPress={onPress}>
            <AddIcon size="10" />
          </IconButton>

          <Box alignItems="center" px="5">
            <Text fontSize="16" textAlign="center" fontWeight={400}>
              {item.name}
            </Text>
          </Box>
        </HStack>
      </Pressable>
    </View>
  );
}
