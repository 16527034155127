import { KeyboardAvoidingView } from "native-base";
import React from "react";

import { ScrollView } from "../components/elements/ScrollView";
import { SecondaryEmail } from "../components/SecondaryEmail";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import { useBackButton } from "../hooks/useBackButton";
import { Body } from "../layout/Body";
import { isiOS } from "../util/platform";

export function AddSecondaryEmail() {
  useBackButton(() => false);
  return (
    <>
      <Body>
        <KeyboardAvoidingView
          flex={1}
          behavior={isiOS() ? "padding" : "height"}
          keyboardVerticalOffset={50}
        >
          <ScrollView
            pt="10"
            contentContainerStyle={{ justifyContent: "center" }}
          >
            <WhiteBoxContainer>
              <SecondaryEmail />
            </WhiteBoxContainer>
          </ScrollView>
        </KeyboardAvoidingView>
      </Body>
    </>
  );
}
