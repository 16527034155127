import {
  useStyledSystemPropsResolver,
  ITextProps as NBTextProps,
} from "native-base";
import React, { forwardRef } from "react";
import { View, Text as RNText } from "react-native";

const FONT_WEIGHTS: { [key: number]: string } = {
  300: "Poppins_300Light",
  400: "Poppins_400Regular",
  500: "Poppins_500Medium",
  600: "Poppins_600SemiBold",
};
interface ITextProps extends NBTextProps {
  fontWeight?: number;
}
export const Text = forwardRef<View, React.PropsWithChildren<ITextProps>>(
  ({ children, fontWeight, ...props }: ITextProps, ref) => {
    const [style, otherProps] = useStyledSystemPropsResolver(props);

    const font = fontWeight ? FONT_WEIGHTS[fontWeight] : FONT_WEIGHTS[400];

    return (
      <RNText {...otherProps} style={[{ fontFamily: font }, style]} ref={ref}>
        {children}
      </RNText>
    );
  }
);
