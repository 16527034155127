import AsyncStorage from "@react-native-async-storage/async-storage";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { UserEntity } from "api/src/users/entities/user.entity";

import { api } from "../util/api";

export async function useLoginRedirect() {
  const { dispatch } = useNavigation();

  return {
    async navigate(token: string) {
      AsyncStorage.setItem("access_token", token);
      const response = await api.get<UserEntity>("users/account", {});
      if (response.status === 200) {
        const me = response.data;
        if (!me?.dob || !me?.gender) {
          dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                { name: "Main", params: { screen: "CompleteRegistration" } },
              ],
            })
          );
        } else {
          dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "Main" }],
            })
          );
        }
      }
    },
  };
}
