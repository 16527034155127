export enum IAmGreatQuestion {
  admireMyselfFor = "admireMyselfFor",
  makesMeLaugh = "makesMeLaugh",
  feelGoodAbout = "feelGoodAbout",
  feelGoodWhen = "feelGoodWhen",
  fellHappyIn = "fellHappyIn",
  futureGoals = "futureGoals",
  greatAt = "greatAt",
  greatBecause = "greatBecause",
  greatestTalentIs = "greatestTalentIs",
  meanALotTo = "meanALotTo",
  myFriendsThink = "myFriendsThink",
  naturalGiftedAt = "naturalGiftedAt",
  othersPraiseMy = "othersPraiseMy",
  othersThinkIAmGoodAt = "othersThinkIAmGoodAt",
  peaceWhen = "peaceWhen",
  reallyEnjoy = "reallyEnjoy",
  reasonsToAchieveGoals = "reasonsToAchieveGoals",
  succeededBeforeAt = "succeededBeforeAt",
  thinkIamGoodAt = "thinkIamGoodAt",
}
