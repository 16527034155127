import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function SaveFileIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 22 22" {...props}>
      <Svg width={22} height={22} fill="none" stroke={color}>
        <Path
          d="M16.733 18.703H5.325a.625.625 0 0 1-.448-.19.663.663 0 0 1-.186-.462V3.694c0-.173.067-.339.186-.461a.625.625 0 0 1 .448-.191h7.606l4.436 4.568V18.05a.67.67 0 0 1-.186.461.632.632 0 0 1-.448.191Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12.93 3.042V7.61h4.437M9.127 12.83h3.803M11.025 10.873v3.915"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
