import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiErrorResponse } from "./IApiErrorResponse";
import { IApiResponse } from "./IApiResponse";

interface IContentFeedback {
  status: string;
  id: string;
  helpful: boolean;
  message: string;
}

type IContentFeedbackRequest = Record<string, string | boolean>;

export function useContentFeedback() {
  return useMutation<
    IApiResponse<IContentFeedback>,
    IApiErrorResponse,
    IContentFeedbackRequest
  >("auth", async ({ ...data }: IContentFeedbackRequest) => {
    const response = await api.post<IApiResponse<IContentFeedback>>(
      "content/feedback",
      data
    );

    return response.data;
  });
}
