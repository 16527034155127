import { IInputProps } from "native-base";
import React from "react";
import { Control } from "react-hook-form";

import { Input } from "../form/Input";

interface IJournalEntryInputProps extends IInputProps {
  isTextarea?: boolean;
  control: Control;
  placeholder: string;
  name: string;
}

export function JournalEntryInput({
  isTextarea = false,
  control,
  placeholder,
  name,
  ...props
}: IJournalEntryInputProps) {
  return (
    <Input
      isTextarea={isTextarea}
      control={control}
      name={name}
      textAlignVertical="top"
      placeholder={placeholder}
      size="xl"
      variant="unstyled"
      placeholderTextColor="secondary.500"
      fontSize={16}
      fontWeight="300"
      bg="white"
      _web={{ bg: "secondary.100" }}
      _dark={{ bg: "dark.200" }}
      h={isTextarea ? 100 : 50}
      {...props}
    />
  );
}
