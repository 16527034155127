import { ContentType } from "api/src/content/enum/ContentType";
import { NotificationEntity } from "api/src/notification/entities/notification.entity";
import dayjs from "dayjs";
import { Box, Divider, HStack, Text, Button, VStack } from "native-base";
import React, { useEffect, useMemo, useState } from "react";

import { ButtonDirectionToggle } from "../components/ButtonDirectionToggle";
import { ContentCard } from "../components/content/ContentCard.web";
import { RenderHtmlContent } from "../components/content/RenderHtmlContent";
import { LoadingAnimation } from "../components/elements/LoadingAnimation";
import { ScrollView } from "../components/elements/ScrollView";
import { Grid } from "../components/Grid";
import { useContent } from "../hooks/useContent";
import { useUserUnReadNotification } from "../hooks/useUnreadNotification";
import { useUsersNotification } from "../hooks/useUsersNotification";
import { useUsersReadNotification } from "../hooks/useUsersReadNotification";
import { Header } from "../layout/Header";
import { isHtml } from "../util/content";
import { getImage } from "../util/image";
import { getMarkDownContent } from "../util/notifications";

export function NotificationsScreen() {
  const { mutate: readNotification, isLoading: isReadLoading } =
    useUsersReadNotification();
  const { mutate: unReadNotification, isLoading: isUnReadLoading } =
    useUserUnReadNotification();

  const { data, isLoading: isNotificationLoading } = useUsersNotification({
    limit: 6,
  });

  const firstNotification = data?.pages[0].items[0];
  const [notification, setNotification] = useState<
    NotificationEntity | undefined
  >(firstNotification);

  useEffect(() => {
    if (firstNotification) {
      setNotification(firstNotification);
    }
  }, [firstNotification]);

  const { data: content, isLoading: isContentLoading } = useContent(
    notification?.content?.id
  );

  const isContentHtml = isHtml(content?.content);
  const [direction, setDirection] = useState<"column" | "row">("column");

  const allNotification = useMemo(() => {
    if (!data || !content) {
      return;
    }
    return (
      data.pages.map((item) => item.items.map((item) => item)).flat() || []
    ).filter((contentDetails) => contentDetails?.content?.id !== content.id);
  }, [data, content]);

  const handeReadNotification = () => {
    if (notification) {
      if (notification && !notification?.isRead) {
        readNotification({ notificationId: notification.id });
      }
    }
  };

  const handleUnReadNotification = () => {
    if (notification) {
      if (notification && notification?.isRead) {
        unReadNotification({ notificationId: notification.id });
      }
    }
  };

  if (isNotificationLoading || isContentLoading) {
    return (
      <>
        <Header hasBack={true} />
        <Box
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingAnimation autoPlay={true} size={100} isLoading={true} />
        </Box>
      </>
    );
  }

  if (!content) {
    return (
      <>
        <Header hasBack={true} />
        <Box
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          There is no notification
        </Box>
      </>
    );
  }

  return (
    <>
      <Header hasBack={true} />
      <ScrollView
        px={5}
        bg="white"
        contentContainerStyle={{ paddingBottom: 200 }}
        scrollEventThrottle={100}
      >
        <HStack
          justifyContent="space-between"
          alignItems="center"
          space="2"
          my="5"
        >
          <Text width="70%" color="primary.400" fontWeight={500} fontSize={20}>
            {content?.title}
          </Text>
        </HStack>
        <Divider />
        <Text flex={1} py={3}>
          <Text color="primary.400" py={5}>
            {dayjs(notification?.createdAt).format("DD/MM/YY")}
          </Text>
          <img
            src={getImage(content?.image)}
            alt={content?.title}
            style={{
              width: 350,
              height: 250,
              float: "right",
              borderRadius: 5,
              paddingLeft: 5,
              paddingBottom: 5,
            }}
          />

          {content.type === ContentType.article ? (
            <>
              {isContentHtml ? (
                <>
                  <RenderHtmlContent content={content} />
                </>
              ) : (
                <div
                  className="article"
                  dangerouslySetInnerHTML={{
                    __html: getMarkDownContent(content.content ?? ""),
                  }}
                />
              )}
            </>
          ) : (
            <div className="article" style={{ paddingTop: 5 }}>
              <a
                href={content.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                {content?.headline ?? content.url}
              </a>
            </div>
          )}

          {notification?.isRead ? (
            <Button
              variant="outline"
              width={130}
              alignSelf="flex-end"
              my="5"
              fontSize="sm"
              isLoading={isUnReadLoading}
              onPress={handleUnReadNotification}
            >
              Mark As unRead
            </Button>
          ) : (
            <Button
              variant="outline"
              width={125}
              alignSelf="flex-end"
              my="5"
              fontSize="sm"
              isLoading={isReadLoading}
              onPress={handeReadNotification}
            >
              Mark As Read
            </Button>
          )}
        </Text>
        {allNotification?.length ? (
          <>
            <HStack space={5} alignItems="center">
              <Text fontSize="lg" textAlign="center" fontWeight={600}>
                {firstNotification?.organisation?.name} Channel
              </Text>
              <HStack flex="auto" justifyContent="flex-end">
                <ButtonDirectionToggle
                  direction={direction}
                  onPress={(direction) => setDirection(direction)}
                />
              </HStack>
            </HStack>
            <Divider orientation="horizontal" mt={2} mb={5} />
            <VStack position="relative" w="100%">
              <Grid direction={direction}>
                {(allNotification || []).map((notification, index) => (
                  <ContentCard
                    // @ts-ignore
                    contentData={notification.content}
                    direction={direction}
                    channelType="default"
                    key={index}
                    setNotification={setNotification}
                    notification={notification}
                  />
                ))}
              </Grid>
            </VStack>
          </>
        ) : null}
      </ScrollView>
    </>
  );
}
