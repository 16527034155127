import { useNavigation } from "@react-navigation/native";
import {
  VStack,
  Text,
  HStack,
  Image,
  Heading,
  Box,
  Divider,
  Pressable,
} from "native-base";
import React from "react";

import { DigestItem } from "../../components/digest-media/DigestItem";
import { Button } from "../../components/elements/Button";
import { IconButton } from "../../components/elements/IconButton";
import { BrainIcon, CloseCircularIcon } from "../../components/icons/Icons";
import { ShoutIcon } from "../../components/icons/ShoutIcon";
import { DIGEST_TYPES } from "../../constants/digest-types";
import { useFlaggedUser } from "../../hooks/useFlaggedUser";
import { useFlaggedUserEdit } from "../../hooks/useFlaggedUserEdit";
import { useMe } from "../../hooks/useMe";
import { isWeb } from "../../util/platform";

import { EmergencyContactText } from "./EmergencyContactText";
import { ISignpostingScreenProps } from "./SignpostingScreen";

export function HighAlertScreens({
  setCurrentIndex,
  currentIndex,
  handleSamaritansButton,
  handleWaveButton,
  handleCloseButton,
  handleNavigationButton,
}: ISignpostingScreenProps) {
  const { navigate, reset } = useNavigation();
  const { data: flaggedUser } = useFlaggedUser();
  const { mutate: editFlaggedUser } = useFlaggedUserEdit();
  const { data: user } = useMe();

  const onPress = () => {
    editFlaggedUser({ hasAnswered: true });
    if (isWeb()) {
      navigate("Home");
      return;
    }
    reset({ index: 1, routes: [{ name: "App" }] });
  };

  switch (currentIndex) {
    case 0:
      return (
        <VStack space={4}>
          <HStack alignItems="center" w="100%" justifyContent="center">
            <Heading fontSize="20" fontWeight={600}>
              Are you ok?
            </Heading>
          </HStack>
          <Box pb={20} justifyContent="center">
            <VStack space={4} alignItems="center">
              <Text>
                {flaggedUser?.triggerWord
                  ? `You just searched for ${flaggedUser?.triggerWord}`
                  : "Your wellbeing score is low today."}
              </Text>
              <Text textAlign="center">
                Would you like me to connect you to a trained professional now,
                so you can communicate with someone over SMS, completely
                confidential, leave at any time and only via text message?
              </Text>
              <Box height="50" justifyContent="center">
                <IconButton
                  style={{
                    height: 50,
                    justifyContent: "center",
                  }}
                  hitSlop={-15}
                  onPress={handleWaveButton}
                >
                  <ShoutIcon size="100" />
                </IconButton>
              </Box>
              <HStack space={10} justifyContent="center">
                <Button
                  px="10"
                  variant="outline"
                  onPress={() => {
                    if (setCurrentIndex) {
                      setCurrentIndex(currentIndex + 1);
                    }
                  }}
                >
                  NO
                </Button>
                <Button px="10" onPress={handleWaveButton}>
                  YES
                </Button>
              </HStack>
            </VStack>
          </Box>
        </VStack>
      );
    case 1:
      return (
        <VStack space={4}>
          <HStack alignItems="center" w="100%" justifyContent="center">
            <Heading fontSize="20" fontWeight={600}>
              Are you ok?
            </Heading>
          </HStack>
          <Box pb={20} justifyContent="center">
            <VStack space={4} alignItems="center">
              <Text textAlign="center">
                Would you like to speak to someone over the phone, completely
                confidential?
              </Text>
              <Pressable
                onPress={handleSamaritansButton}
                _pressed={{ opacity: 0.8 }}
              >
                <Image
                  height={100}
                  width={300}
                  resizeMode="contain"
                  source={require("../../assets/images/samaritans.png")}
                  alt="Logo"
                />
              </Pressable>
            </VStack>
            <HStack space={10} justifyContent="center">
              <Button
                px="10"
                variant="outline"
                onPress={() => {
                  if (setCurrentIndex) {
                    setCurrentIndex(currentIndex + 1);
                  }
                }}
              >
                NO
              </Button>
              <Button px="10" onPress={handleSamaritansButton}>
                YES
              </Button>
            </HStack>
          </Box>
        </VStack>
      );
    case 2:
      return (
        <VStack space={4}>
          <HStack alignItems="center" w="100%" justifyContent="center">
            <Heading fontSize="20" fontWeight={600}>
              Please look after yourself.
            </Heading>
          </HStack>
          <Box pb={20} justifyContent="center">
            <VStack space={4} alignItems="center">
              {user?.shouldInformEmergencyContact ? (
                <Text textAlign="center">
                  As you’re feeling a little under the weather, we’ve dropped a
                  quick text to your emergency contact, just to let them know.
                </Text>
              ) : null}
              <Text textAlign="center" color="primary.400">
                The app will now increase the frequency of check-ins for you, so
                you can keep track of your symptoms. Miindset will provide
                additional help and support as well as suggestions of where to
                get instant support over the next 2 weeks or until your
                wellbeing improves.
              </Text>
              <Text textAlign="center">
                You can always find additional help and support in your Miindset
                account. It may also be beneficial to read through our{" "}
                <Text
                  color="primary.400"
                  onPress={() => {
                    handleNavigationButton("CopingStrategies");
                  }}
                >
                  Coping Strategies
                </Text>{" "}
                Section.
              </Text>
              <BrainIcon />
              {/* TODO: Re-add once implemented button <Text>Look at Coping Strategies</Text> */}
              <Divider my="4" />
              <Text textAlign="center">
                If you are in imminent danger of harming yourself/acting on
                suicidal thoughts then please call 999 or speak with
                <EmergencyContactText />
              </Text>
              <Pressable onPress={handleWaveButton} _pressed={{ opacity: 0.8 }}>
                <Box height="50" justifyContent="center">
                  <ShoutIcon size="100" />
                </Box>
              </Pressable>
              <Text textAlign="center">
                You can also always get instant, confidential support via SMS
                with a trained professional by texting ‘SHOUT’ to 85258.
              </Text>
              <Pressable onPress={handleWaveButton} _pressed={{ opacity: 0.8 }}>
                <Text color="primary.200">Text ‘SHOUT’ Now</Text>
              </Pressable>
              <Divider my="4" />
              <Pressable onPress={handleSamaritansButton}>
                <Image
                  height={100}
                  width={300}
                  resizeMode="contain"
                  source={require("../../assets/images/samaritans.png")}
                  alt="Logo"
                />
              </Pressable>
              <Text textAlign="center">
                You can also speak to someone at the Samaritans by calling 116
                123.
              </Text>
              <Pressable onPress={handleSamaritansButton}>
                <Text color="primary.200">Call Samaritans Now</Text>
              </Pressable>
              <Divider my="4" />
              <HStack space={2}>
                {DIGEST_TYPES?.map(({ label, icon }) => (
                  <DigestItem
                    label={label}
                    icon={icon}
                    key={label}
                    onPress={onPress}
                  />
                ))}
              </HStack>

              <Text textAlign="center">
                You can{" "}
                <Text onPress={onPress} color="primary.400">
                  read articles
                </Text>
                ,{" "}
                <Text onPress={onPress} color="primary.400">
                  watch videos
                </Text>{" "}
                and{" "}
                <Text onPress={onPress} color="primary.400">
                  listen to podcasts
                </Text>{" "}
                related to how you are feeling right now.
              </Text>

              <Text textAlign="center">
                Remember how you are feeling is not wrong, you haven’t done
                anything wrong and you are entitled to feel what you are
                feeling. What you do about how you are feeling, is what matters
                now. There are lots of people who care and who can support you
                to help you get through these times.
              </Text>
              <Text textAlign="center">
                Miindset has lots of information to help you work through this
                moment and we can also link you to in person support via the
                phone and SMS as well as other services.
              </Text>
              <Text textAlign="center" pt="4">
                We will check in with you again later, we are here for you.
              </Text>
            </VStack>
            <HStack mt={10} justifyContent="center">
              <IconButton onPress={handleCloseButton}>
                <CloseCircularIcon size={12} />
              </IconButton>
            </HStack>
          </Box>
        </VStack>
      );
    default:
      navigate("Main");
      break;
  }
}
