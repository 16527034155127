import { ISectionListProps } from "native-base/lib/typescript/components/basic/SectionList/types";
import React, { forwardRef } from "react";
import { SectionList as RNSectionList } from "react-native";

export const SectionList = forwardRef<
  RNSectionList,
  React.PropsWithChildren<ISectionListProps>
>(({ ...props }: React.PropsWithChildren<ISectionListProps>, ref) => (
  <RNSectionList
    bounces={false}
    showsVerticalScrollIndicator={false}
    paddingX={[0, 0, "10%", "15%", "20%"]}
    {...props}
    ref={ref}
  />
));
