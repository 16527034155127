import { Button, Popover } from "native-base";
import React, { ReactElement } from "react";

import { IIconProps } from "../interfaces/IIconprops";
import { PositionPlacementType } from "../types/PositionPlacementType";

interface IInfoPopover {
  icon: (props: IIconProps) => ReactElement;
  placement?: PositionPlacementType;
  text: string;
}

export function InfoPopover({
  icon: Icon,
  placement = "top right",
  text,
}: IInfoPopover) {
  return (
    <Popover
      placement={placement}
      trigger={(triggerProps) => (
        <Button
          _hover={{ bg: "transparent" }}
          {...triggerProps}
          variant="unstyled"
        >
          <Icon />
        </Button>
      )}
    >
      <Popover.Content>
        <Popover.Arrow />
        <Popover.Body w="56">{text}</Popover.Body>
      </Popover.Content>
    </Popover>
  );
}
