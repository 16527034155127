import { useNavigation } from "@react-navigation/core";
import { RouteProp, useRoute } from "@react-navigation/native";
import { VStack, Text, HStack, Pressable, Spinner } from "native-base";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { AuthButton } from "../../components/auth/AuthButton";
import { AuthCard } from "../../components/auth/AuthCard";
import { AuthInput } from "../../components/auth/AuthInput";
import { AuthContent } from "../../components/AuthContent";
import { useForgotPasswordValidateEmail } from "../../hooks/useForgotPasswordValidateEmail";
import { useFormErrors } from "../../hooks/useFormErrors";
import { useResetPassword } from "../../hooks/useResetPassword";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { RootStackParamList } from "../../navigation/RootStackParamList";

export function ResetPasswordScreen() {
  const toast = useToast();
  const { navigate } = useNavigation();
  const [seconds, setSeconds] = useState<number>(30);
  const [resend, setResend] = useState<boolean>(false);
  const [restartTimer, setRestartTimer] = useState<number>(0);
  const { params } = useRoute<RouteProp<RootStackParamList, "ResetPassword">>();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const { setErrors } = useFormErrors(setError);
  const { mutate: resetPassword, isLoading } = useResetPassword();
  const { mutate: validateEmail, isLoading: isResendingCode } =
    useForgotPasswordValidateEmail();

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => {
        if (seconds - 1 === 0) {
          clearInterval(interval);
          setResend(true);
          return 30;
        }
        return seconds - 1;
      });
    }, 1000);

    if (seconds === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [restartTimer]);

  function onResendEmailRequest() {
    if (!params?.email) {
      toast({
        title: "Email not found please try again",
        status: ToastStatus.error,
      });
      navigate("ForgotPassword");
      return;
    }
    validateEmail(
      {
        email: params.email,
      },
      {
        onSuccess: () => {
          setResend(false);
          setRestartTimer(restartTimer + 1);
          toast({
            title: "Verification code sent successfully again.",
            status: ToastStatus.success,
          });
        },
        onError: (data) => {
          // @ts-ignore
          setErrors(data.errors ?? data ?? {});
        },
      }
    );
  }

  function onSubmit(data: Record<string, string>) {
    if (data.password !== data.RetypePassword) {
      setError("RetypePassword", { message: "Password does not match" });
      return;
    }
    resetPassword(
      {
        ...data,
        email: params?.email || "",
      },
      {
        onSuccess: () => {
          navigate("Login");
          toast({
            title: "Password Changed Successfully",
            status: ToastStatus.success,
          });
        },
        onError: (data) => {
          // @ts-ignore
          setErrors(data.errors ?? data ?? {});
        },
      }
    );
  }

  return (
    <AuthContent>
      <AuthCard
        label="Reset your password"
        subheading="We can help you recover your account"
      >
        <VStack space={2}>
          <AuthInput
            name="code"
            placeholder="Verification Code"
            control={control}
            errors={errors}
            label="Verification Code"
            keyboardType="default"
          />

          <AuthInput
            px={5}
            name="password"
            borderWidth={0}
            type="password"
            control={control}
            errors={errors}
            placeholder="Password"
          />
          <AuthInput
            px={5}
            name="RetypePassword"
            borderWidth={0}
            type="password"
            control={control}
            errors={errors}
            placeholder="Retype Password"
          />

          <HStack justifyContent="center" space="1" my="2">
            <Text>Didn't receive the email?</Text>

            {resend ? (
              <Pressable
                onPress={() => {
                  onResendEmailRequest();
                }}
              >
                {isResendingCode ? (
                  <Spinner />
                ) : (
                  <Text fontWeight={600} color="primary.400">
                    Resend
                  </Text>
                )}
              </Pressable>
            ) : (
              <HStack space="1">
                <Text>Resend in</Text>
                <Text fontWeight={600} color="primary.400">
                  {seconds}
                </Text>
                <Text>seconds</Text>
              </HStack>
            )}
          </HStack>

          <AuthButton onPress={handleSubmit(onSubmit)} isLoading={isLoading}>
            Submit
          </AuthButton>
        </VStack>
      </AuthCard>
    </AuthContent>
  );
}
