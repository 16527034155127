import { useNavigation } from "@react-navigation/core";
import { ContentType } from "api/src/content/enum/ContentType";
import { Role } from "api/src/users/enum/role";
import {
  Box,
  HStack,
  Image,
  Pressable,
  Text,
  useBreakpointValue,
  VStack,
} from "native-base";
import React from "react";

import { CONTENT_LABELS } from "../../constants/content-labels";
import { useAddViewedContent } from "../../hooks/useAddViewedContent";
import { useMe } from "../../hooks/useMe";
import { getChannelsLabel } from "../../util/channel";
import { getContentTypeIcon } from "../../util/content";
import { getImage } from "../../util/image";
import { openUrl } from "../../util/linking";

import { IContentVariantProps } from "./Content";

export function ContentHorizontal({
  item: {
    id,
    url,
    image,
    title,
    abstract,
    channels,
    type = ContentType.article,
  },
}: IContentVariantProps) {
  const Icon = getContentTypeIcon(type);
  const { navigate } = useNavigation();
  const { data: user } = useMe();
  const { mutate: viewContent } = useAddViewedContent();

  const displayAbstract = useBreakpointValue({
    md: "block",
  });

  const channelName = getChannelsLabel(channels);

  const handleEdit = () => {
    if (!id) {
      return;
    }
    navigate("ReviewContents", {
      screen: "ReviewContent",
      params: { id },
    });
  };

  function onPress() {
    if (!id) {
      return;
    }
    if ((url && type === ContentType.podcast) || type === ContentType.video) {
      if (!url) {
        return;
      }
      viewContent({ contentId: id });
      openUrl(url);
      return;
    }
    navigate("ContentDetails", { contentId: id });
  }

  return (
    <Box p={2} mb={2} flex="1" borderRadius={5}>
      <HStack space={[3, 3, 5]} alignItems="flex-start">
        <Box>
          <Image
            key={id}
            source={{ uri: getImage(image) }}
            alt={title}
            borderRadius={5}
            w={[20, 20, 24]}
            h={[20, 24, 24]}
          />
        </Box>

        <VStack alignItems="flex-start" flex={1} space={[1, 1, 2, 2, 2]}>
          <Pressable onPress={onPress}>
            <Text
              fontSize={[13, 18, 20, 20, 18]}
              textAlign="left"
              fontWeight="400"
              _dark={{ color: "secondary.200" }}
            >
              {title}
            </Text>
          </Pressable>
          {displayAbstract ? (
            <Text fontSize={[10, 12, 14, 16, 14]}>{abstract}</Text>
          ) : null}
          <HStack alignItems="center" space={2}>
            <Pressable
              onPress={() => {
                const channel = channels && channels.length > 0 && channels[0];
                if (channel) {
                  const { slug, label } = channel;
                  navigate("ChannelDetails", { slug, label });
                }
              }}
            >
              <Text
                fontSize={[12, 12, 12, 14, 15]}
                textAlign="center"
                variant="strongHighlight"
                fontWeight="600"
              >
                {channelName}
              </Text>
            </Pressable>
            {Icon ? <Icon variant="strongHighlight" size={5} /> : null}
          </HStack>

          <HStack space={5}>
            <Pressable onPress={onPress}>
              <Text variant="strongHighlight">{CONTENT_LABELS[type]}</Text>
            </Pressable>
            {user?.role === Role.admin ? (
              <Pressable onPress={handleEdit}>
                <Text variant="strongHighlight">Edit</Text>
              </Pressable>
            ) : null}
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
}
