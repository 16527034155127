import { Feeling } from "../enum/feeling";

export const POSITIVE_FEELINGS = [
  { label: "Active", value: Feeling.active },
  { label: "Joy", value: Feeling.joy },
  { label: "Gratitude", value: Feeling.gratitude },
  { label: "Hope", value: Feeling.hope },
  { label: "Pride", value: Feeling.pride },
  { label: "Awe", value: Feeling.awe },
  { label: "Inspiration", value: Feeling.inspiration },
  { label: "Upbeat", value: Feeling.upbeat },
  { label: "Amused", value: Feeling.amused },
  { label: "Relaxed", value: Feeling.relaxed },
  { label: "Love", value: Feeling.love },
  { label: "Happy", value: Feeling.happy },
  { label: "Relieved", value: Feeling.relieved },
  { label: "Calm", value: Feeling.calm },
  { label: "Rested", value: Feeling.rested },
  { label: "Safe", value: Feeling.safe },
];

export const NEGATIVE_FEELINGS = [
  {
    label: "Anger",
    value: Feeling.anger,
  },
  {
    label: "Rage",
    value: Feeling.rage,
  },
  {
    label: "Emptiness",
    value: Feeling.emptiness,
  },
  {
    label: "Fear",
    value: Feeling.fear,
  },
  {
    label: "Guilt",
    value: Feeling.guilt,
  },
  {
    label: "Grief",
    value: Feeling.grief,
  },
  {
    label: "Anxious",
    value: Feeling.anxious,
  },
  {
    label: "Lonely",
    value: Feeling.lonely,
  },
  {
    label: "Disgust",
    value: Feeling.disgust,
  },
  {
    label: "Annoyed",
    value: Feeling.annoyed,
  },
  {
    label: "Frustrated",
    value: Feeling.frustrated,
  },
  {
    label: "Anguish",
    value: Feeling.anguish,
  },
  {
    label: "Numb",
    value: Feeling.numb,
  },
  {
    label: "Unfit",
    value: Feeling.unfit,
  },
  {
    label: "Stressed",
    value: Feeling.stressed,
  },
];
