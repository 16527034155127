import { useNavigation } from "@react-navigation/core";
import { VStack, Text } from "native-base";
import React from "react";

import { AuthButton } from "../components/auth/AuthButton";
import { AuthCard } from "../components/auth/AuthCard";
import { AuthContent } from "../components/AuthContent";
import { PRIVACY_AND_POLICIES } from "../constants/privacy-and-policies";
import { isWeb } from "../util/platform";

export function PrivacyAndPolicyScreen() {
  const { navigate } = useNavigation();

  async function handleNextPress() {
    navigate(isWeb() ? "Home" : "App");
  }
  const renderTextWithFormatting = (text: string, index: number) => {
    const isHeading = text.startsWith("**");
    const fontWeight = isHeading ? 600 : 400;

    const content = isHeading ? text.substring(2) : text;

    return (
      <Text
        key={index.toString()}
        fontSize={14}
        fontWeight={fontWeight}
        textAlign="center"
      >
        {content}
      </Text>
    );
  };

  return (
    <AuthContent>
      <AuthCard label="Privacy Policy">
        <VStack space={6}>
          <VStack space={6} px={2} mt={-4} mb={4} alignItems="center">
            {PRIVACY_AND_POLICIES.map((text, index) =>
              renderTextWithFormatting(text, index)
            )}
          </VStack>
          <VStack space={2}>
            <AuthButton
              onPress={() => handleNextPress()}
              _text={{ fontSize: 18 }}
              variant="solid"
            >
              Next
            </AuthButton>
          </VStack>
        </VStack>
      </AuthCard>
    </AuthContent>
  );
}
