import { Insight } from "api/src/insights/enum/insight";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useLatestInsight() {
  return useQuery<Insight | undefined>(
    ["insights/latest-insight"],
    async () => {
      const response = await api.get<Insight | undefined>(
        "insights/latest-insight",
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    },
    {
      keepPreviousData: true,
    }
  );
}
