import LottieView from "lottie-react-native";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { WindIcon } from "../icons/Icons";

interface IBreathingAnimationProps {
  size?: number;
  shouldStart: boolean;
}

const FRAME_POINTS: number[] = [0, 120];
export function BreathingAnimation({
  size,
  shouldStart,
}: IBreathingAnimationProps) {
  const animationRef = useRef<LottieView>(null);
  const [shouldReverse, setShouldReverse] = useState<boolean>(true);

  const handleAnimationFinish = useCallback(() => {
    const [startFrame, endFrame] = shouldReverse
      ? [...FRAME_POINTS].reverse()
      : FRAME_POINTS;

    animationRef?.current?.play(startFrame, endFrame);
  }, [shouldReverse]);

  useEffect(() => {
    animationRef?.current?.play(...FRAME_POINTS);
    shouldStart = true;
  }, [shouldStart]);

  if (!shouldStart) {
    return <WindIcon size={75} />;
  }

  return (
    <LottieView
      source={require("../../assets/animations/breath.json")}
      speed={0.75}
      ref={animationRef}
      style={{ width: size, height: size }}
      autoPlay={false}
      loop={false}
      onAnimationFinish={() => {
        handleAnimationFinish();
        setShouldReverse(!shouldReverse);
      }}
    />
  );
}
