/**
 * Get the formatted number
 *
 * @param num  any number value
 *
 * @example
 * numFormatter(1000) // "1.0K"
 * numFormatter(1000000) // "1.0M"
 * numFormatter(999) // "999"
 */
export const numFormatter = (num: number | undefined) => {
  if (!num) {
    return "0";
  }
  if (num >= 1000 && num < 1000000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  return num.toString();
};
