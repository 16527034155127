import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { OrganisationContentNavigator } from "./OrganisationContentNavigator.web";
import { OrganisationEmployeesNavigator } from "./OrganisationEmployeesNavigator.web";
import { OrganisationInsightsNavigator } from "./OrganisationInsightsNavigator.web";
import { OrganisationSettingsNavigator } from "./OrganisationSettingsNavigator.web";

const Organisation = createStackNavigator();

export function OrganisationNavigator() {
  return (
    <Organisation.Navigator
      initialRouteName="OrganisationInsights"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Organisation.Screen
        name="OrganisationInsights"
        component={OrganisationInsightsNavigator}
      />
      <Organisation.Screen
        name="OrganisationEmployees"
        component={OrganisationEmployeesNavigator}
      />
      <Organisation.Screen
        name="OrganisationContent"
        component={OrganisationContentNavigator}
      />
      <Organisation.Screen
        name="OrganisationSettings"
        component={OrganisationSettingsNavigator}
      />
    </Organisation.Navigator>
  );
}
