import { UserEntity } from "api/src/users/entities/user.entity";
import { HStack, Text, Pressable, Tooltip } from "native-base";
import { IHStackProps } from "native-base/lib/typescript/components/primitives/Stack/HStack";
import React from "react";

import { INVITE_CODE_MESSAGE } from "../../../constants/organisation-employee-managment";
import { ToastStatus, useToast } from "../../../hooks/useToast";
import { CopyPasteIcon } from "../../icons/CopyPasteIcon";

interface IInviteCodeProps extends IHStackProps {
  user: UserEntity | undefined;
}

export function InviteCode({ user, ...props }: IInviteCodeProps) {
  const toast = useToast();
  const handleCopyInviteCode = () => {
    if (user) {
      navigator.clipboard.writeText(
        user?.organisationUser.organisation.inviteCode
      );
      toast({ title: "Copied to clipboard", status: ToastStatus.success });
    }
  };

  return (
    <Tooltip label={INVITE_CODE_MESSAGE} openDelay={500} w="380">
      <Pressable marginLeft="auto" onPress={handleCopyInviteCode}>
        <HStack
          justifyContent="flex-end"
          alignItems="center"
          alignContent="center"
          {...props}
        >
          <CopyPasteIcon />
          <Text color="primary.400" _dark={{ color: "primary.400" }}>
            Copy Invitation Code
          </Text>
        </HStack>
      </Pressable>
    </Tooltip>
  );
}
