import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationLocationsOrDepartments() {
  return useQuery(
    ["organisations", "locations-departments"],
    async () => {
      const response = await api.get("organisations/locations-departments", {});

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject(
        "Could not get organisations locations or departments"
      );
    },
    {
      keepPreviousData: true,
    }
  );
}
