import { Icon, useColorModeValue } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../../interfaces/IIconprops";

export function MoodIcon({ ...props }: IIconProps) {
  const plusIconFill = useColorModeValue("#424241", "#0a0c13");

  return (
    <Icon viewBox="0 2 32 30" {...props}>
      <Svg width={31} height={32} fill="none">
        <Path
          d="M8.192 17.497c-.07.44.227.862.635 1.122.425.273 1.032.422 1.746.317a2.262 2.262 0 0 0 1.582-.993c.3-.455.425-1.01.286-1.42a.298.298 0 0 0-.487-.121c-1.07 1.016-2.28 1.266-3.364.863a.298.298 0 0 0-.398.232ZM20.161 17.497c.071.44-.227.862-.634 1.122-.426.273-1.033.422-1.747.317a2.262 2.262 0 0 1-1.582-.993c-.3-.455-.424-1.01-.286-1.42a.298.298 0 0 1 .487-.121c1.071 1.016 2.28 1.266 3.364.863a.298.298 0 0 1 .398.232ZM18.532 22.316c-.1.235-.26.461-.46.668-.77.802-2.12 1.458-3.756 1.458-1.632 0-3.003-.653-3.762-1.463a2.365 2.365 0 0 1-.45-.657c-.095-.218-.161-.496-.09-.778a.897.897 0 0 1 .628-.646c.27-.081.56-.049.827.027 1.403.365 2.087.529 2.765.532.677.003 1.376-.154 2.805-.533l.008-.002c.294-.074.594-.1.867-.03a.941.941 0 0 1 .676.605c.102.297.039.593-.058.82Z"
          fill="#C4C4C4"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.308 28.02c5.926 0 10.73-4.805 10.73-10.732 0-5.926-4.804-10.73-10.73-10.73-5.927 0-10.73 4.804-10.73 10.73 0 5.927 4.803 10.731 10.73 10.731Zm0-1.193a9.538 9.538 0 0 0 9.538-9.539 9.538 9.538 0 0 0-9.538-9.538 9.538 9.538 0 0 0-9.539 9.538 9.538 9.538 0 0 0 9.539 9.539Z"
          fill="#C4C4C4"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.758 7.452a6.21 6.21 0 1 1-12.42 0 6.21 6.21 0 0 1 12.42 0Zm-9.626 0a.31.31 0 0 1 .31-.31h2.795V4.346a.31.31 0 1 1 .621 0V7.14h2.795a.31.31 0 1 1 0 .621h-2.795v2.795a.31.31 0 0 1-.62 0V7.762h-2.795a.31.31 0 0 1-.31-.31Z"
          fill={plusIconFill}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.548 13.04a5.589 5.589 0 1 0 0-11.177 5.589 5.589 0 0 0 0 11.178ZM19.51 7.453c0-.515.417-.932.932-.932h2.173V4.347a.932.932 0 0 1 1.863 0V6.52h2.174a.931.931 0 1 1 0 1.863h-2.174v2.174a.931.931 0 0 1-1.863 0V8.383h-2.173a.931.931 0 0 1-.932-.931Zm.932-.31a.31.31 0 1 0 0 .62h2.794v2.795a.31.31 0 0 0 .621 0V7.762h2.795a.31.31 0 1 0 0-.62h-2.795V4.346a.31.31 0 1 0-.62 0V7.14h-2.795Zm3.105 6.52a6.21 6.21 0 1 0 0-12.42 6.21 6.21 0 0 0 0 12.42Z"
          fill="#fff"
        />
      </Svg>
    </Icon>
  );
}
