import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";
import { useMe } from "./useMe";

type IChangeEmail = Record<string, string>;

export function useChangeEmail() {
  const queryClient = useQueryClient();
  const { data: user } = useMe();
  return useMutation<
    IApiResponse<IChangeEmail>,
    IApiResponse<IChangeEmail>,
    IChangeEmail
  >(
    "change-email",
    async (data: IChangeEmail) => {
      const response = await api.post<IApiResponse<IChangeEmail>>(
        "/users/change-email",
        data
      );

      if (response.status !== 201) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data.emailType === "primary") {
          queryClient.setQueryData("account", {
            ...user,
            email: data.newEmail,
          });
        } else {
          queryClient.setQueryData("account", {
            ...user,
            secondaryEmail: data.newEmail,
          });
        }
      },
    }
  );
}
