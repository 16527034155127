import { EditUserDto } from "api/src/users/dto/edit-user.dto";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

export function useEditUser() {
  const queryClient = useQueryClient();

  return useMutation<null, null, EditUserDto>(
    "edit-user",
    async (data: EditUserDto) => {
      const response = await api.put("users/edit", data);

      if (response.status !== 200) {
        throw response.data.errors;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["account"]);
      },
    }
  );
}
