import { CountryCode } from "api/src/users/types/countries";
import { Box } from "native-base";
import React from "react";
import PhoneInput from "react-native-phone-number-input";

import { isWeb } from "../../util/platform";
import { useTextColor } from "../../util/theme-helper";
import { Icon } from "../elements/Icon";

interface ICountryPhoneInputProps {
  arrayKey: number;
  phoneNumber: string;
  defaultCode: CountryCode;
  handleChangeText: (phoneNumber: string, key: number) => void;
  handleChangeFormattedText: (phoneNumber: string, key: number) => void;
  handleChangeCountry: (code: CountryCode, key: number) => void;
}

export function CountryPhoneInput({
  arrayKey,
  phoneNumber,
  defaultCode,
  handleChangeText,
  handleChangeFormattedText,
  handleChangeCountry,
  ...props
}: ICountryPhoneInputProps) {
  /* const [
    secondary50,
    secondary150,
    secondary650,
    secondary400,
    secondary850,
    dark100,
    dark200,
  ] = useToken("colors", [
    "secondary.50",
    "secondary.150",
    "secondary.650",
    "secondary.400",
    "secondary.850",
    "dark.100",
    "dark.200",
  ]); */
  const { defaultTextColor, fadedTextColor } = useTextColor();

  /*  const textColor = useColorModeValue(secondary850, secondary400);
  const fadedTextColor = useColorModeValue(secondary850, secondary650);
  const primaryColorVariant = useColorModeValue(secondary150, dark200);
  const backgroundColor = useColorModeValue(secondary50, dark100); */

  return (
    <Box
      borderWidth="0"
      borderRadius={10}
      h={50}
      pl="5"
      pr="2"
      alignItems="center"
      bg="rgba(0,0,0,.04)"
      maxW={500}
      alignSelf="center"
      w="100%"
      my="2"
      _dark={{ bg: "dark.200" }}
    >
      <PhoneInput
        countryPickerProps={{ preferredCountries: ["GB", "US"] }}
        containerStyle={{
          width: isWeb() ? 460 : undefined,
          height: 50,
          backgroundColor: "transparent",
        }}
        textContainerStyle={{
          paddingLeft: 20,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: "transparent",
        }}
        textInputProps={{ selectionColor: defaultTextColor }}
        textInputStyle={
          isWeb()
            ? { fontSize: 12, outline: "none", color: defaultTextColor }
            : { fontSize: 12, color: defaultTextColor }
        }
        codeTextStyle={{
          fontWeight: "400",
          fontSize: 12,
          color: fadedTextColor,
        }}
        flagButtonStyle={{
          width: 60,
          justifyContent: "flex-start",
        }}
        defaultValue={phoneNumber}
        defaultCode={defaultCode}
        layout="first"
        onChangeText={(text) => {
          handleChangeText(text, arrayKey);
        }}
        onChangeFormattedText={(text) => {
          handleChangeFormattedText(text, arrayKey);
        }}
        onChangeCountry={(country) => {
          handleChangeCountry(country.cca2, arrayKey);
        }}
        renderDropdownImage={
          <Icon icon="caretdown" size={2} color={fadedTextColor} />
        }
        {...props}
      />
    </Box>
  );
}
