import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

/**
 * Returns a string representing the time elapsed since the given timestamp.
 * If the timestamp is more than 7 days in the past, the date in the format "DD/MM/YY" is returned.
 * If the timestamp is within the past 7 days, a string representing the time elapsed since the timestamp is returned.
 *
 * @param {string | Date} timestamp The timestamp to convert to a time ago string.
 * @returns {string} A string representing the time elapsed since the given timestamp.
 */
export const timeAgo = (timestamp: string | Date): string => {
  const time = dayjs(timestamp);
  if (time.isBefore(dayjs().subtract(7, "day"))) {
    return time.format("DD/MM/YY");
  }

  return `${time.fromNow()}`;
};
