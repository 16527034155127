import {
  ChevronDownIcon,
  Text,
  HStack,
  Select,
  VStack,
  Link,
  FormControl,
  useColorModeValue,
} from "native-base";
import React, { useCallback, useMemo } from "react";
import { Control, useController } from "react-hook-form";

import { Icon, IconLibrary } from "../elements/Icon";

import { InputLabel } from "./InputLabel";

interface IDropdownInputProps {
  label?: string;
  items: { label: string; value: string }[];
  name: string;
  placeholder?: string;
  supportsMultiple?: boolean;
  control: Control;
  errors?: { [x: string]: { message: string } };
  variant?: string;
  maxWidth?: number | string;
  borderRadius?: number | string;
  alignSelf?: string;
  defaultValue?: string;
  setValue?: (value: string) => void;
}

export function DropdownInput({
  label,
  variant,
  name,
  placeholder,
  errors = {},
  control,
  supportsMultiple,
  items,
  maxWidth,
  borderRadius = 0,
  alignSelf,
  defaultValue,
  setValue,
}: IDropdownInputProps) {
  const iconColor = useColorModeValue("secondary.850", "secondary.650");

  const errorMessage = errors[name]?.message;

  const { field } = useController({
    control,
    defaultValue: supportsMultiple ? [] : "",
    name,
  });

  const handleChange = useCallback(
    (value: string) => {
      if (!supportsMultiple) {
        if (setValue) {
          setValue(value);
        }
        return field.onChange(value);
      }

      const newValue = [...field.value, value];
      field.onChange(newValue);
    },
    [field, items]
  );

  const handleRemove = useCallback(
    (value: string) => {
      field.onChange(field.value.filter((v: string) => v !== value));
    },
    [field, items]
  );

  const filteredItems = useMemo(() => {
    if (!supportsMultiple) {
      return items;
    }

    return items.filter(({ value }) => field.value.indexOf(value) === -1);
  }, [field, items]);

  return (
    <FormControl
      isInvalid={!!errorMessage}
      maxWidth={maxWidth}
      alignSelf={alignSelf}
    >
      <VStack space={0} flex={0}>
        <InputLabel label={label} variant={variant} />

        <Select
          placeholder={placeholder}
          // @ts-ignore (this is a bug in react-native-base)
          variant={variant}
          borderRadius={borderRadius}
          accessibilityLabel={placeholder}
          defaultValue={defaultValue}
          onValueChange={(value) => handleChange(value)}
          dropdownIcon={
            <ChevronDownIcon size={3} mr="22px" color={iconColor} />
          }
          _dark={{
            _actionSheetContent: {
              bg: "dark.100",
            },
          }}
        >
          {filteredItems.map(({ label, value }) => (
            <Select.Item key={label} label={label} value={value} />
          ))}
        </Select>

        {Array.isArray(field.value) && supportsMultiple ? (
          <HStack flexWrap="wrap" space={2}>
            {field.value?.map((value: string, index: number) => (
              <HStack
                px={3}
                py={1}
                mt={2}
                borderRadius={100}
                fontSize={12}
                bg="primary.200"
                justifyContent="center"
                alignItems="center"
                space={2}
                key={`key-${index?.toString()}`}
              >
                <Text color="white">
                  {
                    items.find(({ value: itemValue }) => itemValue === value)
                      ?.label
                  }
                </Text>
                <Link onPress={() => handleRemove(value)}>
                  <Icon
                    icon="close"
                    size={4}
                    color="white"
                    iconLibrary={IconLibrary.antDesign}
                  />
                </Link>
              </HStack>
            ))}
          </HStack>
        ) : null}
        {errorMessage ? (
          <FormControl.ErrorMessage justifyContent="center" alignItems="center">
            <Text
              mb={1}
              fontSize={12}
              color="red.500"
              fontWeight={600}
              textAlign="center"
            >
              {errorMessage}
            </Text>
          </FormControl.ErrorMessage>
        ) : null}
      </VStack>
    </FormControl>
  );
}
