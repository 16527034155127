import { FlaggedUserEditDto } from "api/src/signposting/dto/flagged-user-edit.dto";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

export function useFlaggedUserEdit() {
  const queryClient = useQueryClient();

  return useMutation<null, null, FlaggedUserEditDto>(
    ["flagged-user-edit"],
    async (data: FlaggedUserEditDto) => {
      const response = await api.put("signposting/flagged-user", data);
      if (response.status !== 200) {
        throw response.data;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["flagged-user"]);
      },
    }
  );
}
