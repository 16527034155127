import { Icon } from "native-base";
import React from "react";
import { G, Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

interface IWhiteLogoIconProps extends IIconProps {
  viewBox?: string;
  width?: number;
  height?: number;
}

export function WhiteLogoIcon({
  viewBox,
  width,
  height,
  ...props
}: IWhiteLogoIconProps) {
  return (
    <Icon viewBox={viewBox || "0 0 602.79 541.89"} {...props}>
      <Svg
        width={width || 602.79}
        height={height || 541.89}
        viewBox="0 0 602.79 541.89"
      >
        <G data-name="Layer 2">
          <G data-name="Layer 1" fill="#fff">
            <Path d="m364.56 539.23 114.24-51.15A79.82 79.82 0 0 0 505.64 362l31.69-11.64c39.77-13.55 65.46-44.77 65.46-79.57s-25.69-66-65.06-79.4l-31.85-11.7A79.81 79.81 0 0 0 478.8 53.81L364.56 2.67a30.35 30.35 0 0 0-42.76 27.7v481.14a30.37 30.37 0 0 0 42.76 27.72Zm99.72-453a44.22 44.22 0 0 1 3.06 79.25l-96.07-35.28c-9.14-3.22-12.45-9.1-13.94-12.58V38.36Zm-106.95 76.7c.65.25 1.29.5 1.92.72l62 22.76L357.33 215Zm0 90a30.45 30.45 0 0 0 7.23-2.21L469 204l56.88 20.89c25.13 8.56 41.38 26.58 41.38 45.92s-16.26 37.31-41.78 46.01l-56.81 20.87-111.34-50.9Zm63.95 102.17L358.9 378l-1.57.65v-52.8ZM357.33 431c2.56-10.41 7.43-17.1 14.17-19.74l95.43-35.06a44.22 44.22 0 0 1-2.65 79.47l-106.95 47.86ZM267.15 537A30.25 30.25 0 0 0 281 511.51V30.37a30.35 30.35 0 0 0-42.77-27.7L124 53.81a79.81 79.81 0 0 0-27.09 125.84l-31.85 11.7C25.7 204.75 0 236 0 270.75s25.7 66 65.46 79.57L97.15 362A79.82 79.82 0 0 0 124 488.08l114.24 51.15a30.25 30.25 0 0 0 28.91-2.23ZM245.46 38.36v79.27c-1.49 3.48-4.79 9.36-13.94 12.58l-96.06 35.28a44.22 44.22 0 0 1 3.06-79.25Zm0 176.66-63.9-28.61 62-22.76c.62-.22 1.26-.47 1.91-.72Zm0 71.77-111.34 50.9-56.8-20.87c-25.54-8.7-41.78-26.72-41.78-46.05s16.24-37.36 41.38-45.92L133.79 204l104.45 46.76a30.38 30.38 0 0 0 7.22 2.21Zm0 39.07v52.8l-1.56-.66-62.39-22.9Zm0 177.67-106.94-47.88a44.22 44.22 0 0 1-2.66-79.47l95.43 35.06c6.74 2.64 11.62 9.33 14.17 19.74Z" />
          </G>
        </G>
      </Svg>
    </Icon>
  );
}
