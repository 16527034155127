import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { EngagementScreen } from "../../screens/organisations/EngagementScreen.web";
import { OrganisationIndicatorsScreen } from "../../screens/organisations/OrganisationIndicatorsScreen.web";
import { OrganisationOverviewScreen } from "../../screens/organisations/OrganisationOverviewScreen.web";

const OrganisationInsights = createStackNavigator();

export function OrganisationInsightsNavigator() {
  return (
    <OrganisationInsights.Navigator
      initialRouteName="Overview"
      screenOptions={{
        headerShown: false,
      }}
    >
      <OrganisationInsights.Screen
        name="Overview"
        component={OrganisationOverviewScreen}
        options={{ title: "Organisation Overview - Miindset" }}
      />
      <OrganisationInsights.Screen
        name="Indicators"
        component={OrganisationIndicatorsScreen}
        options={{ title: "Organisation Indicators - Miindset" }}
      />
      <OrganisationInsights.Screen
        name="Engagement"
        component={EngagementScreen}
        options={{ title: "Organisation Engagement - Miindset" }}
      />
    </OrganisationInsights.Navigator>
  );
}
