import { Pressable, Text } from "native-base";
import React, { ReactNode } from "react";
import { PressableProps } from "react-native";

interface IAuthFooterTextProps extends PressableProps {
  children?: ReactNode;
  primary?: string;
  secondary?: string;
  onPress: () => void;
}

export function AuthFooterText({
  children,
  primary,
  secondary,
  ...props
}: IAuthFooterTextProps) {
  return (
    <Pressable bg="transparent" padding={0} alignItems="center" {...props}>
      {children && (
        <Text fontWeight={500} fontSize={13}>
          {children}
        </Text>
      )}
      {primary && (
        <Text
          fontWeight={500}
          fontSize={13}
          _dark={{
            color: "secondary.650",
          }}
        >
          {primary}
        </Text>
      )}
      {secondary && (
        <Text fontWeight={600} color="primary.400">
          {secondary}
        </Text>
      )}
    </Pressable>
  );
}
