import { IOrganisationDepartment } from "api/src/organisations/interfaces/IOrganisationDepartment";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationDepartmentsByInviteCode(
  inviteCode: string,
  isEnable: boolean = false
) {
  return useQuery<IOrganisationDepartment[]>(
    ["organisation", "departments", inviteCode],
    async () => {
      const response = await api.get<IOrganisationDepartment[]>(
        `organisations/departments/${inviteCode}`,
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get organisation departments");
    },
    { keepPreviousData: true, enabled: isEnable }
  );
}
