import { BreathingButton } from "../enum/breathing-button";
import { BreathingStepType } from "../enum/breathing-step-type";
import { IBreathingScreen } from "../interfaces/IBreathingScreen";

import { BREATHING_COMPLETE_SCREEN } from "./breathing-complete-screen";
import { BREATHING_FIRST_SCREEN } from "./breathing-first-screen";

export const FOURSEVENEIGHT_BREATHING: IBreathingScreen[] = [
  BREATHING_FIRST_SCREEN,
  {
    step: "Let's take breath",
    title: "4-7-8 Breathing",
    details:
      "This breathing exercise uses belly breathing to help you relax, do this exercise either lying or sitting down.",
    buttons: [BreathingButton.begin],
  },
  {
    step: "Step 1",
    title:
      "To start put one hand on your belly and the other on your chest like the belly breathing exercise.",
    type: BreathingStepType.step,
    auto: true,
  },
  {
    step: "Step 2",
    title:
      "Take a deep slow breath from your belly and silently count to 4 as you breathe in.",
    auto: true,
    type: BreathingStepType.step,
  },
  {
    step: "Step 3",
    title: "Hold your breath and silently count to 7.",
    auto: true,
    type: BreathingStepType.step,
  },
  {
    step: "Step 4",
    title:
      "Breathe out completely as you silently count from 1 to 8 try to expel all of the air from your lungs by the time you can't at 8.",
    auto: true,
    type: BreathingStepType.step,
  },
  {
    step: "Step 5",
    title: "Repeat these steps 3 to 7 times or until you're feeling calm.",
    type: BreathingStepType.step,
  },
  BREATHING_COMPLETE_SCREEN,
];
