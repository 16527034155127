import { DigestType } from "api/src/wellbeing/enum/DigestType";
import AnimatedLottieView from "lottie-react-native";
import {
  Box,
  Center,
  Heading,
  View,
  VStack,
  Text,
  HStack,
  Pressable,
  useToken,
  useColorModeValue,
  useBreakpointValue,
} from "native-base";
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import { Animated } from "react-native";
import { View as AnimatedView } from "react-native-animatable";

import { Container } from "../../components/Container";
import { DigestItem } from "../../components/digest-media/DigestItem";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { HeadphonesIcon } from "../../components/icons/HeadphonesIcon";
import { OpenBookIcon } from "../../components/icons/OpenBookIcon";
import { VideoCameraIcon } from "../../components/icons/VideoCameraIcon";
import { FirsLoginContext } from "../../context/FirstLoginContext";
import { TransitionContext } from "../../context/TransitionContext";
import { FirstLoginStepsType } from "../../enum/first-login-steps";
import { useDigestOrderSubmit } from "../../hooks/useDigestOrderSubmit";
import { IIconProps } from "../../interfaces/IIconprops";

import { FirstLoginSteps } from "./FirstLoginSteps";

interface IDigestType {
  label: string;
  type: DigestType;
  icon: (icon: IIconProps) => JSX.Element;
}

const DIGEST_TYPES: IDigestType[] = [
  {
    label: "Read",
    type: DigestType.read,
    icon: OpenBookIcon,
  },
  {
    label: "Watch",
    type: DigestType.watch,
    icon: VideoCameraIcon,
  },

  {
    label: "Listen",
    type: DigestType.listen,
    icon: HeadphonesIcon,
  },
];
const inAnimation = "fadeInDown";

export function TakeLookScreen() {
  const colourAnimation = useRef(new Animated.Value(0)).current;
  const [, setButtonTopOffset] = useState<number>(0);
  const [, setIsTransitioning] = useState<boolean>(false);
  const { setCurrentStep } = useContext(FirsLoginContext);
  const [activeItems, setActiveItems] = useState<DigestType[]>([]);
  const { mutate: submitDigest, isLoading } = useDigestOrderSubmit();

  useEffect(() => {
    handleAnimation();
    setCurrentStep(FirstLoginStepsType.takeLook);
  }, []);

  function handleAnimation() {
    Animated.timing(colourAnimation, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
    }).start(() => {
      setIsTransitioning(false);
    });
  }

  const [primary400, gray100, dark500] = useToken("colors", [
    "primary.400",
    "secondary.200",
    "dark.500",
  ]);

  const backgroundColor = useColorModeValue(gray100, dark500);

  const backgroundColourValue = colourAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [primary400, backgroundColor],
  });

  const backgroundColorBreakpoint = useBreakpointValue({
    base: "white",
    lg: backgroundColourValue,
  });

  const { startTransition } = useContext(TransitionContext);
  const [animationState] = useState<"In" | "OutDown">("In");

  const handlePress = useCallback(
    (value: DigestType) => {
      const existingItem = activeItems.findIndex((item) => item === value);

      if (existingItem !== -1) {
        setActiveItems(activeItems.filter((item) => item !== value));
        return;
      }

      setActiveItems([...activeItems, value]);
    },
    [activeItems]
  );

  const handleSubmit = useCallback(() => {
    submitDigest(
      { digestOrder: activeItems },
      {
        onSuccess: () => {
          startTransition({
            screen: "Digesting",
          });
        },
      }
    );
  }, [activeItems, submitDigest, startTransition, setCurrentStep]);

  const isButtonActive = useMemo(() => activeItems.length >= 3, [activeItems]);

  return (
    <>
      <Animated.View
        style={{
          backgroundColor: backgroundColorBreakpoint,
        }}
      >
        <Container minH="100vh" justifyContent="center">
          <Box
            alignSelf="center"
            p={10}
            width={{ base: "unset", lg: "70%" }}
            bg="white"
            _dark={{ bg: "dark.100" }}
            borderRadius={5}
          >
            <AnimatedView animation="fadeIn" delay={750} duration={750}>
              <FirstLoginSteps />
            </AnimatedView>
            <VStack px={{ base: 0, lg: 4 }} pt={12} space={8} marginTop={-30}>
              <AnimatedView animation={inAnimation} delay={25} duration={750}>
                <View
                  flex={1}
                  onLayout={(event) => {
                    const { layout } = event.nativeEvent;
                    setButtonTopOffset(layout.height / 2 + layout.y);
                  }}
                >
                  <Center>
                    <AnimatedLottieView
                      source={require("../../assets/animations/character.json")}
                      speed={0.75}
                      style={{ width: 400, height: 450 }}
                      autoPlay={true}
                      loop={true}
                    />
                  </Center>
                </View>
              </AnimatedView>
              <View
                pb={10}
                flex="1"
                justifyContent="center"
                px={{ base: 0, lg: 10 }}
                marginTop={-70}
              >
                <VStack space={8}>
                  <AnimatedView
                    style={{ width: "100%" }}
                    animation={`fadeInDown${
                      animationState === "In" ? "" : "Out"
                    }`}
                    delay={1000 + 300 + 400}
                    duration={1000}
                  >
                    <Heading fontSize={28} fontWeight="600" textAlign="center">
                      Take a look
                    </Heading>
                  </AnimatedView>
                  <VStack space={2}>
                    <AnimatedView
                      style={{ width: "100%" }}
                      animation={`fadeInLeft${
                        animationState === "In" ? "" : "Out"
                      }`}
                      delay={1000 + 300 + 500}
                      duration={1500}
                    >
                      <Text textAlign="center" lineHeight={24} fontSize={16}>
                        Explore some suggested content for you based on your
                        wellbeing check results
                      </Text>
                    </AnimatedView>
                  </VStack>
                  <AnimatedView
                    style={{ width: "100%" }}
                    animation={`fade${
                      animationState === "In" ? "InUp" : "OutDown"
                    }`}
                    delay={1000 + 300 + 600}
                    duration={2000}
                  >
                    <VStack space={16} alignItems="center">
                      <HStack space={4} justifyContent="space-between" px={10}>
                        {DIGEST_TYPES.map(({ label, icon, type }) => (
                          <DigestItem
                            label={label}
                            icon={icon}
                            key={label}
                            onPress={() => handlePress(type)}
                            activeIndex={
                              activeItems.findIndex((value) => value === type) +
                              1
                            }
                          />
                        ))}
                      </HStack>
                      <>
                        <Pressable
                          _pressed={isButtonActive ? { opacity: 0.7 } : {}}
                          opacity={isButtonActive ? 1 : 0}
                          disabled={!isButtonActive || isLoading}
                          onPress={handleSubmit}
                        >
                          <ArrowIcon size="4xl" />
                        </Pressable>
                      </>
                    </VStack>
                    ;
                  </AnimatedView>
                </VStack>
              </View>
            </VStack>
          </Box>
        </Container>
      </Animated.View>
    </>
  );
}
