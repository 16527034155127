import { WomenHealthSubChannel } from "api/src/channels/enum/Channel";

import { FamilySupport } from "./FamilySupport";
import { FertilitySupport } from "./FertilitySupport";
import { HealthyAgeingSupport } from "./HealthyAgeing";
import { NutritionAndDietrySupport } from "./NutritionAndDietry";
import { PremenopauseSupport } from "./PremenopauseSupport";
import { SexualHealthSupport } from "./SexualHealthSupport";
import { NonBinaryAndTransSupport } from "./TransSupport";

interface IProps {
  subChannel?: string;
}

export function SupportSection({ subChannel }: IProps) {
  if (subChannel === WomenHealthSubChannel.familyAndRelationships) {
    return <FamilySupport />;
  }
  if (
    subChannel === WomenHealthSubChannel.fertitlitPregnancyAndPostnatalSupport
  ) {
    return <FertilitySupport />;
  }
  if (subChannel === WomenHealthSubChannel.healthyAgeingAndLongTermCondition) {
    return <HealthyAgeingSupport />;
  }
  if (subChannel === WomenHealthSubChannel.nonBinaryAndTrans) {
    return <NonBinaryAndTransSupport />;
  }
  if (subChannel === WomenHealthSubChannel.sexualHealth) {
    return <SexualHealthSupport />;
  }
  if (subChannel === WomenHealthSubChannel.nutritionDietAndEating) {
    return <NutritionAndDietrySupport />;
  }
  if (
    subChannel === WomenHealthSubChannel.premenopauseMenopauseAndPerimenopause
  ) {
    return <PremenopauseSupport />;
  }
  return null;
}
