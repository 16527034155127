import { Modal, VStack, Text, Image } from "native-base";
import React from "react";

import { PRIVACY_AND_POLICIES } from "../constants/privacy-and-policies";

interface IProps {
  modalVisible?: boolean;
  setModalVisible: (t: boolean) => void;
}

export function PrivacyAndPoliciesPopUp({
  modalVisible = false,
  setModalVisible,
}: IProps) {
  function handleClose() {
    setModalVisible(false);
  }

  const renderTextWithFormatting = (text: string, index: number) => {
    const isHeading = text.startsWith("**");
    const fontWeight = isHeading ? 600 : 400;

    const content = isHeading ? text.substring(2) : text;

    return (
      <Text
        key={index.toString()}
        fontSize={14}
        fontWeight={fontWeight}
        textAlign="center"
      >
        {content}
      </Text>
    );
  };

  return (
    <Modal isOpen={modalVisible} onClose={handleClose} size="xl">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header textAlign="center">Privacy Policy</Modal.Header>
        <Modal.Body alignItems="center" pb="20">
          <Image
            height={100}
            width={120}
            source={require("../assets/images/logo.png")}
            alt="Logo"
          />
          <VStack space={6} px={2} mx={10} alignItems="center">
            {PRIVACY_AND_POLICIES.map((text, index) =>
              renderTextWithFormatting(text, index)
            )}
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
