import React from "react";
import { G, Path } from "react-native-svg";

export function EcstaticMoodSvg({
  x,
  y,
  scale = 1,
}: {
  x: number;
  y: number;
  scale?: number;
}) {
  return (
    <G x={x} y={y} scale={scale}>
      <Path
        d="M130.649 258.2c70.848 0 128.28-57.465 128.28-128.35 0-70.885-57.432-128.35-128.28-128.35S2.369 58.965 2.369 129.85c0 70.885 57.432 128.35 128.28 128.35Z"
        fill="#FFF"
        stroke="#C4C4C4"
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M188.636 180.596c-1.318 3.306-3.454 6.49-6.083 9.404-10.183 11.29-28.031 20.526-49.651 20.526-21.567 0-39.685-9.201-49.718-20.6-2.565-2.914-4.624-6.05-5.936-9.253-1.258-3.071-2.135-6.98-1.19-10.956 1.077-4.54 4.215-7.785 8.288-9.087 3.576-1.142 7.415-.686 10.938.375 18.538 5.148 27.578 7.447 36.543 7.49 8.945.043 18.188-2.166 37.066-7.504l.105-.03c3.885-1.037 7.856-1.412 11.458-.416 3.909 1.08 7.459 3.874 8.946 8.525 1.335 4.177.505 8.339-.766 11.526ZM177 70l8.262 25.592H212l-21.631 15.816L198.631 137 177 121.183 155.369 137l8.262-25.592L142 95.592h26.738L177 70ZM84 70l8.262 25.592H119l-21.631 15.816L105.63 137 84 121.183 62.369 137l8.262-25.592L49 95.592h26.738L84 70Z"
        fill="#C4C4C4"
      />
    </G>
  );
}
