import {
  NEGATIVE_FEELINGS,
  POSITIVE_FEELINGS,
} from "api/src/emotion/constants/feelings";

export const FEELINGS = [
  {
    title: "Positive",
    data: POSITIVE_FEELINGS,
  },
  {
    title: "Negative",
    data: NEGATIVE_FEELINGS,
  },
];
