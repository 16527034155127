import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function InfoIcon({ color = "#C4C4C4", ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 31 31" {...props}>
      <Svg width={24} height={24} fill="none" stroke={color} strokeWidth={2}>
        <Path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </Svg>
    </Icon>
  );
}
