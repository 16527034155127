import {
  FormControl,
  Input as NbInput,
  TextArea,
  IInputProps as NbIInputProps,
} from "native-base";
import React, { useMemo } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  RegisterOptions,
  useController,
} from "react-hook-form";

import { InputLabel } from "./InputLabel";

export interface IInputProps extends NbIInputProps {
  label?: string;
  name: string;
  control: Control;
  isTextarea?: boolean;
  errors?: { [x: string]: { message: string } };
  startYear?: number;
  endYear?: number;
  mode?: "date" | "time" | "datetime";
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

export function Input({
  name,
  errors = {},
  control,
  isTextarea,
  variant,
  label,
  ...props
}: IInputProps) {
  const { field } = useController({ control, defaultValue: "", name });
  const errorMessage = errors[name]?.message;

  const inputProps = useMemo(
    () => ({
      value: field.value,
      px: 3,
      py: 2,
      variant,
      borderRadius: 0,
      onChangeText: field.onChange,
    }),
    [field, variant]
  );

  return (
    <FormControl isInvalid={!!errorMessage} flex={1}>
      {label ? <InputLabel label={label} variant={variant} /> : null}
      {!isTextarea ? (
        <NbInput {...inputProps} {...props} />
      ) : (
        <TextArea {...inputProps} {...props} />
      )}
      {errorMessage ? (
        <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
      ) : null}
    </FormControl>
  );
}
