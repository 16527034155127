import { VStack } from "native-base";
import { IVStackProps } from "native-base/lib/typescript/components/primitives/Stack/VStack";
import React, { memo, ReactNode } from "react";

interface IBodyProps extends IVStackProps {
  children: ReactNode;
}

export const Body = memo(({ children, ...props }: IBodyProps) => (
  <VStack
    bg="white"
    _dark={{ bg: "dark.100" }}
    flexShrink="1"
    flexGrow="1"
    flexBasis="100%"
    {...props}
  >
    {children}
  </VStack>
));
