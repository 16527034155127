import { VStack, HStack, Box, Text, Heading } from "native-base";
import React, { ReactElement } from "react";

import { IIconProps } from "../../../../interfaces/IIconprops";
import {
  HeadphonesIcon,
  OpenBookIcon,
  VideoCameraIcon,
} from "../../../icons/Icons";

interface ITotalMediaConsumedProps {
  numberOfVideos: string;
  numberOfPodcasts: string;
  numberOfArticles: string;
}

const mediaConsumedComponent = (
  totalCount: string,
  Icon: (props: IIconProps) => ReactElement,
  label: string
) => (
  <VStack space={1}>
    <HStack justifyContent="center" space={2} alignItems="center">
      <Text textAlign="center" fontWeight="600" fontSize="lg">
        {totalCount}
      </Text>
      <Box w="25%">
        <Icon color="primary.400" alignSelf="center" />
      </Box>
    </HStack>
    <Text fontWeight="500" textAlign="center">
      {label}
    </Text>
  </VStack>
);

export function TotalMediaConsumed({
  numberOfVideos,
  numberOfPodcasts,
  numberOfArticles,
}: ITotalMediaConsumedProps) {
  return (
    <VStack p="10" space={5}>
      <Heading fontWeight={600} textAlign="center" fontSize={13}>
        Total Media Consumed
      </Heading>
      {mediaConsumedComponent(numberOfVideos, VideoCameraIcon, "Videos")}
      {mediaConsumedComponent(numberOfPodcasts, HeadphonesIcon, "Podcasts")}
      {mediaConsumedComponent(numberOfArticles, OpenBookIcon, "Articles")}
    </VStack>
  );
}
