import { Channel, WomenHealthSubChannel } from "api/src/channels/enum/Channel";

import { RequiredImageType } from "../type/RequiredImageType";

export const DEFAULT_CHANNEL_IMAGES: Partial<
  Record<Channel, RequiredImageType>
> = {
  [Channel.womenHealth]: require("../assets/images/channels/womens-channel.jpg"),
  [Channel.mentalHealth]: require("../assets/images/channels/mental-health.png"),
  [Channel.work]: require("../assets/images/channels/work.jpg"),
  [Channel.physicalHealth]: require("../assets/images/channels/physical-health.jpg"),
  [Channel.neurodiversity]: require("../assets/images/channels/neurodiversity.jpg"),
  [Channel.diversity]: require("../assets/images/channels/diversity.jpg"),
  [Channel.scienceAndTechnology]: require("../assets/images/channels/science-and-technology.jpg"),
  [Channel.familyAndRelationships]: require("../assets/images/channels/family-and-relationships.jpg"),
  [Channel.lgbtq]: require("../assets/images/channels/lgbtq.jpg"),
  [Channel.entertainment]: require("../assets/images/channels/entertainment.jpg"),
  [Channel.opinion]: require("../assets/images/channels/opinion.jpg"),
  [Channel.money]: require("../assets/images/channels/money.jpg"),
  [Channel.travel]: require("../assets/images/channels/travel.jpg"),
  [Channel.sleep]: require("../assets/images/channels/sleep.jpg"),
  [Channel.sport]: require("../assets/images/channels/sport.jpg"),
  [Channel.food]: require("../assets/images/channels/food.jpg"),
  [Channel.education]: require("../assets/images/channels/education.jpg"),
};

export const SUB_CHANNEL_IMAGES: Partial<Record<string, RequiredImageType>> = {
  [`${Channel.womenHealth}-${WomenHealthSubChannel.fertitlitPregnancyAndPostnatalSupport}`]: require("../assets/images/sub-channels/fertility-pregnancy-postnatal-support.png"),
  [`${Channel.womenHealth}-${WomenHealthSubChannel.sexualHealth}`]: require("../assets/images/sub-channels/sexual-health.png"),
  [`${Channel.womenHealth}-${WomenHealthSubChannel.premenopauseMenopauseAndPerimenopause}`]: require("../assets/images/sub-channels/premenopause-menopause-perimenopause.png"),
  [`${Channel.womenHealth}-${WomenHealthSubChannel.nutritionDietAndEating}`]: require("../assets/images/sub-channels/nutrition-diet-eating.png"),
  [`${Channel.womenHealth}-${WomenHealthSubChannel.nonBinaryAndTrans}`]: require("../assets/images/sub-channels/non-binary-and-trans.png"),
  [`${Channel.womenHealth}-${WomenHealthSubChannel.healthyAgeingAndLongTermCondition}`]: require("../assets/images/sub-channels/healthy-ageing-and-long-term-conditions.png"),
  [`${Channel.womenHealth}-${WomenHealthSubChannel.healthAndBeauty}`]: require("../assets/images/sub-channels/health-and-beauty.png"),
  [`${Channel.womenHealth}-${WomenHealthSubChannel.diseaseHealthAndWellness}`]: require("../assets/images/sub-channels/disease-health-wellness.png"),
  [`${Channel.womenHealth}-${WomenHealthSubChannel.empowerment}`]: require("../assets/images/sub-channels/empowerment.png"),
  [`${Channel.womenHealth}-${WomenHealthSubChannel.familyAndRelationships}`]: require("../assets/images/sub-channels/family-and-relationships.png"),
};
