import { useNavigation, useRoute } from "@react-navigation/core";
import { Channel } from "api/src/channels/enum/Channel";
import { ISubChannel } from "api/src/channels/interface/IChannel";
import { ContentType } from "api/src/content/enum/ContentType";
import { capitalize } from "lodash";
import { Divider, VStack, View } from "native-base";
import React, { useState, useEffect } from "react";

import { ButtonDirectionToggle } from "../components/ButtonDirectionToggle";
import { Container } from "../components/Container";
import { ChannelContentContainer } from "../components/content/ChannelContentContainer.web";
import { FinancialSupport } from "../components/content/FinancialSupport";
import { SupportSection } from "../components/content/SupportSection";
import { ContentSliderWeb } from "../components/ContentSliderWeb";
import { Loading } from "../components/Loading";
import { useChannelContent } from "../hooks/useChannelContent";
import { useChannels } from "../hooks/useChannels";
import { useOrganisationChannelContents } from "../hooks/useOrganisationChannelContents";
import { Header } from "../layout/Header";
import { ChannelDetailsRouteProp } from "../navigation/ChannelStackParamList";

export function ChannelsDetailsScreen() {
  const { params } = useRoute<ChannelDetailsRouteProp>();
  const [direction, setDirection] = useState<"column" | "row">("column");
  const navigation = useNavigation();
  const isOrganisation = params.type === "organisation";

  const { data } = useChannels();

  const channel = data?.find((channel) => channel.slug === params.slug);

  const { navigate } = useNavigation();
  const [subChannel, setSubChannel] = useState<ISubChannel | undefined>({
    label: params?.subChannelLabel ?? "",
    slug: params?.subChannelSlug ?? "",
  });

  useEffect(() => {
    setSubChannel({
      label: params?.subChannelLabel ?? "",
      slug: params?.subChannelSlug ?? "",
    });
  }, [params?.subChannelSlug]);

  const handleSubChannel = (subChannel?: ISubChannel) => {
    navigate("ChannelDetails", {
      slug: params.slug,
      label: params.label,
      type: params.type,
      subChannelLabel: subChannel?.label,
      subChannelSlug: subChannel?.slug,
    });
  };

  const { data: channelContent, isLoading: channelContentLoading } =
    useChannelContent({
      channel: params.slug,
      contentType: "all",
      subChannel: subChannel?.slug,
      isEnabled: !isOrganisation,
    });

  const { data: organisationContent, isLoading: organisationContentLoading } =
    useOrganisationChannelContents({
      contentType: "all",
      isEnabled: isOrganisation,
    });

  const content = isOrganisation
    ? organisationContent?.pages.map((item) => item.content.items).flat() || []
    : channelContent?.pages.map((item) => item.content.items).flat() || [];

  useEffect(() => {
    navigation?.setOptions({
      title: `${capitalize(params.slug)} - Miindset`,
    });
  }, [params.slug]);

  if (channelContentLoading || organisationContentLoading) {
    return (
      <>
        <Loading showHeader={true} />
      </>
    );
  }

  return (
    <>
      <Header />
      <Container>
        <View p={10} width="100%" bg="white" _dark={{ bg: "dark.100" }}>
          <VStack w="100%" space={2} display={["none", "none", "block"]}>
            <ContentSliderWeb
              items={content.slice(0, 5) || []}
              title={params.label}
              channel={channel}
              subChannel={subChannel}
              handleSubChannel={handleSubChannel}
            />
          </VStack>

          <ButtonDirectionToggle
            direction={direction}
            onPress={(direction) => setDirection(direction)}
          />

          <Divider />

          <ChannelContentContainer
            direction={direction}
            type={ContentType.article}
            channelType={params.type}
            channel={params.slug}
            isOrganisation={isOrganisation}
            subChannel={
              params.type === "subchannel" ? subChannel?.slug : undefined
            }
          />
          {params.slug === Channel.womenHealth && content?.length ? (
            <>
              <Divider />
              <SupportSection subChannel={subChannel?.slug} />
            </>
          ) : null}
          {params.slug === Channel.money && content?.length ? (
            <>
              <Divider />
              <FinancialSupport />
            </>
          ) : null}
          <ChannelContentContainer
            direction={direction}
            type={ContentType.podcast}
            channelType={params.type}
            channel={params.slug}
            isOrganisation={isOrganisation}
            subChannel={
              params.type === "subchannel" ? subChannel?.slug : undefined
            }
          />
          <ChannelContentContainer
            direction={direction}
            type={ContentType.video}
            channelType={params.type}
            channel={params.slug}
            isOrganisation={isOrganisation}
            subChannel={
              params.type === "subchannel" ? subChannel?.slug : undefined
            }
          />
        </View>
      </Container>
    </>
  );
}
