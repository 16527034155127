import { ISwitchProps, Switch as NbSwitch } from "native-base";
import React from "react";

export function Switch({ ...props }: ISwitchProps) {
  return (
    <NbSwitch
      offTrackColor="secondary.200"
      onTrackColor="secondary.400"
      onThumbColor="primary.200"
      offThumbColor="primary.100"
      /* @ts-ignore: Native base bug on _dark prop */
      _dark={{
        offTrackColor: "dark.400",
        offThumbColor: "primary.200",
        onTrackColor: "dark.500",
        onThumbColor: "primary.400",
      }}
      height="6"
      size="sm"
      {...props}
    />
  );
}
