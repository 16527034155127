import { Box, HStack, Text } from "native-base";
import React from "react";

interface ITagProps {
  label: string;
  value?: string | number;
}

export function Tag({ label, value }: ITagProps) {
  return (
    <Box bg="blue.200" borderRadius={5} overflow="hidden" mr={1} mb={1}>
      <HStack alignItems="center">
        {value ? (
          <Box
            backgroundColor="blue.300"
            p={1}
            px={2}
            alignItems="center"
            justifyContent="center"
          >
            <Text
              alignItems="center"
              justifyContent="center"
              fontSize={11}
              _dark={{ color: "dark.500" }}
            >
              {value}
            </Text>
          </Box>
        ) : null}
        <Text p={1} px={2} fontSize={11} _dark={{ color: "dark.500" }}>
          {label}
        </Text>
      </HStack>
    </Box>
  );
}
