import { BreathingButton } from "../enum/breathing-button";
import { BreathingStepType } from "../enum/breathing-step-type";
import { IBreathingScreen } from "../interfaces/IBreathingScreen";

import { BREATHING_COMPLETE_SCREEN } from "./breathing-complete-screen";
import { BREATHING_FIRST_SCREEN } from "./breathing-first-screen";

export const BELLY_BREATHING: IBreathingScreen[] = [
  BREATHING_FIRST_SCREEN,
  {
    step: "Let's take breath",
    title: "Belly Breathing",
    details:
      "Belly breathing is easy to do and very relaxing.\n\n Try this basic exercise anytime you need to relax or relieve stress.",
    buttons: [BreathingButton.begin],
  },
  {
    step: "Step 1",
    title: "Sit or lie flat in a comfortable position.",
    type: BreathingStepType.step,
  },
  {
    step: "Step 2",
    title:
      "Put one hand on your belly just below your ribs and the other hand on your chest.",
    auto: true,
    type: BreathingStepType.step,
  },
  {
    step: "Step 3",
    title:
      "Take a deep breath in through your nose, and let your belly push your hand out. \n\nYour chest should not move.",
    auto: true,
    type: BreathingStepType.step,
  },
  {
    step: "Step 4",
    title:
      "Breathe out through pursed lips as if you were whistling. \n\nFeel the hand on your belly go in, and use it to push all the air out.",
    auto: true,
    type: BreathingStepType.step,
  },
  {
    step: "Step 5",
    title: "Do this breathing 3 to 10 times. Take your time with each breath.",
    type: BreathingStepType.step,
  },
  BREATHING_COMPLETE_SCREEN,
];
