import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { AlertLevel } from "api/src/signposting/enum/AlertLevel";
import { View, VStack } from "native-base";
import React, { useState } from "react";

import { Container } from "../../components/Container";
import { SAMARITANS_PHONE_NUMBER } from "../../constants/settings/samaritans-phone-number";
import { WAVE_PHONE_NUMBER } from "../../constants/settings/wave-phone-number";
import { useFlaggedUserEdit } from "../../hooks/useFlaggedUserEdit";
import { RootStackParamList } from "../../navigation/RootStackParamList";
import { openMessageApp, openPhoneApp } from "../../util/linking";

import { HighAlertScreens } from "./HighAlertScreens";
import { LowAlertScreens } from "./LowAletScreens";
import { MediumAlertScreens } from "./MediumAlertScreens";

export interface ISignpostingScreenProps {
  setCurrentIndex?: (index: number) => void;
  currentIndex?: number;
  alertLevel?: AlertLevel;
  handleSamaritansButton?: () => void;
  handleWaveButton?: () => void;
  handleCloseButton?: () => void;
  hhandleNavigationButton?: (
    route: keyof RootStackParamList,
    params?: object
  ) => void;
}

export function SignpostingScreen() {
  const { goBack, navigate } = useNavigation();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { mutate: editFlaggedUser } = useFlaggedUserEdit();

  const { params: allParams } =
    useRoute<RouteProp<RootStackParamList, "Signposting">>();

  function handleWaveButton() {
    openMessageApp(WAVE_PHONE_NUMBER, "SHOUT");
    editFlaggedUser({ hasAnswered: true, hasContactedWave: true });
  }

  function handleSamaritansButton() {
    openPhoneApp(SAMARITANS_PHONE_NUMBER);
    editFlaggedUser({ hasAnswered: true, hasContactedSamaritans: true });
  }

  function handleCloseButton() {
    if (allParams.index === 1) {
      editFlaggedUser({ alertLevel: AlertLevel.high });
    }
    editFlaggedUser({ hasAnswered: true });
    goBack();
  }

  function handleNavigationButton(
    route: keyof RootStackParamList,
    params?: object
  ): void {
    editFlaggedUser({ hasAnswered: true });
    navigate(route, params);
  }

  function switchBetweenScreens({
    setCurrentIndex,
    currentIndex,
    alertLevel,
  }: ISignpostingScreenProps) {
    switch (alertLevel) {
      case AlertLevel.high:
        return HighAlertScreens({
          setCurrentIndex,
          currentIndex,
          handleSamaritansButton,
          handleWaveButton,
          handleCloseButton,
          handleNavigationButton,
        });
      case AlertLevel.medium:
        return MediumAlertScreens({
          currentIndex: allParams.index ?? 0,
          handleCloseButton,
          handleNavigationButton,
        });
      case AlertLevel.low:
        return LowAlertScreens({ handleCloseButton, handleNavigationButton });
      default:
        break;
    }
  }

  return (
    <View
      bg={{ base: "white", lg: "secondary.200" }}
      minH="100vh"
      justifyContent="center"
    >
      <Container minH="unset">
        <VStack
          alignSelf="center"
          p={10}
          width={{ base: "unset", lg: "70%" }}
          bg="white"
          borderRadius={5}
        >
          {switchBetweenScreens({
            setCurrentIndex,
            currentIndex,
            alertLevel: allParams.alertLevel,
            handleSamaritansButton,
            handleWaveButton,
            handleCloseButton,
          })}
        </VStack>
      </Container>
    </View>
  );
}
