import { Icon, IIconProps } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

export function MediaLibraryIcon(props: IIconProps) {
  return (
    <Icon viewBox="-5 0 35 30" {...props}>
      <Svg width="29" height="35" viewBox="0 0 29 35" fill="none">
        <Path
          d="M18.125 16.0719L12.6875 11.7861V20.3576L18.125 16.0719Z"
          stroke="#C4C4C4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M5.4375 25.7148L23.5625 25.7148C24.5635 25.7148 25.375 24.7554 25.375 23.572V8.57172C25.375 7.38824 24.5635 6.42883 23.5625 6.42883L5.4375 6.42883C4.43648 6.42883 3.625 7.38824 3.625 8.57172V23.572C3.625 24.7554 4.43648 25.7148 5.4375 25.7148Z"
          stroke="#C4C4C4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M18.125 30H10.875"
          stroke="#C4C4C4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </Svg>
    </Icon>
  );
}
