export const AFFIRMATIONS = [
  "I’m perfect in my imperfections, happy in my pain, strong in my weaknesses and beautiful in my own way",
  "To fall in love with yourself is the first secret to happiness",
  "Because I love myself, I listen to myself",
  "If you have the ability to love, love yourself first",
  "Be yourself because an original is worth more than just a copy",
  "I’m the most Awesome person I know",
  "I love myself, regardless of my accomplishments",
  "I love myself, unconditionally",
  "I never loved another person the way I loved myself",
  "Being yourself is the most courageous thing you can do",
  "I am perfect just as I am",
  "I am learning to love myself. It’s the hardest thing I’ve ever done",
  " I am worthy of love, and I give myself permission to be loved ",
  "I love myself JUST the way I am",
  "I am made of the same atoms as the stars. I am stardust",
  "I am not alone, I have Me Myself and I",
  "I am so happy with myself; it’s an awesome feeling",
  "Beauty begins the moment you decide to be yourself",
  "I am already everything I need to be",
  "Love yourself as much as you want to be loved",
  "Love yourself first and everything else falls into line",
  "Just be yourself, there is no one better",
  "Your value doesn’t decrease based on someone’s inability to see your worth",
  "Know your worth. Don’t settle for anything less",
  "I am not what happened to me, I am what I choose to become",
  "I take a lot of pride in being myself. I’m comfortable with who I am",
  "Just because my path is different doesn’t mean I’m lost",
  "My imperfections make me beautiful",
  "I am my own experiment. I am my own work of art",
  "Beauty is not flawless; It shines even through your flaws",
  "Just be who you want to be, not what others want to see",
  "Love yourself enough to never lower your standards for anyone",
  "I am a beautiful soul and I shine my light for all to see",
  "No matter where life takes me, find me with a smile",
  "It is never too late to be what you might have been",
  "I’m the architect of my own happiness",
  "Do you want to meet the love of your life? Look in the mirror",
  "I don’t want other people to decide who I am. I want to decide that for myself",
  "I love and accept myself for who I am and the way I am",
  "I love myself and I am perfect the way I am",
  "It is OK to love myself exactly the way I am right now",
  "I don’t like myself, I’m crazy about myself",
  "You demonstrate love by giving it unconditionally to yourself",
  "Celebrate who you are in your deepest heart. Love yourself and the world will love you",
  "Don’t impress others with what you have; Impress them with who you are",
  "What makes you different, makes you beautiful",
  "Be your own kind of beautiful",
  "Accept who you are because you’re BEAUTIFUL",
  "I’m beautiful and capable of being the best me I can",
  "I love myself the way I am, there’s nothing I need to change",
  "Be gentle with yourself, learn to love yourself, to forgive yourself",
  "I am strong because I know my weaknesses",
  "I am beautiful because I am aware of my flaws",
  "I am wise because I learn from my mistakes",
  "I am beautiful because I know who I am inside",
  "I am beautiful. See it! Say it! Believe it!",
  "You, yourself, as much as anybody in the entire universe, deserve your love and affection",
  "Nothing is greater than or lesser than the self",
  "Every day, in every way, I am becoming better and better",
  "Today, I will celebrate me",
  "Nothing on earth can ever equate to the level of joy that comes with loving oneself unconditionally",
  "Be proud of who you are, and everything you’ve overcome",
  "I am the master of my fate and the captain of my destiny",
  "I am the master of my fate and the captain of my soul",
  "Every decision I make is the right decision for me",
  "Now I have the courage to stand and say ‘This is who I am’",
  "The most terrifying thing is to accept oneself completely",
  "The privilege of a lifetime is being who you are",
  "Remind yourself that it’s okay not to be perfect",
  "I am WORTHY!",
  "I refuse to be pushed by my problems, I will be led by my dreams",
  "I can reach my goals, I am unstoppable",
  "In order to be irreplaceable one must always be different",
  "To be beautiful means to be yourself. You don’t need to be accepted by others. You need to accept yourself",
  "Opening the door to self-respect is a key of happiness",
  "I will not allow anything outside of myself determine my happiness",
  "Have unshakable faith in yourself to become everything you want to be",
  "The most powerful relationship you will ever have is the relationship with yourself",
  "The day you decide to love yourself is the day you’ll conquer the world",
  "The world has a need for me",
  "My smile can make someone feel better",
  "I light the world with my smile",
  "No one is you, and that is your superpower",
  "There is a voice inside which speaks and says: This is the REAL me!",
  "I am stronger than my worries",
  "The most important love in life is the one that comes from you to you",
  "What if you simply devoted this year to loving yourself more?",
  "Self-love is not selfish you cannot truly love another until you know how to love yourself",
  "Our first and last love is self-love",
  "Self-love is the source of all our other loves",
  "Love yourself first and everything else falls into place",
  "F.L.Y. First Love Yourself. Others will come next",
  "Love yourself first, because that’s who you’ll be spending the rest of your life with",
  "Loving yourself is the greatest revolution",
  "You’re a work of art. Not everyone will understand you",
  "I feel beautiful. I feel strong, and I feel confident in who I am",
  "I am strong, I am beautiful, I am enough",
  "I am confident because I can admit who I am, what I’ve done, and love myself for who I’ve become",
  "I am unique. I am special. I am ME",
];
