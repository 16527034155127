import { useNavigation } from "@react-navigation/core";
import { isEmpty } from "lodash";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React from "react";

import { Button } from "../components/elements/Button";
import { LoadingAnimation } from "../components/elements/LoadingAnimation";
import { HabitCard } from "../components/HabitCard";
import { HabitHeader } from "../components/HabitHeader";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import { useHabits } from "../hooks/useHabits";
import { Header } from "../layout/Header";

export function TrackHabitsScreen() {
  const { navigate } = useNavigation();
  const { data: habits, isLoading } = useHabits();

  return (
    <>
      <Box minH="100vh" bg="secondary.200" _dark={{ bg: "dark.500" }}>
        <Header />

        <WhiteBoxContainer>
          <VStack space={5}>
            <HabitHeader
              title="Habit tracker"
              subtitle="Track your habits to improve your mindset"
            />
            <HStack flexWrap="wrap" alignSelf="center" justifyContent="center">
              {!isLoading
                ? habits?.map((habit) => (
                    <Box w="50%" minW={400}>
                      <Box p={2}>
                        <HabitCard item={habit} />
                      </Box>
                    </Box>
                  ))
                : null}

              <Center top="35%">
                {isLoading && (
                  <Box pb={20}>
                    <LoadingAnimation size={100} isLoading={isLoading} />
                  </Box>
                )}
                {!isLoading && isEmpty(habits) && (
                  <Text
                    color="secondary.500"
                    textAlign="center"
                    mx="20"
                    fontSize={20}
                  >
                    You haven't add any Habits yet, add some and it will appear
                    here!
                  </Text>
                )}
              </Center>
            </HStack>

            {!isLoading ? (
              <Button
                mt={5}
                w="40"
                alignSelf="center"
                onPress={() => navigate("DefaultHabit")}
              >
                Add habit
              </Button>
            ) : null}
          </VStack>
        </WhiteBoxContainer>
      </Box>
    </>
  );
}
