import { ContentType } from "api/src/content/enum/ContentType";
import { Center, Divider, HStack, useColorModeValue } from "native-base";
import React, { ReactElement, useCallback } from "react";

import { IIconProps } from "../interfaces/IIconprops";

import { IconButton } from "./elements/IconButton";
import { HeadphonesIcon, OpenBookIcon, VideoCameraIcon } from "./icons/Icons";

export type ContentFilterOption = "all" | ContentType;

interface IFilterButton {
  type: ContentFilterOption;
  icon: (props: IIconProps) => ReactElement;
}

const FILTER_BUTTONS: IFilterButton[] = [
  {
    type: ContentType.article,
    icon: OpenBookIcon,
  },
  {
    type: ContentType.podcast,
    icon: HeadphonesIcon,
  },
  {
    type: ContentType.video,
    icon: VideoCameraIcon,
  },
];

interface IContentFilterProps {
  onFilterChange: (filter: ContentFilterOption) => void;
  activeFilter: ContentFilterOption;
}

export function ContentFilter({
  onFilterChange,
  activeFilter,
}: IContentFilterProps) {
  const inactiveFilterColor = useColorModeValue(
    "secondary.700",
    "secondary.400"
  );
  const getIconColor = useCallback(
    (name: string) =>
      activeFilter === name ? "primary.300" : inactiveFilterColor,
    [activeFilter]
  );

  return (
    <Center mt="1" justifyContent="flex-start">
      <HStack space="10" divider={<Divider />}>
        {FILTER_BUTTONS.map(({ type, icon: Icon }) => (
          <IconButton
            key={type}
            onPress={() => {
              const newActiveFilter = activeFilter === type ? "all" : type;

              onFilterChange(newActiveFilter);
            }}
          >
            <Icon size="lg" color={getIconColor(type)} />
          </IconButton>
        ))}
      </HStack>
    </Center>
  );
}
