import { IOrganisationDepartment } from "api/src/organisations/interfaces/IOrganisationDepartment";
import { Box, HStack, Text, Pressable, Heading } from "native-base";
import React from "react";

import { useOrganisationDepartments } from "../../../../hooks/useOrganisationDepartments";
import { Loading } from "../../../Loading";

interface IDepartmentListProps {
  departmentIds: string[];
  handleDepartment: (department: IOrganisationDepartment) => void;
}

export function DepartmentList({
  departmentIds,
  handleDepartment,
}: IDepartmentListProps) {
  const { data: organisationDepartment, isLoading } =
    useOrganisationDepartments();

  if (!organisationDepartment?.length) {
    return null;
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Heading fontSize="xl" fontWeight={500}>
            Departments
          </Heading>
          <HStack flexDirection="row" flexWrap="wrap" space="5" mt="2">
            {organisationDepartment?.map((department, index) => (
              <Pressable
                _pressed={{ opacity: 0.7 }}
                onPress={() => {
                  handleDepartment(department);
                }}
                key={index}
              >
                <Box
                  width="130"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor={
                    departmentIds.find((id) => id === department.value)
                      ? "secondary.200"
                      : undefined
                  }
                  p="1"
                  borderWidth="1"
                  borderColor="primary.400"
                  borderRadius="5"
                  marginBottom="2"
                >
                  <Text
                    fontSize="md"
                    isTruncated={true}
                    w={90}
                    textAlign="center"
                    textTransform="capitalize"
                  >
                    {department.label}
                  </Text>
                </Box>
              </Pressable>
            ))}
          </HStack>
        </>
      )}
    </>
  );
}
