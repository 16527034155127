import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { Departments } from "../../components/organisations/user-list/Departments.web";
import { UserList } from "../../components/organisations/user-list/UserList.web";
import { useBackgroundColor } from "../../util/theme-helper";

const OrganisationEmployees = createStackNavigator();

export function OrganisationEmployeesNavigator() {
  const { backgroundColorWeb } = useBackgroundColor();
  return (
    <OrganisationEmployees.Navigator
      initialRouteName="UserList"
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor: backgroundColorWeb },
      }}
    >
      <OrganisationEmployees.Screen
        name="UserList"
        component={UserList}
        options={{
          title: "Organisation UserList - Miindset",
        }}
      />
      <OrganisationEmployees.Screen
        name="Departments"
        component={Departments}
        options={{
          title: "Organisation Department - Miindset",
        }}
      />
    </OrganisationEmployees.Navigator>
  );
}
