import { Box, HStack } from "native-base";
import React, { useContext, useMemo } from "react";
import { SafeAreaView } from "react-native-safe-area-context";

import { FirsLoginContext } from "../../context/FirstLoginContext";
import { FirstLoginStepsType } from "../../enum/first-login-steps";

const FIRST_LOGIN_ORDER = [
  FirstLoginStepsType.welcomeScreen,
  FirstLoginStepsType.digestMedia,
  FirstLoginStepsType.takeLook,
];

const SIGNUP_STEP_COUNT = FIRST_LOGIN_ORDER.length;

export function FirstLoginSteps() {
  const { currentStep } = useContext(FirsLoginContext);

  const currentStepIndex = useMemo(
    () => (currentStep ? FIRST_LOGIN_ORDER.indexOf(currentStep) : -1),
    [currentStep]
  );
  if (!currentStep) {
    return null;
  }

  return (
    <SafeAreaView>
      <HStack space={2} position="relative" px={5} _android={{ pt: "4" }}>
        {[...Array(SIGNUP_STEP_COUNT)].map((_, index) => (
          <Box
            flex={1}
            key={index}
            height={1}
            bg={currentStepIndex >= index ? "primary.200" : "secondary.200"}
          />
        ))}
      </HStack>
    </SafeAreaView>
  );
}
