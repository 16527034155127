import AsyncStorage from "@react-native-async-storage/async-storage";
import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";
import { useAuth } from "./useAuth";

type ILoginRequest = Record<string, string>;

interface ILoginResponse {
  token: string;
}

export function useLogin() {
  const { authorize } = useAuth();

  return useMutation<
    IApiResponse<ILoginResponse>,
    IApiResponse<ILoginResponse>,
    ILoginRequest
  >("auth", async (data: ILoginRequest) => {
    const response = await api.post<IApiResponse<ILoginResponse>>(
      "auth/login",
      data,
      { validateStatus: () => true }
    );

    if (response.status !== 201) {
      throw response.data;
    }

    await new Promise((res) => {
      setTimeout(res, 1000);
    });

    AsyncStorage.setItem("access_token", response.data.token);
    await authorize();

    return response.data;
  });
}
