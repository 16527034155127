import { Box, Heading, VStack } from "native-base";
import React from "react";

import { ChannelBubble } from "../components/ChannelBubble";
import { FlatList } from "../components/elements/FlatList";
import { ChannelsBadgeIcon } from "../components/icons/ChannelsBadgeIcon";
import { DEFAULT_CHANNEL_IMAGE } from "../constants/default-channel-image";
import { useChannels } from "../hooks/useChannels";
import { useMe } from "../hooks/useMe";

export function ChannelsScreen() {
  const { data } = useChannels();
  const { data: user } = useMe();

  const channels = [
    ...(user?.organisationUser
      ? [
          {
            label: user?.organisationUser.organisation.name,
            slug: user?.organisationUser.organisation.name,
            type: "organisation",
            badge:
              user?.organisationUser.organisation.badge ||
              DEFAULT_CHANNEL_IMAGE,
          },
        ]
      : []),
    ...(data || []),
  ];

  return (
    <>
      <FlatList
        ListHeaderComponent={
          <Box pt="10" mb={6}>
            <VStack justifyContent="center" alignItems="center" space={1}>
              <ChannelsBadgeIcon size="3xl" />
              <Heading mt={1} fontSize={16} fontWeight={600} textAlign="center">
                Channels
              </Heading>
            </VStack>
          </Box>
        }
        data={channels}
        numColumns={3}
        renderItem={({ item }) => (
          <Box flexBasis="33%" key={item.label}>
            <ChannelBubble item={item} type={item.type} badge={item.badge} />
          </Box>
        )}
        keyExtractor={(_, index) => index.toString()}
        _dark={{
          bg: "dark.100",
        }}
      />
    </>
  );
}
