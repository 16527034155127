import {
  Button as NBButton,
  IButtonProps as NbIButtonProps,
} from "native-base";
import React, { forwardRef, ReactNode } from "react";

export interface IButtonProps extends NbIButtonProps {
  children: ReactNode;
}

export const Button = forwardRef((props: IButtonProps, ref) => (
  <NBButton
    // native-base has the wrong type for button ref prop
    // @ts-ignore
    ref={ref ?? undefined}
    _dark={{ _hover: { bg: "primary.300" } }}
    {...props}
  />
));
