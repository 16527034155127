import { useStyledSystemPropsResolver, IPressableProps } from "native-base";
import { Pressable as RNPressable } from "react-native";

export function Pressable({ _pressed, ...props }: IPressableProps) {
  const [style, rest] = useStyledSystemPropsResolver(props);
  const [stylePressed] = useStyledSystemPropsResolver(_pressed || {});

  return (
    <RNPressable
      {...rest}
      style={({ pressed }) => {
        if (pressed) {
          return {
            ...style,
            ...stylePressed,
          };
        }

        return style;
      }}
    />
  );
}
