import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect, useNavigation } from "@react-navigation/core";
import dayjs from "dayjs";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { HStack, Text, VStack, useToken, useColorModeValue } from "native-base";
import React, { ReactElement, useCallback, useState } from "react";

import {
  EXPO_PUSH_NOTIFICATION_TOKEN,
  LAST_VIEW_NOTIFICATION_SCREEN,
} from "../constants/storage";
import { useExpoPushNotificationTokenDelete } from "../hooks/useExpoPushNotificationTokenDelete";
import { IIconProps } from "../interfaces/IIconprops";

import { Switch } from "./elements/Switch";

interface ISwitchProps {
  icon?: (props: IIconProps) => ReactElement;
}

export function NotificationSwitch({ icon: Icon }: ISwitchProps) {
  const iconColor = useToken("colors", "secondary.400");
  const fadedColor = useColorModeValue("secondary.500", "secondary.650");
  const [isPushNotificationsEnabled, setIsPushNotificationsEnabled] =
    useState<boolean>(!!AsyncStorage.getItem(EXPO_PUSH_NOTIFICATION_TOKEN));
  const { mutate: expoPushNotificationTokenDelete } =
    useExpoPushNotificationTokenDelete();
  async function handleRefreshNotifications() {
    // Check if notifications have been disabled in the system. If so, disable
    // notifications in the app
    const expoPushNotificationToken = await AsyncStorage.getItem(
      EXPO_PUSH_NOTIFICATION_TOKEN
    );
    setIsPushNotificationsEnabled(!!expoPushNotificationToken);

    if (!Device.isDevice) {
      return;
    }

    const { status } = await Notifications.getPermissionsAsync();
    if (status !== "granted") {
      handleDisableNotifications();
    }
  }

  useFocusEffect(
    useCallback(() => {
      handleRefreshNotifications();
    }, [])
  );
  const { navigate } = useNavigation();
  async function handleNotification() {
    if (isPushNotificationsEnabled) {
      handleDisableNotifications();
    } else {
      setIsPushNotificationsEnabled(true);
      navigate("NotificationAlert");
    }
  }

  async function handleDisableNotifications() {
    const token = await AsyncStorage.getItem(EXPO_PUSH_NOTIFICATION_TOKEN);

    if (token) {
      setIsPushNotificationsEnabled(false);
      await expoPushNotificationTokenDelete({ token });

      await AsyncStorage.setItem(
        LAST_VIEW_NOTIFICATION_SCREEN,
        dayjs().toString()
      );
    }
  }

  return (
    <>
      <HStack space="3" py="3" px="1" alignItems="flex-start">
        {Icon && (
          <Icon marginLeft={1} size="2xl" marginTop={2.5} color={iconColor} />
        )}
        <VStack flexGrow="1" flexShrink="1" flexBasis="100%">
          <HStack justifyContent="space-between">
            <Text fontSize={[15, 16]} marginTop={3.5}>
              Notifications
            </Text>
            <Switch
              size="sm"
              marginTop={3}
              isChecked={isPushNotificationsEnabled}
              onChange={handleNotification}
            />
          </HStack>
          <Text fontSize={[13, 14]} color={fadedColor}>
            Push notifications advised {"\n"}
            for full experience
          </Text>
        </VStack>
      </HStack>
    </>
  );
}
