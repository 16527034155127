import { isString } from "lodash";
import { Text, VStack } from "native-base";
import React from "react";

interface IJournalResponseProps {
  title: string;
  question: string | string[] | undefined;
}

export function JournalResponse({ title, question }: IJournalResponseProps) {
  return (
    <VStack>
      <Text fontSize="18" fontWeight={500}>
        {title}
      </Text>
      {isString(question) ? (
        <Text>{question}</Text>
      ) : (
        question?.map((thing, index) => (
          <Text key={index}>
            {index + 1}. {thing}
          </Text>
        ))
      )}
    </VStack>
  );
}
