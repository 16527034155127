import { useNavigation } from "@react-navigation/core";
import { CountryCode, CountryCodeList } from "api/src/users/types/countries";
import { isEmpty } from "lodash";
import { View, Text, Checkbox, Pressable, HStack } from "native-base";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { isValidNumber } from "react-native-phone-number-input";

import { AuthButton } from "../components/auth/AuthButton";
import { AuthCard } from "../components/auth/AuthCard";
import { AuthInput } from "../components/auth/AuthInput";
import { ScrollView } from "../components/elements/ScrollView";
import { CountryPhoneInput } from "../components/form/CountryPhoneInput";
import { InputLabel } from "../components/form/InputLabel";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import {
  CONTACTS_NAME_INPUT_DETAIL,
  DEFAULT_CONTACTS,
  IEmergencyContact,
} from "../constants/emergency-contacts";
import { OnboardingContext } from "../context/OnboardingContext";
import { OnboardingStep } from "../enum/signup-step";
import { useEmergencyContacts } from "../hooks/useEmergencyContacts";
import { useFormErrors } from "../hooks/useFormErrors";
import { useMe } from "../hooks/useMe";
import { useToast, ToastStatus } from "../hooks/useToast";
import { Header } from "../layout/Header";
import { isWeb } from "../util/platform";

export function EmergencyContactsScreen() {
  const { data: user } = useMe();
  const toast = useToast();
  const { navigate } = useNavigation();

  const {
    watch,
    setValue,
    control,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm();
  const { setCurrentStep } = useContext(OnboardingContext);
  const { setErrors } = useFormErrors(setError);
  const { mutate, isLoading } = useEmergencyContacts();

  const contacts = useMemo(() => {
    if (isEmpty(user?.emergencyContacts)) {
      return DEFAULT_CONTACTS;
    }
    const userContacts = user?.emergencyContacts?.map((contact, index) => {
      setValue(
        CONTACTS_NAME_INPUT_DETAIL[index].fieldName,
        contact.contactName
      );
      return {
        ...contact,
        countryCode:
          CountryCodeList[
            CountryCodeList.findIndex(
              (_contact) => _contact === contact.countryCode
            )
          ],
        error: false,
        ...CONTACTS_NAME_INPUT_DETAIL[index],
      };
    });

    return userContacts ?? DEFAULT_CONTACTS;
  }, [user]);

  const [emergencyContacts, setEmergencyContacts] =
    useState<IEmergencyContact[]>(contacts);

  const [shouldInformEmergencyContact, setShouldInformEmergencyContact] =
    useState<boolean>(user?.shouldInformEmergencyContact || false);

  function handleChangeText(text: string, key: number) {
    emergencyContacts[key].number = text;
    setEmergencyContacts([...emergencyContacts]);
  }

  function handleChangeFormattedText(text: string, key: number) {
    emergencyContacts[key].numberWithCountryCode = text;
    setEmergencyContacts([...emergencyContacts]);
  }

  function handleChangeCountry(code: CountryCode, key: number) {
    emergencyContacts[key].countryCode = code;
    setEmergencyContacts([...emergencyContacts]);
  }

  function contactsValidation(data: IEmergencyContact[]) {
    let isError = false;

    const userContacts = data.map((contact) => {
      if (!isEmpty(contact.number) && isEmpty(contact.contactName)) {
        setError(contact.fieldName, { message: "Contact name required" });
        isError = true;
      }

      if (isEmpty(contact.number) && !isEmpty(contact.contactName)) {
        isError = true;
        return {
          ...contact,
          error: true,
        };
      }

      if (!isEmpty(contact.number)) {
        return {
          ...contact,
          error: !isValidNumber(contact.number, contact.countryCode),
        };
      }
      return contact;
    });
    setEmergencyContacts(userContacts);

    if (userContacts.some((numbers) => numbers.error) || isError) {
      return false;
    }
    return true;
  }

  async function onSkip() {
    if (!user?.hasOnboarded) {
      setCurrentStep(OnboardingStep.digestMedia);
      navigate("DigestMedia");
    } else {
      navigate(isWeb() ? "Home" : "App");
    }
  }

  async function onSubmit(data: Record<string, string>) {
    const contactsDetail = emergencyContacts.map((contact) => ({
      ...contact,
      contactName: data[contact.fieldName] ?? contact.contactName,
    }));

    const isValid = contactsValidation(contactsDetail);

    if (!isValid) {
      return;
    }

    mutate(
      {
        emergencyContacts: contactsDetail,
        shouldInformEmergencyContact,
      },
      {
        onSuccess: () => {
          if (!user?.hasOnboarded) {
            setCurrentStep(OnboardingStep.digestMedia);
            navigate("DigestMedia");
          } else {
            navigate(isWeb() ? "Home" : "App");
            toast({
              title: "Emergency contacts Updated",
              status: ToastStatus.success,
            });
          }
        },
        onError: (data) => {
          // @ts-ignore
          setErrors(data.errors ?? data ?? {});
        },
      }
    );
  }

  useEffect(() => {
    setCurrentStep(OnboardingStep.emergencyContacts);
  }, []);

  return (
    <>
      {user?.hasOnboarded ? <Header hasBack={!isWeb()} /> : null}
      <ScrollView
        paddingX={[6, 6, "10%", 0, 0]}
        _web={{ _dark: { bg: "dark.500" } }}
      >
        <WhiteBoxContainer>
          <AuthCard
            label="Emergency Contacts"
            subheading={
              <>
                You can add up to 2 next of kins, family members or friends.
                This is optional and will only be used in emergencies.
              </>
            }
          >
            {emergencyContacts.map((contact, index) => (
              <View
                mb={5}
                _web={{ maxW: 500, alignSelf: "center" }}
                key={index}
              >
                <InputLabel label={contact.label} variant="title" />
                <AuthInput
                  mt={2}
                  name={contact.fieldName}
                  placeholder={contact.placeholder}
                  mb={2}
                  control={control}
                  errors={errors}
                />
                <CountryPhoneInput
                  arrayKey={index}
                  phoneNumber={contact.number}
                  defaultCode={contact.countryCode}
                  handleChangeText={handleChangeText}
                  handleChangeFormattedText={handleChangeFormattedText}
                  handleChangeCountry={handleChangeCountry}
                />

                {contact.error ? (
                  <Text
                    mb={1}
                    fontSize={12}
                    color="red.500"
                    fontWeight={600}
                    textAlign="center"
                  >
                    Phone number incorrect
                  </Text>
                ) : null}
              </View>
            ))}
            <HStack justifyContent="center" my="5">
              <Checkbox
                accessibilityLabel="inform-the-contact"
                size="sm"
                value="two"
                isChecked={shouldInformEmergencyContact}
                onChange={(value) => {
                  setShouldInformEmergencyContact(value);
                }}
              />
              <Text
                ml="1"
                mt="-0.5"
                fontSize={13}
                lineHeight={24}
                textAlign="center"
                color="secondary.750"
                _dark={{ color: "secondary.400" }}
                _web={{ maxW: 400 }}
              >
                Automatically inform this contact if we detect that you are
                struggling with your mental wellbeing
              </Text>
            </HStack>
            <AuthButton
              onPress={handleSubmit(onSubmit)}
              isLoading={isLoading}
              isDisabled={
                !emergencyContacts.some(
                  (contact) => contact.number && watch(contact.fieldName)
                )
              }
            >
              Save
            </AuthButton>
            <Pressable _pressed={{ opacity: 0.7 }} pt="3" onPress={onSkip}>
              <Text
                textAlign="center"
                color="secondary.650"
                _dark={{ color: "secondary.400" }}
              >
                Skip emergency contacts
              </Text>
            </Pressable>
          </AuthCard>
        </WhiteBoxContainer>
      </ScrollView>
    </>
  );
}
