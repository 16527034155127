import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function WorriedFaceIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 74 74" {...props}>
      <Svg width={74} height={82} fill="none">
        <Path
          d="M37 71.647c15.326 0 27.75-13.593 27.75-30.36 0-16.769-12.424-30.362-27.75-30.362S9.25 24.518 9.25 41.286 21.674 71.646 37 71.646Z"
          stroke="#DF3D8D"
          strokeWidth={3}
          strokeMiterlimit={10}
        />
        <Path
          d="m23.125 55.201 4.625-6.325 4.625 6.325L37 48.876l4.625 6.325 4.625-6.325 4.625 6.325"
          stroke="#DF3D8D"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M26.594 38.756c1.915 0 3.468-1.7 3.468-3.795 0-2.096-1.553-3.795-3.468-3.795-1.916 0-3.469 1.699-3.469 3.795s1.553 3.795 3.469 3.795ZM47.406 38.756c1.916 0 3.469-1.7 3.469-3.795 0-2.096-1.553-3.795-3.469-3.795s-3.468 1.699-3.468 3.795 1.552 3.795 3.468 3.795Z"
          fill="#DF3D8D"
        />
      </Svg>
    </Icon>
  );
}
