import { ChartOptions, Chart as ChartJS } from "chart.js";
import { useColorModeValue, useToken } from "native-base";

export function useDoughnutChartOptions(): ChartOptions<"doughnut"> {
  const [secondary700, secondary400] = useToken("colors", [
    "secondary.700",
    "secondary.400",
  ]);
  const tickColor = useColorModeValue(secondary700, secondary400);

  return {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "80%",
    plugins: {
      legend: {
        position: "left" as const,
        align: "center" as const,
        labels: {
          generateLabels: (chart: ChartJS) => {
            const { datasets } = chart.data;
            return datasets[0].data.map((data, i) => ({
              text: `${data}% ${chart?.data?.labels?.[i]}`,
              // @ts-ignore
              fillStyle: datasets[0].backgroundColor[i],
              // @ts-ignore
              strokeStyle: datasets[0].backgroundColor[i],
            }));
          },
          color: tickColor,
          font: {
            size: 14,
            family: "Poppins_500Medium",
          },
        },
      },
    },
  };
}
