import AsyncStorage from "@react-native-async-storage/async-storage";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import { registerRootComponent } from "expo";
import * as SplashScreen from "expo-splash-screen";
import { NativeBaseProvider, StorageManager, ColorMode } from "native-base";
import React from "react";
import { LogBox } from "react-native";
import { QueryClient, QueryClientProvider } from "react-query";

import { MeContextProvider } from "./components/context/MeContextProvider";
import { AppLoader } from "./components/splash-screen/AppLoader";
import { WebScripts } from "./components/WebScripts";
import { RootNavigator } from "./navigation/RootNavigator";
import { miindsetTheme } from "./styles/miindsetTheme";

// Instruct SplashScreen not to hide yet, we want to do this manually
SplashScreen.preventAutoHideAsync().catch(() => {
  // reloading the app might trigger some race conditions, ignore them
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Defines a persisting color mode
const colorModeManager: StorageManager = {
  get: async () => {
    try {
      const val = await AsyncStorage.getItem("@color-mode");
      return val === "dark" ? "dark" : "light";
    } catch (e) {
      return "light";
    }
  },
  set: async (value: ColorMode) => {
    try {
      await AsyncStorage.setItem("@color-mode", value as string);
    } catch (e) {
      console.log(e);
    }
  },
};

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
LogBox?.ignoreLogs([
  "Setting a timer for a long period of time",
  "NativeBase: The contrast ratio of",
]);

export default function App() {
  return (
    <WebScripts>
      <AppLoader>
        <QueryClientProvider client={queryClient}>
          <NativeBaseProvider
            theme={miindsetTheme}
            colorModeManager={colorModeManager}
          >
            <MeContextProvider>
              <RootNavigator />
            </MeContextProvider>
          </NativeBaseProvider>
        </QueryClientProvider>
      </AppLoader>
    </WebScripts>
  );
}
registerRootComponent(App);
