import { Text, Spinner, VStack, Image } from "native-base";
import React from "react";
import { Pressable } from "react-native";

import { getImage } from "../util/image";

import { UploadIcon } from "./icons/Icons";

interface IImageBoxProps {
  supportedFormats: string;
  handleImage: () => void;
  image?: string;
  isLoading: boolean;
}

export function ImageBox({
  image,
  supportedFormats,
  handleImage,
  isLoading,
}: IImageBoxProps) {
  return (
    <>
      <Pressable onPress={() => handleImage()}>
        <VStack
          flex={1}
          padding={5}
          borderWidth={2}
          borderColor="primary.100"
          backgroundColor="primary.50"
          _dark={{
            borderWidth: 1,
            bg: "dark.300",
            borderColor: "dark.500",
          }}
        >
          {isLoading ? (
            <Spinner size="lg" />
          ) : (
            <>
              {image ? (
                <VStack alignItems="center" justifyContent="center" space="2">
                  <Image
                    key={image}
                    w={250}
                    h={250}
                    source={{ uri: getImage(image) }}
                  />
                </VStack>
              ) : (
                <>
                  <VStack alignItems="center" justifyContent="center" space="2">
                    <UploadIcon size={50} />

                    <Text underline={true} color="primary.400" fontSize="md">
                      Browse
                    </Text>
                    <Text>Supported formats: {supportedFormats}</Text>
                  </VStack>
                </>
              )}
            </>
          )}
        </VStack>
      </Pressable>
    </>
  );
}
