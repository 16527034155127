import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function LockIcon({ color = "#C4C4C4", ...props }: IIconProps) {
  return (
    <Icon viewBox="-2 0 33 33" {...props}>
      <Svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        stroke={color}
      >
        <Path
          d="M8.49332 12.0374V9.31239C8.49332 7.58832 9.17821 5.93486 10.3973 4.71576C11.6164 3.49665 13.2699 2.81177 14.9939 2.81177C16.718 2.81177 18.3715 3.49665 19.5906 4.71576C20.8097 5.93486 21.4946 7.58832 21.4946 9.31239V12.0374M6.35582 12.0374H23.6433C23.958 12.0377 24.2598 12.163 24.4822 12.3856C24.7046 12.6083 24.8296 12.9102 24.8296 13.2249V25.9999C24.8296 26.3147 24.7045 26.6167 24.482 26.8394C24.2594 27.062 23.9575 27.1872 23.6427 27.1874H6.35645C6.0415 27.1874 5.73946 27.0623 5.51676 26.8396C5.29406 26.6169 5.16895 26.3148 5.16895 25.9999V13.2249C5.16895 12.9099 5.29406 12.6079 5.51676 12.3852C5.73946 12.1625 6.0415 12.0374 6.35645 12.0374H6.35582Z"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M14.9939 22.1338C16.3864 22.1338 17.5152 21.005 17.5152 19.6126C17.5152 18.2201 16.3864 17.0913 14.9939 17.0913C13.6015 17.0913 12.4727 18.2201 12.4727 19.6126C12.4727 21.005 13.6015 22.1338 14.9939 22.1338Z"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
