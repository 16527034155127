import { IAmGreatQuestion } from "../enum/IAmGreatQuestion";

import { I_AM_GREAT_LABELS } from "./i-am-great-labels";

export const I_AM_GREAT_ENTRIES = [
  {
    label: I_AM_GREAT_LABELS.greatBecause,
    question: IAmGreatQuestion.greatBecause,
  },
  { label: I_AM_GREAT_LABELS.greatAt, question: IAmGreatQuestion.greatAt },
  {
    label: I_AM_GREAT_LABELS.feelGoodAbout,
    question: IAmGreatQuestion.feelGoodAbout,
  },
  {
    label: I_AM_GREAT_LABELS.myFriendsThink,
    question: IAmGreatQuestion.myFriendsThink,
  },
  {
    label: I_AM_GREAT_LABELS.meanALotTo,
    question: IAmGreatQuestion.meanALotTo,
  },
  {
    label: I_AM_GREAT_LABELS.othersThinkIAmGoodAt,
    question: IAmGreatQuestion.othersThinkIAmGoodAt,
  },
  {
    label: I_AM_GREAT_LABELS.thinkIamGoodAt,
    question: IAmGreatQuestion.thinkIamGoodAt,
  },
  {
    label: I_AM_GREAT_LABELS.fellHappyIn,
    question: IAmGreatQuestion.fellHappyIn,
  },
  {
    label: I_AM_GREAT_LABELS.reallyEnjoy,
    question: IAmGreatQuestion.reallyEnjoy,
  },
  {
    label: I_AM_GREAT_LABELS.admireMyselfFor,
    question: IAmGreatQuestion.admireMyselfFor,
  },
  {
    label: I_AM_GREAT_LABELS.futureGoals,
    question: IAmGreatQuestion.futureGoals,
  },
  {
    label: I_AM_GREAT_LABELS.reasonsToAchieveGoals,
    question: IAmGreatQuestion.reasonsToAchieveGoals,
  },
  {
    label: I_AM_GREAT_LABELS.naturalGiftedAt,
    question: IAmGreatQuestion.naturalGiftedAt,
  },
  {
    label: I_AM_GREAT_LABELS.othersPraiseMy,
    question: IAmGreatQuestion.othersPraiseMy,
  },
  {
    label: I_AM_GREAT_LABELS.feelGoodWhen,
    question: IAmGreatQuestion.feelGoodWhen,
  },
  {
    label: I_AM_GREAT_LABELS.succeededBeforeAt,
    question: IAmGreatQuestion.succeededBeforeAt,
  },
  {
    label: I_AM_GREAT_LABELS.makesMeLaugh,
    question: IAmGreatQuestion.makesMeLaugh,
  },
  {
    label: I_AM_GREAT_LABELS.greatestTalentIs,
    question: IAmGreatQuestion.greatestTalentIs,
  },
  { label: I_AM_GREAT_LABELS.peaceWhen, question: IAmGreatQuestion.peaceWhen },
];
