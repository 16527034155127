import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

type IResetPassword = Record<string, string>;

export function useResetPassword() {
  return useMutation<
    IApiResponse<IResetPassword>,
    IApiResponse<IResetPassword>,
    IResetPassword
  >("reset-password", async (data: IResetPassword) => {
    const response = await api.post<IApiResponse<IResetPassword>>(
      "/users/reset-password",
      data
    );

    if (response.status !== 201) {
      throw response.data;
    }

    return response.data;
  });
}
