import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

type WaitingListRequest = Record<string, string>;

interface ISendEmailResponse {
  id: string;
  email: string;
}

export function useDeleteAccount() {
  return useMutation<
    IApiResponse<ISendEmailResponse>,
    IApiResponse<ISendEmailResponse>,
    WaitingListRequest
  >("users", async (data: WaitingListRequest) => {
    const response = await api.post<IApiResponse<ISendEmailResponse>>(
      "users/delete-account",
      data
    );

    if (response.data.status !== "ok") {
      throw response.data;
    }

    return response.data;
  });
}
