import { Text, VStack, Button, Pressable, Spinner } from "native-base";
import React, { useRef, useState } from "react";

import { useActiveSubscription } from "../hooks/useActiveSubscription";
import { useCheckout } from "../hooks/useCheckout";
import { useMe } from "../hooks/useMe";
import { useReactivateSubscription } from "../hooks/useReactivateSubscription";
import { useVerifyEmailAgain } from "../hooks/useVerifyEmailAgain";
import { isWeb } from "../util/platform";

import { CheckoutStripe } from "./CheckoutStripe";
import { CancelMembershipModal } from "./modal/CancelMembershipModal";
import { CancelSubscriptionModal } from "./modal/CancelSubscriptionModal";

interface ICheckoutButtonProps {
  onShowStripe?: (token: string) => void;
}
export function CheckoutButton({ onShowStripe }: ICheckoutButtonProps) {
  const { data: me } = useMe();
  const checkoutRef = useRef(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isCancelMembershipModalOpen, setIsCancelMembershipModalOpen] =
    useState<boolean>(false);
  const { mutate: checkout, isLoading: isCheckoutLoading } = useCheckout();

  const { mutate: verifyEmail, isLoading: isVerifyEmailLoading } =
    useVerifyEmailAgain(true);

  const { isLoading: isActiveLoading, data: activeSubscription } =
    useActiveSubscription();
  const { mutate: reactivate, isLoading: isReactivateLoading } =
    useReactivateSubscription();

  const isActive = !activeSubscription?.isFree;
  function handleCheckout() {
    if (isActive && activeSubscription?.cancelAtPeriodEnd) {
      return reactivate({});
    }

    if (isActive && !activeSubscription?.cancelAtPeriodEnd) {
      return;
    }

    checkout(
      {},
      {
        onSuccess: (data) => {
          // @ts-ignore
          checkoutRef?.current?.checkout();

          if (onShowStripe) {
            onShowStripe(data.session);
          }
        },
        onError: () => {
          console.log("Could not checkout");
        },
      }
    );
  }

  function getButtonText(): string {
    if (isActive && activeSubscription?.cancelAtPeriodEnd) {
      return "Reactivate";
    }
    if (isActive) {
      return "Active now";
    }
    if (activeSubscription.isSpecialPrice) {
      return "Yes Please";
    }
    if (!me?.hasTrialed) {
      return "Start free trial";
    }

    return "Start plan";
  }

  const isLoading = isReactivateLoading || isCheckoutLoading || isActiveLoading;
  if (activeSubscription?.isPermanent) {
    return null;
  }

  const hancleCancelMembership = () => {
    verifyEmail(
      { email: me?.email },
      {
        onSuccess: () => {
          setIsCancelMembershipModalOpen(true);
        },
      }
    );
  };

  return (
    <>
      {isWeb() ? <CheckoutStripe ref={checkoutRef} /> : null}
      <CancelSubscriptionModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        cancelAtPeriodEnd={activeSubscription?.cancelAtPeriodEnd}
      />
      <CancelMembershipModal
        isOpen={isCancelMembershipModalOpen}
        onClose={() => setIsCancelMembershipModalOpen(false)}
        user={me}
      />
      <VStack space={2}>
        {isLoading ? (
          <Spinner size="lg" />
        ) : (
          <>
            <Button
              minWidth="50%"
              onPress={handleCheckout}
              isLoading={isLoading}
              pointerEvents={
                !activeSubscription?.isFree &&
                !activeSubscription?.cancelAtPeriodEnd
                  ? "none"
                  : undefined
              }
            >
              {getButtonText()}
            </Button>

            {!isLoading && !activeSubscription?.isFree ? (
              <Pressable
                onPress={() => setIsCancelModalOpen(true)}
                p={2}
                _pressed={{ opacity: 0.8 }}
              >
                <Text color="red.400" fontWeight={400}>
                  {activeSubscription?.cancelAtPeriodEnd
                    ? "Delete subscription"
                    : "Cancel subscription"}
                </Text>
              </Pressable>
            ) : null}
            {activeSubscription?.isSpecialPrice ? (
              <Button
                minWidth="50%"
                variant="outline"
                onPress={hancleCancelMembership}
                isLoading={isVerifyEmailLoading}
              >
                Cancel Membership
              </Button>
            ) : null}
          </>
        )}
      </VStack>
    </>
  );
}
