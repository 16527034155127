import { ContentEntity } from "api/src/content/entity/content.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useSearchContent(title: string) {
  return useQuery<ContentEntity[]>(
    ["content", "search", title],
    async () => {
      const response = await api.get<ContentEntity[]>("content/search", {
        params: { title },
      });

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get content");
    },
    {
      keepPreviousData: true,
      enabled: title.length > 0,
    }
  );
}
