import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";

import { EXPO_PUSH_NOTIFICATION_TOKEN_CACHED } from "../constants/storage";

import { confirmation } from "./confirmation";
import { isAndroid } from "./platform";

/**
 * Request the users permission to receieve push notifications.
 *
 * {@link https://docs.expo.io/versions/latest/sdk/notifications/} taken from the docs
 * @returns {Promise<string|null>}
 */
export async function registerForPushNotifications(): Promise<string | null> {
  let token = null;

  if (!Device.isDevice) {
    alert(
      "Must use a physical device for push notifications, they do not work via a emulator."
    );

    return token;
  }

  const { status: existingStatus } = await Notifications.getPermissionsAsync();

  let finalStatus = existingStatus;

  // The user wants to turn on push notifications
  // and enabled the permission when prompted.
  if (existingStatus !== "granted") {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }

  // The user wants to turn on push notifications
  // But they declined the push notification permission.
  if (finalStatus !== "granted") {
    confirmation(
      "Enable Push Notifications",
      "Notification permissions must be granted in order to enable the notifications on app. If you would like to enable them now then press again the enable notifications.",
      "OK",
      () => {
        // Do Nothing
      }
    );
    return null;
  }

  // No need to talk to expos api if we already know the token
  const existingToken = await AsyncStorage.getItem(
    EXPO_PUSH_NOTIFICATION_TOKEN_CACHED
  );

  if (existingToken) {
    token = existingToken;
  } else {
    token = (await Notifications.getExpoPushTokenAsync()).data;
  }

  if (isAndroid()) {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token;
}

/**
 * Has the user already granted the app notification permissions on a system level
 *
 * @returns {Promise<boolean>}
 */
export async function hasNotificationPermsEnabled(): Promise<boolean> {
  const result = await Notifications.getPermissionsAsync();
  return result.granted;
}

/**
 * regex for replacing gettinh url portion
 */
const urlRegex =
  /\[(.*?)\]\((http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+)\)(?!.*\(\1\))/;

export function getMarkDownContent(content: string) {
  const highlightedText = content?.replace(
    urlRegex,
    // eslint-disable-next-line quotes
    '<a href="$2" target="_blank" rel="noopener noreferrer" style="color: #DF3D8D; text-decoration: none;">$2</a>'
  );
  return highlightedText;
}
