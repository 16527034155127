import { Icon, IIconProps } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

export function LinkIcon(props: IIconProps) {
  return (
    <Icon viewBox="0 0 31 31" {...props}>
      <Svg width={31} height={31} fill="none">
        <Path
          d="m14.816 8.65 2.397-2.398a5.329 5.329 0 1 1 7.535 7.535l-3.425 3.425a5.326 5.326 0 0 1-7.535 0"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="m16.185 22.35-2.397 2.397a5.328 5.328 0 1 1-7.535-7.535l3.425-3.425a5.326 5.326 0 0 1 7.535 0"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
