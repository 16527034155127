import AnimatedLottieView from "lottie-react-native";
import {
  Box,
  Center,
  Heading,
  useBreakpointValue,
  useColorModeValue,
  useToken,
  View,
  VStack,
  Text,
} from "native-base";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Animated } from "react-native";
import { View as AnimatedView } from "react-native-animatable";

import { Container } from "../../components/Container";
import { Button } from "../../components/elements/Button";
import { MenuBarIcon } from "../../components/icons/MenuBarIcon";
import { SecondLoginContext } from "../../context/SecondLoginContext";
import { TransitionContext } from "../../context/TransitionContext";
import { SecondLoginStepsType } from "../../enum/second-login-steps";
import { useCompleteLoginActivity } from "../../hooks/useCompleteLoginActivity";

import { SecondLoginSteps } from "./SecondLoginSteps";

const inAnimation = "fadeInDown";

export function Help() {
  const colourAnimation = useRef(new Animated.Value(0)).current;
  const [, setButtonTopOffset] = useState<number>(0);
  const [, setIsTransitioning] = useState<boolean>(false);
  const { setCurrentStep } = useContext(SecondLoginContext);
  const { mutateAsync: completeLogin } = useCompleteLoginActivity();

  useEffect(() => {
    handleAnimation();
    setCurrentStep(SecondLoginStepsType.help);
  }, []);

  function handleAnimation() {
    Animated.timing(colourAnimation, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
    }).start(() => {
      setIsTransitioning(false);
    });
  }

  const [primary400, gray100, dark500] = useToken("colors", [
    "primary.400",
    "secondary.200",
    "dark.500",
  ]);
  const backgroundColor = useColorModeValue(gray100, dark500);

  const backgroundColourValue = colourAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [primary400, backgroundColor],
  });

  const backgroundColorBreakpoint = useBreakpointValue({
    base: "white",
    lg: backgroundColourValue,
  });

  const { startTransition } = useContext(TransitionContext);
  const [animationState] = useState<"In" | "OutDown">("In");

  return (
    <>
      <Animated.View
        style={{
          backgroundColor: backgroundColorBreakpoint,
        }}
      >
        <Container
          _dark={{ bg: "dark.100" }}
          minH="100vh"
          justifyContent="center"
        >
          <Box
            alignSelf="center"
            p={10}
            width={{ base: "unset", lg: "70%" }}
            bg="white"
            _dark={{ bg: "dark.100" }}
            borderRadius={5}
          >
            <AnimatedView animation="fadeIn" delay={750} duration={750}>
              <SecondLoginSteps />
            </AnimatedView>
            <VStack px={{ base: 0, lg: 4 }} pt={5} space={8} marginTop={-30}>
              <AnimatedView animation={inAnimation} delay={25} duration={750}>
                <View
                  flex={1}
                  onLayout={(event) => {
                    const { layout } = event.nativeEvent;
                    setButtonTopOffset(layout.height / 2 + layout.y);
                  }}
                >
                  <Center>
                    <AnimatedLottieView
                      source={require("../../assets/animations/character.json")}
                      speed={0.75}
                      style={{ width: 400, height: 450 }}
                      autoPlay={true}
                      loop={true}
                    />
                  </Center>
                </View>
              </AnimatedView>

              <View
                pb={10}
                flex="1"
                justifyContent="center"
                px={{ base: 0, lg: 10 }}
                marginTop={-70}
              >
                <VStack space={8}>
                  <AnimatedView
                    style={{ width: "100%" }}
                    animation={`fadeInDown${
                      animationState === "In" ? "" : "Out"
                    }`}
                    delay={1000 + 300 + 400}
                    duration={1000}
                  >
                    <Heading fontSize={28} fontWeight="600" textAlign="center">
                      We here to help!
                    </Heading>
                  </AnimatedView>
                  <VStack space={2}>
                    <AnimatedView
                      style={{ width: "100%", alignItems: "center" }}
                      animation={`fadeInLeft${
                        animationState === "In" ? "" : "Out"
                      }`}
                      delay={1000 + 300 + 500}
                      duration={1500}
                    >
                      <Text textAlign="center" lineHeight={24} fontSize={16}>
                        We have a host of CBT and breathing exercises known to
                        help improve mental wellbeing for you to use. We’ll
                        leave you to explore the app now. if you would like to
                        see what other tools you have at your disposal, then
                        just click on the menu icon.
                      </Text>
                      <MenuBarIcon m={5} size="6xl" />
                    </AnimatedView>
                  </VStack>
                  <AnimatedView
                    style={{ width: "100%" }}
                    animation={`fade${
                      animationState === "In" ? "InUp" : "OutDown"
                    }`}
                    delay={1000 + 300 + 600}
                    duration={2000}
                  >
                    <Center mt={6}>
                      <Button
                        px="4"
                        minW="180"
                        variant="outline"
                        onPress={async () => {
                          await completeLogin({ login: "second" });
                          startTransition({
                            screen: "TrackHabits",
                          });
                        }}
                      >
                        Go To My Habits
                      </Button>
                    </Center>
                    <Center mt={6}>
                      <Button
                        px="4"
                        minW="180"
                        variant="outline"
                        onPress={() =>
                          startTransition({
                            screen: "Digesting",
                          })
                        }
                      >
                        Explore my Feed
                      </Button>
                    </Center>
                  </AnimatedView>
                </VStack>
              </View>
            </VStack>
          </Box>
        </Container>
      </Animated.View>
    </>
  );
}
