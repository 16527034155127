import { HabitStreakEntity } from "api/src/habit/entities/habit-streak.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useHabitStreak(habitId: string | undefined) {
  return useQuery<HabitStreakEntity[]>(
    ["habit-streak", habitId],
    async () => {
      const response = await api.get<HabitStreakEntity[]>(
        `habit/streak/${habitId}`,
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Couldn't fetch streak habits");
    },
    {
      keepPreviousData: true,
    }
  );
}
