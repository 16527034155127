import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { FirstLoginContextProvider } from "../components/context/FirstLoginContextProvider";
import { TransitionContextProvider } from "../components/context/TransitionContextProvider";
import { AffirmationScreen } from "../screens/AffirmationScreen";
import { DigestMediaScreen } from "../screens/first-login/MediaSelectionScreen";
import { TakeLookScreen } from "../screens/first-login/TakeLookScreen";
import { WelcomeScreen } from "../screens/first-login/WelcomeScreen";

import { DigestingScreen } from "./DigestingScreen";

const Auth = createStackNavigator();

export function FirstLoginNavigator() {
  return (
    <>
      <TransitionContextProvider>
        <FirstLoginContextProvider>
          <Auth.Navigator
            initialRouteName="FirstLoginWelcome"
            screenOptions={{
              headerShown: false,
            }}
          >
            <Auth.Screen name="FirstLoginWelcome" component={WelcomeScreen} />
            <Auth.Screen
              name="FirstLoginDigestMedia"
              component={DigestMediaScreen}
            />
            <Auth.Screen name="FirstLoginTakeLook" component={TakeLookScreen} />
            <Auth.Screen name="Digesting" component={DigestingScreen} />
            <Auth.Screen name="Affirmation" component={AffirmationScreen} />
          </Auth.Navigator>
        </FirstLoginContextProvider>
      </TransitionContextProvider>
    </>
  );
}
