import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function SearchIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 -1 30 34" {...props}>
      <Svg width={32} height={32} fill="none" stroke={color}>
        <Path
          d="M10.5 14.5h8M14.5 10.5v8M14.5 25C20.299 25 25 20.299 25 14.5 25 8.7 20.299 4 14.5 4S4 8.7 4 14.5C4 20.299 8.701 25 14.5 25ZM21.924 21.925 27.999 28"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
