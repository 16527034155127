import { JournalEntryDto } from "api/src/journal/dto/journal-entry.dto";
import { useMutation, useQueryClient } from "react-query";

import { EVENT_ADD_JOURNAL } from "../constants/analytics";
import { eventHit } from "../util/analytics";
import { api } from "../util/api";

export function useJournalSubmit(journalId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation<null, null, JournalEntryDto>(
    "journal-submit",
    async (data: JournalEntryDto) => {
      const method = !journalId ? "post" : "put";
      const response = await api[method](`journal/${journalId || ""}`, data);

      if (response?.status >= 400) {
        throw Promise.reject("Could not save/edit journal entry");
      }

      return response.data;
    },
    {
      onSuccess: () => {
        eventHit("submit", EVENT_ADD_JOURNAL);
        queryClient.invalidateQueries(["journal", journalId]);
        queryClient.invalidateQueries(["journal-list"]);
      },
    }
  );
}
