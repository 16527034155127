import { ViewContentDto } from "api/src/content/dto/view-content.dto";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

export function useAddViewedContent() {
  const queryClient = useQueryClient();
  return useMutation<null, null, ViewContentDto>(
    "viewed-content",
    async (data: ViewContentDto) => {
      const response = await api.post("content/viewed", data);

      if (response?.status > 400) {
        throw Promise.reject("Could not add viewed content");
      }

      queryClient.invalidateQueries(["viewed-content", "viewed-content-ids"]);

      return response.data;
    }
  );
}
