import { useNavigation } from "@react-navigation/core";
import { View, VStack } from "native-base";
import React from "react";
import { useForm } from "react-hook-form";

import { AuthButton as Button } from "../components/auth/AuthButton";
import { AuthCard } from "../components/auth/AuthCard";
import { AuthInput } from "../components/auth/AuthInput";
import { ScrollView } from "../components/elements/ScrollView";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import { useChangePassword } from "../hooks/useChangePassword";
import { useFormErrors } from "../hooks/useFormErrors";
import { ToastStatus, useToast } from "../hooks/useToast";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";
import { isWeb } from "../util/platform";

export function ChangePasswordScreen() {
  const toast = useToast();
  const { navigate } = useNavigation();
  const { mutate: changePassword, isLoading } = useChangePassword();

  const {
    reset,
    control,
    formState: { errors },
    clearErrors,
    setError,
    getValues,
  } = useForm();
  const { setErrors } = useFormErrors(setError);

  function onSubmit(data: Record<string, string>) {
    clearErrors();

    if (data.newPassword !== data.RetypePassword) {
      setError("RetypePassword", { message: "Password does not match" });
      return;
    }
    changePassword(
      { ...data },
      {
        onSuccess: (data) => {
          if (data.status === "ok") {
            reset();
            navigate(isWeb() ? "Home" : "App");
            toast({
              title: data.message,
              status: ToastStatus.success,
            });
          } else {
            toast({
              title: data.message,
              status: ToastStatus.error,
            });
          }
        },
        onError: (data) => {
          setErrors(data.errors ?? data ?? {});
        },
      }
    );
  }

  return (
    <>
      <Header hasBack={!isWeb()} />

      <Body>
        <ScrollView
          pt="10"
          contentContainerStyle={{ justifyContent: "center" }}
          _web={{ _dark: { bg: "dark.500" } }}
        >
          <WhiteBoxContainer>
            <AuthCard label="Change Password">
              <VStack space={5} _web={{ mt: 5 }}>
                <AuthInput
                  name="currentPassword"
                  placeholder="Current password"
                  control={control}
                  errors={errors}
                  label="Current Password"
                  keyboardType="default"
                  type="password"
                />

                <AuthInput
                  px={5}
                  name="newPassword"
                  borderWidth={0}
                  type="password"
                  control={control}
                  errors={errors}
                  placeholder="New Password"
                />
                <AuthInput
                  px={5}
                  name="RetypePassword"
                  borderWidth={0}
                  type="password"
                  control={control}
                  errors={errors}
                  placeholder="Retype New Password"
                />

                <View
                  justifyContent="center"
                  alignItems="center"
                  _web={{ flexDirection: "row" }}
                >
                  {isWeb() ? (
                    <Button
                      py={4}
                      mb={2}
                      variant="outline"
                      onPress={() => {
                        reset();
                        navigate(isWeb() ? "Home" : "App");
                      }}
                      _web={{ maxW: 200, mx: 2 }}
                    >
                      Cancel
                    </Button>
                  ) : null}
                  <Button
                    mb={2}
                    py={4}
                    variant="solid"
                    isLoading={isLoading}
                    onPress={() => onSubmit(getValues())}
                    _web={{ maxW: 200, mx: 2 }}
                  >
                    Update
                  </Button>
                </View>
              </VStack>
            </AuthCard>
          </WhiteBoxContainer>
        </ScrollView>
      </Body>
    </>
  );
}
