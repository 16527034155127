import { GOOGLE_ANALYTICS_TRACKING_ID } from "@env";
import { Analytics, PageHit, Event } from "expo-analytics";

export const analytics = new Analytics(GOOGLE_ANALYTICS_TRACKING_ID);

/**
 * Sends a page hit event to the analytics service.
 *
 * @param page - The name or URL of the page being hit.
 *
 * @returns A Promise that resolves when the event has been sent.
 */
export const pageHit = async (page: string) => analytics.hit(new PageHit(page));

/**
 * Sends an event to the Expo analytics service.
 *
 * @param category - The event category.
 * @param eventName - The name of the event.
 *
 * @returns A promise that resolves when the event is sent.
 */
export const eventHit = async (category: string, eventName: string) =>
  analytics.event(new Event(category, eventName));
