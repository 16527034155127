import AsyncStorage from "@react-native-async-storage/async-storage";
import { ContentType } from "api/src/content/enum/ContentType";
import { AlertLevel } from "api/src/signposting/enum/AlertLevel";
import { HStack, VStack, View, Divider } from "native-base";
import React, { useEffect, useState } from "react";

import { AppInfoPopUp } from "../components/AppInfoPopup";
import { ButtonDirectionToggle } from "../components/ButtonDirectionToggle";
import { Container } from "../components/Container";
import { ContentContainer } from "../components/content/ContentContainer.web";
import { ContentSliderWeb } from "../components/ContentSliderWeb";
import { HighAlertBanner } from "../components/HighAlertBanner";
import { Loading } from "../components/Loading";
import { PaymentReminderPopUp } from "../components/PaymentReminderPopup";
import { WellDonePopup } from "../components/wellbeing-checker/WellDonePopup";
import {
  PAYMENT_REMINDER_SCREEN_KEY,
  WELLBEING_WELL_DONE_SCREEN_KEY,
} from "../constants/storage";
import { useFlaggedUser } from "../hooks/useFlaggedUser";
import { useMe } from "../hooks/useMe";
import { useRecommendedContent } from "../hooks/useRecommendedContent";
import { Header } from "../layout/Header";

export function HomeScreen() {
  const { data: recommendedContent, isLoading } = useRecommendedContent();
  const [direction, setDirection] = useState<"column" | "row">("column");
  const { data: flaggedUser } = useFlaggedUser();
  const [isWellDone, setIsWellbeing] = useState(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { data: user } = useMe();

  useEffect(() => {
    (async () => {
      const wellbeingWellDone = await AsyncStorage.getItem(
        WELLBEING_WELL_DONE_SCREEN_KEY
      );
      if (wellbeingWellDone === "show") {
        setIsWellbeing(true);
      }
      setTimeout(async () => {
        const paymentReminderValue = await AsyncStorage.getItem(
          PAYMENT_REMINDER_SCREEN_KEY
        );
        if (paymentReminderValue === "show") {
          setModalVisible(true);
        }
      }, 10);
    })();
  }, [user?.hasOnboarded]);

  if (isLoading) {
    return <Loading showHeader={true} />;
  }

  const carousel = recommendedContent?.content?.slice(0, 1) || [];
  const content = recommendedContent?.content?.slice(1) || [];

  return (
    <>
      <Header />
      <Container>
        <View p={10} width="100%" bg="white" _dark={{ bg: "dark.100" }}>
          <WellDonePopup
            setModalVisible={setIsWellbeing}
            modalVisible={isWellDone}
          />
          <AppInfoPopUp isWellDone={isWellDone} />
          <PaymentReminderPopUp
            accountType={user?.accountType}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
          />
          <HStack>
            <VStack w="100%" space={2}>
              <ContentSliderWeb items={carousel} />
            </VStack>
          </HStack>

          <ButtonDirectionToggle
            direction={direction}
            onPress={(direction) => setDirection(direction)}
          />

          {flaggedUser?.alertLevel === AlertLevel.high ||
          flaggedUser?.alertLevel === AlertLevel.medium ? (
            <HighAlertBanner alertLevel={flaggedUser?.alertLevel} />
          ) : null}
          <Divider orientation="horizontal" mt={2} />

          <ContentContainer
            data={content}
            direction={direction}
            type={ContentType.article}
          />
          <ContentContainer
            data={content}
            direction={direction}
            type={ContentType.podcast}
          />
          <ContentContainer
            data={content}
            direction={direction}
            type={ContentType.video}
          />

          {recommendedContent?.sections.slice(1).map((section, index) => (
            <ContentContainer
              data={section.content}
              direction={direction}
              hideIcon={true}
              label={section.channel?.label}
              key={index}
            />
          ))}
        </View>
      </Container>
    </>
  );
}
