import { JournalEntryType } from "api/src/journal/enum/JournalEntryType";

import { JournalDetailsScreen } from "../enum/journal-details-screen";

export const JOURNAL_DETAILS_SCREENS: Record<
  JournalEntryType,
  JournalDetailsScreen
> = {
  [JournalEntryType.fiveMinute]: JournalDetailsScreen.fiveMinuteDetails,
  [JournalEntryType.gratitude]: JournalDetailsScreen.gratitudeDetails,
  [JournalEntryType.iAmGreat]: JournalDetailsScreen.iAmGreatDetails,
  [JournalEntryType.diary]: JournalDetailsScreen.diaryDetails,
};
