import { IChannel, ISubChannel } from "api/src/channels/interface/IChannel";
import { isEmpty } from "lodash";

import {
  DEFAULT_CHANNEL_IMAGES,
  SUB_CHANNEL_IMAGES,
} from "../constants/channel-images";
import { RequiredImageType } from "../type/RequiredImageType";

/**
 * Get channel image
 *
 * @param channel - The channel to get the image for
 *
 * @returns 'require'd channel image
 */
export function getChannelImage(channel: IChannel): RequiredImageType {
  return DEFAULT_CHANNEL_IMAGES[channel.slug];
}

export function getSubChannelImage(
  channel: IChannel,
  subChannel: ISubChannel
): RequiredImageType {
  return SUB_CHANNEL_IMAGES[`${channel.slug}-${subChannel.slug}`];
}
/**
 * Get channel label for content
 *
 * @param channels - Array of channel
 * @return channels label
 */
export function getChannelsLabel(channels?: IChannel[]) {
  return !isEmpty(channels)
    ? channels?.slice(0, 1).map((channel) => channel?.label)
    : "";
}
