import { createContext } from "react";

import { SecondLoginStepsType } from "../enum/second-login-steps";

export interface ISecondLoginContext {
  currentStep: SecondLoginStepsType | null;
  setCurrentStep: (step: SecondLoginStepsType | null) => void;
}

export const SecondLoginContext = createContext<ISecondLoginContext>({
  currentStep: null,
  setCurrentStep: () => {},
});
