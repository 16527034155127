import { isEmpty } from "lodash";
import { Box, VStack, HStack, Input, Spinner, Center } from "native-base";
import React, { useState } from "react";
import { TouchableOpacity } from "react-native-gesture-handler";

import { useAddDepartment } from "../../../hooks/useAddDepartment";
import { useMe } from "../../../hooks/useMe";
import { useOrganisationDepartments } from "../../../hooks/useOrganisationDepartments";
import { ToastStatus, useToast } from "../../../hooks/useToast";
import { Header } from "../../../layout/Header";
import { useBackgroundColor } from "../../../util/theme-helper";
import { Container } from "../../Container";
import { AddIcon } from "../../icons/AddIcon";
import { ArrowIcon } from "../../icons/ArrowIcon";
import { Loading } from "../../Loading";
import { InviteCode } from "../invitations/InviteCode";
import { OrganisationTabs } from "../OrganisationTabs";

import { ReviewableDepartment } from "./ReviewableDepartment";

export function Departments() {
  const [newDepartment, setNewDepartment] = useState<string>("");
  const { data: user } = useMe();
  const toast = useToast();

  const { data: organisationDepartment, isLoading } =
    useOrganisationDepartments();
  const { mutate: addDepartment, isLoading: isNewLoading } = useAddDepartment();

  function handleAddDepartment() {
    if (isEmpty(newDepartment)) {
      toast({ title: "Department cannot be empty", status: ToastStatus.error });
      return;
    }

    addDepartment(
      { newDepartment },
      {
        onSuccess: (data) => {
          setNewDepartment("");
          toast({ title: data.message, status: ToastStatus.success });
        },
        onError: (data) => {
          setNewDepartment("");
          toast({
            title: data.errors.message || "Department cannot be added",
            status: ToastStatus.success,
          });
        },
      }
    );
  }
  const { backgroundColor } = useBackgroundColor();

  return (
    <>
      <Header hasBack={false} />
      <Container
        size="container.xl"
        h="auto"
        minH="94vh"
        pt={10}
        bg={backgroundColor}
      >
        <Box h="100%" w="100%">
          <OrganisationTabs activeTab="Departments" />
          <InviteCode user={user} mr="10" />

          <VStack padding={10} space={5} w="100%">
            {isLoading ? (
              <Loading />
            ) : (
              <VStack space={5}>
                <HStack flexDirection="row" flexWrap="wrap" space="5">
                  <Input
                    variant="rounded"
                    placeholder="Add Department"
                    borderColor="primary.400"
                    p="3"
                    value={newDepartment}
                    onChangeText={(text: string) => {
                      setNewDepartment(text);
                    }}
                    InputLeftElement={
                      <AddIcon size="md" pl="3" color="primary.400" />
                    }
                  />
                  <Center>
                    {isNewLoading ? (
                      <Spinner />
                    ) : (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={handleAddDepartment}
                      >
                        <ArrowIcon size="md" />
                      </TouchableOpacity>
                    )}
                  </Center>
                </HStack>
                <HStack flexDirection="row" flexWrap="wrap" space="5">
                  {organisationDepartment?.map((department, index) => (
                    <ReviewableDepartment department={department} key={index} />
                  ))}
                </HStack>
              </VStack>
            )}
          </VStack>
        </Box>
      </Container>
    </>
  );
}
