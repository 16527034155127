import { useNavigation } from "@react-navigation/core";
import { HabitEntity } from "api/src/habit/entities/habit.entity";
import dayjs from "dayjs";
import { Box, HStack, Text, useToken, VStack } from "native-base";
import React, { useMemo, useState } from "react";
import { Pressable } from "react-native";

import { useDeleteHabit } from "../hooks/useHabitDelete";
import { useHabitStreak } from "../hooks/useHabitStreak";
import { useHabitStreakAdd } from "../hooks/useHabitStreakAdd";
import { ToastStatus, useToast } from "../hooks/useToast";

import { AlertDialog } from "./elements/AlertDialog";
import { Icon, IconLibrary } from "./elements/Icon";
import { IconButton } from "./elements/IconButton";
import { AddIcon } from "./icons/AddIcon";
import { RoundCheckIcon } from "./icons/RoundCheckIcon";

interface IHabitCardProps {
  item: Partial<HabitEntity>;
}

export function HabitCard({ item }: IHabitCardProps) {
  const { navigate } = useNavigation();
  const [primary400] = useToken("colors", ["primary.400"]);
  const toast = useToast();
  const { mutate: deleteHabit } = useDeleteHabit(item.id);
  const { mutate: addHabitStreak } = useHabitStreakAdd(item.id);
  const { data: habitStreak } = useHabitStreak(item.id);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = () => setIsOpen(false);

  function handleDelete() {
    deleteHabit(
      {
        habitId: item.id,
      },
      {
        onSuccess: () => {
          toast({ title: "Habit Deleted", status: ToastStatus.success });
          onClose();
        },
        onError: () => {
          toast({ title: "Couldn't delete habit", status: ToastStatus.error });
        },
      }
    );
  }

  function handleAddStreak() {
    addHabitStreak(
      {
        habitId: item.id,
      },
      {
        onSuccess: () => {
          toast({ title: "Streak Added", status: ToastStatus.success });
          onClose();
        },
        onError: () => {
          toast({ title: "Couldn't delete streak", status: ToastStatus.error });
        },
      }
    );
  }

  const isDayCompleted = useMemo(
    () =>
      !!habitStreak?.find(({ createdAt }) => dayjs().isSame(createdAt, "date")),
    [habitStreak]
  );

  const daysLeft = useMemo(() => {
    const startDate = dayjs(item?.createdAt);
    const daysPassed = Math.ceil(dayjs().diff(startDate, "day", true));
    return (item?.daysToStreak || 10) - daysPassed;
  }, [item, isDayCompleted]);

  const isHabitCompleted = daysLeft === 0;

  return (
    <>
      <Box width="100%">
        <HStack
          bg="secondary.100"
          _dark={{ bg: "dark.300" }}
          justifyContent="space-evenly"
          alignItems="center"
          minWidth={200}
          rounded="xl"
          p={2}
        >
          <Box height="40px" width="40px">
            {!isDayCompleted ? (
              <IconButton onPress={handleAddStreak}>
                <AddIcon size="10" />
              </IconButton>
            ) : (
              <RoundCheckIcon color={primary400} size="12" />
            )}
          </Box>
          <VStack space="1" alignItems="flex-start" px="5" pr={2} flex={1}>
            <HStack
              justifyContent="space-between"
              flex={1}
              space={3}
              w="100%"
              alignItems="center"
            >
              <Text fontSize="16" textAlign="center" fontWeight="400">
                {item.name}
              </Text>
              <Pressable onPress={() => setIsOpen(true)}>
                <Icon
                  icon="times"
                  iconLibrary={IconLibrary.fontAwesome5}
                  color="secondary.400"
                  size={4}
                />
              </Pressable>
            </HStack>
            <HStack alignItems="flex-start" space={2}>
              <Text
                color="secondary.500"
                onPress={() =>
                  navigate("HabitDetails", { habitId: item?.id ?? "" })
                }
              >
                {!isHabitCompleted ? "View" : "Completed"}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </Box>
      <AlertDialog
        headerText="Delete Habit"
        bodyText="This will remove your habit. Are you sure you want to
          delete it?"
        buttonText="Delete"
        buttonAction={handleDelete}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}
