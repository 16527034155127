import { SubscriptionEntity } from "api/src/subscription/entity/subscription.entity";
import { ISubscription } from "api/src/subscription/interface/ISubscription";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useActiveSubscription() {
  return useQuery<ISubscription | undefined>(
    ["active-subscriptions"],
    async () => {
      const response = await api.get<SubscriptionEntity>(
        "subscriptions/active",
        {}
      );

      if (response?.status !== 200) {
        return undefined;
      }

      return response.data;
    },
    {
      keepPreviousData: true,
    }
  );
}
