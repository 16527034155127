import { Center } from "native-base";
import React from "react";

import { isWeb } from "../util/platform";

import { Text } from "./core/Text";

export interface IEmptyProps {
  title: string;
  message?: string;
  isVisible?: boolean;
  height?: string | number;
}

export function Empty({
  title,
  message,
  isVisible = true,
  height = isWeb() ? "60vh" : "80%",
}: IEmptyProps) {
  if (isVisible) {
    return (
      <Center h={height}>
        <Text fontSize={18} color="secondary.500">
          {title}
        </Text>
        <Text fontSize={16} color="secondary.400">
          {message}
        </Text>
      </Center>
    );
  }
  return <></>;
}
