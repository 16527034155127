import { useColorModeValue, useToken } from "native-base";

function useTokenColors(): {
  primary400: string;
  secondary200: string;
  secondary400: string;
  secondary650: string;
  secondary850: string;
  dark100: string;
  dark500: string;
} {
  const [
    primary400,
    secondary200,
    secondary400,
    secondary650,
    secondary850,
    dark100,
    dark500,
  ] = useToken("colors", [
    "primary.400",
    "secondary.200",
    "secondary.400",
    "secondary.650",
    "secondary.850",
    "dark.100",
    "dark.500",
  ]);

  return {
    primary400,
    secondary200,
    secondary400,
    secondary650,
    secondary850,
    dark100,
    dark500,
  };
}

export function useBackgroundColor(): {
  backgroundColor: string;
  backgroundColorDarker: string;
  backgroundColorWeb: string;
} {
  const { dark100, dark500, secondary200 } = useTokenColors();
  const backgroundColor = useColorModeValue("white", dark100);
  const backgroundColorDarker = useColorModeValue("white", dark500);
  const backgroundColorWeb = useColorModeValue(secondary200, dark500);

  return { backgroundColor, backgroundColorDarker, backgroundColorWeb };
}

export function useTextColor(): {
  defaultTextColor: string;
  fadedTextColor: string;
} {
  const { secondary650, secondary400, secondary850 } = useTokenColors();
  const defaultTextColor = useColorModeValue(secondary850, secondary400);
  const fadedTextColor = useColorModeValue(secondary850, secondary650);

  return { defaultTextColor, fadedTextColor };
}

export function useMoodIconColors(): {
  faceFillColor: string;
  faceStrokeColor: string;
  eyesFillColor: string;
  mouthFillColor: string;
} {
  const { primary400, dark100 } = useTokenColors();

  const faceFillColor = useColorModeValue("#FEEFF7", dark100);
  const faceStrokeColor = useColorModeValue("#E66CA9", primary400);
  const eyesFillColor = useColorModeValue("#FFD2E9", primary400);
  const mouthFillColor = useColorModeValue("#FFD2E9", primary400);

  return { faceFillColor, faceStrokeColor, eyesFillColor, mouthFillColor };
}
