import { UNIDAYS_REDIRECT_URI, UNIDAYS_CLIENT_ID } from "@env";
import { last } from "lodash";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import WebView from "react-native-webview";

import { useLoginRedirect } from "../../hooks/useLoginRedirect";
import { isWeb } from "../../util/platform";

export function UnidaysScreen() {
  const onLoginRedirect = useLoginRedirect();

  function getUnidaysOAuthUrl(): string {
    const params = new URLSearchParams();

    params.append("response_type", "code");
    params.append("client_id", UNIDAYS_CLIENT_ID);
    params.append("redirect_uri", UNIDAYS_REDIRECT_URI);
    params.append("scope", "openid name email verification offline_access");

    return `https://account.myunidays.com/oauth/authorize?${params.toString()}`;
  }

  const INJECTED_JAVASCRIPT = `(function() {
    const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta);
  })();`;

  async function handleLoadEvent({ url }: { url: string }) {
    const isSuccess = url.includes("/unidays-success");

    if (isSuccess) {
      const token = last(url.match(/loginToken=(.*)/));
      if (token) {
        (await onLoginRedirect).navigate(token);
      }
    }
  }

  return (
    <>
      {!isWeb() ? (
        <SafeAreaView style={{ flex: 1 }}>
          <WebView
            source={{ uri: getUnidaysOAuthUrl() }}
            injectedJavaScript={INJECTED_JAVASCRIPT}
            incognito={true}
            onNavigationStateChange={handleLoadEvent}
            onMessage={() => {}}
          />
        </SafeAreaView>
      ) : (
        (window.location.href = getUnidaysOAuthUrl())
      )}
    </>
  );
}
