export const APP_MEDIA_BASE = "https://res.cloudinary.com/tlero/image/upload/";

/**
 * Generates the URL for a given image ID.
 *
 * @param id - The ID of the image to generate the URL for.
 * @returns The generated URL for the image, or `undefined` if no ID was provided.
 */
export function getImage(id: string | undefined): string | undefined {
  if (!id) {
    return undefined;
  }
  return `${APP_MEDIA_BASE}${id}`;
}
