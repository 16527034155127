import { CommonActions, useNavigation } from "@react-navigation/native";
import { AlertDialog as NbAlertDialog, HStack } from "native-base";
import React, { useRef } from "react";

import { useCancelSubscription } from "../../hooks/useCancelSubscription";
import { Button } from "../elements/Button";

interface IAlertDialogProps {
  isOpen: boolean;
  cancelAtPeriodEnd?: boolean;
  onClose: () => void;
}

export function CancelSubscriptionModal({
  cancelAtPeriodEnd,
  isOpen,
  onClose,
}: IAlertDialogProps) {
  const cancelRef = useRef<typeof Button>(null);
  const { mutate: subscriptionCancel, isLoading } = useCancelSubscription();
  const { dispatch } = useNavigation();

  return (
    <NbAlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <NbAlertDialog.Content>
        <NbAlertDialog.CloseButton />
        <NbAlertDialog.Header>
          {cancelAtPeriodEnd ? "Delete subscription" : "Disable auto-renew"}
        </NbAlertDialog.Header>
        <NbAlertDialog.Body>
          {cancelAtPeriodEnd
            ? "This will cancel your subscription immediately and you will loose access to all features immediately. This action is irreversible."
            : "Are you sure you wish to disable auto-renew? You will still be able to use your subscription until the day it expires."}
        </NbAlertDialog.Body>
        <NbAlertDialog.Footer>
          <HStack flexBasis="100%" justifyContent="space-between">
            <Button variant="outline" ref={cancelRef} onPress={onClose}>
              Cancel
            </Button>
            <HStack space={[2, 0]}>
              <Button
                onPress={() => {
                  subscriptionCancel(
                    { instant: cancelAtPeriodEnd },
                    {
                      onSuccess: () => {
                        if (cancelAtPeriodEnd) {
                          dispatch(
                            CommonActions.reset({
                              index: 0,
                              routes: [{ name: "SubscriptionMain" }],
                            })
                          );
                        }

                        return onClose();
                      },
                    }
                  );
                }}
                bg="red.600"
                isLoading={isLoading}
                px={5}
              >
                {cancelAtPeriodEnd
                  ? "Delete subscription"
                  : "Disable auto-renew"}
              </Button>
            </HStack>
          </HStack>
        </NbAlertDialog.Footer>
      </NbAlertDialog.Content>
    </NbAlertDialog>
  );
}
