import { Icon, useToken } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function ArrowIcon({ color, ...props }: IIconProps) {
  const [primary400] = useToken("colors", ["primary.400"]);
  return (
    <Icon viewBox="0 0 41 40" {...props}>
      <Svg width={41} height={40} color={color || primary400}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2974 40C25.6017 40 30.6888 37.8929 34.4395 34.1421C38.1902 30.3914 40.2974 25.3043 40.2974 20C40.2974 14.6957 38.1902 9.60859 34.4395 5.85786C30.6888 2.10714 25.6017 0 20.2974 0C14.993 0 9.90596 2.10714 6.15523 5.85786C2.4045 9.60859 0.297363 14.6957 0.297363 20C0.297363 25.3043 2.4045 30.3914 6.15523 34.1421C9.90596 37.8929 14.993 40 20.2974 40ZM29.5649 18.2325L22.0649 10.7325C21.5934 10.2771 20.9619 10.0251 20.3064 10.0308C19.6509 10.0365 19.0238 10.2994 18.5603 10.763C18.0968 11.2265 17.8339 11.8535 17.8282 12.509C17.8225 13.1645 18.0745 13.796 18.5299 14.2675L21.7624 17.5H12.7974C12.1343 17.5 11.4984 17.7634 11.0296 18.2322C10.5608 18.7011 10.2974 19.337 10.2974 20C10.2974 20.663 10.5608 21.2989 11.0296 21.7678C11.4984 22.2366 12.1343 22.5 12.7974 22.5H21.7624L18.5299 25.7325C18.2911 25.9631 18.1006 26.239 17.9696 26.544C17.8386 26.849 17.7696 27.177 17.7667 27.509C17.7639 27.8409 17.8271 28.1701 17.9528 28.4774C18.0785 28.7846 18.2641 29.0638 18.4989 29.2985C18.7336 29.5332 19.0127 29.7189 19.32 29.8446C19.6272 29.9703 19.9564 30.0335 20.2884 30.0306C20.6203 30.0277 20.9484 29.9588 21.2534 29.8278C21.5584 29.6967 21.8342 29.5063 22.0649 29.2675L29.5649 21.7675C30.0335 21.2987 30.2968 20.6629 30.2968 20C30.2968 19.3371 30.0335 18.7013 29.5649 18.2325Z"
          fill={color}
        />
      </Svg>
    </Icon>
  );
}
