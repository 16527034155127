import React from "react";

import { DrawerNavigationMenu } from "../components/DrawerNavigationMenu";
import { Header } from "../layout/Header";
import { isNotWeb } from "../util/platform";

/** TODO: MAKE IT MORE DYNAMIC
 *  - Refactor into a SectionList
 * */

export function MyPageScreen() {
  return (
    <>
      {isNotWeb() ? <Header hasBack={true} /> : null}
      <DrawerNavigationMenu />
    </>
  );
}
