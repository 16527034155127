import React from "react";
import { Path, Svg, Circle } from "react-native-svg";

export function TransIcon() {
  return (
    <Svg width="39" height="84" viewBox="0 0 39 84" fill="none">
      <Circle
        cx="19.3794"
        cy="44.4141"
        r="17"
        stroke="#EF3F95"
        stroke-width="4"
      />
      <Path
        d="M11.3794 74.4141H26.3794"
        stroke="#DF3D8D"
        stroke-width="3"
        stroke-linecap="round"
      />
      <Path
        d="M19.3794 82.4141L19.3794 63.4141"
        stroke="#DF3D8D"
        stroke-width="3"
        stroke-linecap="round"
      />
      <Path
        d="M1.17675 17.9468C1.51835 17.6078 1.93453 17.454 2.42527 17.4855C2.91602 17.517 3.36056 17.6941 3.75891 18.0167C5.97376 19.9269 8.40581 21.3623 11.0551 22.3228C13.7043 23.2834 16.3998 23.7688 19.1416 23.7792C21.8841 23.7904 24.5884 23.3307 27.2544 22.3999C29.9205 21.4691 32.3581 20.0671 34.5673 18.1937C34.9689 17.8749 35.4252 17.6917 35.9362 17.6439C36.4473 17.5962 36.872 17.7428 37.2102 18.0836C37.5492 18.4252 37.7078 18.8366 37.6859 19.3178C37.6641 19.7989 37.4724 20.1982 37.1109 20.5155C34.54 22.7479 31.7006 24.4187 28.5926 25.5279C25.4847 26.6372 22.33 27.1857 19.1287 27.1736C15.9257 27.1614 12.7697 26.5841 9.66072 25.4417C6.55172 24.2992 3.73114 22.5913 1.19897 20.3179C0.839856 19.9963 0.661174 19.6048 0.662925 19.1436C0.664675 18.6824 0.835949 18.2834 1.17675 17.9468Z"
        fill="#EF3F95"
      />
    </Svg>
  );
}
