import { Pressable, IPressableProps } from "native-base";
import React, { ReactNode } from "react";

interface IIconButtonProps extends IPressableProps {
  children: ReactNode;
}

export function IconButton({ children, ...props }: IIconButtonProps) {
  return (
    <Pressable hitSlop={15} _pressed={{ opacity: 0.5 }} {...props}>
      {children}
    </Pressable>
  );
}
