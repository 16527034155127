import { Box, Text, HStack, VStack } from "native-base";
import React from "react";

import { IInputProps, Input } from "./Input";

type InputProps = Pick<IInputProps, "name" | "control">;
interface IThresholdInputProps {
  upperProps: InputProps;
  lowerProps: InputProps;
  label?: string;
}
export function ThresholdInput({
  upperProps,
  lowerProps,
  label,
}: IThresholdInputProps) {
  return (
    <Box>
      <Text fontSize={16} color="primary.400" fontWeight="600" px={1} mb={1}>
        {label}
      </Text>
      <HStack space={5}>
        <VStack flex={1} alignItems="flex-start" space={1}>
          <Text fontSize={13} color="primary.400" fontWeight="600" pl={1}>
            Lower (0-100)
          </Text>
          <Input
            flex={1}
            fontSize={14}
            type="number"
            variant="contentInput"
            keyboardType="numeric"
            placeholder="Lower"
            {...lowerProps}
          />
        </VStack>
        <VStack flex={1} alignItems="flex-end" space={1}>
          <Text fontSize={13} color="primary.400" fontWeight="600" pr={1}>
            Upper (0-100)
          </Text>
          <Input
            flex={1}
            keyboardType="numeric"
            type="number"
            fontSize={14}
            variant="contentInput"
            placeholder="Upper"
            {...upperProps}
          />
        </VStack>
      </HStack>
    </Box>
  );
}
