import { useNavigation } from "@react-navigation/core";
import { View, VStack, Text } from "native-base";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { useChangeEmail } from "../hooks/useChangeEmail";
import { useFormErrors } from "../hooks/useFormErrors";
import { useMe } from "../hooks/useMe";
import { useSendVerificationCode } from "../hooks/useSendVerificationCode";
import { ToastStatus, useToast } from "../hooks/useToast";
import { isWeb } from "../util/platform";

import { AuthButton as Button } from "./auth/AuthButton";
import { AuthCard } from "./auth/AuthCard";
import { AuthInput } from "./auth/AuthInput";
import { AlertDialog } from "./elements/AlertDialog";

// CHANGE
export function SecondaryEmail() {
  const toast = useToast();
  const { data: user } = useMe();
  const { navigate } = useNavigation();

  const [isConfirmationAlertOpen, setIsConfirmationAlertOpen] =
    useState<boolean>(false);

  const [isVerificationCodeSent, setIsVerificationCodeSent] =
    useState<boolean>(false);

  const { mutate: sendVerificationCode, isLoading: isSendingCodeLoading } =
    useSendVerificationCode();
  const {
    mutate: addOrChangeSecondaryEmail,
    isLoading: isChangingEmailLoading,
  } = useChangeEmail();

  const {
    reset,
    control,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
  } = useForm();
  const { setErrors } = useFormErrors(setError);

  function onSendVerificationCode(data: Record<string, string>) {
    if (data.newSecondaryEmail === user?.secondaryEmail) {
      setError("newSecondaryEmail", {
        message:
          "New Secondary email cannot be the same as old secondary email",
      });
      return;
    }

    sendVerificationCode(
      {
        currentPassword: data.currentPassword,
        newEmail: data.newSecondaryEmail,
        emailType: "secondary",
      },
      {
        onSuccess: (data) => {
          if (data.status === "ok") {
            setIsVerificationCodeSent(true);
            clearErrors(["currentPassword", "newSecondaryEmail"]);
            toast({
              title: "A verification code has been sent to you Secondary Email",
              status: ToastStatus.success,
            });
          } else {
            toast({
              title: data.message,
              status: ToastStatus.error,
            });
          }
        },
        onError: (data) => {
          setErrors(
            data.errors
              ? {
                  currentPassword: data.errors.currentPassword,
                  newSecondaryEmail: data.errors.newEmail,
                }
              : {}
          );
        },
      }
    );
  }
  function onSubmitSecondaryEmail(data: Record<string, string>) {
    onClose();

    addOrChangeSecondaryEmail(
      { code: data.code, type: "secondary" },
      {
        onSuccess: (data) => {
          if (data.status === "ok") {
            reset();
            setIsVerificationCodeSent(false);
            navigate(isWeb() ? "Home" : "App");
            toast({
              title: "Your Secondary Email has been verified",
              status: ToastStatus.success,
            });
          } else {
            toast({
              title: data.message,
              status: ToastStatus.error,
            });
          }
        },
        onError: (data) => {
          setErrors(
            data.errors
              ? {
                  ...data.errors,
                  code: data.errors.code,
                }
              : {}
          );
        },
      }
    );
  }

  function onClose(): void {
    setIsConfirmationAlertOpen(false);
  }

  return (
    <>
      <AuthCard label="Secondary Email">
        <VStack space={5} _web={{ mt: 5 }}>
          {user?.secondaryEmail ? (
            <AuthInput
              name="currentSecondaryEmail"
              placeholder={user?.secondaryEmail}
              control={control}
              errors={errors}
              label="Current Email"
              keyboardType="default"
              isDisabled={true}
            />
          ) : null}

          <AuthInput
            name="newSecondaryEmail"
            placeholder={user?.secondaryEmail ? "New Email" : "Add Email"}
            control={control}
            errors={errors}
            keyboardType="email-address"
            isDisabled={isVerificationCodeSent}
          />
          <AuthInput
            name="currentPassword"
            placeholder="Current Password"
            control={control}
            errors={errors}
            keyboardType="default"
            type="password"
            isDisabled={isVerificationCodeSent}
          />
          {isVerificationCodeSent ? (
            <>
              <Text
                fontSize="10"
                textAlign="center"
                color="secondary.600"
                fontWeight={400}
              >
                Please insert the 6 digit confirmation code you received via
                email in the box below.
              </Text>
              <AuthInput
                name="code"
                placeholder="Verification Code"
                control={control}
                errors={errors}
                keyboardType="default"
                type="password"
              />
            </>
          ) : null}

          <Text
            fontSize="10"
            textAlign="center"
            color="secondary.600"
            fontWeight={400}
          >
            This email needs to be a non-work email address. {"\n"}The password
            remains the same for both. {"\n"} By adding a secondary email to
            your account, you still have {isWeb() ? "\n" : null} access to
            Miindset should your employment status change.
          </Text>
          <View
            justifyContent="center"
            alignItems="center"
            _web={{ flexDirection: "row" }}
          >
            <Button
              bg="primary.400"
              mb={2}
              py={4}
              variant="solid"
              isLoading={
                isVerificationCodeSent
                  ? isChangingEmailLoading
                  : isSendingCodeLoading
              }
              onPress={() => {
                if (isVerificationCodeSent && user?.secondaryEmail) {
                  setIsConfirmationAlertOpen(!isConfirmationAlertOpen);
                } else if (isVerificationCodeSent && !user?.secondaryEmail) {
                  onSubmitSecondaryEmail(getValues());
                } else {
                  onSendVerificationCode(getValues());
                }
              }}
              _web={{ maxW: 200, mx: 2 }}
            >
              {isVerificationCodeSent ? "Update" : "Send Code"}
            </Button>
          </View>
        </VStack>
        <AlertDialog
          headerText="Change Secondary Email"
          bodyText="This will change your Secondary Email. Are you sure?"
          buttonColor="red.100"
          buttonText="Confirm"
          buttonAction={() => {
            onSubmitSecondaryEmail(getValues());
          }}
          isOpen={isConfirmationAlertOpen}
          onClose={onClose}
        />
      </AuthCard>
    </>
  );
}
