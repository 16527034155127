import { IConsumedMedia } from "api/src/organisations/interfaces/IConsumedMedia";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationConsumedMedia(
  location?: string,
  departmentIds?: string[]
) {
  return useQuery<IConsumedMedia>(
    ["consumed-media", location, departmentIds],
    async () => {
      const response = await api.post<IConsumedMedia>(
        "organisations/consumed-media",
        { location, departmentIds }
      );

      if (response.status === 201) {
        return response.data;
      }

      return Promise.reject("Could not get consumed media of organisation");
    },
    { keepPreviousData: true }
  );
}
