import { useNavigation } from "@react-navigation/core";
import { ContentType } from "api/src/content/enum/ContentType";
import { NotificationEntity } from "api/src/notification/entities/notification.entity";
import { Box, HStack, Text, VStack, Divider } from "native-base";
import React from "react";
import { Pressable } from "react-native";

import { useUsersNotification } from "../hooks/useUsersNotification";
import { useUsersReadNotification } from "../hooks/useUsersReadNotification";
import { openUrl } from "../util/linking";

import { UsersIcon } from "./icons/Icons";

export function UserNotification() {
  const { navigate } = useNavigation();

  const { data, hasNextPage } = useUsersNotification({ limit: 3 });
  const { mutate: readNotification } = useUsersReadNotification();

  function handleNotificationPress({
    notification,
  }: {
    notification: NotificationEntity;
  }) {
    if (!notification.isRead) {
      readNotification({ notificationId: notification.id });
    }

    if (
      notification.content?.url &&
      (notification.content?.type === ContentType.podcast ||
        notification.content?.type === ContentType.video)
    ) {
      openUrl(notification.content?.url);
    }

    if (
      notification.content?.id &&
      notification.content?.type === ContentType.article
    ) {
      navigate("Notification", {
        contentId: notification.content?.id,
        createAt: notification.createdAt,
      });
    }
  }

  return (
    <Box pb="5" px="5">
      {data?.pages.map((page) =>
        page.items.map((notification) => (
          <Pressable onPress={() => handleNotificationPress({ notification })}>
            <HStack
              justifyContent="space-between"
              alignItems="center"
              space="5"
              pb="5"
            >
              <Box>
                {!notification.isRead ? (
                  <Box
                    position="absolute"
                    top="-10"
                    right="0"
                    width="2"
                    height="2"
                    rounded="full"
                    background="red.400"
                  />
                ) : null}
                <UsersIcon color="primary.400" />
              </Box>
              <VStack flex="1">
                <Text>{`Message From ${notification.organisation?.name}`}</Text>
                <Text>{notification.content?.title}</Text>
              </VStack>
            </HStack>
          </Pressable>
        ))
      )}
      {hasNextPage && (
        <Pressable onPress={() => navigate("Notifications")}>
          <Text alignSelf="flex-end">Show More</Text>
        </Pressable>
      )}
      {data?.pages[0]?.meta?.totalItems ? (
        <Divider my="5" w="80%" alignSelf="center" />
      ) : null}
    </Box>
  );
}
