import { MaterialIcons } from "@expo/vector-icons";
import {
  FormControl,
  Text,
  Input as NbInput,
  Icon,
  View,
  useColorModeValue,
} from "native-base";
import React, { useState } from "react";
import { useController } from "react-hook-form";

import { IInputProps } from "../form/Input";

export function AuthInput({
  name,
  errors = {},
  control,
  type,
  rules,
  ...props
}: IInputProps) {
  const { field } = useController({
    control,
    defaultValue: "",
    name,
    rules,
  });
  const errorMessage = errors[name]?.message;
  const [isPassword, setIsPassword] = useState<boolean>(type === "password");
  const iconColor = useColorModeValue("secondary.700", "secondary.650");

  return (
    <FormControl
      isInvalid={!!errorMessage}
      maxW={500}
      width="100%"
      alignSelf="center"
    >
      <NbInput
        value={field.value}
        paddingRight={type === "password" ? 12 : 0}
        variant="auth"
        onChangeText={field.onChange}
        InputRightElement={
          type === "password" ? (
            <Icon
              style={{ position: "absolute", right: 20 }}
              as={
                <MaterialIcons
                  name={isPassword ? "visibility" : "visibility-off"}
                />
              }
              size={5}
              color={iconColor}
              onPress={() => setIsPassword(!isPassword)}
            />
          ) : (
            <View />
          )
        }
        {...props}
        type={isPassword ? "password" : "text"}
      />
      {errorMessage ? (
        <FormControl.ErrorMessage justifyContent="center" alignItems="center">
          <Text
            mb={1}
            fontSize={12}
            color="red.500"
            fontWeight={600}
            textAlign="center"
          >
            {errorMessage}
          </Text>
        </FormControl.ErrorMessage>
      ) : null}
    </FormControl>
  );
}
