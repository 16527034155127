import { useStyledSystemPropsResolver, IImageProps } from "native-base";
import React, { forwardRef, useState } from "react";
import { Image as RNImage } from "react-native";

export const Image = forwardRef<RNImage, React.PropsWithChildren<IImageProps>>(
  ({ children, ...props }: React.PropsWithChildren<IImageProps>, ref) => {
    const [style, otherProps] = useStyledSystemPropsResolver(props);
    const [image, setImage] = useState<{ uri: string }>(otherProps.source);

    function handleOnError(): void {
      if (otherProps.fallbackSource && image !== otherProps.fallbackSource) {
        setImage(otherProps.fallbackSource);
      }
    }

    return (
      <RNImage
        {...otherProps}
        source={image}
        style={style}
        ref={ref}
        onError={handleOnError}
      >
        {children}
      </RNImage>
    );
  }
);
