import { CriticalIndicatorChartFilters } from "api/src/organisations/enum/critical-indicator-chart-filter";
import {
  HStack,
  Text,
  Pressable,
  Heading,
  useColorModeValue,
} from "native-base";
import React from "react";

import { DateRange } from "../../../elements/DateRange";

interface ICriticalIndicatorChartProps {
  activeFilter: string;
  isLoading: boolean;
  setActiveFilter: (filter: CriticalIndicatorChartFilters) => void;
  onDateRangeSelected: (startDate: string, endDate: string) => void;
}

export function CriticalIndicatorsFilter({
  activeFilter,
  isLoading,
  setActiveFilter,
  onDateRangeSelected,
}: ICriticalIndicatorChartProps) {
  const criticalIndicatorFilters = [
    { label: CriticalIndicatorChartFilters.week, onFilter: () => {} },
    { label: CriticalIndicatorChartFilters.month, onFilter: () => {} },
    { label: CriticalIndicatorChartFilters.quarter, onFilter: () => {} },
    { label: CriticalIndicatorChartFilters.year, onFilter: () => {} },
  ];

  const buttonColorInactive = useColorModeValue("white", "dark.200");
  const textColorInactive = useColorModeValue("primary.400", "secondary.200");

  return (
    <HStack alignItems="center" pl="5" w="100%" justifyContent="space-between">
      <Heading fontSize={13} fontWeight={500}>
        Critical Indicators over the last
      </Heading>
      <DateRange
        onDateRangeSelected={onDateRangeSelected}
        isLoading={isLoading && activeFilter === "Custom"}
        isSelect={activeFilter === "Custom"}
      />

      <HStack space={2}>
        {criticalIndicatorFilters.map(({ label, onFilter }) => {
          const isActive = activeFilter === label;
          return (
            <Pressable
              paddingX={6}
              paddingY={1}
              key={label}
              borderColor="primary.400"
              borderWidth={1}
              rounded="full"
              _pressed={{ opacity: 0.7 }}
              bg={isActive ? "primary.200" : buttonColorInactive}
              onPress={() => {
                setActiveFilter(label);
                onFilter();
              }}
            >
              <Text
                color={isActive ? "white" : textColorInactive}
                fontSize="xs"
              >
                {label}
              </Text>
            </Pressable>
          );
        })}
      </HStack>
    </HStack>
  );
}
