import { OrganisationUserEntity } from "api/src/organisations/entities/organisation-user.entity";
import { OrganisationRole } from "api/src/organisations/enum/organisation-role";
import dayjs from "dayjs";
import {
  HStack,
  Text,
  Select,
  Box,
  CheckIcon,
  Divider,
  ChevronDownIcon,
  Spacer,
} from "native-base";
import React, { useState } from "react";

import { useMe } from "../../../hooks/useMe";
import { useOrganisationDepartments } from "../../../hooks/useOrganisationDepartments";
import { useOrganisationUserChangeDepartment } from "../../../hooks/useOrganisationUserChangeDepartment";
import { useOrganisationUserChangeRole } from "../../../hooks/useOrganisationUserChangeRole";
import { useRemoveEmployee } from "../../../hooks/useRemoveEmployee";
import { ToastStatus, useToast } from "../../../hooks/useToast";
import { AlertDialog } from "../../elements/AlertDialog";
import { Button } from "../../elements/Button";
import { Icon, IconLibrary } from "../../elements/Icon";
import { IconButton } from "../../elements/IconButton";
import { DeleteIcon } from "../../icons/DeleteIcon";

interface IOrganisationUserProps {
  item: OrganisationUserEntity;
}

export function OrganisationUser({ item }: IOrganisationUserProps) {
  const { user, department } = item;
  const { data: me } = useMe();
  const [isRoleEditOpen, setIsRoleEditOpen] = useState(false);
  const { mutate: removeEmployee, isLoading: isRemoveEmployeeLoading } =
    useRemoveEmployee();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDepartmentOpen, setIsEditDepartmentOpen] = useState(false);
  const [newRole, setNewRole] = useState(item.organisationRole as string);
  const [newDepartment, setNewDepartment] = useState<string>();
  const { mutate: changeRole, isLoading: isRoleLoading } =
    useOrganisationUserChangeRole();
  const { data: organisationDepartments } = useOrganisationDepartments();
  const { mutate: changeDepartment, isLoading: isDepartmentLoading } =
    useOrganisationUserChangeDepartment();
  const toast = useToast();

  function handleOnSaveUserRole() {
    changeRole(
      {
        userId: user.id,
        organisationRole: newRole,
      },
      {
        onSuccess: (data) => {
          setIsRoleEditOpen(false);
          toast({ title: data.message, status: ToastStatus.success });
        },
        onError: (data) => {
          setIsRoleEditOpen(false);
          toast({ title: data.message, status: ToastStatus.error });
        },
      }
    );
  }
  function handleOnSaveUserDepartment() {
    if (newDepartment) {
      changeDepartment(
        {
          userId: user.id,
          departmentId: newDepartment,
          id: item.id,
        },
        {
          onSuccess: (data) => {
            setIsEditDepartmentOpen(false);
            toast({ title: data.message, status: ToastStatus.success });
          },
          onError: (data) => {
            setIsEditDepartmentOpen(false);
            toast({ title: data.message, status: ToastStatus.error });
          },
        }
      );
    }
  }

  function handleDeleteUser() {
    removeEmployee(
      {
        employeeId: user.id,
      },
      {
        onSuccess: () => {
          toast({ title: "Employee Deleted!", status: ToastStatus.success });
        },
        onError: () => {
          toast({
            title: "Could not delete Employee",
            status: ToastStatus.error,
          });
        },
      }
    );
  }

  return (
    <>
      <Box
        bg="secondary.100"
        my="2"
        p="2"
        px="4"
        borderRadius="5"
        key={user.id}
        _dark={{ bg: "dark.200" }}
      >
        <HStack
          space={[2, 3]}
          py="2"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text width="10%">{user?.firstName}</Text>
          <Text width="10%">{user?.lastName}</Text>
          <Divider orientation="vertical" />
          <Text width="30%" isTruncated={true}>
            {user?.email}
          </Text>
          <Divider orientation="vertical" />
          {!isEditDepartmentOpen ? (
            <HStack
              justifyContent="space-between"
              alignItems="center"
              width="20%"
            >
              <Text isTruncated={true}>{department?.name}</Text>
              <IconButton onPress={() => setIsEditDepartmentOpen(true)}>
                <Icon
                  icon="edit"
                  size={4}
                  color="secondary.600"
                  iconLibrary={IconLibrary.feather}
                />
              </IconButton>
            </HStack>
          ) : (
            <HStack alignItems="center" justifyContent="center" space="5">
              <Select
                selectedValue={newDepartment}
                minWidth="200"
                placeholder="Choose Department"
                dropdownIcon={<ChevronDownIcon size={3} mr="3" />}
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(itemValue) => setNewDepartment(itemValue)}
              >
                {organisationDepartments?.map((department) => (
                  <Select.Item
                    key={department?.value}
                    label={department?.label}
                    value={department.value}
                  />
                ))}
              </Select>
              <Button
                h="8"
                onPress={handleOnSaveUserDepartment}
                isLoading={isDepartmentLoading}
              >
                Save
              </Button>
              <IconButton onPress={() => setIsEditDepartmentOpen(false)}>
                <Icon
                  icon="close"
                  size={4}
                  color="secondary.600"
                  iconLibrary={IconLibrary.materialCommunity}
                />
              </IconButton>
            </HStack>
          )}
          <Divider orientation="vertical" />
          {!isRoleEditOpen ? (
            <HStack
              alignItems="center"
              justifyContent="space-between"
              width="15%"
            >
              <Text fontSize="sm" isTruncated={true}>
                {item.organisationRole}
              </Text>

              {me?.id !== user.id ? (
                <IconButton onPress={() => setIsRoleEditOpen(true)}>
                  <Icon
                    icon="edit"
                    size={4}
                    color="secondary.600"
                    iconLibrary={IconLibrary.feather}
                  />
                </IconButton>
              ) : (
                <Text color="secondary.600" _dark={{ color: "secondary.600" }}>
                  (You)
                </Text>
              )}
            </HStack>
          ) : (
            <HStack alignItems="center" justifyContent="center" space="5">
              <Select
                selectedValue={newRole}
                minWidth="200"
                placeholder="Choose Role"
                dropdownIcon={<ChevronDownIcon size={3} mr="3" />}
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(itemValue) => setNewRole(itemValue)}
              >
                {Object.values(OrganisationRole).map((role, index) => (
                  <Select.Item
                    label={role}
                    value={role}
                    key={`key-${index}-${role}`}
                  />
                ))}
              </Select>
              <Button
                h="8"
                onPress={handleOnSaveUserRole}
                isLoading={isRoleLoading}
              >
                Save
              </Button>
              <IconButton onPress={() => setIsRoleEditOpen(false)}>
                <Icon
                  icon="close"
                  size={4}
                  color="secondary.600"
                  iconLibrary={IconLibrary.materialCommunity}
                />
              </IconButton>
            </HStack>
          )}
          <Spacer size={2} />
          <Text fontSize="sm" width="22%" isTruncated={true}>
            Date Joined {dayjs(item.user.createdAt).format("DD/MM/YYYY")}
          </Text>
          <Divider orientation="vertical" />
          <IconButton onPress={() => setIsDeleteDialogOpen(true)}>
            <DeleteIcon color="secondary.700" />
          </IconButton>
        </HStack>
      </Box>
      <AlertDialog
        headerText="Are you sure?"
        bodyText={`You are about to remove ${item?.user.firstName} ${item?.user.lastName} from having access to Miindset. \n\n${item?.user.firstName} ${item?.user.lastName} will have access to Miindset for 7 days after being removed and given the option to continue using the full version at their own cost.`}
        buttonColor="red.400"
        buttonText="Confirm"
        textAlign="center"
        isConfirmButtonLoading={isRemoveEmployeeLoading}
        isOpen={isDeleteDialogOpen}
        isShowCloseButton={false}
        buttonAction={() => {
          setIsDeleteDialogOpen(false);
          handleDeleteUser();
        }}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
      />
    </>
  );
}
