import {
  Box,
  FlatList,
  HStack,
  Input,
  Link,
  Pressable,
  Text,
} from "native-base";
import React, { useState, useCallback, useMemo } from "react";
import { Control, useController } from "react-hook-form";

import { Icon, IconLibrary } from "../elements/Icon";

export interface IAutoCompleteInputProps {
  name: string;
  control: Control;
  variant?: string;
  items: string[];
  placeholder?: string;
}

export default function AutoCompleteInput({
  name,
  control,
  variant,
  items,
  placeholder,
}: IAutoCompleteInputProps) {
  const { field } = useController({
    control,
    defaultValue: [],
    name,
  });

  const [textInputFocus, setTextInputFocus] = useState<boolean>();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchTermChange = (value: string) => {
    if (value.length > 1 && value.charAt(value.length - 1) === ",") {
      handleChange(value.slice(0, -1));
      setSearchTerm("");
      return;
    }
    setSearchTerm(value);
  };
  const handleChange = useCallback(
    (value: string) => {
      const newValue = [...field.value, value];
      field.onChange(newValue);
    },
    [field, items]
  );

  const handleRemove = useCallback(
    (value: string) => {
      field.onChange(field.value.filter((v: string) => v !== value));
    },
    [field, items]
  );

  const filteredItems = useMemo(
    () =>
      items
        .filter((value) => value.includes(searchTerm))
        .filter((value) => field.value.indexOf(value) === -1),
    [field, items, searchTerm]
  );

  return (
    <Box zIndex={1}>
      <Input
        placeholder={placeholder}
        variant={variant}
        borderRadius={0}
        onFocus={() => {
          setTextInputFocus(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setTextInputFocus(false);
          }, 200);
        }}
        value={searchTerm}
        onChangeText={handleSearchTermChange}
        maxHeight={9}
      />

      <HStack flexWrap="wrap" space={2}>
        {field.value?.map((value: string) => (
          <HStack
            px={3}
            py={1}
            mt={2}
            borderRadius={100}
            fontSize={12}
            bg="primary.200"
            justifyContent="center"
            alignItems="center"
            space={2}
          >
            <Text color="white">{value}</Text>
            <Link onPress={() => handleRemove(value)}>
              <Icon
                icon="close"
                size={4}
                color="white"
                iconLibrary={IconLibrary.antDesign}
              />
            </Link>
          </HStack>
        ))}
      </HStack>

      {textInputFocus && (
        <FlatList
          backgroundColor="white"
          position="absolute"
          data={filteredItems}
          width="100%"
          right={0}
          maxHeight={220}
          padding={2}
          background="secondary.200"
          zIndex={999}
          shadow={3}
          marginTop={10}
          renderItem={({ item }) => (
            <Pressable
              onPress={() => {
                handleChange(item);
              }}
              height={10}
              marginLeft={2}
              justifyContent="center"
            >
              <Text fontSize={15}>{item}</Text>
            </Pressable>
          )}
          keyExtractor={({ id }) => id}
        />
      )}
    </Box>
  );
}
