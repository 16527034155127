export const CONTENT_CHANNELS: { label: string; value: string }[] = [
  { label: "Women's Health", value: "women-health" },
  { label: "Work", value: "work" },
  { label: "Food", value: "food" },
  { label: "LGBTQ+", value: "lgbtq" },
  { label: "Money", value: "money" },
  { label: "Sleep", value: "sleep" },
  { label: "Sport", value: "sport" },
  { label: "Ageing", value: "ageing" },
  { label: "Neurodiversity", value: "neurodiversity" },
  { label: "Diversity", value: "diversity" },
  { label: "Travel", value: "travel" },
  { label: "Opinion", value: "opinion" },
  { label: "Education", value: "education" },
  { label: "Mental Health", value: "mental-health" },
  { label: "Entertainment", value: "entertainment" },
  { label: "Physical Health", value: "physical-health" },
  { label: "Science and Technology", value: "science-and-technology" },
  { label: "Family and Relationships", value: "family-and-relationships" },
];
