import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

interface IInvitationCodeResponse {
  invitationCode: string;
}

export function useGenerateInvitationCode() {
  return useMutation<
    IApiResponse<IInvitationCodeResponse>,
    IApiResponse<IInvitationCodeResponse>
  >("invites/generate-invite-code", async () => {
    const response = await api.post<IApiResponse<IInvitationCodeResponse>>(
      "invites/generate-invite-code"
    );
    return response.data;
  });
}
