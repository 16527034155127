export const WEB_NAVIGATION_CONFIG = {
  screens: {
    StripeApp: "stripe-app",

    Auth: {
      initialRouteName: "/",
      path: "/",
      screens: {
        AuthCheck: "/",
        Onboarding: {
          path: "onboarding",
          screens: {
            EmergencyContacts: "emergency-contacts",
            DigestMedia: "digest-media",
            WellbeingCheckerOnboarding: "wellbeing-checker",
            TrackMoodOnboarding: "track-mood",
            Digesting: "digesting",
          },
        },
        WellbeingChecker: "wellbeing-checker",
        Welcome: "welcome",
        Login: "login",
        Register: "register",
        ConfirmEmail: "confirmation-email",
        ForgotPassword: "forgot-password",
        ResetPassword: "reset-password",
        Unidays: "unidays",
        FirstLogin: {
          path: "first-login",
          screens: {
            DigestMedia: "digest-media",
            Welcome: "welcome-screen",
            TakeLook: "take-look",
            Digesting: "digesting",
          },
        },
        SecondLogin: {
          path: "second-login",
          screens: {
            GetGrounded: "get-grounded",
            HabitTracker: "habit-tracker",
            Help: "help",
            Digesting: "digesting",
          },
        },
      },
    },
    UnidaysWebRedirect: "unidays-success",
    Main: {
      path: "main",
      screens: {
        Home: "home",
        Subscription: "subscription",
        TrackMood: "track-mood",
        TrackFeelings: "track-feelings",
        SubscriptionMain: "SubscriptionMain",
        TermsAndConditions: "TermsAndConditions",
        PrivacyAndPolicy: "PrivacyAndPolicy",
        TrackHabits: "track-habits",
        Insights: "insights",
        WellbeingChecker: "wellbeing-checker",
        ChannelDetails: "channel",
        SubChannels: "sub-channels",
        ContentDetails: "content/:contentId",
        BreathingExercises: "breathing-exercises",
        CopingStrategies: "coping-strategies",
        GetGrounded: "get-grounded",
        Notifications: "Notifications",
        EmergencyContacts: "emergency-contacts",
        ReviewContents: {
          path: "review",
          screens: {
            ReviewContentsList: "",
            ReviewContent: "content",
          },
        },
        Organisation: {
          path: "organisation",
          screens: {
            OrganisationInsights: {
              path: "insights",
              screens: {
                Overview: "overview",
                Indicators: "indicators",
                Engagement: "engagement",
              },
            },
            OrganisationEmployees: {
              path: "employee-management",
              screens: {
                Invitations: "invitations",
                UserList: "users",
                Departments: "departments",
              },
            },
            OrganisationContent: {
              path: "content-management",
              screens: {
                ReviewContentsList: "review-content-list",
                ReviewContent: "review-content",
                EditOrganisationContent: "edit-organisation-content",
              },
            },
            OrganisationSettings: {
              screens: {
                Settings: "settings",
              },
            },
          },
        },
        MyJournal: "my-journal",
        MyJournalType: "my-journal/type",
        AddPost: "my-journal/add-post",
        AddHabit: "habit/add",
        DefaultHabit: "habits",
        HabitDetails: "/habit/details",
        AddDiaryEntry: "diary/add",
        AddTagsEntry: "tags/add",
        DiaryDetails: "diary/:id",
        TagDetails: "tags/:id",
        AddGratitudeEntry: "gratitude/add",
        GratitudeDetails: "gratitude",
        AddIAmGreatEntry: "iam-great/add",
        AddFiveMinuteEntry: "five-minute/add",
        FiveMinuteDetails: "five-minute",
        GetGroundedSuccess: "get-grounded/success",
        DeleteAccount: "account/delete",
        Affirmation: "affirmation",
        ChangeEmail: "change-email",
        ChangePassword: "change-password",

        MyPage: {
          initialRouteName: "",
          screens: {
            MyPageMain: "my-page",
          },
        },
        Digesting: "digesting",
        MyMediaLibrary: "my-media-library",
      },
    },
  },
};
