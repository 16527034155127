import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import {
  Box,
  Center,
  Heading,
  HStack,
  Text,
  useToken,
  VStack,
} from "native-base";
import React, { useMemo, useState } from "react";

import { AlertDialog } from "../components/elements/AlertDialog";
import { Icon } from "../components/elements/Icon";
import { IconButton } from "../components/elements/IconButton";
import { LoadingAnimation } from "../components/elements/LoadingAnimation";
import { PencilIcon } from "../components/icons/PencilIcon";
import { RoundCheckIcon } from "../components/icons/RoundCheckIcon";
import { RoundCrossIcon } from "../components/icons/RoundCrossIcon";
import { RoundMinusIcon } from "../components/icons/RoundMinusIcon";
import { WhiteBoxContainer } from "../components/WhiteBoxContainer";
import { useHabit } from "../hooks/useHabit";
import { useHabitStreak } from "../hooks/useHabitStreak";
import { useDeleteHabitStreak } from "../hooks/useHabitStreakDelete";
import { Header } from "../layout/Header";
import { RootStackParamList } from "../navigation/RootStackParamList";

export function HabitDetailsScreen() {
  const { goBack, navigate } = useNavigation();
  const [primary400] = useToken("colors", ["primary.400"]);

  const {
    params: { habitId },
  } = useRoute<RouteProp<RootStackParamList, "HabitDetails">>();
  const { data: habit, isLoading } = useHabit(habitId);
  const { data: habitStreak } = useHabitStreak(habitId);
  const { mutate: deleteStreak } = useDeleteHabitStreak(habitId);

  const startDate = useMemo(() => dayjs(habit?.createdAt), [habit]);
  const daysPassed = useMemo(
    () => Math.ceil(dayjs().diff(startDate, "day", true)),
    [startDate]
  );
  const daysLeft = useMemo(
    () => (habit?.daysToStreak || 10) - daysPassed,
    [daysPassed, habit, habitStreak]
  );

  const streakDays = useMemo(() => {
    const array: string[] = [];
    [...Array(habit?.daysToStreak || 10)].map((habit, day) =>
      habitStreak?.find(({ createdAt }) => {
        if (dayjs(createdAt).isSame(startDate.add(day, "day"), "day")) {
          return array.push("check");
        }
        if (dayjs(habit?.createdAt).isAfter(startDate.add(day, "day"), "day")) {
          return array.push("cross");
        }
        return array.push("minus");
      })
    );
    return array;
  }, [habit, habitStreak, deleteStreak]);

  const isHabitCompleted = daysLeft === 0;

  const [isRestartHabitModalOpen, setIsRestartHabitModalOpen] =
    useState<boolean>(false);
  function handleRestart() {
    deleteStreak(
      {
        habitId,
      },
      {
        onSuccess: () => {
          setIsRestartHabitModalOpen(false);

          goBack();
        },
      }
    );
  }

  return (
    <>
      <Box bg="secondary.200" minH="100vh" _dark={{ bg: "dark.500" }}>
        <Header hasBack={true} />
        {!isLoading ? (
          <WhiteBoxContainer>
            <VStack>
              <HStack mb="6" justifyContent="center" alignItems="flex-end">
                <Heading fontSize={20} fontWeight={500}>
                  {habit?.name}
                </Heading>
                <Box position="absolute" right="0">
                  <IconButton
                    onPress={() => {
                      navigate("AddHabit", { habitId });
                    }}
                  >
                    <PencilIcon size="sm" />
                  </IconButton>
                </Box>
                <Box position="absolute" left="0">
                  <IconButton
                    onPress={() =>
                      setIsRestartHabitModalOpen(!isRestartHabitModalOpen)
                    }
                  >
                    <Icon icon="reload1" color="secondary.400" size={6} />
                  </IconButton>
                </Box>
              </HStack>
              <Text
                textAlign="center"
                fontSize="22"
                fontWeight="600"
                variant="strongHighlight"
              >
                {!isHabitCompleted
                  ? !isEmpty(streakDays)
                    ? "You are doing great! Keep it up!"
                    : "Add your first day to start streak!"
                  : "You did great congratulations!"}
              </Text>
              <Text
                textAlign="center"
                fontSize="18"
                color="secondary.650"
                _dark={{ color: "secondary.400" }}
              >
                {!isHabitCompleted
                  ? !isEmpty(streakDays)
                    ? `You have ${daysLeft} more days to complete your goal of ${habit?.daysToStreak} days.`
                    : ""
                  : "Press the restart button if you want to redo the habit"}
              </Text>
            </VStack>
            {!isEmpty(streakDays) ? (
              <HStack flexWrap="wrap" justifyContent="center" pt="8">
                {streakDays.map((value, index) => {
                  if (value === "check") {
                    return (
                      <RoundCheckIcon
                        size="2xl"
                        color={primary400}
                        key={index}
                      />
                    );
                  }
                  if (value === "cross") {
                    return <RoundCrossIcon size="2xl" key={index} />;
                  }
                  return <RoundMinusIcon size="2xl" key={index} />;
                })}
              </HStack>
            ) : null}
          </WhiteBoxContainer>
        ) : (
          <Center h="full">
            <LoadingAnimation size={100} isLoading={isLoading} />
          </Center>
        )}
        <AlertDialog
          headerText="Restart Habit"
          bodyText="This will remove all your streak days. Are you sure you want to
          restart your habit?"
          buttonText="Restart"
          buttonAction={handleRestart}
          isOpen={isRestartHabitModalOpen}
          onClose={() => {
            setIsRestartHabitModalOpen(false);
          }}
        />
      </Box>
    </>
  );
}
