import {
  View,
  Text,
  Image,
  Heading,
  HStack,
  VStack,
  Link,
  Pressable,
} from "native-base";
import React from "react";
import { Linking } from "react-native";

import { useToast, ToastStatus } from "../../hooks/useToast";
import { isWeb } from "../../util/platform";
import { CallIcon } from "../icons/CallIcon";
import { NetworkIcon } from "../icons/NetworkIcon";

export function HealthyAgeingSupport() {
  const toast = useToast();

  const handleContactClick = (phoneNumber: string) => {
    const url = `tel:${phoneNumber}`;
    if (isWeb()) {
      return;
    }
    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          toast({
            title: "Can't handle this contact",
            status: ToastStatus.warning,
          });
        } else {
          return Linking.openURL(url);
        }
      })
      .catch((err) =>
        toast({
          title: `An error occurred: ${err}`,
          status: ToastStatus.error,
        })
      );
  };
  return (
    <View mb={10} mt={50} alignItems="center">
      {!isWeb() ? (
        <Image
          width={60}
          height={75}
          source={require("../../assets/images/badges/ageing.png")}
          alt="The Concept Of Sacred Spaces"
          borderRadius={10}
        />
      ) : null}
      <Heading mt={5} mb={5} fontSize={28} fontWeight={600} textAlign="center">
        Healthy Ageing
      </Heading>
      {isWeb() ? (
        <Image
          width={60}
          height={75}
          source={require("../../assets/images/badges/ageing.png")}
          alt="The Concept Of Sacred Spaces"
          borderRadius={10}
        />
      ) : null}
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Age UK's Advice Line is a free, confidential national telephone service
        for older people, as well as their families, friends, carers and
        professionals. Our team will give you information that's reliable and up
        to date and help you access the advice you need.
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Do you know what benefits to claim and how to claim them?
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Worried that you can’t afford to retire?
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Anxious about a planned hospital stay and how to cope when
        you leave?
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        {"\u2022"} Need advice on choosing the right care home?
      </Text>
      <Text
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Free independent advice 365 days a year
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Lines are open 8 am-7 pm, every day of a ayear including all bank
        holidays.
      </Text>
      <VStack
        mt={10}
        flex={1}
        w={isWeb() ? 460 : 250}
        space={5}
        justifyContent="space-around"
        flexDirection={isWeb() ? "row" : "column"}
      >
        <Pressable onPress={() => handleContactClick("0800 678 1602")}>
          <HStack space={2}>
            <CallIcon />
            <VStack>
              <Text>Telephone - 0800 678 1602</Text>
              <Text fontSize="10">365 Days a year - 9am to 9pm</Text>
            </VStack>
          </HStack>
        </Pressable>
        <Link href="https://www.ageuk.org.uk/" isExternal={true}>
          <HStack space={2}>
            <NetworkIcon />
            <VStack>
              <Text underline={true}>ageuk.org.uk</Text>
            </VStack>
          </HStack>
        </Link>
      </VStack>
    </View>
  );
}
