import { CriticalIndicatorChartFilters } from "api/src/organisations/enum/critical-indicator-chart-filter";
import { IOrganisationDepartment } from "api/src/organisations/interfaces/IOrganisationDepartment";
import { VStack, HStack, Box, Divider } from "native-base";
import React, { useState } from "react";

import { Container } from "../../components/Container";
import { ScrollView } from "../../components/elements/ScrollView";
import { AgeResilienceScore } from "../../components/organisations/dashboard/overview/AgeResilienceScore";
import { CriticalIndicatorsChart } from "../../components/organisations/dashboard/overview/CriticalIndicatorsChart.web";
import { CriticalIndicatorsFilter } from "../../components/organisations/dashboard/overview/CriticalIndicatorsFilter";
import { DepartmentList } from "../../components/organisations/dashboard/overview/DepartmentList";
import { GeneralOrganisationStats } from "../../components/organisations/dashboard/overview/GeneralOrganisationStats";
import { LocationResilienceScore } from "../../components/organisations/dashboard/overview/LocationResilienceScore";
import { OrganisationOverviewMap } from "../../components/organisations/dashboard/overview/OrganisationOverviewMap";
import { OrganisationResilienceScore } from "../../components/organisations/dashboard/overview/OrganisationResilienceScore";
import { TotalMediaConsumed } from "../../components/organisations/dashboard/overview/TotalMediaConsumed";
import { OrganisationTabs } from "../../components/organisations/OrganisationTabs";
import { PopUp } from "../../components/Popup";
import { useOrganisationAgeResilienceScore } from "../../hooks/useOrganisationAgeResilience";
import { useOrganisationAverageResilience } from "../../hooks/useOrganisationAverageResilience";
import { useOrganisationConsumedMedia } from "../../hooks/useOrganisationConsumedMedia";
import { useOrganisationCriticalIndicatorChart } from "../../hooks/useOrganisationCriticalIndicatorChart";
import { useOrganisationLocations } from "../../hooks/useOrganisationLocations";
import { useOrganisationStats } from "../../hooks/useOrganisationStats";
import { Header } from "../../layout/Header";
import { numFormatter } from "../../util/organisation-overview";

const MIN_DEPARTMENT_USERS = 5;

export function OrganisationOverviewScreen() {
  const [dateFilter, setDateFilter] = useState<CriticalIndicatorChartFilters>(
    CriticalIndicatorChartFilters.week
  );
  const [dateRange, setDateRange] = useState<{
    startingDate: string;
    endingDate: string;
  }>({
    startingDate: "",
    endingDate: "",
  });

  const [locationFilter, setLocationFilter] = useState<string>("");
  const [departmentIds, setDepartmentIds] = useState<string[]>([]);
  const [departmentsPopupVisible, setDepartmentsPopupVisible] =
    useState<boolean>(false);

  const { data: organisationStats } = useOrganisationStats(locationFilter);
  const { data: ageResilienceScores } = useOrganisationAgeResilienceScore();
  const { data: consumedMedia } = useOrganisationConsumedMedia(
    locationFilter,
    departmentIds
  );
  const { data: averageResilience } = useOrganisationAverageResilience();
  const { data: organisationLocations } = useOrganisationLocations();
  const {
    data: criticalChartData,
    isLoading,
    isFetching,
  } = useOrganisationCriticalIndicatorChart(
    locationFilter,
    dateFilter,
    dateRange.startingDate,
    dateRange.endingDate
  );

  const onDateRangeSelected = (startDate: string, endDate: string) => {
    setDateRange({
      startingDate: startDate,
      endingDate: endDate,
    });
    setDateFilter(CriticalIndicatorChartFilters.custom);
  };

  const handleDepartment = (department: IOrganisationDepartment) => {
    if (department.numberOfUsers < MIN_DEPARTMENT_USERS) {
      setDepartmentsPopupVisible(true);
      return;
    }
    if (departmentIds.includes(department.value)) {
      setDepartmentIds(departmentIds.filter((id) => id !== department.value));
      return;
    }
    setDepartmentIds([...departmentIds, department.value]);
  };

  return (
    <>
      <Header hasBack={false} />

      <ScrollView
        bg="secondary.200"
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
        _dark={{ bg: "dark.500" }}
      >
        <Container size="container.xl" paddingBottom="10">
          <VStack pt={10} h="100%" w="100%" space={10}>
            <OrganisationTabs activeTab="Overview" />
            <Divider />
            <HStack h="87.5%" w="100%" space={5}>
              <VStack w="30%">
                <VStack h="100%" justifyContent="flex-start" space="20">
                  <OrganisationResilienceScore
                    percentage={averageResilience || 0}
                    filterOptions={[]}
                    onFilterChange={() => {}}
                  />

                  <LocationResilienceScore />

                  <AgeResilienceScore
                    ageResilienceScoreData={ageResilienceScores}
                  />
                </VStack>
              </VStack>
              <Box w="1px" h="93%" bg="secondary.400" alignSelf="center" />
              <VStack w="65%">
                <Box h="5%">
                  <GeneralOrganisationStats
                    organisationStats={organisationStats}
                  />
                </Box>
                <HStack h="50%">
                  <VStack
                    paddingRight={2}
                    paddingBottom={2}
                    paddingTop={3}
                    w="75.5%"
                  >
                    <Box maxHeight="460" mt="5">
                      <DepartmentList
                        departmentIds={departmentIds}
                        handleDepartment={handleDepartment}
                      />
                      <OrganisationOverviewMap
                        setLocationFilter={setLocationFilter}
                        locations={organisationLocations}
                      />
                    </Box>
                  </VStack>
                  <Box w="1px" h="80%" bg="secondary.400" alignSelf="center" />
                  <Box w="23.5%">
                    <TotalMediaConsumed
                      numberOfVideos={numFormatter(consumedMedia?.videos)}
                      numberOfPodcasts={numFormatter(consumedMedia?.podcasts)}
                      numberOfArticles={numFormatter(consumedMedia?.articles)}
                    />
                  </Box>
                </HStack>
                <Box h="5%">
                  <CriticalIndicatorsFilter
                    isLoading={isLoading || isFetching}
                    activeFilter={dateFilter}
                    setActiveFilter={setDateFilter}
                    onDateRangeSelected={onDateRangeSelected}
                  />
                </Box>

                <VStack h="42.5%">
                  <CriticalIndicatorsChart
                    ICriticalIndicatorChart={criticalChartData}
                  />
                </VStack>
              </VStack>
            </HStack>
          </VStack>
          <PopUp
            modalVisible={departmentsPopupVisible}
            setModalVisible={setDepartmentsPopupVisible}
            label={`We need more than ${MIN_DEPARTMENT_USERS} users to generate data`}
          />
        </Container>
      </ScrollView>
    </>
  );
}
