import {
  HStack,
  Image,
  Pressable,
  Text,
  useColorModeValue,
  useToken,
} from "native-base";
import React, { useCallback, useEffect, useRef } from "react";
import { Animated } from "react-native";

import { IIssue } from "../constants/issues";
import { getIssueImage } from "../util/wellbeing";

interface IChannelSelectionProps {
  issue: IIssue;
  isActive?: boolean;
  onPress?: () => void;
}

export function IssueSelection({
  issue,
  onPress,
  isActive,
}: IChannelSelectionProps) {
  const [primary400, white, secondary400, secondary750, dark300] = useToken(
    "colors",
    ["primary.400", "white", "secondary.400", "secondary.750", "dark.400"]
  );

  const backgroundInactiveColor = useColorModeValue(white, dark300);
  const textInactiveColor = useColorModeValue(secondary750, secondary400);

  const colourAnimation = useRef(new Animated.Value(1)).current;

  const handleAnimation = useCallback(() => {
    Animated.timing(colourAnimation, {
      toValue: isActive ? 0 : 1,
      duration: 100,
      useNativeDriver: false,
    }).start();
  }, [colourAnimation, isActive]);

  useEffect(() => {
    handleAnimation();
  }, [isActive, handleAnimation]);

  const backgroundColourValue = colourAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [primary400, backgroundInactiveColor],
  });

  const textColor = colourAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [white, textInactiveColor],
  });

  return (
    <Pressable
      onPress={onPress}
      borderRadius={500}
      overflow="hidden"
      w="100%"
      maxW={200}
    >
      <Animated.View
        style={{ backgroundColor: backgroundColourValue, padding: 5 }}
      >
        <HStack alignItems="center" space={3} w="100%" overflow="hidden">
          <Image
            source={getIssueImage(issue.slug)}
            size={10}
            borderRadius={100}
            alt={issue.label}
          />
          <Text
            fontSize="11"
            textAlign="left"
            fontWeight="400"
            noOfLines={2}
            flex={1}
          >
            <Animated.Text style={{ color: textColor }}>
              {issue.label}
            </Animated.Text>
          </Text>
        </HStack>
      </Animated.View>
    </Pressable>
  );
}
