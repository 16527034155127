import { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

import { Content } from "../components/content/Content";
import { Box, View, Text } from "../components/core/index";
import { FlatList } from "../components/elements/FlatList";
import { LoadingAnimation } from "../components/elements/LoadingAnimation";
import { SearchHeader } from "../components/SearchHeader";
import { useFlagUserBySearch } from "../hooks/useFlagUserBySearch";
import { useSearchContent } from "../hooks/useSearchContent";
import { Body } from "../layout/Body";
import { useTextColor } from "../util/theme-helper";

export function SearchScreen() {
  const { defaultTextColor } = useTextColor();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { data: content, isFetching } = useSearchContent(debouncedSearchTerm);
  const handleChangeSearchTerm = debounce((term: string) => {
    setDebouncedSearchTerm(term);
  }, 1000);

  useFlagUserBySearch(searchTerm);

  const handleInputChanged = useCallback((searchValue: string) => {
    setSearchTerm(searchValue);
    handleChangeSearchTerm(searchValue);
  }, []);

  const shouldLoad = useMemo(
    () => debouncedSearchTerm !== searchTerm || isFetching,
    [debouncedSearchTerm, searchTerm, isFetching]
  );

  return (
    <>
      <Body>
        <View
          opacity={shouldLoad ? 1 : 0}
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          justifyContent="center"
          alignItems="center"
        >
          <LoadingAnimation size={100} isLoading={true} />
        </View>
        <FlatList
          data={shouldLoad ? [] : content}
          pt="10"
          px="4"
          ListHeaderComponent={
            <SearchHeader value={searchTerm} onChange={handleInputChanged} />
          }
          ListEmptyComponent={
            <Box alignSelf="center" top="25%">
              {!shouldLoad && (
                <Text color={defaultTextColor}>No content found.</Text>
              )}
            </Box>
          }
          contentContainerStyle={{
            flex: shouldLoad ? 1 : 0,
          }}
          renderItem={({ item }) => (
            <Box>
              <Content variant="horizontal" item={item} />
            </Box>
          )}
          keyExtractor={({ id }) => id}
        />
      </Body>
    </>
  );
}
