import { useMemo } from "react";
import { UseFormSetError, FieldValues } from "react-hook-form";

export function useFormErrors(setError: UseFormSetError<FieldValues>) {
  return useMemo(
    () => ({
      setErrors: (data: Record<string, string | undefined>) => {
        Object.entries(data).forEach(([key, value]) => {
          setError(key, { type: "manual", message: value ?? "" });
        });
      },
    }),
    [setError]
  );
}
