import { CountryCode } from "api/src/users/types/countries";

export interface IEmergencyContact {
  number: string;
  numberWithCountryCode: string;
  countryCode: CountryCode;
  contactName: string;
  error: boolean;
  fieldName: string;
  placeholder: string;
  label: string;
}

export const CONTACTS_NAME_INPUT_DETAIL = [
  {
    fieldName: "firstContactName",
    placeholder: "First contact name",
    label: "Emergency Contact 1",
  },
  {
    fieldName: "secondContactName",
    placeholder: "Second contact name",
    label: "Emergency Contact 2",
  },
];

export const DEFAULT_CONTACTS: IEmergencyContact[] = [
  {
    number: "",
    numberWithCountryCode: "",
    countryCode: "GB",
    contactName: "",
    error: false,
    ...CONTACTS_NAME_INPUT_DETAIL[0],
  },
  {
    number: "",
    numberWithCountryCode: "",
    countryCode: "GB",
    contactName: "",
    error: false,
    ...CONTACTS_NAME_INPUT_DETAIL[1],
  },
];
