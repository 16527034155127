import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

interface ICompleteLogin {
  login: string;
}

export function useCompleteLoginActivity() {
  const queryClient = useQueryClient();
  return useMutation(
    "complete-user-login",
    async ({ login }: ICompleteLogin) => {
      await api.post(`users/complete-user-login/${login}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("account");
      },
    }
  );
}
