import { View } from "native-base";
import React, { useEffect, useMemo } from "react";
import { Linking, Dimensions, ViewStyle } from "react-native";
import AutoHeightWebView from "react-native-autoheight-webview";

import { isiOS } from "../util/platform";

interface IHtmlViewProps {
  html?: string;
  style?: ViewStyle;
  onInitialized?: () => void;
}

const { width } = Dimensions.get("window");

export const HtmlView = React.memo(
  ({ html = "", style = {}, onInitialized = () => null }: IHtmlViewProps) => {
    const value = useMemo(
      () =>
        `<p>${html
          ?.replace(/<br>/gi, "")
          // eslint-disable-next-line quotes
          ?.replace(/"\/\/www/g, '"https://www')}</p>`,
      [html]
    );

    // Fallback just in case onInitialised is not called for some reason
    // (resulting in an infinite loading screen)
    useEffect(() => {
      setTimeout(() => {
        onInitialized();
      }, 3000);
    }, []);

    return (
      <View py={7.5} style={style}>
        {/* @ts-ignore */}
        <AutoHeightWebView
          bounces={false}
          cacheEnabled={false}
          scrollEnabled={false}
          originWhitelist={["*"]}
          customStyle={HTML_STYLES}
          androidLayerType="hardware"
          pullToRefreshEnabled={false}
          source={{
            html: value,
            baseUrl: "",
          }}
          // https://github.com/react-native-webview/react-native-webview/issues/1069#issuecomment-651699461
          style={{ minHeight: 1, opacity: 0.99 }}
          viewportContent={`width=${width}, initial-scale=1.0, user-scalable=no`}
          onLoadProgress={({
            nativeEvent,
          }: {
            nativeEvent: { progress: number };
          }) => {
            if (nativeEvent.progress === 1) {
              onInitialized();
            }
          }}
          // https://stackoverflow.com/questions/60493866/onshouldstartloadwithrequest-automatically-calling-in-ios-react-native-on-loadin
          onShouldStartLoadWithRequest={(event: {
            navigationType: string;
            url: string;
          }) => {
            const isExternalLink = isiOS()
              ? event.navigationType === "click"
              : true;

            if (event.url.startsWith("http") && isExternalLink) {
              Linking.canOpenURL(event.url).then((supported) => {
                if (supported) {
                  Linking.openURL(event.url);
                }
              });
              return false;
            }

            return true;
          }}
        />
      </View>
    );
  }
);

const HTML_STYLES = `
html,body {
    width: ${width - 40}px;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: "San Francisco", "Roboto", Calibri !important;
}
* {
    word-wrap: break-word;
    margin-top: 0 !important;
    color: #000000 !important;
    line-height: 1.4;
    overflow-wrap: break-word;
    max-width: ${width - 40}px;
    font-family: sans-serif !important;
}
p {
  font-size: 15px !important;
}
div {
  font-size: 22px;
}
span {
    font-size: inherit;
}
iframe {
  border: 0 !important;
  width: 100% !important;
  height: 200px !important;
  display: block !important;
  position: initial !important;
  background: #f5f5f5 !important;
}
a {
    color: black !important;
    text-decoration: none;
    color: #EF5DA8 !important;

}
h1,h2,h3,h1 *,h2 *,h3 * {
    font-size: 18px !important;
    margin-top: 25px !important;
    margin-bottom: 5px !important;
    padding-bottom: 6px !important;
    color: #EF5DA8 !important;
}
h4,h5,h6 {
    font-size: 16px !important;
    margin-bottom: 10px;
}
img{
    max-width: 100%;
    height: auto !important;
    margin: 20px auto 10px auto;
}
li {
    font-size: 15px;
    margin-bottom: 5px;
}
nav {
    font-size: 16px;
}

em {
  font-size: 15px !important;
}
`;
