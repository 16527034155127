import { GratitudeQuestion } from "../enum/GratitudeQuestion";

export const GRATITUDE_ENTRIES = [
  {
    label: "10 things I'm grateful for:",
    question: GratitudeQuestion.gratefulFor,
  },
  {
    label: "3 things I learned from my challenges:",
    question: GratitudeQuestion.whatILearned,
  },
  {
    label: "5 people who made my life happier:",
    question: GratitudeQuestion.peopleThatMakeYouHappier,
  },
  {
    label: "The best part of my day",
    question: GratitudeQuestion.bestPartOfTheDay,
  },
];
