import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

type ICheckoutRequest = Record<string, string>;

export function useReactivateSubscription() {
  const queryClient = useQueryClient();
  return useMutation<boolean, null, ICheckoutRequest>(
    "checkout",
    async () => {
      const response = await api.post<null>("subscriptions/reactivate", {});
      return response.status === 200;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["active-subscriptions"]);
      },
    }
  );
}
