import dayjs from "dayjs";
import { FormControl, Text, Box, HStack, Pressable } from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { Control, useFieldArray } from "react-hook-form";
import DateTimePickerModal from "react-native-modal-datetime-picker";

import { HabitReminderType, ReminderType } from "../type/HabitReminderTypes";

import { IInputProps } from "./form/Input";
import { HabitReminderItem } from "./HabitReminderItem";

export function HabitReminders({
  name,
  errors = {},
  placeholder = "00:00 am",
  control,
  reminders,
  ...props
}: IInputProps & { control: Control<HabitReminderType>; reminders: string[] }) {
  const [isDatePickerVisible, setIsDatePickerVisible] =
    useState<boolean>(false);
  const [isAddNew, setIsAddNew] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const { fields, append, replace, update, remove } = useFieldArray({
    control,
    name: "reminders",
  });

  const errorMessage = errors[name]?.message;

  useEffect(() => {
    const allReminders: ReminderType[] = [];
    reminders?.forEach((time) => {
      if (time) {
        allReminders.push({ time });
      }
    });
    replace(allReminders);
  }, []);

  const handleConfirm = useCallback(
    (date: Date) => {
      const reminderTime = dayjs(date).format("hh:mm A");

      if (!isAddNew) {
        update(index, { time: reminderTime });
      } else {
        append({ time: reminderTime });
      }
      setIsDatePickerVisible(false);
      setIsAddNew(false);
    },
    [isAddNew, isDatePickerVisible, index]
  );
  return (
    <>
      <DateTimePickerModal
        isVisible={isDatePickerVisible}
        mode="time"
        onConfirm={handleConfirm}
        onCancel={() => {
          setIsAddNew(false);
          setIsDatePickerVisible(false);
        }}
      />

      {fields.map((field, index) => (
        <HabitReminderItem
          key={field.id}
          errors={errors}
          name={name}
          index={index}
          field={field}
          remove={remove}
          setIndex={setIndex}
          setIsAddNew={setIsAddNew}
          setIsDatePickerVisible={setIsDatePickerVisible}
        />
      ))}
      <FormControl isInvalid={!!errorMessage} {...props}>
        <Pressable
          _pressed={{ opacity: 0.8 }}
          onPress={() => {
            setIsAddNew(true);
            setIsDatePickerVisible(true);
          }}
        >
          <Box
            px={3}
            h={50}
            borderWidth={0}
            color="gray.800"
            key="initial-item"
            borderRadius={10}
            pointerEvents="none"
            background="rgba(0,0,0,.05)"
            placeholderTextColor="white"
            {...props}
            alignItems="flex-start"
            justifyContent="center"
          >
            <HStack justifyContent="space-between" width="100%">
              <Text fontSize={14} fontWeight="400" color="gray.400">
                {fields?.length
                  ? "Add another reminder time"
                  : "Set time of your reminder"}
              </Text>
              <Text fontSize={14} fontWeight="400" color="gray.400">
                {placeholder}
              </Text>
            </HStack>
          </Box>
        </Pressable>
      </FormControl>
    </>
  );
}
