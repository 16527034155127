import { Box, Text, useToast as useToastNB } from "native-base";
import React, { useCallback, useRef } from "react";
import { Animated, Dimensions } from "react-native";

import { Icon } from "../components/elements/Icon";
import { isWeb } from "../util/platform";

export enum ToastStatus {
  info = "info",
  warning = "warning",
  success = "success",
  error = "error",
}

interface IToastStatus {
  type: ToastStatus;
  color: string;
  icon: string;
}

const TOAST_STATUS: IToastStatus[] = [
  {
    type: ToastStatus.success,
    color: "emerald.500",
    icon: "checkcircleo",
  },
  {
    type: ToastStatus.error,
    color: "red.500",
    icon: "exclamationcircleo",
  },
  {
    type: ToastStatus.warning,
    color: "orange.500",
    icon: "warning",
  },
  {
    type: ToastStatus.info,
    color: "blue.500",
    icon: "infocirlceo",
  },
];

interface IToast {
  title: string;
  status: ToastStatus;
}

export function useToast() {
  const toast = useToastNB();
  const { width, height } = Dimensions.get("window");
  const popAnimation = useRef(new Animated.Value(height)).current;

  const popIn = useCallback(
    () =>
      Animated.timing(popAnimation, {
        toValue: height * 0.065,
        duration: 1000,
        useNativeDriver: true,
      }).start(popOut),
    [toast]
  );

  const popOut = useCallback(
    () =>
      setTimeout(() => {
        Animated.timing(popAnimation, {
          toValue: height,
          duration: 1000,
          useNativeDriver: true,
        }).start();
      }, 2000),
    [toast]
  );

  return useCallback(
    ({ title, status }: IToast) => {
      const currentStatus =
        TOAST_STATUS.find((toastStatus) => toastStatus.type === status) ||
        TOAST_STATUS[0];

      toast.show({
        duration: isWeb() ? 2000 : 5000,
        placement: isWeb() ? "top-right" : "bottom",
        render: () => (
          <Animated.View
            style={[
              {
                transform: [{ translateY: isWeb() ? 0 : popAnimation }],
              },
            ]}
          >
            <Box
              _ios={{ marginBottom: "30" }}
              _web={{ marginRight: "4", borderRadius: "8" }}
              bg={currentStatus.color}
              px="3"
              minW={isWeb() ? 320 : width}
              height="50"
              alignItems="center"
              justifyContent="flex-start"
              flexDirection="row"
            >
              <Box mr="2">
                <Icon icon={currentStatus.icon} size={4} color="white" />
              </Box>
              <Text fontWeight="600" color="white" pt="0.5">
                {title}
              </Text>
            </Box>
          </Animated.View>
        ),
      });
      popIn();
    },
    [toast]
  );
}
