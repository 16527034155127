import { ActivityLogType } from "api/src/activity-logs/enum/ActivityLogType";
import { useMutation } from "react-query";

import { api } from "../util/api";

export function useActivityCompleted(type: ActivityLogType) {
  return useMutation<boolean, null, null>("record-activity-log", async () => {
    await api.post("activity-logs/record", {
      activityType: type,
    });
    return true;
  });
}
