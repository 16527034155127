import { View, VStack, Heading, Text } from "native-base";
import React, { ReactNode } from "react";

interface IAuthCardProps {
  label: string;
  subheading?: string | ReactNode;
  message?: string | ReactNode;
  children: ReactNode;
}

export function AuthCard({
  label,
  children,
  subheading,
  message,
}: IAuthCardProps) {
  return (
    <View borderRadius={15} maxW="100%">
      <View p={4}>
        <VStack
          p={2}
          mb={6}
          space={1}
          alignItems="center"
          justifyContent="center"
        >
          <Heading
            fontSize={26}
            fontWeight="600"
            w="100%"
            textAlign={["center", "center"]}
          >
            {label}
          </Heading>
          {subheading ? (
            <Text
              fontSize={14}
              lineHeight={24}
              w="100%"
              textAlign={["center", "center"]}
              color="secondary.750"
            >
              {subheading}
            </Text>
          ) : null}
          {message ? (
            <Text
              fontSize={13}
              lineHeight={24}
              w="100%"
              textAlign={["center", "center"]}
              color="secondary.750"
            >
              {message}
            </Text>
          ) : null}
        </VStack>
        <View>{children}</View>
      </View>
    </View>
  );
}
