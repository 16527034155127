import { useQuery } from "react-query";

import { api } from "../util/api";

export function useSavedContentIds() {
  return useQuery<string[]>(
    "saved-content-ids",
    async () => {
      const response = await api.get<string[]>("content/saved-ids", {});
      return response.data ?? [];
    },
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );
}
