import { useNavigation, useRoute } from "@react-navigation/core";
import { JournalTitleEntry } from "api/src/journal/constants/journal-title-entries";
import { JournalEntryType } from "api/src/journal/enum/JournalEntryType";
import dayjs from "dayjs";
import {
  Box,
  Divider,
  Pressable,
  Text,
  VStack,
  HStack,
  useToken,
} from "native-base";
import React, { useMemo, useState } from "react";

import { JOURNAL_DETAILS_SCREENS } from "../../constants/journal-details-screens";
import {
  JOURNAL_ENTRY_SCREENS,
  JOURNAL_ENTRY_POINT,
} from "../../constants/journal-entry-screens";
import { useJournalEntryDelete } from "../../hooks/useJournalEntryDelete";
import { useToast, ToastStatus } from "../../hooks/useToast";
import { getJournalDeleteSuccessMsg } from "../../util/journal-delete-message";
import { isWeb } from "../../util/platform";
import { AlertDialog } from "../elements/AlertDialog";
import { IconButton } from "../elements/IconButton";
import { ArrowForwardIcon } from "../icons/ArrowForwardIcon";
import { PencilIcon } from "../icons/PencilIcon";
import { TrashIcon } from "../icons/TrashIcon";

interface IJournalEntryProps {
  id?: string;
  title: JournalTitleEntry | string;
  createdAt?: Date;
  type?: JournalEntryType;
}

export function JournalEntry({
  id,
  title,
  createdAt,
  type,
}: IJournalEntryProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const route = useRoute();
  const toast = useToast();

  const { navigate } = useNavigation();

  const [secondaryColor] = useToken("colors", ["secondary.400"]);
  const { mutate: deleteJournalEntry } = useJournalEntryDelete(id);

  const date = useMemo(
    () => dayjs(createdAt).format("DD/MM/YYYY hh:mm A"),
    [createdAt]
  );

  function handleDetailsJournalEntry() {
    navigate(JOURNAL_DETAILS_SCREENS[type as JournalEntryType], {
      journalId: id,
    });
  }

  function handleAddJournalEntry() {
    navigate(JOURNAL_ENTRY_SCREENS[title as JournalTitleEntry], {
      journalId: undefined,
    });
  }

  const onClose = () => setIsOpen(false);

  function handleDelete() {
    deleteJournalEntry(
      {
        journalId: id,
      },
      {
        onSuccess: () => {
          onClose();
          toast({
            title: getJournalDeleteSuccessMsg(type),
            status: ToastStatus.success,
          });
        },
      }
    );
  }

  return (
    <VStack>
      <HStack>
        <Pressable
          flex="1"
          borderRadius={5}
          _pressed={{ bg: "secondary.200" }}
          _dark={{ _pressed: { bg: "#0a0c13" } }}
          onPress={
            route.name === "MyJournal"
              ? handleDetailsJournalEntry
              : handleAddJournalEntry
          }
        >
          <Box
            flexDirection={createdAt ? "column" : "row"}
            justifyContent="space-between"
            py="5"
            px="1"
            alignItems={createdAt ? "flex-start" : "center"}
          >
            <Text fontSize={16} fontWeight="500">
              {title}
            </Text>
            {createdAt ? (
              <VStack alignItems="center">
                <Text fontSize={12} color="secondary.450">
                  {date}
                </Text>
              </VStack>
            ) : (
              <ArrowForwardIcon size="sm" />
            )}
          </Box>
        </Pressable>
        {isWeb() && type && (
          <>
            <HStack space="2" alignItems="center">
              <IconButton
                onPress={() => {
                  navigate(JOURNAL_ENTRY_POINT[type], { journalId: id });
                }}
              >
                <PencilIcon size="lg" />
              </IconButton>
              <IconButton
                onPress={() => {
                  setIsOpen(true);
                }}
              >
                <TrashIcon size="4xl" color={secondaryColor} />
              </IconButton>
            </HStack>
            <AlertDialog
              headerText="Delete Entry"
              bodyText="This will remove your entry. Are you sure you want to
          delete it?"
              buttonText="Delete"
              buttonAction={handleDelete}
              isOpen={isOpen}
              onClose={onClose}
            />
          </>
        )}
      </HStack>
      <Divider />
    </VStack>
  );
}
