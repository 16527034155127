import { IOrganisationStats } from "api/src/organisations/interfaces/IOrganisationStats";
import { HStack, Text } from "native-base";
import React from "react";

import { CircleProgressBar } from "../../../CircleProgressBar";

interface IGeneralOrganisationStats {
  organisationStats: IOrganisationStats[] | undefined;
}

export function GeneralOrganisationStats({
  organisationStats,
}: IGeneralOrganisationStats) {
  return (
    <HStack justifyContent="flex-start" space={3}>
      {organisationStats &&
        organisationStats.map(({ label, percentage }: IOrganisationStats) => (
          <HStack key={label} alignItems="center" space={2}>
            <CircleProgressBar percentage={Math.floor(percentage)} />
            <Text fontSize="xs" fontWeight={500} maxW={125}>
              {label}
            </Text>
          </HStack>
        ))}
    </HStack>
  );
}
