import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { useActiveSubscription } from "./useActiveSubscription";

interface ICancelSubscriptionRequest {
  instant?: boolean;
}

export function useCancelSubscription() {
  const queryClient = useQueryClient();
  const { data: activeSubscription } = useActiveSubscription();

  return useMutation<boolean, null, ICancelSubscriptionRequest>(
    ["cancel"],
    async ({ instant }: ICancelSubscriptionRequest) => {
      const response = await api.post<null>("subscriptions/cancel", {
        instant,
      });

      return response.status === 200;
    },
    {
      onSuccess: (_, { instant }) => {
        queryClient.setQueryData(
          ["active-subscriptions"],
          instant
            ? null
            : {
                ...activeSubscription,

                cancelAtPeriodEnd: instant
                  ? true
                  : !activeSubscription?.cancelAtPeriodEnd,
              }
        );

        queryClient.invalidateQueries(["subscriptions"]);
      },
    }
  );
}
