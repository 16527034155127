import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function FertilitySupportIcon({ ...props }: IIconProps) {
  return (
    <Icon size={79} {...props}>
      <Svg width="79" height="90" viewBox="-5 0 85 90" fill="none">
        <Path
          d="M34.5205 0C37.9425 0 41.2244 1.31169 43.6442 3.64652C46.0639 5.98135 47.4233 9.14806 47.4233 12.45C47.4233 15.7519 46.0639 18.9187 43.6442 21.2535C41.2244 23.5883 37.9425 24.9 34.5205 24.9C31.0984 24.9 27.8165 23.5883 25.3968 21.2535C22.977 18.9187 21.6176 15.7519 21.6176 12.45C21.6176 9.14806 22.977 5.98135 25.3968 3.64652C27.8165 1.31169 31.0984 0 34.5205 0ZM68.9281 66.4L60.3262 43.824C58.8209 39.7155 57.4876 36.1465 51.7243 33.2C45.789 30.295 41.488 29.05 34.5205 29.05C27.5099 29.05 23.252 30.295 17.3166 33.2C11.5534 36.1465 10.2201 39.7155 8.71473 43.824L0.112813 66.4C-1.26349 72.0025 10.2631 76.526 17.7897 79.6385V70.55C17.7897 66.6075 21.4886 63.827 28.8862 62.1255C29.5744 61.9595 30.2195 61.8765 30.7356 61.7935C28.4131 58.3905 27.4669 55.361 27.3809 55.112L34.9936 52.622C35.0366 52.705 37.2301 59.2205 42.4342 62.499C43.3374 62.7895 44.2406 63.1215 45.1008 63.495C48.4126 64.906 50.391 66.732 51.0361 68.9315C45.2729 71.131 39.7676 72.2515 34.5205 72.2515L30.2195 71.8365V82.751L34.5205 83C40.4128 83 46.004 81.838 51.2512 79.6385C58.7779 76.526 70.0033 71.0895 68.9281 66.4ZM49.5738 62.25C47.8628 62.25 46.2218 61.5942 45.012 60.4267C43.8021 59.2593 43.1224 57.676 43.1224 56.025C43.1224 54.374 43.8021 52.7907 45.012 51.6233C46.2218 50.4558 47.8628 49.8 49.5738 49.8C51.2848 49.8 52.9258 50.4558 54.1357 51.6233C55.3455 52.7907 56.0252 54.374 56.0252 56.025C56.0252 57.676 55.3455 59.2593 54.1357 60.4267C52.9258 61.5942 51.2848 62.25 49.5738 62.25Z"
          fill="#DF3D8D"
        />
      </Svg>
    </Icon>
  );
}
