import { useQuery } from "react-query";

import { ChartData } from "../components/elements/Chart";
import { api } from "../util/api";

export function useMoods() {
  return useQuery<ChartData>(
    "moods",
    async () => {
      const response = await api.get<ChartData>("emotion/moods", {});

      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Couldn't fetch moods");
    },
    {
      keepPreviousData: true,
    }
  );
}
