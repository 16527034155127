import { SubscriptionStatus } from "api/src/subscription/enum/SubscriptionStatus";
import { ISubscription } from "api/src/subscription/interface/ISubscription";
import { UserEntity } from "api/src/users/entities/user.entity";

import { timeAgo } from "./time";

/**
 * Returns a string indicating the status of a user's subscription to Miindset.
 * @param activeSubscription - An object containing information about the user's active subscription.
 * @param me - An object containing information about the user.
 * @returns A string providing information about the user's subscription status.
 */
export const getSubscriptionText = (
  activeSubscription?: ISubscription,
  me?: UserEntity
): string => {
  const isActive = !activeSubscription?.isFree;

  if (activeSubscription?.isPermanent) {
    return "You have free access to Miindset Premium for life!";
  }

  if (activeSubscription?.isSpecialPrice) {
    return "Miindsets goal, is to assist as many people as possible improve their mental health, If you feel Miindset is continuing to support & improve your mental health, you can continue to have access to Miindset at a very special discounted price of £1.99 per calendar month";
  }

  if (!isActive && !me?.hasTrialed && me?.accountType === "student") {
    return "Start your 30 day free trial to access Miindset's wellbeing features";
  }

  if (!isActive && !me?.hasTrialed) {
    return "Start your 30 day free trial to access Miindset's wellbeing features";
  }

  if (!isActive && me?.hasTrialed) {
    return "Subscribe to Miindset Premium today to access Miindset's wellbeing features";
  }

  if (
    isActive &&
    activeSubscription?.cancelAtPeriodEnd &&
    activeSubscription?.status === SubscriptionStatus.trialing
  ) {
    return `Your free trial ends in ${timeAgo(
      activeSubscription?.periodEnd as string | Date
    )}. Reactivate to continue using Miindset's wellbeing features.`;
  }

  if (activeSubscription?.status === SubscriptionStatus.ended) {
    return "Your subscription has expired. Reactivate to continue using Miindset's wellbeing features.";
  }

  if (isActive && activeSubscription?.cancelAtPeriodEnd) {
    return `Your Miindset Premium subscription will end in ${timeAgo(
      activeSubscription?.periodEnd as string | Date
    )}. Reactivate now to keep access to Miindset!`;
  }

  if (isActive) {
    return "You are currently subscribed to Miindset Premium! Enjoy using Miindset's wellbeing features.";
  }

  return "A subscription is required to continue";
};
