import { DayMoodsDto } from "api/src/emotion/dto/day-moods.dto";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useDayMoods() {
  return useQuery<DayMoodsDto>(
    "day-moods",
    async () => {
      const response = await api.get<DayMoodsDto>("emotion/days-moods", {});

      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Couldn't fetch moods");
    },
    {
      keepPreviousData: true,
    }
  );
}
