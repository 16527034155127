import { ContentEntity } from "api/src/content/entity/content.entity";
import { useInfiniteQuery } from "react-query";

import { IPaginatedResponse } from "../interfaces/IPaginatedResponse";
import { api } from "../util/api";

export function useReviewContents(
  searchTerm: string,
  status: string,
  channel: string,
  startingDate: string,
  endingDate: string
) {
  return useInfiniteQuery<IPaginatedResponse<ContentEntity>>(
    [
      "content",
      "review",
      searchTerm,
      status,
      channel,
      startingDate,
      endingDate,
    ],
    async ({ pageParam = 1 }) => {
      const response = await api.get<IPaginatedResponse<ContentEntity>>(
        "content/review",
        {
          params: {
            page: pageParam,
            searchTerm,
            status,
            channel,
            startingDate,
            endingDate,
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get recommended content");
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.currentPage < lastPage.meta.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
      },
    }
  );
}
