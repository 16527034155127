import { VStack, Text, HStack, Heading, Box, Divider } from "native-base";
import React from "react";

import { DigestItem } from "../../components/digest-media/DigestItem";
import { IconButton } from "../../components/elements/IconButton";
import {
  BrainIcon,
  CloseCircularIcon,
  CoffeeIcon,
  MyJournalIcon,
  WindIcon,
} from "../../components/icons/Icons";
import { DIGEST_TYPES } from "../../constants/digest-types";

import { ISignpostingScreenProps } from "./SignpostingScreen";

export function LowAlertScreens({
  handleCloseButton,
  handleNavigationButton,
}: ISignpostingScreenProps) {
  return (
    <VStack space={4}>
      <HStack alignItems="center" w="100%" justifyContent="center">
        <Heading fontSize="20" fontWeight={600}>
          Please look after yourself.
        </Heading>
      </HStack>
      <Box pb={20} justifyContent="center">
        <VStack space={4} alignItems="center">
          <Text textAlign="center" color="primary.400">
            It seems like you are struggling a little at the moment, please look
            after yourself and check in to Miindset regularly.
          </Text>
          <HStack space={2}>
            {DIGEST_TYPES.map(({ label, icon }) => (
              <DigestItem label={label} icon={icon} key={label} />
            ))}
          </HStack>
          <Text textAlign="center">
            You can find useful <Text color="primary.400"> articles</Text>,
            <Text color="primary.400"> videos</Text> and
            <Text color="primary.400"> podcasts</Text> tailored to how you are
            feeling right know here or on Homepage feed.
          </Text>
          <Divider my="4" />
          <BrainIcon />
          <Heading>Coping Strategies</Heading>
          <Text textAlign="center">
            You can find useful Coping Strategies
            <Text
              color="primary.400"
              onPress={() => {
                handleNavigationButton("CopingStrategies");
              }}
            >
              {" "}
              here
            </Text>
            .
          </Text>
          <Divider my="4" />
          <WindIcon color="primary.400" />
          <Heading>Breathing Exercises</Heading>
          <Text textAlign="center">
            You can also try one of our built in
            <Text
              color="primary.400"
              onPress={() => {
                handleNavigationButton("BreathingExercises");
              }}
            >
              {" "}
              breathing exercises
            </Text>
            .
          </Text>
          <Divider my="4" />
          <Text textAlign="center">
            We have a{" "}
            <Text
              color="primary.400"
              onPress={() => {
                handleNavigationButton("MyJournal");
              }}
            >
              {" "}
              Journaling Tool
            </Text>{" "}
            and
            <Text
              color="primary.400"
              onPress={() => {
                handleNavigationButton("GetGrounded");
              }}
            >
              {" "}
              Grounding Exercises
            </Text>
            , you can try to lift your mood and feel better in this moment.
          </Text>
          <HStack justifyContent="space-around" w="100%">
            <VStack alignItems="center" w="22.5%" space={2}>
              <MyJournalIcon color="primary.400" />
              <Text
                textAlign="center"
                onPress={() => {
                  handleNavigationButton("MyJournal");
                }}
              >
                Try Writing A Journal
              </Text>
            </VStack>
            <VStack alignItems="center" w="25%" space={2}>
              <CoffeeIcon color="primary.400" />
              <Text textAlign="center">Try Grounding Exercises</Text>
            </VStack>
          </HStack>
          <Divider my="4" />
          <Text textAlign="center">
            You can also track your mood and how you are feeling with our
            <Text
              color="primary.400"
              onPress={() => {
                handleNavigationButton("TrackMood");
              }}
            >
              {" "}
              Mood Tracker{" "}
            </Text>
            and{" "}
            <Text
              color="primary.400"
              onPress={() => {
                handleNavigationButton("TrackFeelings");
              }}
            >
              {" "}
              Feelings Tracker
            </Text>
            .
          </Text>
          <Text textAlign="center">
            Tracking how you are feeling can be helpful because in the moment it
            can often feel like your mood is low more often than it is! However,
            if you track your mood over time and then look back at how you were
            feeling, you can often find that you were feeling better more often
            than you remembered. This is completely normal, humans remember the
            negative mood and emotions more strongly than positive ones, so it
            can be uplifting to realise you were doing better than you thought
            :)
          </Text>
        </VStack>
        <HStack pt={4} justifyContent="center">
          <IconButton onPress={handleCloseButton}>
            <CloseCircularIcon size={12} />
          </IconButton>
        </HStack>
      </Box>
    </VStack>
  );
}
