import { SubmitMoodDto } from "api/src/emotion/dto/submit-mood.dto";
import { useMutation } from "react-query";

import { EVENT_RECORD_MOOD } from "../constants/analytics";
import { eventHit } from "../util/analytics";
import { api } from "../util/api";

export function useMoodSubmit() {
  return useMutation<null, { message: string }, SubmitMoodDto>(
    "mood/submit",
    async (data: SubmitMoodDto) => {
      const response = await api.post("emotion/mood", data);

      if (response.status !== 201) {
        throw response.data;
      }

      eventHit("submit", EVENT_RECORD_MOOD);

      return null;
    }
  );
}
