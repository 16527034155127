import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function HideIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 21 22" {...props}>
      <Svg width={21} height={22} fill="none" stroke={color}>
        <Path
          d="M4.195 3.735 16.87 18.09M12.665 13.328a3.115 3.115 0 0 1-2.282.844 3.132 3.132 0 0 1-2.194-1.064 3.321 3.321 0 0 1-.82-2.35A3.303 3.303 0 0 1 8.402 8.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M6.252 6.067c-3.23 1.684-4.594 4.846-4.594 4.846s2.535 5.873 8.873 5.873a9.13 9.13 0 0 0 4.277-1.028M16.916 14.265c1.727-1.592 2.486-3.352 2.486-3.352s-2.535-5.874-8.872-5.874c-.549 0-1.097.045-1.638.137"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M11.13 7.708a3.148 3.148 0 0 1 1.75 1.01c.46.522.745 1.185.81 1.888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
