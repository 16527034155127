import { NotificationEntity } from "api/src/notification/entities/notification.entity";
import { useInfiniteQuery } from "react-query";

import { IPaginatedResponse } from "../interfaces/IPaginatedResponse";
import { api } from "../util/api";

export interface IUseUsersNotification {
  limit?: number;
}

export function useUsersNotification({ limit = 10 }: IUseUsersNotification) {
  return useInfiniteQuery<IPaginatedResponse<NotificationEntity>>(
    ["notifications", limit],
    async ({ pageParam = 1 }) => {
      const response = await api.get<IPaginatedResponse<NotificationEntity>>(
        "notification",
        {
          params: { page: pageParam, limit },
        }
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Something thing went wrong");
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.currentPage < lastPage.meta.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
      },
    }
  );
}
