import {
  Entypo,
  Feather,
  Ionicons,
  AntDesign,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { Icon as NativeBaseIcon } from "native-base";
import React from "react";
import { ViewStyle } from "react-native";

export enum IconLibrary {
  entypo = "entypo",
  feather = "feather",
  ionicons = "ionicons",
  material = "material",
  antDesign = "antDesign",
  fontAwesome = "fontAwesome",
  fontAwesome5 = "fontAwesome5",
  materialCommunity = "materialCommunity",
}

export interface IIconProps {
  size?: number;
  color?: string;
  style?: ViewStyle;
  /**
   * {@link https://icons.expo.fyi/}
   */
  icon: string;
  /**
   * Choose which icon library to use,
   * any specified in `IconLibrary` enum can be used
   */
  iconLibrary?: IconLibrary;
}

export function Icon({
  icon,
  style,
  color,
  size = 20,
  iconLibrary: library = IconLibrary.antDesign,
}: IIconProps) {
  /**
   * Return the specified icon set.
   *
   * @param {IconLibrary} library
   */
  function getIconSet(library: IconLibrary) {
    switch (library) {
      case IconLibrary.antDesign:
        return AntDesign;
      case IconLibrary.fontAwesome:
        return FontAwesome;
      case IconLibrary.fontAwesome5:
        return FontAwesome5;
      case IconLibrary.entypo:
        return Entypo;
      case IconLibrary.feather:
        return Feather;
      case IconLibrary.ionicons:
        return Ionicons;
      case IconLibrary.material:
        return MaterialIcons;
      case IconLibrary.materialCommunity:
        return MaterialCommunityIcons;
      default:
        IconLibrary.fontAwesome;
    }
  }

  const IconSet = getIconSet(library);

  return (
    <NativeBaseIcon
      as={IconSet}
      name={icon}
      size={size}
      style={style}
      color={color}
    />
  );
}
