import {
  VStack,
  Heading,
  Box,
  FormControl,
  Input,
  useColorModeValue,
} from "native-base";
import React from "react";
import { useForm } from "react-hook-form";

import { SearchIcon } from "./icons/SearchIcon";

interface ISearchHeaderProps {
  value: string;
  onChange: (value: string) => void;
}
export function SearchHeader({ value, onChange }: ISearchHeaderProps) {
  const { control } = useForm();

  const iconColor = useColorModeValue("secondary.800", "secondary.400");

  return (
    <VStack mb="4" px="2">
      <Heading size="lg" fontWeight="600" mb="2">
        Search
      </Heading>
      <Box>
        <FormControl>
          <Input
            // @ts-ignore
            control={control}
            name="searchValue"
            variant="graySearch"
            value={value}
            onChangeText={onChange}
            InputRightElement={
              <Box pr="3">
                <SearchIcon size="md" color={iconColor} />
              </Box>
            }
          />
        </FormControl>
      </Box>
    </VStack>
  );
}
