import { SaveContentDto } from "api/src/content/dto/save-content.dto";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { useSavedContentIds } from "./useSavedContentIds";

interface ISaveContentResponse {
  isSaved: boolean;
}

export function useSaveContent() {
  const queryClient = useQueryClient();
  const { data: savedContentIds = [] } = useSavedContentIds();

  return useMutation<ISaveContentResponse, null, SaveContentDto>(
    "save-content",
    async (data: SaveContentDto) => {
      const shouldDelete = savedContentIds?.includes(data.contentId);

      queryClient.setQueryData(
        "saved-content-ids",
        savedContentIds?.includes(data.contentId)
          ? savedContentIds?.filter((content) => content !== data.contentId)
          : [...savedContentIds, data.contentId]
      );

      const method = shouldDelete ? "delete" : "post";
      const response = await api[method](
        `content/save/${shouldDelete ? data.contentId : ""}`,
        data
      );

      if (response?.status > 400) {
        throw Promise.reject("Could not save/delete content");
      }

      return { isSaved: !shouldDelete };
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("saved-content-ids");
      },
    }
  );
}
