import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

type IAddDepartment = Record<string, string>;

export function useAddDepartment() {
  const queryClient = useQueryClient();
  return useMutation<
    IApiResponse<IAddDepartment>,
    IApiResponse<IAddDepartment>,
    IAddDepartment
  >(
    ["organisations", "add-department"],
    async (data: IAddDepartment) => {
      const response = await api.post<IApiResponse<IAddDepartment>>(
        "/organisations/add-department",
        data
      );

      if (response.status !== 201) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["organisations", "departments"]);
      },
    }
  );
}
