import { Toaster } from "react-hot-toast";

export function WebToaster() {
  return (
    <Toaster
      position="top-right"
      containerStyle={{
        top: 50,
      }}
      toastOptions={{
        duration: 3000,
        className: "",
        style: {
          height: "34px",
          minWidth: "300px",
          color: "white",
          fontWeight: "600",
          fontSize: "14px",
          fontFamily: "Poppins_400Regular",
          background: "#3B82F6",
        },
        success: {
          style: {
            background: "#10B981",
            iconTheme: {
              primary: "white",
              secondary: "#10B981",
            },
          },
        },
        error: {
          style: {
            background: "#EF4444",
            iconTheme: {
              primary: "white",
              secondary: "#EF4444",
            },
          },
        },
      }}
    />
  );
}
