import { isArray } from "lodash";
import { useStyledSystemPropsResolver } from "native-base";
import { IHStackProps as NBHStackProps } from "native-base/lib/typescript/components/primitives/Stack/HStack";
import React, { forwardRef } from "react";
import { View } from "react-native";

import { Box } from "./Box";

interface IHStackProps extends NBHStackProps {
  space?: number;
}

export const HStack = forwardRef<View, React.PropsWithChildren<IHStackProps>>(
  ({ children, space, ...props }: IHStackProps, ref) => {
    const [style, otherProps] = useStyledSystemPropsResolver(props);

    return (
      <Box
        {...otherProps}
        style={style ? { ...style, flexDirection: "row" } : undefined}
        ref={ref}
      >
        {isArray(children) ? (
          children?.map((child, index) =>
            index !== children.length - 1 && space ? (
              <Box marginRight={space}>{child}</Box>
            ) : (
              child
            )
          )
        ) : space ? (
          <Box marginRight={space}>{children}</Box>
        ) : (
          children
        )}
      </Box>
    );
  }
);
