import { APP_STORE_IOS, APP_STORE_ANDROID } from "@env";
import { Image, VStack, Text, Pressable, HStack } from "native-base";
import React from "react";

import { openUrl } from "../util/linking";

export function DownloadAppScreen() {
  return (
    <VStack
      flex={1}
      space={2}
      px={4}
      mt={-4}
      mb={4}
      alignItems="center"
      background="white"
    >
      <Image
        mt={20}
        height={150}
        width={150 * (1000 / 600)}
        source={require("../assets/images/logo.png")}
        alt="Miindset"
      />

      <Image
        height={200}
        width={200}
        source={require("../assets/images/hero-phone.png")}
        resizeMode="contain"
        alt="iphone"
      />
      <Text fontSize={24} fontWeight={600} textAlign="center" mt={10}>
        {"Download our app\nto continue"}
      </Text>

      <HStack space={2} mt={10}>
        <Pressable onPress={() => openUrl(APP_STORE_IOS)}>
          <Image
            height={100}
            width={140}
            source={require("../assets/images/app-store.png")}
            alt="App Store"
            resizeMode="contain"
          />
        </Pressable>
        <Pressable onPress={() => openUrl(APP_STORE_ANDROID)}>
          <Image
            height={100}
            width={140}
            source={require("../assets/images/google-play.png")}
            alt="Play Store"
            resizeMode="contain"
          />
        </Pressable>
      </HStack>
    </VStack>
  );
}
