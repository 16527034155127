import { View, Text, Image, Heading, HStack, VStack, Link } from "native-base";
import React from "react";

import { isWeb } from "../../util/platform";
import { NetworkIcon } from "../icons/NetworkIcon";
import { NutritionIcon } from "../icons/NutritionIcon";

export function NutritionAndDietrySupport() {
  return (
    <View mb={10} mt={50} alignItems="center">
      {!isWeb() ? (
        <Image
          width={63}
          height={75}
          source={require("../../assets/images/badges/nutrition.png")}
          alt="The Concept Of Sacred Spaces"
          borderRadius={10}
        />
      ) : null}
      <Heading mt={5} mb={5} fontSize={28} fontWeight={600} textAlign="center">
        Nutrition And Dietry
      </Heading>
      {isWeb() ? <NutritionIcon /> : null}
      <Heading
        mt={5}
        fontSize={20}
        color="primary.400"
        fontWeight={600}
        textAlign="center"
      >
        NHS
      </Heading>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Eating a healthy, balanced diet is an important part of maintaining good
        health, and can help you feel your best. This means eating a wide
        variety of foods in the right proportions, and consuming the right
        amount of food and drink to achieve and maintain a healthy body weight.
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 860 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Information covering healthy eating advice for the general population
        can be found at the link below. If you have special dietary needs or a
        medical condition you should contact your doctor or a registered
        dietitian for advice.
      </Text>

      <Link
        mt={10}
        href="https://www.nhs.uk/live-well/eat-well/how-to-eat-a-balanced-diet/eating-a-balanced-diet/"
        isExternal={true}
      >
        <HStack space={2}>
          <NetworkIcon />
          <VStack>
            <Text underline={true}>www.nhs.uk/live-well</Text>
          </VStack>
        </HStack>
      </Link>
    </View>
  );
}
