import { Center } from "native-base";
import React from "react";

import { Header } from "../layout/Header";
import { isWeb } from "../util/platform";

import { LoadingAnimation } from "./elements/LoadingAnimation";

export interface ILoadingProps {
  showHeader?: boolean;
  isLoading?: boolean;
  size?: number;
  containerHeight?: number | string;
}

export function Loading({
  showHeader = false,
  isLoading = true,
  size = 150,
  containerHeight = isWeb() ? "80vh" : "80%",
}: ILoadingProps) {
  if (isLoading) {
    return (
      <>
        {showHeader ? <Header /> : null}
        <Center h={containerHeight}>
          <LoadingAnimation isLoading={isLoading} size={size} />
        </Center>
      </>
    );
  }
  return <></>;
}
