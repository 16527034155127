import AnimatedLottieView from "lottie-react-native";
import {
  Box,
  Center,
  Heading,
  useBreakpointValue,
  useColorModeValue,
  useToken,
  View,
  VStack,
  Text,
} from "native-base";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Animated, TouchableOpacity } from "react-native";
import { View as AnimatedView } from "react-native-animatable";

import { Container } from "../../components/Container";
import { Button } from "../../components/elements/Button";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { HabitTrackerIcon } from "../../components/icons/HabitTrackerIcon";
import { SecondLoginContext } from "../../context/SecondLoginContext";
import { TransitionContext } from "../../context/TransitionContext";
import { SecondLoginStepsType } from "../../enum/second-login-steps";

import { AddHabit } from "./AddHabit";
import { SecondLoginSteps } from "./SecondLoginSteps";

const inAnimation = "fadeInDown";

export function HabitTracker() {
  const colourAnimation = useRef(new Animated.Value(0)).current;
  const [, setButtonTopOffset] = useState<number>(0);
  const [, setIsTransitioning] = useState<boolean>(false);
  const { setCurrentStep } = useContext(SecondLoginContext);

  useEffect(() => {
    handleAnimation();
    setCurrentStep(SecondLoginStepsType.habitTracker);
  }, []);

  function handleAnimation() {
    Animated.timing(colourAnimation, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
    }).start(() => {
      setIsTransitioning(false);
    });
  }

  const [primary400, gray100, dark500] = useToken("colors", [
    "primary.400",
    "secondary.200",
    "dark.500",
  ]);
  const backgroundColor = useColorModeValue(gray100, dark500);

  const backgroundColourValue = colourAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [primary400, backgroundColor],
  });

  const backgroundColorBreakpoint = useBreakpointValue({
    base: "white",
    lg: backgroundColourValue,
  });

  const { startTransition } = useContext(TransitionContext);
  const [animationState] = useState<"In" | "OutDown">("In");
  const [isCreateHabit, setIsCreateHabit] = useState(false);

  return (
    <>
      <Animated.View
        style={{
          backgroundColor: backgroundColorBreakpoint,
        }}
      >
        <Container minH="100vh">
          <Box
            alignSelf="center"
            p={10}
            width={{ base: "unset", lg: "70%" }}
            bg="white"
            _dark={{ bg: "dark.100" }}
            borderRadius={5}
          >
            <AnimatedView animation="fadeIn" delay={750} duration={750}>
              <SecondLoginSteps />
            </AnimatedView>
            <VStack
              px={{ base: 0, lg: 4 }}
              pt={5}
              space={8}
              marginTop={!isCreateHabit ? -30 : 20}
            >
              {!isCreateHabit ? (
                <AnimatedView animation={inAnimation} delay={25} duration={750}>
                  <View
                    flex={1}
                    onLayout={(event) => {
                      const { layout } = event.nativeEvent;
                      setButtonTopOffset(layout.height / 2 + layout.y);
                    }}
                  >
                    <Center>
                      <AnimatedLottieView
                        source={require("../../assets/animations/character.json")}
                        speed={0.75}
                        style={{ width: 400, height: 450 }}
                        autoPlay={true}
                        loop={true}
                      />
                    </Center>
                  </View>
                </AnimatedView>
              ) : null}
              <View
                pb={10}
                flex="1"
                justifyContent="center"
                px={{ base: 0, lg: 10 }}
                marginTop={isCreateHabit ? 0 : -70}
              >
                {!isCreateHabit ? (
                  <VStack space={8}>
                    <AnimatedView
                      style={{ width: "100%" }}
                      animation={`fadeInDown${
                        animationState === "In" ? "" : "Out"
                      }`}
                      delay={1000 + 300 + 400}
                      duration={1000}
                    >
                      <Heading
                        fontSize={28}
                        fontWeight="600"
                        textAlign="center"
                      >
                        Habit Tracking
                      </Heading>
                    </AnimatedView>
                    <VStack space={2}>
                      <AnimatedView
                        style={{ width: "100%", alignItems: "center" }}
                        animation={`fadeInLeft${
                          animationState === "In" ? "" : "Out"
                        }`}
                        delay={1000 + 300 + 500}
                        duration={1500}
                      >
                        <Text textAlign="center" lineHeight={24} fontSize={16}>
                          Prioritising your mental health can improve your mood,
                          energy, concentration, and physical health. Small
                          habits will help you reach your larger, less tangible
                          goals, such as being more present, happy, positive, or
                          grateful.
                        </Text>
                        <HabitTrackerIcon m={5} size="6xl" />
                        <Text
                          textAlign="center"
                          lineHeight={24}
                          fontWeight={600}
                          fontSize={13}
                        >
                          A habit tracker reminds you to act.
                        </Text>{" "}
                        <Text mt={3} fontWeight={600} fontSize={13}>
                          {" "}
                          A habit tracker motivates you to continue.{" "}
                        </Text>{" "}
                        <Text mt={3} fontWeight={600} fontSize={13}>
                          A habit tracker provides immediate satisfaction.
                        </Text>
                      </AnimatedView>
                    </VStack>
                    <AnimatedView
                      style={{ width: "100%" }}
                      animation={`fade${
                        animationState === "In" ? "InUp" : "OutDown"
                      }`}
                      delay={1000 + 300 + 600}
                      duration={2000}
                    >
                      <Center mt={6}>
                        <Button
                          px="4"
                          minW="150"
                          variant="outline"
                          onPress={() => setIsCreateHabit(true)}
                        >
                          Create a Habit now
                        </Button>
                      </Center>
                    </AnimatedView>
                  </VStack>
                ) : (
                  <AddHabit />
                )}
                {!isCreateHabit ? (
                  <AnimatedView
                    style={{ width: "100%" }}
                    animation={`fade${
                      animationState === "In" ? "InUp" : "OutDown"
                    }`}
                    delay={1000 + 300 + 600}
                    duration={2000}
                  >
                    <Center mt={6}>
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={() => {
                          startTransition({
                            screen: "Help",
                            callback: () => {
                              setCurrentStep(SecondLoginStepsType.help);
                            },
                          });
                        }}
                      >
                        <ArrowIcon size="4xl" />
                        <Text textAlign="center" mt={3}>
                          Skip
                        </Text>
                      </TouchableOpacity>
                    </Center>
                  </AnimatedView>
                ) : null}
              </View>
            </VStack>
          </Box>
        </Container>
      </Animated.View>
    </>
  );
}
