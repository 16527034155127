import { IQuestionResponse } from "api/src/wellbeing/interface/IQuestionResponse";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useWellbeingQuestions() {
  return useQuery<IQuestionResponse[]>(
    ["wellbeing", "questions"],
    async () => {
      const response = await api.get<IQuestionResponse[]>(
        "wellbeing/questions",
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could fetch wellbeing questions");
    },
    {
      keepPreviousData: true,
    }
  );
}
