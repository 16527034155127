import { AlertDialog as NbAlertDialog, HStack } from "native-base";
import React, { useRef } from "react";

import { Button } from "./Button";

interface IAlertDialogProps {
  headerText: string;
  bodyText: string;
  buttonText: string;
  buttonColor?: string;
  buttonAction: () => void;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  isOpen: boolean;
  onClose: () => void;
  showFooter?: boolean;
  textAlign?: "start" | "left" | "right" | "center" | "justify" | undefined;
  isConfirmButtonLoading?: boolean;
  isShowCloseButton?: boolean;
}

export function AlertDialog({
  headerText,
  bodyText,
  buttonText,
  buttonColor = "primary.400",
  buttonAction,
  secondaryButtonText,
  secondaryButtonAction,
  isOpen,
  onClose,
  showFooter = true,
  textAlign = undefined,
  isConfirmButtonLoading = false,
  isShowCloseButton = true,
}: IAlertDialogProps) {
  const cancelRef = useRef<typeof Button>(null);

  return (
    <NbAlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <NbAlertDialog.Content _dark={{ bg: "dark.100" }}>
        {isShowCloseButton ? <NbAlertDialog.CloseButton /> : null}
        <NbAlertDialog.Header textAlign={textAlign} _dark={{ bg: "dark.100" }}>
          {headerText}
        </NbAlertDialog.Header>
        <NbAlertDialog.Body textAlign={textAlign} _dark={{ bg: "dark.100" }}>
          {bodyText}
        </NbAlertDialog.Body>
        {showFooter ? (
          <NbAlertDialog.Footer _dark={{ bg: "dark.100" }}>
            <HStack flexBasis="100%" justifyContent="space-between">
              <Button variant="outline" ref={cancelRef} onPress={onClose}>
                Cancel
              </Button>
              <HStack space={[2, 0]}>
                {secondaryButtonText && (
                  <Button
                    onPress={secondaryButtonAction}
                    bg={buttonColor}
                    px={5}
                  >
                    {secondaryButtonText}
                  </Button>
                )}
                <Button
                  isLoading={isConfirmButtonLoading}
                  onPress={buttonAction}
                  bg={buttonColor}
                  px={5}
                >
                  {buttonText}
                </Button>
              </HStack>
            </HStack>
          </NbAlertDialog.Footer>
        ) : null}
      </NbAlertDialog.Content>
    </NbAlertDialog>
  );
}
