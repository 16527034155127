import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function ChannelsIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 30 34" {...props}>
      <Svg width={32} height={32} fill="none" stroke={color}>
        <Path
          d="m4 22 12 7 12-7"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="m4 16 12 7 12-7"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="m4 10 12 7 12-7-12-7-12 7Z"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
