import dayjs from "dayjs";
import {
  Box,
  Divider,
  Heading,
  HStack,
  Modal,
  Pressable,
  Text,
  View,
  VStack,
} from "native-base";
import React, { useContext, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { Button } from "../../components/elements/Button";
import { Switch } from "../../components/elements/Switch";
import { Input } from "../../components/form/Input";
import { HabitReminders } from "../../components/HabitReminders";
import { ArrowLine } from "../../components/icons/ArrowLineIcon";
import { CircleTickIcon } from "../../components/icons/CircleTickIcon";
import { SecondLoginContext } from "../../context/SecondLoginContext";
import { TransitionContext } from "../../context/TransitionContext";
import { SecondLoginStepsType } from "../../enum/second-login-steps";
import { useHabitAdd } from "../../hooks/useHabitAdd";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { Body } from "../../layout/Body";
import { HabitReminderType, ReminderType } from "../../type/HabitReminderTypes";
import { isWeb } from "../../util/platform";

export function AddHabit() {
  const toast = useToast();
  const { mutate: addHabit } = useHabitAdd();

  const defaultValues: FieldValues = {
    name: "",
    timer1: "",
  };
  const [daysToTrack, setDaysToTrack] = useState<number>(5);

  const { control, handleSubmit, reset } = useForm<HabitReminderType>({
    defaultValues,
  });
  const [isVisible, setModalVisible] = useState<boolean>(false);
  const [hasReminder, setHasReminder] = useState<boolean>(false);
  const { setCurrentStep } = useContext(SecondLoginContext);
  const { startTransition } = useContext(TransitionContext);

  function onSubmit(data: HabitReminderType) {
    const { name, reminders } = data;
    const [firstReminder = { time: "" }, ...otherReminders]: ReminderType[] =
      reminders || [];

    return addHabit(
      {
        name: name || "",
        daysToStreak: daysToTrack,
        reminder: hasReminder ? firstReminder?.time : "",
        startDate: dayjs().toISOString(),
        endDate: dayjs().add(daysToTrack, "day").toISOString(),
        timeZoneOffset: dayjs().format("Z"),
        otherReminders: otherReminders.map(
          (reminder: ReminderType) => reminder.time
        ),
      },

      {
        onSuccess: () => {
          reset();
          setModalVisible(true);
        },
        onError: () => {
          toast({
            title: "Please enter habit.",
            status: ToastStatus.warning,
          });
        },
      }
    );
  }

  return (
    <View>
      <Modal
        isOpen={isVisible}
        onClose={() => setModalVisible(false)}
        size="xl"
      >
        <Modal.Content>
          <Modal.Header textAlign="center" alignItems="center">
            Habit Created
          </Modal.Header>
          <Modal.Body alignItems="center" pb="20">
            <CircleTickIcon />
            <VStack mt="3" space={6} px={2} mx={10} alignItems="center">
              <Text>Succesfully created new habit</Text>
              <Divider alignSelf="center" mb={5} mt={1} />
              <Button
                my="13"
                w="40"
                alignSelf="center"
                onPress={() => {
                  setModalVisible(false);
                  startTransition({
                    screen: "Help",
                    callback: () => {
                      setCurrentStep(SecondLoginStepsType.help);
                    },
                  });
                }}
              >
                Close
              </Button>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Body _dark={{ bg: "dark.200" }}>
        <VStack pt="10" pb="16">
          <HStack justifyContent="center" alignItems="center">
            <Heading fontSize={[20, 24]} fontWeight="600">
              Create a New Habit
            </Heading>
          </HStack>
          <VStack
            space="2"
            bg="secondary.50"
            padding="2"
            mt="10"
            rounded="xl"
            _dark={{ bg: "dark.300" }}
          >
            <Box>
              <Input
                maxLength={30}
                py="4"
                rounded="xl"
                background="white"
                fontSize={14}
                variant="auth"
                name="name"
                control={control}
                placeholderTextColor="secondary.400"
                placeholder="Name of your new habit"
                _dark={{ bg: "dark.200" }}
              />
            </Box>
            <HStack
              bg="white"
              py="4"
              rounded="xl"
              px="3"
              justifyContent="space-between"
              alignItems="center"
              _dark={{ bg: "dark.200" }}
            >
              <Text fontSize={14} fontWeight="400">
                Days to track
              </Text>
              <HStack space="3">
                {[5, 10, 30].map((value, index) => {
                  const isActive = value === daysToTrack;
                  return (
                    <Pressable
                      onPress={() => setDaysToTrack(value)}
                      key={index}
                    >
                      <VStack alignItems="center" justifyContent="center">
                        <Text fontSize="15">{value}</Text>
                        <Box
                          bg={isActive ? "primary.400" : "secondary.50"}
                          h="2"
                          w="12"
                          rounded="xl"
                        />
                      </VStack>
                    </Pressable>
                  );
                })}
              </HStack>
            </HStack>
            {!isWeb() ? (
              <HStack
                bg="white"
                py="4"
                rounded="xl"
                px="3"
                justifyContent="space-between"
                _dark={{ bg: "dark.200" }}
              >
                <Text
                  fontSize={14}
                  fontWeight="400"
                  color={hasReminder ? "secondary.850" : "secondary.450"}
                  _dark={{
                    color: hasReminder ? "secondary.400" : "secondary.650",
                  }}
                >
                  Set reminder
                </Text>
                <Switch
                  size="sm"
                  isChecked={hasReminder}
                  onChange={() => setHasReminder(!hasReminder)}
                />
              </HStack>
            ) : null}
            {hasReminder ? (
              <Box>
                <HabitReminders
                  backgroundColor="white"
                  name="timer1"
                  control={control}
                  mode="time"
                  _dark={{ bg: "dark.300" }}
                  reminders={[]}
                />
              </Box>
            ) : null}
            <Button
              my="13px"
              padding="2px"
              w="120px"
              alignSelf="center"
              onPress={handleSubmit(onSubmit)}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <ArrowLine />
                <Text color="white">Next</Text>
              </View>
            </Button>
          </VStack>
        </VStack>
      </Body>
    </View>
  );
}
