import { Linking } from "react-native";

import { isiOS } from "./platform";

/**
 * Opens the default SMS app with the specified phone number and message.
 * @param phoneNumber The phone number to send the message to.
 * @param message The message to send. This is optional and will be empty by default.
 */
export function openMessageApp(phoneNumber: string, message?: string): void {
  const separators = isiOS() ? "&" : "?";
  Linking.openURL(`sms:${phoneNumber}${separators}body=${message || ""}`);
}

/**
 * Opens the default phone app with the specified phone number.
 * @param phoneNumber The phone number to dial.
 */
export function openPhoneApp(phoneNumber: string): void {
  Linking.openURL(`tel:${phoneNumber}`);
}

/**
 * Opens the specified URL in the default web browser.
 * @param url The URL to open.
 */
export function openUrl(url: string): void {
  Linking.openURL(url);
}
