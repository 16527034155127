import { OrderEntity } from "api/src/subscription/entity/order.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrder() {
  return useQuery<OrderEntity>(
    ["order"],
    async () => {
      const response = await api.get<OrderEntity>("subscriptions/order");
      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Could not get order");
    },
    {
      keepPreviousData: true,
      retry: false,
    }
  );
}
