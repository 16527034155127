import { Heading } from "native-base";
import React, { ReactNode } from "react";

interface IMyPageListHeaderProps {
  children: ReactNode;
}

export function MyPageListHeader({ children }: IMyPageListHeaderProps) {
  return (
    <Heading fontSize={[16, 16]} mb={[0, 2]} fontWeight={500}>
      {children}
    </Heading>
  );
}
