import { useNavigation } from "@react-navigation/core";
import { CountryCode } from "api/src/users/types/countries";
import dayjs from "dayjs";
import { VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { AuthButton } from "../../components/auth/AuthButton";
import { AuthCard } from "../../components/auth/AuthCard";
import { AuthContent } from "../../components/AuthContent";
import { CountryInput } from "../../components/form/CountryInput";
import { DatePicker } from "../../components/form/DatePicker";
import { DropdownInput } from "../../components/form/DropdownInput";
import { GENDERS } from "../../constants/genders";
import { useEditUser } from "../../hooks/useEditUser";
import { useFormErrors } from "../../hooks/useFormErrors";
import { useMe } from "../../hooks/useMe";

export function CompleteRegistrationScreen() {
  const { data: user } = useMe();

  const [countryCode, setCountryCode] = useState<CountryCode>(
    user?.country as CountryCode
  );
  const { mutate: editUser, isLoading } = useEditUser();
  const { reset } = useNavigation();

  useEffect(() => {
    if (user) {
      setCountryCode(user.country);
    }
  }, [user]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const { setErrors } = useFormErrors(setError);

  function handleConfirm() {
    reset({
      index: 0,
      routes: [{ name: "Main", params: { tab: "home" } }],
    });
  }

  function onSubmit(data: Record<string, string>) {
    editUser(
      {
        ...data,
        country: countryCode,
      },
      {
        onSuccess: () => {
          handleConfirm();
        },
        onError: (data) => {
          // @ts-ignore
          setErrors(data.errors ?? data ?? {});
        },
      }
    );
  }

  return (
    <AuthContent>
      <AuthCard label="Complete Registration">
        <VStack space={2}>
          <DatePicker
            name="dob"
            placeholder="Date of birth"
            mode="date"
            endYear={parseInt(dayjs().format("YYYY")) - 16}
            control={control}
            errors={errors}
          />

          <DropdownInput
            name="gender"
            placeholder="Gender"
            variant="auth"
            control={control}
            items={GENDERS}
          />
          <CountryInput
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
          <AuthButton onPress={handleSubmit(onSubmit)} isLoading={isLoading}>
            Register
          </AuthButton>
        </VStack>
      </AuthCard>
    </AuthContent>
  );
}
