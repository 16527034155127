import { IOrganisationEngagement } from "api/src/organisations/interfaces/IOrganisationEngagement";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationEngagement() {
  return useQuery<IOrganisationEngagement[]>(
    ["organisation", "engagement"],
    async () => {
      const response = await api.get<IOrganisationEngagement[]>(
        "organisations/engagement"
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get engagement");
    },
    {
      keepPreviousData: true,
    }
  );
}
