import { AlertLevel } from "api/src/signposting/enum/AlertLevel";
import { Box, Text } from "native-base";
import React from "react";

export function HighAlertBanner({ alertLevel }: { alertLevel: AlertLevel }) {
  return (
    <Box
      p="5"
      pt="10"
      _web={{ pt: "5" }}
      bg="pink.100"
      _dark={{ bg: "dark.200" }}
    >
      <Text>
        Miindset is currently in
        <Text color="primary.400">
          {alertLevel === AlertLevel.high ? " high" : " medium"} contact mode
        </Text>{" "}
        and will display more specific information in your feed as well as
        increase the amount of contact and notifications you get. Complete the
        Wellbeing Checker if you are feeling different.
      </Text>
    </Box>
  );
}
