import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { JournalTitleEntry } from "api/src/journal/constants/journal-title-entries";
import { FiveMinuteQuestion } from "api/src/journal/enum/FiveMinuteQuestion";
import { JournalEntryType } from "api/src/journal/enum/JournalEntryType";
import { Text, HStack, VStack } from "native-base";
import React, { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { Button } from "../../components/elements/Button";
import { SaveFileIcon } from "../../components/icons/Icons";
import { JournalCurrentDay } from "../../components/journal/JournalCurrentDay";
import { JournalEntryContainer } from "../../components/journal/JournalEntryContainer";
import { JournalEntryInput } from "../../components/journal/JournalEntryInput";
import { JournalEntryInputLabel } from "../../components/journal/JournalEntryInputLabel";
import { Loading } from "../../components/Loading";
import { useJournalEntry } from "../../hooks/useJournalEntry";
import { useJournalSubmit } from "../../hooks/useJournalSubmit";
import { ToastStatus, useToast } from "../../hooks/useToast";
import { RootStackParamList } from "../../navigation/RootStackParamList";

export function AddFiveMinuteEntryScreen() {
  const toast = useToast();
  const { navigate } = useNavigation();

  const {
    params: { journalId },
  } = useRoute<RouteProp<RootStackParamList, "AddFiveMinuteEntry">>();

  const { mutate: submitFiveMinuteEntry, isLoading: isJournalSubmitLoading } =
    useJournalSubmit(journalId);
  const { data: journal, isLoading } = useJournalEntry(journalId);

  const defaultValues: FieldValues = {
    couldHaveDone: journal?.fiveMinuteData.couldHaveDone,
    dailyAffirmation: journal?.fiveMinuteData.dailyAffirmation,
    gratefulFor: journal?.fiveMinuteData.gratefulFor,
    plansToMakeItGreat: journal?.fiveMinuteData.plansToMakeItGreat,
    proudOfMyself: journal?.fiveMinuteData.proudOfMyself,
    thingOne: journal?.fiveMinuteData.amazingThingsToday[0],
    thingTwo: journal?.fiveMinuteData.amazingThingsToday[1],
    thingThree: journal?.fiveMinuteData.amazingThingsToday[2],
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: journalId ? defaultValues : undefined,
  });

  function onSubmit(data: Record<string, string>) {
    const {
      couldHaveDone,
      dailyAffirmation,
      gratefulFor,
      plansToMakeItGreat,
      proudOfMyself,
      thingOne,
      thingTwo,
      thingThree,
    } = data;
    return submitFiveMinuteEntry(
      {
        type: JournalEntryType.fiveMinute,
        title: JournalTitleEntry.fiveMinute,
        fiveMinute: {
          couldHaveDone,
          dailyAffirmation,
          gratefulFor,
          plansToMakeItGreat,
          proudOfMyself,
          amazingThingsToday: [thingOne, thingTwo, thingThree],
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${
              !journalId
                ? "New 5 Minute Journal created"
                : "5 Minute Journal edited"
            }`,
            status: ToastStatus.success,
          });
          navigate("MyJournal");
        },
        onError: () => {
          toast({
            title: `Couldn't ${
              !journalId ? "created" : "edit"
            } 5 Minute Journal`,
            status: ToastStatus.warning,
          });
        },
      }
    );
  }

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [journal]);

  if (isLoading) {
    return <Loading showHeader={true} />;
  }
  return (
    <JournalEntryContainer>
      <HStack justifyContent="space-between" alignItems="center">
        <Text fontSize={18} fontWeight="500">
          {JournalTitleEntry.fiveMinute}
        </Text>
        <JournalCurrentDay />
      </HStack>
      <VStack space={2} mt="2">
        <JournalEntryInput
          isTextarea={true}
          control={control}
          name={FiveMinuteQuestion.gratefulFor}
          placeholder="I am grateful for..."
        />
        <JournalEntryInput
          isTextarea={true}
          control={control}
          name={FiveMinuteQuestion.plansToMakeItGreat}
          placeholder="What will I do to make it great?"
        />
        <JournalEntryInput
          isTextarea={true}
          control={control}
          name={FiveMinuteQuestion.dailyAffirmation}
          placeholder="Daily affirmation. I am..."
        />
        <VStack>
          <JournalEntryInputLabel
            title="Here are 3 amazing things that happened today."
            subtitle="These can be anything that improved the way you felt today"
            mb={2}
          />
          <VStack space="2">
            <JournalEntryInput
              control={control}
              name="thingOne"
              placeholder="1."
            />
            <JournalEntryInput
              control={control}
              name="thingTwo"
              placeholder="2."
            />
            <JournalEntryInput
              control={control}
              name="thingThree"
              placeholder="3."
            />
          </VStack>
        </VStack>
        <JournalEntryInput
          isTextarea={true}
          control={control}
          name={FiveMinuteQuestion.proudOfMyself}
          placeholder="Today I am proud of myself because"
        />
        <JournalEntryInput
          isTextarea={true}
          control={control}
          name={FiveMinuteQuestion.couldHaveDone}
          placeholder="What could I have done to make the day even better?"
        />
      </VStack>
      <HStack pt="10" justifyContent="center" space="10">
        <Button
          onPress={handleSubmit(onSubmit)}
          isLoading={isJournalSubmitLoading}
        >
          <HStack alignItems="center" space="1">
            <SaveFileIcon color="white" size={["sm", "md"]} />
            <Text color="white" fontSize={[15, 18]} fontWeight="300">
              {!journalId ? "Save" : "Edit"}
            </Text>
          </HStack>
        </Button>
      </HStack>
    </JournalEntryContainer>
  );
}
