import { ContentEntity } from "api/src/content/entity/content.entity";
import { useQuery } from "react-query";

import { ChannelFilterOption } from "../components/ChannelFilter";
import { ContentFilterOption } from "../components/ContentFilter";
import { api } from "../util/api";

interface ISavedContentRequest {
  type: ContentFilterOption;
  channel: ChannelFilterOption;
}

export function useSavedContent({ type, channel }: ISavedContentRequest) {
  return useQuery<ContentEntity[]>(
    ["saved-content", type, channel],
    async () => {
      const response = await api.get<ContentEntity[]>("content/saved", {
        params: {
          type: type === "all" ? undefined : type,
          channel: channel === "all" ? undefined : channel,
        },
      });

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get saved content");
    },
    {
      keepPreviousData: true,
    }
  );
}
