import { useNavigation } from "@react-navigation/native";
import { ContentStatus } from "api/src/content/enum/ContentStatus";
import { ContentType } from "api/src/content/enum/ContentType";
import {
  Button,
  Heading,
  HStack,
  Text,
  VStack,
  Box,
  Input,
  Select,
  Pressable,
  Spinner,
  Tooltip,
} from "native-base";
import React, { useEffect, useState } from "react";

import { Container } from "../../components/Container";
import { AlertDialog } from "../../components/elements/AlertDialog";
import { Icon, IconLibrary } from "../../components/elements/Icon";
import { Empty } from "../../components/Empty";
import { DeleteIcon, SearchIcon } from "../../components/icons/Icons";
import { Loading } from "../../components/Loading";
import { ReviewableContent } from "../../components/organisations/ReviewableContent";
import { PUBLISH_INFO } from "../../constants/organisation-content-edit-screen";
import { useDeleteBulkOrganisationContent } from "../../hooks/useDeleteBulkOrganisationContent";
import { useOrganisationContents } from "../../hooks/useOrganisationContents";
import { Header } from "../../layout/Header";
import { toTitleCase } from "../../util/string";

export function OrganisationContentListScreen() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [draftFilter, setDraftFilter] = useState<ContentType>();
  const [publishedFilter, setPublishedFilter] = useState<ContentType>();
  const [contentDeleteType, setContentDeleteType] = useState<
    "drafts" | "published"
  >();
  const { navigate } = useNavigation();

  const {
    data: drafts,
    isLoading: isLoadingDrafts,
    isFetching: isFetchingDrafts,
    fetchNextPage: fetchNextPageDrafts,
    hasNextPage: hasNextPageNextPageDrafts,
    isFetchingNextPage: isFetchingNextPageDrafts,
    refetch: refetchDrafts,
  } = useOrganisationContents({
    limit: 5,
    status: ContentStatus.draft,
    title,
    filter: draftFilter,
  });

  const {
    data: content,
    isLoading: isLoadingContent,
    isFetching: isFetchingContent,
    fetchNextPage: fetchNextPageContent,
    hasNextPage: hasNextPageNextPageContent,
    isFetchingNextPage: isFetchingNextPageContent,
    refetch: refetchPublished,
  } = useOrganisationContents({ title, filter: publishedFilter });

  const { mutate: deleteDraftContent, isLoading: isDeletingDrafts } =
    useDeleteBulkOrganisationContent();

  const { mutate: deletePublishContent, isLoading: isDeletingPublishContent } =
    useDeleteBulkOrganisationContent();

  function handleAddOrganisationContent() {
    navigate("EditOrganisationContent", { id: "", type: "organisationAdmin" });
  }

  const isDraftContentAvailable = drafts?.pages[0].meta.totalItems !== 0;
  const isPublishContentAvailable = content?.pages[0].meta.totalItems !== 0;

  useEffect(() => {
    if (title.length > 2 || title.length === 0) {
      refetchPublished();
      refetchDrafts();
    }
  }, [title]);

  useEffect(() => {
    refetchDrafts();
  }, [draftFilter]);

  useEffect(() => {
    refetchPublished();
  }, [publishedFilter]);

  function Filter({
    value,
    items,
    isLoading = false,
    onValueChange,
  }: {
    value: string;
    items: string[];
    isLoading?: boolean;
    onValueChange: (value: string) => void;
  }) {
    return (
      <HStack alignItems="center">
        <Select
          mx="2"
          maxW={80}
          selectedValue={value}
          placeholder="Filter"
          color="secondary.800"
          borderWidth={0}
          dropdownIcon={<></>}
          onValueChange={onValueChange}
        >
          {items.map((e) => (
            <Select.Item label={toTitleCase(e)} value={e} />
          ))}
          <Select.Item label="Notification" value="notification" />
        </Select>

        {isLoading ? (
          <Spinner />
        ) : !value ? (
          <Icon
            icon="chevron-down"
            color="primary.400"
            iconLibrary={IconLibrary.ionicons}
            size={4}
          />
        ) : (
          <Pressable onPress={() => onValueChange("")}>
            <Icon icon="close" iconLibrary={IconLibrary.ionicons} size={4} />
          </Pressable>
        )}
      </HStack>
    );
  }

  return (
    <>
      <Header hasBack={false} />
      <Container size="container.xl" h="auto" minH="94vh" pt={10}>
        <Box h="100%" w="100%" px="10">
          <HStack alignItems="center" justifyContent="space-between" my="5">
            <HStack alignItems="center" space="5">
              <Heading fontSize={18} fontWeight={600} textAlign="center">
                Articles & Notifications
              </Heading>
              <Tooltip alignItems="center" label={PUBLISH_INFO} w={280}>
                <Button
                  variant="solid"
                  h={8}
                  onPress={handleAddOrganisationContent}
                >
                  <HStack space={2}>
                    <Icon
                      icon="plus"
                      size={4}
                      color="white"
                      iconLibrary={IconLibrary.feather}
                    />
                    <Text color="white" fontSize="xs">
                      Add Content
                    </Text>
                  </HStack>
                </Button>
              </Tooltip>
            </HStack>

            <HStack alignItems="center" space="5">
              <Input
                variant="rounded"
                placeholder="Find content"
                pl="3"
                value={title}
                onChangeText={(text) => {
                  setTitle(text);
                }}
                InputRightElement={
                  <Box pr="3">
                    {(isFetchingContent || isFetchingDrafts) &&
                    title.length > 2 ? (
                      <Spinner />
                    ) : (
                      <SearchIcon size="xs" color="secondary.650" />
                    )}
                  </Box>
                }
              />
              {isDraftContentAvailable && (
                <Button
                  variant="outline"
                  borderWidth={2}
                  isLoading={isDeletingDrafts}
                  h={8}
                  onPress={() => {
                    setIsOpen(true);
                    setContentDeleteType("drafts");
                  }}
                >
                  <HStack space={2}>
                    <DeleteIcon size={4} color="secondary.800" />
                    <Text fontSize="xs">Delete All</Text>
                  </HStack>
                </Button>
              )}
            </HStack>
          </HStack>
          <VStack space={5} w="100%">
            <VStack space={2}>
              <Text>
                <Text color="primary.400">Drafts</Text>
                <Filter
                  isLoading={isFetchingDrafts && draftFilter !== undefined}
                  value={draftFilter as string}
                  items={Object.keys(ContentType)}
                  onValueChange={(value) => {
                    setDraftFilter(value as ContentType);
                  }}
                />
              </Text>

              <Empty
                isVisible={drafts?.pages[0].meta.totalItems === 0}
                title="No drafts available"
                height="40"
              />

              {isLoadingDrafts ? (
                <Loading
                  isLoading={isLoadingDrafts}
                  containerHeight="20vh"
                  size={100}
                />
              ) : (
                drafts?.pages.map((page) =>
                  page.items.map((content) => (
                    <ReviewableContent item={content} />
                  ))
                )
              )}
              {hasNextPageNextPageDrafts && (
                <Button
                  width={120}
                  variant="ghost"
                  alignSelf="flex-end"
                  onPress={() => fetchNextPageDrafts()}
                  isLoading={isFetchingNextPageDrafts}
                  isDisabled={!hasNextPageNextPageDrafts}
                >
                  Load More
                </Button>
              )}
            </VStack>

            <VStack space={2}>
              <HStack justifyContent="space-between" alignItems="center">
                <HStack alignItems="center">
                  <Text color="primary.400">Posted</Text>
                  <Filter
                    isLoading={
                      isFetchingContent && publishedFilter !== undefined
                    }
                    value={publishedFilter as string}
                    items={Object.keys(ContentType)}
                    onValueChange={(value) => {
                      setPublishedFilter(value as ContentType);
                    }}
                  />
                </HStack>

                {isPublishContentAvailable && (
                  <Button
                    variant="outline"
                    borderWidth={2}
                    isLoading={isDeletingPublishContent}
                    h={8}
                    onPress={() => {
                      setIsOpen(true);
                      setContentDeleteType("published");
                    }}
                  >
                    <HStack space={2}>
                      <DeleteIcon size={4} color="secondary.800" />
                      <Text fontSize="xs">Delete All</Text>
                    </HStack>
                  </Button>
                )}
              </HStack>
              {isLoadingContent ? (
                <Loading
                  containerHeight="30vh"
                  isLoading={isLoadingContent}
                  size={100}
                />
              ) : (
                content?.pages.map((page) =>
                  page.items.map((content) => (
                    <ReviewableContent item={content} />
                  ))
                )
              )}
              <Empty
                isVisible={content?.pages[0].meta.totalItems === 0}
                title="No published content available"
                height="40vh"
              />

              {hasNextPageNextPageContent && (
                <Button
                  width={120}
                  variant="ghost"
                  alignSelf="flex-end"
                  onPress={() => fetchNextPageContent()}
                  isLoading={isFetchingNextPageContent}
                  isDisabled={!hasNextPageNextPageContent}
                >
                  Load More
                </Button>
              )}
            </VStack>
          </VStack>
        </Box>
      </Container>
      <AlertDialog
        headerText="Delete Content"
        bodyText="This will remove content permanently. Are you sure you?"
        buttonColor="red.400"
        buttonText="Confirm"
        isOpen={isOpen}
        buttonAction={() => {
          setIsOpen(false);
          if (contentDeleteType === "drafts") {
            deleteDraftContent(ContentStatus.draft);
          } else if (contentDeleteType === "published") {
            deletePublishContent(ContentStatus.live);
          }
        }}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
}
