import { RecommendedContentDto } from "api/src/content/dto/recommended-content.dto";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useRecommendedContent() {
  return useQuery<RecommendedContentDto>(
    ["content", "recommended"],
    async () => {
      const response = await api.get<RecommendedContentDto>(
        "content/recommended",
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return Promise.reject("Could not get recommended content");
    },
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );
}
