import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { EditContentScreen } from "../screens/admin/EditContentScreen.web";
import { ReviewListScreen } from "../screens/admin/ReviewListScreen.web";
import { useBackgroundColor } from "../util/theme-helper";

const ReviewContent = createStackNavigator();
export function ReviewContentNavigator() {
  const { backgroundColorDarker } = useBackgroundColor();
  return (
    <ReviewContent.Navigator
      initialRouteName="ReviewContentsList"
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor: backgroundColorDarker },
      }}
    >
      <ReviewContent.Screen
        name="ReviewContentsList"
        component={ReviewListScreen}
        options={{ title: "Review Content - Miindset" }}
      />
      <ReviewContent.Screen
        name="ReviewContent"
        component={EditContentScreen}
        options={{ title: "Add/Edit Content - Miindset" }}
      />
    </ReviewContent.Navigator>
  );
}
