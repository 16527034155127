import { RouteProp, useRoute } from "@react-navigation/native";
import React, { useEffect } from "react";

import { useLoginRedirect } from "../../hooks/useLoginRedirect";
import { RootStackParamList } from "../../navigation/RootStackParamList";

export function UnidaysWebRedirectScreen() {
  const onLoginRedirect = useLoginRedirect();

  const {
    params: { loginToken },
  } = useRoute<RouteProp<RootStackParamList, "UnidaysWebRedirect">>();

  useEffect(() => {
    (async () => {
      (await onLoginRedirect).navigate(loginToken);
    })();
  }, [loginToken]);

  return <></>;
}
