import { HStack, Text } from "native-base";
import React from "react";

import { Button } from "../elements/Button";

export function AffirmationOption({
  text,
  onPress,
}: {
  text?: string;
  onPress?: () => void;
}) {
  return (
    <HStack justifyContent="center" space="2" mt="2">
      <Button variant="moodInverted" onPress={onPress} py={2.5} minWidth="100%">
        <Text fontSize={15}> {text}</Text>
      </Button>
    </HStack>
  );
}
