import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Popover, Text, Button, Box, useToken } from "native-base";
import React, { useState } from "react";
import { Range, DateRange as DT } from "react-date-range";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useMe } from "../../hooks/useMe";

interface IDateRangeProps {
  isLoading?: boolean;
  isSelect?: boolean;
  onDateRangeSelected: (startDate: string, endDate: string) => void;
}

export function DateRange({
  isLoading = false,
  isSelect = false,
  onDateRangeSelected,
}: IDateRangeProps) {
  dayjs.extend(utc);
  const { data: user } = useMe();
  const dateFormate = user?.country === "US" ? "MMM DD YYYY" : "DD MMM YYYY";
  const [dateRange, setDateRange] = useState<[Range]>([
    {
      key: "selection",
    },
  ]);
  const [primaryColor, secondaryColor] = useToken("colors", [
    "primary.400",
    "secondary.400",
  ]);
  return (
    <Box alignItems="center" mx="2">
      <Popover
        placement="bottom"
        trigger={(triggerProps) => (
          <Button
            {...triggerProps}
            h={7}
            borderColor={
              dateRange[0].startDate && dateRange[0].endDate
                ? primaryColor
                : secondaryColor
            }
            borderWidth={1}
            rounded="full"
            _pressed={{ opacity: 0.7 }}
            isLoading={isLoading}
            variant={isSelect ? "solid" : "outline"}
            _dark={{ bg: "dark.200" }}
          >
            <Text
              fontSize="12"
              color={
                dateRange[0].startDate && dateRange[0].endDate
                  ? primaryColor
                  : secondaryColor
              }
              _dark={{ color: "secondary.200" }}
            >
              {dateRange[0].startDate
                ? dayjs(dateRange[0].startDate?.toString()).format(dateFormate)
                : "Start Date"}
              {" - "}
              {dateRange[0].endDate
                ? dayjs(dateRange[0].endDate?.toString()).format(dateFormate)
                : "End Date"}
            </Text>
          </Button>
        )}
      >
        <Popover.Content _dark={{ bg: "dark.100" }}>
          <Popover.Arrow color="primary.400" />
          <Popover.Body _dark={{ bg: "dark.100" }}>
            <DT
              className="DateRange"
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              maxDate={new Date()}
              rangeColors={[primaryColor]}
            />
          </Popover.Body>
          <Popover.Footer justifyContent="flex-end" _dark={{ bg: "dark.100" }}>
            <Button
              h="8"
              onPress={() => {
                onDateRangeSelected(
                  dayjs(dateRange[0].startDate).utc().format(),
                  dayjs(dateRange[0].endDate).utc().format()
                );
              }}
            >
              Select
            </Button>
          </Popover.Footer>
        </Popover.Content>
      </Popover>
    </Box>
  );
}
