import { Box, HStack, Pressable, Text } from "native-base";
import React from "react";

interface IQuestionProps {
  label: string;
  isDisabled?: boolean;
  onPress: () => void;
}
export function Question({ isDisabled, label, onPress }: IQuestionProps) {
  return (
    <Pressable
      disabled={isDisabled}
      _pressed={{ opacity: 0.7 }}
      maxW={400}
      w="100%"
      onPress={onPress}
    >
      <Box p={5} borderRadius={5} bg="gray.50" _dark={{ bg: "dark.200" }}>
        <HStack space={4}>
          <Text>{label}</Text>
        </HStack>
      </Box>
    </Pressable>
  );
}
