import { isEmpty } from "lodash";
import { VStack, HStack, Select } from "native-base";
import React from "react";

import { CircleProgressBar } from "../../../CircleProgressBar";
import { ArrowForwardIcon } from "../../../icons/Icons";

interface ILocationFilter {
  filterData: string[] | undefined;
  resilience: number;
  value: string;
  onChange: (value: string) => void;
}

export function LocationFilter({
  filterData,
  resilience,
  value,
  onChange,
}: ILocationFilter) {
  return (
    <VStack w={150} alignItems="center" space={2}>
      <HStack alignItems="center">
        <CircleProgressBar percentage={resilience} />
      </HStack>
      <HStack
        minWidth={125}
        borderColor="secondary.400"
        borderWidth="1"
        rounded="full"
        px={2}
        justifyContent="space-between"
        alignItems="center"
      >
        {filterData && !isEmpty(filterData) ? (
          <Select
            selectedValue={value}
            placeholder="Filter"
            maxW="125"
            borderColor="transparent"
            placeholderTextColor="secondary.650"
            backgroundColor="transparent"
            borderWidth={0}
            dropdownIcon={<></>}
            onValueChange={(selection) => onChange(selection)}
          >
            {[...filterData].map((value: string, index: number) => (
              <Select.Item key={index} pl={4} label={value} value={value} />
            ))}
          </Select>
        ) : null}
        <ArrowForwardIcon size={3} />
      </HStack>
    </VStack>
  );
}
