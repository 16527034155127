import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function CallIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="-4 -6 35 33" {...props}>
      <Svg width="26" height="25" viewBox="0 0 26 25" fill="none">
        <Path
          d="M24.3643 16.8015L18.5018 14.3015C18.1993 14.1659 17.8672 14.1097 17.5369 14.1381C17.2067 14.1665 16.8891 14.2787 16.6143 14.464L13.4768 16.5515C11.5646 15.6228 10.0164 14.0833 9.07676 12.1765L11.1518 9.00147C11.3358 8.72632 11.4482 8.40955 11.4788 8.07993C11.5093 7.75031 11.4571 7.41828 11.3268 7.11398L8.81426 1.25147C8.63894 0.855617 8.34208 0.525897 7.96673 0.310129C7.59138 0.0943609 7.15707 0.00376339 6.72676 0.0514747C5.03875 0.272605 3.48847 1.09919 2.36416 2.37755C1.23985 3.65591 0.618011 5.29905 0.614258 7.00147C0.614258 16.9265 8.68926 25.0015 18.6143 25.0015C20.3167 24.9977 21.9598 24.3759 23.2382 23.2516C24.5165 22.1273 25.3431 20.577 25.5643 18.889C25.612 18.4587 25.5214 18.0243 25.3056 17.649C25.0898 17.2737 24.7601 16.9768 24.3643 16.8015Z"
          fill="#DF3D8D"
        />
      </Svg>
    </Icon>
  );
}
