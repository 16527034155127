import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";

import { IWebScripts } from "./WebScripts";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    AWIN: unknown;
  }
}
export function WebScripts({ children }: IWebScripts) {
  const [hasAwin, setHasAwin] = useState<boolean>(false);
  const [checkIterations, setCheckIterations] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const awc = urlParams.get("awc");

      if (awc) {
        await AsyncStorage.setItem("awc", awc);
      }
    })();
  }, []);

  const checkAwin = useCallback(() => {
    if (!hasAwin) {
      setHasAwin(!!window.AWIN || checkIterations > 20);
    }

    setCheckIterations(checkIterations + 1);
  }, [hasAwin, setHasAwin, setCheckIterations, checkIterations]);

  useEffect(() => {
    if (hasAwin) {
      return;
    }
    const interval = setInterval(checkAwin, 100);
    return () => clearInterval(interval);
  }, [checkAwin]);

  return (
    <>
      <Helmet>
        <script src="https://www.dwin1.com/31754.js" type="text/javascript" />
      </Helmet>
      {hasAwin && children}
    </>
  );
}
