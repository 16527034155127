import AsyncStorage from "@react-native-async-storage/async-storage";
import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiErrorResponse } from "./IApiErrorResponse";
import { IApiResponse } from "./IApiResponse";

export interface IRegisterResponse {
  access_token: string;
  message: string;
}

type IRegisterRequest = Record<string, string>;

export function useRegister() {
  return useMutation<
    IApiResponse<IRegisterResponse>,
    IApiErrorResponse,
    IRegisterRequest
  >("auth", async ({ ...data }: IRegisterRequest) => {
    const response = await api.post<IApiResponse<IRegisterResponse>>(
      "users/register",
      data
    );

    AsyncStorage.setItem("email", `${data.email}`.toLowerCase());
    AsyncStorage.setItem("password", data.password);

    if (response.status !== 201) {
      throw response.data.errors;
    }

    return response.data;
  });
}
