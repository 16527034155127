import { FlatList as NbFlatList } from "native-base";
import { IFlatListProps } from "native-base/lib/typescript/components/basic/FlatList/types";
import React from "react";

export function FlatList({ ...props }: IFlatListProps) {
  return (
    <NbFlatList
      bounces={false}
      showsVerticalScrollIndicator={false}
      paddingX={[6, 6, "10%", "15%", "20%"]}
      {...props}
    />
  );
}
