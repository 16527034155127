import { HStack, Link, Menu, Text } from "native-base";
import React from "react";

const ORGANISATION_MENU = [
  { name: "Insights", screen: "OrganisationInsights" },
  { name: "Employee Management", screen: "OrganisationEmployees" },
  { name: "Content Management", screen: "OrganisationContent" },
  { name: "Settings", screen: "OrganisationSettings" },
];

export function OrganisationMenu({
  handleOrganisationMenu,
}: {
  handleOrganisationMenu: (screen: string) => void;
}) {
  return (
    <>
      {ORGANISATION_MENU.map(({ name, screen }, index) => (
        <Menu.Item
          p={0}
          bg="transparent"
          key={index}
          _dark={{
            _hover: {
              bg: "dark.200",
            },
            _focus: {
              bg: "dark.200",
            },
          }}
        >
          <Link
            p={2}
            w="100%"
            _hover={{ bg: "secondary.200" }}
            _dark={{
              _hover: {
                bg: "dark.200",
              },
            }}
            onPress={() => {
              handleOrganisationMenu(screen);
            }}
          >
            <HStack alignItems="center">
              <Text
                fontSize={14}
                fontWeight={500}
                color="secondary.750"
                _dark={{ color: "secondary.400" }}
              >
                {name}
              </Text>
            </HStack>
          </Link>
        </Menu.Item>
      ))}
    </>
  );
}
