import { ContentStatus } from "api/src/content/enum/ContentStatus";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

export function useDeleteBulkOrganisationContent() {
  const queryClient = useQueryClient();

  return useMutation(
    ["content", "delete-bulk-organisation-content"],
    async (status: ContentStatus) => {
      const response = await api.delete(
        `content/delete-bulk-organisation-content/${status}`
      );

      if (response?.status > 400) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("content");
      },
    }
  );
}
