import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React, { useEffect } from "react";

import { useActiveSubscription } from "../hooks/useActiveSubscription";
import { useMe } from "../hooks/useMe";
import { AuthInitializeScreen } from "../screens/auth/AuthInitializeScreen";
import { ForgotPasswordScreen } from "../screens/auth/ForgotPasswordScreen";
import { LoginScreen } from "../screens/auth/LoginScreen";
import { RegisterScreen } from "../screens/auth/RegisterScreen";
import { ResetPasswordScreen } from "../screens/auth/ResetPasswordScreen";
import { UnidaysScreen } from "../screens/auth/UnidaysScreen";
import { WelcomeScreen } from "../screens/auth/WelcomeScreen";
import { ConfirmEmailScreen } from "../screens/ConfirmEmailScreen";
import { SubscriptionScreen } from "../screens/Subscription";
import { WellbeingCheckerScreen } from "../screens/WellbeingCheckerScreen";
import { isWeb } from "../util/platform";
import { useBackgroundColor } from "../util/theme-helper";

import { FirstLoginNavigator } from "./FirstTimeLoginNavigator";
import { OnboardingNavigator } from "./OnboardingNavigator";
import { SecondLoginNavigator } from "./SecondLoginNavigator";

const Auth = createStackNavigator();
export function AuthNavigator() {
  const { navigate } = useNavigation();
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useActiveSubscription();

  const { data: user, error, isLoading: isMeLoading } = useMe();

  useEffect(() => {
    (async () => {
      if (isSubscriptionLoading || isMeLoading) {
        return;
      }
      const isLoggedIn =
        error !== 401 && (await AsyncStorage.getItem("access_token"));

      if (isWeb() && window.location.pathname.includes("main")) {
        return;
      }

      if (
        isWeb() &&
        isLoggedIn &&
        user?.hasOnboarded &&
        user?.firstLogin &&
        user?.loginCount === 2 &&
        !user?.secondLogin
      ) {
        navigate("Auth", { screen: "SecondLogin" });
        return;
      }

      if (
        isLoggedIn &&
        user?.hasOnboarded &&
        !user?.firstLogin &&
        user?.loginCount === 1
      ) {
        navigate("Auth", { screen: "FirstLogin" });
        return;
      }

      if (
        isWeb() &&
        isLoggedIn &&
        user?.hasOnboarded &&
        !subscription?.isFree
      ) {
        navigate("Main");
        return;
      }
      if (isWeb() && isLoggedIn && user?.hasOnboarded && subscription?.isFree) {
        navigate("SubscriptionAuth");
        return;
      }

      if (
        isWeb() &&
        isLoggedIn &&
        !user?.hasOnboarded &&
        !window.location.pathname.includes("onboarding")
      ) {
        navigate("Onboarding");
      }
    })();
  }, [user, error]);

  const { backgroundColor } = useBackgroundColor();

  return (
    <>
      <Auth.Navigator
        initialRouteName="AuthCheck"
        screenOptions={{
          headerShown: false,
          cardStyle: { backgroundColor },
        }}
      >
        <Auth.Screen name="AuthCheck" component={AuthInitializeScreen} />
        <Auth.Screen name="SubscriptionAuth" component={SubscriptionScreen} />
        <Auth.Screen name="Onboarding" component={OnboardingNavigator} />
        <Auth.Screen name="FirstLogin" component={FirstLoginNavigator} />
        <Auth.Screen name="SecondLogin" component={SecondLoginNavigator} />
        <Auth.Screen
          name="WellbeingChecker"
          component={WellbeingCheckerScreen}
          options={{ animationEnabled: false }}
        />
        <Auth.Screen
          name="Welcome"
          component={WelcomeScreen}
          options={{
            title: "Instant wellness - Miindset",
          }}
        />
        <Auth.Screen
          name="Login"
          component={LoginScreen}
          options={{ title: "Login - Miindset" }}
        />

        <Auth.Screen
          name="ForgotPassword"
          component={ForgotPasswordScreen}
          options={{ title: "Forgot Password" }}
        />
        <Auth.Screen
          name="ResetPassword"
          component={ResetPasswordScreen}
          options={{ title: "Reset Password" }}
        />

        <Auth.Screen
          name="Register"
          component={RegisterScreen}
          options={{ title: "Register - Miindset" }}
        />
        <Auth.Screen
          name="ConfirmEmail"
          component={ConfirmEmailScreen}
          options={{ title: "Confirm Email - Miindset" }}
        />
        <Auth.Screen
          name="Unidays"
          component={UnidaysScreen}
          options={{ title: "Unidays Sign In - Miindset" }}
        />
      </Auth.Navigator>
    </>
  );
}
