import { IOrganisationTab } from "../interfaces/IOrganisationTabs";

export const ORGANISATION_TABS: IOrganisationTab[] = [
  {
    title: "Overview",
    root: "insights",
    url: "Overview",
  },
  {
    title: "Indicators",
    root: "insights",
    url: "Indicators",
  },
  {
    title: "Engagement",
    root: "insights",
    url: "Engagement",
  },

  {
    title: "",
    root: "content-management",
    url: "ReviewContentsList",
  },
  {
    title: "",
    root: "settings",
    url: "Settings",
  },
  {
    title: "User List",
    root: "employee-management",
    url: "UserList",
  },
  {
    title: "Departments",
    root: "employee-management",
    url: "Departments",
  },
  {
    title: "",
    root: "notifications-management",
    url: "Notifications",
  },
];
