import AsyncStorage from "@react-native-async-storage/async-storage";
import { NotificationDto } from "api/src/users/dto/notification-token.dto";
import { useMutation, useQueryClient } from "react-query";

import { EXPO_PUSH_NOTIFICATION_TOKEN } from "../constants/storage";
import { api } from "../util/api";

import { useMe } from "./useMe";

export function useExpoPushNotificationTokenUpdate() {
  const queryClient = useQueryClient();
  const { data: user } = useMe();
  return useMutation<null, null, NotificationDto>(
    "users/expo-notification-token/add",
    async (data: NotificationDto) => {
      const response = await api.put("users/expo-notification-token/add", data);

      if (response.status === 200) {
        AsyncStorage.setItem(EXPO_PUSH_NOTIFICATION_TOKEN, data.token);
        return response.data;
      }

      return null;
    },
    {
      onSuccess: (newUser) => {
        queryClient.setQueryData("account", { ...user, newUser });
        queryClient.invalidateQueries("account");
      },
    }
  );
}
