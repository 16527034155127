import { IResilienceScoreAverage } from "api/src/organisations/interfaces/IResilienceScoresAverage";
import { VStack, HStack, Text, Heading } from "native-base";
import React from "react";

import { CircleProgressBar } from "../../../CircleProgressBar";

interface IAgeResilienceScore {
  ageResilienceScoreData: IResilienceScoreAverage | undefined;
}

export function AgeResilienceScore({
  ageResilienceScoreData,
}: IAgeResilienceScore) {
  return (
    <VStack alignItems="center" space={5}>
      <Heading fontSize={18} fontWeight={600} textAlign="center">
        Age Resilience Score
      </Heading>
      <HStack w="100%" space={10} justifyContent="center">
        <VStack space={2}>
          {ageResilienceScoreData &&
            Object.keys(ageResilienceScoreData)
              .slice(0, 3)
              .map((key: string) => (
                <HStack alignItems="center" key={key}>
                  <CircleProgressBar
                    percentage={Math.round(ageResilienceScoreData[key] * 100)}
                    isAgeResilience={true}
                    infoTextPlacement="top left"
                  />
                  <Text fontSize="xs">{key}</Text>
                </HStack>
              ))}
        </VStack>
        <VStack alignItems="center" space={2}>
          {ageResilienceScoreData &&
            Object.keys(ageResilienceScoreData)
              .slice(3)
              .map((key) => (
                <HStack
                  key={key}
                  alignItems="center"
                  justifyContent="flex-end"
                  w="100%"
                >
                  <Text fontSize="xs">{key}</Text>
                  <CircleProgressBar
                    percentage={Math.round(ageResilienceScoreData[key] * 100)}
                    isAgeResilience={true}
                  />
                </HStack>
              ))}
        </VStack>
      </HStack>
    </VStack>
  );
}
