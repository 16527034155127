import {
  View,
  Text,
  Heading,
  HStack,
  VStack,
  Link,
  Divider,
  Pressable,
} from "native-base";
import React from "react";
import { Linking } from "react-native";

import { ToastStatus, useToast } from "../../hooks/useToast";
import { isWeb } from "../../util/platform";
import { CallIcon } from "../icons/CallIcon";
import { MoneyBadgeIcon } from "../icons/MoneyBadgeIcon";
import { NetworkIcon } from "../icons/NetworkIcon";

export function FinancialSupport() {
  const toast = useToast();

  const handleContactClick = async (phoneNumber: string): Promise<void> => {
    const url = `tel:${phoneNumber}`;
    if (isWeb()) {
      return;
    }
    try {
      const supported = await Linking.canOpenURL(url);
      if (!supported) {
        toast({
          title: "Can't handle this contact",
          status: ToastStatus.warning,
        });
      } else {
        return Linking.openURL(url);
      }
    } catch (err) {
      toast({
        title: `An error occurred: ${err}`,
        status: ToastStatus.error,
      });
    }
  };

  return (
    <View mb={10} mt={50} alignItems="center">
      {!isWeb() ? <MoneyBadgeIcon /> : null}
      <Heading mt={5} mb={5} fontSize={28} fontWeight={600} textAlign="center">
        Financial Support
      </Heading>
      {isWeb() ? <MoneyBadgeIcon /> : null}
      <Heading
        mt={5}
        fontSize={20}
        color="primary.400"
        fontWeight={600}
        textAlign="center"
      >
        MoneyHelper
      </Heading>
      {isWeb() ? null : <Divider mt={5} />}
      <Text
        mt={5}
        w={isWeb() ? 500 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        If you need to speak to someone about money related problems don't
        hesitate to reach out for support.
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 500 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        You can use the MoneyHelper website to find a debt adviser near you and
        again advice on other debt matters.
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 500 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Webchat is available at the website, Monday to Friday, 8am to 6pm
        Saturday, 8am to 3pm
      </Text>
      <Text
        mt={5}
        w={isWeb() ? 500 : 360}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Whatsapp us with questions about debt, borrowing and pension on 07701
        342744.
      </Text>
      <VStack
        mt={10}
        flex={1}
        w={isWeb() ? 560 : 250}
        space={5}
        justifyContent="space-between"
        flexDirection={isWeb() ? "row" : "column"}
      >
        <Pressable onPress={() => handleContactClick("0808 802 6666")}>
          <HStack space={2}>
            <CallIcon />
            <VStack _dark={{ color: "secondary.200" }}>
              <Text>Telephone - 0800 138 7777</Text>
              <Text fontSize="10">
                (Monday to Friday 9am to 6pm and Saturday 8am to 6pm)
              </Text>
            </VStack>
          </HStack>
        </Pressable>
        <Link href="https://www.moneyhelper.org.uk/en" isExternal={true}>
          <HStack space={2}>
            <NetworkIcon />
            <VStack>
              <Text _dark={{ color: "secondary.200" }} underline={true}>
                MoneyHelper.org.uk
              </Text>
            </VStack>
          </HStack>
        </Link>
      </VStack>
    </View>
  );
}
