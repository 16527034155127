import { Icon, IIconProps } from "native-base";
import React from "react";
import { Svg, Line, Path } from "react-native-svg";

export function MenuWithBellIcon(props: IIconProps) {
  return (
    <Icon viewBox="0 0 30 34" {...props}>
      <Svg width="32" height="29" viewBox="0 0 32 29" fill="none">
        <Line
          x1="1"
          y1="-1"
          x2="22.5026"
          y2="-1"
          transform="matrix(-1 0 0 1 31.8291 29)"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
        />
        <Line
          x1="1"
          y1="-1"
          x2="13.6891"
          y2="-1"
          transform="matrix(-1 0 0 1 31.8291 19)"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Line
          x1="1"
          y1="-1"
          x2="7.81347"
          y2="-1"
          transform="matrix(-1 0 0 1 31.8291 9)"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Path
          d="M13.7525 10.6659C13.7006 10.6034 13.6497 10.5409 13.5997 10.4806C12.9122 9.64906 12.4963 9.14719 12.4963 6.79313C12.4963 5.57438 12.2047 4.57437 11.63 3.82437C11.2063 3.27031 10.6334 2.85 9.87845 2.53938C9.86873 2.53397 9.86006 2.52688 9.85282 2.51844C9.58126 1.60906 8.83814 1 8.00001 1C7.16189 1 6.41907 1.60906 6.14751 2.5175C6.14026 2.52563 6.13171 2.5325 6.1222 2.53781C4.36032 3.26312 3.50407 4.65469 3.50407 6.79219C3.50407 9.14719 3.08876 9.64906 2.40064 10.4797C2.35064 10.54 2.2997 10.6012 2.24782 10.665C2.11382 10.8266 2.02892 11.0232 2.00317 11.2316C1.97742 11.4399 2.01189 11.6513 2.10251 11.8406C2.29532 12.2469 2.70626 12.4991 3.17532 12.4991H12.8281C13.295 12.4991 13.7031 12.2472 13.8966 11.8428C13.9876 11.6534 14.0224 11.4419 13.9969 11.2333C13.9713 11.0247 13.8865 10.8278 13.7525 10.6659ZM8.00001 15C8.45158 14.9996 8.89464 14.8771 9.28219 14.6453C9.66973 14.4135 9.98732 14.0811 10.2013 13.6834C10.2113 13.6644 10.2163 13.643 10.2157 13.6215C10.2151 13.5999 10.2089 13.5789 10.1978 13.5604C10.1867 13.542 10.1709 13.5267 10.1522 13.5161C10.1334 13.5055 10.1122 13.5 10.0906 13.5H5.91001C5.88843 13.4999 5.86719 13.5054 5.84837 13.516C5.82955 13.5266 5.81378 13.5418 5.80261 13.5603C5.79144 13.5788 5.78524 13.5998 5.78462 13.6214C5.784 13.643 5.78898 13.6644 5.79907 13.6834C6.01299 14.0811 6.33053 14.4134 6.71801 14.6452C7.1055 14.877 7.54849 14.9996 8.00001 15Z"
          fill="white"
        />
      </Svg>
    </Icon>
  );
}
