import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { Pressable, View, ScrollView, Text, Heading } from "native-base";
import React, { useEffect, useState } from "react";

import { Button } from "../components/elements/Button";
import { EVENT_NOTIFICATIONS_ENABLED } from "../constants/analytics";
import { LAST_VIEW_NOTIFICATION_SCREEN } from "../constants/storage";
import { useExpoPushNotificationTokenUpdate } from "../hooks/useExpoPushNotificationTokenUpdate";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";
import { eventHit } from "../util/analytics";
import { registerForPushNotifications } from "../util/notifications";

export function NotificationsAlertScreen() {
  const { goBack } = useNavigation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate: expoPushNotificationTokenUpdate } =
    useExpoPushNotificationTokenUpdate();

  useEffect(() => {
    async function setlastViewed() {
      await AsyncStorage.setItem(
        LAST_VIEW_NOTIFICATION_SCREEN,
        dayjs().toString()
      );
    }
    setlastViewed();
  }, []);
  async function handleEnableNotifications() {
    try {
      eventHit("enable", EVENT_NOTIFICATIONS_ENABLED);

      setIsLoading(true);
      const token = await registerForPushNotifications();
      if (!token) {
        return;
      }
      await expoPushNotificationTokenUpdate({ token });
      setTimeout(() => {
        goBack();
      }, 300);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Header hasBack={true} />
      <Body>
        <ScrollView flex={1} paddingTop={130}>
          <View paddingLeft={30} paddingRight={30}>
            <Heading
              fontSize={16}
              fontWeight={600}
              marginBottom={5}
              textAlign="center"
            >
              Notifications permissions
            </Heading>
            <Text marginBottom={5} textAlign="center" fontSize={13}>
              In order to help you keep up with the intentions{" "}
              <Text fontWeight={600}>you</Text> set in your Miindset app and to
              ensure you benefit from the personalised advice provided, we
              strongly encourage you to turn notifications on.
            </Text>
            <Text fontSize={13} textAlign="center" marginBottom={10}>
              You can always turn them off in the settings at any point if you
              change your mind.
            </Text>
          </View>
          <View paddingLeft={20} paddingRight={20} textAlign="center">
            <Button
              variant="outline"
              marginBottom={15}
              borderRadius={35}
              isLoading={isLoading}
              onPress={handleEnableNotifications}
            >
              Enable Notifications
            </Button>
            <Pressable onPress={() => goBack()}>
              <Text fontSize={13} textAlign="center">
                No, not right now
              </Text>
            </Pressable>
          </View>
        </ScrollView>
      </Body>
    </>
  );
}
