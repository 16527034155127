import { ContentEntity } from "api/src/content/entity/content.entity";
import { useQuery, useQueryClient } from "react-query";

import { api } from "../util/api";

export function useOrganisationContent(contentId?: string) {
  const queryClient = useQueryClient();

  return useQuery<ContentEntity | undefined>(
    ["content", "single-content", contentId],
    async () => {
      const response = await api.get<ContentEntity>(
        `content/organisation-content/${contentId}`,
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    },
    {
      keepPreviousData: true,
      enabled: !!contentId,
      onSuccess: () => {
        queryClient.invalidateQueries(["content", "organisation-content"]);
      },
    }
  );
}
