import {
  View,
  Text,
  Heading,
  Divider,
  HStack,
  VStack,
  Link,
} from "native-base";
import React from "react";

import { isWeb } from "../../util/platform";
import { NetworkIcon } from "../icons/NetworkIcon";
import { SexualHealthIcon } from "../icons/SexualHealthIcon";

export function SexualHealthSupport() {
  return (
    <View mb={10} mt={50} alignItems="center">
      {!isWeb() ? <SexualHealthIcon /> : null}
      <Heading mt={5} mb={5} fontSize={28} fontWeight={600} textAlign="center">
        Sexual Health
      </Heading>
      {isWeb() ? <SexualHealthIcon /> : null}
      <Text
        mt={5}
        w={isWeb() ? 500 : 300}
        fontSize={14}
        textAlign="center"
        color="black"
        _dark={{ color: "secondary.200" }}
      >
        Forgotten your pill or had unprotected sex? Maybe you're worried about
        something? Find a sexual health clinic, contraception and pregnancy, and
        STI services near you.
      </Text>
      {isWeb() ? null : <Divider w="80%" mt={5} alignSelf="center" />}
      <Heading
        mt={5}
        fontSize={20}
        color="primary.400"
        fontWeight={600}
        textAlign="center"
      >
        NHS - Sexual Health
      </Heading>

      <Link
        mt={10}
        href="https://www.nhs.uk/service-search/sexual-health"
        isExternal={true}
      >
        <HStack space={2}>
          <NetworkIcon />
          <VStack>
            <Text _dark={{ color: "secondary.200" }} underline={true}>
              www.nhs.uk
            </Text>
          </VStack>
        </HStack>
      </Link>
    </View>
  );
}
