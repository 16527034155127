import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

interface IDeleteContent {
  contentId: string | undefined;
}

export function useDeleteContent(contentId: string | undefined) {
  const queryClient = useQueryClient();

  return useMutation<null, null, IDeleteContent>(
    ["delete-content", contentId],
    async () => {
      const response = await api.delete(`content/${contentId}`);

      if (response?.status === 200) {
        return response.data;
      }
      throw response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("content");
      },
    }
  );
}
