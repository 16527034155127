import { Box, ChevronDownIcon, HStack, Input, Select } from "native-base";
import React from "react";

import { CONTENT_CHANNELS } from "../../constants/content-channels";
import { CONTENT_STATUS } from "../../constants/content-status";
import { DateRange } from "../elements/DateRange";

interface IFilteredSearchProps {
  searchTerm: string;
  handleChannelChange: (channel: string) => void;
  handleSearchChange: (searchValue: string) => void;
  handleStatusChange: (value: string) => void;
  onDateRangeSelected: (startDate: string, endDate: string) => void;
}
export function FilteredSearch({
  searchTerm,
  handleChannelChange,
  handleSearchChange,
  handleStatusChange,
  onDateRangeSelected,
}: IFilteredSearchProps) {
  return (
    <HStack space={3} marginLeft={5}>
      <Input
        variant="rounded"
        placeholder="Search"
        borderRadius="full"
        width={200}
        value={searchTerm}
        onChangeText={handleSearchChange}
        maxHeight={9}
        paddingLeft={5}
      />
      <Select
        w={150}
        placeholder="Status"
        borderRadius="full"
        accessibilityLabel="Status"
        defaultValue="All"
        onValueChange={handleStatusChange}
        dropdownIcon={<ChevronDownIcon size={3} mr="22px" color="#000" />}
      >
        {["All", ...CONTENT_STATUS].map((label) => (
          <Select.Item key={label} label={label} value={label} />
        ))}
      </Select>

      <Select
        w={150}
        placeholder="Channels"
        borderRadius="full"
        accessibilityLabel="Channels"
        defaultValue="All"
        onValueChange={handleChannelChange}
        dropdownIcon={<ChevronDownIcon size={3} mr="22px" color="#000" />}
      >
        {[{ label: "All", value: "All" }, ...CONTENT_CHANNELS].map(
          (channel) => (
            <Select.Item
              key={channel.label}
              label={channel.label}
              value={channel.value}
            />
          )
        )}
      </Select>
      <Box marginTop={1}>
        <DateRange onDateRangeSelected={onDateRangeSelected} />
      </Box>
    </HStack>
  );
}
