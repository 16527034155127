import { Box, VStack, HStack, Button, Spinner, Input } from "native-base";
import React, { useEffect, useState } from "react";

import { useMe } from "../../../hooks/useMe";
import { useOrganisationUsers } from "../../../hooks/useOrganisationUsers";
import { Header } from "../../../layout/Header";
import { useBackgroundColor } from "../../../util/theme-helper";
import { Container } from "../../Container";
import { SearchIcon } from "../../icons/SearchIcon";
import { Loading } from "../../Loading";
import { InviteCode } from "../invitations/InviteCode";
import { OrganisationTabs } from "../OrganisationTabs";

import { OrganisationUser } from "./OrganisationUser";

export function UserList() {
  const { data: user } = useMe();

  const [searchText, setSearchText] = useState<string>("");

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
  } = useOrganisationUsers({
    filter: searchText,
    limit: 10,
  });

  useEffect(() => {
    if (searchText.length >= 2 || searchText.length === 0) {
      refetch();
    }
  }, [searchText]);

  const { backgroundColor } = useBackgroundColor();

  return (
    <>
      <Header hasBack={false} />
      <Container
        size="container.xl"
        h="auto"
        minH="94vh"
        pt={10}
        bg={backgroundColor}
      >
        <Box w="100%">
          <OrganisationTabs activeTab="UserList" />
          <HStack
            mr={9}
            justifyContent="flex-end"
            alignItems="center"
            alignContent="center"
          >
            <InviteCode user={user} mr="4" />
            <Input
              variant="rounded"
              placeholder="Search user"
              pl="3"
              value={searchText}
              onChangeText={(searchText) => {
                setSearchText(searchText);
              }}
              InputRightElement={
                <Box pr="3">
                  {isFetching && searchText.length > 2 ? (
                    <Spinner />
                  ) : (
                    <SearchIcon size="xs" color="secondary.650" />
                  )}
                </Box>
              }
            />
          </HStack>

          <VStack px={8} pt={3} space={5} w="100%">
            {isLoading ? (
              <Loading />
            ) : (
              <Box>
                {data?.pages?.map(({ items }) =>
                  items.map((item) => <OrganisationUser item={item} />)
                )}
                <HStack marginTop={5} justifyContent="center">
                  {hasNextPage && (
                    <Button
                      onPress={() => fetchNextPage()}
                      disabled={!hasNextPage}
                      isLoading={isFetchingNextPage}
                    >
                      Load More
                    </Button>
                  )}
                </HStack>
              </Box>
            )}
          </VStack>
        </Box>
      </Container>
    </>
  );
}
