import { extendTheme, ColorMode } from "native-base";

export const miindsetTheme = extendTheme({
  config: { useSystemColorMode: false },
  colors: {
    primary: {
      50: "#fef8fa",
      100: "#FBCFE8",
      200: "#EF5DA8",
      300: "#E74FAB",
      400: "#DF3D8D",
      500: "#dc2882",
      600: "#d22b7d",
    },
    secondary: {
      50: "#FAFAFA",
      100: "#FAF8FD",
      150: "#00000009",
      200: "#F4F4F5",
      300: "#ECE9F1",
      400: "#C4C4C4",
      450: "#A1A1AA",
      500: "#96A1A3",
      600: "#758184",
      650: "#71717a",
      700: "#5D5B6A",
      750: "#4b5563",
      800: "#424241",
      850: "#3f3f46",
      900: "#18181b",
    },
    red: {
      100: "#ffe4e6",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
    },
    dark: {
      100: "#161F32",
      200: "#0a0c1370",
      300: "#0a0c1340",
      350: "#0a0c1320",
      400: "#111624",
      500: "#0a0c13",
    },
  },
  shadows: {
    inverse: {
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: -2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
  },
  fontConfig: {
    Poppins: {
      300: {
        normal: "Poppins_300Light",
      },
      400: {
        normal: "Poppins_400Regular",
      },
      500: {
        normal: "Poppins_500Medium",
      },
      600: {
        normal: "Poppins_600SemiBold",
      },
    },
  },
  fonts: {
    heading: "Poppins",
    body: "Poppins",
    mono: "Poppins",
  },
  components: {
    Container: {
      baseStyle: {
        bg: "white",
        _dark: {
          bg: "dark.100",
        },
      },
    },
    Text: {
      baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
        color: colorMode === "light" ? "secondary.850" : "secondary.400",
      }),
      variants: {
        faded: {
          color: "secondary.400",
          _dark: {
            color: "secondary.650",
          },
        },
        strongHighlight: {
          color: "primary.400",
          _dark: {
            color: "primary.400",
          },
        },
        lightHighlight: {
          color: "primary.300",
          _dark: {
            color: "primary.300",
          },
        },
        "input-title": {},
      },
    },
    Heading: {
      baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
        color: colorMode === "light" ? "secondary.850" : "secondary.200",
      }),
    },
    Button: {
      baseStyle: {
        borderRadius: 35,
        pt: 2.5,
        pb: 2.5,
        _web: {
          _hover: {
            bg: "primary.100",
          },
        },
      },
      variants: {
        outline: {
          // REVER
          borderColor: "primary.200",
          borderWidth: 2,
          _dark: {
            bg: "dark.300",
            _text: {
              color: "secondary.200",
            },
          },
        },
        solid: {
          bg: "primary.400",
          _loading: { bg: "primary.400" },
          _pressed: { bg: "primary.400" },
          _disabled: { bg: "primary.200" },
          _hover: { bg: "primary.300" },
          _text: {
            fontWeight: "400",
            fontSize: "14",
            color: "white",
          },
          _dark: {
            _hover: { bg: "primary.300" },
            _pressed: { bg: "primary.300" },
          },
        },
        moodInverted: {
          borderColor: "primary.200",
          borderWidth: 2,
          _pressed: {
            opacity: 0.7,
          },
          _text: {
            color: "secondary.850",
          },
          _dark: {
            _text: {
              color: "secondary.200",
            },
          },
        },
        inverted: {
          borderColor: "primary.300",
          borderWidth: 2,
          bg: "white",
          _pressed: {
            bg: "secondary.200",
          },
          _text: {
            color: "secondary.750",
          },
          _dark: {
            bg: "dark.300",
            _text: {
              color: "secondary.400",
            },
            _pressed: {
              bg: "dark.500",
            },
          },
        },
        auth: {
          bg: "primary.400",
          padding: 4,
          _text: {
            color: "white",
            fontWeight: 600,
            fontSize: 13,
          },
          _pressed: {
            opacity: 0.7,
          },
          borderWidth: 2,
          borderColor: "primary.400",
        },
        invertedAuth: {
          bg: "transparent",
          padding: 4,
          _text: {
            color: "secondary.750",
            fontWeight: 500,
            fontSize: 13,
          },
          _pressed: {
            opacity: 0.7,
          },
          borderWidth: 2,
          borderColor: "primary.400",
          _dark: {
            _text: {
              color: "secondary.200",
            },
          },
        },
      },
    },
    Select: {
      variants: {
        auth: {
          borderWidth: 0,
          py: 3.5,
          px: 5,
          bg: "secondary.150",
          color: "secondary.850",
          placeholderTextColor: "secondary.700",
          _dark: {
            bg: "dark.200",
            color: "secondary.400",
            placeholderTextColor: "secondary.650",
          },
        },
      },
    },
    Input: {
      variants: {
        contentInput: {
          borderBottomWidth: 1,
          borderColor: "secondary.400",
          placeholderTextColor: "secondary.700",
        },
        title: {
          h: 55,
          py: 3,
          bg: "white",
          color: "secondary.850",
          fontWeight: "500",
          placeholderTextColor: "secondary.700",
          a: {
            fontSize: 2,
          },
          _dark: {
            bg: "dark.200",
            color: "secondary.400",
            placeholderTextColor: "secondary.650",
          },
        },
        auth: {
          borderWidth: 0,
          borderRadius: 10,
          px: 5,
          py: 3,
          h: 50,
          bg: "secondary.150",
          color: "secondary.900",
          placeholderTextColor: "secondary.700",
          _dark: {
            bg: "dark.200",
            color: "secondary.400",
            placeholderTextColor: "secondary.650",
            _disabled: {
              bg: "dark.300",
            },
          },
        },
        graySearch: {
          style: {
            fontSize: 15,
          },
          h: 55,
          bg: "secondary.200",
          borderRadius: 10,
          borderWidth: 0,
          _dark: {
            bg: "dark.200",
          },
        },
        lastViewedSearch: {
          style: {
            fontSize: 12,
          },
          h: 44,
          w: 250,
          mb: 10,
          bg: "secondary.200",
          borderRadius: 40,
          borderWidth: 0,
        },
        rounded: {
          _dark: {
            bg: "dark.200",
            placeholderTextColor: "secondary.650",
            _hover: {
              bg: undefined,
              opacity: 0.7,
            },
          },
        },
      },
    },
    Pressable: {
      baseStyle: {
        _web: {
          cursor: "pointer",
          _disabled: {
            cursor: "default",
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        _dark: {
          bg: "secondary.650",
        },
      },
    },
    Tooltip: {
      baseStyle: {
        bg: "secondary.800",
        _dark: {
          _text: {
            color: "secondary.200",
          },
          bg: "secondary.600",
        },
      },
    },
    Icon: {
      defaultProps: {
        size: "2xl",
      },
    },
  },
});
