import { BrainIcon } from "../components/icons/BrainIcon";
import { PersonWalkIcon } from "../components/icons/PersonWalkIcon";
import { WorriedFaceIcon } from "../components/icons/WorriedFaceIcon";
import { ICopingStrategies } from "../interfaces/ICopingStrategies";

/* 
TODO: 
 - ADD REAL CONTENT;

*/

export const COPING_STRATEGIES: ICopingStrategies[] = [
  {
    label: "Positive Steps",
    icon: PersonWalkIcon,
    description:
      "Here are some steps you can take to help improve the way you are feeling.",
    sections: [
      {
        label: "Be kind to yourself",
        description:
          "Being kind to yourself means not judging yourself harshly for not being perfect. It also means not holding yourself to impossibly high standards. An easy way to start being kinder to yourself is to take note of when you have a critical thought toward yourself, and reframe it. Ask yourself ‘what would I say to a friend in this situation?’. Treat yourself with the same kindness and you’ll be well on your way!",
        content: [
          "33aca005-4bcb-4993-b188-6ac14371fc11",
          "be3c2648-b67b-4d02-a852-e26af32791de",
          "a7b4b0b5-f4b4-48d8-8f16-388bfa4802a1",
          "dd29e4a8-c28d-482c-bbf2-a35dcfa7cd94",
        ],
      },
      {
        label: "Exercise regularly",
        description:
          "Exercise reduces stress and anxiety, improves physical health, gives us more energy and helps lift mood by releasing endorphins and dopamine. Pick the type of exercise you really love or makes you feel good (think badminton, swimming, cycling etc) - don’t follow the latest trends and push yourself too far too quickly. Start at your own pace, build up slowly while listening to your body and you’ll be more likely to stick to your new habit.",
        content: [
          "41d7e5e7-59bf-46ec-ad6f-ca6270c88e35",
          "af83e882-eccd-4653-9983-66f2cd22b052",
          "5a531c17-468b-4bb1-bb65-fe074e842d82",
        ],
      },
      {
        label: "Learn a new skill",
        description:
          "Taking up a hobby or learning a new skill boosts all sorts of feel-good hormones, and gets your brain active - which it loves! Hobbies help us feel connected to our sense of creativity and give us a sense of achievement, and can help us relax after a hard day. New skills that push us just out of our comfort zone [e.g learning a new song on an instrument] are great for our wellbeing and put us into ‘flow state’ - where all feel-good chemicals, concentration and motivation increase almost 100%!",
        content: [
          "a916ca36-8dc4-4b80-b99b-a02070b7e15c",
          "ba143388-f301-41d9-8d6c-d20b1f17e9a4",
        ],
      },
      {
        label: "Accepting",
        description:
          "We tend to fight against distressing thoughts and feelings, but we can learn to just notice them and let go of that struggle. Accepting doesn’t mean giving up or giving into thoughts - in fact, it is one of the most challenging things to do when you’re in a tough situation, so it takes a lot of strength. Acceptance is the first step to looking at ways to adjust or change a situation or a thought you don’t like. Think of the analogy of a shout at sea - it takes great skill but it is much more enjoyable to ride that shout than to try to fight it and have it crash against you. Start practising allowing all your thoughts in without judgment and letting them pass to build this skill - a simple meditation practice called ‘noting’ can help build this muscle.",
        content: [
          "9013670c-dbd3-11ec-9d64-0242ac120002",
          "93daa0ca-78d2-4e84-9739-f1968d0249f9",
          "717ed4f8-0509-4c9c-86c9-d59a51aa0887",
        ],
      },
      {
        label: "The big picture",
        description:
          "We all give different meanings to situations and see things from our point of view, but it can help us better relate to others and the world around us if we widen our perspective and consider the bigger picture. This can make problems seem slightly less daunting. Think what can I do right now that will help most? How important is it or will it be in a year? Is there another way of looking at this?",
        content: [
          "10799f6b-4b4d-48e8-8c29-0e62029b4127",
          "4bce1a38-abe3-4dd8-9457-bc82cebcb2a5",
          "735907bb-f192-448a-9084-fa94b05fd7fb",
        ],
      },
      {
        label: "Avoid alcohol and other mind-altering substances",
        description:
          "Although alcohol and other substances can offer relief after a busy day or a stressful week, they tend to start off a spiral where they deplete your body of energy and can cause disrupted sleep, having a knock-on effect on your mood, sugar cravings, and lifestyle choices. Dr Rangan Chatterjee calls them ‘junk happiness habits’ and they can include reaching for that chocolate bar or mindlessly scrolling through our phones. Try noticing some of these habits and replacing them with healthier coping strategies like calling a friend, doing some yoga or breathwork, completing a word search, or watching a nature documentary.",
        content: [
          "4846f386-f5a3-4cf1-8eec-d58e0bcf5d1a",
          "8520c086-3da3-4277-8bc4-a6ad59450e9d",
          "7a2ab445-b6e5-4d8f-afeb-89673d127581",
        ],
      },
      {
        label: "Connect with others",
        description:
          "Did you know that loneliness causes more health concerns than smoking? Don’t make that an excuse to grab a cigarette though! Try to make regular and frequent contact with friends and family; even once or twice a week dropping them a message or having a call on a Sunday morning. It’s important to try and plan physical meetups regularly too if you are able, as these release more happiness hormones and increase your sense of connection. Don’t have a lot of people in your life to spend time with? Try joining a local club or group [think dog walking, book club, athletics group etc] and meet some new local people that way.",
        content: [
          "ff0d4c00-d4fe-4be2-bb66-65d8d840261b",
          "b92529c3-5c8f-4f42-adf6-52e33f046fd3",
          "511c5f96-3cc5-4c70-94ac-5c535ade5bb9",
        ],
      },
      {
        label: "Sleep",
        description:
          "Getting enough sleep can be a real challenge in today’s world, but it couldn’t be a more pivotal part of good well-being. Your brain detoxes while you sleep and processes the day’s events, leaving your brain more refreshed for the next day. Create a bedtime routine that you can stick to which will ensure a better night’s sleep. Ensure you go to bed and wake up at a similar time each day [even at weekends!], and aim to go to sleep before 11 pm; it’s been proven that every hour you sleep before midnight is worth 3x any hour afterwards. Make your room an optimal sleep den; use an eye mask or blackout curtains/blinds to block out light, and try to wind down 30 mins -1 hour before bed by reading or talking rather than being on your phone. The blue light it emits can block the production of melatonin and make it harder to fall asleep.",
        content: [
          "1c0bd62f-ae51-4371-bd82-17a520496c3d",
          "be7bbace-e5c8-45fb-a41c-363bf873e0bf",
          "8462d690-0bbc-4f75-a5a7-9b22b62e65c8",
        ],
      },
      {
        label: "Healthy eating",
        description:
          "The key to healthy eating is finding all the foods you love most, that didn’t come out of a packet. We’re talking fruits, vegetables, nuts, seeds, grass-fed meats and wild fish. The best rule of thumb for knowing what you’re eating is healthy is it doesn’t have an ingredients list on the back. Cut down on any processed foods that are part of your daily diet [crisps, chocolate etc] and aim to cook fresh at home as much as possible. If you can, buying organic is really great too - check out the ‘dirty dozen’ to see the top 12 foods you should try buy organic where you can.",
        content: [
          "5924619c-59ba-43dd-8641-af5fd3875aae",
          "e2004227-9ee8-4628-b6bd-16af413145eb",
        ],
      },
      {
        label: "Learn to relax",
        description:
          "Taking time for yourself is something a lot of us find hard. We have been programmed to think relaxing is synonymous with laziness; but this simply isn’t true. When you relax, you recharge. Would you think your car was selfish for needing to be filled with petrol? Of course not! We need relaxation to replenish our reserves, and in the long run, it can make us more productive and energetic. What do you like to do to relax? Read, go for a walk, listen to music, have a bath? Do at least one thing every few days that really relaxes you.",
        content: [
          "dedddbaa-dbbe-4d46-a416-c50435a6216d",
          "ec61eadd-7045-46ac-8edc-80d758045daa",
          "95c2481f-4a58-49b2-9ba2-0e60dbd7ad91",
        ],
      },
      {
        label: "Helping others",
        description:
          "Try charity work, community projects or simply just helping someone out who asks for directions. Doing activities like this will not only benefit others but you will be doing something worthwhile which will help you feel better about yourself. It also boosts our connection to others and to our community, which as you know has a myriad of health benefits to all involved.",
        content: [
          "f1ec3214-6a78-43a0-a6b3-6cc22e76ada0",
          "40b08e17-f725-4906-935d-165214c91e3e",
          "8fa1c975-3747-468e-ac13-801cc11d729e",
        ],
      },
      {
        label: "Enjoy yourself",
        description:
          "Have some fun and be creative! Playing is not just for kids; get in touch with all the activities you loved to do as a child and pick one you’d like to still be doing. Skipping, hula hooping, painting, and crafting - all make great activities for enjoying yourself. If you have children in your life, you can even do it with them, too.",
        content: [],
      },
    ],
  },
  {
    label: "Managing worry",
    icon: WorriedFaceIcon,
    description:
      "Rather than trying to stop or get rid of an anxious thought, give yourself permission to have it, and try to make space for it. Take a few deep breaths and remember that you are separate from your thoughts, and you are simply observing them as they come and go. If you are really worried about something, it could help to write down an actionable list of things that are in your control that may help you worry a little less.",
    sections: [
      {
        label: "Make it worse",
        description:
          "When you try too hard to control your anxiety, this can have the opposite effect and make it worse. Anxiety is most often felt as sensations in the body and chest. Can you notice how your body is feeling currently? When we are anxious our breath naturally speeds up and becomes shallower, which activates our fight or flight system. A quick hack to alleviate that feeling of breathlessness and panic is to take control of your breath and take 10 deep breaths in and out [see ‘Belly Breathing’]. Try to make the out-breaths longer than the in-breaths, to activate your soothe system. This should turn on your body's biological switch to calm down, and help you maintain good levels of oxygen.",
        content: [
          "274f43cb-f7dc-4713-bca9-9733b5da8fd5",
          "77852a5a-3089-41bb-b4d9-b610d62ecad8",
          "8fa1c975-3747-468e-ac13-801cc11d729e",
          "3a894ec4-2428-4b65-b20e-e40a6dd5da62",
        ],
      },
      {
        label: "Recognising false alarms",
        description:
          "Our brain is hardwired from thousands of years of evolution to have a sensitive threat system, which makes sense when there could be a sabertooth tiger around every corner, but not so much when you think you might have left your straighteners on. Remember that your brain responds to any sensed danger as a potential life or death situation, but in this modern world we live in, you know better. Make the arrangements you need to rectify the situation, but remember that your brain is slightly overreacting due to its caveman instincts!",
        content: [
          "3868c352-bc16-4954-8b81-669e9c1a25a4",
          "faa9707d-ea5d-4827-8d69-d58d886a24ed",
          "fdce6886-a822-4185-8821-7d78be50c960",
        ],
      },
      {
        label: "Worry time",
        description:
          "Try setting aside 10 to 20 minutes every day just for your worries. If you're worrying about something, write down the reason and resolve to think it through later. You can then come back to the list later in the day and see if those worries are still bothering you. If they are, you can journal through them - what you can do about them, or why they might be affecting you in the way they are.",
        content: [
          "48438fac-6f4f-41f2-b02e-0e35324c53e7",
          "5495e7ad-9773-473b-9fd2-7c0d685a7dac",
          "570b5e69-09a4-4053-ac96-3e50b1f1a46a",
        ],
      },
      {
        label: "Breathe it out",
        description:
          "You may notice that when your body is tense you hold your breath. Focusing on your breathing is a common but effective technique to calm the nerves. Listen to the movement of your breath, and try counting in for 4, hold for 4, out for 4.",
        content: [
          "e203aef8-4b82-4728-89f8-ac56526646c8",
          "22f8c007-8318-4a52-9aae-b3a421989221",
          "195e980e-9b73-434a-915d-0c3cc60d4192",
        ],
        cta: {
          text: "Breathing Exercises",
          link: "BreathingExercises",
        },
      },
    ],
  },
  {
    label: "Thinking patterns",
    icon: BrainIcon,
    description:
      "Once you start to recognize your unhelpful thoughts and thinking styles you can start to notice them and start to think in a more helpful way. See some common thought patterns we engage in below and how to change them into more effective strategies.",
    sections: [
      {
        label: "Mind reading",
        description:
          "This is when we assume what others are thinking and feeling, and make assumptions based on these beliefs. Imagine we see someone in the height of the toilet roll shortage with a trolley full of toilet papers. We may instantly think they are panic buying, or looking to turn a profit by selling them on. However they could be looking after multiple elderly relatives or work in a care home and be getting supplies for those who cannot shop themselves. Always be mindful of these assumptions, as they can make us feel a certain way when our beliefs couldn’t actually be further from the reality of the situation.",
        content: [
          "41f20358-bde1-4640-8320-b96a87cbccdf",
          "29a05f1f-d651-40cf-b4ee-5b68372d389c",
        ],
      },
      {
        label: "Mental filter",
        description:
          "When we notice only what our filter allows us to notice, we dismiss anything that doesn't fit with it. Have you ever bought a new car and then all of a sudden you notice that car on the road everywhere? You may think there are more on the road, but in reality your mental filter has simply switched focus. This can be powerful, especially in creating a negative spiral. If you have a pessimistic filter on the world, you can guarantee that you’ll find what you’re looking for. So why not try a more optimistic approach?",
        content: [
          "2955bdcb-2604-4fc0-837c-c282b17658fa",
          "9ecdf4b5-dbe5-4369-94f2-a57835e2f9ce",
          "e12be46b-ff04-440d-a381-7fd90961ef95",
          "1fdee1b2-a84c-45ec-910a-7188c295aa8c",
        ],
      },
      {
        label: "Prediction",
        description:
          "This is when we believe we can predict what is going to happen - for example ‘I know I won’t get the job’.  Challenge these thought patterns and remember that we cannot know or predict with certainty anything that is outside of our control. Anything could happen, so always try to keep an open mind.",
        content: [
          "53f6b699-7d38-4ce8-939e-81118a45336a",
          "cd18e7e6-99c7-40db-90c1-d284a2a0744d",
          "e01a131d-50db-4a84-85f8-135010b83f10",
        ],
      },
      {
        label: "Compare and despair",
        description:
          "We’ve all been there; scrolled through our social feeds and compared what holidays our peers are going on vs those we have booked; what cars others drive or where they go out for dinner. These comparisons are unhelpful for a number of reasons; not first because people have a tendency to only show the ‘highlight reel’ of their life, so you’re not getting the full picture of what they do and don’t have from a social feed. Secondly, whether or not you had a fantastic trip to Cornwall last month is unaffected by how many people enjoyed a trip to the Seychelles at the same time. True contentment comes from being happy with ourselves and what we have, regardless of others around us.",
        content: [
          "cb571457-ed20-4dd3-90b9-dea7500e6f14",
          "ba912c47-5f05-4170-83b9-31bf55eaff73",
          "035d19dd-dd81-4d8b-a275-f0649b86b900",
        ],
      },
      {
        label: "Should and must",
        description:
          "These are words that create a high expectations either on ourselves or others, and often lead to us feeling let down or like we have failed. If you say to yourself ‘I must get an A in my school paper’, you are leaving little room for other outcomes, or for feeling good about yourself when you do achieve it. Rather if you reframed to telling yourself ‘I would love to get an A on my paper, but I am going to be proud of myself no matter what I get’, this then leaves room for you to feel really good for reaching a stretch goal, but not feeling bad if you don’t hit it this time. Here’s another reframing: ‘My partner should do the laundry before I get home’ vs ‘It would be really thoughtful for my partner to do chores before I’m back from work, but if not I can ask them to help me and I know they will’. One leaves room for disappointment, while the other for team work.",
        content: [
          "8db574ce-0694-4bd7-8dcd-67d1e2c2a93e",
          "6030c5f5-d9f2-43aa-a752-75239a5d748a",
          "c3a3757e-4409-489e-be1f-4d915d382662",
        ],
      },
      {
        label: "Critical self",
        description:
          "We all have an inner critic, and although it is usually there to try and push us to do our best, it's been scientifically proven that being compassionate to yourself makes you more productive and successful. Next time you catch yourself giving you a hard time, flip the narrative and speak to yourself like you would a friend or a young child. Give yourself support and encouragement - after all, you deserve it as much as they do.",
        content: [
          "affc9bfe-dd5f-4ea0-abb7-cf3680e2f55e",
          "88bd4e37-469c-4212-ab80-718936f84de3",
          "bc7e2a49-4da5-4c31-9ab8-c4c342d7dfce",
        ],
      },
      {
        label: "All or nothing",
        description:
          "Also known as ‘black and white’ thinking, we all fall into this trap. An example would be ‘If I don’t win this game, I’m a complete failure’. This sort of distorted thinking causes unnecessary stress, and often doesn’t reflect the reality of a situation. Try looking at things from alternate perspectives when you feel yourself falling into this trap. If you don’t win that game, you have learnt some valuable skills to help you better prepare for your next game, bringing you one step closer to winning!",
        content: [
          "bb558358-eee7-4331-ad7f-788871863159",
          "8eba0a00-1762-4402-a53d-0a0302855133",
        ],
      },
      {
        label: "Catastrophizing",
        description:
          "We can often feel overwhelmed by events and emotions, which leads to us thinking the situation at hand is much worse than it actually is. In the moment, missing your train may feel like the end of the world, spiraling into thoughts like you’re late for work, and your boss will fire you, and you won't be able to pay the bills, and you’ll be homeless… but in reality, you’ll likely call your boss, explain the situation [the barrier wouldn’t take your ticket], and be 15 minutes late to work which you can always make up later that day. Taking deep breaths is always good when you find yourself spiraling like this. Some space always helps calm whirring thoughts.",
        content: [
          "4630a49a-d6b9-43bb-84a0-526b566e3504",
          "52e5a506-4e19-49b2-a09b-b2a08e2eed25",
          "791d0802-3332-4c79-8ed6-04e23af4a323",
        ],
      },
      {
        label: "Bad memories",
        description:
          "Hurtful memories from the past can sometimes be a trigger leading us to believe that the danger is in the here and now rather than in the past, causing us to feel stressed, anxious and upset. Your body has been wired to respond a certain way due to what has happened in the past, so it’s trying to keep you safe. However, you can logically distinguish between the present moment’s situation and one in the past where perhaps you had much less control or didn’t know what to do in that moment. Again, taking a step back from the situation with some deep breaths can help give you some perspective that this situation and what has happened to you in the past are separate, unrelated events, and you can handle yourself in this current situation in an effective way.",
        content: [
          "b8dbf4f4-96cd-44ac-aee3-c646852b70b6",
          "5f2191ef-992d-4c9b-9a24-067831a58d56",
        ],
      },
    ],
  },
];
