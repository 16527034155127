import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function CopyPasteIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 43 49" {...props}>
      <Svg width={43} height={49} fill="none">
        <Path
          d="M17.057 11.538v17.63a2.938 2.938 0 0 0 2.938 2.939h11.753a2.938 2.938 0 0 0 2.939-2.939V16.301a2.938 2.938 0 0 0-.885-2.1l-4.87-4.764a2.938 2.938 0 0 0-2.054-.837h-6.883a2.938 2.938 0 0 0-2.938 2.938v0Z"
          stroke="#DF3D8D"
          strokeWidth={2.42}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M28.81 32.107v2.938a2.938 2.938 0 0 1-2.938 2.938H14.118a2.938 2.938 0 0 1-2.938-2.938v-16.16a2.938 2.938 0 0 1 2.938-2.94h2.939"
          stroke="#DF3D8D"
          strokeWidth={2.42}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
