import { useNavigation } from "@react-navigation/core";
import { ActivityLogType } from "api/src/activity-logs/enum/ActivityLogType";
import { Text, useColorModeValue, useToken, VStack } from "native-base";
import React from "react";

import { ConfettiAnimation } from "../components/animations/ConfettiAnimation";
import { Button } from "../components/elements/Button";
import { ScrollView } from "../components/elements/ScrollView";
import { EVENT_GET_GROUNDED } from "../constants/analytics";
import { useActivityCompleted } from "../hooks/useActivityCompleted";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";
import { eventHit } from "../util/analytics";
import { isWeb } from "../util/platform";

export function GetGroundedSuccessScreen() {
  const { navigate } = useNavigation();

  const { mutate: submitActivityLog } = useActivityCompleted(
    ActivityLogType.getGrounded
  );

  function handleContinue() {
    submitActivityLog(null, {
      onSuccess: () => {
        eventHit("submit", EVENT_GET_GROUNDED);
        navigate(isWeb() ? "Home" : "MyPage");
      },
    });
  }

  const [dark100, dark300] = useToken("colors", ["dark.100", "dark.300"]);
  const backgroundColor = useColorModeValue(
    "white",
    isWeb() ? dark300 : dark100
  );

  return (
    <>
      <Header />
      <Body>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ConfettiAnimation />

          <VStack
            my="12"
            paddingTop={10}
            alignItems="center"
            space="6"
            _web={{
              background: backgroundColor,
              p: "10",
              width: { sm: "90%", md: "70%" },
              margin: "auto",
            }}
          >
            <Text fontSize={14} fontWeight={600} textAlign="center">
              Well done. Give yourself a pat on the back.
            </Text>
            <VStack space="6">
              <Text fontSize={14} textAlign="center">
                Feel the sensation of your feet on the ground. Take a deep
                breath and go into your day more grounded and relaxed{" "}
                {String.fromCodePoint(0x1f60c)}
              </Text>
            </VStack>
            <VStack space="6">
              <Text fontSize={14} textAlign="center">
                Remember you can come back to this exercise everytime you need
                it
              </Text>
            </VStack>
            <Button
              maxW={300}
              variant="auth"
              marginTop={30}
              onPress={handleContinue}
            >
              Continue
            </Button>
          </VStack>
        </ScrollView>
      </Body>
    </>
  );
}
