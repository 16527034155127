import { Box, HStack } from "native-base";
import React, { useContext, useMemo } from "react";
import { SafeAreaView } from "react-native-safe-area-context";

import { OnboardingContext } from "../context/OnboardingContext";
import { OnboardingStep } from "../enum/signup-step";

const SIGNUP_STEPS_ORDER = [
  OnboardingStep.digestMedia,
  OnboardingStep.wellbeing,
  OnboardingStep.trackMood,
  OnboardingStep.trackFeelings,
];

const SIGNUP_STEP_COUNT = SIGNUP_STEPS_ORDER.length;

export function OnboardingSteps() {
  const { currentStep } = useContext(OnboardingContext);

  const currentStepIndex = useMemo(
    () => (currentStep ? SIGNUP_STEPS_ORDER.indexOf(currentStep) : -1),
    [currentStep]
  );

  if (!currentStep) {
    return null;
  }

  return (
    <SafeAreaView>
      <HStack space={2} position="relative" px={5} _android={{ pt: "4" }}>
        {[...Array(SIGNUP_STEP_COUNT)].map((_, index) => (
          <Box
            flex={1}
            key={index}
            height={1}
            bg={currentStepIndex >= index ? "primary.200" : "secondary.200"}
          />
        ))}
      </HStack>
    </SafeAreaView>
  );
}
