import { IOrganisationDepartment } from "api/src/organisations/interfaces/IOrganisationDepartment";
import { Box, HStack, Text, Divider, Button } from "native-base";
import React, { useState } from "react";

import { useDeleteDepartment } from "../../../hooks/useDeleteDepartment";
import { ToastStatus, useToast } from "../../../hooks/useToast";
import { AlertDialog } from "../../elements/AlertDialog";
import { DeleteIcon } from "../../icons/DeleteIcon";

export function ReviewableDepartment({
  department,
}: {
  department: IOrganisationDepartment;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toast = useToast();
  const { mutate: deleteDepartment } = useDeleteDepartment(department.value);

  function handleDelete() {
    deleteDepartment(
      { departmentId: department.value },
      {
        onSuccess: (data) => {
          if (data.status === "ok") {
            toast({
              title: data.message,
              status: ToastStatus.success,
            });
          } else {
            toast({
              title: data.errors.message,
              status: ToastStatus.error,
            });
          }
        },
        onError: (data) => {
          toast({
            title: data.message,
            status: ToastStatus.error,
          });
        },
      }
    );
  }

  return (
    <Box
      width="31%"
      bg="secondary.100"
      borderRadius="5"
      mb="4"
      p="1"
      key={department.value}
      _dark={{ bg: "dark.200" }}
    >
      <HStack justifyContent="space-between" alignItems="center" space="3">
        <Text
          fontSize="lg"
          pl="5"
          textTransform="capitalize"
          textAlign="center"
        >
          {department.label}
        </Text>
        <HStack h="100%">
          <Divider orientation="vertical" bg="secondary.700" />
          <Button
            justifyContent="center"
            alignItems="center"
            variant="unstyled"
            onPress={() => setIsOpen(true)}
          >
            <DeleteIcon size={8} color="secondary.700" />
            <AlertDialog
              headerText="Delete Content"
              bodyText="This action will remove the department permanently. Are you sure you?"
              buttonColor="red.500"
              buttonText="Confirm"
              isOpen={isOpen}
              buttonAction={() => {
                setIsOpen(false);
                handleDelete();
              }}
              onClose={() => {
                setIsOpen(false);
              }}
            />
          </Button>
        </HStack>
      </HStack>
    </Box>
  );
}
