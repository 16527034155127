import { useNavigation } from "@react-navigation/native";
import { Heading, HStack, Text, VStack } from "native-base";
import { useEffect, useMemo, useState } from "react";

import { ORGANISATION_TABS } from "../../constants/organisation-tabs";

export function OrganisationTabs({ activeTab }: { activeTab: string }) {
  const { navigate } = useNavigation();
  const [pathname, setPathname] = useState(window.location.pathname);

  useEffect(() => {
    const pathTimeout = setTimeout(() => {
      setPathname(window.location.pathname);
    }, 10);
    return () => clearTimeout(pathTimeout);
  }, []);

  const title = useMemo(
    () =>
      ORGANISATION_TABS.find(({ root }) => pathname.includes(root as string))
        ?.root?.replace(/-/g, " ")
        .replace(/^./, (x) => x.toUpperCase()),
    [pathname]
  );

  return (
    <VStack space={2} ml={6}>
      <HStack alignItems="center">
        <Heading
          fontSize={24}
          color="primary.400"
          fontWeight="600"
          _dark={{ color: "secondary.200" }}
        >
          {title}
        </Heading>
      </HStack>
      <HStack space={5} ml={0.5}>
        {ORGANISATION_TABS.filter(({ root }) =>
          pathname.includes(root as string)
        ).map((tab, index) => (
          <Text
            key={index}
            onPress={() => navigate(tab.url)}
            fontSize="lg"
            fontWeight={tab.url === activeTab ? 500 : 100}
            color={tab.url === activeTab ? "primary.400" : undefined}
            _dark={{
              color: tab.url === activeTab ? "primary.400" : "secondary.400",
            }}
          >
            {tab.title}
          </Text>
        ))}
      </HStack>
    </VStack>
  );
}
