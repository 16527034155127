import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function UserIcon({ color, ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 30 34" {...props}>
      <Svg width={32} height={32} fill="none" stroke={color}>
        <Path
          d="M16 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <Path
          d="M3.873 26.999a14.005 14.005 0 0 1 24.253 0"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
