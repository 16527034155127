import { ContentType } from "api/src/content/enum/ContentType";
import { ReactElement } from "react";

import { HeadphonesIcon } from "../components/icons/HeadphonesIcon";
import { OpenBookIcon } from "../components/icons/OpenBookIcon";
import { VideoCameraIcon } from "../components/icons/VideoCameraIcon";
import { IIconProps } from "../interfaces/IIconprops";

export const CONTENT_TYPE_ICONS: Partial<
  Record<ContentType, (props: IIconProps) => ReactElement>
> = {
  [ContentType.podcast]: HeadphonesIcon,
  [ContentType.video]: VideoCameraIcon,
  [ContentType.article]: OpenBookIcon,
};
