import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { useMe } from "./useMe";

export function useOnboard() {
  const queryClient = useQueryClient();
  const { data: me } = useMe();
  return useMutation(
    "onboard",
    async () => {
      await api.post("users/onboard");
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(["account"], { ...me, hasOnboarded: true });
      },
    }
  );
}
