import { JournalEntryEntity } from "api/src/journal/entities/journal-entry.entity";
import { useQuery } from "react-query";

import { api } from "../util/api";

export function useJournalEntry(journalId: string | undefined) {
  return useQuery<JournalEntryEntity>(
    ["journal", journalId],
    async () => {
      const response = await api.get<JournalEntryEntity>(
        `journal/${journalId}`,
        {}
      );

      if (response.status === 200) {
        return response.data;
      }

      throw Promise.reject("Couldn't fetch journal entry");
    },
    {
      keepPreviousData: true,
      enabled: !!journalId,
    }
  );
}
