import AsyncStorage from "@react-native-async-storage/async-storage";
import { useContext } from "react";
import { useMutation } from "react-query";

import { EXPO_PUSH_NOTIFICATION_TOKEN } from "../constants/storage";
import { MeContext } from "../context/MeContext";

import { useExpoPushNotificationTokenDelete } from "./useExpoPushNotificationTokenDelete";

export function useLogout() {
  const { logout } = useContext(MeContext);
  const { mutate: expoPushNotificationDelete } =
    useExpoPushNotificationTokenDelete();

  return useMutation("logout", async () => {
    // Disable push notifications (if they are enabled)
    const token = await AsyncStorage.getItem(EXPO_PUSH_NOTIFICATION_TOKEN);

    if (token) {
      await expoPushNotificationDelete({ token });
    }
    // Deleting All keys from AsyncStorage
    const keys = await AsyncStorage.getAllKeys();
    await AsyncStorage.multiRemove(keys);
    logout();

    return true;
  });
}
