import { IChannel } from "api/src/channels/interface/IChannel";
import { ContentType } from "api/src/content/enum/ContentType";
import { random } from "lodash";
import { Box, Image } from "native-base";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Animated, Dimensions, Easing } from "react-native";

import { getChannelImage } from "../util/channel";
import { randomPointNearRect } from "../util/math";

import { ContentIcon } from "./content/ContentIcon";

const { width, height } = Dimensions.get("window");

interface IDigestingItemProps {
  channel: IChannel;
  animationTime?: number;
}

const RECT_START_X = 300;
const RECT_START_Y = 300;
const RECT_DIST = 100;
const RECT_BOUNDRY = 100;

export function DigestingItem({
  animationTime = 1000,
  channel,
}: IDigestingItemProps) {
  const [startX, startY] = useMemo(
    () =>
      randomPointNearRect(
        -RECT_START_X,
        -RECT_START_Y,
        width + RECT_START_X + RECT_DIST,
        height + RECT_START_X + RECT_DIST,
        RECT_BOUNDRY,
        RECT_BOUNDRY
      ),
    [height, width]
  );

  const initialScale = useMemo(() => random(0.4, 0.7), []);
  const initialType = useMemo(
    () =>
      [ContentType.podcast, ContentType.video][Math.floor(Math.random() * 2)],
    []
  );

  const topPosition = useRef(new Animated.Value(startY)).current;
  const leftPosition = useRef(new Animated.Value(startX)).current;
  const image = useMemo(() => getChannelImage(channel), [channel]);

  const scaleAnimation = useRef(new Animated.Value(initialScale)).current;

  const randomisedAnimationTime = useMemo(
    () => animationTime * (0.5 + Math.random() / 2),
    []
  );

  const handleAnimation = useCallback(() => {
    Animated.timing(scaleAnimation, {
      toValue: 0.3,
      duration: randomisedAnimationTime,
      useNativeDriver: true,
    }).start();

    Animated.timing(leftPosition, {
      useNativeDriver: true,
      duration: randomisedAnimationTime,
      toValue: width / 2 - 100,
      easing: Easing.out(Easing.ease),
    }).start();

    Animated.timing(topPosition, {
      useNativeDriver: true,
      duration: randomisedAnimationTime,
      toValue: height / 2 - 100,
      easing: Easing.out(Easing.ease),
    }).start();
  }, [scaleAnimation]);

  useEffect(() => {
    handleAnimation();
  }, [handleAnimation]);

  return (
    <Animated.View
      style={{
        zIndex: initialScale * 100,
        position: "absolute",
        transform: [
          { translateX: leftPosition },
          { translateY: topPosition },
          { scale: scaleAnimation },
        ],
      }}
    >
      <Box alignItems="center" justifyContent="center">
        <Image
          width={200}
          height={200}
          borderRadius={1000}
          alt={channel.label}
          source={image}
        />

        <ContentIcon
          p={8}
          size={8}
          shadow={0}
          borderWidth={2}
          type={initialType}
          position="absolute"
          borderColor="primary.200"
          zIndex={initialScale * 100 + 1}
        />
      </Box>
    </Animated.View>
  );
}
