import { createContext } from "react";

import { OnboardingStep } from "../enum/signup-step";

export interface IOnboardingContext {
  currentStep: OnboardingStep | null;
  setCurrentStep: (step: OnboardingStep | null) => void;
}

export const OnboardingContext = createContext<IOnboardingContext>({
  currentStep: null,
  setCurrentStep: () => {},
});
