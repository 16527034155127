import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { isEmpty } from "lodash";
import { HStack, Text, Box } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";

import { useDoughnutChartOptions } from "../../../../hooks/useDoughnutChartOptions";
import { useOrganisationStressAnxietyDepressionTotalPercentage } from "../../../../hooks/useOrganisationStressAnxietyDepressionTotalPercentage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const pieData = {
  labels: ["Stress", "Anxiety", "Depression"],
  datasets: [
    {
      data: [] as number[],
      backgroundColor: ["#D14C4C", "#35CD1C", "#1BA2CD"],
    },
  ],
};

export function TotalOrganisationPercentageChart() {
  const chartOptions = useDoughnutChartOptions();
  const chartRef = useRef<ChartJS<"doughnut">>(null);
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(true);
  const { data: totalPercentages, isLoading } =
    useOrganisationStressAnxietyDepressionTotalPercentage();

  useEffect(() => {
    const chart = chartRef.current;
    if (totalPercentages !== undefined && !isEmpty(totalPercentages)) {
      setIsDataEmpty(false);
      pieData.datasets[0].data = [];
      totalPercentages.forEach((value: number) => {
        pieData.datasets[0].data.push(value);
      });
      chart?.update();
    }
  }, [totalPercentages]);

  return (
    <HStack h="100%" w="100%">
      {isLoading || isDataEmpty ? (
        <Box alignItems="center" justifyContent="center" w="100%">
          <Text color="secondary.650">
            There's no data to be shown currently.
          </Text>
        </Box>
      ) : (
        <Chart
          ref={chartRef}
          type="doughnut"
          data={pieData}
          options={chartOptions}
        />
      )}
    </HStack>
  );
}
