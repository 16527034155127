import { useNavigation, useRoute, RouteProp } from "@react-navigation/core";
import dayjs from "dayjs";
import { Center, Text } from "native-base";
import React, { useState } from "react";

import { AlertDialog } from "../../components/elements/AlertDialog";
import { LoadingAnimation } from "../../components/elements/LoadingAnimation";
import { ScrollView } from "../../components/elements/ScrollView";
import { JournalEntryHeader } from "../../components/journal/JournalEntryHeader";
import { WhiteBoxContainer } from "../../components/WhiteBoxContainer";
import { useJournalEntry } from "../../hooks/useJournalEntry";
import { useJournalEntryDelete } from "../../hooks/useJournalEntryDelete";
import { useToast, ToastStatus } from "../../hooks/useToast";
import { Body } from "../../layout/Body";
import { Header } from "../../layout/Header";
import { RootStackParamList } from "../../navigation/RootStackParamList";
import { getJournalDeleteSuccessMsg } from "../../util/journal-delete-message";

export function DiaryDetailsScreen() {
  const { goBack } = useNavigation();
  const toast = useToast();

  const {
    params: { journalId },
  } = useRoute<RouteProp<RootStackParamList, "DiaryDetails">>();

  const {
    data: journalEntry,
    isLoading,
    isFetching,
  } = useJournalEntry(journalId);

  const { mutate: deleteJournalEntry } = useJournalEntryDelete(
    journalEntry?.id
  );

  const date = dayjs(journalEntry?.updatedAt || journalEntry?.createdAt).format(
    "MMM Do YYYY hh:mmA"
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = () => setIsOpen(false);

  function handleDelete() {
    deleteJournalEntry(
      {
        journalId: journalEntry?.id,
      },
      {
        onSuccess: () => {
          onClose();
          toast({
            title: getJournalDeleteSuccessMsg(journalEntry?.type),
            status: ToastStatus.success,
          });
          goBack();
        },
      }
    );
  }

  return (
    <>
      <Header hasBack={true} />
      <Body>
        {!isLoading || !isFetching ? (
          <>
            <ScrollView>
              <WhiteBoxContainer>
                <JournalEntryHeader
                  journalId={journalEntry?.id}
                  title={journalEntry?.title}
                  setIsOpen={() => setIsOpen(!isOpen)}
                  editEntrypoint="AddDiaryEntry"
                  date={date}
                />
                <Text> {journalEntry?.diaryData?.post}</Text>
              </WhiteBoxContainer>
            </ScrollView>
            <AlertDialog
              headerText="Delete Entry"
              bodyText="This will remove your entry. Are you sure you want to
          delete it?"
              buttonText="Delete"
              buttonAction={handleDelete}
              isOpen={isOpen}
              onClose={onClose}
            />
          </>
        ) : (
          <Center h="full">
            <LoadingAnimation size={100} isLoading={isFetching || isLoading} />
          </Center>
        )}
      </Body>
    </>
  );
}
