import { UserEntity } from "api/src/users/entities/user.entity";
import { createContext } from "react";

export interface IMeContext {
  me?: UserEntity;
  isAuthenticated: boolean | null;
  authorize: () => Promise<void>;
  logout: () => void;
}

export const MeContext = createContext<IMeContext>({
  me: undefined,
  isAuthenticated: false,
  logout: () => {},
  authorize: async () => {},
});
