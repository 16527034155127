import AsyncStorage from "@react-native-async-storage/async-storage";
import { CommonActions, useNavigation } from "@react-navigation/core";
import { DEFAULT_CHANNELS } from "api/src/channels/constants/default-channels";
import { Box, useToken, View } from "native-base";
import React, { useCallback, useEffect, useRef } from "react";
import { Animated, Dimensions, Easing } from "react-native";

import { DigestingItem } from "../components/DigestingItem";
import { WELLBEING_WELL_DONE_SCREEN_KEY } from "../constants/storage";
import { useBackButton } from "../hooks/useBackButton";
import { useCompleteLoginActivity } from "../hooks/useCompleteLoginActivity";
import { useMe } from "../hooks/useMe";
import { useOnboard } from "../hooks/useOnboard";
import { isWeb } from "../util/platform";

const { width, height } = Dimensions.get("window");

const ANIMATION_TIME = 2500;
const PULSE_DELAY = 300;
const PULSE_DURATION = 400;

export function DigestingScreen() {
  const scaleAnimation = useRef(new Animated.Value(0.4)).current;
  const { mutateAsync: onboard } = useOnboard();
  const { mutateAsync: completeLogin } = useCompleteLoginActivity();
  const { data: user } = useMe();

  const { dispatch, navigate } = useNavigation();
  const backgroundColor = useToken("colors", "dark.100");

  useBackButton(() => true);

  const handleCompleted = useCallback(async () => {
    if (!user?.hasOnboarded) {
      await AsyncStorage.setItem(WELLBEING_WELL_DONE_SCREEN_KEY, "show");
      await onboard();
    }
    if (user?.hasOnboarded && !user?.firstLogin && user?.loginCount === 1) {
      await completeLogin({ login: "first" });
    }
    if (
      user?.hasOnboarded &&
      user.firstLogin &&
      !user?.secondLogin &&
      user?.loginCount === 2
    ) {
      await completeLogin({ login: "second" });
    }
    if (
      user?.hasOnboarded &&
      user.firstLogin &&
      user.secondLogin &&
      !user?.thirdLogin &&
      user?.loginCount === 3
    ) {
      await completeLogin({ login: "third" });
    }
    if (!isWeb()) {
      dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: "Main" }],
        })
      );
      return;
    }
    if (window.location.pathname.includes("main")) {
      dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{ name: "Main" }],
        })
      );
      return;
    }

    navigate("Main");
  }, []);

  const handlePulse = useCallback(() => {
    Animated.timing(scaleAnimation, {
      toValue: 0.45,
      useNativeDriver: true,
      duration: PULSE_DURATION,
      easing: Easing.in(Easing.ease),
    }).start(() => {
      Animated.timing(scaleAnimation, {
        toValue: 0.4,
        useNativeDriver: true,
        duration: PULSE_DURATION,
        easing: Easing.out(Easing.ease),
      }).start(handleCompleted);
    });
  }, []);

  useEffect(() => {
    (async () => {
      setTimeout(() => {
        handlePulse();
      }, ANIMATION_TIME + PULSE_DELAY);
    })();
  }, [handlePulse]);

  return (
    <View _dark={{ bg: backgroundColor }} w={width} height={height}>
      {DEFAULT_CHANNELS.map((channel) => (
        <Box zIndex={1} key={channel.slug}>
          <DigestingItem animationTime={ANIMATION_TIME} channel={channel} />
        </Box>
      ))}

      <Box
        zIndex={2}
        position="absolute"
        top="50%"
        bottom="50%"
        alignItems="center"
        justifyContent="center"
        alignSelf="center"
      >
        <Animated.Image
          style={{
            transform: [{ scale: scaleAnimation }],
            zIndex: 1000,

            width: 200,
            height: 200,
          }}
          source={require("../assets/images/logo-single.png")}
        />
      </Box>
    </View>
  );
}
