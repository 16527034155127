import { useColorModeValue, useToken, View } from "native-base";
import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { Animated } from "react-native";

interface ISemiCircleProps {
  minValue?: number;
  maxValue?: number;
  percentage: number;
  children?: ReactNode;
  progressWidth: number;
  currentValue?: number;
  circleRadius?: number;
  progressColor?: string;
  animationSpeed?: number;
  initialPercentage?: number;
}

export function SemiCircleProgress({
  minValue,
  maxValue,
  children,
  percentage,
  currentValue,
  progressWidth,
  animationSpeed = 2,
  circleRadius = 100,
  initialPercentage = 0,
  progressColor = "primary.400",
}: ISemiCircleProps) {
  const rotationAnimation = useRef(
    new Animated.Value(initialPercentage)
  ).current;
  const [color, dark100, secondary200, secondary400] = useToken("colors", [
    progressColor,
    "dark.100",
    "secondary.200",
    "secondary.400",
  ]);
  const backgroundColor = useColorModeValue("white", dark100);
  const emptySpaceColor = useColorModeValue(secondary400, secondary200);
  function getPercentage() {
    if (percentage) {
      return Math.max(Math.min(percentage, 100), 0);
    }

    if (currentValue && minValue && maxValue) {
      return Math.max(
        Math.min(
          ((currentValue - minValue) / (maxValue - minValue)) * 100,
          100
        ),
        0
      );
    }

    return 0;
  }

  const handleAnimation = useCallback(() => {
    const toValue = getPercentage();

    Animated.spring(rotationAnimation, {
      toValue,
      speed: animationSpeed,
      useNativeDriver: true,
    }).start();
  }, [getPercentage, animationSpeed, rotationAnimation]);

  useEffect(() => {
    handleAnimation();
  }, [handleAnimation]);

  const interiorCircleRadius = circleRadius - progressWidth;

  return (
    <>
      <View
        width={circleRadius * 2}
        height={circleRadius}
        borderRadius={circleRadius}
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
        alignItems="center"
        overflow="hidden"
        backgroundColor={emptySpaceColor}
      >
        <View
          position="absolute"
          left={0}
          width={circleRadius * 2}
          height={circleRadius}
          top={circleRadius}
        >
          <Animated.View
            style={{
              top: 0,
              left: 0,
              height: circleRadius,
              position: "absolute",
              backgroundColor: color,
              borderTopLeftRadius: 0,
              width: circleRadius * 2,
              borderTopRightRadius: 0,
              borderRadius: circleRadius,
              transform: [
                { translateY: -circleRadius / 2 },
                {
                  rotate: rotationAnimation.interpolate({
                    inputRange: [0, 100],
                    outputRange: ["0deg", "180deg"],
                  }),
                },
                { translateY: circleRadius / 2 },
              ],
            }}
          />
        </View>
        <View
          overflow="hidden"
          alignItems="center"
          backgroundColor={backgroundColor}
          justifyContent="flex-end"
          borderBottomLeftRadius={0}
          top={`${progressWidth}px`}
          borderBottomRightRadius={0}
          height={interiorCircleRadius + 1}
          width={interiorCircleRadius * 2}
          borderRadius={interiorCircleRadius}
        >
          {children}
        </View>
      </View>
    </>
  );
}
