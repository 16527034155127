import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function PhoneIcon({ color = "#C4C4C4", ...props }: IIconProps) {
  return (
    <Icon viewBox="-4 0 35 33" {...props}>
      <Svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        stroke={color}
      >
        <Path
          d="M25.45 18.9251C24.1265 18.7617 22.8122 18.5302 21.5125 18.2313C21.1952 18.1253 20.8559 18.1029 20.5275 18.1664C20.199 18.2299 19.8925 18.3772 19.6375 18.5938C19.3063 18.9313 18.35 19.8751 17.3563 20.8501C13.7958 19.0998 10.9163 16.2181 9.1688 12.6563C10.1375 11.6626 11.0438 10.7126 11.4125 10.3813C11.6292 10.1264 11.7764 9.81989 11.8399 9.49142C11.9034 9.16294 11.8811 8.82363 11.775 8.50633C11.4758 7.20462 11.2442 5.88827 11.0813 4.56258C11.0638 4.39376 11.0122 4.23027 10.9295 4.08206C10.8468 3.93386 10.7348 3.80405 10.6003 3.70054C10.4658 3.59704 10.3116 3.522 10.1472 3.48002C9.98272 3.43803 9.81145 3.42997 9.6438 3.45633H4.3188C4.09899 3.47617 3.89324 3.57293 3.73776 3.72955C3.58227 3.88618 3.48703 4.09264 3.4688 4.31258C3.12505 9.11883 5.8063 14.2626 6.35005 15.2501V15.2876L6.42505 15.4313C8.37151 18.8072 11.1742 21.6099 14.55 23.5563V23.5563L14.825 23.7126C16.075 24.3751 21.0438 26.8751 25.6875 26.5313C25.9097 26.5159 26.1191 26.4219 26.2783 26.2662C26.4375 26.1104 26.536 25.9031 26.5563 25.6813V20.3626C26.5827 20.1949 26.5746 20.0237 26.5326 19.8592C26.4906 19.6948 26.4156 19.5406 26.3121 19.4061C26.2086 19.2716 26.0788 19.1596 25.9306 19.0769C25.7824 18.9942 25.6189 18.9425 25.45 18.9251Z"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
