import { View, useBreakpointValue } from "native-base";
import React from "react";
import { ViewStyle } from "react-native";

export interface IGridProps {
  direction: string;
  gap?: string | number;
  children?: React.ReactNode | React.ReactNode[];
}

/**
 * Warning: This component only works on `web`
 */
export function Grid({
  direction = "column",
  children,
  gap = "2rem",
}: IGridProps) {
  const numberOfColumn = useBreakpointValue({
    base: "1fr",
    sm: "1fr",
    md: "1fr 1fr ",
    lg: "1fr 1fr 1fr",
    xl: "1fr 1fr 1fr",
  });

  return (
    <View
      style={
        {
          gridGap: gap,
          display: "grid",
          gridTemplateColumns: direction === "column" ? numberOfColumn : "1fr",
        } as unknown as ViewStyle
      }
    >
      {children}
    </View>
  );
}
