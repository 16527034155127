import { AccountType } from "api/src/users/enum/account-type";

import { RequiredImageType } from "../type/RequiredImageType";

export const ACCOUNT_TYPES: Record<
  AccountType,
  { image: RequiredImageType; label: string }
> = {
  [AccountType.ambassador]: {
    image: require("../assets/images/badges/ambassador.png"),
    label: "Ambassador",
  },
  [AccountType.corporate]: {
    image: require("../assets/images/badges/corporate.png"),
    label: "Corporate Member",
  },
  [AccountType.corporateFamily]: {
    image: require("../assets/images/badges/corporateFamily.png"),
    label: "Corporate Family Member",
  },
  [AccountType.founder]: {
    image: require("../assets/images/badges/founder.png"),
    label: "Founding Member",
  },
  [AccountType.standard]: {
    image: require("../assets/images/badges/standard.png"),
    label: "Standard Member",
  },
  [AccountType.student]: {
    image: require("../assets/images/badges/student.png"),
    label: "Student Member",
  },
  [AccountType.unidays]: {
    image: require("../assets/images/badges/unidays.png"),
    label: "Unidays Member",
  },
  [AccountType.vip]: {
    image: require("../assets/images/badges/vip.png"),
    label: "VIP Member",
  },
};
