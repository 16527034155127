import React from "react";
import { StyleSheet } from "react-native";
import MDMarkdown from "react-native-markdown-display";

import { useTextColor } from "../../util/theme-helper";

interface IMarkdown {
  children?: string;
}

export function Markdown({ children }: IMarkdown) {
  const { defaultTextColor } = useTextColor();

  const style: StyleSheet.NamedStyles<unknown> = {
    body: {
      fontFamily: "Poppins_400Regular",
      fontSize: 13,
      color: defaultTextColor,
    },
    heading2: {
      fontSize: 15,
      fontFamily: "Poppins_600SemiBold",
    },
    link: {
      textDecorationLine: "none",
      color: "#DF3D8D",
    },
    textgroup: {
      textAlign: "justify",
    },
  };

  return <MDMarkdown style={style}>{children}</MDMarkdown>;
}
