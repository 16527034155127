import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

interface IHabitId {
  habitId: string | undefined;
}

export function useHabitStreakAdd(habitId: string | undefined) {
  const queryClient = useQueryClient();

  return useMutation<null, null, IHabitId>(
    ["habit-streak-add", habitId],
    async ({ habitId }) => {
      const response = await api.post(`habit/streak/${habitId}`, {});

      if (response.status !== 201) {
        throw response.data;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("habit-streak");
      },
    }
  );
}
