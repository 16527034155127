export enum Feeling {
  active = "active",
  joy = "joy",
  gratitude = "gratitude",
  hope = "hope",
  pride = "pride",
  awe = "awe",
  inspiration = "inspiration",
  upbeat = "upbeat",
  amused = "amused",
  relaxed = "relaxed",
  love = "love",
  happy = "happy",
  relieved = "relieved",
  calm = "calm",
  rested = "rested",
  safe = "safe",
  anger = "anger",
  rage = "rage",
  emptiness = "emptiness",
  fear = "fear",
  guilt = "guilt",
  grief = "grief",
  anxious = "anxious",
  lonely = "lonely",
  disgust = "disgust",
  annoyed = "annoyed",
  frustrated = "frustrated",
  anguish = "anguish",
  numb = "numb",
  unfit = "unfit",
  stressed = "stressed",
}
