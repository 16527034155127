import { useQuery } from "react-query";

import { api } from "../util/api";

export function useOrganisationMonthWiseWellbeingScores() {
  return useQuery<number[]>(
    ["organisations", "wellbeing-scores"],
    async () => {
      const response = await api.get<number[]>(
        "organisations/month-wise-wellbeing-scores"
      );

      if (response.status === 200) {
        return response.data;
      }
      return Promise.reject("Could not get organisation wellbeing scores");
    },
    {
      keepPreviousData: true,
    }
  );
}
