import { useMutation } from "react-query";

import { api } from "../util/api";

import { IApiResponse } from "./IApiResponse";

type IForgotPasswordValidateEmail = Record<string, string>;

export function useForgotPasswordValidateEmail() {
  return useMutation<
    IApiResponse<IForgotPasswordValidateEmail>,
    IApiResponse<IForgotPasswordValidateEmail>,
    IForgotPasswordValidateEmail
  >(
    "forgot-password-validate-email",
    async (data: IForgotPasswordValidateEmail) => {
      const response = await api.post<
        IApiResponse<IForgotPasswordValidateEmail>
      >("/users/forgot-password-validate-email", data);

      if (response.status !== 201) {
        throw response.data;
      }

      return response.data;
    }
  );
}
