import { Box, Text, VStack } from "native-base";
import React from "react";

import { SemiCircleProgress } from "./elements/SemiCircularProgress";

interface IResilienceScoreProps {
  text: string;
  percentage?: number;
  hasBottomText?: boolean;
}

const RESILIENCE_SCORE_TEXT = "Your Resilience Score";

export function UserResilienceScore({
  text,
  percentage = 0,
  hasBottomText = false,
}: IResilienceScoreProps) {
  return (
    <Box pb="4" px="6">
      <VStack alignItems="center" mb="4" space={2}>
        <Text fontSize="24" fontWeight="600" pb="4">
          {!hasBottomText ? RESILIENCE_SCORE_TEXT : null}
        </Text>
        <Box pb="2">
          <SemiCircleProgress percentage={percentage * 100} progressWidth={5} />
          <Box position="absolute" alignSelf="center" bottom="-10">
            <Text fontSize="50" fontWeight="600">
              {(percentage * 100).toFixed(0)}
            </Text>
          </Box>
        </Box>
        <Text fontSize="18" fontWeight="600">
          {hasBottomText ? RESILIENCE_SCORE_TEXT : null}
        </Text>
        <Text
          pt={!hasBottomText ? "2" : null}
          fontSize="14"
          fontWeight="400"
          textAlign="center"
          maxW={400}
        >
          {text}
        </Text>
      </VStack>
    </Box>
  );
}
