import { WellbeingResponseDto } from "api/src/wellbeing/dto/wellbeing-response.dto";
import { useMutation, useQueryClient } from "react-query";

import { EVENT_WELLBEING_CHECKER_COMPLETED } from "../constants/analytics";
import { eventHit } from "../util/analytics";
import { api } from "../util/api";

export function useWellbeingSubmit() {
  const queryClient = useQueryClient();

  return useMutation<null, null, WellbeingResponseDto>(
    "wellbeing/submit",
    async (data: WellbeingResponseDto) => {
      const response = await api.post("wellbeing", data);

      if (response.status !== 201) {
        throw response.data;
      }

      eventHit("submit", EVENT_WELLBEING_CHECKER_COMPLETED);
      queryClient.invalidateQueries(["content", "recommended"]);

      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["flagged-user"]);
      },
    }
  );
}
