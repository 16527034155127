import AsyncStorage from "@react-native-async-storage/async-storage";
import { AccountType } from "api/src/users/enum/account-type";
import { Modal, VStack, Text, Image } from "native-base";
import React, { useEffect, useState } from "react";

import { PAYMENT_REMINDER } from "../constants/payment-reminder";
import {
  PAYMENT_REMINDER_SCREEN_KEY,
  VERIFY_PAYMENT_REMINDER,
} from "../constants/storage";

interface IPaymentReminderProps {
  accountType: AccountType | undefined;
  setModalVisible: (m: boolean) => void;
  modalVisible: boolean;
}

export function PaymentReminderPopUp({
  accountType,
  modalVisible,
  setModalVisible,
}: IPaymentReminderProps) {
  const [paymentFee, setPaymentFee] = useState<number>(3.99);

  useEffect(() => {
    (async () => {
      if (accountType) {
        const paymentFee =
          [AccountType.student, AccountType.unidays].includes(accountType) ===
          true
            ? 2.99
            : 3.99;
        setPaymentFee(paymentFee);
      }
    })();
  }, [accountType, modalVisible]);

  async function handleClose() {
    await AsyncStorage.removeItem(PAYMENT_REMINDER_SCREEN_KEY);
    await AsyncStorage.setItem(VERIFY_PAYMENT_REMINDER, "viewed");
    setModalVisible(false);
  }

  return (
    <Modal isOpen={modalVisible} onClose={handleClose} size="xl">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header textAlign="center">Payment Reminder</Modal.Header>
        <Modal.Body alignItems="center" pb="20">
          <Image
            height={100}
            width={120}
            source={require("../assets/images/logo.png")}
            alt="Logo"
          />
          <VStack space={6} px={2} mx={10} alignItems="center">
            {PAYMENT_REMINDER(paymentFee).map((text, index) => (
              <Text
                key={index.toString()}
                fontSize={14}
                fontWeight={500}
                textAlign="center"
              >
                {text}
              </Text>
            ))}
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
