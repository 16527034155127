import { Modal, VStack, Text, Image } from "native-base";
import React, { Dispatch, SetStateAction } from "react";

interface IPopup {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  label: string;
}
export function PopUp({ modalVisible, setModalVisible, label }: IPopup) {
  async function handleClose() {
    setModalVisible(false);
  }

  return (
    <Modal isOpen={modalVisible} onClose={handleClose} size="md">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Body alignItems="center" pb="10">
          <Image
            height={100}
            width={120}
            source={require("../assets/images/logo.png")}
            alt="Logo"
          />
          <VStack px={2} mx={10} alignItems="center" textAlign="center">
            <Text>{label}</Text>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
