import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function NotificationIcon({ ...props }: IIconProps) {
  return (
    <Icon viewBox="3 0 32 33" {...props}>
      <Svg width="32" height="33" fill="none">
        <Path
          d="M11 20.25C14.5899 20.25 17.5 17.3399 17.5 13.75C17.5 10.1601 14.5899 7.25 11 7.25C7.41015 7.25 4.5 10.1601 4.5 13.75C4.5 17.3399 7.41015 20.25 11 20.25Z"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M19.4268 7.49215C20.3208 7.24026 21.2584 7.18287 22.1765 7.32386C23.0945 7.46486 23.9717 7.80095 24.749 8.3095C25.5262 8.81805 26.1855 9.48726 26.6823 10.272C27.1791 11.0568 27.502 11.939 27.6292 12.8591C27.7564 13.7791 27.685 14.7158 27.4197 15.6059C27.1544 16.4961 26.7014 17.319 26.0913 18.0194C25.4812 18.7197 24.7281 19.2812 23.8827 19.666C23.0373 20.0508 22.1193 20.2499 21.1905 20.25"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M2 24.9246C3.01517 23.4806 4.36287 22.302 5.92931 21.4884C7.49576 20.6748 9.23497 20.2501 11.0001 20.25C12.7652 20.2499 14.5045 20.6746 16.071 21.488C17.6375 22.3015 18.9853 23.48 20.0006 24.9239"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M21.1904 20.25C22.9557 20.2487 24.6953 20.6728 26.262 21.4864C27.8286 22.3 29.1761 23.4791 30.1905 24.9239"
          stroke="#C4C4C4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
