import { useMutation } from "react-query";

import { api } from "../util/api";

export interface ICheckoutResponse {
  session: string;
}

type ICheckoutRequest = Record<string, string>;

export function useCheckout() {
  return useMutation<ICheckoutResponse, null, ICheckoutRequest>(
    ["checkout"],
    async (data) => {
      const response = await api.post<ICheckoutResponse>(
        "subscriptions/checkout",
        data
      );

      return response.data;
    }
  );
}
