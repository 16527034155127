import { HideContentDto } from "api/src/content/dto/hide-content.dto";
import { RecommendedContentDto } from "api/src/content/dto/recommended-content.dto";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

import { useHiddenContentIds } from "./useHiddenContentIds";

interface IHideContentResponse {
  isHidden: boolean;
  hiddenId: string;
}

export function useHideContent() {
  const queryClient = useQueryClient();
  const { data: hiddenContentIds = [] } = useHiddenContentIds();

  return useMutation<IHideContentResponse, null, HideContentDto>(
    "hide-content",
    async (data: HideContentDto) => {
      const shouldDelete = hiddenContentIds?.includes(data.contentId);

      queryClient.setQueryData(
        "hidden-content-ids",
        hiddenContentIds?.includes(data.contentId)
          ? hiddenContentIds?.filter((content) => content !== data.contentId)
          : [...hiddenContentIds, data.contentId]
      );

      const method = shouldDelete ? "delete" : "post";
      const response = await api[method](
        `content/hide/${shouldDelete ? data.contentId : ""}`,
        data
      );
      if (response?.status > 400) {
        throw Promise.reject("Could not hidden content");
      }

      return { isHidden: !shouldDelete, hiddenId: data.contentId };
    },
    {
      onSuccess: (data) => {
        const recommendedContent: RecommendedContentDto | undefined =
          queryClient.getQueryData(["content", "recommended"]);
        const content = recommendedContent?.content.filter(
          (content) => content.id !== data.hiddenId
        );

        const sections = recommendedContent?.sections?.map((channelData) => {
          const channelContent = channelData?.content.filter(
            (content) => content.id !== data.hiddenId
          );
          return { ...channelData, content: channelContent };
        });

        queryClient.setQueryData(["content", "recommended"], {
          ...recommendedContent,
          content,
          sections,
        });
      },
    }
  );
}
