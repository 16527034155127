import { PieOptions } from "@antv/g2plot";
import { PieChart } from "@opd/g2plot-react";
import { IOrganisationEngagement } from "api/src/organisations/interfaces/IOrganisationEngagement";
import { isEmpty } from "lodash";
import { Box, HStack, Text, useColorModeValue, useToken } from "native-base";
import React from "react";

import { BACKGROUND_COLORS } from "../../constants/engagement-chart-bg-colors";

interface IEngagementChartProps {
  data: IOrganisationEngagement[];
  isLoading: boolean;
}
export function EngagementChart({ data, isLoading }: IEngagementChartProps) {
  const [secondary700, secondary400] = useToken("colors", [
    "secondary.700",
    "secondary.400",
  ]);
  const itemColor = useColorModeValue(secondary700, secondary400);
  const maxHeightForLegend = data && data.length / 2 <= 8 ? 300 : 380;

  const piePlot: PieOptions = {
    height: 420,
    width: 950,
    autoFit: true,
    appendPadding: 10,
    data,
    colorField: "label",
    angleField: "count",
    legend: {
      itemName: { style: { fill: itemColor } },
      position: "left" as const,
      itemHeight: 25,
      flipPage: false,
      maxWidth: 5000,
      maxRow: 2,
      maxHeight: maxHeightForLegend,
    },
    radius: 1,
    innerRadius: 0.8,
    color: BACKGROUND_COLORS,
    label: {
      content: "",
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: {
        content: "",
      },
    },
  };

  return (
    <HStack w="100%" justifyContent="center">
      {isLoading || isEmpty(data) ? (
        <Box alignItems="center" justifyContent="center" w="100%">
          <Text color="secondary.650">
            There's no data to be shown currently.
          </Text>
        </Box>
      ) : (
        <HStack w="100%" justifyContent="left" marginLeft="12">
          <PieChart {...piePlot} />
        </HStack>
      )}
    </HStack>
  );
}
