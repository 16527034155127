import { useMutation, useQueryClient } from "react-query";

import { api } from "../util/api";

interface IDeleteOrganisationContent {
  contentId: string | undefined;
}

export function useDeleteOrganisationContent(contentId: string | undefined) {
  const queryClient = useQueryClient();

  return useMutation<null, null, IDeleteOrganisationContent>(
    ["content", "delete-organisation", contentId],
    async () => {
      const response = await api.delete(
        `content/delete-organisation/${contentId}`
      );

      if (response?.status > 400) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("content");
      },
    }
  );
}
