import { useNavigation } from "@react-navigation/core";
import { CommonActions } from "@react-navigation/native";
import { AccountType } from "api/src/users/enum/account-type";
import { VStack, Text, useColorMode } from "native-base";
import React, { useMemo, useState } from "react";
import { Share } from "react-native";

import { APP_VERSION } from "../constants/app_version";
import { useCorporateUserRemainingInvitations } from "../hooks/useCorporateUserRemainingInvitations";
import { useGenerateInvitationCode } from "../hooks/useGenerateInvitationCode";
import { useLogout } from "../hooks/useLogout";
import { useMe } from "../hooks/useMe";
import { ToastStatus, useToast } from "../hooks/useToast";
import { useUsersUnReadNotificationQuantity } from "../hooks/useUsersUnReadNotificationQuantity";
import { Body } from "../layout/Body";
import { RootStackParamList } from "../navigation/RootStackParamList";
import { getShareMessage } from "../util/invite";
import { isNotWeb, isWeb } from "../util/platform";

import { DarkModeSwitch } from "./DarkModeSwitch";
import { ScrollView } from "./elements/ScrollView";
import {
  GrayFlowerLotusIcon,
  LinkIcon,
  InsightsIcon,
  MyJournalIcon,
  LogOutIcon,
  HabitTrackerIcon,
  BrainIcon,
  WindIcon,
  CoffeeIcon,
  DeleteIcon,
  NotificationIcon,
  MediaLibraryIcon,
  BellIcon,
  PrimaryColorBellIcon,
} from "./icons/Icons";
import { LockIcon } from "./icons/LockIcon";
import { PhoneIcon } from "./icons/PhoneIcon";
import { SpikeEmailIcon } from "./icons/SpikeEmail";
import { MoodIcon } from "./mood/icons/MoodIcon";
import { MyPageListHeader } from "./my-page/MyPageListHeader";
import { MyPageListItem } from "./MyPageListItem";
import { NotificationSwitch } from "./NotificationSwitch";
import { PrivacyAndPoliciesPopUp } from "./PrivacyAndPolicyPopUp";
import { ProfileHeader } from "./ProfileHeader";
import { TermsAndConditionsPopUp } from "./TermsAndConditionsPopup";

interface IDrawerNavigationMenuProps {
  onPress?: () => void;
}

/**
 * Originally refactored out of the MyPageScreen
 */
export function DrawerNavigationMenu({
  onPress = () => null,
}: IDrawerNavigationMenuProps) {
  const { dispatch, navigate } = useNavigation();
  const [isTCModalVisible, setIsTCModalVisible] = useState(false);
  const [isPrivacyPoliciesModalVisible, setIsPrivacyPoliciesModalVisible] =
    useState(false);
  const { data: unReadNotifications } = useUsersUnReadNotificationQuantity();
  const { mutate: logout } = useLogout();
  const toast = useToast();
  const { mutate: generateInviteCode } = useGenerateInvitationCode();
  const { data: me } = useMe();
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const { data: corporateUserRemainingInvitations } =
    useCorporateUserRemainingInvitations();
  const accountType = useMemo(
    () => me?.accountType ?? AccountType.standard,
    [me]
  );

  function handleOnLogout(): void {
    logout(undefined, {
      onSuccess: () => {
        dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: "Auth" }],
          })
        );
      },
    });
  }

  async function handleOnShare(): Promise<void> {
    generateInviteCode(undefined, {
      onSuccess: async ({ inviteCode }) => {
        if (isWeb()) {
          navigator.clipboard.writeText(
            `${process.env.APP_URL}/register?invitationCode=${inviteCode}`
          );
          toast({
            title: "Copied to clipboard",
            status: ToastStatus.success,
          });
          return;
        }
        await Share.share({
          message: getShareMessage(inviteCode),
        });
      },
    });
  }

  function handleOnNavigate(
    route: keyof RootStackParamList,
    params?: object
  ): void {
    onPress();
    navigate(route, params);
  }

  function handleTermsAndConditions(): void {
    if (isWeb()) {
      onPress();
      setIsTCModalVisible(!isTCModalVisible);
    } else {
      onPress();
      navigate("TermsAndConditions");
    }
  }

  function handlePrivacyAndPolicy(): void {
    if (isWeb()) {
      onPress();
      setIsPrivacyPoliciesModalVisible(!isPrivacyPoliciesModalVisible);
    } else {
      onPress();
      navigate("PrivacyAndPolicy");
    }
  }

  const notificationColor = isDarkMode ? "dark.200" : "#EBEBEB";
  // const onInviteFriend = () => {
  // if (!data?.inviteCode) {
  //   return;
  // }
  // Clipboard.setString(data.inviteCode);
  // toast({ title: "Copied to clipboard.", status: ToastStatus.success });
  // };
  return (
    <Body _web={{ maxH: "100vh" }}>
      <ScrollView paddingX={[6, 6, "10%", 0, 0]} _dark={{ bg: "dark.100" }}>
        <TermsAndConditionsPopUp
          modalVisible={isTCModalVisible}
          setModalVisible={setIsTCModalVisible}
        />
        <PrivacyAndPoliciesPopUp
          modalVisible={isPrivacyPoliciesModalVisible}
          setModalVisible={setIsPrivacyPoliciesModalVisible}
        />
        <ProfileHeader />
        <VStack space={5} pb={5}>
          <VStack px={[0, 5]}>
            <MyPageListHeader>Personal</MyPageListHeader>
            <VStack>
              {/*
                TODO: Readd when implemented
                <MyPageListItem
                icon={StreakIcon}
                title="2 Day Streak - Thats Great!"
                subtitle="5 days to go to unlock a months subscription to Kineticoach. View some more content tomorrow to continue your streak."
              /> */}
              <MyPageListItem
                icon={MoodIcon}
                title="Record Mood"
                onPress={() => {
                  handleOnNavigate("TrackMood");
                }}
              />
              <MyPageListItem
                bgColor={
                  unReadNotifications && unReadNotifications > 0
                    ? notificationColor
                    : undefined
                }
                icon={
                  unReadNotifications && unReadNotifications > 0
                    ? PrimaryColorBellIcon
                    : BellIcon
                }
                title="My Notifications"
                onPress={() => {
                  handleOnNavigate("Notifications");
                }}
              />
              <MyPageListItem
                icon={GrayFlowerLotusIcon}
                title="Complete Wellbeing Checker"
                onPress={() => {
                  handleOnNavigate("WellbeingChecker", { type: "menu" });
                }}
              />
              <MyPageListItem
                icon={InsightsIcon}
                title="Insights"
                onPress={() => {
                  handleOnNavigate("Insights");
                }}
              />
              <MyPageListItem
                icon={MediaLibraryIcon}
                title="My Media Library"
                onPress={() => {
                  handleOnNavigate("MyMediaLibrary");
                }}
              />
              <MyPageListItem
                icon={MyJournalIcon}
                title="My Journal"
                onPress={() => {
                  handleOnNavigate("MyJournal");
                }}
              />
              <MyPageListItem
                icon={MyJournalIcon}
                title="Record Feelings"
                onPress={() => {
                  handleOnNavigate("TrackFeelings");
                }}
              />
              <MyPageListItem
                icon={HabitTrackerIcon}
                title="Habit Tracker"
                onPress={() => {
                  handleOnNavigate("TrackHabits");
                }}
              />
              <MyPageListItem
                icon={BrainIcon}
                title="Coping Strategies"
                onPress={() => {
                  handleOnNavigate("CopingStrategies");
                }}
              />
              <MyPageListItem
                icon={WindIcon}
                title="Breathing Exercises"
                onPress={() => {
                  handleOnNavigate("BreathingExercises");
                }}
              />
              <MyPageListItem
                icon={CoffeeIcon}
                title="Get Grounded"
                onPress={() => {
                  handleOnNavigate("GetGrounded");
                }}
              />
            </VStack>
          </VStack>
          <VStack px={[0, 5]}>
            <MyPageListHeader>Membership</MyPageListHeader>

            <VStack>
              <MyPageListItem
                icon={LinkIcon}
                title={`Invite friends${
                  accountType === AccountType.corporate ? "/family" : ""
                }`}
                subtitle={
                  corporateUserRemainingInvitations
                    ? `${corporateUserRemainingInvitations} members invites left`
                    : ""
                }
                onPress={handleOnShare}
              />
              <MyPageListItem
                icon={LinkIcon}
                title="Subscription"
                subtitle="View and manage your subscription"
                onPress={() => {
                  handleOnNavigate("SubscriptionMain");
                }}
              />
              <MyPageListItem
                icon={LinkIcon}
                title="T&C's"
                subtitle="Read Terms and Conditions"
                onPress={handleTermsAndConditions}
              />
              <MyPageListItem
                icon={LinkIcon}
                title="Privacy Policy"
                subtitle="Read Privacy Policy"
                onPress={handlePrivacyAndPolicy}
              />
              {/* <MyPageListItem
                  icon={LinkIcon}
                  title="Invite Friends"
                  subtitle={`Tell your friends to use invite code: ${data?.inviteCode}`}
                  onPress={onInviteFriend}
                /> */}
            </VStack>
          </VStack>
          <VStack px={[0, 5]}>
            <MyPageListHeader>Settings</MyPageListHeader>
            <VStack>
              {isNotWeb() && <NotificationSwitch icon={NotificationIcon} />}
              <MyPageListItem
                icon={PhoneIcon}
                title="Emergency Contacts"
                onPress={() => {
                  handleOnNavigate("EmergencyContacts");
                }}
              />
              <MyPageListItem
                icon={SpikeEmailIcon}
                title="Email"
                style={{ marginLeft: 55 }}
                onPress={() => {
                  handleOnNavigate("ChangeEmail");
                }}
              />
              <MyPageListItem
                icon={LockIcon}
                title="Change Password"
                onPress={() => {
                  handleOnNavigate("ChangePassword");
                }}
              />
              <DarkModeSwitch />

              <MyPageListItem
                icon={DeleteIcon}
                textColor="red.400"
                title="Delete Account"
                onPress={() => {
                  handleOnNavigate("DeleteAccount");
                }}
              />
              <MyPageListItem
                icon={LogOutIcon}
                title="Log Out"
                onPress={handleOnLogout}
              />
              <Text p="3.5">You Are Using Version {APP_VERSION}</Text>
            </VStack>
          </VStack>
        </VStack>
      </ScrollView>
    </Body>
  );
}
