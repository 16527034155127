import { StackActions, useNavigation } from "@react-navigation/core";
import { Heading, Text, VStack } from "native-base";
import React, { useCallback, useRef, useState } from "react";
import { Animated, Easing, Pressable } from "react-native";

import { AlertDialog } from "../components/elements/AlertDialog";
import { Button } from "../components/elements/Button";
import { ScrollView } from "../components/elements/ScrollView";
import { CoffeeIcon, FlowerLotusLineIcon } from "../components/icons/Icons";
import { GET_GROUNDED_OPACITY_VALUES } from "../constants/get-grounded-opacity-values";
import { GET_GROUNDED_SCREEN } from "../constants/get-grounded-screen";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";
import { isWeb } from "../util/platform";

interface IGetGroundedScreen {
  isSecondLogin?: boolean;
  handleLoginContinue?: () => void;
}

export function GetGroundedScreen({
  isSecondLogin = false,
  handleLoginContinue,
}: IGetGroundedScreen) {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [groundedStep, setGroundedStep] = useState<number>(0);
  const [strokeStep, setStrokeStep] = useState<number>(0);
  const pulseAnimation = useRef(new Animated.Value(0)).current;
  const beginButtonOpicity = useRef(new Animated.Value(1)).current;
  const tapViewOpacity = useRef(new Animated.Value(0)).current;
  const { dispatch, navigate, goBack } = useNavigation();
  const pulse = pulseAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 1.2],
  });

  const handleOpacityStep = useCallback(() => {
    if (strokeStep === GET_GROUNDED_OPACITY_VALUES.length - 1) {
      setGroundedStep(0);
      setStrokeStep(0);
    } else {
      Animated.timing(pulseAnimation, {
        toValue: 1,
        duration: 1000,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start(() => {
        setStrokeStep(strokeStep + 1);
      });
    }
  }, [groundedStep, strokeStep, pulse]);

  const onPressOut = () => {
    Animated.timing(pulseAnimation, {
      toValue: 0,
      duration: 100,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };

  const handleBeginPress = () => {
    Animated.timing(beginButtonOpicity, {
      toValue: 0,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => setGroundedStep(1));
    Animated.timing(tapViewOpacity, {
      toValue: 1,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };

  const handleContinue = () => {
    if (isSecondLogin && groundedStep === 1) {
      setGroundedStep(1);
      setStrokeStep(0);
      return;
    }
    if (groundedStep === 3) {
      dispatch(StackActions.replace("GetGroundedSuccess"));
      return;
    }

    setGroundedStep(groundedStep + 1);
    setStrokeStep(0);
  };

  return (
    <>
      <AlertDialog
        headerText="Quit Exercise"
        bodyText="Are you sure you want to quit?"
        buttonText="Okay"
        buttonAction={() => {
          setShowAlert(false);
          if (isWeb()) {
            return navigate("Home");
          }
          return navigate("App");
        }}
        isOpen={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
      />
      {isSecondLogin ? null : (
        <Header
          hasBack={isWeb() ? groundedStep > 0 : true}
          onBackPress={() => {
            if (groundedStep > 0) {
              setShowAlert(true);
            } else {
              goBack();
            }
          }}
        />
      )}
      <Body>
        <ScrollView
          contentContainerStyle={{
            justifyContent: "center",
            alignContent: "center",
            flex: 1,
          }}
          _web={{ _dark: { bg: "dark.100" } }}
        >
          <VStack
            minH={700}
            space={10}
            alignItems="center"
            justifyContent="flex-start"
            _android={{
              marginTop: "10",
            }}
            _web={{
              background: "white",
              p: "10",
              width: { sm: "90%", md: "70%" },
              margin: "auto",
            }}
            _dark={{ bg: "dark.100" }}
          >
            <VStack space="4" alignItems="center">
              <CoffeeIcon size="6xl" />
              <Text fontSize={18}>
                Let's take a {isSecondLogin ? "moment" : "breath"}
              </Text>
              <Heading fontSize={18} fontWeight={600} textAlign="center">
                {isSecondLogin && groundedStep === 1 && strokeStep === 3
                  ? "Do you feel more gounded?"
                  : null}
                {isSecondLogin && groundedStep === 1 && strokeStep < 3
                  ? "Name 3 Things that are fast"
                  : null}
                {isSecondLogin && groundedStep === 0
                  ? GET_GROUNDED_SCREEN[groundedStep].title
                  : null}
                {isSecondLogin ? null : GET_GROUNDED_SCREEN[groundedStep].title}
              </Heading>
              <Text fontSize={14} textAlign="center">
                {isSecondLogin && groundedStep === 1 && strokeStep < 3
                  ? "Tap once you have named the 3 things"
                  : null}
                {isSecondLogin && groundedStep === 0
                  ? GET_GROUNDED_SCREEN[groundedStep].details
                  : null}
                {isSecondLogin
                  ? null
                  : GET_GROUNDED_SCREEN[groundedStep].details}
              </Text>
            </VStack>
            <VStack>
              {groundedStep === 0 ? (
                <Animated.View style={{ opacity: beginButtonOpicity }}>
                  <Button
                    px="4"
                    minW="150"
                    variant="outline"
                    onPress={handleBeginPress}
                  >
                    Begin
                  </Button>
                </Animated.View>
              ) : (
                <Animated.View style={{ opacity: tapViewOpacity }}>
                  <VStack mt="4" alignItems="center" space="6">
                    <Pressable
                      onPressIn={handleOpacityStep}
                      onPressOut={onPressOut}
                      disabled={strokeStep === 3}
                    >
                      <Animated.Image
                        source={require("../assets/images/logo-single.png")}
                        style={{
                          width: 70,
                          height: 70,
                          transform: [
                            {
                              scale: pulse,
                            },
                          ],
                        }}
                      />
                    </Pressable>
                    <FlowerLotusLineIcon
                      size="150"
                      strokeOpacities={GET_GROUNDED_OPACITY_VALUES[strokeStep]}
                    />
                  </VStack>
                </Animated.View>
              )}
              {strokeStep === 3 ? (
                <Button
                  px="4"
                  minW="40%"
                  variant="solid"
                  onPress={handleContinue}
                >
                  {isSecondLogin && groundedStep === 1
                    ? "No, Continue"
                    : "Continue"}
                </Button>
              ) : null}
              {isSecondLogin && strokeStep === 3 && groundedStep === 1 ? (
                <>
                  <Button
                    px="6"
                    mt="5"
                    minW="40%"
                    variant="outline"
                    onPress={handleLoginContinue}
                  >
                    Yes, End exercise now
                  </Button>
                </>
              ) : null}
            </VStack>
          </VStack>
        </ScrollView>
      </Body>
    </>
  );
}
