import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import { View, VStack, Text } from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { Alert, Dimensions } from "react-native";

import { AuthButton } from "../components/auth/AuthButton";
import { AuthCard } from "../components/auth/AuthCard";
import { useAccountActive } from "../hooks/useAccountActive";
import { useLogin } from "../hooks/useLogin";
import { ToastStatus, useToast } from "../hooks/useToast";
import { useVerifyEmailAgain } from "../hooks/useVerifyEmailAgain";

const { height } = Dimensions.get("window");
export function ConfirmEmailScreen() {
  const { mutate: login, isLoading: isLoginLoading } = useLogin();
  const { navigate } = useNavigation();
  const { mutate: fetchAccountActive, isLoading: isActiveLoading } =
    useAccountActive();
  const [accountEmail, setAccountEmail] = useState<string>("");
  const [isAccountActive, setIsAccountActive] = useState<boolean>(false);
  const { mutate: verifyEmailAgain, isLoading: isSendEmailLoading } =
    useVerifyEmailAgain();
  const toast = useToast();
  const isLoading = isLoginLoading || isActiveLoading;

  useEffect(() => {
    (async () => {
      const email = await AsyncStorage.getItem("email");
      setAccountEmail(email ?? "");
      fetchAccountActive(null, {
        onSuccess: () => {
          setIsAccountActive(true);
        },
      });
    })();
  }, []);

  async function handleLogin() {
    const password = await AsyncStorage.getItem("password");
    login(
      { email: accountEmail, password: password ?? "" },
      {
        onSuccess: () => {
          navigate("Onboarding");
        },
        onError: () => {},
      }
    );
  }

  const handleAlreadyVerified = useCallback(() => {
    fetchAccountActive(null, {
      onSuccess: () => {
        handleLogin();
      },
      onError: () => {
        Alert.alert(
          "Account not active",
          "Make sure you've activated your account by clicking the link in the email."
        );
      },
    });
  }, [fetchAccountActive, handleLogin]);

  const handleEmailVerification = () => {
    verifyEmailAgain(
      {
        email: accountEmail,
      },
      {
        onSuccess: (data) => {
          if (data.status === "ok") {
            toast({
              title: data.message || "Email sent successfully",
              status: ToastStatus.success,
            });
          } else {
            toast({
              title: data.message || "Failed to send email.",
              status: ToastStatus.error,
            });
          }
        },
        onError: () => {
          toast({
            title: "Failed to send email.",
            status: ToastStatus.error,
          });
        },
      }
    );
  };

  return (
    <View justifyContent="center" alignItems="center" minH={height}>
      <AuthCard
        label="Confirm your email"
        subheading={
          <>
            We've sent an email to{"\n"}
            <Text fontWeight={600} color="primary.400">
              {accountEmail}
            </Text>
            {"\n"}Please verify your email to get started.
          </>
        }
      >
        <VStack space={2} mt={4}>
          <VStack space={2}>
            <AuthButton
              onPress={handleAlreadyVerified}
              isLoading={isLoading}
              variant="invertedAuth"
            >
              I've verified
            </AuthButton>
            <AuthButton
              onPress={handleEmailVerification}
              isLoading={isSendEmailLoading}
              variant="solid"
              isDisabled={isAccountActive}
            >
              Resend Email
            </AuthButton>
          </VStack>
        </VStack>
      </AuthCard>
    </View>
  );
}
