import { Icon } from "native-base";
import React from "react";
import { Svg, Rect, Line } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function VerticalContentIcon({
  color = "#DF3D8D",
  ...props
}: IIconProps) {
  return (
    <Icon viewBox="0 -5 30 20" {...props}>
      <Svg width="14" height="10" viewBox="0 0 14 10" fill="none">
        <Rect x="0.5" y="0.5" width="13" height="7" rx="1.5" stroke={color} />
        <Line
          x1="1.77344"
          y1="9.5"
          x2="12.228"
          y2="9.5"
          stroke={color}
          strokeLinecap="round"
        />
      </Svg>
    </Icon>
  );
}
