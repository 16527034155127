import AsyncStorage from "@react-native-async-storage/async-storage";
import { CommonActions, useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";

import { useActiveSubscription } from "../../hooks/useActiveSubscription";
import { useMe } from "../../hooks/useMe";

export function AuthInitializeScreen() {
  const { dispatch } = useNavigation();
  const { data: activeSubscription, isLoading: isActiveSubscriptionLoading } =
    useActiveSubscription();
  const { data: user, error, isLoading: isMeLoading } = useMe();

  useEffect(() => {
    (async () => {
      if (
        (user === undefined && error === null) ||
        isActiveSubscriptionLoading ||
        isMeLoading
      ) {
        return null;
      }

      // TODO: Cache the useMe hook so it doesn't have to make a network request
      // to load the app
      let nextScreen = "Main";
      if (user && !user.hasOnboarded) {
        nextScreen = "Onboarding";
      } else if (activeSubscription?.isFree) {
        nextScreen = "SubscriptionAuth";
      }

      const loginToken =
        error !== 401 && (await AsyncStorage.getItem("access_token"));

      dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: loginToken ? nextScreen : "Welcome" }],
        })
      );
    })();
  }, [user, error, isActiveSubscriptionLoading, activeSubscription]);

  return <></>;
}
