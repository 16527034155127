import React from "react";
import { G, Path, Rect } from "react-native-svg";

export function OkayMoodSvg({
  x,
  y,
  scale = 1,
}: {
  x: number;
  y: number;
  scale?: number;
}) {
  return (
    <G x={x} y={y} scale={scale}>
      <Path
        d="M130.649 258.2c70.848 0 128.28-57.465 128.28-128.35 0-70.885-57.432-128.35-128.28-128.35S2.369 58.965 2.369 129.85c0 70.885 57.432 128.35 128.28 128.35Z"
        fill="#fff"
        stroke="#C4C4C4"
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M102.714 106.2c0 16.624-8.659 30.1-19.34 30.1-10.68 0-19.339-13.476-19.339-30.1 0-16.624 8.659-30.1 19.34-30.1 10.68 0 19.339 13.476 19.339 30.1ZM197.261 106.2c0 16.624-8.658 30.1-19.339 30.1s-19.339-13.476-19.339-30.1c0-16.624 8.658-30.1 19.339-30.1s19.339 13.476 19.339 30.1Z"
        fill="#C4C4C4"
      />
      <Rect
        x={68.832}
        y={179.8}
        width={123.631}
        height={17.9}
        rx={8.95}
        fill="#C4C4C4"
        stroke="#C4C4C4"
      />
    </G>
  );
}
