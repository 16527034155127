import { Icon } from "native-base";
import React from "react";
import { Path, Svg } from "react-native-svg";

import { IIconProps } from "../../interfaces/IIconprops";

export function CoffeeIcon({ color = "#C4C4C4", ...props }: IIconProps) {
  return (
    <Icon viewBox="0 0 33 33" {...props}>
      <Svg width={33} height={33} fill="none" stroke={color}>
        <Path
          d="M11.53 3.9v4M15.53 3.9v4M19.53 3.9v4M4.53 27.9h22M10.941 27.9a11.002 11.002 0 0 1-6.412-10v-6h22v6a11.002 11.002 0 0 1-6.412 10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M26.53 11.9a4 4 0 0 1 4 4v1a4 4 0 0 1-4 4h-.423"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
