import { useFocusEffect } from "@react-navigation/core";
import { BackHandler } from "react-native";

export function useBackButton(handler: () => boolean) {
  return useFocusEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handler);

    return () => BackHandler.removeEventListener("hardwareBackPress", handler);
  });
}
