import { useNavigation } from "@react-navigation/core";
import { RouteProp, useRoute } from "@react-navigation/native";
import { VStack, Text } from "native-base";
import React from "react";
import { View as AnimatedView } from "react-native-animatable";

import { AffirmationOption } from "../components/affirmation/AffirmationOption";
import { Button } from "../components/elements/Button";
import { ScrollView } from "../components/elements/ScrollView";
import { AFFIRMATIONS } from "../constants/affirmations";
import { useBackButton } from "../hooks/useBackButton";
import { Body } from "../layout/Body";
import { Header } from "../layout/Header";
import { RootStackParamList } from "../navigation/RootStackParamList";

export function AffirmationScreen() {
  const { navigate } = useNavigation();

  const { params: allParams } =
    useRoute<RouteProp<RootStackParamList, "Affirmation">>();

  function handleContinue() {
    navigate("Digesting");
  }

  useBackButton(() => !(allParams?.status === "skipped"));
  return (
    <>
      {allParams?.status === "skipped" && <Header hasBack={true} />}
      <Body>
        <ScrollView
          pb={100}
          contentContainerStyle={{ paddingBottom: 70, paddingTop: 70 }}
        >
          <VStack space={6} px={6}>
            <VStack space={2}>
              <AnimatedView animation="zoomIn" delay={0} duration={1000}>
                <Text textAlign="center" fontSize={18} fontWeight="600">
                  {
                    AFFIRMATIONS[
                      Math.floor(Math.random() * AFFIRMATIONS.length)
                    ]
                  }
                </Text>
              </AnimatedView>
              <AnimatedView animation="fadeIn" delay={1100} duration={1000}>
                <Text
                  textAlign="center"
                  fontSize={14}
                  fontWeight="600"
                  paddingTop={10}
                  paddingBottom={4}
                >
                  Would You Like To Try...
                </Text>
                <AffirmationOption
                  text="A Breathing Exercise"
                  onPress={() => navigate("BreathingExercises")}
                />
                <AffirmationOption
                  text="Get Grounded"
                  onPress={() => navigate("GetGrounded")}
                />
                <AffirmationOption
                  text="Coping Strategies"
                  onPress={() => navigate("CopingStrategies")}
                />

                <AffirmationOption
                  text="Insights"
                  onPress={() => navigate("Insights")}
                />

                <Button variant="auth" marginTop={30} onPress={handleContinue}>
                  Continue
                </Button>
              </AnimatedView>
            </VStack>
          </VStack>
        </ScrollView>
      </Body>
    </>
  );
}
