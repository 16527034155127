import { API_URL } from "@env";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios, { AxiosRequestConfig } from "axios";

const api = axios.create({
  baseURL: `${API_URL}${API_URL.endsWith("/") ? "" : "/"}`,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: () => true,
});

/**
 * Attach auth token
 *
 * @remarks Attach auth token to every request
 *
 * @param config - axios request config
 * @returns modified configuration
 */
async function attachAuthToken(config: AxiosRequestConfig) {
  const token = await AsyncStorage.getItem("access_token");

  if (!config.headers) {
    config.headers = {};
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
}

api.interceptors.request.use(attachAuthToken);

export { api };
