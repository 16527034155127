import { Text } from "native-base";
import { VariantType } from "native-base/lib/typescript/components/types";
import React from "react";

interface IInputLabelProps {
  variant?: VariantType<"Input">;
  label?: string;
}

export function InputLabel({ label, variant }: IInputLabelProps) {
  if (!label) {
    return null;
  }
  if (variant === "title" || variant === "contentInput") {
    return (
      <Text fontSize={16} color="primary.400" fontWeight="600" px={1} mb={1}>
        {label}
      </Text>
    );
  }

  return (
    <Text fontSize={20} fontWeight="300" color="secondary.500">
      {label}
    </Text>
  );
}
