import { Box, VStack, Pressable, Text } from "native-base";
import React from "react";

import { IIconProps } from "../../interfaces/IIconprops";

interface IDigestItemProps {
  activeIndex?: number;
  label: string;
  icon: (icon: IIconProps) => JSX.Element;
  onPress?: () => void;
}

export function DigestItem({
  activeIndex,
  icon: Icon,
  onPress,
  label,
}: IDigestItemProps) {
  return (
    <Pressable _pressed={{ opacity: 0.8 }} onPress={onPress}>
      <VStack
        p={2}
        px={5}
        space={2}
        borderRadius={5}
        alignItems="center"
        bg={activeIndex ? "rgba(0,0,0,.05)" : "transparent"}
      >
        <Icon size={10} color="primary.400" />
        <Text>{label}</Text>
      </VStack>
      {activeIndex ? (
        <Box
          h={6}
          w={6}
          top={-5}
          right={-5}
          bg="primary.400"
          borderRadius={100}
          position="absolute"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontSize={12} color="white">
            {activeIndex}
          </Text>
        </Box>
      ) : null}
    </Pressable>
  );
}
